// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { css } from '@emotion/css';
import { Theme, useTheme, Autocomplete, Button, InputAdornment, TextField } from '@mui/material';
import { BiFingerprint, BiPalette, BiTime } from 'react-icons/bi';
import { IoCog } from 'react-icons/io5';
import { MdHdrAuto } from 'react-icons/md';

import VinLookup from '@components/Selectors/VinLookup';
import StockLookup from '@components/Selectors/StockLookup';
import MakeSelect from '@components/Selectors/MakeSelect';
import ModelSelect from '@components/Selectors/ModelSelect';

import { getColors, getYears } from '@features/moveCreation/services/movePlannerService';
import { MovePlannerContext, MovePlannerContextType } from '@features/moveCreation/providers/MovePlannerProvider';
import { Vehicle } from '@features/moveCreation/providers/MovePlannerProvider';

// TYPES ---------------------------------------------------------------- //

interface MovePlannerVehicleFormProps {
  index?: number;
  requireMakeAndModel?: boolean;
  rooftopId?: number;
  vehicle?: Vehicle;
  setVehicle?: (vehicle?: Vehicle) => void;
  appointmentMode?: boolean;
  refNumLabel?: string | null;
}

// COMPONENT ---------------------------------------------------------------- //

const MovePlannerVehicleForm = ({
  index,
  requireMakeAndModel,
  rooftopId,
  vehicle,
  setVehicle,
  appointmentMode,
  refNumLabel,
}: MovePlannerVehicleFormProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const { validation } = React.useContext(MovePlannerContext) as MovePlannerContextType;

  const vehicleIndex = `vehicle${index}`;

  return (
    <div className={cls.vehicle}>
      <div className={cls.flex}>
        <div className={cls.flexItem}>
          <label className={cls.textFieldLabel} htmlFor='vehicle-reference-id'>
            {refNumLabel ? refNumLabel : `Reference ID`}
          </label>

          <TextField
            fullWidth
            name='vehicle-reference-id'
            placeholder={refNumLabel ? `Enter ${refNumLabel}...` : `Enter reference ID...`}
            value={vehicle?.referenceId || ``}
            onChange={e => {
              if (setVehicle) setVehicle({ ...vehicle, referenceId: e?.target?.value || `` });
            }}
            slotProps={{
              htmlInput: {
                maxLength: 24,
              },
              input: {
                startAdornment: (
                  <InputAdornment position='start'>
                    <BiFingerprint size={20} />
                  </InputAdornment>
                ),
              },
            }}
          />
        </div>

        <div className={cls.flexItem}>
          <label className={cls.textFieldLabel} htmlFor='vehicle-transmission'>
            Transmission
          </label>

          <div className={cls.buttonGroup}>
            <Button
              fullWidth
              color='utility'
              variant={vehicle?.manual === false ? `contained` : `outlined`}
              startIcon={<MdHdrAuto />}
              onClick={() => {
                if (setVehicle) setVehicle({ ...vehicle, manual: false });
              }}
              sx={{ height: `40px`, borderRadius: `6px 0 0 6px` }}
            >
              Automatic
            </Button>

            <Button
              fullWidth
              color='utility'
              variant={vehicle?.manual === true ? `contained` : `outlined`}
              startIcon={<IoCog />}
              onClick={() => {
                if (setVehicle) setVehicle({ ...vehicle, manual: true });
              }}
              sx={{ height: `40px`, borderRadius: `0 6px 6px 0` }}
            >
              Manual
            </Button>
          </div>
        </div>
      </div>

      <div className={cls.spacer} />

      <div className={cls.flex}>
        <div className={cls.flexItem}>
          <VinLookup
            required={requireMakeAndModel}
            disableClearable
            rooftopId={rooftopId}
            error={!validation?.[vehicleIndex]?.vin}
            name={`vehicle-vin`}
            label={`VIN`}
            helperText={!validation?.[vehicleIndex]?.vin ? `Please enter the vehicle VIN!` : ``}
            value={vehicle?.vin}
            onChange={v => {
              if (setVehicle)
                setVehicle({
                  ...v,
                  color: v?.vehicle_color || null,
                  make: v?.vehicle_make || null,
                  manual: v?.manual_flag || false,
                  model: v?.vehicle_model || null,
                  referenceId: v?.reference_num || null,
                  stock: v?.vehicle_stock || null,
                  vin: v?.vehicle_vin || null,
                  year: v?.vehicle_year || null,
                });
            }}
            onInputChange={vin => {
              if (setVehicle) setVehicle({ ...vehicle, vin });
            }}
          />
        </div>

        {!appointmentMode && (
          <div className={cls.flexItem}>
            <StockLookup
              rooftopId={rooftopId}
              name={`vehicle-stock`}
              label={`Stock`}
              value={vehicle?.stock}
              onChange={v => {
                if (setVehicle)
                  setVehicle({
                    ...v,
                    color: v?.vehicle_color || null,
                    make: v?.vehicle_make || null,
                    manual: v?.manual_flag || false,
                    model: v?.vehicle_model || null,
                    referenceId: v?.reference_num || null,
                    stock: v?.vehicle_stock || null,
                    vin: v?.vehicle_vin || null,
                    year: v?.vehicle_year || null,
                  });
              }}
              onInputChange={stock => {
                if (setVehicle) setVehicle({ ...vehicle, stock });
              }}
            />
          </div>
        )}
      </div>

      <div className={cls.spacer} />

      <div className={cls.flex}>
        <div className={cls.flexItem}>
          <label className={cls.textFieldLabel} htmlFor='vehicle-year'>
            Year
            {requireMakeAndModel ? <span className={cls.textFieldLabelRequired}> *</span> : null}
          </label>

          <Autocomplete
            fullWidth
            options={getYears()}
            getOptionLabel={option => option}
            noOptionsText='No years found'
            value={vehicle?.year || null}
            onChange={(_, value) => {
              if (setVehicle) setVehicle({ ...vehicle, year: value || null });
            }}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                error={!validation?.[vehicleIndex]?.year}
                type='number'
                name='vehicle-year'
                placeholder='Select a year...'
                helperText={!validation?.[vehicleIndex]?.year ? `Please select the vehicle year!` : ``}
                slotProps={{
                  htmlInput: {
                    ...params?.inputProps,
                    maxLength: 4,
                  },
                  input: {
                    ...params?.InputProps,
                    startAdornment: (
                      <InputAdornment position='start' sx={{ marginLeft: `8px`, marginRight: 0 }}>
                        <BiTime size={20} />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            )}
          />
        </div>

        <div className={cls.flexItem}>
          <MakeSelect
            required={requireMakeAndModel}
            error={!validation?.[vehicleIndex]?.make}
            name={`vehicle-make`}
            label={`Make`}
            helperText={!validation?.[vehicleIndex]?.make ? `Please enter the vehicle make!` : ``}
            value={vehicle?.make}
            onChange={make => {
              if (setVehicle) setVehicle({ ...vehicle, make });
            }}
            onInputChange={make => {
              if (setVehicle) setVehicle({ ...vehicle, make });
            }}
          />
        </div>
      </div>

      <div className={cls.spacer} />

      <div className={cls.flex}>
        <div className={cls.flexItem}>
          <ModelSelect
            make={vehicle?.make}
            required={requireMakeAndModel}
            error={!validation?.[vehicleIndex]?.model}
            name={`vehicle-model`}
            label={`Model`}
            helperText={!validation?.[vehicleIndex]?.model ? `Please enter the vehicle model!` : ``}
            value={vehicle?.model}
            onChange={model => {
              if (setVehicle) setVehicle({ ...vehicle, model });
            }}
            onInputChange={model => {
              if (setVehicle) setVehicle({ ...vehicle, model });
            }}
          />
        </div>

        <div className={cls.flexItem}>
          <label className={cls.textFieldLabel} htmlFor='vehicle-color'>
            Color
          </label>

          <Autocomplete
            fullWidth
            options={getColors()}
            getOptionLabel={option => option}
            noOptionsText='No colors found'
            value={vehicle?.color || null}
            onChange={(_, value) => {
              if (setVehicle) setVehicle({ ...vehicle, color: value || null });
            }}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                name='vehicle-color'
                placeholder='Select a color...'
                slotProps={{
                  htmlInput: {
                    ...params?.inputProps,
                    maxLength: 24,
                  },
                  input: {
                    ...params?.InputProps,
                    startAdornment: (
                      <InputAdornment position='start' sx={{ marginLeft: `8px`, marginRight: 0 }}>
                        <BiPalette size={20} />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  return {
    vehicle: css`
      position: relative;
      margin-bottom: 18px;
    `,

    textFieldLabel: css`
      display: block;
      margin-bottom: 2px;
      margin-left: 6px;
      font-size: 12px;
      font-weight: 600;
    `,
    textFieldLabelRequired: css`
      color: ${theme?.palette?.error?.main};
    `,

    buttonGroup: css`
      display: flex;
    `,

    flex: css`
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 12px;
      ${theme?.breakpoints?.down('sm')} {
        flex-direction: column;
      }
    `,
    flexItem: css`
      flex: 1;
      width: 100%;
    `,

    spacer: css`
      width: 100%;
      height: 12px;
    `,
  };
};

// EXPORT ---------------------------------------------------------------- //

export default MovePlannerVehicleForm;
