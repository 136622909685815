import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Paper, Typography, Button, Icon, TextField, InputAdornment, useTheme, Theme } from '@mui/material';
import { css } from '@emotion/css';
import { toast } from 'react-toastify';
import { useUpdateLocationMutation } from '@gql/schema';

const log = false;

interface LocationDetailsEditProps {
  location: {
    id: number;
    type: string;
    name?: string;
    address?: string;
    nickname?: string;
    email?: string;
    phone?: string;
  };
}

const LocationDetailsEdit: React.FC<LocationDetailsEditProps> = props => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const { location } = props;

  const [type, setType] = useState(location.type || ``);
  const [name, setName] = useState(location.name || ``);
  const [address, setAddress] = useState(location.address || ``);
  const [nickname, setNickname] = useState(location.nickname || ``);
  const [email, setEmail] = useState(location.email || ``);
  const [phone, setPhone] = useState(location.phone || ``);
  const [disabled, setDisabled] = useState(true);

  const [updateLocationMutation] = useUpdateLocationMutation();

  useEffect(() => {
    getDisabledButton();
  }, [location, type, name, address, nickname, email, phone]);

  const handleInputChange =
    (setHandler: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setHandler(event.target.value);
    };

  const getDisabledButton = () => {
    if (name.length < 1 || address.length < 1) setDisabled(true);
    else if (location.type !== type) setDisabled(false);
    else if (location.name !== name) setDisabled(false);
    else if (location.address !== address) setDisabled(false);
    else if (location.nickname !== nickname) setDisabled(false);
    else if (location.email !== email) setDisabled(false);
    else if (location.phone !== phone) setDisabled(false);
    else setDisabled(true);
  };

  const updateLocation = async () => {
    if (!disabled) {
      const variables = {
        id: location.id,
        type: type,
        name: name,
        address: address,
        nickname: nickname,
        email: email,
        phone: phone,
      };

      try {
        const res = await updateLocationMutation({ variables });
        if (res) {
          log && console.log(`>> UPDATED Location:`, res?.data?.update_locations?.returning[0]);
          toast.success('Location updated successfully');
        }
      } catch (err) {
        console.log('Error updating location:', err);
        toast.error('Error updating location');
      }
    }
  };

  return (
    <div className={cls.main}>
      <Paper variant='custom' className={cls.paper}>
        <Typography variant='h5' sx={{ lineHeight: 1, fontWeight: 600 }}>
          Required Fields
        </Typography>

        <div className={cls.bigBreak} />

        <TextField
          required
          fullWidth
          label='Name'
          placeholder='Enter a name...'
          value={name}
          onChange={handleInputChange(setName)}
          variant='outlined'
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  local_offer
                </Icon>
              </InputAdornment>
            ),
          }}
          className={cls.field}
        />

        <div className={cls.break} />

        <TextField
          disabled
          required
          fullWidth
          label='Address'
          placeholder='Enter an address...'
          value={address}
          onChange={handleInputChange(setAddress)}
          variant='outlined'
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  room
                </Icon>
              </InputAdornment>
            ),
          }}
          className={cls.field}
        />

        <div className={cls.bigBreak} />

        <Typography variant='h5' sx={{ lineHeight: 1, fontWeight: 600 }}>
          Optional Fields
        </Typography>

        <div className={cls.bigBreak} />

        <TextField
          fullWidth
          label='Nickname'
          placeholder='(Optional) Enter a nickname...'
          value={nickname}
          onChange={handleInputChange(setNickname)}
          variant='outlined'
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  loyalty
                </Icon>
              </InputAdornment>
            ),
          }}
          className={cls.field}
        />

        <div className={cls.break} />

        <TextField
          fullWidth
          type='email'
          label='Email'
          placeholder='(Optional) Enter a related email...'
          value={email}
          onChange={handleInputChange(setEmail)}
          variant='outlined'
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  email
                </Icon>
              </InputAdornment>
            ),
          }}
          className={cls.field}
        />

        <div className={cls.break} />

        <TextField
          fullWidth
          type='phone'
          label='Phone'
          placeholder='(Optional) Enter a related phone number...'
          value={phone}
          onChange={handleInputChange(setPhone)}
          variant='outlined'
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  phone_iphone
                </Icon>
              </InputAdornment>
            ),
          }}
          className={cls.field}
        />

        <div className={cls.break} />

        <div className={cls.actions}>
          <Button disabled={disabled} color='primary' onClick={() => updateLocation()}>
            Save Location
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default LocationDetailsEdit;

const useStyles = (theme?: Theme) => ({
  main: css``,
  paper: css`
    width: 100%;
    padding: ${theme?.spacing(2)};
  `,
  title: css`
    font-size: 21px;
    font-weight: 500;
  `,
  field: css`
    margin: 0;
  `,
  actions: css`
    display: flex;
    justify-content: flex-end;
    width: 100%;
  `,
  break: css`
    width: 100%;
    height: ${theme?.spacing(2)};
  `,
  bigBreak: css`
    width: 100%;
    height: ${theme?.spacing(3)};
  `,
});

const UPDATE_LOCATION = gql`
  mutation update_location(
    $id: bigint!
    $type: String!
    $name: String!
    $address: String!
    $nickname: String
    $email: String
    $phone: String
  ) {
    update_locations(
      where: { id: { _eq: $id } }
      _set: { type: $type, name: $name, address: $address, nickname: $nickname, email: $email, phone: $phone }
    ) {
      affected_rows
      returning {
        id
        region_id
        place_id
        type
        name
        address
        nickname
        email
        phone
        latitude
        longitude
      }
    }
  }
`;
