// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import { useUser, useRooftop } from '@store';
import { checkForPowerRole } from '@features/auth/services/authService';
import { validatePhone } from '@services/validationService';

import {
  Customers,
  Lanes,
  Locations,
  Moves,
  Workflowsets,
  Appointments,
  useGetLocationsLazyQuery,
  useGetMovePlannerDefaultMovesLazyQuery,
  useGetMovePlannerLanesLazyQuery,
  useGetPayersLazyQuery,
  useGetRooftopsLazyQuery,
  useGetWorkflowsetsLazyQuery,
  useInsertMovePlannerMovesMutation,
  useUpsertMovePlannerLanesMutation,
} from '@gql/schema';

import { LinearProgress } from '@mui/material';

import { buildMovesToInsert } from '@features/moveCreation/services/movePlannerService';
import { enrichCustomerConfig, getDefaultConfig } from '@utils/configsService';

const log = false;

// TYPES ---------------------------------------------------------------- //

export interface MovePlannerContextType {
  hasAdminPrivileges?: boolean;
  movePlannerLocked?: boolean;
  showEmailNotifications?: boolean;
  showDistanceEstimates?: boolean;
  showPriceEstimates?: boolean;
  validation?: Validation;
  rooftopConfig?: any;
  rooftops?: Customers[];
  payers?: Customers[];
  workflowsets?: Workflowsets[];
  lanes?: Lanes[];
  locations?: Locations[];
  rooftopId?: number;
  payerId?: number;
  workflowsetId?: number;
  type?: string;
  emails?: string[];
  consumer?: Consumer;
  time?: string;
  isDefaultTime?: boolean;
  lane?: Lanes | null;
  pickup?: Locations | null;
  delivery?: Locations | null;
  move1Title?: string;
  move2Title?: string;
  vehicle1?: Vehicle;
  vehicle2?: Vehicle;
  additional1?: Additional;
  additional2?: Additional;
  saving?: boolean;
  setValidation: (validation?: Validation) => void;
  setRooftopConfig: (config?: any) => void;
  setRooftops: (rooftops?: Customers[]) => void;
  setPayers: (payers?: Customers[]) => void;
  setWorkflowsets: (workflowsets?: Workflowsets[]) => void;
  setLanes: (lanes?: Lanes[]) => void;
  setLocations: (locations?: Locations[]) => void;
  setRooftopId: (id?: number) => void;
  setPayerId: (id?: number) => void;
  setWorkflowsetId: (id?: number) => void;
  setType: (type?: string) => void;
  setEmails: (emails?: string[]) => void;
  setConsumer: (consumer?: Consumer) => void;
  setTime: (time?: string) => void;
  setIsDefaultTime: (isDefaultTime?: boolean) => void;
  setLane: (lane?: Lanes | null) => void;
  setPickup: (pickup?: Locations | null) => void;
  setDelivery: (delivery?: Locations | null) => void;
  setMove1Title: (title?: string) => void;
  setMove2Title: (title?: string) => void;
  setVehicle1: (vehicle?: Vehicle) => void;
  setVehicle2: (vehicle?: Vehicle) => void;
  setAdditional1: (additional?: Additional) => void;
  setAdditional2: (additional?: Additional) => void;
  setSaving: (saving?: boolean) => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  handleSubmitAndAddAnother: (e?: React.FormEvent<HTMLFormElement>) => void;
  validateForm: () => { [key: string]: any };
  defaultValidation: Validation;
  defaultLocation: Locations | null;
  defaultLane: Lanes | null;
  defaultVehicle: Vehicle;
  defaultAdditional: Additional;
  defaultConsumer: Consumer;
  defaultTime: string;
  defaultIsDefaultTime: boolean;
}

export interface MovePlannerProviderProps {
  children?: React.ReactNode;
  state?: {
    moveId1?: number;
    moveId2?: number;
    inverse?: boolean;
    appointment?: Appointments;
  };
}

export interface Validation {
  rooftopId?: boolean;
  payerId?: boolean;
  workflowsetId?: boolean;
  type?: boolean;
  emails?: boolean;
  consumer?: {
    name?: boolean;
    phone?: boolean;
    action?: boolean;
    first?: boolean;
  };
  time?: boolean;
  lane?: boolean;
  pickup?: boolean;
  delivery?: boolean;
  vehicle1?: {
    referenceId?: boolean;
    manual?: boolean;
    vin?: boolean;
    stock?: boolean;
    make?: boolean;
    model?: boolean;
    year?: boolean;
    color?: boolean;
  };
  vehicle2?: {
    referenceId?: boolean;
    manual?: boolean;
    vin?: boolean;
    stock?: boolean;
    make?: boolean;
    model?: boolean;
    year?: boolean;
    color?: boolean;
  };
  additional1?: {
    contact?: boolean;
    notes?: boolean;
  };
  additional2?: {
    contact?: boolean;
    notes?: boolean;
  };
}

export interface Consumer {
  name?: string | null;
  phone?: string | null;
  action?: string | null;
  first?: string | null;
}

export interface Vehicle {
  referenceId?: string | null;
  manual?: boolean;
  vin?: string | null;
  stock?: string | null;
  make?: string | null;
  model?: string | null;
  year?: string | null;
  color?: string | null;
}

export interface Additional {
  contact?: string | null;
  notes?: string | null;
}

// DEFAULTS ---------------------------------------------------------------- //

export const defaultValidation: Validation = {
  rooftopId: true,
  payerId: true,
  workflowsetId: true,
  type: true,
  emails: true,
  consumer: {
    name: true,
    phone: true,
    action: true,
    first: true,
  },
  time: true,
  lane: true,
  pickup: true,
  delivery: true,
  vehicle1: {
    referenceId: true,
    manual: true,
    vin: true,
    stock: true,
    make: true,
    model: true,
    year: true,
    color: true,
  },
  vehicle2: {
    referenceId: true,
    manual: true,
    vin: true,
    stock: true,
    make: true,
    model: true,
    year: true,
    color: true,
  },
  additional1: {
    contact: true,
    notes: true,
  },
  additional2: {
    contact: true,
    notes: true,
  },
};

export const defaultType: string = ``;

export const defaultEmails: string[] = [];

export const defaultConsumer: Consumer = {
  name: ``,
  phone: ``,
  action: `pickup`,
  first: `loaner`,
};

export const defaultTime: string = ``;

export const defaultIsDefaultTime: boolean = false;

export const defaultLane: Lanes | null = null;

export const defaultLocation: Locations | null = null;

export const defaultMove1Title: string = `MOVE 1`;

export const defaultMove2Title: string = `MOVE 2`;

export const defaultVehicle: Vehicle = {
  referenceId: ``,
  manual: false,
  vin: ``,
  stock: ``,
  make: ``,
  model: ``,
  year: ``,
  color: ``,
};

export const defaultAdditional: Additional = {
  contact: ``,
  notes: ``,
};

// COMPONENT ---------------------------------------------------------------- //

export const MovePlannerContext = React.createContext<MovePlannerContextType | null>(null);

export const MovePlannerProvider = ({ children, state }: MovePlannerProviderProps) => {
  const user = useUser();
  const hasAdminPrivileges = checkForPowerRole(user);

  const navigate = useNavigate();

  // Queries
  const [getRooftops] = useGetRooftopsLazyQuery();
  const [getPayers] = useGetPayersLazyQuery();
  const [getWorkflowsets] = useGetWorkflowsetsLazyQuery();
  const [getLanes] = useGetMovePlannerLanesLazyQuery();
  const [getLocations] = useGetLocationsLazyQuery();
  const [getMovesFromProps] = useGetMovePlannerDefaultMovesLazyQuery();

  // Mutations
  const [upsertLanes] = useUpsertMovePlannerLanesMutation();
  const [insertMoves] = useInsertMovePlannerMovesMutation();

  // Options State
  const [movePlannerLocked, setMovePlannerLocked] = React.useState<boolean>(false);
  const [showEmailNotifications, setShowEmailNotifications] = React.useState<boolean>(false);
  const [showDistanceEstimates, setShowDistanceEstimates] = React.useState<boolean>(false);
  const [showPriceEstimates, setShowPriceEstimates] = React.useState<boolean>(false);

  // Validation State
  const [validation, setValidation] = React.useState<Validation>(defaultValidation);

  // Store State
  const rooftopStore = useRooftop();
  const [rooftopConfig, setRooftopConfig] = React.useState<any>({});

  // Data State
  const [rooftops, setRooftops] = React.useState<Customers[]>([]);
  const [payers, setPayers] = React.useState<Customers[]>([]);
  const [workflowsets, setWorkflowsets] = React.useState<Workflowsets[]>([]);
  const [lanes, setLanes] = React.useState<Lanes[]>([]);
  const [locations, setLocations] = React.useState<Locations[]>([]);

  // ID State (Selected IDs from datasets)
  const [rooftopId, setRooftopId] = React.useState<number>(0);
  const [payerId, setPayerId] = React.useState<number>(0);
  const [workflowsetId, setWorkflowsetId] = React.useState<number>(0);

  // Form State
  const [type, setType] = React.useState<string>(defaultType);
  const [emails, setEmails] = React.useState<string[]>(defaultEmails);
  const [consumer, setConsumer] = React.useState<Consumer>(defaultConsumer);
  const [time, setTime] = React.useState<string>(defaultTime);
  const [isDefaultTime, setIsDefaultTime] = React.useState<boolean>(defaultIsDefaultTime);
  const [lane, setLane] = React.useState<Lanes | null>(defaultLane);
  const [pickup, setPickup] = React.useState<Locations | null>(defaultLocation);
  const [delivery, setDelivery] = React.useState<Locations | null>(defaultLocation);
  const [move1Title, setMove1Title] = React.useState<string>(defaultMove1Title);
  const [move2Title, setMove2Title] = React.useState<string>(defaultMove2Title);
  const [vehicle1, setVehicle1] = React.useState<Vehicle>(defaultVehicle);
  const [vehicle2, setVehicle2] = React.useState<Vehicle>(defaultVehicle);
  const [additional1, setAdditional1] = React.useState<Additional>(defaultAdditional);
  const [additional2, setAdditional2] = React.useState<Additional>(defaultAdditional);
  const [earliestAvailableTime, setEarliestAvailableTime] = React.useState<string>(defaultTime);

  // Load/Save State
  // const [loading, setLoading] = React.useState<boolean>(false);
  const [saving, setSaving] = React.useState<boolean>(false);

  // Fetch/Refetch the rooftops on mount
  React.useEffect(() => {
    const fetchRooftops = async () => {
      const res = await getRooftops();
      const resRooftops: Customers[] = res?.data?.customers || [];

      setRooftops(resRooftops);
    };
    fetchRooftops();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch/Refetch the allowed payers when the user changes
  React.useEffect(() => {
    const fetchPayers = async () => {
      const payersArray = user?.claims?.['x-hasura-allowed-payers'] || [];

      if (payersArray?.length) {
        const res = await getPayers({ variables: { payersArray } });
        const resPayers: Customers[] = res?.data?.customers || [];

        setPayers(resPayers);
      } else {
        setPayers([]);
      }
    };
    fetchPayers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // Fetch/Refetch the workflowsets, lanes & locations when the rooftop ID changes
  React.useEffect(() => {
    const fetchWorkflowsets = async () => {
      if (rooftopId) {
        const res = await getWorkflowsets({ variables: { rooftopId } });
        let resWorkflowsets = (res?.data?.workflowsets || []) as Workflowsets[];

        setWorkflowsets(resWorkflowsets);
      } else {
        setWorkflowsets([]);
      }
    };
    const fetchLanes = async () => {
      if (rooftopId) {
        const res = await getLanes({ variables: { rooftopId } });
        const resLanes: Lanes[] = res?.data?.lanes || [];

        setLanes(resLanes);
      } else {
        setLanes([]);
      }
    };
    const fetchLocations = async () => {
      if (rooftopId) {
        const res = await getLocations({ variables: { rooftopId } });
        const resLocations: Locations[] = res?.data?.locations || [];

        setLocations(resLocations);
      } else {
        setLocations([]);
      }
    };
    fetchWorkflowsets();
    fetchLanes();
    fetchLocations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rooftopId, rooftopConfig?.public_workflowsets]);

  // Fetch/Refetch the initial moves when the prop state changes
  React.useEffect(() => {
    const fetchMovesFromProps = async () => {
      // Initialize variables
      const moveIds = [];
      if (state?.moveId1) moveIds.push(state?.moveId1);
      if (state?.moveId2) moveIds.push(state?.moveId2);
      const isInverse = state?.inverse || false;

      // Query the moves
      const res = await getMovesFromProps({ variables: { moveIds } });
      const resMoves: Moves[] = res?.data?.moves || [];

      // Set the form fields based on the moves that came in
      if (resMoves?.length) {
        // Initialize the moves
        const move1 = resMoves?.find(m =>
          !state?.moveId2 ? m?.id === state?.moveId1 : isInverse ? m?.id === state?.moveId2 : m?.id === state?.moveId1
        );
        const move2 = resMoves?.find(m =>
          !state?.moveId2 ? undefined : isInverse ? m?.id === state?.moveId1 : m?.id === state?.moveId2
        );

        // If we dont have any moves, return
        if (!move1 && !move2) return;

        // Find the type of the moves
        let foundType = defaultType;

        if (move1?.consumer_type === `loaner` || move2?.consumer_type === `loaner`) {
          foundType = `concierge-loaner`;
        } else if (move1?.consumer_type === `customer`) {
          foundType = `concierge`;
        } else if (move1 && move2) {
          foundType = `round-trip`;
        } else {
          foundType = `one-way`;
        }

        // Find the consumer info
        const foundConsumer = defaultConsumer;

        if (move1?.consumer_name || move2?.consumer_name) {
          foundConsumer.name = move1?.consumer_name || move2?.consumer_name;
        }

        if (move1?.consumer_phone || move2?.consumer_phone) {
          foundConsumer.phone = move1?.consumer_phone || move2?.consumer_phone;
        }

        if (
          (move1?.consumer_type === `loaner` && move1?.consumer_at_pickup === 1) ||
          (move1?.consumer_type === `customer` && move1?.consumer_at_pickup === 0)
        ) {
          foundConsumer.action = isInverse ? `pickup` : `return`;
        } else {
          foundConsumer.action = isInverse ? `return` : `pickup`;
        }

        if (move1?.consumer_type === `customer`) {
          foundConsumer.first = `consumer`;
        } else {
          foundConsumer.first = `loaner`;
        }

        // Find the lanes
        let foundLane = defaultLane;
        if (move1 && !move2) {
          foundLane = isInverse ? move1?.lane?.inverse : move1?.lane;
        } else {
          foundLane = move1?.lane;
        }

        // Set the state
        setRooftopId(move1?.customer_id || 0);
        setPayerId(move1?.payer_id || 0);
        setWorkflowsetId(move1?.workflowset_id || 0);

        setType(foundType || defaultType);
        setEmails(move1?.config?.emailsToNotify || defaultEmails);
        setConsumer(foundConsumer || defaultConsumer);
        setLane(foundLane || defaultLane);
        setPickup(foundLane?.pickup || defaultLocation);
        setDelivery(foundLane?.delivery || defaultLocation);
        setVehicle1({
          referenceId: move1?.reference_num || ``,
          manual: move1?.manual_flag || false,
          vin: move1?.vehicle_vin || ``,
          stock: move1?.vehicle_stock || ``,
          make: move1?.vehicle_make || ``,
          model: move1?.vehicle_model || ``,
          year: move1?.vehicle_year || ``,
          color: move1?.vehicle_color || ``,
        });
        setAdditional1({
          contact: move1?.dealer_contact || ``,
          notes: move1?.move_details || ``,
        });
        setVehicle2({
          referenceId: move2?.reference_num || ``,
          manual: move2?.manual_flag || false,
          vin: move2?.vehicle_vin || ``,
          stock: move2?.vehicle_stock || ``,
          make: move2?.vehicle_make || ``,
          model: move2?.vehicle_model || ``,
          year: move2?.vehicle_year || ``,
          color: move2?.vehicle_color || ``,
        });
        setAdditional2({
          contact: move2?.dealer_contact || ``,
          notes: move2?.move_details || ``,
        });
      }
    };

    if (state?.moveId1 || (state?.moveId1 && state?.moveId2)) {
      fetchMovesFromProps();
    }

    if (state?.appointment) {
      // Initialize lane data from the appointment
      setPickup(state?.appointment?.pickup || null);
      setDelivery(state?.appointment?.delivery || null);
      setLane(state?.appointment?.lane || null);

      // Update locations array if needed
      const newLocations = [];
      if (state.appointment.pickup) {
        newLocations.push(state.appointment.pickup);
      }
      if (state.appointment.delivery) {
        newLocations.push(state.appointment.delivery);
      }
      if (newLocations.length > 0) {
        setLocations(newLocations);
      }

      setEmails(state?.appointment?.config?.emailsToNotify || defaultEmails);
      setWorkflowsetId(state?.appointment?.workflow_set_id || 1);
      setVehicle1({
        referenceId: state?.appointment?.config?.claim_number || '',
        manual: state?.appointment?.vehicle_manual || false,
        vin: state?.appointment?.vehicle_vin || '',
        make: state?.appointment?.vehicle_make || '',
        model: state?.appointment?.vehicle_model || '',
        year: String(state?.appointment?.vehicle_year || ''),
        color: state?.appointment?.vehicle_color || '',
      });
      setAdditional1({
        notes: state?.appointment?.driver_notes || '',
      });
      setConsumer({
        name: state?.appointment?.consumer_name || '',
        phone: state?.appointment?.consumer_phone || '',
        action: state?.appointment?.consumer_at_pickup ? 'pickup' : 'return',
      });
      setTime(state?.appointment?.appointment_time || '');
      setIsDefaultTime(false);
      setType('concierge');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // When the rooftop store changes, update the rooftop ID with it
  React.useEffect(() => {
    if (rooftopStore && !rooftopId) {
      setRooftopId(rooftopStore?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rooftopStore]);

  // Update rooftop config and locations
  const updateRooftopConfigAndLocations = async (rooftops: Customers[], rooftopId: number) => {
    try {
      const userToken = await user?.getToken();
      if (!userToken) throw new Error(`User token not found`);

      let config = {};

      if (rooftops?.length && rooftopId) {
        const selectedRooftop: Customers = rooftops?.find((r: Customers) => r?.id === rooftopId);
        if (selectedRooftop) {
          config = await enrichCustomerConfig(
            userToken,
            selectedRooftop?.config,
            selectedRooftop?.organization?.config
          );
        }
      }

      if (config && Object.keys(config)?.length) {
        const defaultWorkflowsetIdOverride = config?.public_workflowsets === false ? 0 : 1;

        setMovePlannerLocked(config?.add_moves_always ? false : config?.add_moves_disabled);
        setShowEmailNotifications(state?.appointment ? true : config?.allow_email_notifications);
        setShowDistanceEstimates(state?.appointment ? true : config?.distance_estimates);
        setShowPriceEstimates(state?.appointment ? true : config?.price_estimates);
        if (!state) {
          setPayerId(config?.require_third_party_payer && config?.default_payer ? config?.default_payer : null);
          setWorkflowsetId(config?.default_workflow_set_id || defaultWorkflowsetIdOverride);
          setType(config?.default_move_type || defaultType);
          if (config?.allow_email_notifications && user?.profile?.email) {
            setEmails([user?.profile?.email]);
          }
        }
      }

      if (!state) {
        setLane(defaultLane);
        setPickup(defaultLocation);
        setDelivery(defaultLocation);
      }

      setRooftopConfig(config);
    } catch (err) {
      console.error(`Failed to update rooftop config and locations:`, err);
    }
  };

  // When the rooftops list and rooftop ID changes, update the rooftop config, config state and reset the locations
  React.useEffect(() => {
    updateRooftopConfigAndLocations(rooftops, rooftopId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rooftops, rooftopId]);

  // When the type changes, reset the validation
  React.useEffect(() => {
    setValidation(defaultValidation);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  // When the type & consumer object changes, update the titles and default time bool
  React.useEffect(() => {
    if (type === `concierge`) {
      setMove1Title(`CUSTOMER MOVE`);
      setMove2Title(``);
      setIsDefaultTime(false); // Reset default time for concierge
    }
    if (type === `concierge-loaner`) {
      if (consumer?.first === `loaner`) {
        setMove1Title(`LOANER MOVE`);
        setMove2Title(`CUSTOMER MOVE`);
      }
      if (consumer?.first === `consumer`) {
        setMove1Title(`CUSTOMER MOVE`);
        setMove2Title(`LOANER MOVE`);
      }
      setIsDefaultTime(false); // Reset default time for concierge
    }
    if (type === `one-way`) {
      setMove1Title(`MOVE`);
      setMove2Title(``);
    }
    if (type === `round-trip`) {
      setMove1Title(`MOVE 1`);
      setMove2Title(`MOVE 2`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, consumer]);

  // When the time changes, check if the time is within business hours
  React.useEffect(() => {
    if (time && lane && type) {
      const isMovePair = type === `round-trip` || type === `concierge-loaner`;
      checkBusinessHours(lane, dayjs(time), isMovePair);
    }
  }, [time, lane, type]);

  // Check the business hours of the selected locations for the selected time- give a warning and set earliestAvailableTimeMessage
  const checkBusinessHours = async (lane: Lanes, time: dayjs.Dayjs, isMovePair: boolean) => {
    const userToken = await user?.getToken();

    try {
      const response = await fetch('/.netlify/functions/checkBusinessHours', {
        method: 'POST',
        body: JSON.stringify({ lane: lane, ready_by: time.format(), isMovePair: isMovePair }),
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });

      const { duration_sec = 0 } = lane as { duration_sec?: number };

      const businessHoursCheck = await response.json();

      if (!businessHoursCheck.success) {
        console.error('Error checking business hours:', businessHoursCheck.error);
        return;
      }

      if (businessHoursCheck.earliestAvailableTimeMessageOne) {
        // toast.warning(businessHoursCheck.earliestAvailableTimeMessageOne, { toastId: 'business-hours' });
        const formattedEarliestAvailableTime = dayjs(businessHoursCheck.earliestAvailableTimeOne).utc().format();
        setEarliestAvailableTime(formattedEarliestAvailableTime);
        return;
      }
      if (isMovePair && businessHoursCheck.earliestAvailableTimeMessageTwo) {
        // toast.warning(businessHoursCheck.earliestAvailableTimeMessageTwo, { toastId: 'business-hours' });
        const formattedEarliestAvailableTime = dayjs(businessHoursCheck.earliestAvailableTimeTwo)
          .subtract(duration_sec, 'seconds')
          .utc()
          .format();
        setEarliestAvailableTime(formattedEarliestAvailableTime);
        return;
      }
    } catch (error) {
      console.error('Error checking business hours:', error);
    }
  };

  /** Validate each field on the form */
  const validateForm = () => {
    const validity = { ...validation };

    // Validate rooftopId
    if (!rooftopId) validity.rooftopId = false;
    else validity.rooftopId = true;

    // Validate payerId
    if (rooftopConfig?.require_third_party_payer && !payerId) validity.payerId = false;
    else validity.payerId = true;

    // Validate workflowsetId
    if (!workflowsetId) validity.workflowsetId = false;
    else validity.workflowsetId = true;

    // Validate emails
    if (emails?.length > 5) validity.emails = false;
    else validity.emails = true;

    // Validate consumer info
    if (type === `concierge` || type === `concierge-loaner` || state?.appointment) {
      if (!consumer?.name) validity.consumer.name = false;
      else validity.consumer.name = true;
      if (!validatePhone(consumer?.phone)) validity.consumer.phone = false;
      else validity.consumer.phone = true;
    } else {
      validity.consumer.name = true;
      validity.consumer.phone = true;
    }

    // Validate time
    if (!isDefaultTime) {
      if (!time) {
        validity.time = false;
      } else if (state?.appointment) {
        const currentTimePlus120 = dayjs().add(120, 'minute');
        validity.time = !dayjs(time).isBefore(currentTimePlus120);
      } else {
        validity.time = true;
      }
    } else {
      validity.time = true;
    }

    // Validate lane
    if (!pickup) validity.pickup = false;
    else validity.pickup = true;
    if (!delivery) validity.delivery = false;
    else validity.delivery = true;

    // Validate vehicle 1 info
    if (type !== `concierge-loaner` || (type === `concierge-loaner` && consumer?.first === `consumer`)) {
      if (vehicle1?.vin?.length < 6) validity.vehicle1.vin = false;
      else validity.vehicle1.vin = true;
      if (!vehicle1?.year) validity.vehicle1.year = false;
      else validity.vehicle1.year = true;
      if (!vehicle1?.make) validity.vehicle1.make = false;
      else validity.vehicle1.make = true;
      if (!vehicle1?.model) validity.vehicle1.model = false;
      else validity.vehicle1.model = true;
    } else {
      validity.vehicle1.vin = true;
      validity.vehicle1.year = true;
      validity.vehicle1.make = true;
      validity.vehicle1.model = true;
    }

    // Validate vehicle 2 info
    if (type === `round-trip` || (type === `concierge-loaner` && consumer?.first === `loaner`)) {
      if (vehicle2?.vin?.length < 6) validity.vehicle2.vin = false;
      else validity.vehicle2.vin = true;
      if (!vehicle2?.year) validity.vehicle2.year = false;
      else validity.vehicle2.year = true;
      if (!vehicle2?.make) validity.vehicle2.make = false;
      else validity.vehicle2.make = true;
      if (!vehicle2?.model) validity.vehicle2.model = false;
      else validity.vehicle2.model = true;
    } else {
      validity.vehicle2.vin = true;
      validity.vehicle2.year = true;
      validity.vehicle2.make = true;
      validity.vehicle2.model = true;
    }

    // Set the validation state
    setValidation(validity);

    // Go through the validation object and check if all fields are valid
    let isValid = true;
    for (const key in validity) {
      if (typeof validity?.[key] === `object`) {
        for (const subKey in validity?.[key]) {
          if (!validity?.[key]?.[subKey]) {
            isValid = false;
            break;
          }
        }
      } else {
        if (!validity?.[key]) {
          isValid = false;
          break;
        }
      }
    }

    // Return if the form is valid or not
    return isValid;
  };

  /** Orchestration to save the lanes and moves */
  const finalizePlan = async () => {
    setSaving(true);

    // Check validity of all inputs the user has control over
    const isFormValid = validateForm();

    // If the form is not valid, do not submit and alert the user
    if (!isFormValid) {
      toast.warning(`Please fill out all required fields before submitting!`, { toastId: `move-planner-validation` });
      setSaving(false);
      return false;
    }

    // Be sure both the pickup and delivery have a region ID before saving
    if (!pickup?.region_id && !delivery?.region_id) {
      if (!pickup?.region_id) console.error(`Pickup location is missing a region ID.`);
      if (!delivery?.region_id) console.error(`Delivery location is missing a region ID.`);
      toast.error(
        `At least one of the selected locations must be within the Hopdrive service area! It is possible a location is slightly outside of our area. If you believe this is the case, please contact us.`,
        {
          toastId: `move-planner-submit`,
          autoClose: false,
        }
      );
      setSaving(false);
      return false;
    }

    // Be sure there is a lane and inverse lane before saving
    let mainLane: Lanes | null = null;
    let inverseLane: Lanes | null = null;
    try {
      mainLane = lanes?.find(l => l?.pickup?.id === pickup?.id && l?.delivery?.id === delivery?.id);
      inverseLane = lanes?.find(l => l?.pickup?.id === delivery?.id && l?.delivery?.id === pickup?.id);
      if (!mainLane || !inverseLane) throw new Error(`One or both lanes were not found in the lanes array.`);
      log && console.log(`Main Lane Detected:`, mainLane);
      log && console.log(`Inverse Lane Detected:`, inverseLane);
    } catch (err) {
      console.error(`Failed to detect lanes needed for move creation:`, err);
      toast.error(`Failed to plan moves! If the problem persists, please contact us.`, {
        toastId: `move-planner-submit`,
        autoClose: false,
      });
      setSaving(false);
      return false;
    }

    // If the lanes have a pickup and delivery object on them, delete those objects so the upsert will work
    try {
      mainLane = JSON.parse(JSON.stringify(mainLane)) as Lanes;
      inverseLane = JSON.parse(JSON.stringify(inverseLane)) as Lanes;
      if (mainLane?.pickup) delete mainLane.pickup;
      if (mainLane?.delivery) delete mainLane.delivery;
      if (inverseLane?.pickup) delete inverseLane.pickup;
      if (inverseLane?.delivery) delete inverseLane.delivery;
    } catch (err) {
      console.error(`Failed to remove pickup and delivery from lane:`, err);
      toast.error(`Failed to plan moves! If the problem persists, please contact us.`, {
        toastId: `move-planner-submit`,
        autoClose: false,
      });
      setSaving(false);
      return false;
    }

    // Upsert the lanes if one or both are missing an ID
    if (!mainLane?.id || !inverseLane?.id) {
      try {
        const upsertableLanes: Lanes[] = [mainLane, inverseLane];
        const upsertRes = await upsertLanes({ variables: { upsertableLanes } });
        const upsertedLanes = upsertRes?.data?.insert_lanes?.returning;
        if (upsertedLanes?.length) {
          const mutableLanes: Lanes[] = JSON.parse(JSON.stringify(upsertedLanes));
          mainLane = mutableLanes?.find(l => l?.pickup?.id === pickup?.id && l?.delivery?.id === delivery?.id);
          inverseLane = mutableLanes?.find(l => l?.pickup?.id === delivery?.id && l?.delivery?.id === pickup?.id);
          log && console.log(`Main Lane Upserted:`, mainLane);
          log && console.log(`Inverse Lane Upserted:`, inverseLane);
        } else throw new Error(`No upserted lanes were returned.`);
      } catch (err) {
        console.error(`Failed to save lanes:`, err);
        toast.error(`Failed to plan moves! If the problem persists, please contact us.`, {
          toastId: `move-planner-submit`,
          autoClose: false,
        });
        setSaving(false);
        return false;
      }
    }

    // Build the insertable moves array
    let insertableMoves: Moves[] = [];
    try {
      insertableMoves = await buildMovesToInsert(
        {
          rooftopId,
          payerId,
          workflowsetId,
          pickupWorkflowId: workflowsets?.find(w => w?.id === workflowsetId)?.pickup_workflow_id,
          deliveryWorkflowId: workflowsets?.find(w => w?.id === workflowsetId)?.delivery_workflow_id,
          slaId: workflowsets?.find(w => w?.id === workflowsetId)?.raterulegroups?.[0]?.sla?.id,
          slaHrs: workflowsets?.find(w => w?.id === workflowsetId)?.raterulegroups?.[0]?.sla?.duration_hrs,
          type,
          emails,
          consumerName: consumer?.name,
          consumerPhone: consumer?.phone,
          consumerAction: consumer?.action,
          consumerFirst: consumer?.first,
          earliestAvailableTime: earliestAvailableTime,
          time,
          mainLane,
          inverseLane,
          vehicleReferenceId1: vehicle1?.referenceId,
          vehicleManual1: vehicle1?.manual,
          vehicleVin1: vehicle1?.vin,
          vehicleStock1: vehicle1?.stock,
          vehicleMake1: vehicle1?.make,
          vehicleModel1: vehicle1?.model,
          vehicleYear1: vehicle1?.year,
          vehicleColor1: vehicle1?.color,
          contact1: additional1?.contact,
          notes1: additional1?.notes,
          vehicleReferenceId2: vehicle2?.referenceId,
          vehicleManual2: vehicle2?.manual,
          vehicleVin2: vehicle2?.vin,
          vehicleStock2: vehicle2?.stock,
          vehicleMake2: vehicle2?.make,
          vehicleModel2: vehicle2?.model,
          vehicleYear2: vehicle2?.year,
          vehicleColor2: vehicle2?.color,
          contact2: additional2?.contact,
          notes2: additional2?.notes,
        },
        user
      );
      log && console.log(`Moves Built:`, insertableMoves);
    } catch (err) {
      console.error(`Failed to build moves:`, err);
      toast.error(`Failed to plan moves! If the problem persists, please contact us.`, {
        toastId: `move-planner-submit`,
        autoClose: false,
      });
      setSaving(false);
      return false;
    }

    // If we have a pair of moves to insert, nest the child in the parent
    if (insertableMoves?.length === 2) {
      insertableMoves[0].childMoves = { data: [insertableMoves[1]] };
      insertableMoves.pop();
    }

    // Save the insertable moves array to the database
    try {
      const res = await insertMoves({ variables: { insertableMoves } });
      log && console.log(`Moves Saved:`, res);
      toast.success(
        `Your move(s) have been planned! A dispatcher will assign it to a driver around the time it's ready.`,
        { toastId: `move-planner-submit` }
      );
    } catch (err) {
      console.error(`Failed to save moves:`, err);
      toast.error(`Failed to plan moves! If the problem persists, please contact us.`, {
        toastId: `move-planner-submit`,
        autoClose: false,
      });
      setSaving(false);
      return false;
    }

    setSaving(false);
    return true;
  };

  /** Handle the submission of the form, then navigate to the dashboard */
  const handleSubmit = async () => {
    const success = await finalizePlan();
    if (success) navigate(`/dashboard`);
  };

  /** Handle the submission of the form, prevent navigation and keep most of the inputs */
  const handleSubmitAndAddAnother = async () => {
    await finalizePlan();
  };

  return (
    <MovePlannerContext.Provider
      value={{
        hasAdminPrivileges,
        movePlannerLocked,
        showEmailNotifications,
        showDistanceEstimates,
        showPriceEstimates,
        validation,
        rooftopConfig,
        rooftops,
        payers,
        workflowsets,
        lanes,
        locations,
        rooftopId,
        payerId,
        workflowsetId,
        type,
        emails,
        consumer,
        time,
        isDefaultTime,
        lane,
        pickup,
        delivery,
        move1Title,
        move2Title,
        vehicle1,
        vehicle2,
        additional1,
        additional2,
        saving,
        setValidation,
        setRooftopConfig,
        setRooftops,
        setPayers,
        setWorkflowsets,
        setLanes,
        setLocations,
        setRooftopId,
        setPayerId,
        setWorkflowsetId,
        setType,
        setEmails,
        setConsumer,
        setTime,
        setIsDefaultTime,
        setLane,
        setPickup,
        setDelivery,
        setMove1Title,
        setMove2Title,
        setVehicle1,
        setVehicle2,
        setAdditional1,
        setAdditional2,
        setSaving,
        handleSubmit,
        handleSubmitAndAddAnother,
        validateForm,
        defaultValidation,
        defaultLocation,
        defaultLane,
        defaultVehicle,
        defaultAdditional,
        defaultConsumer,
        defaultTime,
        defaultIsDefaultTime,
      }}
    >
      {rooftopStore?.id && rooftops?.length ? children : <LinearProgress />}
    </MovePlannerContext.Provider>
  );
};
