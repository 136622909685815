// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { Locations } from '@gql/schema';

import { css } from '@emotion/css';
import { Paper, Typography, Theme, useTheme, Tooltip } from '@mui/material';

import Spacer from '@components/Utils/Spacer';
import InfoItem from '@components/InfoItem';

// TYPES ---------------------------------------------------------------- //

interface LocationDetailsInfoProps {
  location: Locations;
}

// COMPONENT ---------------------------------------------------------------- //

const LocationDetailsInfo: React.FC<LocationDetailsInfoProps> = ({ location }) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const getType = () => {
    if (location.type === `customer`) return `Standard`;
    else if (location.type === `consumer business`) return `Consumer Business`;
    else if (location.type === `consumer residential`) return `Consumer Residential`;
    else return `-`;
  };

  return (
    <Paper variant='custom' className={cls.paper}>
      <Tooltip title='HopDrive Location ID' placement='top'>
        <Typography variant='h5' sx={{ lineHeight: 1, fontWeight: 600 }}>
          #{location?.id}
        </Typography>
      </Tooltip>

      <Spacer />

      <div className={cls.sectionFlex}>
        <div className={cls.sectionFlexItem}>
          <InfoItem keyText={`Name`} valueText={location?.name || `N/A`} />
          <InfoItem keyText={`Address`} valueText={location?.address || `N/A`} />
          <InfoItem keyText={`Nickname`} valueText={location?.nickname || `N/A`} />
          <InfoItem keyText={`Email`} valueText={location?.email || `N/A`} />
          <InfoItem keyText={`Phone`} valueText={location?.phone || `N/A`} />
        </div>

        <div className={cls.sectionFlexItem}>
          <InfoItem keyText={`Market`} valueText={location?.customer?.name || `N/A`} />
          <InfoItem keyText={`Type`} valueText={getType() || `N/A`} />
          <InfoItem keyText={`Latitude`} valueText={location?.latitude || `N/A`} />
          <InfoItem keyText={`Longitude`} valueText={location?.longitude || `N/A`} />
          <InfoItem keyText={`Timezone`} valueText={location?.timezone || `N/A`} />
        </div>
      </div>
    </Paper>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  paper: css`
    width: 100%;
    padding: ${theme?.spacing(2)};
  `,

  sectionFlex: css`
    display: flex;
    gap: 20px;
    ${theme?.breakpoints?.down('md')} {
      flex-direction: column;
      gap: 6px;
    }
  `,
  sectionFlexItem: css`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;
    word-break: break-word;
  `,

  listItem: css`
    display: flex;
    flex-wrap: nowrap;
  `,
  keyTxt: css`
    color: ${theme?.palette.text.secondary};
    text-align: left;
    line-height: 1.25;
    font-size: 14px;
    font-weight: 400;
    @media (max-width: ${theme?.breakpoints.values.sm}px) {
      font-size: 13px;
    }
    @media (max-width: ${theme?.breakpoints.values.xs}px) {
      font-size: 12px;
    }
  `,
  valTxt: css`
    width: 100%;
    text-align: right;
    line-height: 1.25;
    font-size: 14px;
    font-weight: 400;
    @media (max-width: ${theme?.breakpoints.values.sm}px) {
      font-size: 13px;
    }
    @media (max-width: ${theme?.breakpoints.values.xs}px) {
      font-size: 12px;
    }
  `,
  lineBreak: css`
    width: 100%;
    height: 1px;
    margin-top: 6px;
    margin-bottom: 8px;
    background: #e0e0e0;
  `,
  hiddenBreak: css`
    display: none;
    width: 100%;
    height: ${theme?.spacing(2)};
    @media (max-width: ${theme?.breakpoints.values.sm}px) {
      display: block;
    }
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default LocationDetailsInfo;
