import React from 'react';
import { toast } from 'react-toastify';
import { Typography, LinearProgress, Theme, SxProps, useTheme } from '@mui/material';
import Spacer from '@components/Utils/Spacer';
import { ModalHeader, ModalContent, ModalFooter, ModalAction } from '@components/Modal';

import { useUserDetails } from '@hooks/useUserDetails';

//////////////////////// COMPONENT ////////////////////////

export default function ReinstateModalContent({ input, onClose }: { input: any, onClose: () => void }) {
  const theme = useTheme();
  const cls = useSxStyles(theme);
  const ctx = useUserDetails();

  const [isSaving, setIsSaving] = React.useState(false);

  // Handle submitting the form
  const handleSubmit = async () => {
    const res = await ctx.reinstateUser(input.user.id);
    if (res) {
      toast.success(`Successfully reinstated user!`, { autoClose: 2500 });
      if (onClose) onClose();
    } else {
      toast.error(`Failed to reinstate user, please contact an admin!`);
    }

    setIsSaving(false);
  };

  return (
    <>
      <ModalHeader handleClose={onClose}>Reinstate User</ModalHeader>

      <ModalContent>
        <Typography sx={cls.subtitle}>
          By clicking "submit" below, the user's login credentials will be reinstated. They will receive an email prompting them to set a new password.
        </Typography>

        <Spacer />
         
      </ModalContent>

      <div>{isSaving ? <LinearProgress /> : null}</div>

      <ModalFooter>
        <ModalAction
          loading={isSaving}
          disabled={isSaving}
          color='primary'
          onClick={() => {
            setIsSaving(true);
            handleSubmit();
          }}
        >
          Submit
        </ModalAction>

        <ModalAction onClick={() => onClose()}>Close</ModalAction>
      </ModalFooter>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  subtitle: {
    lineHeight: 1.333,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
});