import dayjs from 'dayjs';
import { Lanes, Locations, Moves, Slas } from '@gql/schema';
import { User } from '@features/auth/services/authService';

interface MovesData {
  rooftopId?: number;
  payerId?: number;
  workflowsetId?: number;
  pickupWorkflowId?: number;
  deliveryWorkflowId?: number;
  slaId?: number;
  slaHrs?: number;
  type?: string;
  emails?: string[];
  consumerName?: string | null;
  consumerPhone?: string | null;
  consumerAction?: string | null;
  consumerFirst?: string | null;
  time?: string;
  earliestAvailableTime?: string;
  mainLane?: Lanes | null;
  inverseLane?: Lanes | null;
  vehicleReferenceId1?: string | null;
  vehicleManual1?: boolean;
  vehicleVin1?: string | null;
  vehicleStock1?: string | null;
  vehicleMake1?: string | null;
  vehicleModel1?: string | null;
  vehicleYear1?: string | null;
  vehicleColor1?: string | null;
  contact1?: string | null;
  notes1?: string | null;
  vehicleReferenceId2?: string | null;
  vehicleManual2?: boolean;
  vehicleVin2?: string | null;
  vehicleStock2?: string | null;
  vehicleMake2?: string | null;
  vehicleModel2?: string | null;
  vehicleYear2?: string | null;
  vehicleColor2?: string | null;
  contact2?: string | null;
  notes2?: string | null;
}

/** Export the default operational SLA */
export const defaultSla: Slas = {
  id: 1,
  name: `default`,
  label: `HopDrive Default`,
  description: `The HopDrive default SLA for operational moves.`,
  duration_hrs: 48,
  public: true,
};

/** Export the default concierge SLA */
export const defaultConciergeSla: Slas = {
  id: 2,
  name: `default_concierge`,
  label: `HopDrive Concierge`,
  description: `The HopDrive default SLA for concierge moves (Completed no later than one hour after the appointment time).`,
  duration_hrs: 1,
  public: true,
};

/** Build a cleansed version of the locations array */
export const cleanseLocations = (locations?: Locations[], excludeList?: number[]) => {
  if (!locations?.length && !excludeList?.length) return [];

  const cleansedLocations = [...locations]
    ?.filter(l => (excludeList?.includes(l?.id) ? false : true))
    ?.sort((a, b) => {
      if (a?.favorite && !b?.favorite) return -1;
      if (!a?.favorite && b?.favorite) return 1;
      if (a?.favorite === b?.favorite) {
        if (a?.name < b?.name) return -1;
        if (a?.name > b?.name) return 1;
      }
      return 0;
    });
  return cleansedLocations;
};

/** Get a list of years from the last 50 years, plus the next 2 years */
export const getYears = () => {
  const currentYear = new Date().getFullYear();
  const yearList = Array.from({ length: 53 }, (_, i) => (currentYear - 50 + i).toString()).reverse();
  return yearList;
};

/** Get a list of car paint colors */
export const getColors = () => [
  `Beige`,
  `Black`,
  `Blue`,
  `Brown`,
  `Gold`,
  `Gray`,
  `Green`,
  `Maroon`,
  `Orange`,
  `Pink`,
  `Purple`,
  `Red`,
  `Silver`,
  `Tan`,
  `White`,
  `Yellow`,
];

/** Orchestration to build insertable moves from the data we have */
export const buildMovesToInsert = async (data?: MovesData, user?: User) => {
  // If we don't have data, return an empty array of moves
  if (!data) return [];

  // Initialize both moves
  let move1: Moves = {};
  let move1Config: any = null;
  let move2: Moves = {};
  let move2Config: any = null;

  // Get the emails to notify if present
  const calculatedEmails = data?.emails?.length ? data?.emails : null;
  if (calculatedEmails) {
    move1Config = { ...move1Config, emailsToNotify: calculatedEmails };
    move2Config = { ...move2Config, emailsToNotify: calculatedEmails };
  }

  // Get the consumer info if needed
  let calculatedConsumerName = null;
  let calculatedConsumerPhone = null;
  let calculatedConsumerPickup1 = false;
  let calculatedConsumerPickup2 = false;
  let calculatedConsumerAtPickup1 = 0;
  let calculatedConsumerAtPickup2 = 0;
  let calculatedConsumerType1 = null;
  let calculatedConsumerType2 = null;

  if (data?.type === `concierge` || data?.type === `concierge-loaner`) {
    calculatedConsumerPickup1 = true;
    calculatedConsumerPickup2 = true;
    calculatedConsumerName = data?.consumerName || null;
    calculatedConsumerPhone = data?.consumerPhone || null;
    if (data?.type === `concierge` && data?.consumerAction === `pickup`) {
      calculatedConsumerAtPickup1 = 1;
      calculatedConsumerType1 = `customer`;
    }
    if (data?.type === `concierge` && data?.consumerAction === `return`) {
      calculatedConsumerAtPickup1 = 0;
      calculatedConsumerType1 = `customer`;
    }
    if (data?.type === `concierge-loaner` && data?.consumerAction === `pickup` && data?.consumerFirst === `loaner`) {
      calculatedConsumerAtPickup1 = 0;
      calculatedConsumerAtPickup2 = 1;
      calculatedConsumerType1 = `loaner`;
      calculatedConsumerType2 = `customer`;
    }
    if (data?.type === `concierge-loaner` && data?.consumerAction === `pickup` && data?.consumerFirst === `consumer`) {
      calculatedConsumerAtPickup1 = 1;
      calculatedConsumerAtPickup2 = 0;
      calculatedConsumerType1 = `customer`;
      calculatedConsumerType2 = `loaner`;
    }
    if (data?.type === `concierge-loaner` && data?.consumerAction === `return` && data?.consumerFirst === `loaner`) {
      calculatedConsumerAtPickup1 = 1;
      calculatedConsumerAtPickup2 = 0;
      calculatedConsumerType1 = `loaner`;
      calculatedConsumerType2 = `customer`;
    }
    if (data?.type === `concierge-loaner` && data?.consumerAction === `return` && data?.consumerFirst === `consumer`) {
      calculatedConsumerAtPickup1 = 0;
      calculatedConsumerAtPickup2 = 1;
      calculatedConsumerType1 = `customer`;
      calculatedConsumerType2 = `loaner`;
    }
  }

  // Get the expected pickup and delivery times
  const baseTime = data?.time ? dayjs(data?.time) : dayjs().add(90, `minute`);
  const duration = data?.mainLane?.duration_sec || 0;
  const timeMinusLane = baseTime.subtract(duration, `second`);
  const time = baseTime;
  const timePlusLane = baseTime.add(duration, `second`);
  const timePlusLaneX2 = baseTime.add(duration * 2, `second`);

  let calculatedStartTime1 = null;
  let calculatedEndTime1 = null;
  let calculatedStartTime2 = null;
  let calculatedEndTime2 = null;

  if (data?.type === `concierge` || data?.type === `one-way` || data?.type === `round-trip`) {
    calculatedStartTime1 = time;
    calculatedEndTime1 = timePlusLane;
  }
  if (data?.type === `round-trip`) {
    calculatedStartTime2 = timePlusLane;
    calculatedEndTime2 = timePlusLaneX2;
  }
  if (data?.type === `concierge-loaner`) {
    if (
      (data?.consumerAction === `pickup` && data?.consumerFirst === `loaner`) ||
      (data?.consumerAction === `return` && data?.consumerFirst === `consumer`)
    ) {
      calculatedStartTime1 = timeMinusLane;
      calculatedEndTime1 = time;
      calculatedStartTime2 = time;
      calculatedEndTime2 = timePlusLane;
    }
    if (
      (data?.consumerAction === `pickup` && data?.consumerFirst === `consumer`) ||
      (data?.consumerAction === `return` && data?.consumerFirst === `loaner`)
    ) {
      calculatedStartTime1 = time;
      calculatedEndTime1 = timePlusLane;
      calculatedStartTime2 = timePlusLane;
      calculatedEndTime2 = timePlusLaneX2;
    }
  }

  // Get the expected SLA deadline and duration
  const calculatedSlaId1 = calculatedConsumerPickup1 ? defaultConciergeSla?.id : data?.slaId || defaultSla?.id;
  const calculatedSlaId2 = calculatedConsumerPickup2 ? defaultConciergeSla?.id : data?.slaId || defaultSla?.id;
  const calculatedSlaHrs1 = calculatedConsumerPickup1
    ? defaultConciergeSla?.duration_hrs
    : data?.slaHrs || defaultSla?.duration_hrs;
  const calculatedSlaHrs2 = calculatedConsumerPickup2
    ? defaultConciergeSla?.duration_hrs
    : data?.slaHrs || defaultSla?.duration_hrs;

  // Get the class and class override
  const calculatedClass = data?.type === `round-trip` ? `base` : `stranded`;
  const calculatedClassOverride = data?.type === `concierge-loaner` || data?.type === `round-trip` ? 1 : 0;

  // Build the first move
  move1 = {
    ...move1,
    customer_id: data?.rooftopId || null,
    payer_id: !data?.payerId || data?.rooftopId === data?.payerId ? null : data?.payerId,
    workflowset_id: data?.workflowsetId || 1,
    pickup_workflow_id: data?.pickupWorkflowId || 1,
    delivery_workflow_id: data?.deliveryWorkflowId || 2,
    sla_id: calculatedSlaId1,
    consumer_pickup: calculatedConsumerPickup1,
    consumer_name: calculatedConsumerName,
    consumer_phone: calculatedConsumerPhone,
    consumer_at_pickup: calculatedConsumerAtPickup1,
    consumer_type: calculatedConsumerType1,
    earliest_available_time: data?.earliestAvailableTime ? data?.earliestAvailableTime : null,
    ready_by: calculatedStartTime1?.utc().format(),
    deliver_by: data?.earliestAvailableTime ? dayjs(data?.earliestAvailableTime)?.add(calculatedSlaHrs1, `hours`).format() : calculatedStartTime1?.add(calculatedSlaHrs1, `hours`).format(),
    pickup_time: calculatedStartTime1?.utc().format(),
    delivery_time: calculatedEndTime1?.utc().format(),
    reference_num: data?.vehicleReferenceId1 || null,
    manual_flag: data?.vehicleManual1 || false,
    vehicle_vin: data?.vehicleVin1 || null,
    vehicle_stock: data?.vehicleStock1 || null,
    vehicle_make: data?.vehicleMake1 || null,
    vehicle_model: data?.vehicleModel1 || null,
    vehicle_year: data?.vehicleYear1 || null,
    vehicle_color: data?.vehicleColor1 || null,
    dealer_contact: data?.contact1 || null,
    move_details: data?.notes1 || null,
    createdat: `now()`,
    createdBy: user?.profile?.email || null,
    updatedat: `now()`,
    updatedBy: user?.profile?.email || null,
    lane_id: data?.mainLane?.id || null,
    sequence: 1,
    class: calculatedClass,
    rate_class_override: calculatedClassOverride,
    chargeable: calculatedConsumerType1 === `loaner` ? false : true,
    config: move1Config,
  };

  // Build the second move
  move2 = {
    ...move2,
    customer_id: data?.rooftopId || null,
    payer_id: !data?.payerId || data?.rooftopId === data?.payerId ? null : data?.payerId,
    workflowset_id: data?.workflowsetId || 1,
    pickup_workflow_id: data?.pickupWorkflowId || 1,
    delivery_workflow_id: data?.deliveryWorkflowId || 2,
    sla_id: calculatedSlaId2,
    consumer_pickup: calculatedConsumerPickup2,
    consumer_name: calculatedConsumerName,
    consumer_phone: calculatedConsumerPhone,
    consumer_at_pickup: calculatedConsumerAtPickup2,
    consumer_type: calculatedConsumerType2,
    ready_by: calculatedStartTime2?.utc().format(),
    earliest_available_time: data?.earliestAvailableTime ? dayjs(data?.earliestAvailableTime).add(duration, `seconds`).utc().format() : null,
    deliver_by: data?.earliestAvailableTime 
      ? dayjs(data?.earliestAvailableTime)
          .add(duration, 'seconds')
          .add(calculatedSlaHrs2, 'hours')
          .format()
      : calculatedStartTime2?.add(calculatedSlaHrs2, 'hours').format(),
    pickup_time: calculatedStartTime2?.utc().format(),
    delivery_time: calculatedEndTime2?.utc().format(),
    reference_num: data?.vehicleReferenceId2 || null,
    manual_flag: data?.vehicleManual2 || false,
    vehicle_vin: data?.vehicleVin2 || null,
    vehicle_stock: data?.vehicleStock2 || null,
    vehicle_make: data?.vehicleMake2 || null,
    vehicle_model: data?.vehicleModel2 || null,
    vehicle_year: data?.vehicleYear2 || null,
    vehicle_color: data?.vehicleColor2 || null,
    dealer_contact: data?.contact2 || null,
    move_details: data?.notes2 || null,
    createdat: `now()`,
    createdBy: user?.profile?.email || null,
    updatedat: `now()`,
    updatedBy: user?.profile?.email || null,
    lane_id: data?.inverseLane?.id || null,
    sequence: 2,
    class: calculatedClass,
    rate_class_override: calculatedClassOverride,
    chargeable: calculatedConsumerType2 === `loaner` ? false : true,
    config: move2Config,
  };

  // Return the moves to insert
  if (data?.type === `concierge-loaner` || data?.type === `round-trip`) {
    return [move1, move2];
  }
  return [move1];
};
