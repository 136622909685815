// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import axios from 'axios';

import { useUser } from '@store';
import { validatePhone } from '@services/validationService';

import { css, keyframes } from '@emotion/css';
import {
  Theme,
  useTheme,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from '@mui/material';
import { BiErrorCircle, BiLoaderCircle, BiMinusCircle, BiMobile, BiSolidCheckCircle, BiUser } from 'react-icons/bi';

import { MovePlannerContext, MovePlannerContextType } from '@features/moveCreation/providers/MovePlannerProvider';

// COMPONENT ---------------------------------------------------------------- //

const MovePlannerConsumerForm = () => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const user = useUser();

  const {
    validation,
    type,
    consumer,
    vehicle1,
    vehicle2,
    additional1,
    additional2,
    setValidation,
    setConsumer,
    setVehicle1,
    setVehicle2,
    setAdditional1,
    setAdditional2,
  } = React.useContext(MovePlannerContext) as MovePlannerContextType;

  const [isMobilePhone, setIsMobilePhone] = React.useState<boolean | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handlePhoneValidation = async () => {
    setLoading(true);

    // First, validate the phone number
    if (!validatePhone(consumer?.phone)) {
      setIsMobilePhone(null);
      setLoading(false);
      return;
    }

    // Get the user token from the store
    const userToken = await user?.getToken();

    // Verify the phone number
    try {
      const res = await axios({
        method: `POST`,
        url: `/.netlify/functions/verifyMobilePhone`,
        data: {
          phone: consumer?.phone,
        },
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });

      if (res?.data?.success && (res?.data?.isMobile === true || res?.data?.isMobile === false)) {
        setIsMobilePhone(res?.data?.isMobile);
        setLoading(false);
        return;
      }

      setIsMobilePhone(null);
      setLoading(false);
      return;
    } catch (err) {
      console.error(`Failed to verify mobile phone:`, err);
      setIsMobilePhone(null);
      setLoading(false);
      return;
    }
  };

  if (type === `concierge` || type === `concierge-loaner`) {
    return (
      <div className={cls.consumer}>
        <div className={cls.flex}>
          <div className={cls.flexItem}>
            <label className={cls.textFieldLabel} htmlFor='consumer-name'>
              Customer Name<span className={cls.textFieldLabelRequired}> *</span>
            </label>

            <TextField
              fullWidth
              required
              error={!validation?.consumer?.name}
              name='consumer-name'
              placeholder='Enter customer name...'
              helperText={!validation?.consumer?.name ? `Please enter your customer's name!` : ``}
              value={consumer?.name || ``}
              onChange={e => {
                if (setConsumer) setConsumer({ ...consumer, name: e?.target?.value || `` });
              }}
              slotProps={{
                htmlInput: {
                  maxLength: 48,
                },
                input: {
                  startAdornment: (
                    <InputAdornment position='start'>
                      <BiUser style={{ fontSize: 20 }} />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </div>

          <div className={cls.flexItem}>
            <label className={cls.textFieldLabel} htmlFor='consumer-phone'>
              Customer Mobile Phone<span className={cls.textFieldLabelRequired}> *</span>
            </label>

            <TextField
              fullWidth
              required
              disabled={loading}
              error={!validation?.consumer?.phone}
              type='tel'
              name='consumer-phone'
              placeholder='Enter customer mobile phone number...'
              helperText={!validation?.consumer?.phone ? `Please enter your customer's mobile phone number!` : ``}
              value={consumer?.phone || ``}
              onChange={e => {
                if (setConsumer) setConsumer({ ...consumer, phone: e?.target?.value || `` });
              }}
              onBlur={() => handlePhoneValidation()}
              slotProps={{
                htmlInput: {
                  maxLength: 14,
                },
                input: {
                  startAdornment: (
                    <InputAdornment position='start'>
                      <BiMobile style={{ fontSize: 20 }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <div className={cls.consumerPhoneValidation}>
                      {loading ? (
                        <Tooltip title='Validating...'>
                          <span>
                            <BiLoaderCircle
                              className={cls.consumerPhoneValidationLoader}
                              size={20}
                              style={{ color: theme?.palette?.text?.disabled }}
                            />
                          </span>
                        </Tooltip>
                      ) : isMobilePhone === true ? (
                        <Tooltip title='Phone is able to receive SMS.'>
                          <span>
                            <BiSolidCheckCircle
                              className={cls.consumerPhoneValidationIcon}
                              size={20}
                              style={{ color: theme?.palette?.success?.main }}
                            />
                          </span>
                        </Tooltip>
                      ) : isMobilePhone === false ? (
                        <Tooltip title='Phone is NOT able to receive SMS!'>
                          <span>
                            <BiErrorCircle
                              className={cls.consumerPhoneValidationIcon}
                              size={20}
                              style={{ color: theme?.palette?.error?.main }}
                            />
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip title='Please enter a valid mobile phone number with SMS capability. Do NOT include the country code.'>
                          <span>
                            <BiMinusCircle
                              className={cls.consumerPhoneValidationIcon}
                              size={20}
                              style={{ color: theme?.palette?.text?.disabled }}
                            />
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  ),
                },
              }}
            />
          </div>
        </div>

        <div className={cls.flexAlt}>
          <div className={cls.flexItem}>
            <FormControl>
              <label className={cls.textFieldLabel} htmlFor='consumer-action'>
                The customer's vehicle needs to be...
                <span className={cls.textFieldLabelRequired}> *</span>
              </label>

              <RadioGroup
                sx={{ marginTop: `6px`, marginLeft: `18px` }}
                defaultValue={consumer?.action || `pickup`}
                name='consumer-action'
              >
                <FormControlLabel
                  value='pickup'
                  onChange={() => {
                    // Change to loaner first if the action is pickup (only for concierge-loaner)
                    if (type === `concierge-loaner`) {
                      const currentValue = consumer?.first;
                      if (
                        currentValue !== `loaner` &&
                        setValidation &&
                        setVehicle1 &&
                        setVehicle2 &&
                        setAdditional1 &&
                        setAdditional2
                      ) {
                        setValidation({
                          ...validation,
                          vehicle1: validation?.vehicle2,
                          vehicle2: validation?.vehicle1,
                        });
                        setVehicle1({ ...vehicle2 });
                        setVehicle2({ ...vehicle1 });
                        setAdditional1({ ...additional2 });
                        setAdditional2({ ...additional1 });
                      }
                      if (setConsumer) setConsumer({ ...consumer, action: `pickup`, first: `loaner` });
                    } else {
                      if (setConsumer) setConsumer({ ...consumer, action: `pickup` });
                    }
                  }}
                  label={`Brought in for service`}
                  control={<Radio size='small' sx={{ padding: `6px` }} />}
                  slotProps={{ typography: { style: { fontSize: `14px` } } }}
                />

                <FormControlLabel
                  value='return'
                  onChange={() => {
                    // Change to customer first if the action is return (only for concierge-loaner)
                    if (type === `concierge-loaner`) {
                      const currentValue = consumer?.first;
                      if (
                        currentValue !== `consumer` &&
                        setValidation &&
                        setVehicle1 &&
                        setVehicle2 &&
                        setAdditional1 &&
                        setAdditional2
                      ) {
                        setValidation({
                          ...validation,
                          vehicle1: validation?.vehicle2,
                          vehicle2: validation?.vehicle1,
                        });
                        setVehicle1({ ...vehicle2 });
                        setVehicle2({ ...vehicle1 });
                        setAdditional1({ ...additional2 });
                        setAdditional2({ ...additional1 });
                      }
                      if (setConsumer) setConsumer({ ...consumer, action: `return`, first: `consumer` });
                    } else {
                      if (setConsumer) setConsumer({ ...consumer, action: `return` });
                    }
                  }}
                  label={`Returned to the customer`}
                  control={<Radio size='small' sx={{ padding: `6px` }} />}
                  slotProps={{ typography: { style: { fontSize: `14px` } } }}
                />
              </RadioGroup>
            </FormControl>
          </div>

          {/* {type === `concierge-loaner` ? (
            <div className={cls.flexItem}>
              <FormControl>
                <label className={cls.textFieldLabel} htmlFor='consumer-first'>
                  Will we be moving the loaner or customer vehicle first?
                  <span className={cls.textFieldLabelRequired}> *</span>
                </label>

                <RadioGroup
                  sx={{ marginTop: `6px`, marginLeft: `18px` }}
                  defaultValue={consumer?.first || `loaner`}
                  name='consumer-first'
                >
                  <FormControlLabel
                    value={`loaner`}
                    onChange={e => {
                      const currentValue = consumer?.first;
                      if (
                        currentValue !== e?.target?.value &&
                        setValidation &&
                        setVehicle1 &&
                        setVehicle2 &&
                        setAdditional1 &&
                        setAdditional2
                      ) {
                        setValidation({
                          ...validation,
                          vehicle1: validation?.vehicle2,
                          vehicle2: validation?.vehicle1,
                        });
                        setVehicle1({ ...vehicle2 });
                        setVehicle2({ ...vehicle1 });
                        setAdditional1({ ...additional2 });
                        setAdditional2({ ...additional1 });
                      }
                      if (setConsumer) setConsumer({ ...consumer, first: e?.target?.value });
                    }}
                    label={`Loaner First`}
                    control={<Radio size='small' sx={{ padding: `6px` }} />}
                    slotProps={{ typography: { style: { fontSize: `14px` } } }}
                  />

                  <FormControlLabel
                    value={`consumer`}
                    onChange={e => {
                      const currentValue = consumer?.first;
                      if (
                        currentValue !== e?.target?.value &&
                        setValidation &&
                        setVehicle1 &&
                        setVehicle2 &&
                        setAdditional1 &&
                        setAdditional2
                      ) {
                        setValidation({
                          ...validation,
                          vehicle1: validation?.vehicle2,
                          vehicle2: validation?.vehicle1,
                        });
                        setVehicle1({ ...vehicle2 });
                        setVehicle2({ ...vehicle1 });
                        setAdditional1({ ...additional2 });
                        setAdditional2({ ...additional1 });
                      }
                      if (setConsumer) setConsumer({ ...consumer, first: e?.target?.value });
                    }}
                    label={`Customer First`}
                    control={<Radio size='small' sx={{ padding: `6px` }} />}
                    slotProps={{ typography: { style: { fontSize: `14px` } } }}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          ) : null} */}
        </div>
      </div>
    );
  }
  return null;
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  const loader = keyframes`
    0% {
      opacity: 1;
      rotate: 0deg;
    }
    6.25% {
      opacity: 0.5;
    }
    12.5% {
      opacity: 1;
    }
    18.75% {
      opacity: 0.5;
    }
    25% {
      opacity: 1;
    }
    31.25% {
      opacity: 0.5;
    }
    37.5% {
      opacity: 1;
    }
    43.75% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
      rotate: 180deg;
    }
    56.25% {
      opacity: 0.5;
    }
    62.5% {
      opacity: 1;
    }
    68.75% {
      opacity: 0.5;
    }
    75% {
      opacity: 1;
    }
    81.25% {
      opacity: 0.5;
    }
    87.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      rotate: 360deg;
    }
  `;

  return {
    consumer: css`
      position: relative;
      margin-bottom: 18px;
    `,
    consumerPhoneValidation: css``,
    consumerPhoneValidationIcon: css`
      display: block;
      cursor: pointer;
    `,
    consumerPhoneValidationLoader: css`
      display: block;
      animation: ${loader} 5s linear infinite;
    `,

    textFieldLabel: css`
      display: block;
      margin-bottom: 2px;
      margin-left: 6px;
      font-size: 12px;
      font-weight: 600;
    `,
    textFieldLabelRequired: css`
      color: ${theme?.palette?.error?.main};
    `,

    flex: css`
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      ${theme?.breakpoints?.down('sm')} {
        flex-direction: column;
      }
    `,
    flexAlt: css`
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: 18px;
      ${theme?.breakpoints?.down('md')} {
        flex-direction: column;
      }
    `,
    flexItem: css`
      flex: 1;
      width: 100%;
    `,
  };
};

// EXPORT ---------------------------------------------------------------- //

export default MovePlannerConsumerForm;
