import { create } from 'zustand';

import { User } from '@features/auth/services/authService';
import { Customers } from '@gql/schema';

export type HopDriveStore = {
  user?: User;
  rooftop?: Customers;
  customerConfig?: any;
  organizationConfig?: any;
};

export const useStore = create<HopDriveStore>(() => ({
  user: undefined,
  rooftop: undefined,
  customerConfig: undefined,
  organizationConfig: undefined,
}));

export const getNonReactiveState = (): HopDriveStore => useStore.getState();

export const useUser = () => useStore(state => state.user);
export const setUser = (user?: User) => useStore.setState({ user });

export const useRooftop = () => useStore(state => state.rooftop);
export const setRooftop = (rooftop?: Customers) => useStore.setState({ rooftop });

export const useCustomerConfig = () => useStore(state => state.customerConfig);
export const setCustomerConfig = (customerConfig?: any) => useStore.setState({ customerConfig });

export const useOrganizationConfig = () => useStore(state => state.organizationConfig);
export const setOrganizationConfig = (organizationConfig?: any) => useStore.setState({ organizationConfig });
