import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { authService } from '@features/auth/services/authService';
import { refreshToken } from './refreshToken';
import { navigateToLogin } from '@utils/navigateToLogin';
import { typePolicies } from './cacheConfig';
import { config } from '@config'


const httpLink = createHttpLink({
  uri: config.graphql.httpUrl,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: config.graphql.wsUrl,
    connectionParams: async () => {
      return {
        headers: {
          authorization: `Bearer ${await authService.getAccessTokenWithAutoRefresh()}`,
          'hasura-client-name': config.app.fullName,
        },
      };
    },
  })
);

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${await authService.getAccessTokenWithAutoRefresh()}`,
      'hasura-client-name': config.app.fullName,
    },
  };
});

const errorLink = onError(async ({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.extensions?.code === 'UNAUTHENTICATED' || err.message.includes('JWTExpired')) {
        try {
          const newToken = await refreshToken();
          const oldHeaders = operation.getContext().headers;
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${newToken}`,
            },
          });
          return forward(operation);
        } catch (error) {
          console.error('Error refreshing token:', error);
          navigateToLogin();
        }
      }
    }
  }

  if (networkError) {
    console.error(`[Network Error]: ${networkError.message}`);
  }

  if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
    navigateToLogin();
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

const link = ApolloLink.from([errorLink, authLink, splitLink]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies,
  }),
});

export default client;
