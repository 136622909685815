// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { css } from '@emotion/css';
import { Theme, useTheme, Button, TextField, Typography } from '@mui/material';
import { BiSolidCalendarExclamation, BiSolidCheckCircle } from 'react-icons/bi';

import { MovePlannerContext, MovePlannerContextType } from '@features/moveCreation/providers/MovePlannerProvider';

// COMPONENT ---------------------------------------------------------------- //

const MovePlannerTimeForm = ({ appointmentMode }: { appointmentMode?: boolean }) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const { validation, type, time, isDefaultTime, lane, setTime, setIsDefaultTime } = React.useContext(
    MovePlannerContext
  ) as MovePlannerContextType;

  // Initialize myDate and myTime from the time prop if it exists
  const [myDate, setMyDate] = React.useState(() => (time ? dayjs(time).format('YYYY-MM-DD') : ''));
  const [myTime, setMyTime] = React.useState(() => (time ? dayjs(time).format('HH:mm') : ''));

  const [helperText, setHelperText] = React.useState(``);

  React.useEffect(() => {
    if (!validation?.time) {
      if (appointmentMode) {
        setHelperText(`Please select a date and time at least 2 hours in the future!`);
      } else {
        setHelperText(`Please select a date and time for the move!`);
      }
    } else {
      setHelperText(``);
    }
  }, [validation?.time]);

  // Add an effect to update myDate and myTime when time prop changes
  React.useEffect(() => {
    if (time && !isDefaultTime) {
      setMyDate(dayjs(time).format('YYYY-MM-DD'));
      setMyTime(dayjs(time).format('HH:mm'));
    }
  }, [time, isDefaultTime]);

  const isConcierge = type?.includes(`concierge`);

  // Set the time state when date or time changes
  React.useEffect(() => {
    if (myDate && myTime) {
      const currentTimePlus90 = dayjs().add(90, `minute`);
      const selectedTime = dayjs(`${myDate}T${myTime}`);

      // If the time is in the past, set it to the current time
      if (selectedTime.isBefore(currentTimePlus90) && !appointmentMode) {
        toast.warning(`Time was adjusted to be 90 minutes in the future.`, { toastId: `move-planner-time` }); // Toast ID prevents duplicates
        setMyDate(currentTimePlus90.add(1, `minute`).format(`YYYY-MM-DD`));
        setMyTime(currentTimePlus90.add(1, `minute`).format(`HH:mm`));
        return;
      }

      // Otherwise, set the time
      if (setTime) setTime(selectedTime.format());
    } else {
      if (setTime) setTime(``);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myDate, myTime]);

  // When the isDefaultTime flag changes to true, clear out the date & time
  React.useEffect(() => {
    if (isDefaultTime) {
      setTime(``);
      setMyDate(``);
      setMyTime(``);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDefaultTime]);

  return (
    <div className={cls.time}>
      <div className={cls.title}>
        <BiSolidCalendarExclamation className={cls.titleIcon} />

        {isConcierge ? (
          <Typography variant='body2' sx={{ textWrap: `pretty`, color: theme?.palette?.text?.secondary }}>
            Select the date & time of the appointment with your customer. Timezone is local to your machine (
            {dayjs().format(`z`)}). Must be at least 90 minutes in the future.
          </Typography>
        ) : (
          <Typography variant='body2' sx={{ textWrap: `pretty`, color: theme?.palette?.text?.secondary }}>
            Select the date & time your vehicle(s) will be ready for transport. Timezone is local to your machine (
            {dayjs().format(`z`)}). Must be at least 90 minutes in the future. NOTE: This is <b>NOT</b> an appointment
            time, but rather an estimate so we can determine when to dispatch a driver to complete the move. The actual
            pickup time may vary based on volume and driver availability.
          </Typography>
        )}
      </div>

      <div className={cls.flex}>
        <div className={cls.flexItem}>
          <label className={cls.textFieldLabel} htmlFor='date-select'>
            {isConcierge ? `Appointment Date` : `Ready By Date`}
            <span className={cls.textFieldLabelRequired}> *</span>
          </label>

          <TextField
            fullWidth
            required
            disabled={isDefaultTime}
            error={!validation?.time}
            name='date-select'
            type='date'
            placeholder='Select a date...'
            helperText={helperText}
            value={isDefaultTime ? dayjs().add(90, `minute`).format(`YYYY-MM-DD`) : myDate || ``}
            onChange={e => setMyDate(e?.target?.value)}
            slotProps={{
              htmlInput: {
                min: dayjs().add(90, `minute`).format(`YYYY-MM-DD`),
              },
            }}
          />
        </div>

        <div className={cls.flexItem}>
          <label className={cls.textFieldLabel} htmlFor='time-select'>
            {isConcierge ? `Appointment Time` : `Ready By Time`}
            <span className={cls.textFieldLabelRequired}> *</span>
          </label>

          <TextField
            fullWidth
            required
            disabled={isDefaultTime}
            error={!validation?.time}
            name='time-select'
            type='time'
            placeholder='Select a time...'
            helperText={helperText}
            value={isDefaultTime ? dayjs().add(90, `minute`).format(`HH:mm`) : myTime || ``}
            onChange={e => setMyTime(e?.target?.value)}
            slotProps={{
              htmlInput: {
                min:
                  myDate <= dayjs().add(90, `minute`).format(`YYYY-MM-DD`)
                    ? dayjs().add(90, `minute`).format(`HH:mm`)
                    : `00:00`,
                step: 300, // 5 minutes
              },
            }}
          />
        </div>

        {type === `one-way` || type === `round-trip` ? (
          <div className={cls.flexItemSmall}>
            <label className={cls.textFieldLabelInvisible} htmlFor='default-time-toggle'>
              Use Earliest Time
            </label>

            <Button
              fullWidth
              variant={isDefaultTime ? `contained` : `outlined`}
              color={isDefaultTime ? `success` : `utility`}
              onClick={() => {
                if (setIsDefaultTime) setIsDefaultTime(!isDefaultTime);
              }}
              sx={{ height: `40px` }}
            >
              Use Earliest Time
              {isDefaultTime ? <BiSolidCheckCircle style={{ marginLeft: `6px` }} size={18} /> : null}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  return {
    time: css`
      position: relative;
    `,

    title: css`
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 12px;
      border: 1px solid ${theme?.palette?.divider};
      border-radius: 10px;
      margin-bottom: 12px;
      background-color: ${theme?.palette?.action?.hover};
    `,
    titleIcon: css`
      min-width: 20px;
      min-height: 20px;
      margin-top: -2px;
      font-size: 20px;
      color: ${theme?.palette?.text?.secondary};
    `,

    textFieldLabel: css`
      display: block;
      margin-bottom: 2px;
      margin-left: 6px;
      font-size: 12px;
      font-weight: 600;
    `,
    textFieldLabelInvisible: css`
      display: block;
      margin-bottom: 2px;
      margin-left: 6px;
      font-size: 12px;
      font-weight: 600;
      color: transparent;
      user-select: none;
      ${theme?.breakpoints?.down('md')} {
        display: none;
      }
    `,
    textFieldLabelRequired: css`
      color: ${theme?.palette?.error?.main};
    `,

    flex: css`
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 12px;
      ${theme?.breakpoints?.down('md')} {
        flex-direction: column;
      }
    `,
    flexItem: css`
      flex: 2;
      width: 100%;
    `,
    flexItemSmall: css`
      flex: 1;
      width: 100%;
    `,
  };
};

// EXPORT ---------------------------------------------------------------- //

export default MovePlannerTimeForm;
