//////////////////////// DEPENDENCIES ////////////////////////

import React, { Children, useRef } from 'react';
import {
  TableContainer,
  Table as MUITable,
  Tooltip,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
  Typography,
  Popover,
  ClickAwayListener,
  Theme,
  useTheme,
} from '@mui/material';
import { useTools } from '../../hooks/useTools';
import { getPropValue, getComparator, stableSort } from '@services/utilityService';
import IconAction from '@components/Utils/IconAction';
import { css } from '@emotion/css';
import { SortDirection } from '@mui/material/TableCell/TableCell';
import Pagination from './Pagination';

interface TableProps {
  name?: string;
  data?: object[];
  columnConfig?: Column[];
  onRowClick?: (data: object) => void;
  rowActions?: RowAction[];
  rowKeyCalculator?: (row: object) => number;
  cellKeyCalculator?: (column: Column) => string;
  defaultOrder?: SortDirection | 'asc' | 'desc';
  defaultOrderBy?: string;
  defaultRowsPerPage?: number;
  tableAriaLabel?: string;
  stickyHeader?: boolean;
  padding?: 'normal' | 'checkbox' | 'none';
  size?: 'small' | 'medium';
  classes?: object;
  children?: React.ReactNode;
  usePagination?: boolean;
  setPaginationData?: (data: object) => void;
  paginationData?: PaginationData;
  totalRecordsCount?: number;
  checkedItems?: object[];
  setCheckedItems?: (data: object) => void;
  totalRecordLimit?: number;
  setTotalRecordLimit?: (data: number) => void;
  // value?: any;
  label?: string;
  tooltip?: string;
  style?: object;
  // renderer?: any;
  align?: string;
}

interface Column {
  name: string;
  value: (data: any) => any;
  label: string;
  tooltip?: string;
  style?: object;
  align?: string;
  padding?: string;
  hide?: boolean;
  disableSort?: boolean;
  disableOnRowClick?: boolean;
  checkbox?: boolean;
  component?: () => any;
  renderer?: (data: any) => any;
  onClick?: (data: any) => void;
}

interface Row {
  data: AnyObject[];
}

interface RowAction {
  hide?: boolean | ((data: object) => boolean);
  handler?: any;
  name?: string;
  label?: string;
  data?: object;
  disabled?: boolean;
}

interface ConfigColumn {
  name: string;
  // value: (data: object) => any;
  label: string;
  tooltip?: string;
  style?: object;
  align?: 'left' | 'right' | 'center';
  padding?: 'none' | 'checkbox' | 'normal';
  hide?: boolean;
  disableSort?: boolean;
  disableOnRowClick?: boolean;
  checkbox?: boolean;
  component?: () => React.ReactNode;
  // renderer?: (data: any) => any;
  onClick?: (data: object) => void;
}

interface AnyObject {
  [key: string]: any;
}

interface PaginationData {
  orderBy: string;
  limit: number;
  order: SortDirection | 'asc' | 'desc';
  offset: number;
}

//////////////////////// COMPONENT ////////////////////////
export function Column() {}

export default function Table(props: TableProps) {
  const {
    data = [],
    columnConfig,
    onRowClick,
    rowActions,
    rowKeyCalculator,
    cellKeyCalculator,
    defaultOrder = 'desc',
    defaultOrderBy = 'id',
    defaultRowsPerPage = 50,
    tableAriaLabel = 'data-table',
    stickyHeader = false,
    padding = 'normal',
    size = 'small',
    classes,
    children,
    //used for pagination
    usePagination = false,
    setPaginationData = () => {},
    paginationData = {},
    totalRecordsCount = data.length,
    //used for header checkbox
    checkedItems = [],
    setCheckedItems = () => {},
    totalRecordLimit = null,
    setTotalRecordLimit = () => {},
  } = props;

  const theme = useTheme();
  const cls = useStyles(theme);
  const clsx = useSxStyles(theme);
  const { getReadableText } = useTools();
  const popoverRef = useRef(null);

  const [order, setOrder] = React.useState<SortDirection | 'asc' | 'desc'>(defaultOrder);
  const [orderBy, setOrderBy] = React.useState<string>(defaultOrderBy);
  const [limit, setLimit] = React.useState<number | null>(null);
  const [offset, setOffset] = React.useState<number | null>(null);
  const [page, setPage] = React.useState<number | null>(null);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(defaultRowsPerPage);
  const [headerChecked, setHeaderChecked] = React.useState<boolean>(false);
  const [rowDeselect, setRowDeselect] = React.useState<boolean>(false);
  const [allDeselect, setAllDeselect] = React.useState<boolean>(false);
  const [allSelect, setAllSelect] = React.useState<boolean>(false);
  const [pageSelect, setPageSelect] = React.useState<boolean>(false);
  const [pageDeselect, setPageDeselect] = React.useState<boolean>(false);
  const [openCheckboxPopover, setOpenCheckboxPopover] = React.useState<boolean | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null);

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: { target: unknown }) => {
      console.log('popoverRef', popoverRef);
      if (!popoverRef?.current?.contains(event.target)) {
        handleClosePopover();
      }
    };

    if (anchorEl) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl]);

  React.useEffect(() => {
    let checkedItemsSet: Set<object>;
    let rowsToVerify;
    if (checkedItems && checkedItems.length > 0) {
      checkedItemsSet = new Set(checkedItems);
    }
    if (data && data.length > rowsPerPage) {
      rowsToVerify = stableSort(data, getComparator(order, orderBy)).slice(
        (page || 0) * rowsPerPage,
        (page || 0) * rowsPerPage + rowsPerPage
      );
    } else rowsToVerify = data;

    if (allSelect) {
      if (checkedItems && checkedItems.length > 0) {
        const itemsToAdd: Array<object> = [];
        data.forEach(item => {
          if (!checkedItemsSet.has(item)) {
            itemsToAdd.push(item);
          }
        });
        if (itemsToAdd && itemsToAdd.length > 0) {
          setCheckedItems([...checkedItems, ...itemsToAdd]);
        }
      } else {
        setCheckedItems([...data]);
      }
      setHeaderChecked(true);
      setAllSelect(false);
    } else if (allDeselect) {
      setCheckedItems([]);
      setAllDeselect(false);
      setHeaderChecked(false);
    } else if (pageSelect) {
      if (checkedItems && checkedItems.length > 0) {
        const checkedItemsSet: Set<object> = new Set(checkedItems);
        rowsToVerify.forEach(item => {
          if (!checkedItemsSet.has(item as object)) {
            checkedItemsSet.add(item as object);
          }
        });
        setCheckedItems([...checkedItemsSet]);
        setHeaderChecked(true);
        setPageSelect(false);
      } else {
        setCheckedItems([...rowsToVerify]);
        setHeaderChecked(true);
        setPageSelect(false);
      }
    } else if (pageDeselect) {
      if (checkedItems && checkedItems.length > 0) {
        const checkedItemsSet = new Set(checkedItems);
        rowsToVerify.forEach(item => {
          if (checkedItemsSet.has(item as object)) {
            checkedItemsSet.delete(item as object);
          }
        });
        setCheckedItems([...checkedItemsSet]);
        setHeaderChecked(false);
        setPageDeselect(false);
      } else {
        setHeaderChecked(false);
        setPageDeselect(false);
      }
    } else {
      const itemsChecked: Array<object> = [];
      if (checkedItems && checkedItems.length > 0) {
        rowsToVerify.forEach(item => {
          if (checkedItemsSet.has(item as object)) {
            itemsChecked.push(item as object);
          }
        });
        if (
          itemsChecked &&
          itemsChecked.length > 0 &&
          (rowsPerPage === itemsChecked.length || itemsChecked.length === rowsToVerify.length)
        ) {
          setHeaderChecked(true);
        } else {
          setHeaderChecked(false);
        }
      } else {
        setHeaderChecked(false);
      }
    }
  }, [checkedItems, data, allSelect, allDeselect, pageSelect, pageDeselect, rowsPerPage, page]);

  React.useEffect(() => {
    const checkedItemsSet = new Set(checkedItems);
    if (rowDeselect) {
      data.forEach(item => {
        if (!checkedItemsSet.has(item)) {
          checkedItemsSet.delete(item);
        }
      });
      setCheckedItems([...checkedItemsSet]);
      setRowDeselect(false);
    }
  }, [rowDeselect]);

  React.useEffect(() => {
    if (rowsPerPage && order && orderBy) {
      const newPaginationData = {
        orderBy: orderBy,
        limit: totalRecordLimit ? totalRecordLimit : rowsPerPage,
        order: order,
        offset: offset ? offset : 0,
      };
      setPaginationData(newPaginationData);
    }
  }, [rowsPerPage, order, orderBy, offset, page, totalRecordLimit]);

  const getDefaultColumnConfig = (data?: Array<object>) => {
    try {
      if (!Array.isArray(data)) return [];
      if (data.length <= 0) return [];

      const newConfig: Array<object> = [];
      const firstRow: { [key: string]: AnyObject } & { data?: AnyObject } = data[0] as { [key: string]: AnyObject } & {
        data?: AnyObject;
      };
      if (!firstRow) return []; // Ensure firstRow is not null or undefined

      const columnNames = Object.keys(firstRow) as string[];
      columnNames.forEach(columnName => {
        try {
          let newColumnName = columnName;
          if (columnName.startsWith('__')) return;

          const columnValue = firstRow[columnName]; // Access column value dynamically
          if (columnValue === undefined || columnValue === null) return; // Ensure columnValue is not null or undefined

          // If the column is an object, then try to use the first sub-column (object property)
          if (typeof columnValue === 'object' && !Array.isArray(columnValue)) {
            const subColumns = Object.keys(columnValue).filter(key => !key.startsWith('__'));
            if (!Array.isArray(subColumns) || subColumns.length <= 0) return;
            newColumnName += `.${subColumns[0]}`;
          }

          // If it's an array, just skip it. May be add object treatment for first row?
          if (Array.isArray(columnValue)) return;

          newConfig.push({ name: newColumnName });
        } catch (err) {
          console.log('getDefaultColumnConfig error', err);
        }
      });
      return newConfig;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getChildColumnConfig = () => {
    const childComponents = Children.toArray(children);
    if (!Array.isArray(childComponents)) return;
    if (childComponents.length <= 0) return;
    const childColumns = childComponents.filter(c => typeof c?.type == 'function');
    if (!Array.isArray(childColumns)) return;
    if (childColumns.length <= 0) return;
    return childColumns.map(childColumn => ({ ...childColumn?.props }));
  };

  const defaultColumnConfig = getDefaultColumnConfig();
  const childColumnConfig = getChildColumnConfig();

  const config: ConfigColumn[] = columnConfig || childColumnConfig || defaultColumnConfig;

  const rows = data.map((dataRow: AnyObject) => {
    const row: { [key: string]: AnyObject } & { data?: AnyObject } = {}; // Initialize row with a dynamic key object

    config.forEach(column => {
      // Initialize cellValue with the value from dataRow using column.name
      let cellValue = dataRow[column.name];

      // If column.value exists, use it to calculate cellValue
      if (column?.value) {
        cellValue = column?.value(dataRow);
      }

      // Assign the cellValue to the row with the key as column.name
      row[column.name] = cellValue;
    });

    // Assign the original dataRow to row.data
    row.data = dataRow;

    // Return the newly created row
    return row;
  });

  const handleChangeSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const defaultRowKeyCalculator = (row: AnyObject) => {
    return row?.data?.id;
  };

  const defaultCellKeyCalculator = (column: Column) => {
    return column.name;
  };

  const handleHeaderChecked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOpenCheckboxPopover(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setOpenCheckboxPopover(false);
    setAnchorEl(null);
  };

  const handleSelectAllPage = () => {
    setAnchorEl(null);
    setPageSelect(true);
  };

  const handleSelectAllTotal = () => {
    setAnchorEl(null);
    setTotalRecordLimit(totalRecordsCount);
    setAllSelect(true);
  };

  const handleDeselectAllTotal = () => {
    setAnchorEl(null);
    setAllDeselect(true);
  };

  const handleDeselectAllPage = () => {
    setAnchorEl(null);
    setPageDeselect(true);
  };

  // Header checkbox component that checks/unchecks all filtered rows if clicked.
  const HeaderCheckbox = () => {
    return (
      <div>
        <Checkbox color='primary' onClick={handleHeaderChecked} checked={headerChecked} />
        {anchorEl ? (
          <ClickAwayListener onClickAway={handleClosePopover}>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              ref={popoverRef}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {openCheckboxPopover && !headerChecked ? (
                <>
                  <Typography style={{ padding: '8px' }} onClick={handleSelectAllPage}>
                    Select all on page
                  </Typography>
                  {!checkedItems || !checkedItems.length || checkedItems.length < totalRecordsCount ? (
                    <Typography style={{ padding: '8px' }} onClick={handleSelectAllTotal}>
                      Select all {totalRecordsCount} records
                    </Typography>
                  ) : null}

                  {checkedItems.length > 0 ? (
                    <Typography style={{ padding: '8px' }} onClick={handleDeselectAllTotal}>
                      Deselect {checkedItems.length} records
                    </Typography>
                  ) : null}
                </>
              ) : openCheckboxPopover && headerChecked ? (
                <>
                  <Typography style={{ padding: '8px' }} onClick={handleDeselectAllPage}>
                    Deselect all on page
                  </Typography>
                  <Typography style={{ padding: '8px' }} onClick={handleDeselectAllTotal}>
                    Deselect all {checkedItems.length} records
                  </Typography>
                </>
              ) : null}
            </Popover>
          </ClickAwayListener>
        ) : null}
      </div>
    );
  };

  // Row checkbox component that individually selects this row if clicked
  const RowCheckbox = (item: AnyObject) => {
    const checkedItemsSet = new Set(checkedItems);

    const handleCheckboxChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: object) => {
      event.stopPropagation();
      if (checkedItemsSet.has(item)) {
        checkedItemsSet.delete(item);
        if (headerChecked) {
          setHeaderChecked(false);
          setRowDeselect(true);
        }
      } else {
        checkedItemsSet.add(item);
      }
      setCheckedItems([...checkedItemsSet]);
    };

    return (
      <Checkbox
        color='primary'
        onClick={event => handleCheckboxChange(event, item)}
        checked={checkedItemsSet.has(item as object)}
        disabled={!item || !item?.id}
        indeterminate={!item || !item.id}
      />
    );
  };

  const RenderValue = ({ row, column }: { row: Row; column: Column }) => {
    try {
      if (column.checkbox) return RowCheckbox(row.data);
      if (column.renderer) return column.renderer(row.data);
      if (column.value) return column.value(row.data);
      return getPropValue(row.data, column.name);
    } catch (error) {
      console.error('RenderValue error', error, row.data, column.name);
      return 'unknown';
    }
  };

  return (
    <div>
      <Pagination
        checkedItemsLength={checkedItems?.length}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setLimit={setLimit}
        setOffset={setOffset}
        totalRecords={totalRecordsCount || data?.length}
        page={page}
        setPage={setPage}
      />

      <div className={cls.tableSeparator} />

      <TableContainer className={cls.table}>
        <MUITable
          size={size}
          aria-label={tableAriaLabel}
          padding={padding}
          stickyHeader={stickyHeader}
          classes={classes}
        >
          <TableHead>
            <TableRow>
              {config.map(column => {
                if (!column?.hide)
                  return (
                    <React.Fragment key={`header-${column?.name}`}>
                      <Tooltip title={column?.tooltip || ''} placement='top' disableHoverListener={!column?.tooltip}>
                        <TableCell
                          key={column?.name}
                          align={column?.align || `left`}
                          padding={column?.padding || `normal`}
                          sortDirection={orderBy === column?.name ? order : 'asc'}
                          sx={clsx.tableHeadCell}
                        >
                          {column.component ? (
                            column.component()
                          ) : column.checkbox ? (
                            HeaderCheckbox()
                          ) : (
                            <TableSortLabel
                              hideSortIcon={orderBy === column?.name || column?.disableSort}
                              active={orderBy === column?.name}
                              direction={orderBy === column?.name ? order : `asc`}
                              onClick={() => handleChangeSort(column?.name)}
                              sx={clsx.headTxt}
                            >
                              {column?.label || getReadableText(column?.name, true) || `Label`}
                            </TableSortLabel>
                          )}
                        </TableCell>
                      </Tooltip>
                    </React.Fragment>
                  );
                else return null;
              })}

              {rowActions?.length ? <TableCell sx={clsx.tableHeadCell} /> : null}
            </TableRow>
          </TableHead>

          {usePagination && !totalRecordLimit ? (
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map((row, i) => {
                const rowKey = rowKeyCalculator
                  ? rowKeyCalculator(row as AnyObject)
                  : defaultRowKeyCalculator(row as AnyObject);
                return (
                  <TableRow className={`MuiTableRow-root ${i % 2 ? cls.rowEven : cls.rowOdd}`}>
                    {config.map(column => (
                      <TableCell
                        key={cellKeyCalculator ? cellKeyCalculator(column) : defaultCellKeyCalculator(column)}
                        align={column?.align || `left`}
                        style={column?.style || {}}
                        onClick={() => {
                          if (!column?.disableOnRowClick) {
                            if (column?.onClick) {
                              column?.onClick(row?.data);
                            } else {
                              if (onRowClick) onRowClick(row?.data);
                            }
                          }
                        }}
                      >
                        <RenderValue row={row} column={column} />
                      </TableCell>
                    ))}

                    {rowActions?.length ? (
                      <TableCell align='right'>
                        <IconAction
                          actions={rowActions?.map(action => ({
                            ...action,
                            handler: () => action?.handler(row?.data),
                            hide: action?.hide ? action?.hide(row?.data) : false,
                          }))}
                        />
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice((page || 0) * rowsPerPage, (page || 0) * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  const rowKey = rowKeyCalculator ? rowKeyCalculator(row as Row) : defaultRowKeyCalculator(row as Row);
                  return (
                    <TableRow className={`MuiTableRow-root ${i % 2 ? cls.rowEven : cls.rowOdd}`}>
                      {config?.map(column => (
                        <TableCell
                          key={cellKeyCalculator ? cellKeyCalculator(column) : defaultCellKeyCalculator(column)}
                          align={column?.align || `left`}
                          style={column?.style || {}}
                          onClick={() => {
                            if (!column?.disableOnRowClick) {
                              if (column?.onClick) {
                                column?.onClick(row?.data);
                              } else {
                                if (onRowClick) onRowClick(row?.data);
                              }
                            }
                          }}
                        >
                          <RenderValue row={row as Row} column={column} />
                        </TableCell>
                      ))}

                      {rowActions?.length ? (
                        <TableCell align='right'>
                          <IconAction
                            actions={rowActions?.map(action => ({
                              ...action,
                              handler: () => action?.handler(row?.data),
                              hide: action?.hide ? action?.hide(row?.data) : false,
                            }))}
                          />
                        </TableCell>
                      ) : null}
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </MUITable>
      </TableContainer>

      <Pagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setLimit={setLimit}
        setOffset={setOffset}
        totalRecords={totalRecordsCount || data?.length}
        page={page}
        setPage={setPage}
      />
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useSxStyles = (theme?: Theme) => ({
  tableHeadCell: {
    backgroundColor: theme?.palette?.background?.paper,
  },
  headTxt: {
    fontSize: '12px',
    fontWeight: 600,
  },
});

const useStyles = (theme?: Theme) => ({
  table: css`
    overflow-y: hidden;
  `,
  tableSeparator: css`
    border-top: 1px solid #00000020;
  `,
  rowOdd: css`
    background-color: ${theme?.palette.background.paper};
    &:hover,
    &:active {
      background-color: ${theme?.palette.action.hover} !important;
    }
    cursor: pointer;
    transition: 0.1s;
  `,
  rowEven: css`
    background-color: #f8f8f8;
    &:hover,
    &:active {
      background-color: ${theme?.palette.action.hover} !important;
    }
    cursor: pointer;
    transition: 0.1s;
  `,
});
