// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';

import { Moves, useGetVehiclesByStockLazyQuery } from '@gql/schema';

import { css, cx } from '@emotion/css';
import { Theme, useTheme, Autocomplete, InputAdornment, TextField } from '@mui/material';

import { BiLabel } from 'react-icons/bi';

// TYPES ---------------------------------------------------------------- //

interface StockLookupProps {
  rooftopId?: number;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  name?: string;
  label?: string;
  placeholder?: string;
  helperText?: string;
  value?: string | null;
  onChange?: (vehicle?: Moves | null) => void;
  onInputChange?: (input?: string | null) => void;
  onFetch?: (vehicles?: Moves[]) => void;
  queryCall?: `never` | `onMount` | `onOpen`;

  [x: string]: any;
}

// COMPONENT ---------------------------------------------------------------- //

const StockLookup = ({
  rooftopId,
  required,
  disabled,
  error,
  name = `stock-lookup`,
  label = ``,
  placeholder = `Enter stock...`,
  helperText = ``,
  value = null,
  onChange,
  onInputChange,
  onFetch,
  queryCall = `onOpen`,
  ...rest
}: StockLookupProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const [getVehicles] = useGetVehiclesByStockLazyQuery();

  const [vehicles, setVehicles] = React.useState<Moves[]>([]);
  const [vehicleStock, setVehicleStock] = React.useState<string | null>(value || null);

  const [internalError, setInternalError] = React.useState<boolean>(false);
  const [internalErrorText, setInternalErrorText] = React.useState<string>(``);

  const [loading, setLoading] = React.useState<boolean>(false);

  /** Fetch query and set state */
  const fetchVehiclesAndSetState = async () => {
    setLoading(true);

    try {
      const res = await getVehicles({
        variables: { rooftopId: rooftopId, afterDate: dayjs().subtract(3, `month`).format(`YYYY-MM-DD`) },
      });

      const resVehicles = res?.data?.moves || [];
      const defaultVehicleStock = value || null;

      setVehicles(resVehicles);
      setVehicleStock(defaultVehicleStock);

      if (onFetch) onFetch(resVehicles);

      setInternalError(false);
      setInternalErrorText(``);
    } catch (err) {
      console.error(`Failed to fetch vehicles by stock:`, err);
    }

    setLoading(false);
  };

  /** Handle changing the value */
  const handleValueChange = (value?: string | null) => {
    const selectedValue = value?.toUpperCase()?.trim() || null;
    const selectedVehicle = vehicles.find(v => v?.vehicle_stock?.toUpperCase()?.trim() === selectedValue) || null;
    setVehicleStock(selectedValue);
    if (selectedVehicle && onChange) onChange(selectedVehicle);
  };

  /** Handle changing the input */
  const handleInputChange = (input?: string | null) => {
    const editedInput = input?.toUpperCase()?.trim() || null;
    if (onInputChange) onInputChange(editedInput);
  };

  /** Handle opening the dropdown */
  const handleOpen = async () => {
    if (queryCall === `onOpen`) fetchVehiclesAndSetState();
  };

  // Fetch on mount instead of on open
  React.useEffect(() => {
    if (queryCall === `onMount`) fetchVehiclesAndSetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set the value when it comes in as a prop
  React.useEffect(() => {
    setVehicleStock(value);
  }, [value]);

  return (
    <div className={cls.stock}>
      {label ? (
        <label className={cls.stockLabel} htmlFor={name}>
          {label}
          {required ? <span className={cls.stockLabelRequired}> *</span> : null}
        </label>
      ) : null}

      <Autocomplete
        {...rest}
        fullWidth
        freeSolo
        includeInputInList
        disabled={disabled}
        loading={loading}
        loadingText={`Loading stocks...`}
        noOptionsText={`No stocks found`}
        options={vehicles?.map(v => v?.vehicle_stock) || []}
        getOptionKey={o => o || ``}
        getOptionLabel={o => o || ``}
        value={vehicleStock || null}
        onChange={(_, value) => handleValueChange(value)}
        onInputChange={(_, input) => handleInputChange(input)}
        onOpen={() => handleOpen()}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            required={required}
            error={internalError || error}
            name={name}
            placeholder={placeholder}
            helperText={internalErrorText || helperText}
            slotProps={{
              htmlInput: {
                ...params?.inputProps,
                maxLength: 24,
              },
              input: {
                ...params?.InputProps,
                startAdornment: (
                  <InputAdornment position='start' sx={{ marginLeft: `8px`, marginRight: 0 }}>
                    <BiLabel className={cx(cls.stockIcon, disabled ? cls.stockIconDisabled : null)} />
                  </InputAdornment>
                ),
              },
            }}
          />
        )}
      />
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  const styles = {
    stock: css`
      position: relative;
    `,
    stockLabel: css`
      display: block;
      margin-bottom: 2px;
      margin-left: 6px;
      font-size: 12px;
      font-weight: 600;
    `,
    stockLabelRequired: css`
      color: ${theme?.palette?.error?.main};
    `,
    stockIcon: css`
      display: block;
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      color: ${theme?.palette?.action?.active};
    `,
    stockIconDisabled: css`
      color: ${theme?.palette?.action?.disabled};
    `,
  };

  return styles;
};

// EXPORT ---------------------------------------------------------------- //

export default StockLookup;
