import React from 'react';
import { toast } from 'react-toastify';
import { getPropValue } from '@services/utilityService';
import { useUser } from '@store';
import {
    useGetAllowedCustomersLazyQuery,
    useGetPayersPayeesLazyQuery,
    useGetRoleTypesLazyQuery,
    useGetAllowedRolesLazyQuery,
    useUpsertAllowedCustomersMutation,
    useUpsertAllowedRolesMutation,
    useSaveUserDetailsMutation,
    useTerminateUserMutation,
    useReinstateUserMutation,
    useCreateUserMutation,
    useRemoveAllowedCustomerMutation,
    useRemoveAllowedRoleMutation,
    useGetOrgCustomersLazyQuery,
    Customers,
    Usertocustomers,
    Roletypes,
    Usertoroles,
    Payertocustomer,
} from '@gql/schema';
import { 
    UserDetailsContextType, 
    UserDetailsProviderProps, 
    Validation,  
    UserResponse 
} from '../types/userDetailsTypes';

const log = false;

export const UserDetailsContext = React.createContext<UserDetailsContextType | undefined>(undefined);

export function UserDetailsProvider({ children, user, handleRefetch }: UserDetailsProviderProps) {

  const currentUser = useUser();

  const [getAllowedCustomersLazyQuery] = useGetAllowedCustomersLazyQuery();
    const [getPayersPayeesLazyQuery] = useGetPayersPayeesLazyQuery();
    const [getRoleTypesLazyQuery] = useGetRoleTypesLazyQuery();
    const [getAllowedRolesLazyQuery] = useGetAllowedRolesLazyQuery();
    const [upsertAllowedCustomersMutation] = useUpsertAllowedCustomersMutation();
    const [upsertAllowedRolesMutation] = useUpsertAllowedRolesMutation();
    const [saveUserDetailsMutation] = useSaveUserDetailsMutation();
    const [terminateUserMutation] = useTerminateUserMutation();
    const [reinstateUserMutation] = useReinstateUserMutation();
    const [createUserMutation] = useCreateUserMutation();
    const [removeAllowedCustomerMutation] = useRemoveAllowedCustomerMutation();
    const [removeAllowedRoleMutation] = useRemoveAllowedRoleMutation();
    const [getOrgCustomersLazyQuery] = useGetOrgCustomersLazyQuery();

  // Base State
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  // User Fields
  const [active, setActive] = React.useState<boolean | null>(null);
  const [displayName, setDisplayName] = React.useState<string | null>(null);
  const [email, setEmail] = React.useState<string | null>(null);
  const [role, setRole] = React.useState<string | null>(null);
  const [avatarUrl, setAvatarUrl] = React.useState<string | null>(null);
  const [updatedBy, setUpdatedBy] = React.useState<string | null>(null);
  const [phone, setPhone] = React.useState<string | null>(null);
  const [userId, setUserId] = React.useState<number | null>(null);

  // For building Hasura claims
  const [roles, setRoles] = React.useState< Roletypes[] | null>(null);
  const [allowedRoles, setAllowedRoles] = React.useState<Usertoroles[] | null>(null);
  const [allowedRoleNames, setAllowedRoleNames] = React.useState<string[] | null>(null);
  const [customers, setCustomers] = React.useState<Customers[]>([]);
  const [allowedCustomers, setAllowedCustomers] = React.useState<Usertocustomers[] | Customers[] | []>([]);
  const [customerId, setCustomerId] = React.useState<number | null>(null);
  const [payers, setPayers] = React.useState<Payertocustomer[] | null>(null);
  const [payees, setPayees] = React.useState<Payertocustomer[] | null>(null);
  const [originalRoleIds, setOriginalRoleIds] = React.useState<number[] | null>(null);
  const [originalRoles, setOriginalRoles] = React.useState<Usertoroles[] | null>(null);
  const [originalCustomerIds, setOriginalCustomerIds] = React.useState<number[] | null>(null);
  const [allowedRoleIds, setAllowedRoleIds] = React.useState<number[]>([]);
  const [allowedCustomerIds, setAllowedCustomerIds] = React.useState<number[]>([]);
  const [validation, setValidation] = React.useState<Validation | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [originalCustomers, setOriginalCustomers] = React.useState<Usertocustomers[] | null>(null);

  //get the base lists of customers, and roles to be used as selection options
  React.useEffect(() => {
    if ((!customers || !customers.length)) {
      fetchCustomers();
    }
    if (!roles || !roles.length) {
      fetchRoles();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers, roles]);


  const fetchRoles = async () => {
    const res = await getRoleTypesLazyQuery();
    const newRoletypes = getPropValue(res, `data.roletypes`);
    const typedNewRoletypes = newRoletypes as Roletypes[];
    if (typedNewRoletypes && typedNewRoletypes.length) {
      setRoles(typedNewRoletypes);
    }
  };

    const fetchCustomers = async () => {
        const res = await getOrgCustomersLazyQuery({ variables: { customerId: user?.customer_id ? user.customer_id : currentUser?.profile?.customer_id ? currentUser?.profile?.customer_id : 0 } });
        const newCustomers = res?.data?.organizations?.length && res.data.organizations[0].customers?.length > 0 ? res.data.organizations[0].customers : [];
        const typedNewCustomers = newCustomers as Customers[];
        if (typedNewCustomers && typedNewCustomers.length) {
            setCustomers(typedNewCustomers);
        }
    };

    React.useEffect(() => {
      if (currentUser?.profile?.customer_id) {
        setCustomerId(currentUser.profile.customer_id);
      }
    }, [currentUser])

  // Set state on user upon receiving prop when on user details page
  React.useEffect(() => {
    if (user && Object.keys(user).length > 0) {  // Only run if user has actual properties
      setActive(user?.active ? user.active : false);
      setDisplayName(user.display_name || null);
      setEmail(user.email || null);
      setRole(user.default_role || null);
      setAvatarUrl(user.avatar_url || null);
      setPhone(user.phone || null);
      setUserId(user?.id ? user.id : null);
      setCustomerId(user.customer_id ? user.customer_id : null);

      if (user?.id) {
        fetchAllowedRoles(user.id);
        fetchAllowedCustomers(user.id);
      }
    }
  }, [user]);

  //Reset values when edit mode is disabled
  React.useEffect(() => {
    if (!editMode && user && Object.keys(user).length > 0) {
      setActive(user?.active ? user.active : false);
      setDisplayName(user.display_name || null);
      setEmail(user.email || null);
      setRole(user.default_role || null);
      setAvatarUrl(user.avatar_url || null);
      setPhone(user.phone || null);
      setUserId(user?.id ? user.id : null);
      setCustomerId(user.customer_id ? user.customer_id : null);

      if (originalCustomers && originalCustomers.length > 0 && originalCustomerIds && originalCustomerIds.length > 0 ) {
        setAllowedCustomers(originalCustomers);
        setAllowedCustomerIds(originalCustomerIds);
      } else {
        fetchAllowedCustomers(user.id);
      }

      if (originalRoles && originalRoles.length > 0 && originalRoleIds && originalRoleIds.length > 0) {
        setAllowedRoles(originalRoles);
        setAllowedRoleIds(originalRoleIds);
      } else {
        fetchAllowedRoles(user.id);
      }
    }
  }, [editMode])

  //Once we have the user's allowed role objects, separate out the name strings.
  //This will make it easier to use conditional endering in the UI components.
  React.useEffect(() => {
    const roleNameArray: string[] = [];
    if (allowedRoles && allowedRoles.length > 0) {
      allowedRoles.forEach(r => {
        if (r.roletype && r.roletype.name) {
          roleNameArray.push(r.roletype.name);
        } else {
            const role = r as unknown as Roletypes;
            if (role.name) {
                roleNameArray.push(role.name);
              }
        } 
      });
    }
    setAllowedRoleNames(roleNameArray);
  }, [allowedRoles]);

  // React.useEffect(() => {
  //   //Get related customer permissions
  //   //commented out because not yet in use
  //   if (customerId) {
  //     fetchAllowedPayees(customerId);
  //     fetchAllowedPayers(customerId);
  //   }
  // }, [customerId]);

  const fetchAllowedRoles = async (userId: number) => {
    const origRoles: number[] = [];
    const res = await getAllowedRolesLazyQuery({ variables: { userId } });
    let typedUserToRoles: Usertoroles[] = [];
    const userToRoles = getPropValue(res, `data.usertoroles`);
    if (userToRoles) {
        typedUserToRoles = userToRoles as Usertoroles[];
        if (typedUserToRoles?.length > 0) {
            setAllowedRoles(userToRoles as Usertoroles[]);
            setOriginalRoles(userToRoles as Usertoroles[]);
        }

      //Store initial allowed roles so that later we can check if one has been removed and we need to delete it
      typedUserToRoles.forEach(role => {
        origRoles.push(role.role_id);
      });
      setOriginalRoleIds(origRoles);
      setAllowedRoleIds(origRoles);
    }
  };

  const fetchAllowedCustomers = async (userId: number) => {
    const origCustomers: number[] = [];
    const res = await getAllowedCustomersLazyQuery({ variables: { userId } });
    const userToCustomers = getPropValue(res, `data.usertocustomers`);
    if (userToCustomers) {
        const typedUserToCustomers = userToCustomers as Usertocustomers[];
        if (typedUserToCustomers?.length > 0) {
            setAllowedCustomers(typedUserToCustomers);
            setOriginalCustomers(typedUserToCustomers);
            
            typedUserToCustomers.forEach(customer => {
                origCustomers.push(customer.customer_id);
            });
            setOriginalCustomerIds(origCustomers);
            setAllowedCustomerIds(origCustomers);
        }
    }
  };

  const fetchAllowedPayees = async (customerId: number) => {
    const allowedPayees: Payertocustomer[] = [];
    const res = await getPayersPayeesLazyQuery({ variables: { customerId } });
    const payerToCustomer = getPropValue(res, `data.payertocustomer`);
    if (payerToCustomer) {
        const typedPayerToCustomer = payerToCustomer as Payertocustomer[];
        if (typedPayerToCustomer?.length > 0) {
            typedPayerToCustomer.forEach(p => {
                if (p.payer_id === customerId) {
                  allowedPayees.push(p);
                }
              });
        }

    }
    setPayees(allowedPayees);
  };

  const fetchAllowedPayers = async (customerId: number) => {
    const allowedPayers: Payertocustomer[] = [];
    const res = await getPayersPayeesLazyQuery({ variables: { customerId } });
    const payerToCustomer = getPropValue(res, `data.payertocustomer`);
    if (payerToCustomer) {
        const typedPayerToCustomer = payerToCustomer as Payertocustomer[];
        if (typedPayerToCustomer.length > 0) {
            typedPayerToCustomer.forEach(p => {
                if (p.payee_id === customerId) {
                  allowedPayers.push(p);
                }
              });
            }
        }
    setPayers(allowedPayers);
  };

  //Determine if any allowances were removed during the editing session
  const findRecordsToRemove = (original: number[], current: number[]) => {
    const missingIds: number[] = [];
    original.forEach(o => {
      if (current && current.length > 0 && !current.includes(o)) {
        missingIds.push(o);
      }
    });
    return missingIds;
  };

  const validateForm = () => {
    let valid = true;

    const isValidDisplayName = (username: string | null) => {
      if (!username || username?.length < 2) {
        return false;
      } else return true;
    };

    const isValidEmail = (email: string | null) => {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (email) return emailPattern.test(email.toLowerCase()); else return false;
    };

    const isValidUrl = (url: string | null) => {
      const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
      if (url) return urlPattern.test(url); else return false
    };

    const isValidPhoneNumber = (phoneNumber: string | null) => {
      if (!phoneNumber) return false;
      const digitsOnly = phoneNumber.replace(/\D/g, '');
      const phonePattern = /^\d{10}$/;

      return phonePattern.test(digitsOnly);
    };

    const isCustomersValid = (allowedCustomers: object[] | null) => {
        if (role && (role === 'dealer-admin' || role === 'dealer-super-admin')) {
            const typedAllowedCustomers = allowedCustomers as object[];
            if (!(typedAllowedCustomers?.length > 1)) {
                return false
            }
        }
        return true
    }

    const isCustomerValid = (customerId: number | null) => {
        if (!customerId) {
          return false
        }
      return true
    }

    const newValidation = {
      displayName: isValidDisplayName(displayName),
      email: isValidEmail(email),
      avatar: avatarUrl ? isValidUrl(avatarUrl) : true,
      phone: phone ? isValidPhoneNumber(phone) : true,
      defaultRole: role ? true : false,
      customer: isCustomerValid(customerId),
      customers: isCustomersValid(allowedCustomers)
    };

    setValidation(newValidation);

    Object.keys(newValidation).forEach((key, i) => {
      const value = Object.values(newValidation)[i];
      if (value === false) {
        valid = false;
        setIsSaving(false);
      }
    });

    return {valid: valid, validation: newValidation};
  };

  //If the form is valid: 
  // 1) Remove allowances as needed
  // 2) Insert new allowances
  // 3) Update the user record, which additionally will trigger a build of Firebase claims based on the allowances
  const handleSave = async () => {
    setIsSaving(true);

    const formValidation = validateForm();

    if (!formValidation) {
        toast.error(`Failed to validate form`)
    } else if (!formValidation.valid) {
        if (formValidation.validation.customers === false) {
            toast.error(`Dealer-admins must have at least one allowed customer.`);
        } else toast.error(`Failed to save. Please check the form for errors.`);
    } else {
      let customersToRemove;
      let rolesToRemove;

      if (
        allowedRoles &&
        allowedRoles.length &&
        allowedRoles.length > 0 &&
        originalRoleIds &&
        originalRoleIds.length > 0
      ) {
        rolesToRemove = findRecordsToRemove(originalRoleIds, allowedRoleIds);
      }

      if (
        allowedCustomers &&
        allowedCustomers.length &&
        allowedCustomers.length > 0 &&
        originalCustomerIds &&
        originalCustomerIds.length > 0
      ) {
        customersToRemove = findRecordsToRemove(originalCustomerIds, allowedCustomerIds);
      }

      try {
        if (rolesToRemove && rolesToRemove.length > 0) {
          for (let i = 0; i < rolesToRemove.length; i++) {
            const res = await removeAllowedRoleMutation({ variables: { roleId: rolesToRemove[i], userId: userId ?? 0} });
            log && console.log('Role removal res', res)
          }
        }

        if (customersToRemove && customersToRemove.length > 0) {
          for (let i = 0; i < customersToRemove.length; i++) {
            const res = await removeAllowedCustomerMutation({ variables: { customerId: customersToRemove[i], userId: userId ?? 0 } });
            log && console.log('Customer removal res', res)
          }
        }
      } catch (err) {
        console.log('Error removing allowances', err);
      }

    const allowedCustomersObj =
        allowedCustomers &&
        allowedCustomers.length &&
        allowedCustomers.length > 0 &&
        allowedCustomers.map((c) => {
            const userToCustomer = c as Usertocustomers;
            const customer = c as Customers;
            return {
                customer_id: userToCustomer.customer_id ?? customer.id,
                user_id: user?.id,
                updated_at: 'now()',
                updated_by: currentUser?.profile?.email,
                created_at: 'now()',
                created_by: currentUser?.profile?.email,
            };
        });

      const allowedRolesObj =
        allowedRoles &&
        allowedRoles.length &&
        allowedRoles.length > 0 &&
        allowedRoles.map(r => {
          return {
            role_id: r.role_id || r.id,
            user_id: user?.id,
            updated_at: 'now()',
            updated_by: currentUser?.profile?.email,
            created_at: 'now()',
            created_by: currentUser?.profile?.email,
          };
        });

      try {
        if (allowedCustomersObj) {
            const res = await upsertAllowedCustomersMutation({ variables: { allowedCustomersObj: allowedCustomersObj } });
            log && console.log('allowedCustomers upsert', res)
        }
        if (allowedRolesObj) {
            const res = await upsertAllowedRolesMutation({ variables: { allowedRolesObj: allowedRolesObj } });
            log && console.log('allowedRoles upsert', res)
        }
      } catch (err) {
        console.log('Error upserting new allowances:', err);
        toast.error('Failed to update allowances. User permissions could not be rebuilt');
        setIsSaving(false);
      }

      try {
        const saveUserRes = await handleSaveUserChanges(user?.id);
        log && console.log('saveUserRes', saveUserRes)
        setIsSaving(false);
        setEditMode(false);
        if (handleRefetch) {
          handleRefetch();
        }
      } catch (err) {
        console.log('Failed to update user', err);
        setIsSaving(false);
        setEditMode(false);
      }
    }
  };

  const handleSaveUserChanges = async (userId?: number): Promise<boolean> => {
    setIsSaving(true);

    try {
      const variables = {
        userId: userId ? userId : 0,
        userObj: {
          active: active || true,
          email: email ? email.trim().toLowerCase() : null,
          phone: phone ? phone.trim() : null,
          avatar_url: avatarUrl ? avatarUrl : null,
          default_role: role ? role : null,
          display_name: displayName ? displayName : null,
          updated_at: 'now()',
          customer_id: customerId ? customerId : null,
        },
      };

      const res = await saveUserDetailsMutation({ variables });
      if (res && res.data) {
        const affectedRows = getPropValue(res, `data.update_users.affected_rows`);
        const typedAffectedRows = affectedRows as object[];
        if (typedAffectedRows.length > 0) {
          log && console.log(`Successfully updated user record:`, res.data.update_users);
          toast.success(`Successfully updated User!`);
          
          const newCustomers = (res?.data as unknown as UserResponse)?.update_users?.returning[0]?.users_usertocustomers;

          setAllowedCustomers(newCustomers ? [newCustomers as Usertocustomers] : []);
          setOriginalCustomers(newCustomers ? [newCustomers as Usertocustomers] : null);
          const newCustomerIds = newCustomers ? [newCustomers as Usertocustomers].map((c) => c.customer_id) : [];
          setAllowedCustomerIds(newCustomerIds);
          setOriginalCustomerIds(newCustomerIds);

          setEditMode(false);
          return true;
        }
      }
      setEditMode(false);
      return false;
    } catch (err) {
      toast.error(`Failed to update user!`);
      console.error(`Failed to update user:`, err);
      setEditMode(false);
      return false;
    } finally {
      setIsSaving(false);
    }
};

  const upsertPermissions = async (userId: number) => {
      
    const allowedCustomersObj =
      allowedCustomers &&
      allowedCustomers.length &&
      allowedCustomers.length > 0 &&
      allowedCustomers.map(c => {
        const customer = c as Customers;
        const userToCustomer = c as Usertocustomers;
        return {
          customer_id: userToCustomer.customer_id || customer.id,
          user_id: userId,
          updated_at: 'now()',
          updated_by: currentUser?.profile?.email,
          created_at: 'now()',
          created_by: currentUser?.profile?.email,
        };
      });

    const allowedRolesObj =
      allowedRoles &&
      allowedRoles.length &&
      allowedRoles.length > 0 &&
      allowedRoles.map(r => {
        return {
          role_id: r.role_id || r.id,
          user_id: userId,
          updated_at: 'now()',
          updated_by: currentUser?.profile?.email,
          created_at: 'now()',
          created_by: currentUser?.profile?.email,
        };
      });

    try {
      if (allowedCustomersObj) {
        const res = await upsertAllowedCustomersMutation({ variables: { allowedCustomersObj: allowedCustomersObj } });
        log && console.log('allowedCustomers upsert', res)
        // const res = await apolloClient.mutate({ mutation: UPSERT_ALLOWED_CUSTOMERS, variables: { allowedCustomersObj: allowedCustomersObj } });
      }
      if (allowedRolesObj) {
        const res = await upsertAllowedRolesMutation({ variables: { allowedRolesObj: allowedRolesObj } });
        log && console.log('allowedRoles upsert', res)
      }
      return true
    } catch (err) {
      console.log('Error upserting new allowances:', err);
      toast.error('Failed to update allowances. User permissions could not be rebuilt');
      return false
    }
  };

  const handleCreateUser = async (): Promise<{ success: boolean; userId?: number }> => {
    setIsSaving(true);

    const formValidation = validateForm();
    if (!formValidation.valid) {
        toast.error(`Please check the form for errors.`)
        setIsSaving(false);
        return { success: false };
    }

    try {
        const result = await createUserMutation({
            variables: {
                userObj: {
                    active: true,
                    email: email ? email.toLowerCase().trim() : null,
                    phone: phone ? phone.trim() : null,
                    avatar_url: avatarUrl ? avatarUrl : null,
                    default_role: role ? role : null,
                    display_name: displayName ? displayName : null,
                    created_at: 'now()',
                    updated_at: 'now()',
                    customer_id: customerId ? customerId : null,
                }
            }
        });

        const newUserId = result.data?.insert_users?.returning?.[0]?.id;
        if (newUserId) {
            setUserId(newUserId);
            await upsertPermissions(newUserId);
            await handleSaveUserChanges(newUserId);
            toast.success(`Successfully created User ${newUserId}!`);
            if (handleRefetch) {
                handleRefetch();
            }
            return { success: true, userId: newUserId };
        }
        
        return { success: false };

    } catch (err: unknown) {
        if ((err as { graphQLErrors?: { extensions?: { code: string } }[] }).graphQLErrors?.[0]?.extensions?.code === 'constraint-violation') {
            toast.error(`A user with this email already exists`);
        } else {
            toast.error(`Failed to create user`);
            console.error('Create user error:', err);
        }
        return { success: false };
    } finally {
        setIsSaving(false);
    }
};

  //Gear menu actions
  const terminateUser = async (userId: number) => {
    const res = await terminateUserMutation({ variables: { userId } });
    const terminatedUser = getPropValue(res, `data.update_users.affected_rows`);
    if (terminatedUser) {
      return true;
    } else return false;
  };

  const reinstateUser = async (userId: number) => {
    const res = await reinstateUserMutation({ variables: { userId } });
    const reinstatedUser = getPropValue(res, `data.update_users.affected_rows`);
    if (reinstatedUser) {
      return true;
    } else return false;
  };

  // Set Context
  const context: UserDetailsContextType = {
    // Base State
    editMode,
    setEditMode,
    isSaving,
    setIsSaving,

    // Edit Vars
    active,
    phone,
    role,
    email,
    displayName,
    avatarUrl,
    updatedBy,
    allowedRoles,
    allowedRoleNames,
    allowedCustomers,
    payers,
    payees,
    customers,
    customerId,
    roles,
    allowedCustomerIds,
    allowedRoleIds,
    validation,

    // Set Edit Vars
    setActive,
    setRole,
    setEmail,
    setPhone,
    setDisplayName,
    setUpdatedBy,
    setAvatarUrl,
    setAllowedRoles,
    setAllowedRoleNames,
    setAllowedCustomers,
    setPayers,
    setPayees,
    setCustomers,
    setRoles,
    setCustomerId,
    setAllowedCustomerIds,
    setAllowedRoleIds,
    setValidation,

    // Handler Functions
    handleSaveUserChanges,
    terminateUser,
    reinstateUser,
    handleSave,
    handleCreateUser,
    upsertPermissions,
    fetchAllowedRoles,
    fetchAllowedCustomers,
    fetchAllowedPayees,
    fetchAllowedPayers,
    fetchCustomers,
    findRecordsToRemove,
    validateForm
  };

  return <UserDetailsContext.Provider value={context}>{children}</UserDetailsContext.Provider>;
}
