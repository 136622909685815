// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';

import { Arinvoices } from '@gql/schema';

import { capFirst, clampNegNum, getMonetaryValue } from '@services/formatService';

import { css } from '@emotion/css';
import {
  Button,
  Checkbox,
  Icon,
  Paper,
  Table,
  TableContainer,
  TablePagination,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { BiSolidAdjust, BiSolidBadgeDollar, BiSolidCheckCircle, BiSolidCommentError } from 'react-icons/bi';

import InvoiceDetailsTableBody from '@features/invoices/components/InvoiceDetailsTableBody';
import InvoiceDetailsTableHead from '@features/invoices/components/InvoiceDetailsTableHead';

const defaultOrder = `desc`;
const defaultOrderBy = `MOVE_ID`;

// TYPES ---------------------------------------------------------------- //

type InvoiceDetailsTableProps = {
  invoice?: Arinvoices;
  armoves?: any[];
  amount?: any;
  checkedArmoves?: any[];
  setCheckedArmoves?: any;
  handleDisputeModalOpen?: any;
  handlePayNowModalOpen?: any;
};

// COMPONENT ---------------------------------------------------------------- //

const InvoiceDetailsTable = ({
  invoice,
  armoves,
  amount,
  checkedArmoves,
  setCheckedArmoves,
  handleDisputeModalOpen,
  handlePayNowModalOpen,
}: InvoiceDetailsTableProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);
  const clsx = useSxStyles(theme);

  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  // Reset checkedArmoves when the invoice changes
  React.useEffect(() => {
    setCheckedArmoves([]);
  }, [invoice?.id, setCheckedArmoves]);

  const payable = amount?.due > 0 && invoice?.status === `closed`;

  // See if the armove is checked based on ID found in the checkedArmoves array
  const isItemChecked = (id: string) => checkedArmoves?.indexOf(id) !== -1;

  // Handle the row checkbox when clicked
  const handleCheckOne = (event: any, name: string) => {
    event.stopPropagation();
    const checkedIndex = checkedArmoves?.indexOf(name);
    let newChecked: any[] = [];

    if (checkedIndex === -1) {
      newChecked = newChecked.concat(checkedArmoves, name);
    } else if (checkedIndex === 0) {
      newChecked = newChecked.concat(checkedArmoves?.slice(1));
    } else if (checkedIndex === checkedArmoves?.length - 1) {
      newChecked = newChecked.concat(checkedArmoves?.slice(0, -1));
    } else if (checkedIndex > 0) {
      newChecked = newChecked.concat(checkedArmoves?.slice(0, checkedIndex), checkedArmoves?.slice(checkedIndex + 1));
    }
    setCheckedArmoves(newChecked);
  };

  // Handle the header checkbox when clicked
  const handleCheckAll = (event: any, rowArr: any[]) => {
    // Filter the rows and get the IDs
    const rowIdArr = rowArr?.filter((row: any) => row?.status !== 'paid')?.map((row: any) => row?.id);

    // Check/Uncheck all based on items that are currently checked
    if (checkedArmoves?.length > 0 && checkedArmoves?.length === rowIdArr?.length) setCheckedArmoves([]);
    else setCheckedArmoves(rowIdArr);
  };

  // Header checkbox component that checks/unchecks all unpaid moves if clicked. If all armoves are paid, it is disabled.
  const HeaderCheckbox = () => {
    const paidArmoves = armoves?.filter(
      (armove: any) => armove?.due_amount - armove?.discount_amount <= armove?.paid_amount
    );
    const unpaidArmoves = armoves?.filter(
      (armove: any) => armove?.due_amount - armove?.discount_amount > armove?.paid_amount
    );
    return (
      <Checkbox
        sx={clsx.checkbox}
        color='primary'
        onClick={event => handleCheckAll(event, armoves)}
        checked={checkedArmoves?.length !== 0 && unpaidArmoves?.length === checkedArmoves?.length}
        indeterminate={paidArmoves?.length !== 0 && paidArmoves?.length === armoves?.length}
        disabled={true}
        style={{ display: 'none' }}
        // disabled={paidArmoves.length !== 0 && paidArmoves.length === armoves.length}
      />
    );
  };

  // Handle page state
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  // Handle pagination rows state
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Set headers array used in the table
  const headers = [
    { id: `CHECKBOX`, align: `left`, label: `Checkbox`, component: HeaderCheckbox() },
    { id: `MOVE_ID`, align: `left`, label: `HopDrive\xa0ID` },
    { id: `REF_NUM`, align: `left`, label: `Ref\xa0#` },
    { id: `MOVE_DATE`, align: `left`, label: `Move\xa0Date` },
    { id: `LANE`, align: `left`, label: `Lane` },
    { id: `DLV_REP`, align: `left`, label: `Rep`, hide: invoice.customer.id !== 2 },
    { id: `DISTANCE`, align: `left`, label: `Distance` },
    { id: `WEIGHT_CLASS`, align: `left`, label: `Vehicle\xa0Weight\xa0Class` },
    { id: `COST`, align: `right`, label: `Cost` },
  ];

  // A cleaner way to build the row object for the table (stronger logic when it comes to error handling)
  const buildRowObject = (armove = null) => {
    // Initialize the row object
    let row: any = {};

    // Check for armove and set each row field
    if (armove) {
      const transportDetail = armove?.details?.find((detail: any) => detail?.name === `Transport`);
      row.armove = armove;
      row.CHECKBOX = (
        <Checkbox
          sx={clsx.checkbox}
          color='primary'
          onClick={event => handleCheckOne(event, armove?.id)}
          checked={isItemChecked(armove?.id)}
          disabled={true}
          style={{ display: 'none' }}
          // disabled={armove.due_amount - armove.discount_amount <= armove.paid_amount}
          indeterminate={armove?.due_amount - armove?.discount_amount <= armove?.paid_amount}
        />
      );

      if (armove?.move) {
        row.MOVE_ID = armove?.move?.id ? `#${armove?.move?.id}` : null;
        row.REF_NUM = armove?.move?.reference_num || null;

        if (armove?.move?.lane) {
          row.LANE = armove?.move?.lane?.description || null;
          row.DISTANCE = armove?.move?.lane?.distance_miles ? `${armove?.move?.lane?.distance_miles} mi` : `0 mi`;
        }

        row.DLV_REP =
          armove?.move?.tags && armove?.move?.tags?.includes(`delivery reported`) ? (
            <Icon fontSize='small' style={{ margin: 0 }}>
              backup
            </Icon>
          ) : null;
      }

      row.MOVE_DATE = armove?.billable_datetime ? dayjs(armove?.billable_datetime).utc().format(`MM/DD/YYYY`) : null;
      row.WEIGHT_CLASS =
        transportDetail && transportDetail?.amount > 0 && transportDetail?.rate_source
          ? transportDetail?.rate_source
          : null;
      row.COST = (
        <div className={cls.rowFlex}>
          {armove?.disputed || armove?.discount_amount > 0 ? (
            <Tooltip
              placement='top'
              title={
                armove?.disputed
                  ? `Record is disputed - ${capFirst(armove?.dispute_reason)}`
                  : `Record is discounted for $${armove?.discount_amount?.toFixed(2)} - ${capFirst(
                      armove?.discount_reason
                    )}`
              }
            >
              <span>
                {armove?.disputed ? (
                  <BiSolidCommentError className={cls.rowIcon} style={{ color: theme?.palette?.text?.disabled }} />
                ) : (
                  <BiSolidBadgeDollar className={cls.rowIcon} style={{ color: theme?.palette?.text?.disabled }} />
                )}
              </span>
            </Tooltip>
          ) : null}

          {armove?.due_amount - armove?.discount_amount <= armove?.paid_amount ? (
            <Tooltip placement='top' title={`Paid - ${getMonetaryValue(armove?.paid_amount)}`}>
              <span>
                <BiSolidCheckCircle className={cls.rowIcon} style={{ color: theme?.palette?.success?.main }} />
              </span>
            </Tooltip>
          ) : armove?.paid_amount > 0 ? (
            <Tooltip placement='top' title={`Partially Paid - ${getMonetaryValue(armove?.paid_amount)}`}>
              <span>
                <BiSolidAdjust className={cls.rowIcon} style={{ color: theme?.palette?.warning?.main }} />
              </span>
            </Tooltip>
          ) : null}

          <Typography sx={clsx.rowText}>
            {armove?.due_amount > 0
              ? `${getMonetaryValue(clampNegNum(armove?.due_amount - armove?.discount_amount))}`
              : armove?.due_amount === 0
                ? getMonetaryValue(0)
                : `-`}
          </Typography>
        </div>
      );
    }
    return row;
  };

  // Set rows array with the armove object attached to each row
  const rows = armoves?.map((armove: any, i: number) => {
    return buildRowObject(armove);
  });

  return (
    <div className={cls.table}>
      <Paper variant='custom'>
        <div style={{ padding: `16px` }}>
          <Typography variant='h5' sx={{ lineHeight: 1, fontWeight: 600 }}>
            Moves List
          </Typography>
        </div>

        <Button
          sx={clsx.payBtn}
          disabled={!payable}
          color='primary'
          onClick={() => handlePayNowModalOpen({ invoice, ...amount })}
        >
          Pay Now
        </Button>

        <TableContainer>
          <Table size='small' aria-label='invoice-table'>
            <InvoiceDetailsTableHead
              headers={headers}
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
            />
            <InvoiceDetailsTableBody
              rows={rows}
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
              handleDisputeModalOpen={handleDisputeModalOpen}
            />
          </Table>
        </TableContainer>

        <div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component='div'
            count={rows?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  table: css`
    position: relative;
  `,

  rowFlex: css`
    display: flex;
    align-items: center;
  `,
  rowIcon: css`
    display: block;
    margin-right: 4px;
    font-size: 20px;
  `,
});

const useSxStyles = (theme: Theme) => ({
  payBtn: {
    position: 'absolute',
    top: '12px',
    right: '12px',
  },

  checkbox: {
    padding: theme.spacing(0.25),
  },

  rowText: {
    display: 'inline',
    color: 'inherit',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
});

// EXPORT ---------------------------------------------------------------- //

export default InvoiceDetailsTable;
