// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { getMonetaryValue } from '@services/formatService';

import { css } from '@emotion/css';
import {
  Paper,
  Theme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

// TYPES ---------------------------------------------------------------- //

type InvoiceDetailsProductsProps = {
  products?: any[];
};

// COMPONENT ---------------------------------------------------------------- //

const InvoiceDetailsProducts = ({ products }: InvoiceDetailsProductsProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);
  const clsx = useSxStyles(theme);

  const headers = [
    { id: `PRODUCT`, align: `left`, label: `Product` },
    { id: `DESCRIPTION`, align: `left`, label: `Description` },
    { id: `COST`, align: `right`, label: `Cost` },
  ];

  const rows: any[] = products?.map(product => {
    return {
      PRODUCT: product.details && product.details.length > 0 ? product.details[0].name : null,
      DESCRIPTION: product.details && product.details.length > 0 ? product.details[0].notes : null,
      COST: getMonetaryValue(product.due_amount, true) || null,
      product: product,
    };
  });

  if (!products?.length) return null;
  return (
    <div className={cls.products}>
      <Paper variant='custom'>
        <div style={{ padding: `16px` }}>
          <Typography variant='h5' sx={{ lineHeight: 1, fontWeight: 600 }}>
            Products List
          </Typography>
        </div>

        <TableContainer>
          <Table size='small'>
            <TableHead>
              <TableRow>
                {headers?.map(header => {
                  return (
                    <TableCell key={`products-head-cell-${header?.id}`} align={header?.align || `left`}>
                      <Typography sx={clsx.headLabelText}>{header?.label || `Label`}</Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows?.map((row, i) => {
                const rowCells = [
                  {
                    value: row.PRODUCT || `-`,
                    align: `left`,
                  },
                  {
                    value: row.DESCRIPTION || `-`,
                    align: `left`,
                  },
                  {
                    value: row.COST || `-`,
                    align: `right`,
                  },
                ];

                return (
                  <React.Fragment key={`product-row-${i}`}>
                    <TableRow>
                      {rowCells && rowCells.length > 0
                        ? rowCells.map((cell, i2) => {
                            return (
                              <TableCell
                                key={`atr-row-${i}-col-${i2}`}
                                align={cell?.align || `left`}
                                style={{
                                  borderBottom: i >= rows.length - 1 ? `none` : `1px solid ${theme?.palette?.divider}`,
                                }}
                              >
                                {cell?.value || null}
                              </TableCell>
                            );
                          })
                        : null}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme: Theme) => ({
  products: css`
    position: relative;
  `,
});

const useSxStyles = (theme: Theme) => ({
  headLabelText: {
    fontSize: '12px',
    fontWeight: 600,
  },
});

// EXPORT ---------------------------------------------------------------- //

export default InvoiceDetailsProducts;
