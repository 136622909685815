import { useEffect, useState } from 'react';
import { Typography, Button, LinearProgress, Grid2 as Grid, Theme, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import DashboardControls from '../components/DashboardControls';
import dashboardService from '../services/dashboardService';
import { useGetDashboardMovesQuery } from '@gql/schema';
import DashboardFilter from '../components/DashboardFilter';
import DashboardGrid from '../components/DashboardGrid';
import { useDashboard } from '../providers/DashboardProvider';
import { Moves } from '@gql/schema';
import { css } from '@emotion/css';
import { useUser, useRooftop } from '@store';
import Spacer from '@components/Utils/Spacer';

interface FilteredMoves {
  activeMoves: Moves[];
  plannedMoves: Moves[];
  finishedMoves: Moves[];
  searchResults: Moves[];
}

//////////////////////////////////// COMPONENT ////////////////////////////////////
const DashboardContent = () => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const ctx = useDashboard();
  const user = useUser();
  const rooftop = useRooftop();

  /////////// State Variables ///////////

  const [mutableMoves, setMutableMoves] = useState<Moves[]>([]);
  const [moves, setMoves] = useState<FilteredMoves>({
    activeMoves: [],
    plannedMoves: [],
    finishedMoves: [],
    searchResults: [],
  });
  const [customerId, setCustomerId] = useState<number>(
    ctx.customerId || rooftop?.id || user?.profile?.customer_id || 0
  );

  useEffect(() => {
    let rooftopId: number = 0;
    if (ctx.customerId) {
      rooftopId = ctx.customerId;
    } else if (rooftop?.id) {
      rooftopId = rooftop.id;
    } else if (user?.profile?.customer_id) {
      rooftopId = user.profile.customer_id;
    }
    setCustomerId(rooftopId);
  }, [ctx.customerId, rooftop, user]);

  useEffect(() => {
    if (ctx.range) handleRangeChange(ctx.range);
  }, [ctx.range]);

  useEffect(() => {
    if (ctx.prelimStart) handleDateChange('start')(ctx.prelimStart);
    if (ctx.prelimEnd) handleDateChange('end')(ctx.prelimEnd);
  }, [ctx.prelimStart, ctx.prelimEnd]);

  const { data, error, loading } = useGetDashboardMovesQuery({
    variables: { start: ctx.start, end: ctx.end, customerId },
    pollInterval: 15000,
  });

  useEffect(() => {
    const mutableData: Moves[] | undefined = data?.moves?.map(move => ({ ...move })) as Moves[];
    const fixedMoves: FilteredMoves = dashboardService.getFixedMoves(
      mutableData,
      ctx.sort,
      ctx.statusTags,
      ctx.makeTags,
      ctx.modelTags,
      ctx.yearTags,
      ctx.colorTags,
      ctx.customerNameTags,
      ctx.userNameTags,
      ctx.workflowsetTags,
      ctx.search
    );
    const firstMoveCustomerId =
      data?.moves?.length && data?.moves[0].customer && data.moves[0].customer.id ? data.moves[0].customer.id : 0;
    // The customer is a third party payer if the query has returned moves from more than one customer
    const moveFromDifferentCustomer = data?.moves.find(move =>
      dashboardService.findOtherCustomerMove(move?.customer?.id, firstMoveCustomerId)
    );
    if (moveFromDifferentCustomer) ctx.setCustomerIsThirdPartyPayer(true);
    setMutableMoves(mutableData);
    setMoves(fixedMoves);
  }, [
    data,
    ctx.search,
    ctx.sort,
    ctx.statusTags,
    ctx.makeTags,
    ctx.modelTags,
    ctx.yearTags,
    ctx.colorTags,
    ctx.customerNameTags,
    ctx.workflowsetTags,
    ctx.userNameTags,
  ]);

  if (error) return <div>Error: {error.message}</div>;

  /////////// Handlers ///////////
  const handleRangeChange = (range: string) => {
    try {
      localStorage.setItem('dashboard-range', range);
      const newStart = dashboardService.getStart(range);
      const newEnd = dashboardService.getEnd(range);

      ctx.setStart(newStart);
      ctx.setEnd(newEnd);
      ctx.setDisablePickers(dashboardService.getDisable(range));
    } catch (error) {
      console.error('Error changing range', error);
    }
  };

  const handleDateChange = (name: string | null) => (event: string | null) => {
    if (!event) return;
    if (name === `start`) {
      const newStart = dayjs(event).startOf(`day`).format();
      const dateIsValid = new Date(newStart).getTime() > 0;
      if (!dateIsValid) return;
      localStorage.setItem(`dashboard-start`, newStart);
      ctx.setStart(newStart);
    } else {
      const newEnd = dayjs(event).endOf(`day`).format();
      const dateIsValid = new Date(newEnd).getTime() > 0;
      if (!dateIsValid) return;
      localStorage.setItem(`dashboard-end`, newEnd);
      ctx.setEnd(newEnd);
    }
  };

  if (loading) return <LinearProgress />;

  return (
    <div className={styles.contain}>
      {mutableMoves?.length > 0 ? (
        <>
          <div className={styles.filter}>
            <DashboardFilter moves={mutableMoves} />
          </div>
        </>
      ) : null}
      <div className={styles.grid}>
        <Grid container spacing={2} alignItems='center' justifyContent='space-between' wrap='nowrap'>
          <Grid>
            <Typography className={styles.head}>Dashboard</Typography>
          </Grid>

          <Grid>
            <Link to='/plan-moves'>
              <Button size='large' color='primary' data-testid='add-moves-button' sx={{ whiteSpace: `nowrap` }}>
                Plan Moves
              </Button>
            </Link>
          </Grid>
        </Grid>

        <Spacer />

        <DashboardControls />

        <DashboardGrid moves={moves} />
      </div>
    </div>
  );
};

export default DashboardContent;

const useStyles = (theme?: Theme) => {
  return {
    contain: css`
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: nowrap;
      padding: 12px;
    `,
    filter: css`
      vertical-align: top;
      position: relative;
      margin-right: ${theme?.spacing(2)};
      ${theme?.breakpoints.down('md')} {
        display: none;
      }
    `,
    head: css`
      line-height: 1rem !important;
      font-size: 24px !important;
      font-weight: 600 !important;
      ${theme?.breakpoints?.down('sm')} {
        font-size: 21px !important;
      }
      ${theme?.breakpoints.down('xs')} {
        font-size: 18px !important;
      }
    `,
    grid: css`
      vertical-align: top;
      position: relative;
      width: 100%;
    `,
  };
};
