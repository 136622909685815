// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Action } from 'src/types';
import { copyToClipboard } from '@services/utilityService';

import { css, keyframes } from '@emotion/css';
import { TableCell, TableRow, Theme, useTheme } from '@mui/material';
import { BiCar, BiCommentError, BiCopyAlt } from 'react-icons/bi';

import InvoiceDetailsTableFold from '@features/invoices/components/InvoiceDetailsTableFold';

// TYPES ---------------------------------------------------------------- //

type InvoiceDetailsTableRowProps = {
  row: any;
  foldId: number;
  setFoldId: any;
  handleDisputeModalOpen: any;
};

// COMPONENT ---------------------------------------------------------------- //

const InvoiceDetailsTableRow = ({ row, foldId, setFoldId, handleDisputeModalOpen }: InvoiceDetailsTableRowProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);
  const clsx = useSxStyles(theme);

  const navigate = useNavigate();

  const armove = row.armove || {};
  const move = armove.move || {};

  // Handle fold row state
  const handleFoldRow = (id = 0) => {
    const newFoldId = id === foldId ? 0 : id;
    localStorage.setItem(`invoice-fold-id`, newFoldId.toString());
    setFoldId(newFoldId);
  };

  const formatWeightClass = (inputString: string) => {
    if (!inputString) return '-';
    let formattedString = inputString.replace(/-/g, ' ');
    formattedString = formattedString.replace(/\s+\S+$/, '');
    formattedString = formattedString.replace(/^\w/, (firstWord: string) => firstWord.toUpperCase());
    return formattedString;
  };

  // Set a list of cells to render the row
  const rowCells = [
    {
      value: row.CHECKBOX || `-`,
      align: `left`,
    },
    {
      value: row.MOVE_ID || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.REF_NUM || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.MOVE_DATE || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.LANE || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.DLV_REP || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
      hide: armove.move && armove.move.customer && armove.move.customer.id !== 2,
    },
    {
      value: row.DISTANCE || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: formatWeightClass(row.WEIGHT_CLASS) || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.COST || `-`,
      align: `right`,
      onClick: () => handleFoldRow(armove.id),
    },
  ];

  // Set a list of actions after right-clicking on a row
  const actions: Action[] = [
    {
      label: `Go To Move Details`,
      icon: <BiCar className={cls.menuIcon} />,
      handler: () => navigate(`/moves/${move?.id}`),
    },
    {
      label: armove?.disputed === false ? `Dispute Charges` : `Cancel Dispute`,
      icon: <BiCommentError className={cls.menuIcon} />,
      handler: () => handleDisputeModalOpen(armove),
      disabled: armove?.paid_amount >= armove?.due_amount,
    },
    {
      label: `Copy Reference #`,
      icon: <BiCopyAlt className={cls.menuIcon} />,
      handler: () => copyToClipboard(move?.reference_num),
      hide: !move?.reference_num || (move?.customer && move?.customer?.id !== 2),
    },
    {
      label: `Copy Delivery Timestamp`,
      icon: <BiCopyAlt className={cls.menuIcon} />,
      handler: () => copyToClipboard(move?.delivery_successful),
      hide: !move?.delivery_successful,
    },
  ];

  return (
    <>
      <TableRow sx={clsx.row}>
        {rowCells.map((cell, i) => {
          if (!cell.hide)
            return (
              <React.Fragment key={`invoice-cell-${armove.id}-${i}`}>
                <TableCell
                  sx={armove.disputed ? clsx.cellDisputed : clsx.cell}
                  align={cell.align || `left`}
                  onClick={cell.onClick || undefined}
                >
                  {cell.value || null}
                </TableCell>
              </React.Fragment>
            );
          else return null;
        })}
      </TableRow>

      {foldId === armove?.id ? (
        <TableRow sx={clsx.foldRow}>
          <TableCell sx={clsx.foldCell} colSpan={rowCells?.length || 0}>
            <div className={cls.fold}>
              <InvoiceDetailsTableFold armove={armove} actions={actions} />
            </div>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;

  return {
    fold: css`
      animation: ${fadeIn} 0.667s;
    `,

    menuIcon: css`
      display: block;
      margin-top: -1px;
      margin-right: 8px;
    `,
  };
};

const useSxStyles = (theme?: Theme) => ({
  row: {
    background: theme?.palette?.background?.paper,
    '&:hover, &:active': {
      background: `${theme?.palette?.action?.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },

  cell: {
    color: theme?.palette?.text?.primary,
    transition: `0.1s`,
  },
  cellDisputed: {
    color: theme?.palette?.text?.disabled,
    transition: `0.1s`,
  },

  foldRow: {
    borderBottom: `1px solid ${theme?.palette?.divider}`,
  },
  foldCell: {
    padding: '0 !important',
    border: 'none',
  },
});

// EXPORT ---------------------------------------------------------------- //

export default InvoiceDetailsTableRow;
