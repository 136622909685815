// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { toast } from 'react-toastify';

import { css, keyframes } from '@emotion/css';
import { IconButton, Theme, Tooltip, useTheme } from '@mui/material';

import { BiHourglass, BiRefresh } from 'react-icons/bi';

// TYPES ---------------------------------------------------------------- //

interface IconRefetchProps {
  className?: string;
  refetch?: () => Promise<void>;
  message?: string;
  tooltip?: string;
  tooltipPlacement?: 'bottom' | 'top' | 'left' | 'right';
  toastLevel?: 'info' | 'error';
  [key: string]: any;
}

// COMPONENT ---------------------------------------------------------------- //

const IconRefetch = ({
  className = undefined,
  refetch = undefined,
  message = undefined,
  tooltip = undefined,
  tooltipPlacement = 'top',
  toastLevel = 'info',
  ...rest
}: IconRefetchProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);
  const [refetching, setRefetching] = React.useState(false);

  const handleRefetch = async () => {
    setRefetching(true);
    try {
      await refetch?.();
      if (toastLevel === 'info') {
        toast.info(message || 'Data refetched!', { autoClose: 2000, toastId: `refetch` });
      }
    } catch (err) {
      if (toastLevel === 'error' || toastLevel === 'info') {
        toast.error('Error refetching data!', { toastId: `refetch-error` });
      }
      console.error('Error refetching data:', err);
    }
    setRefetching(false);
  };

  const getClassNames = () => {
    return `${cls.iconBtn} ${className}`;
  };

  return (
    <Tooltip placement={tooltipPlacement} title={tooltip || 'Refetch Data'}>
      <IconButton className={getClassNames()} onClick={handleRefetch} {...rest}>
        {refetching ? <BiHourglass className={cls.loading} size={24} /> : <BiRefresh size={24} />}
      </IconButton>
    </Tooltip>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  const hourglass = keyframes`
    0% {
    transform: rotateZ(0deg);
    }
    40% {
      transform: rotateZ(180deg);
    }
    50% {
      transform: rotateZ(180deg);
    }
    90% {
      transform: rotateZ(360deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  `;

  return {
    iconBtn: css`
      padding: ${theme?.spacing(1)};
    `,
    loading: css`
      animation: ${hourglass} 1s infinite;
      animation-timing-function: ease-in-out;
    `,
  };
};

// EXPORT ---------------------------------------------------------------- //

export default IconRefetch;
