//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { css } from '@emotion/css';
import { SxProps, useTheme, Theme, Typography } from '@mui/material';
const log = false;

interface PrepaidPackageProps {
  productFeeCost: number;
}

//////////////////////// COMPONENT ////////////////////////
const PrepaidPackage: React.FC<PrepaidPackageProps> = ({ productFeeCost }: PrepaidPackageProps) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sxStyles = useSxStyles();
  log && console.log(`Prepaid amount:`, productFeeCost);

  if (productFeeCost) {
    return (
      <>
        <div style={{ width: '100%', height: '16px' }} />
        <div className={styles.package}>
          <Typography display='inline' sx={sxStyles.packageTitle}>
            Prepaid Package Amount:&nbsp;
          </Typography>
          <Typography display='inline' sx={sxStyles.packageAmount}>
            ${productFeeCost}
          </Typography>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default PrepaidPackage;

//////////////////////// STYLES ////////////////////////

const useStyles = (theme: Theme) => {
  return {
    package: css`
      position: relative;
      width: fit-content;
      padding: ${theme.spacing(2)};
      border-radius: ${theme.shape.borderRadius}px;
      background: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.shadows[3]};
    `,
  };
};

const useSxStyles = (): Record<string, SxProps<Theme> | undefined> => ({
  packageTitle: {
    fontSize: 18,
    fontWeight: 400,
  },
  packageAmount: {
    fontSize: 18,
    fontWeight: 600,
  },
});
