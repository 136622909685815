// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { Moves, Hangtags } from '@gql/schema';

import { css } from '@emotion/css';
import { Chip, Grid2 as Grid, Paper, Theme, Tooltip, Typography, useTheme } from '@mui/material';

import { useTools } from '@hooks/useTools';

import InfoItem from '@components/InfoItem';
import MoveStatusTracker from '@components/MoveStatusTracker';
import MoveTileHangtag from '@components/MoveTileHangtag';
import Spacer from '@components/Utils/Spacer';

// TYPES ---------------------------------------------------------------------- //

interface DashboardMoveTileProps {
  move?: Moves;
}

// COMPONENT ------------------------------------------------------------------ //

const DashboardMoveTile = ({ move }: DashboardMoveTileProps) => {
  const theme = useTheme();
  const cls = useStyles(theme, move?.consumer_type === 'customer' || move?.consumer_type === 'loaner');
  const clsx = useSxStyles(theme);

  const navigate = useNavigate();

  const { getFormattedVehicleFromMove, getCancelHeaderFromMove, getCancelReasonFromMove, getCleansedPhoneNumber } =
    useTools();
  const moveId: number | undefined = move?.id ?? undefined;

  return (
    <Paper variant='custom'>
      <div
        className={
          move?.consumer_type === 'customer' || move?.consumer_type === 'loaner' ? cls.consumerMove : cls.standardMove
        }
        onClick={() => navigate(`/moves/${moveId}`)}
      >
        <Grid container spacing={2} alignItems='flex-start' wrap='nowrap' sx={clsx.top}>
          <Grid size='grow'>
            {move?.consumer_type === 'customer' || move?.consumer_type === 'loaner' ? (
              <div className={cls.consumer}>
                <Typography sx={clsx.consumerNameText}>{move?.consumer_name}</Typography>
                <Typography sx={clsx.consumerPhoneText}>{getCleansedPhoneNumber(move?.consumer_phone)}</Typography>
              </div>
            ) : null}

            <Typography sx={clsx.vehicleText}>{getFormattedVehicleFromMove(move)}</Typography>

            <Typography sx={clsx.laneText}>{move?.lane?.description}</Typography>
          </Grid>

          <Grid>
            <Tooltip title='Move Type' placement='top-end'>
              {move?.consumer_type === 'customer' ? (
                <Chip label='Concierge' size='small' color='secondary' />
              ) : move?.consumer_type === 'loaner' ? (
                <Chip label='Loaner' variant='outlined' size='small' color='secondary' />
              ) : (
                <Chip label='Operational' size='small' />
              )}
            </Tooltip>
          </Grid>
        </Grid>

        <Spacer size={1} />

        <MoveStatusTracker move={move} size='sm' />

        <Spacer size={1} />

        <Grid container spacing={2} alignItems='flex-start' wrap='nowrap' sx={clsx.bottom}>
          <Grid size='grow'>
            <InfoItem size={`sm`} keyText={`HopDrive ID`} valueText={move?.id ? `#${move?.id}` : `N/A`} />

            <InfoItem size={`sm`} keyText={`Reference ID`} valueText={move?.reference_num || `N/A`} />

            <InfoItem size={`sm`} keyText={`VIN`} valueText={move?.vehicle_vin || `N/A`} />

            <InfoItem size={`sm`} keyText={`Stock`} valueText={move?.vehicle_stock || `N/A`} />
          </Grid>

          <Grid size='grow'>
            {move?.consumer_type ? (
              <>
                <InfoItem
                  size={`sm`}
                  keyText={`Appointment`}
                  valueText={move?.ready_by ? dayjs(move?.ready_by).format('M/D h:mm A z') : `N/A`}
                />
              </>
            ) : (
              <>
                <InfoItem
                  size={`sm`}
                  keyText={`Ready By`}
                  valueText={move?.ready_by ? dayjs(move?.ready_by).format('M/D h:mm A z') : `N/A`}
                />
              </>
            )}

            <InfoItem
              size={`sm`}
              keyText={`Created By`}
              valueText={
                move?.createdBy
                  ? move?.createdBy
                  : move?.eventlogs && move?.eventlogs.length > 0
                    ? move?.eventlogs[0].user
                    : 'Unknown'
              }
            />

            <InfoItem size={`sm`} keyText={`Workflow`} valueText={move?.workflowset?.name || 'Unknown'} />
          </Grid>
        </Grid>

        {!move?.cancel_status && move?.status === 'delivery successful' ? <div className={cls.completed} /> : null}

        {move?.cancel_status ? (
          <div className={cls.canceled}>
            <div className={cls.canceledTitle}>
              <Typography sx={clsx.cancelText}>{getCancelHeaderFromMove(move)}</Typography>
            </div>

            <div className={cls.canceledSubtitle}>
              <Typography sx={clsx.cancelReasonText}>{getCancelReasonFromMove(move)}</Typography>
            </div>
          </div>
        ) : null}

        {move?.hangtags && move?.hangtags.length && move?.hangtags[0].type === `yard` ? (
          <MoveTileHangtag hangtag={move?.hangtags[0] as Hangtags} />
        ) : null}
      </div>
    </Paper>
  );
};

// STYLES --------------------------------------------------------------------- //

const useStyles = (theme?: Theme, isConcierge?: boolean) => ({
  standardMove: css`
    position: relative;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.15s;
    &:hover {
      outline: 2px solid ${theme?.palette?.primary?.main};
      outline-offset: -2px;
      background-color: ${`${theme?.palette?.primary?.main}24`};
    }
  `,
  consumerMove: css`
    position: relative;
    overflow: hidden;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.15s;
    &:hover {
      outline: 2px solid ${theme?.palette?.secondary?.main};
      outline-offset: -2px;
      background-color: ${`${theme?.palette?.secondary?.main}24`};
    }
  `,

  consumer: css`
    margin-bottom: 2px;
  `,

  completed: css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 8px;
    background-color: ${theme?.palette?.background?.paper};
    opacity: 0.5;
  `,

  canceled: css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 8px;
    background-color: ${theme?.palette?.utility?.darker}b4;
    ${theme?.breakpoints?.down('md')} {
      background-color: ${theme?.palette?.utility?.darker}c8;
    }
  `,
  canceledTitle: css`
    position: absolute;
    top: ${isConcierge ? '13.75%' : '9%'};
    left: 0;
    right: 0;
  `,
  canceledSubtitle: css`
    position: absolute;
    top: ${isConcierge ? '42.25%' : '34.5%'};
    left: 0;
    right: 0;
  `,
});

const useSxStyles = (theme?: Theme) => ({
  top: {
    minWidth: '100%',
    marginBottom: theme?.spacing(0.5),
  },
  bottom: {
    minWidth: '100%',
    marginTop: theme?.spacing(0.5),
  },

  consumerNameText: {
    display: 'inline-block',
    marginRight: '4px',
    lineHeight: 1,
    fontSize: '16px',
    fontWeight: 700,
    color: theme?.palette?.secondary?.main,
  },
  consumerPhoneText: {
    display: 'inline-block',
    lineHeight: 1,
    fontSize: '16px',
    fontWeight: 500,
    color: theme?.palette?.secondary?.main,
  },

  vehicleText: {
    marginBottom: '2px',
    lineHeight: 1,
    fontSize: '16px',
    fontWeight: 700,
  },

  laneText: {
    lineHeight: 1,
    fontSize: '12px',
  },

  cancelText: {
    lineHeight: 1,
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 900,
    color: theme?.palette?.text?.contrastPrimary,
  },
  cancelReasonText: {
    lineHeight: 1,
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 500,
    color: theme?.palette?.text?.contrastPrimary,
  },
});

// EXPORT -------------------------------------------------------------------- //

export default DashboardMoveTile;
