// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { css } from '@emotion/css';
import { Theme, useTheme, Button, CircularProgress, Paper, Typography } from '@mui/material';
import Divider from '@components/Divider';

import { MovePlannerContext, MovePlannerContextType } from '@features/moveCreation/providers/MovePlannerProvider';

import MovePlannerTypeForm from '@features/moveCreation/components/MovePlannerTypeForm';
import MovePlannerRooftopForm from '@features/moveCreation/components/MovePlannerRooftopForm';
import MovePlannerWorkflowsetForm from '@features/moveCreation/components/MovePlannerWorkflowsetForm';
import MovePlannerEmailForm from '@features/moveCreation/components/MovePlannerEmailForm';
import MovePlannerConsumerForm from '@features/moveCreation/components/MovePlannerConsumerForm';
import MovePlannerTimeForm from '@features/moveCreation/components/MovePlannerTimeForm';
import MovePlannerLaneForm from '@features/moveCreation/components/MovePlannerLaneForm';
import MovePlannerVehicleForm from '@features/moveCreation/components/MovePlannerVehicleForm';
import MovePlannerAdditionalForm from '@features/moveCreation/components/MovePlannerAdditionalForm';

// COMPONENT ---------------------------------------------------------------- //

const MovePlannerContent = () => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const {
    rooftopId,
    movePlannerLocked,
    type,
    consumer,
    workflowsets,
    move1Title,
    move2Title,
    vehicle1,
    vehicle2,
    additional1,
    additional2,
    saving,
    setVehicle1,
    setVehicle2,
    setAdditional1,
    setAdditional2,
    handleSubmit,
    // handleSubmitAndAddAnother,
  } = React.useContext(MovePlannerContext) as MovePlannerContextType;

  if (movePlannerLocked) {
    return (
      <div className={cls.title}>
        <Typography variant='h3' sx={{ fontWeight: 600, marginBottom: `12px` }}>
          Move Planner is Locked
        </Typography>

        <Typography variant='body2' sx={{ textWrap: `pretty` }}>
          Your company has surpassed its monthly prepaid move amount. If you wish to increase your monthly package
          amount, or if you have any questions or concerns, contact a hopdrive sales representative by sending an email
          to{' '}
          <a className={cls.lockedLink} href='mailto:sales@hopdrive.com'>
            sales@hopdrive.com
          </a>
          .
        </Typography>
      </div>
    );
  }

  return (
    <form name='plan-form'>
      <div className={cls.title}>
        <Typography variant='h3' sx={{ fontWeight: 600, marginBottom: `12px` }}>
          Move Planner
        </Typography>

        <Typography variant='body2' sx={{ textWrap: `pretty` }}>
          Welcome to the move planner! This form allows you to set up a plan to move your vehicles. When your vehicles
          are prepared, one of our drivers will be sent out to move those vehicles for you. Be sure to have someone meet
          our driver at both ends for inspection.
        </Typography>
      </div>

      <Divider spacer mb={6} tip={`Select the type of move you would like to plan.`}>
        TYPE
      </Divider>

      <MovePlannerTypeForm />

      <Divider
        spacer
        mb={6}
        tip={`Enter some high-level information that we need in order to fulfill your move request.`}
      >
        OPTIONS
      </Divider>

      <Paper variant='custom' sx={{ padding: `16px` }}>
        <MovePlannerRooftopForm />
        {workflowsets?.length && workflowsets?.length > 1 ? <MovePlannerWorkflowsetForm /> : null}
        <MovePlannerEmailForm />
        <MovePlannerConsumerForm />
        <MovePlannerTimeForm />
      </Paper>

      <Divider
        spacer
        mb={6}
        tip={`Select an origin and destination to create a lane between. If the location is not in our system, you may search for a Google-suggested location.`}
      >
        LANE
      </Divider>

      <Paper variant='custom' sx={{ padding: `16px` }}>
        <MovePlannerLaneForm appointmentMode={false} />
      </Paper>

      <Divider spacer mb={6} tip={`Fill out the vehicle & additional move information.`}>
        {move1Title}
      </Divider>

      <Paper variant='custom' sx={{ padding: `16px` }}>
        <MovePlannerVehicleForm
          index={1}
          requireMakeAndModel={
            type !== `concierge-loaner` || (type === `concierge-loaner` && consumer?.first === `consumer`)
          }
          rooftopId={rooftopId}
          vehicle={vehicle1}
          setVehicle={setVehicle1}
        />
        <MovePlannerAdditionalForm additional={additional1} setAdditional={setAdditional1} />
      </Paper>

      {type === `concierge-loaner` || type === `round-trip` ? (
        <>
          <Divider spacer mb={6} tip={`Fill out the vehicle & additional move information.`}>
            {move2Title}
          </Divider>

          <Paper variant='custom' sx={{ padding: `16px` }}>
            <MovePlannerVehicleForm
              index={2}
              requireMakeAndModel={
                type !== `concierge-loaner` || (type === `concierge-loaner` && consumer?.first === `loaner`)
              }
              rooftopId={rooftopId}
              vehicle={vehicle2}
              setVehicle={setVehicle2}
            />
            <MovePlannerAdditionalForm additional={additional2} setAdditional={setAdditional2} />
          </Paper>
        </>
      ) : null}

      {/* FOOTER */}

      <div className={cls.footer}>
        {/* <Button
          name='plan-submit-and-keep'
          variant='outlined'
          color='primary'
          size='large'
          onClick={handleSubmitAndAddAnother}
        >
          Submit & Keep Inputs
        </Button> */}

        <Button disabled={saving} name='plan-submit' color='primary' size='large' onClick={handleSubmit}>
          Submit Plan
          {saving ? <CircularProgress color='inherit' size={24} sx={{ marginLeft: `12px` }} /> : null}
        </Button>
      </div>
    </form>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  return {
    title: css`
      max-width: 560px;
    `,

    lockedLink: css`
      text-decoration: none;
      font-weight: 600;
      color: ${theme?.palette?.primary?.main};
      transition: 0.1s ease-in-out;
      :hover {
        color: ${theme?.palette?.primary?.light};
      }
    `,

    flex: css`
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: space-between;
      gap: 12px;
      ${theme?.breakpoints?.down('md')} {
        flex-direction: column;
      }
    `,
    flexItem: css`
      flex: 1;
    `,

    spacer: css`
      width: 100%;
      height: 24px;
    `,

    footer: css`
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 36px;
    `,
  };
};

// EXPORT ---------------------------------------------------------------- //

export default MovePlannerContent;
