import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { ExportToCsv } from 'export-to-csv';
import { getFormattedCancelStatusFromDrive, getFormattedStatusFromDrive } from '@services/statusService';
import { getMonetaryValue } from '@services/formatService';
import { GetInvoiceDetailsQuery } from '@gql/schema';

export const generateCSV = (invoice: GetInvoiceDetailsQuery['arinvoices'][number]) => {
  if (invoice) {
    try {
      const csvRows = invoice.armoves
        .filter(arm => arm.type === 'move')
        .map(armove => ({
          MOVE_ID: armove?.move?.id,
          REF_NUM: armove?.move?.reference_num || '-',
          VIN: armove?.move?.vehicle_vin || '-',
          MOVE_DATE: dayjs.utc(dayjs(armove.billable_datetime)).format('MM/DD/YYYY'),
          STOCK: armove?.move?.vehicle_stock ? armove?.move?.vehicle_stock.toUpperCase() : 'N/A',
          LANE: armove?.move?.lane?.description || 'Unknown Lane',
          DISTANCE: armove?.move?.lane?.distance_miles ? `${armove.move.lane.distance_miles} mi` : '-',
          COST: armove.due_amount
            ? `${getMonetaryValue(Math.max(armove.due_amount - armove.discount_amount, 0))}`
            : armove.due_amount === 0
              ? getMonetaryValue(0)
              : '-',
          CANCEL_STATUS: armove?.move?.cancel_status ? `${getFormattedCancelStatusFromDrive(armove?.move)}` : '-',
          MOVE_STATUS: armove?.move?.status ? `${getFormattedStatusFromDrive(armove?.move)}` : '-',
          DISTANCE_MILES: armove?.move?.lane?.distance_miles || null,
        }));
      const csvOptions = {
        filename: `${invoice?.customer?.name?.replace(/ /g, '_')}_Invoice_${dayjs(invoice.start_datetime).add(1, 'day').format('MM-DD-YYYY')}_to_${dayjs(invoice.end_datetime).format('MM-DD-YYYY')}`,
        showTitle: true,
        title: `Invoice #${invoice.id}`,
        useKeysAsHeaders: true,
      };

      const csvExporter = new ExportToCsv(csvOptions);
      csvExporter.generateCsv(csvRows);
      toast.info('Generated CSV!', { autoClose: 2000, toastId: `generate-csv` });
    } catch (err) {
      toast.error('Failed to generate CSV!', { toastId: `generate-csv-error` });
      console.error('Failed to generate CSV:', err);
    }
  }
};

export const generateEnhancedCSV = (invoice: GetInvoiceDetailsQuery['arinvoices'][number]) => {
  if (invoice) {
    try {
      const csvRows = invoice.armoves
        .filter(arm => arm.type === 'move')
        .map(armove => ({
          ROOFTOP: armove?.move?.customer?.name || '-',
          INVOICE_ID: armove.invoice_id || '-',
          MOVE_ID: armove?.move?.id,
          REF_NUM: armove?.move?.reference_num || '-',
          VIN: armove?.move?.vehicle_vin || '-',
          MOVE_DATE: dayjs.utc(dayjs(armove.billable_datetime)).format('MM/DD/YYYY'),
          STOCK: armove?.move?.vehicle_stock ? armove.move.vehicle_stock.toUpperCase() : 'N/A',
          LANE: armove?.move?.lane?.description || 'Unknown Lane',
          DISTANCE: armove?.move?.lane?.distance_miles ? `${armove.move.lane.distance_miles} mi` : '-',
          COST: armove.due_amount
            ? `${getMonetaryValue(Math.max(armove.due_amount - armove.discount_amount, 0))}`
            : armove.due_amount === 0
              ? getMonetaryValue(0)
              : '-',
          TRANSPORT_TOTAL: armove?.transportDetails?.aggregate?.sum?.amount || 0,
          FUEL_TOTAL: armove?.fuelDetails?.aggregate?.sum?.amount || 0,
          FUEL_COUNT: armove?.fuelDetails?.aggregate?.count || 0,
          SURGE_TOTAL: armove?.surgeDetails?.aggregate?.sum?.amount || 0,
          SURGE_COUNT: armove?.surgeDetails?.aggregate?.count || 0,
          PARTIAL_TOTAL: armove?.partialDetails?.aggregate?.sum?.amount || 0,
          PARTIAL_COUNT: armove?.partialDetails?.aggregate?.count || 0,
          WAIVED_TOTAL: armove?.waivedDetails?.aggregate?.sum?.amount || 0,
          WAIVED_COUNT: armove?.waivedDetails?.aggregate?.count || 0,
          TOW_TOTAL: armove?.towDetails?.aggregate?.sum?.amount || 0,
          TOW_COUNT: armove?.towDetails?.aggregate?.count || 0,
          DELAY_TOTAL: armove?.delayDetails?.aggregate?.sum?.amount || 0,
          DELAY_COUNT: armove?.delayDetails?.aggregate?.count || 0,
          RIDE_TOTAL: armove?.rideDetails?.aggregate?.sum?.amount || 0,
          RIDE_COUNT: armove?.rideDetails?.aggregate?.count || 0,
          CANCEL_STATUS: armove?.move?.cancel_status ? `${getFormattedCancelStatusFromDrive(armove?.move)}` : '-',
          MOVE_STATUS: armove?.move?.status ? `${getFormattedStatusFromDrive(armove?.move)}` : '-',
          DISTANCE_MILES: armove?.move?.lane?.distance_miles || null,
        }));
      const csvOptions = {
        filename: `${invoice?.customer?.name?.replace(/ /g, '_')}_Invoice_${dayjs(invoice.start_datetime).add(1, 'day').format('MM-DD-YYYY')}_to_${dayjs(invoice.end_datetime).format('MM-DD-YYYY')}`,
        showTitle: true,
        title: `Invoice #${invoice.id}`,
        useKeysAsHeaders: true,
      };

      const csvExporter = new ExportToCsv(csvOptions);
      csvExporter.generateCsv(csvRows);
      toast.info('Generated CSV!', { autoClose: 2000, toastId: `generate-csv` });
    } catch (err) {
      toast.error('Failed to generate CSV!', { toastId: `generate-csv-error` });
      console.error('Failed to generate CSV:', err);
    }
  }
};
