import React from 'react';

import { Alert, useTheme } from '@mui/material';

interface DefaultEmptyFallbackProps {
  message?: string | undefined;
  fullscreen?: boolean;
  severity?: 'info' | 'error' | 'warning' | 'success';
}

const DefaultEmptyFallback: React.FC<DefaultEmptyFallbackProps> = ({
  message,
  fullscreen = false,
  severity = 'info',
}: DefaultEmptyFallbackProps) => {
  const theme = useTheme();

  if (fullscreen)
    return (
      <Alert
        severity={severity}
        sx={{
          borderTop: `1px solid ${theme?.palette?.[severity]?.main}`,
          borderBottom: `1px solid ${theme?.palette?.[severity]?.main}`,
          borderRadius: 0,
        }}
      >
        {message || `No data found.`}
      </Alert>
    );

  return (
    <Alert severity={severity} sx={{ border: `1px solid ${theme?.palette?.[severity]?.main}` }}>
      {message || `No data found.`}
    </Alert>
  );
};

export default DefaultEmptyFallback;
