// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { authService } from '@features/auth/services/authService';
import { useUser } from '@store';

import { css } from '@emotion/css';
import { Theme, useTheme, Avatar, Chip, Menu, MenuItem, Typography } from '@mui/material';

import { BiCog, BiLogOut, BiMessageRoundedDetail, BiSolidUser, BiUser } from 'react-icons/bi';

const isDev = import.meta.env.VITE_ENV !== `production`;

// TYPES ---------------------------------------------------------------- //

interface HeaderAvatarProps {
  onSupport?: () => void;
}

// COMPONENT ---------------------------------------------------------------- //

const HeaderAvatar = ({ onSupport }: HeaderAvatarProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const navigate = useNavigate();
  const user = useUser();

  const [avatarMenuAnchor, setAvatarMenuAnchor] = React.useState<null | HTMLElement>(null);
  const avatarMenuOpen = Boolean(avatarMenuAnchor);
  const handleAvatarMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAvatarMenuAnchor(event.currentTarget);
  };
  const handleAvatarMenuClose = () => {
    setAvatarMenuAnchor(null);
  };

  const handleClickProfile = () => {
    handleAvatarMenuClose();
    navigate('/profile');
  };

  const handleClickSettings = () => {
    handleAvatarMenuClose();
    navigate('/settings');
  };

  const handleClickSupport = () => {
    handleAvatarMenuClose();
    if (onSupport) onSupport();
  };

  const handleClickLogout = async () => {
    handleAvatarMenuClose();
    try {
      await authService.writeUserEvent('user.logout');
    } catch (err) {
      console.log('Failed to write user event', err);
    }
    await authService.logout();
    navigate('/login');
  };

  return (
    <>
      <div className={cls.avatarImage} onClick={handleAvatarMenuClick}>
        <Avatar
          alt={user?.profile?.display_name || `User`}
          src={user?.profile?.avatar_url || ``}
          sx={{
            width: 40,
            height: 40,
            backgroundColor: theme?.palette?.utility?.lighter,
          }}
        >
          <BiSolidUser size={20} />
        </Avatar>

        <div className={cls.avatarImageShadow} />
      </div>

      <Menu anchorEl={avatarMenuAnchor} open={avatarMenuOpen} onClose={handleAvatarMenuClose}>
        <div className={cls.menuInfo}>
          <Typography variant='body1' sx={{ lineHeight: 1.25, fontWeight: 600, color: theme?.palette?.text?.primary }}>
            {user?.profile?.display_name || `Unknown User`}
          </Typography>

          <Typography
            variant='body2'
            sx={{ lineHeight: 1.25, fontWeight: 300, color: theme?.palette?.text?.secondary }}
          >
            {user?.profile?.email || `Unknown Email`}
          </Typography>

          {isDev ? (
            <Chip
              label={user?.profile?.default_role || `Unknown Role`}
              sx={{ marginTop: 1, textTransform: `capitalize` }}
            />
          ) : null}
        </div>

        <div className={cls.menuDivider} />

        <MenuItem onClick={handleClickProfile}>
          <BiUser className={cls.menuIcon} />
          Profile
        </MenuItem>

        <MenuItem disabled onClick={handleClickSettings}>
          <BiCog className={cls.menuIcon} />
          Settings
        </MenuItem>

        <div className={cls.menuDivider} />

        <MenuItem onClick={handleClickSupport}>
          <BiMessageRoundedDetail className={cls.menuIcon} />
          Support
        </MenuItem>

        <div className={cls.menuDivider} />

        <MenuItem onClick={handleClickLogout} style={{ color: theme?.palette?.error?.main }}>
          <BiLogOut className={cls.menuIcon} style={{ color: theme?.palette?.error?.main }} />
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  const styles = {
    avatarImage: css`
      position: relative;
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      :hover,
      :focus,
      :active {
        opacity: 0.875;
        transform: scale(1.1);
      }
    `,
    avatarImageShadow: css`
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50%;
      box-shadow: 0px 0px 6px 2px ${theme?.palette?.action?.focus} inset;
      cursor: pointer;
    `,

    menuInfo: css`
      padding: 8px 16px;
    `,
    menuIcon: css`
      margin-right: 8px;
      color: ${theme?.palette?.text?.primary};
    `,
    menuDivider: css`
      height: 1px;
      margin: 6px 0;
      background-color: ${theme?.palette?.divider};
    `,
  };

  return styles;
};

// EXPORT ---------------------------------------------------------------- //

export default HeaderAvatar;
