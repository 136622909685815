import { Modal } from '@components/Modal';
import { Users } from '@gql/schema';

import ReinstateModalContent from './ReinstateModalContent';

//////////////////////// COMPONENT ////////////////////////

interface ReinstateModalProps {
  open: boolean;
  input: {
    user: Users;
    refetch: () => void;
  } | null;
  onClose: (output: Users | null) => void;
}

export default function ReinstateModal({ open, input, onClose }: ReinstateModalProps) {
  const handleClose = (output: Users | null = null) => {
    if (onClose) onClose(output);
  };

  return <Modal open={open}>{open ? <ReinstateModalContent input={input} onClose={handleClose} /> : null}</Modal>;
}