import React from 'react';
import { Typography, DialogContent, Box, SxProps } from '@mui/material';
import { useTheme, Theme } from '@mui/material/styles';


interface ModalContentProps {
  subtitle?: string;
  disableDividers?: boolean;
  children?: React.ReactNode;
  scroll?: string;
}

const ModalContent: React.FC<ModalContentProps> = ({
  subtitle,
  disableDividers = false,
  children,
}) => {
  const theme: Theme = useTheme();
  const sxStyles = useSxStyles(theme);

  return (
    <DialogContent dividers={!disableDividers} sx={sxStyles.padding}>
      {subtitle && (
        <>
          <Typography sx={sxStyles.subtitle}>{subtitle}</Typography>
          <Box sx={sxStyles.break} />
        </>
      )}
      {children}
    </DialogContent>
  );
};

export default ModalContent;

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  padding: {
    padding: theme.spacing(2),
    margin: 0,
    display: 'block',
    overflowY: 'auto',
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: 400,
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
});
