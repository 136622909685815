import { useEffect, useState } from 'react';
import { useRooftop } from '@store';
import { useGetAppointmentsLazyQuery, GetAppointmentsQuery, Appointments } from '@gql/schema';
import dayjs from 'dayjs';
import { css } from '@emotion/css';
import DefaultFallback from '@components/Fallbacks/DefaultFallback';
import Loading from '@components/Utils/Loading';
import AppointmentsFilter from '../components/AppointmentsFilter';
import * as Sentry from '@sentry/react';
import { useTheme, Grid2 as Grid, Container, SxProps, Theme, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import AppointmentsTable from '../components/AppointmentsTable';

/////////////////////////////////////// FUNCTIONS ///////////////////////////////////////

const getDefaultRange = () => {
  const localRange = localStorage.getItem(`appointment-index-range`);
  if (localRange) return localRange;
  else return `thisWeek`; // Changed default
};

const getDefaultStart = () => {
  const localRange = localStorage.getItem(`appointment-index-range`);
  const localStart = localStorage.getItem(`appointment-index-start`);
  if (localRange === `custom` && localStart) return localStart;

  switch (localRange) {
    case 'today':
      return dayjs().startOf('day').format();
    case 'thisWeek':
      return dayjs().startOf('week').format();
    case 'nextWeek':
      return dayjs().add(1, 'week').startOf('week').format();
    case 'thisMonth':
      return dayjs().startOf('month').format();
    case 'thisYear':
      return dayjs().startOf('year').format();
    default:
      return dayjs().startOf('week').format();
  }
};

const getDefaultEnd = () => {
  const localRange = localStorage.getItem(`appointment-index-range`);
  const localEnd = localStorage.getItem(`appointment-index-end`);
  if (localRange === `custom` && localEnd) return localEnd;

  switch (localRange) {
    case 'today':
      return dayjs().endOf('day').format();
    case 'thisWeek':
      return dayjs().endOf('week').format();
    case 'nextWeek':
      return dayjs().add(1, 'week').endOf('week').format();
    case 'thisMonth':
      return dayjs().endOf('month').format();
    case 'thisYear':
      return dayjs().endOf('year').format();
    default:
      return dayjs().endOf('week').format();
  }
};

const AppointmentsPage = () => {
  const rooftop = useRooftop();
  const theme = useTheme();
  const sxStyles = useSxStyles(theme);
  const cls = useStyles(theme);

  const [appointmentsEnabled, setAppointmentsEnabled] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disablePickers, setDisablePickers] = useState(false);
  const [range, setRange] = useState(getDefaultRange());
  const [start, setStart] = useState(getDefaultStart());
  const [end, setEnd] = useState(getDefaultEnd());
  const [search, setSearch] = useState<string>('');
  const [appointments, setAppointments] = useState<Appointments[]>([]);
  const [filteredAppointments, setFilteredAppointments] = useState<Appointments[]>([]);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const [getAppointments] = useGetAppointmentsLazyQuery({
    variables: {
      customerId: rooftop?.id || 0,
      start: getDefaultStart(),
      end: getDefaultEnd(),
    },
  });

  useEffect(() => {
    if (
      rooftop?.organization?.config?.appointments?.enabled &&
      rooftop?.organization?.config?.appointments?.enabled === true
    ) {
      setAppointmentsEnabled(true);
    } else {
      setAppointmentsEnabled(false);
    }
  }, [rooftop]);

  const getAppointmentsList = async () => {
    setIsLoading(true);
    try {
      const appointmentsResponse: { data: GetAppointmentsQuery | undefined } = await getAppointments({
        variables: {
          customerId: rooftop?.id || 0,
          start: getDefaultStart(),
          end: getDefaultEnd(),
        },
      });
      setAppointments(appointmentsResponse?.data?.appointments as Appointments[]);
    } catch (err) {
      console.error('Error fetching appointments', err);
    } finally {
      setIsLoading(false);
      setInitialLoadComplete(true);
    }
  };

  // Control range picker
  const handleRangeChange = (value: string) => {
    localStorage.setItem(`appointment-index-range`, value);
    if (value !== `custom`) {
      setDisablePickers(true);
      setRange(value);

      switch (value) {
        case 'today':
          setStart(dayjs().startOf('day').format());
          setEnd(dayjs().endOf('day').format());
          break;
        case 'thisWeek':
          setStart(dayjs().startOf('week').format());
          setEnd(dayjs().endOf('week').format());
          break;
        case 'nextWeek':
          setStart(dayjs().add(1, 'week').startOf('week').format());
          setEnd(dayjs().add(1, 'week').endOf('week').format());
          break;
        case 'thisMonth':
          setStart(dayjs().startOf('month').format());
          setEnd(dayjs().endOf('month').format());
          break;
        case 'thisYear':
          setStart(dayjs().startOf('year').format());
          setEnd(dayjs().endOf('year').format());
          break;
      }
    } else {
      setDisablePickers(false);
      setRange(value);
      setStart(getDefaultStart());
      setEnd(getDefaultEnd());
    }
  };

  // Control date pickers
  const handleDateChange = (value: string, name: string) => {
    if (name === `start`) {
      const newDate = dayjs.utc(dayjs(value).startOf(`day`)).format();
      localStorage.setItem(`appointment-index-start`, newDate);
      setStart(newDate);
    } else {
      const newDate = dayjs.utc(dayjs(value).endOf(`day`)).format();
      localStorage.setItem(`appointment-index-end`, newDate);
      setEnd(newDate);
    }
  };

  const handleRefetch = () => {
    getAppointmentsList();
  };

  const applyFilters = (data: Appointments[]) => {
    const filterBySearch = () => {
      if (!search || search.length < 1) {
        const filteredData = data.filter(appointment => appointment.id);
        return filteredData;
      } else {
        return data.filter(appointment => {
          if (
            (appointment.id && (appointment.id + ``).toLocaleLowerCase().includes(search)) ||
            (appointment.appointment_time && appointment.appointment_time.toLocaleLowerCase().includes(search)) ||
            (appointment.consumer_name && appointment.consumer_name.toLocaleLowerCase().includes(search)) ||
            (appointment.vehicle_make && appointment.vehicle_make.toLocaleLowerCase().includes(search)) ||
            (appointment.vehicle_year && appointment.vehicle_year.toString().includes(search)) ||
            (appointment.vehicle_model && appointment.vehicle_model.toLocaleLowerCase().includes(search)) ||
            (appointment.vehicle_vin && appointment.vehicle_vin.toLocaleLowerCase().includes(search)) ||
            (appointment.status && appointment.status.toLocaleLowerCase().includes(search)) ||
            (appointment.customer_id && appointment.customer_id.toString().includes(search)) ||
            (appointment.move_id && appointment.move_id.toString().includes(search)) ||
            (appointment.customer &&
              appointment.customer.name &&
              appointment.customer.name.toLocaleLowerCase().includes(search)) ||
            (appointment.config &&
              appointment.config.claim_number &&
              appointment.config.claim_number.toLocaleLowerCase().includes(search))
          ) {
            return true;
          } else return false;
        });
      }
    };

    const filteredBySearch = filterBySearch();
    return filteredBySearch;
  };

  useEffect(() => {
    if (start && end && appointmentsEnabled) {
      getAppointmentsList();
    }
  }, [start, end, appointmentsEnabled]);

  // apply filters
  useEffect(() => {
    if (search && appointments) {
      const filtered = applyFilters(appointments);
      setFilteredAppointments(filtered);
    } else {
      setFilteredAppointments(appointments);
    }
  }, [search, appointments]);

  if (!appointmentsEnabled) {
    return <DefaultFallback fullscreen severity='error' message='RESTRICTED ACCESS' />;
  }

  return (
    <div className={cls.pageContainer}>
      <Container maxWidth='lg'>
        <div className={cls.header}>
          <Grid container spacing={2} alignItems='center'>
            <Grid>
              <Typography sx={sxStyles.titleText}>Appointments</Typography>
            </Grid>
            <Grid>
              <Link to='/appointments/add'>
                <Button color='primary' data-testid='add-appointment-button' size='large'>
                  ADD&nbsp;APPOINTMENT
                </Button>
              </Link>
            </Grid>
          </Grid>
        </div>

        {isLoading || !initialLoadComplete ? (
          <Grid container spacing={2} alignItems='center'>
            <Loading />
          </Grid>
        ) : (
          <div className={cls.content}>
            <AppointmentsFilter
              refetch={handleRefetch}
              range={range}
              start={start}
              end={end}
              onRangeChange={handleRangeChange}
              onDateChange={handleDateChange}
              disablePickers={disablePickers}
              setSearch={setSearch}
              search={search}
            />
            {filteredAppointments.length === 0 && !isLoading ? (
              <DefaultFallback fullscreen severity='warning' message='No appointments found!' />
            ) : (
              <Sentry.ErrorBoundary
                fallback={
                  <DefaultFallback fullscreen severity='error' message='Error displaying appointments table!' />
                }
              >
                <AppointmentsTable appointments={filteredAppointments as Appointments[]} />
              </Sentry.ErrorBoundary>
            )}
          </div>
        )}
      </Container>
    </div>
  );
};

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  titleText: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
});

const useStyles = (theme: Theme) => {
  return {
    pageContainer: css`
      padding: ${theme.spacing(3)} 0;
      min-height: 100vh;
    `,
    header: css`
      margin-bottom: ${theme.spacing(3)};
    `,
    content: css`
      margin-bottom: ${theme.spacing(7)};
    `,
  };
};

export default AppointmentsPage;
