import React from 'react';
import { css } from '@emotion/css';
import dayjs from 'dayjs';
import { useGetCustomersAndMovesLazyQuery, Customers } from '@gql/schema';
import { Paper } from '@mui/material';
import OverviewHeader from '@features/rooftops/components/OrganizationOverviewTableHeader';
import OverviewBody from '@features/rooftops/components/OrganizationOverviewTableBody';
const log = false;

const OrganizationOverview: React.FC = () => {
  const cls = useStyles();

  const storedDate = localStorage.getItem('overview-date');
  const today = dayjs().toString();

  const [getCustomersAndMoves] = useGetCustomersAndMovesLazyQuery();
  const [date, setDate] = React.useState<string>(storedDate || today);
  const [customers, setCustomers] = React.useState<Customers[]>([]);

  React.useEffect(() => {
    const fetchCustomersAndSetState = async () => {
      try {
        let dt = dayjs(date);
        //create start and end of day ISO strings so that the query will only select one day's worth of moves
        let startOfDate = dt.startOf('day').toString();
        let endOfDate = dt.endOf('day').toString();
        log && console.log('dates', date, startOfDate, endOfDate);
        const res = await getCustomersAndMoves({ variables: { start: startOfDate, end: endOfDate } });
        const resCustomers = res?.data?.customers || [];
        const interableCustomers: Customers[] | undefined = resCustomers.map(customer => ({
          ...customer,
        })) as Customers[];
        if (interableCustomers.length === 0) {
          console.error('No customers found');
        }
        setCustomers(interableCustomers);
      } catch (err) {
        console.error(`Failed to fetch customers:`, err);
      }
    };
    fetchCustomersAndSetState();
  }, [date]);

  return (
    customers &&
    customers.length > 1 && (
      <Paper className={cls.root}>
        <OverviewHeader customers={customers} date={date} setDate={setDate} />
        <OverviewBody customers={customers} />
      </Paper>
    )
  );
};

export default OrganizationOverview;

const useStyles = () => ({
  root: css`
    width: fit-content;
  `,
});
