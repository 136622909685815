// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';
import { useParams } from 'react-router-dom';
import { useRooftop } from '@store';

import { css } from '@emotion/css';
import { Theme, useTheme } from '@mui/material';

import InvoiceDetails from '@features/invoices/components/InvoiceDetails';
import InvoiceSidebar from '@features/invoices/components/InvoiceSidebar';

// COMPONENT ---------------------------------------------------------------- //

const InvoicesPage = () => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const { id: paramInvoiceId } = useParams<{ id: string }>();
  const rooftop = useRooftop();

  return (
    <div className={cls.page}>
      <div className={cls.flex}>
        <div className={cls.flexDetails}>
          <InvoiceDetails rooftopId={rooftop?.id} selectedInvoiceId={paramInvoiceId ? parseInt(paramInvoiceId) : 0} />
        </div>

        <div className={cls.flexSidebar}>
          <InvoiceSidebar rooftopId={rooftop?.id} selectedInvoiceId={paramInvoiceId ? parseInt(paramInvoiceId) : 0} />
        </div>
      </div>
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  page: css`
    position: relative;
  `,
  flex: css`
    display: flex;
    flex-wrap: nowrap;
    height: 100vh;
    padding-top: 64px;
    margin-top: -64px;
  `,
  flexDetails: css`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;
    overflow-y: auto;
  `,
  flexSidebar: css`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 0 0 320px;
    overflow-y: auto;
    border-left: 1px solid ${theme?.palette?.divider};
    background-color: ${theme?.palette?.background?.paper};
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default InvoicesPage;
