import { TypePolicies } from '@apollo/client';

/**
 * List of fields in the Apollo Client cache that should use the custom merge function.
 * Add field names here to apply the same merge logic to them.
 *
 * Note: Using `as const` ensures that TypeScript treats this array as a readonly tuple,
 * allowing us to reference the field names as literal types.
 */
const fieldsToMerge = ['moves'] as const;

/**
 * A reusable merge function that replaces existing data with incoming data.
 * This function can be customized if different merging logic is needed.
 */
const mergeFunction = (existing = [], incoming: any[]) => {
  return incoming;
};

/**
 * Creates type policies dynamically based on the provided fields.
 *
 * @param fields - An array of field names that should use the custom merge function.
 * @returns TypePolicies - A TypePolicies object configured with custom merge functions.
 */
const createTypePolicies = (fields: readonly string[]): TypePolicies => {
  const typePolicies: TypePolicies = {
    Query: {
      fields: {},
    },
    Subscription: {
      fields: {},
    },
  };

  fields.forEach(field => {
    typePolicies.Query.fields[field] = {
      merge: mergeFunction,
    };
    typePolicies.Subscription.fields[field] = {
      merge: mergeFunction,
    };
  });

  return typePolicies;
};

export const typePolicies = createTypePolicies(fieldsToMerge);
