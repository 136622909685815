// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { Customers, useGetPayersLazyQuery } from '@gql/schema';

import { css, cx } from '@emotion/css';
import { Theme, useTheme, Autocomplete, InputAdornment, TextField } from '@mui/material';

import { BiDollarCircle } from 'react-icons/bi';

// TYPES ---------------------------------------------------------------- //

interface PayerSelectProps {
  payersArray?: number[];
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  name?: string;
  label?: string;
  placeholder?: string;
  helperText?: string;
  defaultPayers?: Customers[];
  value?: number;
  onChange?: (id: number) => void;
  onFetch?: (payers?: Customers[]) => void;
  queryOverride?: () => Promise<Customers[]>;
  queryCall?: `never` | `onMount` | `onOpen`;

  [x: string]: any;
}

// COMPONENT ---------------------------------------------------------------- //

const PayerSelect = ({
  payersArray,
  required,
  disabled,
  error,
  name = `payer-select`,
  label = ``,
  placeholder = `Select payer...`,
  helperText = ``,
  defaultPayers = [],
  value = 0,
  onChange,
  onFetch,
  queryOverride,
  queryCall = `onMount`,
  ...rest
}: PayerSelectProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const [getPayers] = useGetPayersLazyQuery();

  const [payers, setPayers] = React.useState<Customers[]>(defaultPayers || []);
  const [payerId, setPayerId] = React.useState<number>(value || 0);

  const [internalError, setInternalError] = React.useState<boolean>(false);
  const [internalErrorText, setInternalErrorText] = React.useState<string>(``);

  const [loading, setLoading] = React.useState<boolean>(false);

  /** Fetch query and set state */
  const fetchPayersAndSetState = async () => {
    setLoading(true);

    try {
      let res;
      if (queryOverride) res = await queryOverride();
      else res = await getPayers({ variables: { payersArray: payersArray || [] } });

      const resPayers = res?.data?.customers || [];
      const defaultPayerId = value || 0;

      setPayers(resPayers);
      setPayerId(defaultPayerId);

      if (onFetch) onFetch(resPayers);

      setInternalError(false);
      setInternalErrorText(``);
    } catch (err) {
      console.error(`Failed to fetch payers:`, err);
      setInternalError(true);
      setInternalErrorText(`Failed to fetch payers`);
    }

    setLoading(false);
  };

  /** Handle changing the value */
  const handleValueChange = (value?: Customers) => {
    const selectedPayerId = value?.id || 0;
    setPayerId(selectedPayerId);
    if (onChange) onChange(selectedPayerId);
  };

  /** Handle opening the dropdown */
  const handleOpen = async () => {
    if (queryCall === `onOpen`) fetchPayersAndSetState();
  };

  // Fetch on mount instead of on open
  React.useEffect(() => {
    if (queryCall === `onMount`) fetchPayersAndSetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set the data when it comes in as a prop (only when there is a query override)
  React.useEffect(() => {
    setPayers(defaultPayers);
  }, [defaultPayers]);

  // Set the value when it comes in as a prop
  React.useEffect(() => {
    setPayerId(value);
  }, [value]);

  return (
    <>
      {label ? (
        <label className={cls.payerLabel} htmlFor={name}>
          {label}
          {required ? <span className={cls.payerLabelRequired}> *</span> : null}
        </label>
      ) : null}

      <Autocomplete
        {...rest}
        fullWidth
        disabled={disabled}
        loading={loading}
        loadingText={`Loading payers...`}
        noOptionsText={`No payers found`}
        options={payers}
        getOptionKey={p => p?.id || 0}
        getOptionLabel={p => p?.name || ``}
        value={payers?.find(p => p?.id === payerId) || null}
        onChange={(_, value) => handleValueChange(value)}
        onOpen={() => handleOpen()}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            required={required}
            error={internalError || error}
            name={name}
            placeholder={placeholder}
            helperText={internalErrorText || helperText}
            slotProps={{
              input: {
                ...params?.InputProps,
                startAdornment: (
                  <InputAdornment position='start' sx={{ marginLeft: `8px`, marginRight: 0 }}>
                    <BiDollarCircle className={cx(cls.payerIcon, disabled ? cls.payerIconDisabled : null)} />
                  </InputAdornment>
                ),
              },
            }}
          />
        )}
      />
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  const styles = {
    payerLabel: css`
      display: block;
      margin-bottom: 2px;
      margin-left: 6px;
      font-size: 12px;
      font-weight: 600;
    `,
    payerLabelRequired: css`
      color: ${theme?.palette?.error?.main};
    `,
    payerIcon: css`
      display: block;
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      color: ${theme?.palette?.action?.active};
    `,
    payerIconDisabled: css`
      color: ${theme?.palette?.action?.disabled};
    `,
  };

  return styles;
};

// EXPORT ---------------------------------------------------------------- //

export default PayerSelect;
