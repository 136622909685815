import React from 'react';
import { toast } from 'react-toastify';
import { useInsertPaymentMethodMutation, useUpdatePaymentMethodByPkMutation } from '@gql/schema';

const log = false;

interface MethodiFrameProps {
  id?: number;
  name: string;
  type: string;
  //TODO: Fix this type
  handleClose: () => void;
  primaryExists: boolean;
  customerId: number;
}

interface ICheckResponse {
  origin: string;
  data: {
    token: string;
    cardType: string;
    cardNumber: string;
    cardExpDate: string;
    accountType: string;
    accountNumber: string;
    routingNumber: string;
    source: string;
    error: string;
  };
}

interface MethodVariables {
  gatewayToken: string;
  cardType: string | null;
  accountType: string | null;
  accountNumber: number;
  routingNumber: number | null;
  expiration: number | null;
  source: string | null;
}

////////// COMPONENT //////////
const MethodiFrame: React.FC<MethodiFrameProps> = (props: MethodiFrameProps) => {
  const { id, name, type, handleClose, primaryExists, customerId } = props;

  React.useEffect(() => {
    // Add event listener to listen for messages from the iFrame
    window.addEventListener('message', onReceiveMessage);

    // Remove event listener when component is unmounted
    return () => {
      window.removeEventListener('message', onReceiveMessage);
    };
  }, []);

  const [insertPaymentMethod] = useInsertPaymentMethodMutation();
  const [updatePaymentMethod] = useUpdatePaymentMethodByPkMutation();

  // Mutation to insert a payment method
  const insertMethod = async (variables: MethodVariables) => {
    console.log(`insertMethod variables:`, variables);
    try {
      const insertMethodRes = await insertPaymentMethod({
        variables: {
          customerId: customerId,
          name: name,
          primary: !primaryExists,
          type: type,
          gatewayToken: variables.gatewayToken,
          accountType: variables.accountType,
          accountNumber: variables.accountNumber,
          cardType: variables.cardType,
          expiration: variables.expiration,
          routingNumber: variables.routingNumber,
          source: variables.source,
        },
      });
      log && console.log(`>> INSERTED Payment Method ${insertMethodRes?.data} for Customer #${customerId}. `);
      handleClose();
    } catch (err) {
      console.log(`Error inserting customer payment method:`, err);
    }
  };

  // Mutation to update a payment method
  const updateMethod = async (variables: MethodVariables) => {
    try {
      if(!id) throw new Error(`No ID found for payment method`);
      const updateMethodRes = await updatePaymentMethod({
        variables: {
          id: id,
          name: name,
          type: type,
          gatewayToken: variables.gatewayToken,
          accountType: variables.accountType,
          accountNumber: variables.accountNumber,
          cardType: variables.cardType,
          expiration: variables.expiration,
          routingNumber: variables.routingNumber,
          source: variables.source,
        },
      });
      log && console.log(`>> UPDATED Payment Method ${updateMethodRes?.data} for Customer #${customerId}. `);
      handleClose();
    } catch (err) {
      console.log(`Error updating customer payment method:`, err);
    }
  };

  // Set the src attribute depending on the type of payment
  const src = `https://portals.icheckgateway.com/iFrame/iFrame${type === `card` ? `CC` : `BA`}.aspx?appId=${import.meta.env.VITE_ICHECK_APP_ID}&appSecret=${import.meta.env.VITE_ICHECK_APP_SECRET}&firstName=${customerId}&custId=${customerId}`;

  const onReceiveMessage = (message: ICheckResponse) => {
    log && console.log('message', message);
    // iFrame sent a message and our app has received it
    if (message?.origin !== 'https://portals.icheckgateway.com') {
      // Handle the message
      log && console.log('Message not from iCheck- ignore');
      return;
    }
    if (message.data) {
      const res = message.data;
      log && console.log(`Response from iCheck:`, message.data);
      let variables: MethodVariables = {
        gatewayToken: '',
        cardType: null,
        accountType: null,
        accountNumber: 0,
        routingNumber: null,
        expiration: null,
        source: null,
      };

      // If it's a credit card
      if (res.cardType) {
        variables.gatewayToken = res.token;
        variables.cardType = res.cardType.toLowerCase();
        variables.accountNumber = parseInt(res.cardNumber);
        variables.expiration = parseInt(res.cardExpDate);
        variables.source = res.source;
        variables.accountType = null;
        variables.routingNumber = null;
      }
      // If it's a check
      else if (res.routingNumber) {
        variables.gatewayToken = res.token;
        variables.accountType = res.accountType.toLowerCase();
        variables.accountNumber = parseInt(res.accountNumber);
        variables.routingNumber = parseInt(res.routingNumber);
        variables.source = res.source;
        variables.cardType = null;
        variables.expiration = null;
      }

      // If there's an error
      if (res.error && res.error !== null && res.error !== undefined && !variables.gatewayToken) {
        console.log(`Error from iCheck:`, res.error);
        toast.error(`Failed to create payment method`);
      }
      // Else if there is no error and no id is found and the variables object is not empty, add a new entry to the database
      else if ((!id || id === null || id === undefined) && Object.getOwnPropertyNames(variables).length > 0) {
        log && console.log('inserting new method');
        insertMethod(variables);
      }
      // Else if there is no error and an ID and the variables object is not empty, replace the entry matching the ID
      else if (id && Object.getOwnPropertyNames(variables).length > 0) {
        log && console.log('updating method');
        updateMethod(variables);
      }
      // Otherwise, we are waiting on the payment method object to insert or update
      else {
        log && console.log(`Waiting on payment method object...`);
      }
    } else {
      log && console.log(`Error: No iframe data found!`);
    }
  };

  return <iframe src={src} id='iMethod' width='100%' height='540px' />;
};

export default MethodiFrame;
