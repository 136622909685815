import { Grid2 as Grid, TextField, InputAdornment, Icon, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { css } from '@emotion/css';
import dayjs from 'dayjs';
import { useDashboard } from '../providers/DashboardProvider';

const DashboardControls = () => {
  const styles = useStyles();
  const ctx = useDashboard();

  return (
    <Grid container spacing={1.5} alignItems='center'>
      <Grid size={{ sm: 4, xs: 12 }}>
        <TextField
          select
          fullWidth
          label='Date Range'
          placeholder='Select a date range...'
          variant='outlined'
          size='small'
          value={ctx.range}
          onChange={e => ctx.setRange(e?.target?.value)}
        >
          <MenuItem value={`day`}>Today</MenuItem>
          <MenuItem value={`3day`}>Past 3 Days</MenuItem>
          <MenuItem value={`week`}>Past Week</MenuItem>
          <MenuItem value={`2week`}>Past 2 Weeks</MenuItem>
          <MenuItem value={`month`}>Past Month</MenuItem>
          <MenuItem value={`custom`}>Custom</MenuItem>
        </TextField>
      </Grid>

      <Grid size={{ sm: 4, xs: 12 }}>
        <DatePicker
          disabled={ctx.disablePickers}
          maxDate={dayjs(ctx.end)}
          label='Start Date'
          orientation='portrait'
          value={dayjs(ctx.start)}
          onChange={date => ctx.setPrelimStart(date?.toString() || '')}
          slots={{
            textField: TextField,
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              size: 'small',
              variant: 'outlined',
              className: styles.date,
            },
          }}
        />
      </Grid>

      <Grid size={{ sm: 4, xs: 12 }}>
        <DatePicker
          disabled={ctx.disablePickers}
          minDate={dayjs(ctx.start)}
          label='End Date'
          orientation='portrait'
          value={dayjs(ctx.end)}
          onChange={date => ctx.setPrelimEnd(date?.toString() || '')}
          slots={{
            textField: TextField,
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              size: 'small',
              variant: 'outlined',
              className: styles.date,
            },
          }}
        />
      </Grid>

      <Grid size={{ sm: 6, xs: 12 }}>
        <TextField
          fullWidth
          select
          label='Sort By'
          placeholder='Sort moves by...'
          variant='outlined'
          margin='none'
          value={ctx.sort}
          onChange={e => ctx.setSort(e?.target?.value)}
          className={styles.actionSort}
        >
          <MenuItem value={`new`}>Pickup Time (Newest)</MenuItem>
          <MenuItem value={`old`}>Pickup Time (Oldest)</MenuItem>
          <MenuItem value={`status`}>Move Status</MenuItem>
          <MenuItem value={`make`}>Vehicle Make</MenuItem>
          <MenuItem value={`model`}>Vehicle Model</MenuItem>
          <MenuItem value={`year`}>Vehicle Year</MenuItem>
          <MenuItem value={`color`}>Vehicle Color</MenuItem>
        </TextField>
      </Grid>

      <Grid size={{ sm: 6, xs: 12 }}>
        <TextField
          fullWidth
          label='Search'
          placeholder='Search for a move...'
          variant='outlined'
          margin='none'
          onChange={e => ctx.setSearch(e?.target?.value.toLowerCase())}
          className={styles.actionSearch}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    search
                  </Icon>
                </InputAdornment>
              </>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DashboardControls;

const useStyles = () => ({
  date: css`
    width: 100%;
  `,

  actionSearch: css`
    min-width: 160px;
  `,
  actionSort: css`
    min-width: 160px;
  `,
});
