import { createTheme, css } from '@mui/material/styles';

// Future purple color that works with our colors? #6000B8

// Extend the MUI theme to include our custom properties
// declare module '@mui/material' {
//   interface Theme {
//     palette?: {
//       utility?: {
//         light?: string;
//         main?: string;
//         dark?: string;
//         contrastText?: string;
//       };
//       nav?: {
//         header?: string;
//         sidebar?: string;
//         contrastText?: string;
//       };
//     };
//     typography?: {
//       fontWeightExtraLight?: number;
//       fontWeightVeryLight?: number;
//       fontWeightBlack?: number;
//     };
//   }
// }

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    custom: true;
    customPrint: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    PageHeader: true;
    SectionHeader: true;
  }
}

// Create the custom theme overrides
const theme = createTheme({
  palette: {
    // Use this site for shade generation: https://maketintsandshades.com/
    // 20% for dark and light colors
    // 50% for darker and lighter colors

    /* MAIN COLOR OVERRIDES */

    primary: {
      darker: `#802418`,
      dark: `#cc3a26`,
      main: `#ff4830`,
      light: `#ff6d59`,
      lighter: `#ffa498`,
      contrastText: `#fff`,
    },
    secondary: {
      darker: `#203450`,
      dark: `#335380`,
      main: `#4068a0`,
      light: `#6686b3`,
      lighter: `#a0b4d0`,
      contrastText: `#fff`,
    },
    tertiary: {
      darker: `#205058`,
      dark: `#33808d`,
      main: `#40a0b0`,
      light: `#66b3c0`,
      lighter: `#a0d0d8`,
      contrastText: `#fff`,
    },

    /* UTILITY COLOR OVERRIDES */

    utility: {
      darker: `#202430`,
      dark: `#333a4d`,
      main: `#404860`,
      light: `#666d80`,
      lighter: `#a0a4b0`,
      contrastText: `#fff`,
    },
    error: {
      darker: `#801020`,
      dark: `#cc1a33`,
      main: `#ff2040`,
      light: `#ff4d66`,
      lighter: `#ff90a0`,
      contrastText: `#fff`,
    },
    warning: {
      darker: `#805018`,
      dark: `#cc8026`,
      main: `#ffa030`,
      light: `#ffb359`,
      lighter: `#ffd098`,
      contrastText: `#fff`,
    },
    success: {
      darker: `#185a24`,
      dark: `#26903a`,
      main: `#30b448`,
      light: `#59c36d`,
      lighter: `#98daa4`,
      contrastText: `#fff`,
    },
    info: {
      darker: `#185070`,
      dark: `#2680b3`,
      main: `#30a0e0`,
      light: `#59b3e6`,
      lighter: `#98d0f0`,
      contrastText: `#fff`,
    },

    /* SPECIAL COLOR OVERRIDES */

    grey: {
      '50': `#f1f2f3`,
      '100': `#e4e6e7`,
      '150': `#d6d9db`,
      '200': `#c9cccf`,
      '250': `#bbc0c3`,
      '300': `#adb3b8`,
      '350': `#a0a6ac`,
      '400': `#9299a0`,
      '450': `#858d94`,
      '500': `#778088`,
      '550': `#6b737a`,
      '600': `#5f666d`,
      '650': `#535a5f`,
      '700': `#474d52`,
      '750': `#3c4044`,
      '800': `#303336`,
      '850': `#242629`,
      '900': `#181a1b`,
      '950': `#0c0d0e`,
      A100: `#f1f2f3`,
      A200: `#c9cccf`,
      A400: `#9299a0`,
      A700: `#474d52`,
    },

    background: {
      default: `#f4f6f8`,
      alt: `#f0f2f4`,
      paper: `#fff`,
    },

    divider: `#00204018`,
    dividerContrast: `#ffffff18`,

    action: {
      active: `#20243080`,
      hover: `#2024300c`,
      selected: `#20243018`,
      disabled: `#20243050`,
      disabledBackground: `#20243024`,
      focus: `#20243024`,
      contrastActive: `#ffffff80`,
      contrastHover: `#ffffff0c`,
      contrastSelected: `#ffffff18`,
      contrastDisabled: `#ffffff50`,
      contrastDisabledBackground: `#ffffff24`,
      contrastFocus: `#ffffff24`,
    },

    /* TEXT COLOR OVERRIDES */

    text: {
      primary: `#202430`,
      secondary: `#202430a0`,
      disabled: `#20243072`,
      contrastPrimary: `#ffffff`,
      contrastSecondary: `#ffffffa0`,
      contrastDisabled: `#ffffff72`,
    },

    /* CUSTOM COLOR OVERRIDES */

    nav: {
      header: `#ffffffe8`,
      sidebar: `#203450`,
      contrastText: `#fff`,
    },
  },

  /* SHAPE OVERRIDES */

  shape: {
    borderRadius: 6,
  },

  /* TYPOGRAPHY OVERRIDES */

  typography: {
    fontFamily: `"Inter Tight", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightExtraLight: 100,
    fontWeightVeryLight: 200,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    fontWeightBlack: 900,
    h1: {
      fontSize: `32px`,
      lineHeight: 1.5,
    },
    h2: {
      fontSize: `28px`,
      lineHeight: 1.5,
    },
    h3: {
      fontSize: `24px`,
      lineHeight: 1.5,
    },
    h4: {
      fontSize: `22px`,
      lineHeight: 1.5,
    },
    h5: {
      fontSize: `20px`,
      lineHeight: 1.5,
    },
    h6: {
      fontSize: `18px`,
      lineHeight: 1.5,
    },
    subtitle1: {
      fontSize: `16px`,
      lineHeight: 1.4,
    },
    subtitle2: {
      fontSize: `14px`,
      lineHeight: 1.4,
    },
    body1: {
      fontSize: `16px`,
      lineHeight: 1.333,
    },
    body2: {
      fontSize: `14px`,
      lineHeight: 1.333,
    },
    button: {
      fontSize: `14px`,
      fontWeight: 700,
      lineHeight: `14px`,
      textTransform: `none`,
    },
    caption: {
      fontSize: `0.75rem`,
      lineHeight: 1.25,
    },
    overline: {
      fontSize: `0.625rem`,
      lineHeight: 1.25,
    },
  },

  /* COMPONENT OVERRIDES */

  components: {
    MuiAlertTitle: {
      styleOverrides: {
        root: css`
          margin: 0;
        `,
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: css`
          padding: 0;
        `,
        loading: css`
          padding: 4px 8px !important;
          border-radius: 6px;
          background-color: transparent;
        `,
        noOptions: css`
          padding: 4px 8px !important;
          border-radius: 6px;
          background-color: transparent;
        `,
        option: css`
          padding: 4px 8px !important;
          border-radius: 6px;
          background-color: transparent;
          margin-bottom: 4px !important;
          :last-child {
            margin-bottom: 0 !important;
          }
        `,
        paper: css`
          background-color: transparent;
        `,
        popper: css`
          padding: 6px;
          border: 1px solid #00204018;
          border-radius: 10px;
          background-color: #ffffffe8;
          box-shadow: 0px 2px 16px 2px #20243028;
          backdrop-filter: blur(4px);
        `,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: `contained`,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'small' && {
            height: 30,
          }),
          ...(ownerState.size === 'medium' && {
            height: 36,
          }),
          ...(ownerState.size === 'large' && {
            height: 42,
          }),
        }),
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
        variant: `contained`,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: `primary`,
      },
      styleOverrides: {
        root: css`
          border-radius: 6px;
          padding: 8px;
        `,
      },
    },
    MuiChip: {
      defaultProps: {
        size: `small`,
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: css`
          padding: 0;
        `,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: css`
          padding: 4px;
        `,
      },
    },
    MuiInput: {
      defaultProps: {
        size: `small`,
      },
    },
    MuiMenu: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        list: css`
          padding-top: 6px;
          padding-bottom: 6px;
        `,
        paper: css`
          border: 1px solid #00204018;
          border-radius: 10px;
          background-color: #ffffffe8;
          box-shadow: 0px 2px 16px 2px #20243028;
          backdrop-filter: blur(4px);
        `,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
      styleOverrides: {
        root: css`
          padding-left: 8px;
          padding-right: 8px;
          border-radius: 6px;
          margin-bottom: 4px;
          margin-left: 6px;
          margin-right: 6px;
          :last-child {
            margin-bottom: 0;
          }
        `,
      },
    },
    MuiMenuList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      variants: [
        {
          props: { variant: `custom` },
          style: css`
            border-radius: 10px;
            box-shadow: 0px 2px 12px 2px #20243018;
          `,
        },
        {
          props: { variant: `customPrint` },
          style: css`
            border-radius: 10px;
            border: 1px solid #20243050;
          `,
        },
      ],
    },
    MuiSelect: {
      defaultProps: {
        label: undefined,
        size: `small`,
        variant: `outlined`,
      },
    },
    MuiTextField: {
      defaultProps: {
        label: undefined,
        size: `small`,
        variant: `outlined`,
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        enterDelay: 250,
        followCursor: true,
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: `body3` },
          style: css`
            line-height: 1.333;
            font-family: 'Inter Tight', 'Roboto', 'Helvetica', 'Arial', sans-serif;
            font-size: 12px;
            font-weight: 400;
          `,
        },
        {
          props: { variant: `body4` },
          style: css`
            line-height: 1.333;
            font-family: 'Inter Tight', 'Roboto', 'Helvetica', 'Arial', sans-serif;
            font-size: 10px;
            font-weight: 400;
          `,
        },
        {
          props: { variant: `PageHeader` },
          style: css`
            display: 'inline';
            vertical-align: 'top';
            line-height: 1rem;
            font-size: 24px;
            font-weight: 600;
            /* [theme?.breakpoints.down('sm')] {
              font-size: 21;
            }, */
          `,
        },
        {
          props: { variant: `SectionHeader` },
          style: css`
            line-height: 1;
            font-size: 21px;
            font-weight: 600;
            cursor: default;
          `,
        },
      ],
    },
  },
});

export default theme;
