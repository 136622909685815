import React from 'react';
import { toast } from 'react-toastify';
import { Container, Theme, useTheme } from '@mui/material';
import Spacer from '@components/Utils/Spacer';
import { useRefreshUserClaimsMutation, useGetUserDetailsQuery, Users } from '@gql/schema';
import { useParams } from 'react-router-dom';
import { useUser } from '@store';
import { UserDetailsProvider } from '../providers/UserDetailsProvider';
import * as Sentry from '@sentry/react';
import Loading from '@components/Utils/Loading';

import DefaultEmptyFallback from '@components/Fallbacks/DefaultEmptyFallback';
import DefaultErrorFallback from '@components/Fallbacks/DefaultErrorFallback';
import Toolbar from '@components/Utils/Toolbar';
import { css } from '@emotion/css';

import UserDetailsInfo from '../components/UserDetailsInfo';
import UserDetailsToolbar from '../components/UserDetailsToolbar';
import TerminationModal from '../components/TerminationModal';
import ReinstateModal from '../components/ReinstateModal';

//////////////////////// COMPONENT ////////////////////////

interface ModalState {
  open: boolean;
  input: { user: Users; refetch: () => void } | null;
  output: Users | null;
}

export default function UserDetails() {
  const theme = useTheme();
  const cls = useStyles(theme);
  const currentUser = useUser();
  const { id: userId } = useParams<{ id: string }>();

  // Move query from wrapper
  const { loading, error, data, refetch } = useGetUserDetailsQuery({
    variables: { userId: Number(userId) },
    skip: !userId,
  });

  const [refreshClaimsMutation] = useRefreshUserClaimsMutation();
  const refreshClaims = async () => {
    console.log('refreshing claims')
    try {
      const res = await refreshClaimsMutation({ variables: { userId: data?.users[0]?.id ?? 0 } });
      console.log('res', res);
      if (res?.data?.update_users?.affected_rows ?? 0 > 0) {
        toast.success('Successfully refreshed user claims');
      } else {
        toast.error('Failed to refresh user claims');
      }
    } catch (err) {
      console.log('Error refreshing claims:', err);
      toast.error('Error refreshing claims');
    }
  };

  const [terminationModal, setTerminationModal] = React.useState<ModalState>({
    open: false,
    input: null,
    output: null,
  });
  const handleTerminationModalOpen = (input: { user: Users; refetch: () => void }) => {
    setTerminationModal({ open: true, input: input, output: null });
  };
  const handleTerminationModalClose = (output: Users | null) => {
    setTerminationModal({ open: false, input: null, output: output });
  };

  const [reinstateModal, setReinstateModal] = React.useState<ModalState>({ open: false, input: null, output: null });
  const handleReinstateModalOpen = (input: { user: Users; refetch: () => void }) => {
    setReinstateModal({ open: true, input, output: null });
  };
  const handleReinstateModalClose = (output: Users | null) => {
    setReinstateModal({ open: false, input: null, output: output });
  };

  // Handle refetch callback to pass as props
  const handleRefetch = () => {
    refetch();
  };

  // Early return for restricted access
  if (currentUser?.profile?.default_role === 'dealer' || currentUser?.profile?.default_role === 'dealer-admin') {
    return (
      <div className={cls.root}>
        <DefaultEmptyFallback message='RESTRICTED ACCESS' />
      </div>
    );
  }

  // Loading state
  if (loading) {
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='User Details' />
          <Loading position='fixed' />
        </Container>
      </div>
    );
  }

  // Error state
  if (error) {
    console.error(`Error getting user details:`, error);
    Sentry.captureException(error);
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='User Details' refetch={refetch} />
          <Spacer />
          <DefaultErrorFallback message='ERROR GETTING USER DETAILS' />
        </Container>
      </div>
    );
  }

  // Empty state
  if (!data?.users?.length) {
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='User Details' refetch={refetch} />
          <Spacer />
          <DefaultEmptyFallback message='NO USER FOUND' />
        </Container>
      </div>
    );
  }

  const user = data.users[0] as Users;

  // DATA STATE //

  const userActions = [
    {
      label: `Refresh Auth Claims`,
      handler: () => {
        console.log('Handler called from UserDetails');
        refreshClaims();
      },
      disabled: false
    },
    {
      label: `Deactivate User`,
      handler: () => handleTerminationModalOpen({ user, refetch: handleRefetch }),
      disabled: !user.active,
    },
    {
      label: `Reinstate User`,
      handler: () => handleReinstateModalOpen({ user, refetch: handleRefetch }),
      disabled: user.active,
    },
  ];

  return (
    <>
      <UserDetailsProvider user={user} handleRefetch={handleRefetch}>
        <TerminationModal
          open={terminationModal.open}
          input={terminationModal.input}
          onClose={(output: Users | null) => handleTerminationModalClose(output)}
        />

        <ReinstateModal
          open={reinstateModal.open}
          input={reinstateModal.input}
          onClose={(output: Users | null) => handleReinstateModalClose(output)}
        />

        <div className={cls.pageContainer}>
          <Container maxWidth='lg'>
            <Toolbar
              back
              title='User Details'
              refetch={handleRefetch}
              actions={userActions.map(action => {
                return action;
              })}
            >
              <UserDetailsToolbar user={user} />
            </Toolbar>

            <Spacer />
            <UserDetailsInfo user={user} />

            <Spacer />
          </Container>
        </div>
      </UserDetailsProvider>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = (theme: Theme) => ({
  pageContainer: css`
  padding: ${theme.spacing(3)} 0;
  min-height: 100vh;
`,
});
