import React from 'react';
import { UserDetailsContext } from '../features/userManagement/providers/UserDetailsProvider';
import { UserDetailsContextType } from '../features/userManagement/types/userDetailsTypes';

export const useUserDetails = (): UserDetailsContextType => {
    const context = React.useContext(UserDetailsContext);
    if (context === undefined) {
        throw new Error('useUserDetails must be used within a UserDetailsProvider');
    }
    return context;
}; 