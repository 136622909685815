import axios from 'axios';

const log = false;

export const getDefaultConfig = async (userToken: string) => {
  const res = await axios({
    method: `POST`,
    url: `/.netlify/functions/getDefaultConfig`,
    data: {},
    headers: {
      authorization: `Bearer ${userToken}`,
    },
  });

  log && console.log(`DEFAULT CONFIG:`, res?.data);

  if (res?.data?.success && res?.data?.defaultConfig) return res?.data?.defaultConfig;
  return null;
};

export const enrichCustomerConfig = async (userToken: string, customerConfig?: any, organizationConfig?: any) => {
  const res = await axios({
    method: `POST`,
    url: `/.netlify/functions/enrichCustomerConfig`,
    data: {
      customerConfig,
      organizationConfig,
    },
    headers: {
      authorization: `Bearer ${userToken}`,
    },
  });

  log && console.log(`ENRICHED CUSTOMER CONFIG:`, res?.data);

  if (res?.data?.success && res?.data?.enrichedCustomerConfig) return res?.data?.enrichedCustomerConfig;
  return null;
};

export const enrichOrganizationConfig = async (userToken: string, organizationConfig?: any) => {
  const res = await axios({
    method: `POST`,
    url: `/.netlify/functions/enrichOrganizationConfig`,
    data: {
      organizationConfig,
    },
    headers: {
      authorization: `Bearer ${userToken}`,
    },
  });

  log && console.log(`ENRICHED ORGANIZATION CONFIG:`, res?.data);

  if (res?.data?.success && res?.data?.enrichedOrganizationConfig) return res?.data?.enrichedOrganizationConfig;
  return null;
};
