// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { Arinvoices, useGetInvoiceSidebarQuery } from '@gql/schema';

import { css } from '@emotion/css';
import { Alert, LinearProgress, Theme, useTheme } from '@mui/material';

import { getDefaultStart, getDefaultEnd } from '@features/invoices/services/calcService';

import InvoiceSidebarFilter from '@features/invoices/components/InvoiceSidebarFilter';
import InvoiceSidebarList from '@features/invoices/components/InvoiceSidebarList';

// TYPES ---------------------------------------------------------------- //

type InvoiceSidebarProps = {
  rooftopId?: number;
  selectedInvoiceId?: number;
};

// COMPONENT ---------------------------------------------------------------- //

const InvoiceSidebar = ({ rooftopId, selectedInvoiceId }: InvoiceSidebarProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const [start, setStart] = React.useState<string>(getDefaultStart());
  const [end, setEnd] = React.useState<string>(getDefaultEnd());

  const { data, loading, error } = useGetInvoiceSidebarQuery({
    variables: { rooftopId, start, end },
  });

  const invoices: Arinvoices[] = data?.arinvoices || [];

  return (
    <div className={cls.sidebar}>
      <InvoiceSidebarFilter start={start} end={end} setStart={setStart} setEnd={setEnd} />

      {loading ? <LinearProgress /> : null}

      {error ? <Alert severity='error'>Error fetching invoices</Alert> : null}

      {!loading && !error ? <InvoiceSidebarList invoices={invoices} selectedInvoiceId={selectedInvoiceId} /> : null}
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  sidebar: css`
    position: relative;
    flex: 1;
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default InvoiceSidebar;
