//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { TableContainer, Table, TablePagination, Theme, useTheme, SxProps, TableHead, TableRow, TableCell, TableSortLabel, TableBody } from '@mui/material';
import { useUserDetails } from '@hooks/useUserDetails';
import { getComparator, stableSort } from '@services/utilityService';
import { css } from '@emotion/css';
import { Customers, Usertocustomers } from '@gql/schema';

//////////////////////// TYPES //////////////////////////////

type Order = 'asc' | 'desc';

interface TableHeader {
  id: string;
  align?: 'left' | 'right' | 'center';
  label: string;
  padding?: 'normal' | 'checkbox' | 'none';
  hide?: boolean;
  component?: React.ReactNode;
}

type Row = {
  ID: string | null;
  CUSTOMER: string | null;
};

//////////////////////// COMPONENT ////////////////////////

function AllowedCustomersTable() {
  const ctx = useUserDetails();

  const theme = useTheme();
  const cls = useSxStyles(theme);
  const styles = useStyles();

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('end_date');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headers = [
    { id: 'ID', align: 'left' as const, label: 'ID' },
    { id: 'CUSTOMER', align: 'left' as const, label: 'Customer' },
  ];

  const rows = Array.isArray(ctx?.allowedCustomers) 
    ? ctx.allowedCustomers.map((allowedCustomer: Customers | Usertocustomers) => {
    let customerId: string | null = null;
    let customerName: string | null = null;

    if ('customer_id' in allowedCustomer) {
      customerId = allowedCustomer.customer_id.toString();
    } else if ('id' in allowedCustomer) {
      customerId = allowedCustomer.id.toString();
    }

    if ('customer' in allowedCustomer && allowedCustomer.customer?.name) {
      customerName = allowedCustomer.customer.name;
    } else if ('name' in allowedCustomer) {
      customerName = allowedCustomer.name ?? null;
    }

    return {
      ID: customerId,
      CUSTOMER: customerName,
    };
  }) : [];

  return (
    <>
      <TableContainer sx={cls.paper}>
        <Table size='small' aria-label='rate-rule-group-index-table'>
          <TableHeadComponent
            headers={headers}
            order={order as Order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
          <TableBodyComponent 
            rows={rows} 
            order={order as Order} 
            orderBy={orderBy} 
            page={page} 
            rowsPerPage={rowsPerPage} 
          />
        </Table>
      </TableContainer>
      <TablePagination
        sx={cls.pagination}
        classes={{
          toolbar: styles.toolbar,
        }}
        rowsPerPageOptions={[10, 25, 50, 100]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  paper: {
    background: theme.palette.background.paper,
    width: '75%'
  },
  pagination: {
    borderBottom: `1px solid #00000020`,
    width: '75%'
  },
  toolbar: {
    paddingRight: 0,
    paddingLeft: 0
  }
});

const useStyles = () => ({
   toolbar: css`
   padding-right: 0;
   padding-left: 0;
   ` 
});

//////////////////////// SUB-COMPONENTS ////////////////////////

function TableHeadComponent({ headers, order, orderBy, setOrder, setOrderBy }: {
  headers: TableHeader[];
  order: Order;
  orderBy: string;
  setOrder: (order: Order) => void;
  setOrderBy: (orderBy: string) => void;
}) {
  const handleChangeSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableHead>
      <TableRow>
        {headers.map(header => {
          if (!header.hide)
            return (
              <TableCell
                key={header.id}
                align={header.align || `left`}
                padding={header.padding || `normal`}
                sortDirection={orderBy === header.id ? order : false}
                sx={{
                  width: header.id === 'ID' ? '200px' : undefined,
                  paddingRight: header.id === 'CUSTOMER' ? '0px' : undefined,
                }}
              >
                {header.component || (
                  <TableSortLabel
                    active={orderBy === header.id}
                    direction={orderBy === header.id ? order : `asc`}
                    onClick={() => handleChangeSort(header.id)}
                  >
                    {header.label || `Label`}
                  </TableSortLabel>
                )}
              </TableCell>
            );
          else return null;
        })}
      </TableRow>
    </TableHead>
  );
}

function TableRowComponent({ row, index }: { row: Row; index: number }) {
  const theme = useTheme();
  const cls = useRowStyles(theme);

  const rowCells = [
    {
      value: row.ID || `-`,
      align: `left`,
      width: '25%',
    },
    {
      value: row.CUSTOMER || `-`,
      align: `left`,
      width: '75%'
    },
  ];

  return (
    <TableRow className={index % 2 ? cls.rowEven : cls.rowOdd}>
      {rowCells.map((cell: { value: string; align: string; width: string; hide?: boolean }, i) => (
        !cell.hide && (
          <TableCell
            key={`atr-row-${row.ID}-col-${i}`}
            align={(cell.align as 'left' | 'right' | 'inherit' | 'center' | 'justify') || 'left'}
            className={cls.tableCell}
          >
            {cell.value || null}
          </TableCell>
        )
      ))}
    </TableRow>
  );
}

function TableBodyComponent({ rows, order, orderBy, page, rowsPerPage }: {
  rows: Row[];
  order: Order;
  orderBy: string;
  page: number;
  rowsPerPage: number;
}) {
  return (
    <TableBody sx={{ whiteSpace: 'nowrap', width: '100%' }}>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, i) => (
          <TableRowComponent 
            key={i}
            row={row as Row} 
            index={i} 
          />
        ))}
    </TableBody>
  );
}

//////////////////////// STYLES ////////////////////////

const useRowStyles = (theme?: Theme) => ({
  rowOdd: css`
    display: flex;
    background-color: ${theme?.palette.background.paper};
    &:hover {
      background-color: ${theme?.palette.action.hover};
    }
    cursor: pointer;
    transition: background-color 0.1s;
    width: 100%;
  `,
  rowEven: css`
    display: flex;
    background-color: #f8f8f8;
    &:hover {
      background-color: ${theme?.palette.action.hover};
    }
    cursor: pointer;
    transition: background-color 0.1s;
    width: 100%;
  `,
  tableCell: css`
    padding: 16px 8px;
    border-bottom: 1px solid ${theme?.palette.divider};
    display: flex;
    align-items: center;
  `,
  fixedWidth: css`
    flex: none;
  `,
  flexCell: css`
    flex: 1;
  `
});

//////////////////////// EXPORT ////////////////////////

export default AllowedCustomersTable;