import React from 'react';
// import * as Sentry from '@sentry/react';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '@components/Modal';
import { toast } from 'react-toastify';
import { useRemovePaymentMethodMutation } from '@gql/schema';

interface MethodRemoveModalProps {
  open: boolean;
  onClose: () => void;
  methodInput: any;
  refetch: () => void;
}

////////// COMPONENT //////////
const MethodRemoveModal: React.FC<MethodRemoveModalProps> = (props: MethodRemoveModalProps) => {
  const { open, onClose, methodInput, refetch } = props;

  const [removePaymentMethod] = useRemovePaymentMethodMutation();

  const handleSubmit = async () => {
    try{
      await removePaymentMethod({variables: {id: methodInput.id}});
      refetch();
    }catch(err){
      toast.error(`Failed to remove payment method`);
      console.log(`Error removing payment method:`, err);
    };
    handleClose();
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
      <Modal open={open}>
        <ModalHeader handleClose={handleClose}>Remove Payment Method</ModalHeader>

        <ModalContent
          subtitle={`Are you sure you want to remove this payment method? (${methodInput && methodInput.name})`}
        />

        <ModalFooter>
          <ModalAction onClick={() => handleSubmit()}>Confirm</ModalAction>
          <ModalAction onClick={() => handleClose()} color='secondary'>
            Cancel
          </ModalAction>
        </ModalFooter>
      </Modal>
  );
};

export default MethodRemoveModal;
