// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';

import { Arinvoices, useGetInvoiceDetailsQuery } from '@gql/schema';

import { css } from '@emotion/css';
import { Alert, CircularProgress, Theme, useTheme } from '@mui/material';

import InvoiceDetailsContent from '@features/invoices/components/InvoiceDetailsContent';

// TYPES ---------------------------------------------------------------- //

type InvoiceDetailsProps = {
  rooftopId?: number;
  selectedInvoiceId?: number;
};

// COMPONENT ---------------------------------------------------------------- //

const InvoiceDetails = ({ rooftopId, selectedInvoiceId }: InvoiceDetailsProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const { data, loading, error, refetch } = useGetInvoiceDetailsQuery({
    variables: { invoiceId: selectedInvoiceId || 0 },
  });

  const invoice: Arinvoices = data?.arinvoices_by_pk;

  const handleRefetch = async () => {
    await refetch();
  };

  return (
    <div className={cls.details}>
      <div className={cls.detailsContainer}>
        {loading ? <CircularProgress className={cls.loading} /> : null}

        {error ? <Alert severity='error'>Error fetching invoice</Alert> : null}

        {!loading && !error ? <InvoiceDetailsContent invoice={invoice} refetch={handleRefetch} /> : null}
      </div>
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  details: css`
    position: relative;
  `,
  detailsContainer: css`
    position: relative;
    max-width: 1080px;
    padding: 24px;
    margin: 0 auto;
    ${theme?.breakpoints?.down('md')} {
      padding: 18px;
    }
    ${theme?.breakpoints?.down('sm')} {
      padding: 12px;
    }
  `,

  loading: css`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    color: ${theme?.palette?.primary?.main};
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default InvoiceDetails;
