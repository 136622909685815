import React from 'react';
import { Typography, Tooltip, Theme, SxProps, useTheme } from '@mui/material';
import { css } from '@emotion/css';
import { Link } from 'react-router-dom';
import { Moves } from '@gql/schema';
import organizationOverviewUtils from '../utils/organizationOverviewUtils';
import MoveItemTooltip from './MoveItemTooltip';

interface OverviewMoveItem {
  move: Moves;
}

const OverviewMoveItem: React.FC<OverviewMoveItem> = (props: OverviewMoveItem) => {
  const theme = useTheme();
  const cls = useStyles(theme);
  const sx = useSxStyles(theme);

  const { move } = props;

  const [status, setStatus] = React.useState<string>('MoveItem');

  //Display time move is scheduled hh:mm a
  let moveISO = move?.ready_by || '';
  let pickupTime = organizationOverviewUtils.formatDateTime(moveISO);

  //Show consumer name on move if it is a concierge move
  let consumerName = move && move.consumer_name ? move.consumer_name : '';
  let consumerPhone = move && move.consumer_phone ? move.consumer_phone : '';

  //Basic vehicle description
  let vehicleColor = move && move.vehicle_color ? move.vehicle_color : '';
  let vehicleDescription = `${organizationOverviewUtils.toProperCase(vehicleColor)} ${move.vehicle_make} ${move.vehicle_model}`;

  //Check cancel status in case move's status has not been set to canceled
  let moveCanceled = move.cancel_status && (move.cancel_status === 'canceled' || move.cancel_status === 'started');

  React.useEffect(() => {
    //Set color and pulse based on move status
    let moveStatus = move?.status;
    if (moveStatus) {
      if (moveStatus === `dispatched` || moveStatus === null) {
        //Move not yet started
        setStatus('MoveItem');
      } else if (moveStatus === `delivery successful`) {
        //move completed
        setStatus('MoveItemCompleted');
      } else if (moveStatus === `canceled` || moveCanceled) {
        //move completed
        setStatus('MoveItemCanceled');
      } else {
        // move in progress
        setStatus('MoveItemInProgress');
      }
    }
  }, [move]);

  return (
    <Tooltip
      classes={{ tooltip: cls.MoveTooltip }}
      enterDelay={500}
      leaveDelay={200}
      title={<MoveItemTooltip move={move} />}
    >
      <Link to={`/moves/${move.id}`} target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
        <div key={`move-item-${move.id}`} className={cls[status]}>
          {/* Only show this text if move is completed or canceled */}
          {status === 'MoveItemCanceled' || status === 'MoveItemCompleted' ? (
            <Typography sx={sx.KeyText}>{status === 'MoveItemCanceled' ? 'CANCELED' : 'COMPLETED'}</Typography>
          ) : null}
          {/* Always show pickup time */}
          <Typography sx={sx.KeyText}>{pickupTime}</Typography>
          {/* Show consumer name if it is a concierge move */}
          {consumerName ? <Typography sx={sx.KeyText}>{consumerName}</Typography> : <></>}
          {/* Show consumer phone if it is a concierge move- else show vehicle info */}
          {consumerPhone ? (
            <Typography sx={sx.MoveText}>{organizationOverviewUtils.formatPhoneNumber(consumerPhone)}</Typography>
          ) : (
            <Typography sx={sx.MoveText}>{vehicleDescription}</Typography>
          )}
        </div>
      </Link>
    </Tooltip>
  );
};

export default OverviewMoveItem;

const useStyles = (theme?: Theme): Record<string, string> => ({
  MoveItem: css`
    color: #fff;
    margin: 4px;
    padding: ${theme?.spacing(1)};
    border-radius: 0.55rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: ${theme?.palette?.primary?.main};
  `,
  MoveItemCompleted: css`
    color: #fff;
    margin: 4px;
    padding: ${theme?.spacing(1)};
    border-radius: 0.55rem;
    display: flex;
    flex-direction: column;
    background-color: #00000040;
    justify-content: space-around;
  `,
  MoveItemCanceled: css`
    color: #fff;
    margin: 4px;
    padding: ${theme?.spacing(1)};
    border-radius: 0.55rem;
    display: flex;
    flex-direction: column;
    background-color: #000;
    justify-content: space-around;
  `,
  MoveItemInProgress: css`
    color: #fff;
    margin: 4px;
    padding: ${theme?.spacing(1)};
    border-radius: 0.55rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: ${theme?.palette?.primary?.main};
    animation: pulse 1s infinite;
    @keyframes pulse {
      0% {
        filter: brightness(80%);
      }
      45% {
        filter: brightness(100%);
      }
      80% {
        box-shadow: 0 0 0 8px #00000000;
      }
      100% {
        filter: brightness(80%);
        box-shadow: 0 0 0 0 #00000000;
      }
    }
  `,
  MoveTooltip: css`
    width: 700px;
  `,
});

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  KeyText: {
    fontWeight: 600,
    margin: '4px',
    lineHeight: '1.25',
    fontSize: 14,
  },
  MoveText: {
    lineHeight: '1.25',
    fontSize: 12,
  },
});
