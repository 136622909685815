// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { css } from '@emotion/css';
import { Theme, useTheme, Tooltip, Typography } from '@mui/material';

import { BiSolidInfoCircle } from 'react-icons/bi';

// TYPES ---------------------------------------------------------------- //

interface DividerProps {
  children?: React.ReactNode;
  spacer?: boolean;
  mt?: number;
  mb?: number;
  tip?: React.ReactNode;
}

// COMPONENT ---------------------------------------------------------------- //

const Divider = ({ children, spacer, mt = 0, mb = 0, tip }: DividerProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  return (
    <>
      {spacer && <div className={cls.dividerSpacer} />}

      <div className={cls.dividerFlex} style={{ marginTop: `${mt}px`, marginBottom: `${mb}px` }}>
        <div className={cls.dividerItemStretch}>
          <div className={cls.dividerLine} />
        </div>

        <div className={cls.dividerItem}>
          <Typography sx={{ textTransform: `uppercase`, fontWeight: 600, color: theme?.palette?.text?.disabled }}>
            {children}
          </Typography>
        </div>

        <div className={cls.dividerItemStretch}>
          <div className={cls.dividerLine} />

          {tip ? (
            <Tooltip placement='top-end' title={tip}>
              <div>
                <BiSolidInfoCircle
                  style={{ display: `block`, cursor: `pointer` }}
                  size={20}
                  color={theme?.palette?.text?.disabled}
                />
              </div>
            </Tooltip>
          ) : null}
        </div>
      </div>
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  return {
    dividerFlex: css`
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
    `,

    dividerItem: css`
      white-space: nowrap;
    `,
    dividerItemStretch: css`
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      flex: 1;
    `,

    dividerLine: css`
      width: 100%;
      border-top: 1px solid ${theme?.palette?.divider};
    `,

    dividerSpacer: css`
      width: 100%;
      height: 48px;
      ${theme?.breakpoints?.down('sm')} {
        height: 36px;
      }
      ${theme?.breakpoints?.down('xs')} {
        height: 24px;
      }
    `,
  };
};

// EXPORT ---------------------------------------------------------------- //

export default Divider;
