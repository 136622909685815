import dayjs from 'dayjs';
import DocumentDefinition from '@utils/pdfDocumentDefinition';
import pdfMake from 'pdfmake/build/pdfmake';

// importing fonts from the library breaks the build. This is a workaround to load the fonts from a CDN
const pdfMakeFonts = {
  Roboto: {
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
};

// No need for vfs_fonts import, just set the fonts
pdfMake.fonts = pdfMakeFonts;

const checkNeg = (num: number) => {
  if (num > 0) return num;
  else return 0;
};

const round = (num: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0);
  const output = Math.round(num * multiplier) / multiplier;
  return output;
};

export const createSingleMoveInvoice = async (invoice: any) => {
  try {
    // Set a consistent amount object that holds the totals
    const amount: any = {};
    // Valid records to calculate base totals
    const subtotalMoves = invoice.armoves.filter((item: any) => item.due_amount > 0);
    const discountedMoves = subtotalMoves.filter(
      (item: any) => item.disputed === false && item.due_amount >= item.discount_amount && item.discount_amount > 0
    );
    const disputedMoves = subtotalMoves.filter((item: any) => item.disputed === true);
    const paidMoves = subtotalMoves.filter((item: any) => item.paid_amount > 0);
    // Base totals from valid records
    amount.subtotal = round(
      subtotalMoves.length > 0
        ? subtotalMoves.map((item: any) => item.due_amount).reduce((total: number, current: number) => total + current)
        : 0,
      2
    );
    amount.discounted = round(
      discountedMoves.length > 0
        ? discountedMoves
            .map((item: any) => item.discount_amount)
            .reduce((total: number, current: number) => total + current)
        : 0,
      2
    );
    amount.disputed = round(
      disputedMoves.length > 0
        ? disputedMoves.map((item: any) => item.due_amount).reduce((total: number, current: number) => total + current)
        : 0,
      2
    );
    amount.paid = round(
      paidMoves.length > 0
        ? paidMoves.map((item: any) => item.paid_amount).reduce((total: number, current: number) => total + current)
        : 0,
      2
    );
    amount.paymentAmount = amount.paid;
    // Calculate totals
    amount.total = checkNeg(amount.subtotal - amount.discounted - amount.disputed);
    amount.moveDueTotal = amount.total;
    amount.dueSans = checkNeg(amount.total - amount.paid);
    amount.processingFee = 0;
    amount.due = checkNeg(amount.dueSans + amount.processingFee);
    // Build the PDF
    const docDefinition = new DocumentDefinition(invoice, amount);
    const dd = docDefinition.create(invoice, amount);
    let name = 'invoice.pdf';
    try {
      if (invoice.armoves && invoice.armoves[0] && invoice.armoves[0].move && invoice.armoves[0].move.consumer_name) {
        name = `${invoice.armoves[0].move.id}_${invoice.armoves[0].move.consumer_name.replace(' ', '_')}_${dayjs.utc(dayjs(invoice.armoves[0].billable_datetime)).format(`MM-DD-YYYY`)}_invoice.pdf`;
      } else {
        name = `${invoice.armoves[0].move.id}_${dayjs.utc(dayjs(invoice.armoves[0].billable_datetime)).format(`MM-DD-YYYY`)}_invoice.pdf`;
      }
    } catch (err) {
      console.error('Could not parse invoice document name:', err);
    }
    pdfMake.createPdf(dd).download(name);
  } catch (err) {
    console.error('Failed to generate PDF of invoice:', err);
    throw new Error('Failed to generate PDF of invoice');
  }
};
