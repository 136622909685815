//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';

interface UploadedTableHeadProps {
  headers: any[];
  order: "asc" | "desc";
  orderBy: string;
  setOrder: (order: "asc" | "desc") => void;
  setOrderBy: (orderBy: string) => void;
}

//////////////////////// COMPONENT ////////////////////////

const UploadedTableHead: React.FC<UploadedTableHeadProps> = props => {
  const sxStyles = useSxStyles();

  const { headers, order, orderBy, setOrder, setOrderBy } = props;

  // Handle sorting state
  const handleChangeSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableHead>
      <TableRow>
        {headers.map(header => {
          if (!header.hide)
            return (
              <TableCell
                key={header.id}
                align={header.align || `left`}
                padding={header.padding || `normal`}
                sortDirection={orderBy === header.id ? order : false}
              >
                {header.component || (
                  <TableSortLabel
                    sx={sxStyles.headTxt}
                    active={orderBy === header.id}
                    direction={orderBy === header.id ? order : `asc`}
                    onClick={() => handleChangeSort(header.id)}
                  >
                    {header.label || `Label`}
                  </TableSortLabel>
                )}
              </TableCell>
            );
          else return null;
        })}
      </TableRow>
    </TableHead>
  );
};

export default UploadedTableHead;

//////////////////////// STYLES ////////////////////////

const useSxStyles = () => ({
  headTxt: {
    fontSize: 12,
    fontWeight: 600,
  },
});
