// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { Moves } from '@gql/schema';

import { css } from '@emotion/css';
import { Theme, useTheme } from '@mui/material';
import DashboardMoveTile from '@features/dashboard/components/DashboardMoveTile';

// TYPES ---------------------------------------------------------------- //

interface MoveDetailsRelatedProps {
  move?: Moves;
  relatedMoves?: Moves[];
}

// COMPONENT ---------------------------------------------------------------- //

const MoveDetailsRelated = ({ move, relatedMoves }: MoveDetailsRelatedProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  if (!move || !relatedMoves?.length) return null;
  return (
    <div className={cls.flex}>
      {relatedMoves.map(relatedMove => (
        <div key={`related-move-${relatedMove.id}`} className={cls.flexItem}>
          <DashboardMoveTile move={relatedMove} />
        </div>
      ))}
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme, props?: any) => ({
  flex: css`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
  `,
  flexItem: css`
    min-width: 480px;
    max-width: 600px;
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default MoveDetailsRelated;
