import { capEach } from '@services/formatService';

export const getFormattedTypeFromLocation = (location: any | null) => {
  const fallbackStatus = `-`;

  if (location) {
    const type = location?.type || null;

    if (type && type !== ``) {
      if (type === `customer`) return `Standard`;
      else if (type === `consumer business`) return `Consumer Business`;
      else if (type === `consumer residential`) return `Consumer Residential`;
      else return capEach(type);
    }
  }
  return fallbackStatus;
};

export const getLocationByIdFromList = (locationId: string, locations: any[]) => {
  if (locationId) {
    const foundLocation = locations.find(pl => pl.id === locationId);
    if (foundLocation) return foundLocation;
  }
  return null;
};

export const getPendingAccessorial = (accs: any[]) => {
  return accs.find(acc => acc.status === 'pending' && acc.authorization);
};

export const getNameFromLocation = (location: any | null) => {
  const fallbackName = `Unknown Location`;

  if (location) {
    let locationName = fallbackName;

    if (location.name) locationName = location.name;

    return locationName;
  }

  return fallbackName;
};

export const getDriveTypeFromMove = (move: any | null) => {
  const fallbackType = `ops`;

  if (move) {
    let driveType = fallbackType;

    if (move.consumer_pickup && move.consumer_type === `customer`) driveType = `concierge`;
    if (move.consumer_type === `loaner`) driveType = `loaner`;

    return driveType;
  }

  return fallbackType;
};
