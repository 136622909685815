import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged as firebaseOnAuthStateChanged,
  signOut,
  User as FirebaseUser,
  ParsedToken,
} from 'firebase/auth';
import { GetUserProfileDocument, GetUserProfileUpdatedAtDocument, Users, Users_Select_Column } from '@gql/schema';
import app from './firebase.config';
import { execGraphqlWithFetch } from '@utils/gql';
import { config } from '@config';
const { firebase } = config;

interface Claims extends ParsedToken {
  'https://hasura.io/jwt/claims': {
    'x-hasura-default-role'?: string;
    'x-hasura-allowed-customers'?: string;
    'x-hasura-allowed-payers'?: string;
    'x-hasura-payer-child-customers'?: string;
    'x-hasura-allowed-regions'?: string;
    'x-hasura-allowed-roles'?: string;
    'x-hasura-customer-id'?: string;
    'x-hasura-user-id'?: string;
    'x-hasura-user-email'?: string;
  };
}

//Claims after they have been converted into their useable types
interface ConvertedClaims {
  'x-hasura-default-role'?: string;
  'x-hasura-allowed-customers'?: number[];
  'x-hasura-allowed-payers'?: number[];
  'x-hasura-payer-child-customers'?: number[];
  'x-hasura-allowed-regions'?: number[];
  'x-hasura-allowed-roles'?: string[];
  'x-hasura-customer-id'?: number;
  'x-hasura-user-id'?: number;
  'x-hasura-user-email'?: string;
}

export type User = {
  getToken: () => Promise<string | undefined>;
  profile: Users | null;
  claims: Claims;
};

const auth = getAuth(app);

export const buildConvenientGlobalUserObject = async (): Promise<User> => {
  const claims = await getClaimsFromToken();
  const userId = claims[config.firebase.claims.userId];
  const profile = await getUserProfile(userId);
  const user: User = {
    getToken: getAccessTokenWithAutoRefresh,
    profile,
    claims,
  };
  return user;
};

export const getUserProfile = async (userId?: number): Promise<Users | null> => {
  try {
    const user = await execGraphqlWithFetch<Users | unknown>({
      query: GetUserProfileDocument,
      variables: { userId },
    });

    if ((user as Users).id) {
      return user as Users;
    }

    return null;
  } catch (err) {
    console.log('Error getting user', err);
    return null;
  }
};

export const getUserProfileLastUpdatedAt = async (userId: number): Promise<Users_Select_Column.UpdatedAt | null> => {
  try {
    const user = await execGraphqlWithFetch<Users>({
      query: GetUserProfileUpdatedAtDocument,
      variables: { userId },
    });

    if ((user as Users).updated_at) {
      return user?.updated_at as Users_Select_Column.UpdatedAt;
    }

    return null;
  } catch (err) {
    console.log('Error getting user', err);
    return null;
  }
};

export const getClaimsFromToken = async (): Promise<ConvertedClaims> => {
  const parsedToken = await auth.currentUser?.getIdTokenResult();
  const rawClaims = JSON.parse(JSON.stringify(parsedToken?.claims[firebase.claims.namespace]));

  if (rawClaims[firebase.claims.allowedRegions])
    rawClaims[firebase.claims.allowedRegions] = JSON.parse(getArrayFromHasuraArray(rawClaims[firebase.claims.allowedRegions]));

  if (rawClaims[firebase.claims.payerChildCustomers])
    rawClaims[firebase.claims.payerChildCustomers] = JSON.parse(
      getArrayFromHasuraArray(rawClaims[firebase.claims.payerChildCustomers])
    );

  if (rawClaims[firebase.claims.allowedCustomers])
    rawClaims[firebase.claims.allowedCustomers] = JSON.parse(getArrayFromHasuraArray(rawClaims[firebase.claims.allowedCustomers]));

  if (rawClaims[firebase.claims.allowedPayers])
    rawClaims[firebase.claims.allowedPayers] = JSON.parse(getArrayFromHasuraArray(rawClaims[firebase.claims.allowedPayers]));

  if (rawClaims[firebase.claims.customerId] && !isNaN(Number(rawClaims[firebase.claims.customerId]))) {
    rawClaims[firebase.claims.customerId] = parseInt(rawClaims[firebase.claims.customerId]);
  }

  return rawClaims;
};

export const checkForPermittedRole = (user?: any) => {
  const allowedRoles = user?.claims?.['x-hasura-allowed-roles'] || [];
  const defaultRole = user?.claims?.['x-hasura-default-role'] || ``;
  if (!allowedRoles.includes(defaultRole)) allowedRoles.push(defaultRole);
  return (
    allowedRoles.includes(`admin`) ||
    allowedRoles.includes(`dispatcher`) ||
    allowedRoles.includes(`dealer-super-admin`) ||
    allowedRoles.includes(`dealer-admin`) ||
    allowedRoles.includes(`dealer`)
  );
};

export const checkForPowerRole = (user?: any) => {
  const allowedRoles = user?.claims?.['x-hasura-allowed-roles'] || [];
  const defaultRole = user?.claims?.['x-hasura-default-role'] || ``;
  if (!allowedRoles.includes(defaultRole)) allowedRoles.push(defaultRole);
  return (
    allowedRoles.includes(`admin`) ||
    allowedRoles.includes(`dispatcher`) ||
    allowedRoles.includes(`dealer-super-admin`) ||
    allowedRoles.includes(`dealer-admin`)
  );
};

const getArrayFromHasuraArray = (arr: string) => {
  try {
    const replacedJSON = JSON.stringify(arr).replace('{', '[').replace('}', ']');
    const parsedJSON = JSON.parse(replacedJSON);
    return parsedJSON;
  } catch (error) {
    console.log(`Failed to convert Hasura array`, { hasuraArray: arr, error });
    return;
  }
};

const login = (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const logout = () => {
  return signOut(auth);
};

const resetPassword = (email: string) => {
  return sendPasswordResetEmail(auth, email);
};

const onAuthStateChanged = (callback: (user: FirebaseUser | null) => void) => {
  return firebaseOnAuthStateChanged(auth, callback);
};

const getAccessTokenWithAutoRefresh = async (): Promise<string | undefined> => {
  try {
    let userId;
    let tokenIssued;
    let firebaseTokenResult = null;
    if (auth && auth.currentUser) {
      firebaseTokenResult = await auth.currentUser.getIdTokenResult();
    }
    return firebaseTokenResult?.token;
  } catch (err) {
    console.log('Error getting most up-to-date token', err);
    return;
  }
};

const getAuthStatus = async () => {
  if (auth && auth.currentUser) {
    return true;
  } else return false;
};

const getAccessToken = async () => {
  try {
    const token = await auth.currentUser?.getIdToken();
    return token;
  } catch (err) {
    console.log('Error getting token', err);
    return;
  }
};

const writeUserEvent = async (action: string) => {
  console.log('user eventlog action', action);
  // const claims = await getClaimsFromToken();
  // const userId = claims['x-hasura-user-id'];
  // const email = claims['x-hasura-user-email'];
  // const token = await getAccessToken();
  //   try {
  //     const userEventlogRes =  await axios({
  //       method: `POST`,
  //       url: `/.netlify/functions/writeUserEventLogs`,
  //       data: {
  //         actorEmail: email,
  //         action: action,
  //         affectedUserId: userId,
  //       },
  //       headers: {
  //         'content-type': 'application/json',
  //         authorization: `Bearer ${token}`,
  //       },
  //     })

  //     console.log('userEventlogRes', userEventlogRes)

  //     if (userEventlogRes && userEventlogRes.status && userEventlogRes.status !== 200) {
  //       console.log('Failed to write user eventlog:', userEventlogRes);
  //     }
  //   } catch (err) {
  //     console.error(`Failed to write user eventlog:`, err);
  //   }
};

const authService = {
  login,
  logout,
  resetPassword,
  onAuthStateChanged,
  getAccessTokenWithAutoRefresh,
  getAccessToken,
  getAuthStatus,
  writeUserEvent,
};

export { auth, authService };

export default authService;
