// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';

import { css } from '@emotion/css';
import { Theme, useTheme, InputAdornment, TextField, Typography } from '@mui/material';
import { BiDetail, BiSolidContact, BiSolidInfoCircle } from 'react-icons/bi';

import { Additional } from '@features/moveCreation/providers/MovePlannerProvider';

const maxContactLength = 128;
const maxNotesLength = 512;

// TYPES ---------------------------------------------------------------- //

interface MovePlannerAdditionalFormProps {
  additional?: Additional;
  setAdditional?: (additional?: Additional) => void;
  appointmentMode?: boolean;
}

// COMPONENT ---------------------------------------------------------------- //

const MovePlannerAdditionalForm = ({ additional, setAdditional, appointmentMode }: MovePlannerAdditionalFormProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  return (
    <div className={cls.additional}>
      <div className={cls.title}>
        <BiSolidInfoCircle className={cls.titleIcon} />

        {appointmentMode ? (
          <>
            <Typography variant='body2' sx={{ textWrap: `pretty`, color: theme?.palette?.text?.secondary }}>
              Any additional information concerning this appointment will be shared with the driver moving the vehicle.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant='body2' sx={{ textWrap: `pretty`, color: theme?.palette?.text?.secondary }}>
              Any additional information concerning this move will be shared with the driver performing the move. Please
              reserve the "Contact Info" field for contact information only. All other information can be added to the
              "Notes/Instructions" field.
            </Typography>
          </>
        )}
      </div>

      {!appointmentMode && (
        <>
          <label className={cls.textFieldLabel} htmlFor='additional-contact'>
            Contact Info{' '}
        <span className={cls.textFieldLength}>
          ({additional?.contact?.length || 0}/{maxContactLength})
        </span>
      </label>

      <TextField
        fullWidth
        multiline
        name='additional-contact'
        placeholder='Enter contact info...'
        value={additional?.contact || ``}
        onChange={e => {
          const value = e?.target?.value || ``;
          if (value?.length > maxContactLength) return;
          if (setAdditional) setAdditional({ ...additional, contact: value });
        }}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position='start'>
                <BiSolidContact size={20} />
              </InputAdornment>
            ),
          },
        }}
      />

      <div className={cls.spacer} />
      </>
      )}

      <label className={cls.textFieldLabel} htmlFor='additional-notes'>
        Notes/Instructions{' '}
        <span className={cls.textFieldLength}>
          ({additional?.notes?.length || 0}/{maxNotesLength})
        </span>
      </label>

      <TextField
        fullWidth
        multiline
        name='additional-notes'
        placeholder='Enter additional notes/instructions...'
        value={additional?.notes || ``}
        onChange={e => {
          const value = e?.target?.value || ``;
          if (value?.length > maxNotesLength) return;
          if (setAdditional) setAdditional({ ...additional, notes: value });
        }}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position='start'>
                <BiDetail size={20} />
              </InputAdornment>
            ),
          },
        }}
      />
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  return {
    additional: css`
      position: relative;
    `,

    title: css`
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 12px;
      border: 1px solid ${theme?.palette?.divider};
      border-radius: 10px;
      margin-bottom: 12px;
      background-color: ${theme?.palette?.action?.hover};
    `,
    titleIcon: css`
      min-width: 20px;
      min-height: 20px;
      margin-top: -2px;
      font-size: 20px;
      color: ${theme?.palette?.text?.secondary};
    `,

    textFieldLabel: css`
      display: block;
      margin-bottom: 2px;
      margin-left: 6px;
      font-size: 12px;
      font-weight: 600;
    `,
    textFieldLength: css`
      color: ${theme?.palette?.text?.secondary};
    `,
    textFieldLabelRequired: css`
      color: ${theme?.palette?.error?.main};
    `,

    flex: css`
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      ${theme?.breakpoints?.down('sm')} {
        flex-direction: column;
      }
    `,
    flexItem: css`
      flex: 1;
      width: 100%;
    `,

    spacer: css`
      width: 100%;
      height: 12px;
    `,
  };
};

// EXPORT ---------------------------------------------------------------- //

export default MovePlannerAdditionalForm;
