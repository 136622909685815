// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';
import { useNavigate } from 'react-router-dom';

import { css } from '@emotion/css';
import { IconButton, Theme, Tooltip, useTheme } from '@mui/material';

import { BiLeftArrowAlt } from 'react-icons/bi';

// TYPES ---------------------------------------------------------------- //

interface IconBackProps {
  className?: string;
  tooltipPlacement?: 'bottom' | 'top' | 'left' | 'right';
  [key: string]: any;
}

// COMPONENT ---------------------------------------------------------------- //

const IconBack = ({ className = undefined, tooltipPlacement = 'top', ...rest }: IconBackProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const navigate = useNavigate();

  const goToPreviousPage = () => {
    navigate(-1);
  };

  const getClassNames = () => {
    return `${cls.iconBtn} ${className}`;
  };

  return (
    <Tooltip placement={tooltipPlacement} title='Back'>
      <IconButton className={getClassNames()} onClick={goToPreviousPage} {...rest}>
        <BiLeftArrowAlt size={24} />
      </IconButton>
    </Tooltip>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  iconBtn: css`
    padding: ${theme?.spacing(1)};
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default IconBack;
