import React from 'react';
import { css } from '@emotion/css';
import { toast } from 'react-toastify';
import { v1 as uuid } from 'uuid';
import { Button, Grid2 as Grid, Typography, Icon, Theme, useTheme, SxProps } from '@mui/material';

// import Loading from '@components/Utils/Loading';
import Spacer from '@components/Utils/Spacer';
// import Button from '@components/Utils/Button';

import { useSubscribeToBatchJobsSubscription, Batch_Jobs } from '@gql/schema';
import { useRooftop } from '@store';

// import * as Sentry from '@sentry/react';

import useMoveImport from '@features/moveImport/hooks/useMoveImport';
import UploadedTable from '@features/moveImport/components/UploadedTable';

const log = false;
interface UploadedContentProps {
  headers: string[];
  entries: any[];
  moves: any[];
}

//////////////////////// COMPONENT ////////////////////////

const UploadedContent: React.FC<UploadedContentProps> = props => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sxStyles = useSxStyles(theme);
  const rooftop = useRooftop();

  const { headers, entries, moves } = props;

  const { insertBatchJobs, downloadErrorMovesCSV } = useMoveImport();

  const [entriesEdit, setEntriesEdit] = React.useState<any>([]);
  const [movesCreateLoading, setMovesCreateLoading] = React.useState(false);
  const [movesCreateDone, setMovesCreateDone] = React.useState(false);
  const [movesCreateSuccess, setMovesCreateSuccess] = React.useState(false);
  const [moveCreateErrors, setMovesCreateErrors] = React.useState<any>([]);
  const [successCount, setSuccessCount] = React.useState(0);
  const [errorCount, setErrorCount] = React.useState(0);
  const [batchId, setBatchId] = React.useState(``);

  const { data, loading, error } = useSubscribeToBatchJobsSubscription({
    variables: { batchId: batchId },
  });

  // Detect initial errors after building the move objects
  React.useEffect(() => {
    if (moves && moves.length > 0) {
      const detectErrors = () => {
        setMovesCreateErrors([]);
        let newErrors: any = [];
        moves.forEach((move, i) => {
          if (move && move.errors && move.errors.length > 0) {
            move.errors.forEach((err: any) => {
              newErrors.push({ index: i, message: err });
            });
          }
        });
        setMovesCreateErrors(newErrors);
      };
      detectErrors();
    }
  }, [moves]);

  // Set editable entries
  React.useEffect(() => {
    if (entries) setEntriesEdit(entries);
    else setEntriesEdit([]);
    setBatchId(``);
    setMovesCreateLoading(false);
    setMovesCreateDone(false);
    setMovesCreateSuccess(false);
  }, [entries]);

  //data
  React.useEffect(() => {
    if (data) {
      console.log('subscription data', data);
      log &&
        console.log(
          `Subscription found batch jobs data, detecting output...`,
          data?.batch_jobs ? JSON.parse(JSON.stringify(data?.batch_jobs)) : null
        );
      handleSubscriptionData(data?.batch_jobs ? JSON.parse(JSON.stringify(data?.batch_jobs)) : null);
    }
  }, [data]);

  // Handler for inserting moves
  const handleInsertBatchJobs = async () => {
    // Set state variables
    setBatchId(``);
    setMovesCreateLoading(true);
    setMovesCreateErrors([]);

    // Get customer ID
    const customerId = rooftop?.id;
    if (!customerId) {
      toast.error(`Failed to create move(s)! Market ID not found.`);
      return;
    }

    // Setting batchId will automatically resubscribe the subscription
    const newBatchId = uuid();

    // Insert all moves thru batch jobs and set the batch id so the subscription can look for it
    const res = await insertBatchJobs(newBatchId, moves, customerId, null);
    if (res && res.success && res.batchJobs && res.batchJobs.length > 0) {
      setBatchId(newBatchId);
    }
  };

  // Handler for when the move creation data is found
  const handleSubscriptionData = (batchJobs: Batch_Jobs[]) => {
    if (!batchJobs || !batchJobs.length) return;
    // Set a bool and check to make sure all responses were resolved
    let allResponsesResolved = true;
    batchJobs.forEach(bj => {
      if (bj.status === `pending` || bj.status === `processing`) allResponsesResolved = false;
    });

    // Make sure all the criteria is met to rerender with data
    if (allResponsesResolved && movesCreateLoading && !movesCreateDone) {
      const movesRes: any = batchJobs.map(bj => bj.output);
      log && console.log(`Move response outputs:`, movesRes);

      // Check the movesRes output
      if (movesRes && movesRes.length > 0) {
        // Initialize variable overrides
        let newErrors: any[] = [];
        let newEntries: any[] = [];
        let newSuccessCount = 0;
        let newErrorCount = 0;

        // Loop over each move response
        movesRes.forEach((moveRes: any, i: number) => {
          let newEntry = entries[i];

          // Handle errors
          if (moveRes && moveRes.errors && moveRes.errors.length > 0) {
            moveRes.errors.forEach((err: any) => newErrors.push({ index: i, ...err }));
            newEntry.success = false;
            newErrorCount++;
          } else if (!moveRes || (moveRes && moveRes.success === false)) {
            newErrors.push({
              index: i,
              message: `Unexpected error while building the move. Please contact our dispatch team.`,
            });
            newEntry.success = false;
            newErrorCount++;
          }

          // Handle successes
          if (moveRes && moveRes.success === true) {
            const reqType = moveRes.request_type;
            if (reqType === `concierge`) newEntry.move_ids = [moveRes.customer_move.id];
            if (reqType === `one-way`) newEntry.move_ids = [moveRes.move.id];
            if (reqType === `concierge-loaner`) newEntry.move_ids = [moveRes.loaner_move.id, moveRes.customer_move.id];
            if (reqType === `round-trip`) newEntry.move_ids = [moveRes.move_one.id, moveRes.move_two.id];
            newEntry.success = true;
            newSuccessCount++;
          }

          // Always push the entry
          newEntries.push(newEntry);
        });

        // Set state variables
        setMovesCreateErrors(newErrors);
        setEntriesEdit(newEntries);
        setSuccessCount(newSuccessCount);
        setErrorCount(newErrorCount);

        // Notify the user if the insert was successful
        if (newErrors.length > 0) {
          toast.error(`Failed to create move(s)! Please take a look at the errors within the rows.`);
        } else {
          toast.success(`Successfully created all listed move(s)!`);
          setMovesCreateSuccess(true);
        }
      } else {
        toast.error(`Failed to create move(s)! An unexpected error has occurred. Please contact our dispatch team.`);
      }

      // Set loading and done
      setMovesCreateLoading(false);
      setMovesCreateDone(true);
    }
  };

  // if (loading) return <Loading />;
  if (error) return <div>Error: {error.message}</div>;
  // if (!data || !data.batch_jobs || !data.batch_jobs.length) return <div>no data</div>;
  if (entriesEdit && entriesEdit.length > 0)
    return (
      <div className={styles.paper}>
        <Typography sx={sxStyles.titleTxt}>Moves Imported from CSV</Typography>

        <Spacer size='xs' />

        <Typography sx={sxStyles.subtitleTxt}>
          Please review and verify the imported moves information before finalizing.
        </Typography>

        <Spacer size='xs' />

        <UploadedTable headers={headers} entries={entriesEdit} errors={moveCreateErrors} />

        {movesCreateSuccess ? (
          <>
            <Spacer size='lg' />

            <div className={styles.success}>
              <Grid container spacing={2} alignItems='center'>
                <Grid size='grow'>
                  <Typography sx={sxStyles.titleTxt}>All {successCount} move(s) were created successfully!</Typography>
                </Grid>
                <Grid>
                  <Icon sx={sxStyles.notifyIcon}>check_circle</Icon>
                </Grid>
              </Grid>
            </div>

            <Spacer size='xs' />
          </>
        ) : null}

        {moveCreateErrors.length > 0 ? (
          <>
            <Spacer size='lg' />

            <div className={styles.error}>
              <Grid container spacing={2} alignItems='center'>
                <Grid size='grow'>
                  {successCount > 0 ? (
                    <Typography sx={sxStyles.titleTxt}>Successfully created {successCount} move(s)!</Typography>
                  ) : null}
                  <Typography sx={sxStyles.titleTxt}>Failed to create {errorCount} move(s)!</Typography>
                </Grid>
                <Grid>
                  <Icon sx={sxStyles.notifyIcon}>error</Icon>
                </Grid>
              </Grid>
            </div>

            <div className={styles.errorOutline}>
              <Spacer size='xs' />

              <Grid container spacing={2} alignItems='center'>
                <Grid>
                  <Button color='error' variant='outlined' onClick={() => downloadErrorMovesCSV(headers, entriesEdit)}>
                    <Icon sx={sxStyles.btnIconL}>download</Icon>
                    Download Failed Moves CSV
                  </Button>
                </Grid>
                <Grid size='grow'>
                  <Typography sx={sxStyles.notifyTxt}>
                    Click on a failed row to see what it needs to be valid. Use the button to download the failed moves
                    CSV. Then fix those moves and try again. Please <b>DO NOT</b> edit your original CSV and reupload
                    it. This may cause duplicate moves to be created.
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </>
        ) : null}

        {!movesCreateDone ? (
          <>
            <Spacer />

            <Grid container>
              <Grid size='grow' />
              <Grid>
                <Button
                  disabled={movesCreateLoading}
                  // loading={movesCreateLoading}
                  color='primary'
                  onClick={() => handleInsertBatchJobs()}
                >
                  Finalize
                </Button>
              </Grid>
            </Grid>
          </>
        ) : null}
      </div>
    );
};
export default UploadedContent;

//////////////////////// STYLES ////////////////////////

const useStyles = (theme: Theme) => {
  return {
    paper: css`
      position: relative;
      width: 100%;
      padding: ${theme.spacing(2)};
      border-radius: 8px;
      background: ${theme.palette.background.paper};
      box-shadow: ${theme.shadows[3]};
    `,
    success: css`
      position: relative;
      width: 100%;
      padding-left: ${theme.spacing(2)};
      padding-right: ${theme.spacing(2)};
      border-radius: 8px;
      background: ${theme.palette.success.main};
      color: ${theme.palette.success.contrastText};
    `,
    error: css`
      position: relative;
      width: 100%;
      padding-left: ${theme.spacing(2)};
      padding-right: ${theme.spacing(2)};
      border-radius: 8px 8px 0 0;
      background: ${theme.palette.error.main};
      color: ${theme.palette.error.contrastText};
    `,
    errorOutline: css`
      padding: ${theme.spacing(2)};
      border: 1px solid ${theme.palette.error.main};
      border-radius: 0 0 8px 8px;
      color: ${theme.palette.error.main};
    `,
  };
};

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  titleTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 500,
  },
  subtitleTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },

  btnIconL: {
    marginTop: -2,
    marginRight: 8,
    fontSize: 16,
  },
  notifyIcon: {
    display: 'block',
    fontSize: 26,
  },
  notifyTxt: {
    fontSize: 14,
    fontWeight: 400,
  },
});
