import { css } from '@emotion/css';
import { Grid2 as Grid, TextField, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

interface AppointmentsFilterProps {
  refetch: () => void;
  range: string;
  start: string;
  end: string;
  onRangeChange: (value: string) => void;
  onDateChange: (value: string, name: string) => void;
  disablePickers: boolean;
  search: string;
  setSearch: (value: string) => void;
}

const AppointmentsFilter: React.FC<AppointmentsFilterProps> = ({
  range,
  start,
  end,
  onRangeChange,
  onDateChange,
  disablePickers,
  search,
  setSearch,
}) => {
  const cls = useStyles();

  const handleRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onRangeChange(event.target.value);
  };

  const handleDateChange = (name: string) => (value: dayjs.Dayjs | null) => {
    if (value) {
      onDateChange(value.format(), name);
    }
  };

  return (
    <div className={cls.main}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              select
              fullWidth
              label='Date Range'
              placeholder='Select a date range...'
              variant='outlined'
              margin='none'
              value={range}
              onChange={handleRangeChange}
            >
              <MenuItem value='today'>Today</MenuItem>
              <MenuItem value='thisWeek'>This Week</MenuItem>
              <MenuItem value='nextWeek'>Next Week</MenuItem>
              <MenuItem value='thisMonth'>This Month</MenuItem>
              <MenuItem value='thisYear'>This Year</MenuItem>
              <MenuItem value='custom'>Custom</MenuItem>
            </TextField>
          </Grid>

          <Grid size={{ xs: 6, sm: 3 }}>
            <DatePicker
              disabled={disablePickers}
              label='Start Date'
              value={dayjs(start)}
              onChange={handleDateChange('start')}
              format='MM/DD/YYYY'
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: 'outlined',
                },
              }}
            />
          </Grid>

          <Grid size={{ xs: 6, sm: 3 }}>
            <DatePicker
              disabled={disablePickers}
              label='End Date'
              value={dayjs(end)}
              onChange={handleDateChange('end')}
              format='MM/DD/YYYY'
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: 'outlined',
                },
              }}
            />
          </Grid>

          <Grid size={{ xs: 12 }}>
            <TextField
              fullWidth
              label='Search'
              placeholder='Search appointments...'
              variant='outlined'
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
    </div>
  );
};

const useStyles = () => ({
  main: css`
    margin-bottom: 16px;
  `,
});

export default AppointmentsFilter;
