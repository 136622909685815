import dayjs from 'dayjs';
import { Moves } from '@gql/schema';

const getConcierge = () => {
  const localConcierge = localStorage.getItem(`dashboard-group-concierge`);
  if (localConcierge === `true`) return true;
  return false;
};
const getRooftop = () => {
  const localRooftop = localStorage.getItem(`dashboard-group-rooftop`);
  if (localRooftop === `true`) return true;
  return false;
};

const getDisable = (range: string | null) => {
  const localRange = range || localStorage.getItem(`dashboard-range`);
  if (localRange === `custom`) return false;
  return true;
};
const getRange = () => {
  const localRange = localStorage.getItem(`dashboard-range`);
  if (localRange) return localRange;
  return `3day`;
};

const getStart = (range: string | null) => {
  try {

    const minDate = dayjs().startOf(`day`).add(1, `day`).subtract(3, `month`).format();
    const defaultStart = dayjs().startOf(`day`).subtract(3, `day`).format();
    const localRange = range || localStorage.getItem(`dashboard-range`);
    const localStart = localStorage.getItem(`dashboard-start`) || defaultStart;
    if (localRange === `day`) return dayjs().startOf(`day`).format();
    if (localRange === `3day`) return dayjs().startOf(`day`).add(1, `day`).subtract(3, `day`).format();
    if (localRange === `week`) return dayjs().startOf(`day`).add(1, `day`).subtract(1, `week`).format();
    if (localRange === `2week`) return dayjs().startOf(`day`).add(1, `day`).subtract(2, `week`).format();
    if (localRange === `month`) return dayjs().startOf(`day`).add(1, `day`).subtract(1, `month`).format();
    if (localRange === `custom`) {
      if (localStart < minDate || localStart === 'Invalid Date') return minDate;
      return localStart;
    }
    return defaultStart;
  } catch (err) {
    console.error('error changing start', err);
    return dayjs().startOf(`day`).subtract(3, `day`).format();
  }

};
const getEnd = (range: string | null) => {
  try {
    const maxDate = dayjs().endOf(`day`).add(3, `month`).format();
    const defaultEnd = dayjs().endOf(`day`).format();
    const localRange = range || localStorage.getItem(`dashboard-range`);
    const localEnd = localStorage.getItem(`dashboard-end`) || defaultEnd;
    if (localRange === `custom`) {
      if (localEnd > maxDate || localEnd === 'Invalid Date') return maxDate;
      return localEnd;
    }
    return defaultEnd;
  } catch (err) {
    console.error('error changing end', err);
    return dayjs().endOf(`day`).format();
  }
};

interface FilteredMoves {
  activeMoves: Moves[];
  plannedMoves: Moves[];
  finishedMoves: Moves[];
  searchResults: Moves[];
}

const getFixedMoves = (
  movesData: Moves[] = [],
  sort: string | null = 'new',
  statusTags: string[] = [],
  makeTags: string[] = [],
  modelTags: string[] = [],
  yearTags: string[] = [],
  colorTags: string[] = [],
  customerNameTags: string[] = [],
  userNameTags: string[] = [],
  workflowsetTags: string[] = [],
  search: string | null = ''
): FilteredMoves => {
  let sortedMoves: Moves[] = [];

  if (sort === 'new') {
    sortedMoves = movesData.sort((a: Moves, b: Moves) => dayjs(b.pickup_time).unix() - dayjs(a.pickup_time).unix());
  } else if (sort === 'old') {
    sortedMoves = movesData.sort((a: Moves, b: Moves) => dayjs(a.pickup_time).unix() - dayjs(b.pickup_time).unix());
  } else if (sort === 'status') {
    sortedMoves = movesData.sort((a: Moves, b: Moves) => {
      const valA: string = a.cancel_status ? `aaa${a.cancel_status.toLowerCase()}` : a.status?.toLowerCase() ?? 'zzz';
      const valB: string = b.cancel_status ? `aaa${b.cancel_status.toLowerCase()}` : b.status?.toLowerCase() ?? 'zzz';
      return valA > valB ? 1 : valA < valB ? -1 : 0;
    });
  } else if (sort === 'make') {
    sortedMoves = movesData.sort((a: Moves, b: Moves) => {
      const valA = a.vehicle_make?.toLowerCase() ?? 'zzz';
      const valB = b.vehicle_make?.toLowerCase() ?? 'zzz';
      return valA > valB ? 1 : valA < valB ? -1 : 0;
    });
  } else if (sort === 'model') {
    sortedMoves = movesData.sort((a: Moves, b: Moves) => {
      const valA = a.vehicle_model?.toLowerCase() ?? 'zzz';
      const valB = b.vehicle_model?.toLowerCase() ?? 'zzz';
      return valA > valB ? 1 : valA < valB ? -1 : 0;
    });
  } else if (sort === 'year') {
    sortedMoves = movesData.sort((a: Moves, b: Moves) => {
      const valA = a.vehicle_year ?? '0';
      const valB = b.vehicle_year ?? '0';
      return valA < valB ? 1 : valA > valB ? -1 : 0;
    });
  } else if (sort === 'color') {
    sortedMoves = movesData.sort((a: Moves, b: Moves) => {
      const valA = a.vehicle_color?.toLowerCase() ?? 'zzz';
      const valB = b.vehicle_color?.toLowerCase() ?? 'zzz';
      return valA > valB ? 1 : valA < valB ? -1 : 0;
    });
  }

  let filterResults = sortedMoves;

  if (statusTags.length > 0) {
    filterResults = filterResults.filter(move => {
      if (move.cancel_status && statusTags.includes('Canceled')) return true;
      if (!move.cancel_status && (!move.status || move.status === 'dispatched') && statusTags.includes('Planning'))
        return true;
      if (!move.cancel_status && move.status?.includes('pickup') && statusTags.includes('Pickup')) return true;
      if (
        !move.cancel_status &&
        move.status?.includes('delivery') &&
        move.status !== 'delivery successful' &&
        statusTags.includes('Delivery')
      )
        return true;
      if (!move.cancel_status && move.status === 'delivery successful' && statusTags.includes('Completed'))
        return true;
      return false;
    });
  }

  if (makeTags.length > 0) {
    filterResults = filterResults.filter(move => makeTags.includes(move.vehicle_make ?? ''));
  }

  if (modelTags.length > 0) {
    filterResults = filterResults.filter(move => modelTags.includes(move.vehicle_model ?? ''));
  }

  if (yearTags.length > 0) {
    filterResults = filterResults.filter(move => yearTags.includes(move.vehicle_year ?? ''));
  }

  if (colorTags.length > 0) {
    filterResults = filterResults.filter(move => colorTags.includes(move.vehicle_color ?? ''));
  }

  if (customerNameTags.length > 0) {
    filterResults = filterResults.filter(move => customerNameTags.includes(move.customer?.name ?? ''));
  }

  if (workflowsetTags.length > 0) {
    filterResults = filterResults.filter(move => workflowsetTags.includes(move.workflowset?.name ?? ''));
  }

  if (userNameTags.length > 0) {
    filterResults = filterResults.filter(move => {
      const user = move.createdBy ?? move.eventlogs?.[0]?.user ?? null;
      return user && userNameTags.includes(user);
    });
  }

  let searchResults = filterResults;

  if (search) {
    searchResults = searchResults.filter(move => {
      return (
        (move.id && move.id.toString().includes(search)) ||
        (move.lane?.description?.toLowerCase().includes(search)) ||
        (move.reference_num?.toLowerCase().includes(search)) ||
        (move.vehicle_stock?.toLowerCase().includes(search)) ||
        (move.vehicle_vin?.toLowerCase().includes(search)) ||
        (move.vehicle_make?.toLowerCase().includes(search)) ||
        (move.vehicle_model?.toLowerCase().includes(search)) ||
        (move.vehicle_year?.toLowerCase().includes(search)) ||
        (move.vehicle_color?.toLowerCase().includes(search)) ||
        (move.consumer_name?.toLowerCase().includes(search)) ||
        (move.consumer_phone?.replace(/\D/g, '').includes(search))
      );
    });
  }

  const activeMoves = searchResults.filter(
    move =>
      move.status &&
      (move.status.includes('pickup') || move.status.includes('delivery')) &&
      move.status !== 'delivery successful' &&
      move.cancel_status === null
  );

  const plannedMoves = searchResults.filter(
    move => (move.status === null || move.status === 'dispatched') && move.cancel_status === null
  );

  const finishedMoves = searchResults.filter(
    move => move.status === 'delivery successful' || move.cancel_status !== null
  );

  return { activeMoves, plannedMoves, finishedMoves, searchResults };
};

const getMovesVariables = (customerId: number | 0, start: string | '', end: string | '') => {
  interface Variables {
    customerId?: number | 0;
    start: string | '';
    end: string | '';
    payerIdArray?: number[];
  }
  const variables: Variables = {
    start: '',
    end: '',
  };
  if (customerId) variables.customerId = customerId;
  variables.start = start;
  variables.end = end;
  // variables.payerIdArray = uniqueCustomersArray;
  return variables;
};

const getFilters = (currentUser: string | null | undefined, moves?: Moves[]) => {
  if (moves && moves.length > 0) {
    let statuses: (string | null | undefined)[] = [];
    let makes: (string | null | undefined)[] = [];
    let models: (string | null | undefined)[] = [];
    let years: (string | null | undefined)[] = [];
    let colors: (string | null | undefined)[] = [];
    let customerNames: (string | null | undefined)[] = [];
    let userNames: (string | null | undefined)[] = [];
    let workflowsets: (string | null | undefined)[] = [];
    statuses = [`Planning`, `Pickup`, `Delivery`, `Completed`, `Canceled`];

    makes = moves?.map(move => move.vehicle_make)
      .filter((make) => make !== null && make !== ``)
      .sort();
    makes = makes.filter((make, i) => makes.indexOf(make) === i);

    models = moves
      .map(move => move.vehicle_model)
      .filter((model) => model !== null && model !== ``)
      .sort();
    models = models.filter((model, i) => models.indexOf(model) === i);

    years = moves
      .map(move => move.vehicle_year)
      .filter((year) => year !== null && year !== ``)
      .sort();
    years = years.filter((year, i) => years.indexOf(year) === i);

    colors = moves
      .map(move => move.vehicle_color)
      .filter((color) => color !== null && color !== ``)
      .sort();
    colors = colors.filter((color, i) => colors.indexOf(color) === i);

    const moveCustomers = moves
      .filter(move => (move.customer && move.customer.name ? move.customer.name : false))
      .map(move => move?.customer?.name);
    //Sets can only contain unique values, so this will remove duplicates
    customerNames = [...new Set(moveCustomers)];

    const userSet: Set<string | null | undefined> = new Set();
    moves.forEach(move => {
      userSet.add(currentUser)
      if (move.createdBy) userSet.add(move.createdBy)
      if (move.eventlogs && move.eventlogs.length > 0) {
        userSet.add(move.eventlogs[0].user)
      }
    });

    userNames = Array.from(userSet).sort();

    workflowsets = moves
      .filter(move => (move.workflowset && move.workflowset.name ? move.workflowset.name : false))
      .map(move => move?.workflowset?.name);
    //Sets can only contain unique values, so this will remove duplicates
    workflowsets = [...new Set(workflowsets)];

    return { statuses, makes, models, years, colors, customerNames, userNames, workflowsets };
  } else return null;
};

const groupBy = (objectArray: any[], ...properties: any[]) => {
  return [
    ...Object.values(
      objectArray.reduce((accumulator, object) => {
        const key = JSON.stringify(properties.map(x => object[x] || null));

        if (!accumulator[key]) {
          accumulator[key] = [];
        }
        accumulator[key].push(object);
        return accumulator;
      }, {})
    ),
  ];
};

const groupByCustomerId = (movesArray: Moves[]) => {
  const groupArray: { id: any; name: string; moves: Moves[]; }[] = [];
  const customerArray = movesArray.map(move => move.customer);
  //Sets can only contain unique values, so this will remove duplicates
  const uniqueCustomerArray = [...new Set(customerArray)];
  //Create a new array of objects that contain the name, id, and moves for each customer
  uniqueCustomerArray.forEach(customer => {
    if (!customer || !customer.id || !customer.name) return;
    const customerMovesArray = movesArray.filter(move => move && move.customer && move.customer.id === customer.id);
    const customerObj = { id: customer.id, name: customer.name, moves: customerMovesArray };
    groupArray.push(customerObj);
  });
  return groupArray;
};

const getColor = (colorData: string) => {
  const listOfColors = [
    `black`,
    `blue`,
    `brown`,
    `burgundy`,
    `gold`,
    `gray`,
    `green`,
    `orange`,
    `pink`,
    `purple`,
    `red`,
    `silver`,
    `tan`,
    `white`,
    `yellow`,
  ];

  const color = colorData ? colorData.toLowerCase() : `default`;
  if (listOfColors.includes(color)) return color;
  else return `default`;
};

const findOtherCustomerMove = (customerId: number | undefined = 0, firstMoveCustomerId: number | 0) => {
  if (!customerId || !firstMoveCustomerId) return false;
  if (customerId !== firstMoveCustomerId) return true;
  return false;
};

const dashboardService = {
  getFixedMoves,
  getMovesVariables,
  getConcierge,
  getRooftop,
  getDisable,
  getRange,
  getStart,
  getEnd,
  getFilters,
  groupBy,
  groupByCustomerId,
  getColor,
  findOtherCustomerMove
};

export default dashboardService;
