import React from 'react';
import { useTheme, Grid2 as Grid, IconButton, Icon, Tooltip, Theme, SxProps, Button } from '@mui/material';
import { useUser } from '@store';
import { useUserDetails } from '@hooks/useUserDetails';
import { Users } from '@gql/schema';

//////////////////////// COMPONENT ////////////////////////

export default function UserToolbar({ user }: { user: Users }) {
  const theme = useTheme();
  const cls = useSxStyles(theme);
  const editorUser = useUser();

  const { isSaving, handleSave, editMode, setEditMode } = useUserDetails();

  const [canEdit, setCanEdit] = React.useState(false);

  React.useEffect(() => {
    const getEditPermissions = async () => {
      const editorRole = editorUser?.profile?.default_role;
      if (editorRole && editorRole === 'dealer-super-admin') {
        setCanEdit(true);
      } else if (editorRole && editorRole === 'dealer-admin') {
        if (user && user.default_role && (user.default_role === 'dealer-admin' || user.default_role === 'dealer')) {
          setCanEdit(true);
        }
      } else setCanEdit(false);
    };
    getEditPermissions();
  }, [user]);

  return (
    <Grid container alignItems='center'>
      <Grid size='grow' />

      {editMode ? (
        <Grid>
          <Tooltip placement='top' title={`Click to lock & save your changes`}>
            <Button
              loading={isSaving}
              disabled={isSaving}
              color='primary'
              onClick={() => handleSave()}
            >
              Save Changes
            </Button>
          </Tooltip>
        </Grid>
      ) : null}

      {canEdit ? (
        <Grid>
          <Tooltip
            placement='top'
            title={editMode ? `Click to lock & discard your changes` : `Click to unlock & edit the driver`}
          >
            <IconButton
              style={{
                color: editMode ? theme.palette.error.main : theme.palette.text.secondary,
              }}
              sx={cls.iconBtn}
              onClick={() => setEditMode(!editMode)}
            >
              <Icon>{editMode ? `lock_open` : `lock`}</Icon>
            </IconButton>
          </Tooltip>
        </Grid>
      ) : null}
    </Grid>
  );
}

//////////////////////// STYLES ////////////////////////

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  bigBtn: {
    marginRight: theme.spacing(1),
  },
  iconBtn: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(-2),
  },
});
