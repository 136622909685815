// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { toast } from 'react-toastify';

import { css } from '@emotion/css';
import { Theme, useTheme, Chip, InputAdornment, TextField } from '@mui/material';
import { BiMailSend } from 'react-icons/bi';
import { MovePlannerContext, MovePlannerContextType } from '../providers/MovePlannerProvider';

const maxEmailsLength = 5;

// COMPONENT ---------------------------------------------------------------- //

const MovePlannerEmailForm = ({ appointmentMode }: { appointmentMode?: boolean }) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const [input, setInput] = React.useState<string>(``);
  const { showEmailNotifications, validation, emails, setEmails } = React.useContext(
    MovePlannerContext
  ) as MovePlannerContextType;

  /** Check if a given email is valid */
  const isValidEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern?.test(email);
  };

  /** Handle adding an email to the emails array */
  const handleAddEmail = (value?: string) => {
    setInput(``);

    if (!value) return;

    if (!isValidEmail(value)) {
      toast.warning(`Email entered was invalid and could not be added!`, { toastId: `move-planner-emails` });
      return;
    }
    if (emails?.includes(value)) {
      toast.warning(`Email was already added!`, { toastId: `move-planner-emails` });
      return;
    }

    const newEmails = [...emails, value];

    if (newEmails?.length > maxEmailsLength) {
      toast.warning(`You can only add up to ${maxEmailsLength} emails!`, { toastId: `move-planner-emails` });
      return;
    }

    if (setEmails) setEmails(newEmails);
  };

  /** Handle blurring away from the input */
  const handleOnBlur = () => {
    handleAddEmail(input);
  };

  /** Handle pressing enter on the input */
  const handleOnKeyDown = (event?: React.KeyboardEvent) => {
    if (event?.key === `Enter`) {
      handleAddEmail(input);
    }
  };

  if (showEmailNotifications) {
    return (
      <div className={cls.emails}>
        <label className={cls.textFieldLabel} htmlFor='emails'>
          Emails To Notify{' '}
          <span className={cls.textFieldLength}>
            ({emails?.length || 0}/{maxEmailsLength})
          </span>
        </label>

        <TextField
          fullWidth
          error={!validation?.emails}
          name='emails'
          placeholder={
            appointmentMode
              ? 'Enter an email to receive a copy of the appointment offer, then press enter...'
              : 'Enter an email to notify on pickup and delivery, then press enter...'
          }
          helperText={!validation?.emails ? `Please do not enter more than 5 emails!` : ``}
          value={input}
          onChange={e => setInput(e?.target?.value || ``)}
          onBlur={() => handleOnBlur()}
          onKeyDown={e => handleOnKeyDown(e)}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position='start'>
                  <BiMailSend style={{ fontSize: 20 }} />
                </InputAdornment>
              ),
            },
          }}
        />

        {emails?.length ? (
          <div className={cls.flex}>
            {emails?.map((email, index) => (
              <Chip
                key={`emails-${index}`}
                label={email}
                onDelete={() => {
                  const newEmails = emails?.filter((_, i) => i !== index);
                  if (setEmails) setEmails(newEmails);
                }}
              />
            ))}
          </div>
        ) : null}
      </div>
    );
  }
  return null;
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  return {
    emails: css`
      position: relative;
      margin-bottom: 18px;
    `,

    textFieldLabel: css`
      display: block;
      margin-bottom: 2px;
      margin-left: 6px;
      font-size: 12px;
      font-weight: 600;
    `,
    textFieldLength: css`
      color: ${theme?.palette?.text?.secondary};
    `,
    textFieldLabelRequired: css`
      color: ${theme?.palette?.error?.main};
    `,

    flex: css`
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 6px;
      margin-top: 6px;
    `,
  };
};

// EXPORT ---------------------------------------------------------------- //

export default MovePlannerEmailForm;
