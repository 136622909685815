// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';
import dayjs from 'dayjs';

import { Arinvoices } from '@gql/schema';

import { getMonetaryValue } from '@services/formatService';
import { getTotalMileageFromMoves } from '@features/invoices/services/calcService';

import { css } from '@emotion/css';
import { Paper, Theme, Tooltip, Typography, useTheme } from '@mui/material';

import InfoItem from '@components/InfoItem';

// TYPES ---------------------------------------------------------------- //

type InvoiceDetailsInfoProps = {
  invoice?: Arinvoices;
  armoves?: any[];
};

// COMPONENT ---------------------------------------------------------------- //

const InvoiceDetailsInfo = ({ invoice, armoves }: InvoiceDetailsInfoProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const mileage = getTotalMileageFromMoves(armoves?.map(armove => armove.move));
  const prepaidAmount = invoice?.armoves
    ?.filter(arm => arm?.type === `prepaid`)
    ?.map(arm => arm?.due_amount)
    ?.reduce((total, current) => total + current, 0);

  return (
    <div className={cls.info}>
      <Paper variant='custom' sx={{ padding: `16px` }}>
        <div className={cls.titleFlex}>
          <div className={cls.titleFlexItem} style={{ textAlign: `left` }}>
            <Tooltip title='HopDrive Invoice ID' placement='top'>
              <Typography variant='h5' sx={{ lineHeight: 1, textTransform: `capitalize`, fontWeight: 600 }}>
                #{invoice?.id}
              </Typography>
            </Tooltip>
          </div>

          <div className={cls.titleFlexItem} style={{ textAlign: `center` }}>
            <Tooltip title='Market Name' placement='top'>
              <Typography variant='h5' sx={{ lineHeight: 1, textTransform: `capitalize`, fontWeight: 600 }}>
                {invoice?.customer?.name}
              </Typography>
            </Tooltip>
          </div>

          <div className={cls.titleFlexItem} style={{ textAlign: `right` }}>
            <Tooltip title='Status' placement='top'>
              <Typography variant='h5' sx={{ lineHeight: 1, textTransform: `capitalize`, fontWeight: 600 }}>
                {invoice?.status}
              </Typography>
            </Tooltip>
          </div>
        </div>

        <div className={cls.sectionFlex}>
          <div className={cls.sectionFlexItem}>
            <InfoItem keyText={`Prepaid Package`} valueText={getMonetaryValue(prepaidAmount, true) || `N/A`} />
            <InfoItem
              keyText={`Total Moves`}
              valueText={armoves?.length === 1 ? armoves?.length + ` Move` : armoves?.length + ` Moves` || `N/A`}
            />
            <InfoItem keyText={`Total Milage`} valueText={mileage ? `${Number(mileage).toFixed(1)} mi` : `N/A`} />
          </div>

          <div className={cls.sectionFlexItem}>
            <InfoItem
              keyText={`Billing Frequency`}
              valueText={invoice?.customer?.billing_frequency || `N/A`}
              valueTransform={`capitalize`}
            />
            <InfoItem
              keyText={`Auto Pay`}
              valueText={invoice?.customer?.auto_pay ? `Activated` : `Not Activated` || `N/A`}
            />
            <InfoItem
              keyText={`Notify`}
              valueText={invoice?.customer?.notify_billing ? `Activated` : `Not Activated` || `N/A`}
            />
          </div>

          <div className={cls.sectionFlexItem}>
            <InfoItem
              keyText={`Start Date`}
              valueText={
                invoice?.start_datetime ? dayjs.utc(dayjs(invoice?.start_datetime)).format(`MM/DD/YYYY`) : `N/A`
              }
            />
            <InfoItem
              keyText={`End Date`}
              valueText={invoice?.end_datetime ? dayjs.utc(dayjs(invoice?.end_datetime)).format(`MM/DD/YYYY`) : `N/A`}
            />
            <InfoItem
              keyText={`Due Date`}
              valueText={
                invoice?.end_datetime
                  ? dayjs
                      .utc(dayjs(invoice.end_datetime).add(invoice.customer.payment_terms, `day`))
                      .format(`MM/DD/YYYY`)
                  : `N/A`
              }
            />
          </div>
        </div>
      </Paper>
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  info: css`
    position: relative;
  `,

  titleFlex: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 16px;
  `,
  titleFlexItem: css`
    flex: 1;
    cursor: pointer;
  `,

  sectionFlex: css`
    display: flex;
    gap: 20px;
    ${theme?.breakpoints?.down('md')} {
      flex-direction: column;
      gap: 6px;
    }
  `,
  sectionFlexItem: css`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;
    word-break: break-word;
  `,

  flex: css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 6px;
  `,
  flexLine: css`
    flex: 1;
    border-top: 1px dashed ${theme?.palette?.action?.focus};
    margin: 9px 0;
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default InvoiceDetailsInfo;
