// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { useUser } from '@store';
import { checkForPowerRole } from '@features/auth/services/authService';

import { css } from '@emotion/css';
import { Theme, useTheme } from '@mui/material';

import { BiSolidMessageRoundedDetail } from 'react-icons/bi';
import { TfiMenuAlt } from 'react-icons/tfi';

import HeaderAvatar from '@features/layout/components/HeaderAvatar';
import HeaderIcon from '@features/layout/components/HeaderIcon';
import HeaderRooftopSelect from '@features/layout/components/HeaderRooftopSelect';
import Contacts from '@features/layout/components/Contacts';

// TYPES ---------------------------------------------------------------- //

interface HeaderProps {
  condensed: boolean;
  handleNavToggle: () => void;
}

// COMPONENT ---------------------------------------------------------------- //

const Header = ({ condensed, handleNavToggle }: HeaderProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const user = useUser();
  const hasAdminPrivileges = checkForPowerRole(user);

  const handleClickMenu = () => {
    handleNavToggle();
  };

  const [contactModalOpen, setContactModalOpen] = React.useState(false);

  // const handleClickSearch = () => {};

  return (
    <>
      <Contacts open={contactModalOpen} onClose={() => setContactModalOpen(!contactModalOpen)} />

      <header className={cls.header}>
        <div className={cls.flex}>
          <div className={cls.flexItem}>
            <HeaderIcon
              icon={<TfiMenuAlt />}
              iconSize={24}
              onClick={handleClickMenu}
              tooltip={condensed ? `Expand menu` : `Collapse menu`}
            />
          </div>

          {/* <div className={cls.flexSpacer} />

          <div className={cls.flexItem}>
            <HeaderIcon icon={<BiSearch />} iconSize={24} onClick={handleClickSearch} />
          </div> */}

          <div className={cls.flexItemStretch} />

          {user ? (
            <>
              {hasAdminPrivileges ? (
                <>
                  <div className={cls.flexSpacer} />

                  <HeaderRooftopSelect />
                </>
              ) : null}

              <div className={cls.flexSpacer} />

              <div className={cls.flexItem}>
                <HeaderIcon
                  icon={<BiSolidMessageRoundedDetail />}
                  iconSize={24}
                  onClick={() => setContactModalOpen(true)}
                  tooltip='Contact your Hopdrive team'
                />
              </div>

              <div className={cls.flexSpacer} />

              <HeaderAvatar onSupport={() => setContactModalOpen(true)} />
            </>
          ) : null}
        </div>
      </header>
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  const styles = {
    header: css`
      z-index: ${theme?.zIndex?.appBar};
      position: sticky;
      display: flex;
      align-items: center;
      justify-content: space-between;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 64px;
      max-height: 64px;
      padding: 8px 18px;
      border-bottom: 1px solid ${theme?.palette?.divider};
      background-color: ${theme?.palette?.nav?.header};
      box-shadow: 0px 0px 16px ${theme?.palette?.divider};
      backdrop-filter: blur(4px);
    `,

    flex: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    `,
    flexItem: css``,
    flexItemStretch: css`
      flex: 1;
      width: 100%;
    `,
    flexSpacer: css`
      width: 16px;
      height: 1px;
    `,

    icon: css`
      color: ${theme?.palette?.text?.secondary};
    `,

    title: css`
      display: flex;
    `,
    titleTxt: css`
      text-transform: capitalize;
      font-size: 20px !important;
      font-weight: 600 !important;
      color: ${theme?.palette?.text?.secondary};
    `,
    titleMainTxt: css`
      text-transform: capitalize;
      font-size: 20px !important;
      font-weight: 600 !important;
      color: ${theme?.palette?.text?.primary};
    `,
  };

  return styles;
};

// EXPORT ---------------------------------------------------------------- //

export default Header;
