// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { Grid2 as Grid, Theme, useTheme, Typography, Icon, SxProps } from '@mui/material';
import Divide from '@components/Divider';
import { Modal, ModalContent, ModalHeader } from '@components/Modal';
import { formatPhone } from '@services/formatService';
import { useGetAdminContactsLazyQuery, Dealer_Viewable_Admin_Info } from '@gql/schema';
import { useRooftop, useCustomerConfig } from '@store';

// TYPES ---------------------------------------------------------------- //

interface ContactsProps {
  open: boolean;
  onClose: () => void;
}

interface Contacts {
  marketManagerId: number;
  accountManagerId: number;
  salesRepId: number;
  arRepId: number;
}

interface SingleContactProps {
  user: Dealer_Viewable_Admin_Info;
}

// COMPONENT ---------------------------------------------------------------- //

const Contacts = ({ open, onClose }: ContactsProps) => {
  const theme = useTheme();
  const sxStyles = useSxStyles(theme);
  const rooftop = useRooftop();
  const customerConfig = useCustomerConfig();
  const [getAdminContacts, { loading, error }] = useGetAdminContactsLazyQuery();

  const [marketManager, setMarketManager] = React.useState<Dealer_Viewable_Admin_Info>();
  const [accountManager, setAccountManager] = React.useState<Dealer_Viewable_Admin_Info>();
  const [salesRep, setSalesRep] = React.useState<Dealer_Viewable_Admin_Info>();
  const [arRep, setArRep] = React.useState<Dealer_Viewable_Admin_Info>();

  const fetchAdminContacts = async (users: Contacts) => {
    const userIds = Object.values(users).filter(id => id !== 0) as number[];
    const { data } = await getAdminContacts({ variables: { userIds } });
    const contacts = data?.dealer_viewable_admin_info as Dealer_Viewable_Admin_Info[];

    const mm = contacts?.find(c => c.user_id === users.marketManagerId);
    const am = contacts?.find(c => c.user_id === users.accountManagerId);
    const sr = contacts?.find(c => c.user_id === users.salesRepId);
    const ar = contacts?.find(c => c.user_id === users.arRepId);

    setMarketManager(mm);
    setAccountManager(am);
    setSalesRep(sr);
    setArRep(ar);
  };

  React.useEffect(() => {
    if (!rooftop || !customerConfig) return;
    const mm = customerConfig?.hopdrive_contacts?.market_manager;
    const am = customerConfig?.hopdrive_contacts?.account_manager;
    const sr = customerConfig?.hopdrive_contacts?.sales_rep;
    const ar = customerConfig?.hopdrive_contacts?.ar_rep;

    const contacts = {
      marketManagerId: mm ?? 0,
      accountManagerId: am ?? 0,
      salesRepId: sr ?? 0,
      arRepId: ar ?? 0,
    };

    fetchAdminContacts(contacts);
  }, [rooftop, customerConfig]);

  const DefaultContact = () => {
    return (
      <Grid container sx={{ textAlign: 'left' }}>
        <Grid size={{ xs: 6 }}>
          <Typography sx={{ fontWeight: 400, fontSize: '18px' }}>Urgent Move Issues</Typography>
        </Grid>
        <Grid size={{ xs: 1 }}>
          <Icon sx={{ color: '#A0A0A0' }}>email</Icon>
        </Grid>
        <Grid size={{ xs: 5 }}>
          <Typography sx={sxStyles.link}>
            <a href={`mailto:dispatchers@hopdrive.com`}>dispatchers@hopdrive.com</a>
          </Typography>
        </Grid>
        <Grid size={{ xs: 6 }} />
        <Grid size={{ xs: 1 }}>
          <Icon sx={{ color: '#A0A0A0' }}>phone</Icon>
        </Grid>
        <Grid size={{ xs: 5 }}>
          <Typography sx={sxStyles.link}>
            <a href={`tel:8009137674`}>(800) 913-7674</a>
          </Typography>
        </Grid>
        <Grid size={{ xs: 6 }} />
        <Grid size={{ xs: 1 }}>
          <Icon sx={{ color: '#A0A0A0' }}>chat</Icon>
        </Grid>
        <Grid size={{ xs: 5 }}>
          <Typography sx={sxStyles.link}>
            <a href={`sms:8049448548`}>(804) 944-8548</a>
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const MarketManagerContact: React.FC<SingleContactProps> = ({ user }) => {
    return (
      <Grid container sx={{ textAlign: 'left' }}>
        <Grid size={{ xs: 6 }}>
          <Typography sx={{ fontWeight: 400, fontSize: '18px' }}>Non-Urgent Operational Questions</Typography>
        </Grid>
        {user.display_name && (
          <>
            <Grid size={{ xs: 1 }}>
              <Icon sx={{ color: '#A0A0A0' }}>person</Icon>
            </Grid>
            <Grid size={{ xs: 5 }}>
              <Typography>{user.display_name}, Market Manager</Typography>
            </Grid>
          </>
        )}

        {user.email && (
          <>
            <Grid size={{ xs: 6 }} />
            <Grid size={{ xs: 1 }}>
              <Icon sx={{ color: '#A0A0A0' }}>email</Icon>
            </Grid>
            <Grid size={{ xs: 5 }}>
              <Typography sx={sxStyles.link}>
                <a href={`mailto:${user.email}`}>{user.email}</a>
              </Typography>
            </Grid>
          </>
        )}

        {user.phone && (
          <>
            <Grid size={{ xs: 6 }} />
            <Grid size={{ xs: 1 }}>
              <Icon sx={{ color: '#A0A0A0' }}>phone</Icon>
            </Grid>
            <Grid size={{ xs: 5 }}>
              <Typography sx={sxStyles.link}>
                <a href={`tel:${user.phone}`}>{formatPhone(user.phone)}</a>
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  const AccountManagerContact: React.FC<SingleContactProps> = ({ user }) => {
    return (
      <Grid container sx={{ textAlign: 'left' }}>
        <Grid size={{ xs: 6 }}>
          <Typography sx={{ fontWeight: 400, fontSize: '18px' }}>Partnership Questions</Typography>
        </Grid>

        {user.display_name && (
          <>
            <Grid size={{ xs: 1 }}>
              <Icon sx={{ color: '#A0A0A0' }}>person</Icon>
            </Grid>
            <Grid size={{ xs: 5 }}>
              <Typography>{user.display_name}, Account Manager</Typography>
            </Grid>
          </>
        )}

        {user.email && (
          <>
            <Grid size={{ xs: 6 }} />
            <Grid size={{ xs: 1 }}>
              <Icon sx={{ color: '#A0A0A0' }}>email</Icon>
            </Grid>
            <Grid size={{ xs: 5 }}>
              <Typography sx={sxStyles.link}>
                <a href={`mailto:${user.email}`}>{user.email}</a>
              </Typography>
            </Grid>
          </>
        )}

        {user.phone && (
          <>
            <Grid size={{ xs: 6 }} />
            <Grid size={{ xs: 1 }}>
              <Icon sx={{ color: '#A0A0A0' }}>phone</Icon>
            </Grid>
            <Grid size={{ xs: 5 }}>
              <Typography sx={sxStyles.link}>
                <a href={`tel:${user.phone}`}>{formatPhone(user.phone)}</a>
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  const SalesRepContact: React.FC<SingleContactProps> = ({ user }) => {
    return (
      <Grid container sx={{ textAlign: 'left' }}>
        <Grid size={{ xs: 6 }}>
          <Typography sx={{ fontWeight: 400, fontSize: '18px' }}>Sales Questions</Typography>
        </Grid>
        {user.display_name && (
          <>
            <Grid size={{ xs: 1 }}>
              <Icon sx={{ color: '#A0A0A0' }}>person</Icon>
            </Grid>
            <Grid size={{ xs: 5 }}>
              <Typography>{user.display_name}, Sales Representative</Typography>
            </Grid>
          </>
        )}

        {user.email && (
          <>
            <Grid size={{ xs: 6 }} />
            <Grid size={{ xs: 1 }}>
              <Icon sx={{ color: '#A0A0A0' }}>email</Icon>
            </Grid>
            <Grid size={{ xs: 5 }}>
              <Typography sx={sxStyles.link}>
                <a href={`mailto:${user.email}`}>{user.email}</a>
              </Typography>
            </Grid>
          </>
        )}

        {user.phone && (
          <>
            <Grid size={{ xs: 6 }} />
            <Grid size={{ xs: 1 }}>
              <Icon sx={{ color: '#A0A0A0' }}>phone</Icon>
            </Grid>
            <Grid size={{ xs: 5 }}>
              <Typography sx={sxStyles.link}>
                <a href={`tel:${user.phone}`}>{formatPhone(user.phone)}</a>
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  const ARRepContact: React.FC<SingleContactProps> = ({ user }) => {
    return (
      <Grid container sx={{ textAlign: 'left' }}>
        <Grid size={{ xs: 6 }}>
          <Typography sx={{ fontWeight: 400, fontSize: '18px' }}>Accounting Questions</Typography>
        </Grid>
        {user.display_name && (
          <>
            <Grid size={{ xs: 1 }}>
              <Icon sx={{ color: '#A0A0A0' }}>person</Icon>
            </Grid>
            <Grid size={{ xs: 5 }}>
              <Typography>{user.display_name}, Accounts Receivable Representative</Typography>
            </Grid>
          </>
        )}

        {user.email && (
          <>
            <Grid size={{ xs: 6 }} />
            <Grid size={{ xs: 1 }}>
              <Icon sx={{ color: '#A0A0A0' }}>email</Icon>
            </Grid>
            <Grid size={{ xs: 5 }}>
              <Typography sx={sxStyles.link}>
                <a href={`mailto:${user.email}`}>{user.email}</a>
              </Typography>
            </Grid>
          </>
        )}

        {user.phone && (
          <>
            <Grid size={{ xs: 6 }} />
            <Grid size={{ xs: 1 }}>
              <Icon sx={{ color: '#A0A0A0' }}>phone</Icon>
            </Grid>
            <Grid size={{ xs: 5 }}>
              <Typography sx={sxStyles.link}>
                <a href={`tel:${user.phone}`}>{formatPhone(user.phone)}</a>
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  if (loading || error)
    return (
      <Modal className='' open={open} width={'md'}>
        <ModalHeader handleClose={onClose}>Contact Your Hopdrive Team</ModalHeader>
        <ModalContent>
          <DefaultContact />
        </ModalContent>
      </Modal>
    );

  return (
    <Modal className='' open={open} width={'md'}>
      <ModalHeader handleClose={onClose}>Contact Your Hopdrive Team</ModalHeader>
      <Grid container>
        <ModalContent>
          <DefaultContact />

          {marketManager && (
            <div>
              <Divide />
              <Grid size={{ xs: 12 }} sx={sxStyles.bigBreak} />
              <MarketManagerContact user={marketManager} />
              <Grid size={{ xs: 12 }} sx={sxStyles.bigBreak} />
            </div>
          )}

          {accountManager && (
            <div>
              <Divide />
              <Grid size={{ xs: 12 }} sx={sxStyles.bigBreak} />
              <AccountManagerContact user={accountManager} />
              <Grid size={{ xs: 12 }} sx={sxStyles.bigBreak} />
            </div>
          )}

          {salesRep && (
            <div>
              <Divide />
              <Grid size={{ xs: 12 }} sx={sxStyles.bigBreak} />
              <SalesRepContact user={salesRep} />
              <Grid size={{ xs: 12 }} sx={sxStyles.bigBreak} />
            </div>
          )}

          {arRep && (
            <div>
              <Divide />
              <Grid size={{ xs: 12 }} sx={sxStyles.bigBreak} />
              <ARRepContact user={arRep} />
              <Grid size={{ xs: 12 }} sx={sxStyles.bigBreak} />
            </div>
          )}
        </ModalContent>
      </Grid>
    </Modal>
  );
};

// STYLES ---------------------------------------------------------------- //

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  break: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(2.5),
  },
  link: {
    color: `#f44232 !important`,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

// EXPORT ---------------------------------------------------------------- //

export default Contacts;
