import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { css } from '@emotion/css';
import { Container, Paper } from '@mui/material';

import FirebaseLogin from '@features/auth/components/FirebaseLogin';
import { authService } from '@features/auth/services/authService';
import { FirebaseError } from 'firebase/app';
import { useUser } from '@store';
import { toast } from 'react-toastify';

const LoginPage: React.FC = () => {
  const user = useUser();
  const styles = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  const from = (location.state as { from: { pathname: string } })?.from?.pathname || '/dashboard';

  React.useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [user]);

  const handleLogin = async (email: string, password: string) => {
    try {
      await authService.login(email, password);

      try {
        await authService.writeUserEvent('user.login');
      } catch (err) {
        console.log('Failed to write user event', err);
      }

      navigate(from, { replace: true });
    } catch (err: unknown) {
      if (err instanceof FirebaseError) {
        if (err.code?.includes('password')) {
          toast.error('Incorrect password');
        } else if (err.code?.includes('user-not-found')) {
          toast.error('User not found by that email');
        } else if (err.code?.includes('invalid-email')) {
          toast.error('Please enter a valid email address');
        } else {
          toast.error('An error occurred while attempting to sign in.');
        }
      }
    }
  };

  return (
    <div className={styles.container}>
      <Container maxWidth='sm' sx={{ paddingTop: `24px`, paddingBottom: `24px` }}>
        <Paper variant='custom' sx={{ position: `relative`, overflow: `hidden`, padding: `36px 24px` }}>
          {!user && (
            <div className={styles.login}>
              <FirebaseLogin onLogin={handleLogin} />
            </div>
          )}
        </Paper>
      </Container>
    </div>
  );
};

const useStyles = () => {
  const styles = {
    container: css`
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom right, #e8f4ff, #ffe8e8);
    `,
    login: css`
      min-width: 320px;
    `,
  };

  return styles;
};

export default LoginPage;
