// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { css } from '@emotion/css';
import { Theme, useTheme } from '@mui/material';
import WorkflowsetSelect from '@components/Selectors/WorkflowsetSelect';

import { MovePlannerContext, MovePlannerContextType } from '@features/moveCreation/providers/MovePlannerProvider';
import { defaultConciergeSla } from '@features/moveCreation/services/movePlannerService';

// COMPONENT ---------------------------------------------------------------- //

const MovePlannerWorkflowsetForm = () => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const { validation, rooftopId, workflowsets, workflowsetId, type, setWorkflowsetId } = React.useContext(
    MovePlannerContext
  ) as MovePlannerContextType;

  return (
    <div className={cls.workflowset}>
      <WorkflowsetSelect
        rooftopId={rooftopId}
        slaOverride={type?.includes(`concierge`) ? defaultConciergeSla : undefined}
        required
        disabled={!workflowsets?.length || workflowsets?.length <= 1}
        error={!validation?.workflowsetId}
        label='Select a Workflow'
        helperText={!validation?.workflowsetId ? `Please select a custom workflow for the move!` : ``}
        defaultWorkflowsets={workflowsets}
        value={workflowsetId}
        onChange={setWorkflowsetId}
        queryCall='never'
      />
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  return {
    workflowset: css`
      position: relative;
      margin-bottom: 18px;
    `,
  };
};

// EXPORT ---------------------------------------------------------------- //

export default MovePlannerWorkflowsetForm;
