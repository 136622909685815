// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { Customers, useGetRooftopsLazyQuery } from '@gql/schema';

import { css, cx } from '@emotion/css';
import { Theme, useTheme, Autocomplete, InputAdornment, TextField } from '@mui/material';

import { BiStore } from 'react-icons/bi';

// TYPES ---------------------------------------------------------------- //

interface RooftopSelectProps {
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  name?: string;
  label?: string;
  placeholder?: string;
  helperText?: string;
  defaultRooftops?: Customers[];
  value?: number;
  onChange?: (id: number) => void;
  onFetch?: (rooftops?: Customers[]) => void;
  queryOverride?: () => Promise<Customers[]>;
  queryCall?: `never` | `onMount` | `onOpen`;

  [x: string]: any;
}

// COMPONENT ---------------------------------------------------------------- //

const RooftopSelect = ({
  required,
  disabled,
  error,
  name = `rooftop-select`,
  label = ``,
  placeholder = `Select market...`,
  helperText = ``,
  defaultRooftops = [],
  value = 0,
  onChange,
  onFetch,
  queryOverride,
  queryCall = `onMount`,
  ...rest
}: RooftopSelectProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const [getRooftops] = useGetRooftopsLazyQuery();

  const [rooftops, setRooftops] = React.useState<Customers[]>(defaultRooftops || []);
  const [rooftopId, setRooftopId] = React.useState<number>(value || 0);

  const [internalError, setInternalError] = React.useState<boolean>(false);
  const [internalErrorText, setInternalErrorText] = React.useState<string>(``);

  const [loading, setLoading] = React.useState<boolean>(false);

  /** Fetch query and set state */
  const fetchRooftopsAndSetState = async () => {
    setLoading(true);

    try {
      let res;
      if (queryOverride) res = await queryOverride();
      else res = await getRooftops();

      const resRooftops = res?.data?.customers || [];
      const defaultRooftopId = value || 0;

      setRooftops(resRooftops);
      setRooftopId(defaultRooftopId);

      if (onFetch) onFetch(resRooftops);

      setInternalError(false);
      setInternalErrorText(``);
    } catch (err) {
      console.error(`Failed to fetch markets:`, err);
      setInternalError(true);
      setInternalErrorText(`Failed to fetch markets`);
    }

    setLoading(false);
  };

  /** Handle changing the value */
  const handleValueChange = (value?: Customers) => {
    const selectedRooftopId = value?.id || 0;
    setRooftopId(selectedRooftopId);
    if (onChange) onChange(selectedRooftopId);
  };

  /** Handle opening the dropdown */
  const handleOpen = async () => {
    if (queryCall === `onOpen`) fetchRooftopsAndSetState();
  };

  // Fetch on mount instead of on open
  React.useEffect(() => {
    if (queryCall === `onMount`) fetchRooftopsAndSetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set the data when it comes in as a prop (only when there is a query override)
  React.useEffect(() => {
    setRooftops(defaultRooftops);
  }, [defaultRooftops]);

  // Set the value when it comes in as a prop
  React.useEffect(() => {
    setRooftopId(value);
  }, [value]);

  return (
    <>
      {label ? (
        <label className={cls.rooftopLabel} htmlFor={name}>
          {label}
          {required ? <span className={cls.rooftopLabelRequired}> *</span> : null}
        </label>
      ) : null}

      <Autocomplete
        {...rest}
        fullWidth
        disabled={disabled}
        loading={loading}
        loadingText={`Loading markets...`}
        noOptionsText={`No markets found`}
        options={rooftops}
        getOptionKey={r => r?.id || 0}
        getOptionLabel={r => r?.name || ``}
        value={rooftops?.find(r => r?.id === rooftopId) || null}
        onChange={(_, value) => handleValueChange(value)}
        onOpen={() => handleOpen()}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            required={required}
            error={internalError || error}
            name={name}
            placeholder={placeholder}
            helperText={internalErrorText || helperText}
            slotProps={{
              input: {
                ...params?.InputProps,
                startAdornment: (
                  <InputAdornment position='start' sx={{ marginLeft: `8px`, marginRight: 0 }}>
                    <BiStore className={cx(cls.rooftopIcon, disabled ? cls.rooftopIconDisabled : null)} />
                  </InputAdornment>
                ),
              },
            }}
          />
        )}
      />
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  const styles = {
    rooftopLabel: css`
      display: block;
      margin-bottom: 2px;
      margin-left: 6px;
      font-size: 12px;
      font-weight: 600;
    `,
    rooftopLabelRequired: css`
      color: ${theme?.palette?.error?.main};
    `,
    rooftopIcon: css`
      display: block;
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      color: ${theme?.palette?.action?.active};
    `,
    rooftopIconDisabled: css`
      color: ${theme?.palette?.action?.disabled};
    `,
  };

  return styles;
};

// EXPORT ---------------------------------------------------------------- //

export default RooftopSelect;
