// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';

import { Moves } from '@gql/schema';

import { css, keyframes } from '@emotion/css';
import { Theme, useTheme, Typography } from '@mui/material';

const getSizePadding = (size: `sm` | `md` | `lg`) => {
  switch (size) {
    case `sm`:
      return `10px`;
    case `md`:
      return `12px`;
    case `lg`:
      return `14px`;
  }
};

const getSizeFont = (size: `sm` | `md` | `lg`) => {
  switch (size) {
    case `sm`:
      return `12px`;
    case `md`:
      return `16px`;
    case `lg`:
      return `20px`;
  }
};

const getSizeGap = (size: `sm` | `md` | `lg`) => {
  switch (size) {
    case `sm`:
      return `24px`;
    case `md`:
      return `30px`;
    case `lg`:
      return `36px`;
  }
};

const getSizeContentWidth = (size: `sm` | `md` | `lg`) => {
  switch (size) {
    case `sm`:
      return `16px`;
    case `md`:
      return `20px`;
    case `lg`:
      return `24px`;
  }
};

const getSizeContentOffset = (size: `sm` | `md` | `lg`) => {
  switch (size) {
    case `sm`:
      return `-8px`;
    case `md`:
      return `-10px`;
    case `lg`:
      return `-12px`;
  }
};

const statuses = [
  null,
  `dispatched`,
  `pickup started`,
  `pickup arrived`,
  `pickup successful`,
  `delivery started`,
  `delivery arrived`,
  `delivery successful`,
];

const getStatusColor = (move?: Moves) => {
  if (move?.consumer_type) return `secondary`;
  return `primary`;
};

// TYPES ---------------------------------------------------------------- //

interface MoveDetailsStatusProps {
  move?: Moves;
  size?: `sm` | `md` | `lg`;
}

// COMPONENT ---------------------------------------------------------------- //

const MoveDetailsStatus = ({ move, size = `md` }: MoveDetailsStatusProps) => {
  const statusColor = getStatusColor(move);
  const statusIndex = move?.status ? statuses.indexOf(move?.status) : 0;

  const theme = useTheme();
  const cls = useStyles(theme, { size, statusColor, statusIndex });

  if (!move) return null;
  return (
    <div className={cls.moveStatus}>
      <div className={cls.plan}>
        <Typography sx={{ marginRight: `-8px`, lineHeight: 1, fontSize: getSizeFont(size), fontWeight: 600 }}>
          Plan
        </Typography>
      </div>

      <div className={cls.pickup}>
        <Typography sx={{ lineHeight: 1, fontSize: getSizeFont(size), fontWeight: 600 }}>Pickup</Typography>
      </div>

      <div className={cls.delivery}>
        <Typography sx={{ marginLeft: `-8px`, lineHeight: 1, fontSize: getSizeFont(size), fontWeight: 600 }}>
          Delivery
        </Typography>
      </div>
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme, props?: any) => {
  const pulse = keyframes`
    0% {
      background-color: ${theme?.palette?.[props?.statusColor]?.main};
    }
    50% {
      background-color: ${theme?.palette?.[props?.statusColor]?.lighter};
    }
    100% {
      background-color: ${theme?.palette?.[props?.statusColor]?.main};
    }
  `;

  return {
    moveStatus: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: ${getSizeGap(props?.size)};
    `,
    plan: css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding: ${getSizePadding(props?.size)};
      border-radius: 64px 0px 0px 64px;
      color: ${theme?.palette?.text?.contrastPrimary};
      background-color: ${props?.statusIndex >= 0
        ? theme?.palette?.[props?.statusColor]?.main
        : theme?.palette?.grey?.[200]};
      animation: ${props?.statusIndex === 0 ? `${pulse} 1s infinite` : `none`};
      ::after {
        content: '';
        position: absolute;
        top: 0px;
        right: ${getSizeContentOffset(props?.size)};
        width: ${getSizeContentWidth(props?.size)};
        height: 100%;
        transform: skewX(-24deg);
        background-color: ${props?.statusIndex >= 0
          ? theme?.palette?.[props?.statusColor]?.main
          : theme?.palette?.grey?.[200]};
        animation: ${props?.statusIndex === 0 ? `${pulse} 1s infinite` : `none`};
      }
    `,
    pickup: css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 2.25;
      padding: ${getSizePadding(props?.size)};
      color: ${theme?.palette?.text?.contrastPrimary};
      background-color: ${props?.statusIndex >= 2
        ? theme?.palette?.[props?.statusColor]?.main
        : theme?.palette?.grey?.[200]};
      animation: ${props?.statusIndex >= 2 && props?.statusIndex <= 3 ? `${pulse} 1s infinite` : `none`};
      ::before {
        content: '';
        position: absolute;
        top: 0px;
        left: ${getSizeContentOffset(props?.size)};
        width: ${getSizeContentWidth(props?.size)};
        height: 100%;
        transform: skewX(-24deg);
        background-color: ${props?.statusIndex >= 2
          ? theme?.palette?.[props?.statusColor]?.main
          : theme?.palette?.grey?.[200]};
        animation: ${props?.statusIndex >= 2 && props?.statusIndex <= 3 ? `${pulse} 1s infinite` : `none`};
      }
      ::after {
        content: '';
        position: absolute;
        top: 0px;
        right: ${getSizeContentOffset(props?.size)};
        width: ${getSizeContentWidth(props?.size)};
        height: 100%;
        transform: skewX(-24deg);
        background-color: ${props?.statusIndex >= 2
          ? theme?.palette?.[props?.statusColor]?.main
          : theme?.palette?.grey?.[200]};
        animation: ${props?.statusIndex >= 2 && props?.statusIndex <= 3 ? `${pulse} 1s infinite` : `none`};
      }
    `,
    delivery: css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 2.75;
      padding: ${getSizePadding(props?.size)};
      border-radius: 0px 64px 64px 0px;
      color: ${theme?.palette?.text?.contrastPrimary};
      background-color: ${props?.statusIndex >= 5
        ? theme?.palette?.[props?.statusColor]?.main
        : theme?.palette?.grey?.[200]};
      animation: ${props?.statusIndex >= 5 && props?.statusIndex <= 6 ? `${pulse} 1s infinite` : `none`};
      ::after {
        content: '';
        position: absolute;
        top: 0px;
        left: ${getSizeContentOffset(props?.size)};
        width: ${getSizeContentWidth(props?.size)};
        height: 100%;
        transform: skewX(-24deg);
        background-color: ${props?.statusIndex >= 5
          ? theme?.palette?.[props?.statusColor]?.main
          : theme?.palette?.grey?.[200]};
        animation: ${props?.statusIndex >= 5 && props?.statusIndex <= 6 ? `${pulse} 1s infinite` : `none`};
      }
    `,
  };
};

// EXPORT ---------------------------------------------------------------- //

export default MoveDetailsStatus;
