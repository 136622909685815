import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@store';
import { getCleansedPhoneNumber } from '@services/formatService';
import { enrichCustomerConfig } from '@utils/configsService';
import { MovePlannerProvider } from '@features/moveCreation/providers/MovePlannerProvider';
import {
  Box,
  Button,
  Chip,
  Container,
  Grid2 as Grid,
  Icon,
  IconButton,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import { BiErrorCircle } from 'react-icons/bi';
import AppointmentAddForm from './AppointmentAddForm.tsx';
import AppointmentCancelModal from './AppointmentCancelModal';
import AppointmentStatusTracker from './AppointmentStatusTracker';
import { toast } from 'react-toastify';
import { useUpdateAppointmentMutation, Appointments } from '@gql/schema';
import dayjs from 'dayjs';

const AppointmentDetailsContent = (appointment: Appointments) => {
  const navigate = useNavigate();
  const user = useUser();
  const theme = useTheme();
  const sx = useSxStyles(theme);

  const [editMode, setEditMode] = React.useState(false);
  const [expired, setExpired] = React.useState(false);
  const [cancelModalOpen, setCancelModalOpen] = React.useState(false);
  const [refNumLabel, setRefNumLabel] = React.useState('Reference #');

  const [updateAppointmentMutation] = useUpdateAppointmentMutation();

  const getRefNumLabel = async () => {
    const token = await user?.getToken();
    if (!token) return;
    const enrichedConfig = await enrichCustomerConfig(
      token,
      appointment?.customer?.config,
      appointment?.customer?.organization?.config
    );
    if (enrichedConfig?.appointments?.ref_num_label) {
      setRefNumLabel(enrichedConfig?.appointments?.ref_num_label);
    } else {
      setRefNumLabel('Reference #');
    }
  };

  const handleCancelModalOpen = () => {
    setCancelModalOpen(true);
  };

  const handleCancelModalClose = () => {
    setCancelModalOpen(false);
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const checkExpiration = (apptTime: string) => {
    const currentTime = dayjs();
    const cutoff = dayjs(apptTime).subtract(90, 'minutes');

    if (currentTime.isAfter(cutoff)) {
      return false;
    }

    const start = dayjs(apptTime).startOf('minute').add(90, 'minutes');
    return currentTime.isBefore(start);
  };

  const getPaymentDeadline = () => {
    if (appointment.appointment_time) {
      return dayjs(appointment.appointment_time).subtract(90, 'minutes').format('MM/DD/YYYY, h:mm A');
    }
    return 'N/A';
  };

  const getFormattedVehicleFromAppointment = (appointment: Appointments) => {
    const { vehicle_year, vehicle_make, vehicle_model, vehicle_color } = appointment;
    if (vehicle_year && vehicle_make && vehicle_model) {
      return `${vehicle_color ? vehicle_color : ''} ${vehicle_year} ${vehicle_make} ${vehicle_model}`;
    }
    return 'N/A';
  };

  const getPaymentStatusFromAppointment = (appointment: Appointments) => {
    if (appointment.status === 'paying' || appointment.status === 'ready') {
      return (
        <Box sx={sx.chipContainer}>
          <Chip label='Pending' color='default' size='small' variant='outlined' />
        </Box>
      );
    } else if (appointment.status === 'paid') {
      return (
        <Box sx={sx.chipContainer}>
          <Chip label='Paid' color='secondary' size='small' variant='outlined' />
        </Box>
      );
    } else if (appointment.status === 'failed') {
      return (
        <Box sx={sx.chipContainer}>
          <Chip label='Failed' color='primary' size='small' variant='outlined' />
        </Box>
      );
    } else if (appointment.status === 'refunded') {
      return (
        <Box sx={sx.chipContainer}>
          <Chip label='Refunded' color='default' size='small' variant='outlined' />
        </Box>
      );
    } else return <Typography sx={sx.valTxt}>-</Typography>;
  };

  const handleResendOffer = async () => {
    //update appointment offered_at to now and offered_by to current user. This will trigger an event to resend the SMS
    const res = await updateAppointmentMutation({
      variables: {
        id: appointment.id,
        changes: {
          offered_by: user?.profile?.email,
          offered_at: dayjs().format(),
        },
      },
    });
    if (res?.data?.update_appointments_by_pk ?? null) {
      toast.success('Offer SMS reinitated successfully!');
    } else {
      toast.error('Failed to reinitiate offer SMS!');
    }
  };

  React.useEffect(() => {
    if (appointment.appointment_time) {
      const validTime = checkExpiration(appointment.appointment_time);
      if (!validTime) {
        setExpired(true);
      } else {
        setExpired(false);
      }
    }
    getRefNumLabel();
  }, [appointment]);

  if (editMode) {
    return (
      <MovePlannerProvider state={{ appointment }}>
        <Box sx={sx.root}>
          <Container maxWidth='lg'>
            <Box sx={sx.break_lg} />
            <Grid container spacing={1} alignItems='center'>
              <Grid>
                <IconButton sx={sx.iconBtn} onClick={() => navigate('/appointments')}>
                  <Icon>arrow_back</Icon>
                </IconButton>
              </Grid>
              <Grid size='grow'>
                <Typography sx={sx.head}>Update Appointment</Typography>
              </Grid>
              {appointment.status === 'ready' || appointment.status === 'failed' ? (
                <Grid>
                  <Tooltip
                    placement='top'
                    title={editMode ? `Click to lock & discard your changes` : `Click to unlock & edit the move`}
                  >
                    <IconButton sx={sx.iconBtn} onClick={() => handleEditMode()}>
                      <Icon>{editMode ? `lock_open` : `lock`}</Icon>
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : null}
            </Grid>
            <AppointmentAddForm appointment={appointment} editMode={editMode} setEditMode={setEditMode} />;
          </Container>
        </Box>
      </MovePlannerProvider>
    );
  }

  return (
    <>
      <AppointmentCancelModal open={cancelModalOpen} onClose={handleCancelModalClose} appointment={appointment} />
      <Box sx={sx.root}>
        <Container maxWidth='lg'>
          <Box sx={sx.break_lg} />
          <Grid container spacing={1} alignItems='center'>
            <Grid>
              <IconButton sx={sx.iconBtn} onClick={() => navigate('/appointments')}>
                <Icon>arrow_back</Icon>
              </IconButton>
            </Grid>
            <Grid size='grow'>
              <Typography sx={sx.head}>Appointment Details</Typography>
            </Grid>
            {appointment.status === 'ready' || appointment.status === 'failed' ? (
              <Grid>
                <Tooltip
                  placement='top'
                  title={editMode ? `Click to lock & discard your changes` : `Click to unlock & edit the move`}
                >
                  <IconButton sx={sx.iconBtn} onClick={() => handleEditMode()}>
                    <Icon>{editMode ? `lock_open` : `lock`}</Icon>
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : null}
          </Grid>

          <Box sx={sx.break_lg} />

          {appointment.status !== 'paid' &&
          appointment.status !== 'canceled' &&
          appointment.status !== 'refunded' &&
          !expired ? (
            <Box sx={sx.pendingContainer}>
              <Grid container alignItems='center' spacing={1}>
                <Grid>
                  <Box component={BiErrorCircle} sx={sx.pendingIcon} />
                </Grid>
                <Grid size='grow'>
                  <Typography variant='body1' sx={sx.pendingText}>
                    Pay by {getPaymentDeadline()}
                  </Typography>
                  <Typography variant='body2' style={{ marginTop: '8px' }}>
                    In order to ensure time for scheduling and driver assignment, the consumer must complete payment by
                    the date and time above.
                  </Typography>
                  <Typography variant='body2'>
                    If payment isn't completed by then, you can set the appointment time further ahead by clicking the
                    lock icon in the upper right.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ) : null}

          {appointment.status !== 'paid' &&
          appointment.status !== 'canceled' &&
          appointment.status !== 'refunded' &&
          expired ? (
            <Box sx={sx.expiredContainer}>
              <Grid container alignItems='center' spacing={1}>
                <Grid>
                  <Box component={BiErrorCircle} sx={sx.expiredIcon} />
                </Grid>
                <Grid size='grow'>
                  <Typography variant='body1' sx={sx.expiredText}>
                    Offer expired!
                  </Typography>
                  <Typography variant='body2' style={{ marginTop: '8px' }}>
                    In order to ensure time for scheduling, payment must be completed within 90 minutes of the scheduled
                    appointment time.
                  </Typography>
                  <Typography variant='body2'>
                    If you would still like to proceed with this appointment, please click the lock icon in the upper
                    right to update the appointment time.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ) : null}

          <AppointmentStatusTracker appointment={appointment as Appointments} />

          <Box sx={sx.break_lg} />
          <Box sx={sx.main}>
            <Box sx={sx.paper}>
              {/* Header Section */}
              <Grid container spacing={2}>
                <Grid size={{ xs: 4 }}>
                  <Tooltip placement='top-start' title='Appointment ID'>
                    <Typography sx={sx.headTxt} style={{ textAlign: 'left' }}>
                      {appointment.id ? `Appointment #${appointment.id}` : `No Appointment ID`}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid size={{ xs: 4 }}>
                  <Tooltip placement='top' title='Appointment Date'>
                    <Typography sx={sx.headTxt} style={{ textAlign: 'center' }}>
                      {dayjs(appointment.appointment_time).format(`MM/DD/YYYY h:mm A`)}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid size={{ xs: 4 }} style={{ textAlign: 'right' }}>
                  <Tooltip placement='top-end' title='Appointment Status'>
                    <Typography sx={sx.headTxt}>
                      {appointment.status.charAt(0).toUpperCase() + appointment.status.slice(1)}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>

              <Box sx={sx.break} />

              {/* Details Section */}
              <Grid container spacing={2}>
                {/* Left Column */}
                <Grid size={{ xs: 6 }}>
                  <Box sx={sx.listItem}>
                    <Typography sx={sx.keyTxt}>Consumer Name:&nbsp;&nbsp;</Typography>
                    <>
                      <Typography sx={sx.valTxt}>{appointment.consumer_name || '-'}</Typography>
                    </>
                  </Box>
                  <Box sx={sx.lineBreak} />
                  <Box sx={sx.listItem}>
                    <Typography sx={sx.keyTxt}>Consumer Phone:&nbsp;&nbsp;</Typography>
                    <>
                      <Typography sx={sx.valTxt}>
                        {getCleansedPhoneNumber(appointment.consumer_phone) || '-'}
                      </Typography>
                    </>
                  </Box>
                  <Box sx={sx.lineBreak} />
                  <Box sx={sx.listItem}>
                    <Typography sx={sx.keyTxt}>Pickup:&nbsp;&nbsp;</Typography>
                    <Typography sx={sx.valTxt}>
                      {appointment.pickup && appointment.pickup.address ? appointment.pickup.address : '-'}
                    </Typography>
                  </Box>
                  <Box sx={sx.lineBreak} />
                  <Box sx={sx.listItem}>
                    <Typography sx={sx.keyTxt}>Delivery:&nbsp;&nbsp;</Typography>
                    <Typography sx={sx.valTxt}>
                      {appointment.delivery && appointment.delivery.address ? appointment.delivery.address : '-'}
                    </Typography>
                  </Box>
                </Grid>

                {/* Right Column */}
                <Grid size={{ xs: 6 }}>
                  <Box sx={sx.listItem}>
                    <Typography sx={sx.keyTxt}>{refNumLabel}:&nbsp;&nbsp;</Typography>
                    <Typography sx={sx.valTxt}>
                      {appointment.config && appointment.config.claim_number ? appointment.config.claim_number : '-'}
                    </Typography>
                  </Box>
                  <Box sx={sx.lineBreak} />
                  <Box sx={sx.listItem}>
                    <Typography sx={sx.keyTxt}>Vehicle:&nbsp;&nbsp;</Typography>
                    <Typography sx={sx.valTxt}>{getFormattedVehicleFromAppointment(appointment)}</Typography>
                  </Box>
                  <Box sx={sx.lineBreak} />
                  <Box sx={sx.listItem}>
                    <Typography sx={sx.keyTxt}>VIN:&nbsp;&nbsp;</Typography>
                    <Typography sx={sx.valTxt}>{appointment.vehicle_vin || '-'}</Typography>
                  </Box>
                  <Box sx={sx.lineBreak} />
                  <Box sx={sx.listItem}>
                    <Typography sx={sx.keyTxt}>Notes:&nbsp;&nbsp;</Typography>
                    <Typography sx={sx.valTxt}>{appointment.driver_notes || '-'}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box sx={sx.break_lg} />

          {/* Metadata Section */}
          <Box sx={sx.main}>
            <Box sx={sx.paper}>
              <Grid container spacing={2}>
                {/* Left Column */}
                <Grid size={{ xs: 6 }}>
                  <Box sx={sx.listItem}>
                    <Typography sx={sx.keyTxt}>Associated Move:&nbsp;&nbsp;</Typography>
                    <Typography sx={sx.valTxt}>
                      {appointment.move_id ? (
                        <Box component='a' href={`/moves/${appointment.move_id}`} sx={sx.link}>
                          Move {appointment.move_id}
                        </Box>
                      ) : (
                        '-'
                      )}
                    </Typography>
                  </Box>
                  <Box sx={sx.lineBreak} />
                  <Box sx={sx.listItem}>
                    <Typography sx={sx.keyTxt}>Market:&nbsp;&nbsp;</Typography>
                    <Typography sx={sx.valTxt}>
                      {appointment && appointment.customer && appointment.customer.name
                        ? appointment.customer.name
                        : '-'}
                    </Typography>
                  </Box>
                  <Box sx={sx.lineBreak} />
                  <Box sx={sx.listItem}>
                    <Typography sx={sx.keyTxt}>Offered:&nbsp;&nbsp;</Typography>
                    <Typography sx={sx.valTxt}>
                      {appointment.offered_at && appointment.offered_by
                        ? `${dayjs(appointment.offered_at).format(`MM/DD/YYYY h:mm A`)} by ${appointment.offered_by}`
                        : '-'}
                    </Typography>
                  </Box>
                  <Box sx={sx.lineBreak} />
                  <Box sx={sx.listItem}>
                    <Typography sx={sx.keyTxt}>Created:&nbsp;&nbsp;</Typography>
                    <Typography sx={sx.valTxt}>
                      {appointment.createdat && appointment.createdby
                        ? `${dayjs(appointment.createdat).format(`MM/DD/YYYY h:mm A`)} by ${appointment.createdby}`
                        : '-'}
                    </Typography>
                  </Box>
                </Grid>

                {/* Right Column */}
                <Grid size={{ xs: 6 }}>
                  <Box sx={sx.listItem}>
                    <Typography sx={sx.keyTxt}>Offer Link:&nbsp;&nbsp;</Typography>
                    <Typography sx={sx.valTxt}>
                      {appointment.config && appointment.config.offer_link ? (
                        <>
                          <Box
                            component='a'
                            href={appointment.config.offer_link}
                            target='_blank'
                            rel='noopener noreferrer'
                            sx={sx.link}
                          >
                            View Offer
                          </Box>
                          <Tooltip placement='top' title='Copy Offer Link'>
                            <IconButton
                              size='small'
                              onClick={() =>
                                appointment.config?.offer_link &&
                                navigator.clipboard.writeText(appointment.config.offer_link)
                              }
                              sx={sx.copyButton}
                            >
                              <Icon>content_copy</Icon>
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        '-'
                      )}
                    </Typography>
                  </Box>
                  <Box sx={sx.lineBreak} />
                  <Box sx={sx.listItem}>
                    <Typography sx={sx.keyTxt}>Payment Status:&nbsp;&nbsp;</Typography>
                    {getPaymentStatusFromAppointment(appointment)}
                  </Box>

                  {appointment.status === 'failed' && appointment.payment_failure_reason ? (
                    <>
                      <Box sx={sx.lineBreak} />
                      <Box sx={sx.listItem}>
                        <Typography sx={sx.keyTxt}>Failure Reason:&nbsp;&nbsp;</Typography>
                        <Typography sx={sx.valTxt}>{appointment.payment_failure_reason}</Typography>
                      </Box>
                    </>
                  ) : null}
                  <Box sx={sx.lineBreak} />
                  <Box sx={sx.listItem}>
                    <Typography sx={sx.keyTxt}>Transaction ID:&nbsp;&nbsp;</Typography>
                    <Typography sx={sx.valTxt}>{appointment.payment_transaction_id || '-'}</Typography>
                  </Box>
                  {appointment?.config?.emailsToNotify && appointment?.config?.emailsToNotify?.length > 0 ? (
                    <>
                      <Box sx={sx.lineBreak} />
                      <Box sx={sx.listItem}>
                        <Typography sx={sx.keyTxt}>Emails Notified:&nbsp;&nbsp;</Typography>
                        <Typography sx={sx.valTxt}>{appointment.config.emailsToNotify.join(', ')}</Typography>
                      </Box>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box sx={sx.break} />

          <Grid container justifyContent='flex-end'>
            {(!appointment.move || appointment.move.status === null || appointment.move.status === 'dispatched') &&
            appointment.status !== 'refunded' &&
            appointment.status !== 'canceled' ? (
              <>
                <Grid>
                  <Button sx={sx.cancelBtn} color='primary' onClick={handleCancelModalOpen}>
                    Cancel Appointment
                  </Button>
                </Grid>
                <Grid>
                  <Button sx={sx.resentBtn} color='primary' onClick={handleResendOffer}>
                    Resend Offer
                  </Button>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  iconBtn: {
    width: 40,
    height: 40,
  },
  head: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  nakedValInput: {
    width: '100%',
    minHeight: 17,
    maxHeight: 17,
    padding: 0,
    background: '#00000010',
    textAlign: 'right',
  },
  nakedValTxt: {
    width: '100%',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  cancelBtn: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(1),
    color: '#fff',
    background: '#000000',
    boxShadow: 'none',
    '&:hover, &:active': {
      boxShadow: 'none',
      background: '#333333',
    },
    transition: '0.2s',
  },
  resentBtn: {
    marginTop: theme.spacing(3),
    color: '#fff',
    background: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover, &:active': {
      boxShadow: 'none',
      background: theme.palette.primary.dark,
    },
  },
  expiredIcon: {
    color: '#B71C1C',
    marginRight: theme.spacing(1.5),
    fontSize: 20,
  },
  expiredText: {
    color: '#B71C1C',
    fontWeight: 500,
  },
  pendingIcon: {
    color: '#757575',
    marginRight: theme.spacing(1.5),
    fontSize: 20,
  },
  pendingText: {
    color: '#616161',
    fontWeight: 500,
  },
  copyButton: {
    marginLeft: theme.spacing(1),
  },
  root: {
    width: '100%',
    height: '100%',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  break_lg: {
    width: '100%',
    height: theme.spacing(3),
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.divider,
  },
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: '8px',
    background: theme.palette.background.paper,
    boxShadow: '0 0 16px #00000024',
  },
  pendingContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(2, 3),
    borderRadius: '8px',
    marginBottom: theme.spacing(2),
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.08)',
    border: '1px solid #e0e0e0',
  },
  expiredContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffebee',
    padding: theme.spacing(2, 3),
    borderRadius: '8px',
    marginBottom: theme.spacing(2),
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.08)',
    border: '1px solid #e0e0e0',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  chipContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
});

export default AppointmentDetailsContent;
