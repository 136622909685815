import React from 'react';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useRequestMoveCancelMutation } from '@gql/schema';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '@components/Modal';
import { useUser } from '@store';
import { config } from '@config';
const log = false;

interface MoveCancelModalProps {
  open: boolean;
  onClose?: (output?: any) => void;
  moveInput: any;
}

////////// COMPONENT //////////
const MoveCancelModal: React.FC<MoveCancelModalProps> = props => {
  const { open, onClose, moveInput } = props;
  const user = useUser();

  const [requestMoveCancelMutation] = useRequestMoveCancelMutation();

  const createNotification = async () => {
    try {
      const variables = {
        type: 'action',
        title: `Move ${moveInput.id} Pending Cancel`,
        body: `Move ${moveInput.id} is pending cancel. Click the link in the notes below to visit the details page and update the cancel status.`,
        createdat: 'now()',
        createdby: 'move-cancel.dealer',
        notes: `[Click to go to Move Details](${config.hopdrive.admin.url}moves/${moveInput.id})`,
      };

      const userToken = await user?.getToken();

      const res = await axios({
        method: `POST`,
        url: `/.netlify/functions/writeAdminNotification`,
        data: {
           variables: variables,
        },
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });
      if (res) {
        console.log(res);
      } else {
        log && console.error('Failed to create notification.');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = async () => {
    try {
      await createNotification();
    } catch (err) {
      console.log(err);
    }
    try {
      const res = await requestMoveCancelMutation({ variables: { id: moveInput.id } });
      if (res?.data?.update_moves) {
        const moveRes = res.data.update_moves.returning[0];
        console.log(moveRes);
        log && console.log(`>> UPDATED move #${moveRes.id} with a cancel_status of '${moveRes.cancel_status}'.`);
        toast.success(`Cancel request successful for Move #${moveRes.id}.`);
        handleClose();
      }
    } catch (err) {
      Sentry.captureException(err);
      console.log(`Error updating move:`, err);
      toast.error(`Failed to request move cancelation`);
    }
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <>
      <Modal open={open}>
        <ModalHeader handleClose={handleClose}>Cancelation Request for Move #{moveInput && moveInput.id}</ModalHeader>
        

        <ModalContent
          subtitle={`Are you sure you want to cancel this move? Your cancelation will be pending until reviewed by a HopDrive dispatcher.`}
        />

        <ModalFooter>
          <ModalAction onClick={() => handleSubmit()}>Confirm</ModalAction>
          <ModalAction onClick={() => handleClose()} color='utility'>
            Cancel
          </ModalAction>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default MoveCancelModal;
