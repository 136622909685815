import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import { useTheme, Theme, TextField, MenuItem } from '@mui/material';
import { Modal, ModalHeader, ModalContent } from '@components/Modal';
import { Arpaymentmethods } from '@gql/schema';
import MethodiFrame from './MethodiFrame';

interface MethodAddEditModalProps {
  open: boolean;
  //TODO: Fix this type
  onClose: () => void;
  methodInput?: Arpaymentmethods;
  primaryExists: boolean;
  customerId: number;
}

////////// COMPONENT //////////
const MethodAddEditModal: React.FC<MethodAddEditModalProps> = (props: MethodAddEditModalProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const { open, onClose, methodInput, primaryExists, customerId } = props;

  const [name, setName] = useState(``);
  const [type, setType] = useState(``);

  useEffect(() => {
    if (methodInput && methodInput.name) setName(methodInput.name);
    if (methodInput && methodInput.type) setType(methodInput.type);
  }, [methodInput]);

  //const handleInputChange = (setHandler: Function) => (event: Event) => {
  //  if (setHandler && event) setHandler(event?.target?.value);
  //};

  const handleClose = () => {
    if (onClose) onClose();
    setName(``);
    setType(``);
  };

  return (
    <>
      <Modal open={open}>
        <ModalHeader handleClose={handleClose}>
          {methodInput && methodInput.id !== null ? `Edit` : `Add`} Payment Method
        </ModalHeader>

        <ModalContent
          subtitle={`By clicking "Continue", your payment method will be saved and secured. Note: Sensitive information and private account details are never stored in\xa0our\xa0database.`}
        >
          <TextField
            fullWidth
            required
            label='Payment Name'
            placeholder='Enter a custom name for this payment...'
            variant='outlined'
            margin='none'
            value={name}
            // onChange={handleInputChange(setName)}
            onChange={event => {
              setName(event.target.value);
            }}
          />
          {name.length > 0 ? (
            <>
              <div className={cls.break} />
              <TextField
                fullWidth
                required
                select
                label='Payment Type'
                placeholder='Select a payment type...'
                variant='outlined'
                margin='none'
                value={type}
                // onChange={handleInputChange(setType)}
                onChange={event => {
                  setType(event.target.value);
                }}
              >
                <MenuItem key={'Select Payment Type'} value={``}>
                  <em>Select Payment Type</em>
                </MenuItem>
                <MenuItem key={'Credit Card'} value={`card`}>
                  Credit Card
                </MenuItem>
                <MenuItem key={'ACH Check'} value={`ach`}>
                  ACH Check
                </MenuItem>
                <MenuItem key={'Electronic Check'} value={`echeck`}>
                  Electronic Check
                </MenuItem>
              </TextField>
              {type !== `` ? (
                <>
                  <div className={cls.break} />
                  <MethodiFrame
                    customerId={customerId}
                    id={methodInput?.id}
                    name={name}
                    type={type}
                    primaryExists={primaryExists}
                    handleClose={handleClose}
                  />
                </>
              ) : null}
            </>
          ) : null}
        </ModalContent>
      </Modal>
    </>
  );
};

export default MethodAddEditModal;

////////// STYLES //////////
const useStyles = (theme: Theme) => {
  return {
    break: css`
      width: 100%;
      height: ${theme.spacing(1.5)};
    `,
  };
};
