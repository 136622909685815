import dayjs from "dayjs";

export const formatString = (str: string, options = {}) => {
  const { removeSpaces = false, capAll = false, capEach = false } = options as any;
  let newStr = str;
  if (str && str.length) {
    newStr = newStr.trim();
    if (removeSpaces) newStr = newStr.replace(/\s/g, '');
    if (options?.capAll) newStr = capAll(newStr);
    if (options?.capEach) newStr = capEach(newStr);
    return newStr;
  }
  return str;
};

export const formatUSD = (value: any = null, options = {}) => {
  const { returnNull = false, removeSign = false, removeCommas = false, removeCents = false } = options as any;

  // Check for value and return null
  if (!value && returnNull) return null;

  // Remove spaces and commas so the value can be converted to a number
  // Build the cleansed monetary string from the numeric value
  const replacedValue = `${value}`.replace(/[,]/g, '');
  const numValue = Number(replacedValue) || 0;
  let cleansedValue = '$' + numValue.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

  // Additional options
  if (removeSign) cleansedValue = cleansedValue.replace(/[$]/g, '');
  if (removeCommas) cleansedValue = cleansedValue.replace(/[,]/g, '');
  if (removeCents) cleansedValue = cleansedValue.split('.')[0];

  // Return cleansed monetary value
  return cleansedValue;
};

export const formatNumber = (str: string | undefined = undefined, options = {}) => {
  if (str) {
    const replacedStr = `${str}`.replace(/[,]/g, '');
    const numValue = Number(replacedStr) || 0;
    return numValue;
  }
  return 0;
};

export const formatPhone = (str: string | undefined = undefined, options = {}) => {
  const { removeInternationalCode = false } = options as any;

  if (str) {
    const cleansedStr = `${str}`.replace(/\D/g, '');
    const match = cleansedStr.match(/^(\d{1})?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? `+${match[1]} ` : '';
      return [removeInternationalCode ? '' : intlCode, '(', match[2], ')\xa0', match[3], '-\u2060', match[4]].join('');
    }
  }
  return str;
};

export const getCleansedPhoneNumber = (str?: string | null) => {
  if (str) {
    const cleaned = ('' + str).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ')\xa0', match[3], '-\u2060', match[4]].join('');
    }
  }
  return str;
};

export const formatSSN = (str: string | undefined = undefined, options = {}) => {
  const { removeDashes = false } = options as any;

  if (str) {
    const cleansedStr = `${str}`.replace(/\D/g, '');
    const match = cleansedStr.match(/^(\d{3})-?(\d{2})-?(\d{4})$/);
    if (match) {
      const joiner = removeDashes ? '' : '-';
      return [match[1], match[2], match[3]].join(joiner);
    }
  }
  return str;
};

export const condensedCase = (str: string | null) => {
  if (str && typeof str === 'string') {
    const newStr = str
      .replace(/[,.?!:;`'"/{}()<>\s_+-]/g, '')
      .toLowerCase()
      .trim();
    return newStr;
  }
  return str;
};

export const capFirst = (str: string | null) => {
  if (str && typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};

export const capEach = (str: string | null) => {
  if (str && typeof str === 'string') {
    if (!str.includes(' ')) return str.charAt(0).toUpperCase() + str.slice(1);
    else {
      let arr = str.split(' ');
      arr = arr.map(s => s.charAt(0).toUpperCase() + s.slice(1));
      return arr.join(' ');
    }
  }
  return str;
};

export const capAll = (str: string | null) => {
  if (str && typeof str === 'string') {
    return str.toUpperCase();
  }
  return str;
};

export const formatTime = (time: string | undefined) => {
  if (time && typeof time === 'string') {
    return dayjs(time).format('MM/DD/YYYY hh:mm A z').replace(/\s/g, '\xa0');
  }
  return null;
};

export const checkNeg = (num: number) => {
  if (num > 0) return num;
  else return 0;
};

export const round = (num: number | null, precision = 0) => {
  if (num) {
    const multiplier = Math.pow(10, precision);
    const output = Math.round(num * multiplier) / multiplier;
    return output;
  }
  return 0;
};

export const getMonetaryValue = (amount?: number | null, returnNull = false) => {
  if (amount) {
    const value = Number(amount) || null;
    if (value) return `$` + value.toFixed(2);
  }
  if (returnNull) return null;
  return `$0.00`;
};

export const getCleansedMonetaryValue = (num: number | null) => {
  if (num) {
    const value = Number(num).toFixed(2);
    return `$${value}`;
  }
  return num;
};

export const clampNegNum = (num: number | null) => {
  if (num && !isNaN(parseFloat(num.toString())) && num > 0) return num;
  return 0;
};

export const moneyWithCommas = (val: number) => {
  if (val) {
    const valStr = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (valStr.includes(`-`)) return `-$` + valStr.replace(`-`, ``);
    else return `$` + valStr;
  } else return `$0`;
};