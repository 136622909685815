// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { toast } from 'react-toastify';

import { Moves, Appointments } from '@gql/schema';

import { css } from '@emotion/css';
import { Theme, useTheme, Chip, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { BiMailSend } from 'react-icons/bi';

const maxEmailsLength = 5;

// TYPES ---------------------------------------------------------------- //

interface MoveDetailsEmailNotificationsProps {
  move?: Moves & { config?: Config };
  appointment?: Appointments & { config?: Config };
  editEmails?: string[];
  setEditEmails?: (editEmails: string[]) => void;
  isEditing?: boolean;
}

interface Config {
  emailsToNotify?: string[];
}

// COMPONENT ---------------------------------------------------------------- //

const MoveDetailsEmailNotifications = ({
  move,
  appointment,
  editEmails,
  setEditEmails,
  isEditing,
}: MoveDetailsEmailNotificationsProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const [input, setInput] = React.useState<string>(``);

  /** Check if a given email is valid */
  const isValidEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern?.test(email);
  };

  /** Handle adding an email to the emails array */
  const handleAddEmail = (value?: string) => {
    setInput(``);

    if (!value) return;

    if (!isValidEmail(value)) {
      toast.warning(`Email entered was invalid and could not be added!`, { toastId: `move-planner-emails` });
      return;
    }
    if (editEmails?.includes(value)) {
      toast.warning(`Email was already added!`, { toastId: `move-planner-emails` });
      return;
    }

    const newEmails = [...(editEmails || []), value];

    if (newEmails?.length > maxEmailsLength) {
      toast.warning(`You can only add up to ${maxEmailsLength} emails!`, { toastId: `move-planner-emails` });
      return;
    }

    if (setEditEmails) setEditEmails(newEmails);
  };

  /** Handle blurring away from the input */
  const handleOnBlur = () => {
    handleAddEmail(input);
  };

  /** Handle pressing enter on the input */
  const handleOnKeyDown = (event?: React.KeyboardEvent) => {
    if (event?.key === `Enter`) {
      handleAddEmail(input);
    }
  };

  if (!move) return null;
  return (
    <Paper variant='custom' sx={{ padding: `16px` }}>
      {appointment ? (
        <Typography variant='body2' sx={{ marginBottom: `12px`, color: theme?.palette?.text?.secondary }}>
          Add up to five email addresses to receive notifications for this appointment.
        </Typography>
      ) : (
        <Typography variant='body2' sx={{ marginBottom: `12px`, color: theme?.palette?.text?.secondary }}>
        You can opt to receive two different emails when this move is updated. One when the vehicle is picked up and one
        when the vehicle is delivered. Up to five email addresses may be added. Below are the emails that will receive
        notifications:
      </Typography>
      )}


      {isEditing ? (
        <>
          <label className={cls.textFieldLabel} htmlFor='move-details-emails'>
            Emails To Notify{' '}
            <span className={cls.textFieldLength}>
              ({isEditing ? editEmails?.length : move?.config?.emailsToNotify?.length || 0}/{maxEmailsLength})
            </span>
          </label>

          <TextField
            fullWidth
            name='move-details-emails'
            placeholder='Enter an email to notify, then press enter...'
            value={input}
            onChange={e => setInput(e?.target?.value || ``)}
            onBlur={() => handleOnBlur()}
            onKeyDown={e => handleOnKeyDown(e)}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position='start'>
                    <BiMailSend style={{ fontSize: 20 }} />
                  </InputAdornment>
                ),
              },
            }}
          />
        </>
      ) : null}

      {isEditing && editEmails?.length ? (
        <div className={cls.chipFlex}>
          {editEmails?.map((email, index) => (
            <Chip
              key={`move-details-emails-${index}`}
              label={email}
              onDelete={() => {
                const newEmails = editEmails?.filter((_, i) => i !== index);
                if (setEditEmails) setEditEmails(newEmails);
              }}
            />
          ))}
        </div>
      ) : !isEditing && move?.config?.emailsToNotify?.length ? (
        <div className={cls.chipFlex}>
          {move?.config?.emailsToNotify?.map((email, index) => (
            <Chip key={`move-details-emails-${index}`} label={email} />
          ))}
        </div>
      ) : (
        <div className={cls.chipFlex}>
          <Chip label={`No emails added`} />
        </div>
      )}
    </Paper>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  textFieldLabel: css`
    display: block;
    margin-bottom: 2px;
    margin-left: 6px;
    font-size: 12px;
    font-weight: 600;
  `,
  textFieldLength: css`
    color: ${theme?.palette?.text?.secondary};
  `,
  textFieldLabelRequired: css`
    color: ${theme?.palette?.error?.main};
  `,

  chipFlex: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 6px;
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default MoveDetailsEmailNotifications;
