import React from 'react';
import { Button, ButtonProps } from '@mui/material';

interface ModalActionProps extends ButtonProps {
  className?: string;
  onClick?: () => void;
  color?: 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained';
  disabled?: boolean;
  children: React.ReactNode;
  loading?: boolean;
}

const ModalAction: React.FC<ModalActionProps> = ({
  className,
  onClick,
  color = 'primary',
  size = 'large',
  variant = 'text',
  disabled,
  children,
  loading,
  ...props
}) => {
  return (
    <Button
      className={className}
      onClick={onClick}
      color={color as ButtonProps['color']}
      size={size}
      variant={variant}
      disabled={disabled || loading}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ModalAction;
