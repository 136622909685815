// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { useParams } from 'react-router-dom';

import {
  Dealer_Viewable_Driver_Info,
  Moves,
  // SubscribeToMoveDetailsDocument,
  useGetMoveDetailsDriverLazyQuery,
  // useGetMoveDetailsQuery,
  useSubscribeToMoveDetailsSubscription,
} from '@gql/schema';

import { css } from '@emotion/css';
import { Theme, useTheme, LinearProgress, Container } from '@mui/material';

import MoveDetailsContent from '@features/moves/components/MoveDetailsContent';

// COMPONENT ---------------------------------------------------------------- //

const MoveDetailsPage = () => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const { id: paramMoveId } = useParams<{ id: string }>();

  const [getDriver] = useGetMoveDetailsDriverLazyQuery({ variables: { moveId: parseInt(paramMoveId || '0') } });

  const [driver, setDriver] = React.useState<Dealer_Viewable_Driver_Info>({});

  const { error, loading, data } = useSubscribeToMoveDetailsSubscription({
    variables: { moveId: parseInt(paramMoveId || '0') },
  });

  // const { subscribeToMore, error, loading, data } = useGetMoveDetailsQuery({
  //   variables: { moveId: parseInt(paramMoveId || '0') },
  // });

  // // Subscribe to move details
  // React.useEffect(() => {
  //   const unsubscribe = subscribeToMore({
  //     document: SubscribeToMoveDetailsDocument,
  //     variables: { moveId: parseInt(paramMoveId || '0') },
  //     updateQuery: (prev, { subscriptionData }) => {
  //       if (!subscriptionData.data) return prev;
  //       return {
  //         ...prev,
  //         moves_by_pk: subscriptionData.data.moves_by_pk,
  //       };
  //     },
  //   });
  //   return unsubscribe;
  // }, [subscribeToMore, paramMoveId]);

  // Fetch the dealer viewable driver info
  React.useEffect(() => {
    const fetchDriver = async () => {
      if (!paramMoveId) return;

      const driverRes = await getDriver();
      if (driverRes?.data?.dealer_viewable_driver_info?.length) {
        setDriver(driverRes?.data?.dealer_viewable_driver_info?.[0]);
      }
    };
    fetchDriver();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramMoveId]);

  // Set the move as a local constant
  const move: Moves = data?.moves_by_pk;

  // Render the loading component
  if (error || loading) return <LinearProgress />;

  // Render the move details page
  return (
      <div className={cls.pageContainer}>
        <Container maxWidth='lg'>
          <MoveDetailsContent move={move} driver={driver} />
        </Container>
      </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  pageContainer: css`
  padding: ${theme?.spacing(3)} 0;
  min-height: 100vh;
`,
});

// EXPORT ---------------------------------------------------------------- //

export default MoveDetailsPage;
