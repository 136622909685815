// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { css } from '@emotion/css';
import { Theme, useTheme, IconButton, Tooltip, TooltipProps } from '@mui/material';

import { BiSolidHelpCircle } from 'react-icons/bi';

// TYPES ---------------------------------------------------------------- //

interface HeaderIconProps {
  icon?: React.ReactElement;
  iconSize?: number;
  onClick?: () => void;
  tooltip?: string;
  tooltipPlacement?: TooltipProps['placement'];
}

// COMPONENT ---------------------------------------------------------------- //

const HeaderIcon = ({ icon, iconSize, onClick, tooltip, tooltipPlacement }: HeaderIconProps) => {
  const theme = useTheme();
  const cls = useStyles(theme, { iconSize });

  const handleClickIcon = () => {
    if (onClick) onClick();
  };

  const Icon = () => {
    const element = React.cloneElement(icon || <BiSolidHelpCircle />, { className: cls.headerIcon });
    return element;
  };

  return (
    <Tooltip title={tooltip || ``} placement={tooltipPlacement || `bottom`}>
      <IconButton onClick={handleClickIcon}>
        <Icon />
      </IconButton>
    </Tooltip>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme, props?: { iconSize?: number }) => {
  const styles = {
    headerIcon: css`
      font-size: ${props?.iconSize || 24}px;
      color: ${theme?.palette?.text?.secondary};
    `,
  };

  return styles;
};

// EXPORT ---------------------------------------------------------------- //

export default HeaderIcon;
