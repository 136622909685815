import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';
import Table from '@components/TableComponents/Table';
import Column from '@components/TableComponents/Table';
import Chip from '@mui/material/Chip';
import { Appointments } from '@gql/schema';

const defaultOrder = `desc`;
const defaultOrderBy = `APPOINTMENT_TIME`;

const formatTime = (time: string) => {
    if (time && typeof time === `string`) {
      let newTime = dayjs(time).format(`MM/DD/YYYY hh:mm A z`);
      newTime = newTime.replace(/\s/g, '\xa0');
      return newTime;
    }
    return null;
  };
  
interface AppointmentsTableProps {
  appointments: Appointments[];
}

const AppointmentsTable: React.FC<AppointmentsTableProps> = ({ appointments }) => {
    const navigate = useNavigate();

    const getChipColor = (status: string) => {
      const statusLower = status.toLowerCase();
      if (['ready', 'paying'].includes(statusLower)) return '#486496'; // blue
      if (['failed'].includes(statusLower)) return '#f44232'; // red
      if (statusLower === 'paid') return '#4caf50'; // green
      return '#757575'; // default gray
    };

    const getFormattedVehicle = (appointment: Appointments) => {
      const { vehicle_year, vehicle_make, vehicle_model } = appointment;
      if (vehicle_year && vehicle_make && vehicle_model) {
        return `${vehicle_year} ${vehicle_make} ${vehicle_model}`;
      }
      return 'N/A';
    };

    return (
      <div style={{background: '#ffffff'}}>
        <Table
          data={appointments}
          onRowClick={(data: any) => navigate(`/appointments/${(data as Appointments)?.id}`)}
          defaultOrderBy={defaultOrderBy}
          defaultOrder={defaultOrder}
          tableAriaLabel="appointments"
          stickyHeader={true}
        >
          <Column
            name="customer"
            value={(appointment: Appointments) => appointment?.customer?.name || '-'}
            label="Market"
            style={{ minWidth: 120 }}
          />
          <Column
            name="status"
            value={(appointment: Appointments) => appointment.status}
            renderer={(appointment: Appointments) => (
              <Chip
                variant="outlined"
                size="small"
                label={appointment.status.charAt(0).toUpperCase() + appointment.status.slice(1).toLowerCase()}
                style={{
                  borderColor: getChipColor(appointment.status),
                  color: getChipColor(appointment.status)
                }}
              />
            )}
            label="Status"
            style={{ minWidth: 100 }}
          />
          <Column
            name="appointment_time"
            value={(appointment: Appointments) => appointment.appointment_time}
            renderer={(appointment: Appointments) => formatTime(appointment.appointment_time)}
            label="Appointment Time"
            style={{ minWidth: 160 }}
          />
          <Column
            name="consumer_name"
            value={(appointment: Appointments) => appointment.consumer_name || '-'}
            label="Consumer Name"
            style={{ minWidth: 140 }}
          />
          <Column
            name="vehicle"
            value={(appointment: Appointments) => getFormattedVehicle(appointment)}
            label="Vehicle"
            style={{ minWidth: 160 }}
          />
          <Column
            name="claim_number"
            value={(appointment: Appointments) => appointment?.config?.claim_number || '-'}
            label="Claim Number"
            style={{ minWidth: 120 }}
          />
        </Table>
      </div>
    );
};

export default AppointmentsTable;