import React from 'react';
import { css } from '@emotion/css';
import dayjs, { Dayjs } from 'dayjs';
import { Customers, Moves } from '@gql/schema';
import { Badge, TextField, Typography, Theme, SxProps, useTheme } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';

interface OverviewHeaderProps {
  customers: Customers[];
  date?: string;
  setDate?: any;
}
const OverviewHeader: React.FC<OverviewHeaderProps> = (props: OverviewHeaderProps) => {
  const theme = useTheme();
  const sx = useSxStyles(theme);
  const cls = useStyles(theme);

  const { customers, date, setDate } = props;

  //Changes query variables to get moves from a different day
  const handleDateChange = (value: Dayjs | null) => {
    if (!value) return;
    const str = JSON.stringify(value);
    const dateString = str.substring(1, str.length - 1);
    // console.log('DatePicked', event, typeof event, JSON.stringify(event), dateString);
    localStorage.setItem('overview-date', dateString);
    setDate(dateString);
  };

  const uncanceledMoveCount = (moves: Moves[]) => {
    /*
    This isNotCanceled func is a safe-gaurd in case a move's cancel_status is in a "canceled" status
    but the move's status has not been updated to "canceled" 
    this should never happen but I saw a few cases in the prod db that were in this in-between state
    */
    let isNotCanceled = (move: Moves) => {
      if (move.cancel_status && (move.cancel_status === 'canceled' || move.cancel_status === 'started')) {
        return false;
      } else {
        return true;
      }
    };
    let uncanceledMoves = moves.filter(move => move.status !== 'canceled' && isNotCanceled(move));
    return uncanceledMoves.length;
  };

  return (
    <div className={cls.headerRow}>
      <div className={cls.calendarCol}>
        <MobileDatePicker
          closeOnSelect
          label='Select Date'
          orientation='portrait'
          value={dayjs(date)}
          onAccept={value => handleDateChange(value)}
          slots={{
            textField: TextField,
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              size: 'small',
              variant: 'outlined',
              // className: styles.date,
            },
          }}
        />
      </div>
      {customers.map((customer: Customers) => {
        return (
          <div key={'day' + customer.id} className={cls.customerCol}>
            <Badge badgeContent={uncanceledMoveCount(customer.moves)} color='primary'>
              <Typography sx={sx.customerText}>{customer.name}</Typography>
            </Badge>
          </div>
        );
      })}
    </div>
  );
};
export default OverviewHeader;

const useStyles = (theme?: Theme) => ({
  headerRow: css`
    position: sticky;
    height: 100%;
    top: 0;
    display: flex;
    background-color: ${theme?.palette?.background?.paper};
    box-shadow: ${theme?.shadows};
    color: ${theme?.palette?.text?.primary};
    opacity: 1;
    z-index: 3;
  `,
  calendarCol: css`
    width: 160px;
    position: sticky;
    left: 0;
    flex: 0 1 25em;
    padding: 20px;
    align-items: center;
    justify-content: center;
    z-index: 4;
    background: inherit;
  `,
  customerCol: css`
    flex: 1;
    word-break: normal;
    padding: 15px;
    place-self: center;
    text-align: center;
    min-width: 164px;
    justify-content: space-between;
  `,
  multilineColor: css`
    color: ${theme?.palette?.text?.primary};
  `,
});

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  customerText: {
    lineHeight: '1.25',
  },
});
