export interface Marker {
  icon: string;
  shadow: boolean;
  lat: number;
  lon: number;
}

export interface BuildParams {
  key?: string;
  markers?: Marker[];
  center?: string;
  zoom?: string;
  scale?: boolean;
  size?: string;
  maptype?: string;
  format?: string;
  visual_refresh?: boolean;
}

export const buildGoogleStaticMapURL = ({
  key,
  markers = [],
  center,
  zoom,
  scale,
  size,
  maptype,
  format,
  visual_refresh,
}: BuildParams): string => {
  let url = 'https://maps.googleapis.com/maps/api/staticmap?1=1';
  url += param('key', key);
  url += param('zoom', zoom);
  url += param('scale', scale || true);
  url += param('size', size);
  url += param('maptype', maptype);
  url += param('format', format);
  url += param('visual_refresh', visual_refresh || true);
  url += param('center', center?.toLowerCase() === 'auto' ? autoCenter(markers) : center);
  url += markers.map(marker => (marker.lat != null ? mark(marker) : '')).join(' ');
  return url;
};

export const param = (name: string, value?: string | boolean): string => {
  return value ? `&${name}=${value}` : '';
};

export const mark = (marker: Marker): string => {
  const icon = mpart('icon', marker.icon);
  const shadow = mpart('shadow', marker.shadow.toString());
  const latlon = `|${marker.lat},${marker.lon}`;
  return param('markers', icon + shadow + latlon);
};

export const mpart = (name: string, value?: string): string => {
  return value ? `|${name}:${value}` : '';
};

export const autoCenter = (markers: Marker[]): string => {
  const minLat = Math.min(...markers.map(o => o.lat));
  const maxLat = Math.max(...markers.map(o => o.lat));
  const minLon = Math.min(...markers.map(o => o.lon));
  const maxLon = Math.max(...markers.map(o => o.lon));
  const centerLat = (minLat + maxLat) / 2;
  const centerLon = (minLon + maxLon) / 2;
  return `${centerLat},${centerLon}`;
};
