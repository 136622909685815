import React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import { useUser } from '@store';

import ProtectedRoute from '@components/ProtectedRoute';

import AppraisalsPage from '@features/appraisals/pages/AppraisalsPage';
import AppraisalDetailsPage from '@features/appraisals/pages/AppraisalDetailsPage';
import LoginPage from '@features/auth/pages/LoginPage';
import ProfilePage from '@features/auth/pages/ProfilePage';
import InvoicesPage from '@features/invoices/pages/InvoicesPage';
import DashboardPage from '@features/dashboard/pages/DashboardPage';
import MovePlannerPage from '@features/moveCreation/pages/MovePlannerPage';
import OrganizationOverview from '@features/rooftops/pages/OrganizationOverview';
import BillingPage from '@features/billing/pages/BillingPage';
import LocationsPage from '@features/locations/pages/LocationsPage';
import LocationDetailPage from '@features/locations/pages/LocationDetailPage';
import MovesPage from '@features/moves/pages/MovesPage';
import MoveImportPage from '@features/moveImport/pages/MoveImportPage';
import MoveDetailsPage from '@features/moves/pages/MoveDetailsPage';
import UsersPage from '@features/userManagement/pages/UsersPage';
import UserAddPage from '@features/userManagement/pages/UserAdd';
import UserDetailsPage from '@features/userManagement/pages/UserDetails';
import AppointmentsPage from '@features/appointments/pages/Appointments';
import AppointmentDetailsPage from '@features/appointments/pages/AppointmentDetails';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<LoggedInToDashboard />} />
      <Route path='/login' element={<LoggedInToDashboard />} />
      <Route
        path='/profile'
        element={
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/invoices'
        element={
          <ProtectedRoute>
            <InvoicesPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/invoices/:id'
        element={
          <ProtectedRoute>
            <InvoicesPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/moves'
        element={
          <ProtectedRoute>
            <MovesPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/moves/:id'
        element={
          <ProtectedRoute>
            <MoveDetailsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/plan-moves'
        element={
          <ProtectedRoute>
            <MovePlannerPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/import-moves'
        element={
          <ProtectedRoute>
            <MoveImportPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/locations'
        element={
          <ProtectedRoute>
            <LocationsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/locations/:id'
        element={
          <ProtectedRoute>
            <LocationDetailPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard'
        element={
          <ProtectedRoute>
            <DashboardPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/organization-view'
        element={
          <ProtectedRoute>
            <OrganizationOverview />
          </ProtectedRoute>
        }
      />
      <Route
        path='/billing-preferences'
        element={
          <ProtectedRoute>
            <BillingPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/users'
        element={
          <ProtectedRoute>
            <UsersPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/users/add'
        element={
          <ProtectedRoute>
            <UserAddPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/users/:id'
        element={
          <ProtectedRoute>
            <UserDetailsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/appraisals'
        element={
          <ProtectedRoute>
            <AppraisalsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/appraisals/:id'
        element={
          <ProtectedRoute>
            <AppraisalDetailsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/appointments'
        element={
          <ProtectedRoute>
            <AppointmentsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/appointments/:id'
        element={
          <ProtectedRoute>
            <AppointmentDetailsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/appointments/add'
        element={
          <ProtectedRoute>
            <AppointmentDetailsPage />
          </ProtectedRoute>
        }
      />
      <Route path='*' element={<NoMatch />}></Route>
    </Routes>
  );
};

function LoggedInToDashboard() {
  const user = useUser();
  const location = useLocation();
  if (user && location?.pathname === '/login') {
    return <Navigate to='/dashboard' />;
  } else {
    return <LoginPage />;
  }
}

function NoMatch() {
  const location = useLocation();

  return (
    <div style={{ marginTop: 200 }}>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export default AppRoutes;
