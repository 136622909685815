import React from 'react';
import { css } from '@emotion/css';
import { useParams } from 'react-router-dom';
import { getMileageFromMove } from '@services/moveService';
// import * as Sentry from '@sentry/react';
import { Container, Grid2 as Grid, Typography, Button, useTheme, Theme } from '@mui/material';

import { useRooftop, useCustomerConfig } from '@store';
import { Appraisals, Moves, useGetAppraisalByIdQuery } from '@gql/schema';

import Loading from '@components/Utils/Loading';
import AppraisalDetailsHeader from '@features/appraisals/components/AppraisalDetailsHeader';
import AppraisalDetailsCarousel from '@features/appraisals/components/AppraisalDetailsCarousel';
import AppraisalDetailsSidebar from '@features/appraisals/components/AppraisalDetailsSidebar';

const log = false;

//This is the format of the image object used in the gallery
type Image = {
  label: string;
  original: string;
  thumbnail: string;
  index: number;
};

interface MatchParams {
  id: string;
}

const AppraisalDetailsPage: React.FC = () => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sxStyles = useSxStyles(theme);
  const rooftop = useRooftop();
  const customerConfig = useCustomerConfig();

  //Get id from URL
  const { id } = useParams<keyof MatchParams>() as MatchParams;
  const appraisalId = parseInt(id);

  const [unsavedChanges, setUnsavedChanges] = React.useState(false);
  const [vehicleImages, setVehicleImages] = React.useState<Image[]>([]);

  const [accessLoading, setAccessLoading] = React.useState(true);
  const [access, setAccess] = React.useState({ active: false });
  const [branding, setBranding] = React.useState(null);

  React.useEffect(() => {
    setCustomerAccess();
  }, [customerConfig]);

  const { data, loading, error, refetch } = useGetAppraisalByIdQuery({
    variables: {
      id: appraisalId,
      customer_id: rooftop?.id || 0,
    },
  });

  // Get the customer config and set the customer's access
  const setCustomerAccess = async () => {
    if (rooftop && customerConfig) {
      try {
        const appraisalsAccess =
          customerConfig && customerConfig.appraisals ? customerConfig.appraisals : { active: false };
        const branding = customerConfig && customerConfig.branding ? customerConfig.branding : null;
        setBranding(branding);
        setAccess(appraisalsAccess);
        setAccessLoading(false);
      } catch (err) {
        console.error(`Error getting customer config:`, err);
      }
    }
  };

  const applySorting = (dataset: Moves[]) => {
    if (dataset && dataset.length > 0)
      return dataset.sort((a, b) => {
        const aTime = a.ready_by;
        const bTime = b.ready_by;
        if (!aTime) return 1;
        if (!bTime) return -1;
        if (aTime > bTime) return -1;
        if (aTime < bTime) return 1;
        return 0;
      });
    else return [];
  };

  const applyFiltering = (dataset: Moves[]) => {
    if (dataset && dataset.length > 0) {
      let newDataset = [...dataset];
      newDataset = newDataset.filter(move => {
        let useMove = true;

        if (
          move.status !== `pickup successful` &&
          move.status !== `delivery started` &&
          move.status !== `delivery arrived` &&
          move.status !== `delivery successful`
        )
          useMove = false;

        if (move.cancel_status && move.cancel_status.includes(`canceled`)) useMove = false;

        if (move.consumer_type === `loaner`) useMove = false;

        return useMove;
      });
      return newDataset;
    } else return [];
  };

  const getAppraisalMoveOdometer = (move: Moves) => {
    if (!move) return 0;
    const mileage = getMileageFromMove(move);
    return mileage;
  };

  if (accessLoading || loading) return <Loading />;
  if (!access.active)
    return (
      <div className={styles.restricted}>
        <Container maxWidth='md'>
          <Typography sx={sxStyles.restrictedText}>
            Welcome to the new HopDrive appraisal feature. This revolutionary tool will turn your Service Lane into an
            Appraisal Drive and help you buy more cars from your customers! If you are interested in seeing a demo,
            please email sales@hopdrive.com to set up some time.
          </Typography>
          <a href='mailto:sales@hopdrive.com?cc=sales@hopdrive.com&subject=Appraisals%20-%20Demo%20Request'>
            <Button sx={sxStyles.restrictedButton} color='primary' variant='contained' size='large'>
              Request Demo
            </Button>
          </a>
        </Container>
      </div>
    );

  if (data && data.appraisals && data.appraisals.length > 0) {
    // Need to clone data to be able to modify it
    const appraisal = { ...data.appraisals[0] } as Appraisals;
    if (appraisal.moves && appraisal.moves.length > 0) {
      const moves = { ...appraisal.moves } as Moves[];
      const sortedMoves = applySorting(moves);
      const filteredMoves = applyFiltering(sortedMoves);
      if (filteredMoves && filteredMoves.length > 0) {
        appraisal.moves = filteredMoves;
      }
    }
    const apiVehicle = appraisal.vehicle_data ? appraisal.vehicle_data : {};
    log && console.log(`Appraisal Data:`, appraisal);

    return (
      <>
        {/* <Prompt
            when={unsavedChanges}
            message='You are attempting to leave the page with unsaved changes. Are you sure you want to do this?'
        /> */}
        <div className={styles.root}>
          <Grid container justifyContent='space-between' alignItems='flex-start' wrap='nowrap'>
            <Grid sx={sxStyles.gridLeft} size={{ xl: 9, lg: 8, md: 7, sm: 6, xs: 12 }}>
              <div className={styles.base}>
                <div className={styles.header}>
                  <AppraisalDetailsHeader
                    appraisal={appraisal}
                    move={appraisal.moves[0] as Moves}
                    mileage={getAppraisalMoveOdometer(appraisal.moves[0] as Moves)}
                  />
                </div>
                <div className={styles.body}>
                  <Container maxWidth='md'>
                    <AppraisalDetailsCarousel
                      appraisal={appraisal}
                      vehicleImages={vehicleImages}
                      setVehicleImages={setVehicleImages}
                    />
                  </Container>
                </div>
              </div>
            </Grid>
            <Grid sx={sxStyles.gridRight} size={{ xl: 3, lg: 4, md: 5, sm: 6, xs: 12 }}>
              <AppraisalDetailsSidebar
                appraisal={appraisal}
                vehicle={apiVehicle}
                unsavedChanges={unsavedChanges}
                setUnsavedChanges={setUnsavedChanges}
                vehicleImages={vehicleImages}
                appraisalMoveOdometer={getAppraisalMoveOdometer(appraisal.moves[0] as Moves)}
                branding={branding}
              />
            </Grid>
          </Grid>
        </div>
      </>
    );
  } else
    return (
      <div className={styles.notFound}>
        <Typography sx={sxStyles.notFoundTxt}>NO APPRAISAL RECORD FOUND</Typography>
      </div>
    );
};

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = (theme: Theme) => {
  return {
    root: css`
      display: block;
      position: relative;
      min-height: 100vh;
    `,
    base: css`
      display: flex;
      flex-direction: column;
    `,
    header: css`
      z-index: 10;
      position: relative;
    `,
    body: css`
      position: relative;
      padding-top: ${theme.spacing(3)};
      padding-bottom: ${theme.spacing(3)};
    `,
    notFound: css`
      padding: ${theme.spacing(4)};
      border: 1px solid #ddd;
      border-radius: 8px;
      margin-left: auto;
      margin-right: auto;
      background: #fff;
    `,
    restricted: css`
      width: 100%;
      padding-top: ${theme.spacing(4)};
      text-align: center;
    `,
  };
};

const useSxStyles = (theme: Theme) => ({
  gridLeft: {
    position: 'relative',
  },
  gridRight: {
    position: 'sticky',
    top: 0,
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: '#fff',
    borderLeft: '1px solid #ddd',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  restrictedText: {
    lineHeight: 1.333,
    fontSize: 18,
    fontWeight: 400,
  },
  restrictedButton: {
    marginTop: theme.spacing(3),
    color: '#fff',
    boxShadow: 'none',
    '&:hover, &:active': {
      boxShadow: 'none',
    },
    transition: '0.2s',
  },
});

//////////////////////////////////////// EXPORT ////////////////////////////////////////
export default AppraisalDetailsPage;
