// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { toast } from 'react-toastify';

import { useCustomerConfig, useUser } from '@store';
import { Dealer_Viewable_Driver_Info, Moves, useUpdateMoveDetailsMutation } from '@gql/schema';

import { css } from '@emotion/css';
import { Theme, useTheme, Paper, Typography } from '@mui/material';

import Divider from '@components/Divider';
import DriverTracker from '@components/DriverTracker/DriverTracker';
import MoveStatusTracker from '@components/MoveStatusTracker';
import Spacer from '@components/Utils/Spacer';

import MoveDetailsToolbar from '@features/moves/components/MoveDetailsToolbar';
import MoveDetailsInfo from '@features/moves/components/MoveDetailsInfo';
import MoveDetailsEmailNotifications from '@features/moves/components/MoveDetailsEmailNotifications';
import MoveDetailsLocation from '@features/moves/components/MoveDetailsLocation';
import MoveDetailsTimeline from '@features/moves/components/MoveDetailsTimeline';
import MoveDetailsImages from '@features/moves/components/MoveDetailsImages';
import MoveDetailsRelated from '@features/moves/components/MoveDetailsRelated';

// HELPERS ---------------------------------------------------------------- //

const getRelatedMoves = (move?: Moves) => {
  const relatedMoves = [];

  const childMoves = move?.childMoves?.filter(cm => cm?.move_type === `drive`);
  if (childMoves?.length) childMoves?.forEach(cm => relatedMoves.push(cm));

  const parentMove = move?.parentMove;
  if (parentMove) relatedMoves.push(parentMove);

  return relatedMoves;
};

// TYPES ---------------------------------------------------------------- //

interface MoveDetailsContentProps {
  move?: Moves;
  driver?: Dealer_Viewable_Driver_Info;
}

// COMPONENT ---------------------------------------------------------------- //

const MoveDetailsContent = ({ move, driver }: MoveDetailsContentProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const user = useUser();
  const customerConfig = useCustomerConfig();
  const relatedMoves = getRelatedMoves(move);

  // Mutations
  const [updateMove] = useUpdateMoveDetailsMutation();

  // Editing State
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [editContact, setEditContact] = React.useState<string>(move?.dealer_contact || ``);
  const [editNotes, setEditNotes] = React.useState<string>(move?.move_details || ``);
  const [editEmails, setEditEmails] = React.useState<string[]>(move?.config?.emailsToNotify || []);

  /** Handle saving edits to the move */
  const handleSaveEdits = async () => {
    try {
      const variables = {
        moveId: move?.id,
        contact: editContact || null,
        notes: editNotes || null,
        config: {
          ...move?.config,
        },
        updatedBy: user?.profile?.email || `dealer`,
      };

      if (editEmails?.length) {
        variables.config.emailsToNotify = editEmails || [];
      } else {
        delete variables.config.emailsToNotify;
      }

      if (Object.keys(variables?.config).length === 0) {
        delete variables.config;
      }

      const res = await updateMove({ variables });
      if (res?.data?.update_moves?.affected_rows) {
        toast.success(`Changes saved!`, {
          toastId: `move-details-save`,
        });
      }
    } catch (err) {
      console.error(`Failed to save move edits:`, err);
      toast.error(`Something went wrong when saving your edits! If this problem persists, please contact us.`, {
        toastId: `move-details-save`,
      });
    }

    setIsEditing(false);
  };

  /** Handle clearing edits to the move */
  const handleClearEdits = () => {
    setEditContact(move?.dealer_contact || ``);
    setEditNotes(move?.move_details || ``);
    setEditEmails(move?.config?.emailsToNotify || []);
    setIsEditing(false);
  };

  // Render the move details page
  return (
    <div className={cls.moveDetails}>
      {/* MOVE TOOLBAR */}
      <MoveDetailsToolbar
        move={move}
        isEditing={isEditing}
        onEdit={setIsEditing}
        onSave={handleSaveEdits}
        onDiscard={handleClearEdits}
      />

      {/* NO MOVE FOUND */}
      {!move ? (
        <Paper variant='custom' sx={{ padding: `16px`, textAlign: `center` }}>
          <Typography
            variant='h5'
            sx={{
              fontWeight: 600,
              color: theme.palette.text.disabled,
            }}
          >
            NO MOVE RECORD FOUND
          </Typography>
        </Paper>
      ) : null}

      {/* MOVE FOUND */}
      {move ? (
        <>
          {/* MOVE STATUS */}
          <div style={{ marginBottom: `24px` }}>
            <MoveStatusTracker move={move} size='lg' />
          </div>

          {/* MOVE INFO */}
          <MoveDetailsInfo
            move={move}
            driver={driver}
            isEditing={isEditing}
            editContact={editContact}
            editNotes={editNotes}
            setEditContact={setEditContact}
            setEditNotes={setEditNotes}
          />

          {/* EMAIL NOTIFICATIONS */}
          {customerConfig?.allow_email_notifications ? (
            <>
              <Divider
                spacer
                mb={6}
                tip={`View the email addresses that will receive pickup and delivery notifications for this move.`}
              >
                Email Notifications
              </Divider>

              <MoveDetailsEmailNotifications
                move={move}
                isEditing={isEditing}
                editEmails={editEmails}
                setEditEmails={setEditEmails}
              />
            </>
          ) : null}

          {/* LANE & LOCATIONS */}
          <Divider spacer mb={6} tip='View the pickup & delivery this move is associated with.'>
            Pickup & Delivery
          </Divider>

          <MoveDetailsTimeline move={move} />

          <Spacer size={1} />

          <div className={cls.flex}>
            <MoveDetailsLocation move={move} type='pickup' />
            <MoveDetailsLocation move={move} type='delivery' />
          </div>

          {/* TRACKING */}
          {!move?.cancel_status && (move?.status?.includes(`pickup`) || move?.status?.includes(`delivery`)) ? (
            <>
              <Divider spacer mb={6} tip='Track where the driver has been.'>
                Tracking
              </Divider>

              <DriverTracker move={move} />
            </>
          ) : null}

          {/* IMAGES */}
          {move?.pickup_photos?.length || move?.delivery_photos?.length ? (
            <>
              <Divider spacer mb={6} tip='View images from the pickup and delivery.'>
                Images
              </Divider>

              <MoveDetailsImages move={move} />
            </>
          ) : null}

          {/* RELATED MOVES */}
          {relatedMoves?.length ? (
            <>
              <Divider spacer mb={6} tip='View any moves related to this move.'>
                Related Moves
              </Divider>

              <MoveDetailsRelated move={move} relatedMoves={relatedMoves} />
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme, props?: object) => ({
  moveDetails: css`
    position: relative;
  `,

  flex: css`
    display: flex;
    gap: 16px;
    ${theme?.breakpoints?.down(`md`)} {
      flex-direction: column;
    }
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default MoveDetailsContent;
