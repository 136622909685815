import { Alert, AlertTitle, useTheme } from '@mui/material';

interface DefaultFallbackProps {
  fullscreen?: boolean;
  severity?: 'info' | 'error' | 'warning' | 'success';
  message?: string | undefined;
}

const DefaultFallback = ({ message, fullscreen = false, severity = 'info' }: DefaultFallbackProps) => {
  const theme = useTheme();

  if (fullscreen)
    return (
      <Alert
        severity={severity || undefined}
        sx={{
          borderTop: `1px solid ${severity ? theme?.palette?.[severity]?.main : theme?.palette?.utility?.main}`,
          borderBottom: `1px solid ${severity ? theme?.palette?.[severity]?.main : theme?.palette?.utility?.main}`,
          borderRadius: 0,
        }}
      >
        <AlertTitle>{message || `No data found.`}</AlertTitle>
      </Alert>
    );

  return (
    <Alert
      severity={severity}
      sx={{ border: `1px solid ${severity ? theme?.palette?.[severity]?.main : theme?.palette?.utility?.main}` }}
    >
      <AlertTitle>{message || `No data found.`}</AlertTitle>
    </Alert>
  );
};

export default DefaultFallback;
