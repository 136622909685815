//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Container, Grid2 as Grid, SxProps, Theme, Typography, useTheme } from '@mui/material';
import Spacer from '@components/Utils/Spacer';
import { useUserDetails } from '@hooks/useUserDetails';
import UserAddEditForm from './UserAddEditForm';
import { Users } from '@gql/schema';
import { css } from '@emotion/css';
import { getCleansedPhoneNumber } from '@services/formatService';
import AllowedCustomersTable from './AllowedCustomersTable';
const titleGridWidth = 5;

//////////////////////// COMPONENT ////////////////////////

export default function UserDetailsInfo({ user }: { user: Users }) {
  const theme = useTheme();
  const cls = useStyles();
  const sxStyles = useSxStyles(theme);
  const ctx = useUserDetails();

  const [defaultCustomerDisplay, setDefaultCustomerDisplay] = React.useState('');

  React.useEffect(() => {
    if (user?.customer) {
      setDefaultCustomerDisplay(user?.customer?.name || '');
    }
  }, [user]);

  return (
    <>
      {ctx.editMode ? (
        <UserAddEditForm />
      ) : (
        <Container maxWidth='lg'>
          {/* VITALS */}
          <Grid container flexWrap='nowrap'>
            <Grid size={{ xs: titleGridWidth }} sx={sxStyles.sectionL}>
              <Typography sx={sxStyles.sectionTitleTxt}>
                Display Name <span className={cls.sectionRequiredTxt}>*</span>
              </Typography>
            </Grid>

            <Grid>
              <div className={cls.sectionLine} />
            </Grid>

            <Grid size='grow' sx={sxStyles.sectionR}>
              <Typography>{ctx.displayName}</Typography>
            </Grid>
          </Grid>

          <Grid container wrap='nowrap'>
            <Grid size={{ xs: titleGridWidth }} sx={sxStyles.sectionL}>
              <Typography sx={sxStyles.sectionTitleTxt}>
                Email <span className={cls.sectionRequiredTxt}>*</span>
              </Typography>
            </Grid>

            <Grid>
              <div className={cls.sectionLine} />
            </Grid>

            <Grid size='grow' sx={sxStyles.sectionR}>
              <Typography sx={sxStyles.contactLink}>
                <a href={`mailto:${ctx.email || null}`}>{ctx.email || null}</a>
              </Typography>
            </Grid>
          </Grid>

          <Grid container wrap='nowrap'>
            <Grid size={{ xs: titleGridWidth }} sx={sxStyles.sectionL}>
              <Typography sx={sxStyles.sectionTitleTxt}>Phone (Optional)</Typography>
            </Grid>

            <Grid>
              <div className={cls.sectionLine} />
            </Grid>

            <Grid size='grow' sx={sxStyles.sectionR}>
              <Typography sx={sxStyles.contactLink}>
                <a href={`tel:${ctx.phone || null}`}>{getCleansedPhoneNumber(ctx.phone || null)}</a>
              </Typography>
            </Grid>
          </Grid>

          {/* <Grid container wrap='nowrap'>
        <Grid size={{ xs: titleGridWidth }} sx={sxStyles.sectionL}>
          <Typography sx={sxStyles.sectionTitleTxt}>Avatar (Optional)</Typography>
        </Grid>

        <Grid>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid size='grow' sx={sxStyles.sectionR}>
            {ctx.avatarUrl ? (
                <CardMedia
                  component='img'
                  alt='User profile picture'
                  style={{ width: '25%' }}
                  image={ctx.avatarUrl}
                  title=''
                />
              ) : null}
        </Grid>
      </Grid> */}

          {/* DEFAULT CUSTOMER */}

          <Grid container wrap='nowrap'>
            <Grid size={{ xs: titleGridWidth }} sx={sxStyles.sectionL}>
              <Typography sx={sxStyles.sectionTitleTxt}>
                Default Market <span className={cls.sectionRequiredTxt}>*</span>
              </Typography>
              <Spacer />
              <Typography sx={sxStyles.sectionSubtitleTxt}>
                The main market with which the user is associated. This field is required for all dealer users. If you
                are creating a user with the dealer super admin role, they will receive permissions for all markets
                within the organization. If you are creating a dealer admin user, you will be prompted to add additional
                markets below.
              </Typography>
            </Grid>

            <Grid>
              <div className={cls.sectionLine} />
            </Grid>

            <Grid size='grow' sx={sxStyles.sectionR}>
              <Typography sx={sxStyles.link}>{defaultCustomerDisplay}</Typography>
            </Grid>
          </Grid>

          {/* ROLE */}

          <Grid container flexWrap='nowrap'>
            <Grid size={{ xs: titleGridWidth }} sx={sxStyles.sectionL}>
              <Typography sx={sxStyles.sectionTitleTxt}>
                User Role <span className={cls.sectionRequiredTxt}>*</span>
              </Typography>
              <Spacer />

              <Typography sx={sxStyles.sectionSubtitleTxt}>
                Select a default role for the user, which will determine their level of access to the customer or
                organization's markets.
              </Typography>
              <Spacer />
              <Typography sx={sxStyles.sectionSubtitleTxt}>
                <b>Super Admin</b> - This user will have full, automatic management access to all markets within your
                organization and features of this site. They can add new super admins, admins, and users.
              </Typography>
              <Spacer />
              <Typography sx={sxStyles.sectionSubtitleTxt}>
                <b>Admin</b> - This user will have access to the subset of markets within your organization that you
                will be prompted to specify. They cannot create new users, but they can access all other configured
                features of this site.
              </Typography>
              <Spacer />
              <Typography sx={sxStyles.sectionSubtitleTxt}>
                <b>User</b> - This user can create and manage moves for their assigned market.
              </Typography>
            </Grid>

            <Grid>
              <div className={cls.sectionLine} />
            </Grid>

            <Grid size='grow' sx={sxStyles.sectionR}>
              <Typography>
                {user.default_role && user.default_role === 'dealer-super-admin'
                  ? 'Super Admin'
                  : user.default_role && user.default_role === 'dealer'
                    ? 'User'
                    : user.default_role && user.default_role === 'dealer-admin'
                      ? 'Admin'
                      : null}
              </Typography>
            </Grid>
          </Grid>

          {/* ALLOWED CUSTOMERS */}

          {ctx?.role && ctx?.role === 'dealer-admin' && (
            <Grid container flexWrap='nowrap'>
              <Grid size={{ xs: titleGridWidth }} sx={sxStyles.sectionL}>
                <Typography sx={sxStyles.sectionTitleTxt}>
                  Allowed Markets <span className={cls.sectionRequiredTxt}>*</span>
                </Typography>
                <Spacer />
                <Typography sx={sxStyles.sectionSubtitleTxt}>
                  Dealer admin users must be given access to at least one market in addition to their default.
                </Typography>
              </Grid>

              <Grid>
                <div className={cls.sectionLine} />
              </Grid>

              <Grid size='grow' sx={sxStyles.sectionR}>
                <AllowedCustomersTable />
              </Grid>
            </Grid>
          )}
        </Container>
      )}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  sectionL: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionR: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionTitleTxt: {
    fontSize: 16,
    fontWeight: 500,
  },
  sectionRequiredTxt: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.error.main,
  },
  sectionSubtitleTxt: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  sectionLine: {
    width: 1,
    height: '100%',
    background: theme.palette.divider,
  },
  contactLink: {
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      textTransform: 'none',
      '&:hover': {
        textDecoration: 'underline',
        backgroundColor: 'transparent',
      },
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    textTransform: 'none',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
});

const useStyles = () => ({
  sectionLine: css`
    width: 1,
    height: '100%',
    background: #00000016,
  `,
  sectionRequiredTxt: css`
    fontSize: 16,
    fontWeight: 500,
    color: #ff2050,
  `,
});
