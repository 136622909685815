//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { css } from '@emotion/css';
import { toast } from 'react-toastify';
import {
  useTheme,
  Theme,
  TableRow,
  TableCell,
  Grid2 as Grid,
  Tooltip,
  Icon,
  Collapse,
  Typography,
} from '@mui/material';
interface UploadedTableRowProps {
  row: any;
  errors: any[];
  index: number;
}

type Cell = {
  value: any;
  align: 'left' | 'right' | 'center' | 'justify' | 'inherit' | undefined;
  onClick: () => void;
};

//////////////////////// COMPONENT ////////////////////////

const UploadedTableRow: React.FC<UploadedTableRowProps> = props => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sxStyles = useSxStyles(theme);

  const { row, errors, index } = props;

  const [collapse, setCollapse] = React.useState(false);

  // Get the keys and values
  const rowKeys = Object.keys(row);
  const rowValues = Object.values(row);
  const success = row.success === true ? true : row.success === false ? false : null;

  // Set a list of cells to render the row
  const rowCells = rowKeys.map((_key, i) => {
    return {
      value: rowValues[i],
      align: `left`,
      onClick: () => setCollapse(success === false ? !collapse : false),
    } as Cell;
  });

  /** Copy a string to the clipboard */
  const copyToClipboard = (str: string = '', useToast = true) => {
    try {
      if (str) {
        navigator.clipboard.writeText(str);
        if (useToast) toast.info(`Copied to clipboard: "${str}"`, { autoClose: 2500 });
      } else if (useToast) toast.warning(`No text was found to copy!`);
    } catch (err) {
      if (useToast) toast.error(`Failed to copy text!`);
      console.error(`Failed to copy text:`, err);
    }
  };

  // Get the display className based on a number of factors
  const getRowClassName = () => {
    // if (collapse && success === true) return cls.rowSuccessActive;
    if (collapse && success === false) return sxStyles.rowErrorActive;
    return index % 2 ? sxStyles.rowEven : sxStyles.rowOdd;
  };

  // Get the display className based on a number of factors
  const getCellClassName = () => {
    if (success === true) {
      // if (collapse) return cls.cellActive;
      return sxStyles.cellSuccess;
    }
    if (success === false) {
      if (collapse) return sxStyles.cellActive;
      return sxStyles.cellError;
    }
    return sxStyles.cell;
  };

  const getCellRowId = (id: number) => {
    return (
      <Grid container alignItems='center'>
        {success === false ? (
          <Grid>
            <Icon
              sx={sxStyles.iconCollapse}
              style={{ color: collapse ? theme.palette.primary.contrastText : theme.palette.error.main }}
            >
              {collapse ? `expand_less` : `expand_more`}
            </Icon>
          </Grid>
        ) : null}

        <Grid>
          <Typography sx={sxStyles.rowTxt}>{id}</Typography>
        </Grid>
      </Grid>
    );
  };

  const getCellMoveIds = (ids: number[]) => {
    return (
      <>
        {ids && Array.isArray(ids) && ids.length > 0
          ? ids.map((id, i) => (
              <Grid key={`csv-move-id-${i}`} container alignItems='center' flexWrap='nowrap'>
                <Grid>
                  <Typography sx={sxStyles.rowTxt}>{id}</Typography>
                </Grid>
                <Grid>
                  <Icon sx={sxStyles.iconCopy} onClick={() => copyToClipboard(`${id}`)}>
                    content_copy
                  </Icon>
                </Grid>
              </Grid>
            ))
          : null}
      </>
    );
  };

  const getCellSuccessIcon = () => {
    if (success === true)
      return (
        <Tooltip title='This move passed through our system successfully!'>
          <Icon sx={sxStyles.iconSuccess}>check_circle</Icon>
        </Tooltip>
      );
    if (success === false)
      return (
        <Tooltip title='This move failed to pass through our system!'>
          <Icon sx={sxStyles.iconError}>report</Icon>
        </Tooltip>
      );
    return (
      <Tooltip title='This move has not yet been run through our system.'>
        <Icon sx={sxStyles.iconNeutral}>remove_circle_outline</Icon>
      </Tooltip>
    );
  };

  const getCellRender = (cell: any, i: number) => {
    if (i === 0) return getCellRowId(cell.value);
    if (i === 1) return getCellMoveIds([cell.value]);
    if (i === 2) return getCellSuccessIcon();
    return cell.value || `-`;
  };

  return (
    <>
      <TableRow sx={getRowClassName()}>
        {rowCells && rowCells.length > 0
          ? rowCells.map((cell, i) => {
              return (
                <TableCell
                  key={`atr-row-${index}-col-${i}`}
                  sx={getCellClassName()}
                  align={cell.align || `left`}
                  onClick={cell.onClick || null}
                >
                  {getCellRender(cell, i)}
                </TableCell>
              );
            })
          : null}
      </TableRow>

      {errors && errors.length > 0 ? (
        <TableRow>
          <TableCell
            style={{
              borderBottom: collapse ? `1px solid ${theme.palette.divider}` : 0,
            }}
            align='left'
            padding='none'
            colSpan={rowCells.length}
          >
            <Collapse in={collapse} timeout='auto'>
              <div className={styles.collapseErrors}>
                {errors.map((err, i) => (
                  <Typography key={`csv-error-${err.index}-${i}`} sx={sxStyles.collapseTxt}>
                    &#8226; {err.message}
                  </Typography>
                ))}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};

export default UploadedTableRow;

//////////////////////// STYLES ////////////////////////

const useStyles = (theme: Theme) => {
  return {
    collapseErrors: css`
      padding: ${theme.spacing(2)};
      background: ${theme.palette.error.light};
    `,
  };
};

const useSxStyles = (theme: Theme) => ({
  rowOdd: {
    background: theme.palette.background.paper,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    transition: '0.1s',
  },
  rowEven: {
    background: '#f8f8f8',
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    transition: '0.1s',
  },
  rowSuccessActive: {
    background: theme.palette.success.main,
    '& $iconSuccess': {
      color: theme.palette.text.primary,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
  rowErrorActive: {
    background: theme.palette.error.main,
    '& $iconError': {
      color: theme.palette.text.primary,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },

  cell: {
    color: theme.palette.text.primary,
  },
  cellSuccess: {
    color: theme.palette.success.main,
  },
  cellError: {
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
  cellActive: {
    color: theme.palette.text.primary,
    cursor: 'pointer',
  },

  iconSuccess: {
    display: 'block',
    marginTop: 1,
    color: theme.palette.success.main,
  },
  iconError: {
    display: 'block',
    marginTop: 1,
    color: theme.palette.error.main,
  },
  iconNeutral: {
    display: 'block',
    marginTop: 1,
    color: theme.palette.text.disabled,
  },

  iconCollapse: {
    display: 'block',
    marginTop: -2,
    marginLeft: -6,
    marginRight: 4,
  },

  iconCopy: {
    display: 'block',
    marginTop: -2,
    marginLeft: 4,
    fontSize: 16,
    cursor: 'pointer',
  },

  collapseErrors: {
    padding: theme.spacing(2),
    background: theme.palette.error.light,
  },
  collapseTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.error.main,
  },
  rowTxt: {
    lineHeight: 1.43,
    fontSize: 14,
    fontWeight: 500,
  },
});
