import React from 'react';
import { css } from '@emotion/css';
import { Button, Container, Theme, Typography, SxProps, useTheme } from '@mui/material';

const AppraisalsNoAccess: React.FC = () => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sxStyles = useSxStyles(theme);

  return (
    <div className={styles.restricted}>
      <Container maxWidth='md'>
        <Typography sx={sxStyles.restrictedText}>
          Welcome to the new HopDrive appraisal feature. This revolutionary tool will turn your Service Lane into an
          Appraisal Drive and help you buy more cars from your customers! If you are interested in seeing a demo, please
          email sales@hopdrive.com to set up some time.
        </Typography>
        <a href='mailto:sales@hopdrive.com?cc=sales@hopdrive.com&subject=Appraisals%20-%20Demo%20Request'>
          <Button sx={sxStyles.restrictedButton} color='primary' variant='contained' size='large'>
            Request Demo
          </Button>
        </a>
      </Container>
    </div>
  );
};
export default AppraisalsNoAccess;

const useStyles = (theme: Theme) => {
  return {
    restricted: css`
      width: 100%;
      padding-top: ${theme.spacing(4)};
      text-align: center;
    `,
  };
};
const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  restrictedText: {
    lineHeight: 1.333,
    fontSize: 18,
    fontWeight: 400,
  },
  restrictedButton: {
    marginTop: theme.spacing(3),
    color: '#fff',
    boxShadow: 'none',
    '&:hover, &:active': {
      boxShadow: 'none',
    },
    transition: '0.2s',
  },
});
