import React from 'react';
import { Typography, useTheme, Theme, Button, Box, Container } from '@mui/material';
import { css } from '@emotion/css';

interface DefaultErrorFallbackProps {
  message?: string | undefined;
  paper?: boolean;
  error?: Error | undefined;
  resetError?: () => void | undefined;
}

const DefaultErrorFallback: React.FC<DefaultErrorFallbackProps> = ({
  message,
  paper = false,
  error,
  resetError,
}: DefaultErrorFallbackProps) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const handleReset = () => {
    // Clear any error-related state or cached data if needed
    // Reload any necessary data
    if (resetError) {
      resetError();
    } else {
      // Fallback to page reload if no reset function provided
      window.location.reload();
    }
  };

  if (paper) {
    return (
      <div className={styles.notifyBoxPaper}>
        <Typography className={styles.notifyText}>{message || `AN ERROR OCCURRED`}</Typography>
        {resetError && (
          <Button onClick={handleReset} sx={{ marginTop: 4 }}>
            Try again
          </Button>
        )}
      </div>
    );
  }
  return (
    <Container maxWidth='lg'>
      <Box sx={{ marginTop: 5, height: '100vh', padding: 10 }}>
        <Typography variant='h4' className={styles.notifyText} sx={{ marginBottom: 4 }}>
          {message || `AN UNEXPECTED ERROR OCCURRED`}
        </Typography>
        {/* <div className={styles.notifyBoxPaper}>
          {error?.message}

          <Typography component={'pre'} fontFamily={'Courier New'}>
            {JSON.stringify(error?.stack, null, 2)}
          </Typography>
        </div> */}
        <Button onClick={handleReset} sx={{ marginTop: 4 }}>
          Try again
        </Button>
      </Box>
    </Container>
  );
};

export default DefaultErrorFallback;

const useStyles = (theme?: Theme) => ({
  notifyBox: css`
    position: relative;
    width: 100%;
    padding: ${theme?.spacing(4) || 32}px;
    border-bottom: 1px solid ${theme?.palette?.divider};
    margin-left: auto;
    margin-right: auto;
    background: ${theme?.palette?.background?.paper};
  `,
  notifyBoxPaper: css`
    position: relative;
    width: 100%;
    padding: ${theme?.spacing(4)};
    border-radius: ${theme?.shape?.borderRadius};
    background: ${theme?.palette?.background?.paper};
    box-shadow: ${theme?.shadows[3] || '0px 4px 4px rgba(0, 0, 0, 0.25)'};
  `,
  notifyText: css`
    color: ${theme?.palette?.error?.main};
    line-height: 1.25;
    text-align: center;
    text-transform: uppercase;
    font-size: 21px;
    font-weight: 500;
    ${theme?.breakpoints.down('sm')} {
      font-size: 18px;
    }
    ${theme?.breakpoints.down('xs')} {
      font-size: 16px;
    }
  `,
});
