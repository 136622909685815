// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import { useUser } from '@store';
import { Moves, useRequestMoveTimeChangeMutation } from '@gql/schema';

import { css } from '@emotion/css';
import { Theme, useTheme, TextField, Typography } from '@mui/material';
import { BiSolidCalendarExclamation } from 'react-icons/bi';

import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '@components/Modal';

// TYPES ---------------------------------------------------------------- //

interface MoveTimeModalProps {
  open: boolean;
  onClose?: () => void;
  moveInput?: Moves;
}

// COMPONENT ---------------------------------------------------------------- //

const MoveTimeModal = ({ open, onClose, moveInput }: MoveTimeModalProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const user = useUser();

  const [requestMoveTimeChangeMutation] = useRequestMoveTimeChangeMutation();

  const isConcierge = moveInput?.consumer_type ? true : false;

  const [myDate, setMyDate] = React.useState<string>(``);
  const [myTime, setMyTime] = React.useState<string>(``);
  const [time, setTime] = React.useState<string>(``);

  React.useEffect(() => {
    if (moveInput) {
      setMyDate(dayjs(moveInput?.ready_by).format(`YYYY-MM-DD`) || ``);
      setMyTime(dayjs(moveInput?.ready_by).format(`HH:mm`) || ``);
    }
  }, [moveInput]);

  // Set the time state when date or time changes
  React.useEffect(() => {
    if (open && myDate && myTime) {
      const currentTime = dayjs();
      const selectedTime = dayjs(`${myDate}T${myTime}`);
      
      // Convert moveInput.ready_by to local time for proper comparison
      const existingReadyBy = moveInput?.ready_by ? dayjs(moveInput.ready_by).local() : null;

      //If the time input has been changed, make sure it's at least 90 minutes from now
      if (existingReadyBy?.format() !== selectedTime.format() && selectedTime.isBefore(currentTime.add(90, `minute`))) {
        toast.warning(`Time was adjusted to be at least 90 minutes from now.`, { toastId: `move-details-time-change` });
        setMyDate(currentTime.add(90, `minute`).format(`YYYY-MM-DD`));
        setMyTime(currentTime.add(90, `minute`).format(`HH:mm`));
        return;
      }

      // Otherwise, set the time
      if (setTime) setTime(selectedTime.utc().format());
    } else {
      if (setTime) setTime(``);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, myDate, myTime]);

  const handleSubmit = async () => {
    // Insert move update eventlog
    // if (input.pickupTime !== newPickupTime ){
    //   let metadataText = {oldPickupTime: `${input.pickupTime}`, newPickupTime: `${newPickupTime}`, oldReadyTime: `${input.readyTime}`, newReadyTime: `${newPickupTime}`}
    //   try {
    //     let eventConfig = {
    //       action: `move.times.updated`,
    //       user: userName,
    //       role: userRole,
    //       move_id: input.move.id,
    //       customer_id: input.move.customer.id,
    //       lane_id: input.move.lane.id,
    //       driver_id: input.move.driver_id || null,
    //       metadata: metadataText || null
    //     };
    //     let logRes = await sdk.events.buildAndCreate(eventConfig);
    //     console.log(`Successfully inserted eventlog ${logRes.id}`);
    //   }  catch (err) {
    //     //Can't return bc other functions need to run
    //     console.error(err);
    //   }
    // }

    try {
      const variables = {
        moveId: moveInput?.id,
        readyByTime: time || null,
        updatedBy: user?.profile?.email,
      };

      const res = await requestMoveTimeChangeMutation({ variables });
      if (res?.data?.update_moves?.affected_rows) {
        toast.success(`Successfully updated ready time!`);
        handleClose();
      }
    } catch (err) {
      console.error(`Failed to update ready time:`, err);
      toast.error(`Failed to update ready time!`);
    }
  };

  const handleClose = () => {
    setMyDate(dayjs(moveInput?.ready_by).format(`YYYY-MM-DD`) || ``);
    setMyTime(dayjs(moveInput?.ready_by).format(`HH:mm`) || ``);
    if (onClose) onClose();
  };

  return (
    <>
      <Modal open={open}>
        <ModalHeader handleClose={handleClose}>Change Start Time</ModalHeader>

        <ModalContent>
          <div className={cls.time}>
            <div className={cls.title}>
              <BiSolidCalendarExclamation className={cls.titleIcon} />

              <Typography variant='body2' sx={{ color: theme?.palette?.text?.secondary }}>
                {isConcierge
                  ? `Select the date & time of the appointment with your customer. Timezone is local to your machine. Must be at least 90 minutes in the future.`
                  : `Select the date & time your vehicle(s) will be ready for transport. Timezone is local to your machine. Must be at least 90 minutes in the future. NOTE: This is not an appointment time, but an estimate so we can determine when to dispatch a driver to complete the move. The pickup time may vary based on volume and driver availability.`}
              </Typography>
            </div>

            <div className={cls.flex}>
              <div className={cls.flexItem}>
                <label className={cls.textFieldLabel} htmlFor='date-select'>
                  {isConcierge ? `Appointment Date` : `Ready By Date`}
                  <span className={cls.textFieldLabelRequired}> *</span>
                </label>

                <TextField
                  fullWidth
                  required
                  name='date-select'
                  type='date'
                  placeholder='Select a date...'
                  value={myDate || ``}
                  onChange={e => setMyDate(e?.target?.value)}
                  slotProps={{
                    htmlInput: {
                      min: dayjs().format(`YYYY-MM-DD`),
                    },
                  }}
                />
              </div>

              <div className={cls.flexItem}>
                <label className={cls.textFieldLabel} htmlFor='time-select'>
                  {isConcierge ? `Appointment Time` : `Ready By Time`}
                  <span className={cls.textFieldLabelRequired}> *</span>
                </label>

                <TextField
                  fullWidth
                  required
                  name='time-select'
                  type='time'
                  placeholder='Select a time...'
                  value={myTime || ``}
                  onChange={e => setMyTime(e?.target?.value)}
                  slotProps={{
                    htmlInput: {
                      min:
                        myDate <= dayjs().add(90, `minute`).format(`YYYY-MM-DD`)
                          ? dayjs().add(90, `minute`).format(`HH:mm`)
                          : `00:00`,
                      step: 300, // 5 minutes
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </ModalContent>

        <ModalFooter>
          <ModalAction disabled={!time || time === `Invalid Date`} onClick={() => handleSubmit()}>
            Confirm
          </ModalAction>
          <ModalAction onClick={() => handleClose()} color='utility'>
            Cancel
          </ModalAction>
        </ModalFooter>
      </Modal>
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  time: css`
    position: relative;
    /* text-align: left; */
  `,

  title: css`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px;
    border: 1px solid ${theme?.palette?.divider};
    border-radius: 10px;
    margin-bottom: 12px;
    background-color: ${theme?.palette?.action?.hover};
  `,
  titleIcon: css`
    min-width: 20px;
    min-height: 20px;
    margin-top: -2px;
    font-size: 20px;
    color: ${theme?.palette?.text?.secondary};
  `,

  textFieldLabel: css`
    display: block;
    margin-bottom: 2px;
    margin-left: 6px;
    font-size: 12px;
    font-weight: 600;
  `,
  textFieldLabelInvisible: css`
    display: block;
    margin-bottom: 2px;
    margin-left: 6px;
    font-size: 12px;
    font-weight: 600;
    color: transparent;
    user-select: none;
    ${theme?.breakpoints?.down('md')} {
      display: none;
    }
  `,
  textFieldLabelRequired: css`
    color: ${theme?.palette?.error?.main};
  `,

  flex: css`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    ${theme?.breakpoints?.down('md')} {
      flex-direction: column;
    }
  `,
  flexItem: css`
    flex: 2;
    width: 100%;
  `,
  flexItemSmall: css`
    flex: 1;
    width: 100%;
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default MoveTimeModal;
