import React from 'react';
import { css } from '@emotion/css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { Appraisals, Moves, useUpdateFavoriteAppraisalsMutation } from '@gql/schema';
import * as Sentry from '@sentry/react';

import { Grid2 as Grid, IconButton, Icon, Tooltip, Typography, useTheme, Theme } from '@mui/material';
import { getCleansedPhoneNumber } from '@services/formatService';

const log = false;

interface AppraisalDetailsHeaderProps {
  appraisal: Appraisals;
  move: Moves;
  mileage: number;
}

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
const AppraisalDetailsHeader: React.FC<AppraisalDetailsHeaderProps> = props => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sxStyles = useSxStyles(theme);
  const navigate = useNavigate();

  const { appraisal, move, mileage } = props;

  const [favoriteAppraisalUpdate] = useUpdateFavoriteAppraisalsMutation();

  // Back to previous page
  const goBack = () => {
    navigate(-1);
  };

  // Get the inline vehicle text
  const getVehicle = () => {
    let vehicleName = null;

    if (move.vehicle_year && move.vehicle_make && move.vehicle_model)
      vehicleName = `${move.vehicle_year} ${move.vehicle_make} ${move.vehicle_model}`;
    else if (move.vehicle_make && move.vehicle_model) vehicleName = `${move.vehicle_make} ${move.vehicle_model}`;

    return vehicleName;
  };

  // Called when the "FAVORITE APPRAISAL" button is clicked
  const favoriteAppraisal = async (appraisalId: number | null, isFavorite: boolean) => {
    if (appraisalId) {
      try {
        const res = await favoriteAppraisalUpdate({
          variables: { id: appraisalId, favorite: isFavorite ? false : true },
        });
        if (
          res?.data?.update_appraisals &&
          res?.data?.update_appraisals?.returning &&
          res?.data?.update_appraisals?.returning?.length > 0
        ) {
          const favRes = res.data.update_appraisals.returning[0];
          log && console.log(`FAVORITED Appraisal #${favRes.id}`);
          //   refetch();
        } else {
          console.error(`Failed to favorite appraisal record!`);
          toast.error(`Failed to favorite appraisal record!`);
        }
      } catch (err) {
        Sentry.captureException(err);
        console.error(`Error favoriting appraisal record:`, err);
      }
    } else {
      console.error(`Appraisal record does not exist, cannot favorite!`);
      toast.error(`Please view the appraisal record before favoriting it!`);
    }
  };

  return (
    <>
      <div className={styles.bar}>
        <Grid
          container
          spacing={4}
          alignItems='center'
          wrap='nowrap'
          sx={{
            overflow: 'auto',
            minHeight: '65px',
            '& .MuiGrid-item': {
              paddingTop: '0 !important',
            },
          }}
        >
          <Grid>
            <Tooltip title='Back'>
              <IconButton onClick={() => goBack()}>
                <Icon>arrow_back</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title={appraisal.favorite ? `Unfavorite Appraisal` : `Favorite Appraisal`}>
              <IconButton
                sx={sxStyles.heartParent}
                onClick={() => favoriteAppraisal(appraisal?.id, appraisal.favorite)}
              >
                <Icon sx={appraisal.favorite ? sxStyles.heartActive : sxStyles.heart}>
                  {appraisal.favorite ? `favorite` : `favorite_border`}
                </Icon>
              </IconButton>
            </Tooltip>
          </Grid>
          {getVehicle() && (
            <Grid>
              <Typography sx={sxStyles.keyTxt}>Vehicle</Typography>
              <Typography sx={sxStyles.valTxt}>{getVehicle()}</Typography>
            </Grid>
          )}
          {appraisal.vin && (
            <Grid>
              <Typography sx={sxStyles.keyTxt}>VIN</Typography>
              <Typography sx={sxStyles.valTxt}>{appraisal.vin}</Typography>
            </Grid>
          )}
          {move.vehicle_odometer || parseInt(move.vehicle_odometer || '0') === 0 ? (
            <Grid>
              <Typography sx={sxStyles.keyTxt}>Mileage</Typography>
              <Typography sx={sxStyles.valTxt}>{mileage} mi</Typography>
            </Grid>
          ) : null}
          {move.consumer_name ? (
            <Grid>
              <Typography sx={sxStyles.keyTxt}>Customer&nbsp;Name</Typography>
              <Typography sx={sxStyles.valTxt}>{move.consumer_name}</Typography>
            </Grid>
          ) : null}
          {move.consumer_phone ? (
            <Grid>
              <Typography sx={sxStyles.keyTxt}>Customer&nbsp;Phone</Typography>
              <Typography sx={sxStyles.valTxt}>{getCleansedPhoneNumber(move.consumer_phone)}</Typography>
            </Grid>
          ) : null}
          <Grid size='grow' />
          {/* <Grid>
            <Tooltip title='Settings'>
              <IconButton>
                <Icon>settings</Icon>
              </IconButton>
            </Tooltip>
          </Grid> */}
        </Grid>
      </div>
    </>
  );
};

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = (theme: Theme) => {
  return {
    bar: css`
      position: relative;
      padding: ${theme.spacing(2)};
      background: #fff;
      box-shadow: 0 0 16px #00000032;
      overflow: auto;
      display: flex;
    `,
  };
};
const useSxStyles = (theme: Theme) => ({
  heartParent: {
    '&:hover $heart': {
      color: theme.palette.error.main,
    },
    '&:hover $heartActive': {
      color: theme.palette.error.light,
    },
  },
  heart: {
    transition: '0.2s',
  },
  heartActive: {
    color: theme.palette.error.main,
    transition: '0.2s',
  },
  keyTxt: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1,
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 500,
  },
  valTxt: {
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 500,
  },
});

//////////////////////////////////////// EXPORT ////////////////////////////////////////
export default AppraisalDetailsHeader;
