import dayjs from 'dayjs';
import { capEach } from '@services/formatService';

export const getFormattedRidesharePartner = (str: string | null) => {
  if (str) {
    const string = str.toLowerCase();
    if (string === `lyft` || string === `l`) return `Lyft`;
    if (string === `uber` || string === `u`) return `Uber`;
    if (string === `auto` || string === `a`) return `Auto`;
    if (string === `hopdrive` || string === `h` || string === `hd`) return `HopDrive`;
  }
  return null;
};

export const getFormattedCombinedStatusFromMove = (move: any | null) => {
  let fallbackStatus = `Unknown`;

  if (move) {
    fallbackStatus = move.move_type === `drive` ? `Not Assigned` : `Not Started`;
    const cancelStatus = move && move.cancel_status ? move.cancel_status.toLowerCase() : null;
    const status = move && move.status ? move.status.toLowerCase() : null;

    if (cancelStatus) {
      if (cancelStatus === `pending`) return `Pending Cancel`;
      if (cancelStatus === `seen`) return `Cancel Was Seen`;
      if (cancelStatus === `canceled`) return `Cancel Before Started`;
      if (cancelStatus === `started`) return `Cancel After Started`;
      if (cancelStatus === `delivered`) return `Cancel After Delivered`;
      return capEach(cancelStatus);
    }
    if (status) {
      if (status === `dispatched`) return `Dispatched`;
      if (status === `pickup started` || status === `pickup_started`) return `Pickup Started`;
      if (status === `pickup arrived` || status === `pickup_arrived`) return `Pickup Arrived`;
      if (status === `pickup successful` || status === `pickup_successful`) return `Pickup Successful`;
      if (status === `delivery started` || status === `delivery_started`) return `Delivery Started`;
      if (status === `delivery arrived` || status === `delivery_arrived`) return `Delivery Arrived`;
      if (status === `delivery successful` || status === `delivery_successful`) return `Delivery Successful`;
      if (status === `awaitingresponse` || status === `awaiting response` || status === `awaiting_response`)
        return `Awaiting Response`;
      if (status === `accepted`) return `Accepted`;
      if (status === `arrived`) return `Arrived`;
      if (status === `pickedup` || status === `picked up` || status === `picked_up`) return `Picked Up`;
      if (status === `droppedoff` || status === `dropped off` || status === `dropped_off`) return `Dropped Off`;
      if (status === `canceled`) return `Canceled`;
      if (status === `failed`) return `Failed`;
      return capEach(status);
    }
  }
  return fallbackStatus;
};

/** Get formatted status string from a drive */
export const getFormattedStatusFromDrive = (drive = null) => {
  // Default fallback status string when not enough info is provided
  let fallbackStatus = `Unknown`;

  // Check for drive
  if (drive) {
    // Set local variables
    fallbackStatus = `Not Assigned`;
    const status = drive && drive.status ? drive.status.toLowerCase() : null;

    // Check statuses and return a formatted string
    if (status) {
      if (status === `dispatched`) return `Dispatched`;
      if (status === `pickup started` || status === `pickup_started`) return `Pickup Started`;
      if (status === `pickup arrived` || status === `pickup_arrived`) return `Pickup Arrived`;
      if (status === `pickup successful` || status === `pickup_successful`) return `Pickup Successful`;
      if (status === `delivery started` || status === `delivery_started`) return `Delivery Started`;
      if (status === `delivery arrived` || status === `delivery_arrived`) return `Delivery Arrived`;
      if (status === `delivery successful` || status === `delivery_successful`) return `Delivery Successful`;
      if (status === `canceled`) return `Canceled`;
      if (status === `failed`) return `Failed`;
      return capEach(status);
    }
  }
  return fallbackStatus;
};

/** Get formatted cancel status string from a drive */
export const getFormattedCancelStatusFromDrive = (drive = null) => {
  // Default fallback status string when not enough info is provided
  let fallbackStatus = `Unknown`;

  // Check for drive
  if (drive) {
    // Set local variables
    fallbackStatus = `Not Canceled`;
    const cancelStatus = drive && drive.cancel_status ? drive.cancel_status.toLowerCase() : null;

    // Check statuses and return a formatted string
    if (cancelStatus) {
      if (cancelStatus === `pending`) return `Pending Cancel`;
      if (cancelStatus === `seen`) return `Cancel Was Seen`;
      if (cancelStatus === `canceled`) return `Cancel Before Started`;
      if (cancelStatus === `started`) return `Cancel After Started`;
      if (cancelStatus === `delivered`) return `Cancel After Delivered`;
      return capEach(cancelStatus);
    }
  }
  return fallbackStatus;
};

/** Get formatted status string from a ride */
export const getFormattedStatusFromRide = (ride = null) => {
  // Default fallback status string when not enough info is provided
  let fallbackStatus = `Unknown`;

  // Check for ride
  if (ride) {
    // Set local variables
    fallbackStatus = `Not Called`;
    const status = ride.status ? ride.status.toLowerCase() : null;

    // Check statuses and return a formatted string
    if (status) {
      if (status === `awaitingresponse` || status === `awaiting response` || status === `awaiting_response`)
        return `Awaiting Response`;
      if (status === `accepted`) return `Accepted`;
      if (status === `arrived`) return `Arrived`;
      if (status === `pickedup` || status === `picked up` || status === `picked_up`) return `Picked Up`;
      if (status === `droppedoff` || status === `dropped off` || status === `dropped_off`) return `Dropped Off`;
      if (status === `canceled`) return `Canceled`;
      if (status === `failed`) return `Failed`;
      return capEach(status);
    }
  }
  return fallbackStatus;
};

export const getFormattedStatusFromPlan = plan => {
  if (!plan || !plan.moves) return null;

  //Find first move whose delivery_time is not in the past
  let currentMove = plan.moves.find(m => dayjs(m.delivery_time).isAfter(dayjs()));
  if (currentMove) return getFormattedCombinedStatusFromMove(currentMove);
  return null;
};

/** Get formatted status string from a hangtag */
export const getFormattedStatusFromHangtag = (hangtag: any | null) => {
  // Default fallback status string when not enough info is provided
  let fallbackStatus = `Unknown`;

  // Check for hangtag
  if (hangtag) {
    // Set local variables
    fallbackStatus = `Unassigned`;
    const status = hangtag.status ? hangtag.status.toLowerCase() : null;
    const type = hangtag.type ? hangtag.type.toLowerCase() : null;

    // Check statuses and return a formatted string
    if (status && type === `concierge`) {
      if (status === `unassigned`) return `Unassigned`;
      if (status === `assigned`) return `Assigned`;
      if (status === `scanned`) return `Scanned`;
      if (status === `completed`) return `Completed`;
      return capEach(status);
    }
    if (status && type === `yard`) {
      if (status === `unassigned`) return `Unassigned`;
      if (status === `scanned`) return `Started`;
      if (status === `assigned`) return `Assigned`;
      if (status === `completed`) return `Staged`;
      return capEach(status);
    }
  }
  return fallbackStatus;
};
