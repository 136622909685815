import React from 'react';
import { useAuth } from '@features/auth/hooks/useAuth';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from '@apollolocal/client';
import MuiThemeProvider from '@muilocal/MuiThemeProvider';
import { APIProvider } from '@vis.gl/react-google-maps';
import Layout from '@features/layout/components/Layout';
import AppRoutes from './Routes';
import { ErrorBoundary } from 'react-error-boundary';
import DefaultErrorFallback from '@components/Fallbacks/DefaultErrorFallback';
const { VITE_GOOGLE_API_KEY } = import.meta.env;

const App: React.FC = () => {
  const { loading } = useAuth();
  
  return (
    <ErrorBoundary
      FallbackComponent={DefaultErrorFallback}
      onError={(error, info) => {
        // Log the error to your error reporting service here
        console.error('Uncaught error:', error, info);
      }}
    >
      <MuiThemeProvider>
        <BrowserRouter>
          <ApolloProvider client={client}>
            <APIProvider apiKey={VITE_GOOGLE_API_KEY}>
                <Layout>{!loading && <AppRoutes />}</Layout>
            </APIProvider>
          </ApolloProvider>
        </BrowserRouter>
      </MuiThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
