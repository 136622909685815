// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';

import { css } from '@emotion/css';
import { Theme, useTheme, Typography } from '@mui/material';

// TYPES ---------------------------------------------------------------- //

interface InfoItemProps {
  size?: `sm` | `md` | `lg`;
  keyText?: string;
  valueText?: string | number;
  valueTransform?: string;
}

// COMPONENT ---------------------------------------------------------------- //

const MoveDetailsInfoItem = ({ size, keyText, valueText, valueTransform }: InfoItemProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const getFontSize = () => {
    switch (size) {
      case `sm`:
        return `12px`;
      case `md`:
        return `14px`;
      case `lg`:
        return `16px`;
      default:
        return `14px`;
    }
  };

  const getLineMargin = () => {
    switch (size) {
      case `sm`:
        return `7px 0`;
      case `md`:
        return `9px 0`;
      case `lg`:
        return `11px 0`;
      default:
        return `9px 0`;
    }
  };

  return (
    <div className={cls.infoItem}>
      {keyText ? (
        <Typography
          variant='body2'
          sx={{ whiteSpace: `nowrap`, fontSize: getFontSize(), color: theme?.palette?.text?.disabled }}
        >
          {keyText}
        </Typography>
      ) : null}

      {keyText || valueText ? (
        <div className={cls.infoItemLine} style={{ margin: getLineMargin() }} />
      ) : (
        <Typography variant='body2' sx={{ visibility: `hidden`, whiteSpace: `nowrap`, fontSize: getFontSize() }}>
          ...
        </Typography>
      )}

      {valueText ? (
        <Typography variant='body2' sx={{ fontSize: getFontSize(), textTransform: valueTransform || `none` }}>
          {valueText}
        </Typography>
      ) : null}
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  infoItem: css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 6px;
    word-break: break-word;
  `,
  infoItemLine: css`
    flex: 1;
    border-top: 1px dashed ${theme?.palette?.action?.focus};
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default MoveDetailsInfoItem;
