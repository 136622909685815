import { useNavigate } from 'react-router-dom';
import {
  goToRoute,
  goToPreviousPage,
  goToNextPage,
  goToMovesIndex,
  goToLanesIndex,
  goToLocationsIndex,
  goToLocationDetails,
  goToMovePlanner,
  goToMoveDetails,
  goToInvoice,
  goToUserDetails,
} from '@services/navigationService';
import {
  condensedCase,
  capFirst,
  capEach,
  capAll,
  round,
  checkNeg,
  getMonetaryValue,
  getCleansedMonetaryValue,
  getCleansedPhoneNumber,
  clampNegNum,
} from '@services/formatService';
import {
  getFormattedRidesharePartner,
  getFormattedCombinedStatusFromMove,
  getFormattedStatusFromDrive,
  getFormattedCancelStatusFromDrive,
  getFormattedStatusFromRide,
  getFormattedStatusFromPlan,
  getFormattedStatusFromHangtag,
} from '@services/statusService';
import {
  getFormattedVehicleFromMove,
  checkMoveInProgress,
  checkMoveCompleted,
  getLatestTimestampFromMove,
  getDriverInitialsFromMove,
  getPickupOrDeliveryTimeFromMove,
} from '@services/moveService';
import {
  getFormattedTypeFromLocation,
  getLocationByIdFromList,
  getPendingAccessorial,
  getNameFromLocation,
  getDriveTypeFromMove,
} from '@services/locationService';
import { durationBetween, getDurationInMinutes, getMinutesBetween } from '@services/timeService';
import {
  getLastNDigits,
  getReadableText,
  getSnakeCase,
  getNonBreakingWord,
  getWorkflowData,
  getCancelHeaderFromMove,
  getCancelReasonFromMove,
  copyToClipboard,
  circularJSONStringify,
  getFirstNameFromDisplayName,
  getMiddleNameFromDisplayName,
  getLastNameFromDisplayName,
  getUserName,
  getInitialsFromName,
} from '@services/utilityService';

export function useTools() {
  const navigate = useNavigate();

  return {
    goToRoute: (route: string | null = null) => goToRoute(route, navigate),
    goToPreviousPage: () => goToPreviousPage(navigate),
    goToNextPage: () => goToNextPage(navigate),
    goToMovesIndex: () => goToMovesIndex(navigate),
    goToLanesIndex: () => goToLanesIndex(navigate),
    goToLocationsIndex: () => goToLocationsIndex(navigate),
    goToLocationDetails: (locationId: number) => goToLocationDetails(navigate, locationId),
    goToMovePlanner: () => goToMovePlanner(navigate),
    goToMoveDetails: (moveId: string | null  = null, edit = false) => goToMoveDetails(moveId, edit, navigate),
    goToInvoice: (invoiceId = null, customerId = null, foldId = null) =>
    goToInvoice(invoiceId, customerId, foldId, navigate),
    goToUserDetails: (userId = null) => goToUserDetails(userId, navigate),
    condensedCase,
    capFirst,
    capEach,
    capAll,
    round,
    checkNeg,
    getFormattedRidesharePartner,
    getFormattedCombinedStatusFromMove,
    getFormattedStatusFromDrive,
    getFormattedCancelStatusFromDrive,
    getFormattedStatusFromRide,
    getFormattedStatusFromPlan,
    getFormattedStatusFromHangtag,
    getFormattedVehicleFromMove,
    getInitialsFromName,
    checkMoveInProgress,
    checkMoveCompleted,
    getLatestTimestampFromMove,
    getDriverInitialsFromMove,
    getPickupOrDeliveryTimeFromMove,
    getFormattedTypeFromLocation,
    getLocationByIdFromList,
    getPendingAccessorial,
    getNameFromLocation,
    getDriveTypeFromMove,
    durationBetween,
    getDurationInMinutes,
    getMinutesBetween,
    getLastNDigits,
    getReadableText,
    getSnakeCase,
    getCleansedPhoneNumber,
    getNonBreakingWord,
    getWorkflowData,
    getCancelHeaderFromMove,
    getCancelReasonFromMove,
    copyToClipboard,
    circularJSONStringify,
    getFirstNameFromDisplayName,
    getMiddleNameFromDisplayName,
    getLastNameFromDisplayName,
    getUserName,
    getMonetaryValue,
    getCleansedMonetaryValue,
    clampNegNum,
  };
}
