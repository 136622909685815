import { useState, useEffect, Fragment } from 'react';
import DashboardMoveTile from './DashboardMoveTile';
import { Moves } from '@gql/schema';
import { Theme, useTheme, SxProps, Typography } from '@mui/material';
import Divide from '@components/Divider';
import BoldDivide from '@components/BoldDivide';
import { css } from '@emotion/css';
import { useDashboard } from '../providers/DashboardProvider';

interface FilteredMoves {
  activeMoves?: Moves[];
  plannedMoves?: Moves[];
  finishedMoves?: Moves[];
  searchResults?: Moves[];
}

interface DashboardGridProps {
  moves?: FilteredMoves;
}

interface GroupedCustomerMoves {
  id: number;
  name: string;
  moves: Moves[];
}

export default function DashboardGrid({ moves }: DashboardGridProps) {
  const theme = useTheme();
  const cls = useStyles(theme);
  const clsx = useSxStyles(theme);

  const ctx = useDashboard();

  const [movesWithHangtags, setMovesWithHangtags] = useState<Moves[]>([]);
  const [movesByRooftop, setMovesByRooftop] = useState<GroupedCustomerMoves[]>([]);
  const [movesByConsumer, setMovesByConsumer] = useState<GroupedCustomerMoves[]>([]);

  const groupByCustomerId = (movesArray: Moves[]): GroupedCustomerMoves[] => {
    const groupArray: GroupedCustomerMoves[] = [];

    const customerMap = new Map<number, GroupedCustomerMoves>();

    movesArray.forEach(move => {
      const customer = move.customer;

      if (customer && customer.id && customer.name) {
        if (!customerMap.has(customer.id)) {
          customerMap.set(customer.id, {
            id: customer.id,
            name: customer.name, // Ensuring name is included here
            moves: [],
          });
        }
        customerMap.get(customer.id)!.moves.push(move);
      }
    });

    groupArray.push(...customerMap.values());

    return groupArray;
  };

  const groupByConsumerName = (movesArray: Moves[]): GroupedCustomerMoves[] => {
    const groupArray: GroupedCustomerMoves[] = [];
    const consumerArray = movesArray.map(move => move.consumer_name);

    // Sets can only contain unique values, so this will remove duplicates
    const uniqueConsumerArray = [...new Set(consumerArray)];

    // Create a new array of objects that contain the name, id, and moves for each customer
    uniqueConsumerArray.forEach(consumer => {
      if (!consumer) return;
      const consumerMovesArray = movesArray.filter(move => move && move.consumer_name === consumer);
      const consumerObj: GroupedCustomerMoves = { id: 0, name: consumer, moves: consumerMovesArray };
      groupArray.push(consumerObj);
    });

    return groupArray;
  };

  useEffect(() => {
    if (moves?.searchResults) {
      const hangtagMoves = moves.searchResults.filter(move => move.hangtags && move.hangtags.length);
      const rooftopMoves: GroupedCustomerMoves[] = groupByCustomerId(moves.searchResults);
      const consumerMoves: GroupedCustomerMoves[] = groupByConsumerName(moves.searchResults);
      setMovesWithHangtags(hangtagMoves);
      setMovesByRooftop(rooftopMoves);
      setMovesByConsumer(consumerMoves);
    }
  }, [moves]);

  return (
    <div className={cls.container}>
      {/* Moves by concierge customer AND rooftop, if both selected */}
      {ctx?.conciergeTag && ctx?.rooftopTag && movesByConsumer?.length && movesByRooftop?.length ? (
        <>
          {movesByRooftop.map((customer, i) => (
            <Fragment key={`${customer?.id}-${i}`}>
              {customer?.name ? (
                <>
                  <BoldDivide spacer mb={6} color='dark' tip={`Track moves for ${customer?.name}.`}>
                    {customer?.name + ' Moves'}
                  </BoldDivide>

                  {movesByConsumer?.map((consumer, j) => (
                    <Fragment key={`${consumer?.id}-${j}`}>
                      <Divide spacer mb={6} tip={`Track moves for ${consumer?.name}.`}>
                        {consumer?.name || `No Customer`}
                      </Divide>

                      <div className={cls.flex}>
                        {consumer?.moves?.map(move => (
                          <div key={move?.id} className={cls.flexItem}>
                            <DashboardMoveTile move={move} />
                          </div>
                        ))}
                      </div>
                    </Fragment>
                  ))}
                </>
              ) : null}
            </Fragment>
          ))}
        </>
      ) : null}

      {/* Moves by concierge customer if option selected */}
      {ctx?.conciergeTag && !ctx?.rooftopTag && movesByConsumer?.length ? (
        <>
          {movesByConsumer?.map((consumer, i) => (
            <Fragment key={`${consumer?.id}-${i}`}>
              {consumer?.name ? (
                <>
                  <Divide spacer mb={6} tip={`Track moves for ${consumer?.name}.`}>
                    {consumer?.name}
                  </Divide>

                  <div className={cls.flex}>
                    {consumer?.moves?.map(move => (
                      <div key={move?.id} className={cls.flexItem}>
                        <DashboardMoveTile move={move} />
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
            </Fragment>
          ))}
        </>
      ) : null}

      {/* Moves by rooftop if option selected */}
      {!ctx?.conciergeTag && ctx?.rooftopTag && movesByRooftop?.length ? (
        <>
          {movesByRooftop?.map((customer, i) => (
            <Fragment key={`${customer?.id}-${i}`}>
              {customer?.name ? (
                <>
                  <BoldDivide spacer mb={6} color='dark' tip={`Track moves for ${customer?.name}.`}>
                    {customer?.name + ' Moves'}
                  </BoldDivide>

                  <div className={cls.flex}>
                    {customer?.moves?.map(move => (
                      <div key={move?.id} className={cls.flexItem}>
                        <DashboardMoveTile move={move} />
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
            </Fragment>
          ))}
        </>
      ) : null}

      {/* Moves by hangtag if option selected */}
      {ctx?.hangtagTag && movesWithHangtags?.length ? (
        <>
          <Divide spacer mb={6} tip={`Track moves with hangtags.`}>
            Moves With Hangtags
          </Divide>

          <div className={cls.flex}>
            {movesWithHangtags?.map(move => (
              <div key={move?.id} className={cls.flexItem}>
                <DashboardMoveTile move={move} />
              </div>
            ))}
          </div>
        </>
      ) : null}

      {/* Active Moves */}
      {moves?.activeMoves?.length ? (
        <>
          <Divide spacer mb={6} tip={`Track moves that are currently in progress.`}>
            Active Moves
          </Divide>

          <div className={cls.flex}>
            {moves?.activeMoves?.map(move => (
              <div key={move?.id} className={cls.flexItem}>
                <DashboardMoveTile move={move} />
              </div>
            ))}
          </div>
        </>
      ) : null}

      {/* Upcoming Moves */}
      {moves?.plannedMoves?.length ? (
        <>
          <Divide spacer mb={6} tip={`Track moves that will be worked soon.`}>
            Upcoming Moves
          </Divide>

          <div className={cls.flex}>
            {moves?.plannedMoves?.map(move => (
              <div key={move?.id} className={cls.flexItem}>
                <DashboardMoveTile move={move} />
              </div>
            ))}
          </div>
        </>
      ) : null}

      {/* Completed Moves */}
      {moves?.finishedMoves?.length ? (
        <>
          <Divide spacer mb={6} tip={`Track moves that have been completed/canceled.`}>
            Completed Moves
          </Divide>

          <div className={cls.flex}>
            {moves?.finishedMoves?.map(move => (
              <div key={move?.id} className={cls.flexItem}>
                <DashboardMoveTile move={move} />
              </div>
            ))}
          </div>
        </>
      ) : null}

      {/* Empty state */}
      {!moves?.activeMoves?.length && !moves?.plannedMoves?.length && !moves?.finishedMoves?.length ? (
        <>
          <Divide spacer mb={6}>
            Moves
          </Divide>

          <div className={cls.notFound}>
            <Typography sx={clsx.notFoundText}>No moves found with selected filters.</Typography>
          </div>
        </>
      ) : null}
    </div>
  );
}

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  notFoundText: {
    color: theme.palette.text.disabled,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
});

const useStyles = (theme?: Theme) => ({
  container: css`
    width: 100%;
  `,

  flex: css`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
  `,
  flexItem: css`
    flex: 0 0 calc(50% - 6px);
    ${theme?.breakpoints.down('xl')} {
      flex: 0 0 calc(100% - 6px);
    }
  `,

  notFound: css`
    padding: ${theme?.spacing(4)};
    border-radius: 8px;
    margin-top: 6px;
    margin-left: auto;
    margin-right: auto;
    background: ${theme?.palette.background.paper};
    box-shadow: 0 0 16px #00000024;
  `,
});
