import React from 'react';
import { css } from '@emotion/css';

import { Appraisal_Vehicles } from '@gql/schema';
import {
  Typography,
  Checkbox,
  Tooltip,
  IconButton,
  Icon,
  useTheme,
  SxProps,
  Theme,
  TextField,
  Menu,
  MenuItem,
  Grid2 as Grid,
} from '@mui/material';

const log = false;

interface TableAction {
  name: string;
  label: string;
  handler: () => void;
  data?: any;
}

interface AppraisalsTagFilterProps {
  vehicles: Appraisal_Vehicles[];
  search: string;
  setSearch: (search: string) => void;
  handleClearFilters: () => void;
  tags: {
    status: string[];
    make: string[];
    model: string[];
    year: string[];
    color: string[];
  };
  setTags: {
    status: (tags: string[]) => void;
    make: (tags: string[]) => void;
    model: (tags: string[]) => void;
    year: (tags: string[]) => void;
    color: (tags: string[]) => void;
  };
  tableActions: TableAction[];
}

interface Filter {
  statuses: string[];
  makes: string[];
  models: string[];
  years: string[];
  colors: string[];
}

type TagKeys = 'status' | 'make' | 'model' | 'year' | 'color';

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
const AppraisalsTagFilter: React.FC<AppraisalsTagFilterProps> = (props: AppraisalsTagFilterProps) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sxStyles = useSxStyles(theme);
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);

  const { vehicles, search, setSearch, handleClearFilters, tags, setTags, tableActions } = props;

  let filter = {
    statuses: [],
    makes: [],
    models: [],
    years: [],
    colors: [],
  } as Filter;

  const getFilters: any = () => {
    if (vehicles && vehicles.length > 0) {
      let statuses = [`Viewed`, `Not Viewed`];
      let makes = [] as string[];
      let models = [] as string[];
      let years = [] as string[];
      let colors = [] as string[];

      makes = vehicles
        .map(vehicle => vehicle?.move?.vehicle_make)
        .filter((make): make is string => make !== null && make !== undefined && make !== ``)
        .sort();
      makes = makes.filter((make, i) => makes.indexOf(make) === i);

      models = vehicles
        .map(vehicle => vehicle?.move?.vehicle_model)
        .filter((model): model is string => model !== null && model !== undefined && model !== ``)
        .sort();
      models = models.filter((model, i) => models.indexOf(model) === i);

      //Commenting this out because these filters are never used in the UI
      // years = vehicles
      //   .map(vehicle => vehicle?.move?.vehicle_year)
      //   .filter((year) => year !== null && year !== ``)
      //   .sort();
      // years = years.filter((year, i) => years.indexOf(year) === i);

      // colors = vehicles
      //   .map(move => move?.vehicle_color)
      //   .filter((color) => color !== null && color !== ``)
      //   .sort();
      // colors = colors.filter((color, i) => colors.indexOf(color) === i);

      log && console.log(`Statuses`, statuses);
      log && console.log(`Makes`, makes);
      log && console.log(`Models`, models);
      // log && console.log(`Years`, years);
      // log && console.log(`Colors`, colors);

      return { statuses, makes, models, years, colors };
    } else return null;
  };

  filter = getFilters();

  const handleTags = (name: TagKeys, str: string) => () => {
    if (str && str !== null) {
      let newTags = [...tags[name]];
      if (newTags.length > 0 && newTags.includes(str)) {
        let strIndex = newTags.indexOf(str);
        if (strIndex !== -1) {
          newTags.splice(strIndex, 1);
          setTags[name]([...newTags]);
        }
      } else {
        newTags.push(str);
        setTags[name]([...newTags]);
      }
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <div className={styles.paper}>
        <Grid container alignItems='center'>
          <Grid size={{ xs: 8 }}>
            <Typography sx={sxStyles.head}>Filter & Search</Typography>
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Tooltip title='Clear Filters'>
              <IconButton onClick={handleClearFilters}>
                <Icon>filter_alt_off</Icon>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid size={{ xs: 2 }}>
            <IconButton onClick={handleMenuOpen}>
              <Icon>settings</Icon>
            </IconButton>
            <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleMenuClose}>
              {tableActions.map((action, index) => (
                <MenuItem
                  key={`${action.name}-${index}`}
                  onClick={() => {
                    handleMenuClose();
                    action.handler();
                  }}
                >
                  {action.label}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>

        <Grid container alignItems='center'>
          <Grid size={{ xs: 12 }}>
            <TextField fullWidth label='Search' value={search} onChange={e => setSearch(e.target.value)} />
          </Grid>
        </Grid>

        {filter && filter.statuses.length > 0 ? (
          <>
            <Typography sx={sxStyles.sub}>Move Status</Typography>
            {filter.statuses.map((item, i) => (
              <Grid key={item + i} container alignItems='center'>
                <Grid>
                  <Checkbox
                    sx={sxStyles.optionCheck}
                    color='primary'
                    checked={tags.status.includes(item)}
                    onChange={handleTags(`status`, item)}
                  />
                </Grid>
                <Grid size='grow'>
                  <Typography sx={sxStyles.optionTxt}>{item}</Typography>
                </Grid>
              </Grid>
            ))}
          </>
        ) : null}

        {filter && filter.makes.length > 0 ? (
          <>
            <Typography sx={sxStyles.sub}>Vehicle Make</Typography>
            {filter.makes.map((item, i) => (
              <Grid key={item + i} container alignItems='center'>
                <Grid>
                  <Checkbox
                    sx={sxStyles.optionCheck}
                    color='primary'
                    checked={tags.make.includes(item)}
                    onChange={handleTags(`make`, item)}
                  />
                </Grid>
                <Grid size='grow'>
                  <Typography sx={sxStyles.optionTxt}>{item}</Typography>
                </Grid>
              </Grid>
            ))}
          </>
        ) : null}

        {filter && filter.models.length > 0 ? (
          <>
            <Typography sx={sxStyles.sub}>Vehicle Model</Typography>
            {filter.models.map((item, i) => (
              <Grid key={item + i} container alignItems='center'>
                <Grid>
                  <Checkbox
                    sx={sxStyles.optionCheck}
                    color='primary'
                    checked={tags.model.includes(item)}
                    onChange={handleTags(`model`, item)}
                  />
                </Grid>
                <Grid size='grow'>
                  <Typography sx={sxStyles.optionTxt}>{item}</Typography>
                </Grid>
              </Grid>
            ))}
          </>
        ) : null}

        {/* The below code was commented out when I copied this from Dealer v2 */}
        {/* {filter && filter.years.length > 0 ? (
          <>
            <Typography className={cls.sub}>Vehicle Year</Typography>
            {filter.years.map((item, i) => (
              <Grid key={item + i} container alignItems='center'>
                <Grid>
                  <Checkbox
                    className={cls.optionCheck}
                    color='primary'
                    checked={tags.year.includes(item)}
                    onChange={handleTags(`year`, item)}
                  />
                </Grid>
                <Grid size='grow'>
                  <Typography className={cls.optionTxt}>{item}</Typography>
                </Grid>
              </Grid>
            ))}
          </>
        ) : null}

        {filter && filter.colors.length > 0 ? (
          <>
            <Typography className={cls.sub}>Vehicle Color</Typography>
            {filter.colors.map((item, i) => (
              <Grid key={item + i} container alignItems='center'>
                <Grid>
                  <Checkbox
                    className={cls.optionCheck}
                    color='primary'
                    checked={tags.color.includes(item)}
                    onChange={handleTags(`color`, item)}
                  />
                </Grid>
                <Grid size='grow'>
                  <Typography className={cls.optionTxt}>{item}</Typography>
                </Grid>
              </Grid>
            ))}
          </>
        ) : null} */}

        {!filter ? (
          <>
            <Typography sx={sxStyles.notFound}>No filters found.</Typography>
          </>
        ) : null}
      </div>
    </>
  );
};
export default AppraisalsTagFilter;

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = (theme: Theme) => {
  return {
    paper: css`
      position: relative;
      width: 240px;
      padding: ${theme.spacing(2)};
      border-radius: 8px;
      margin-bottom: ${theme.spacing(2)};
      background-color: ${theme.palette.background.paper};
      box-shadow: 0 0 16px #00000024;
    `,
    clear: css`
      position: absolute;
      top: 5px;
      right: 5px;
    `,
    refetch: css`
      position: absolute;
      top: 5px;
      right: 5px;
    `,
  };
};

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  head: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
  },
  sub: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: 18,
    fontWeight: 600,
  },
  optionCheck: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  optionTxt: {
    marginTop: `2px`,
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
  },
  notFound: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: 18,
    fontWeight: 500,
  },
});
