// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, useTheme, Typography } from '@mui/material';

import { MovePlannerContext, MovePlannerContextType } from '@features/moveCreation/providers/MovePlannerProvider';

// CONSTANTS ---------------------------------------------------------------- //

const types = [
  {
    value: `concierge`,
    label: `Concierge`,
    description: `Bring in your customer's vehicle for service or return your customer's vehicle to their home (includes SMS notifications).`,
  },
  {
    value: `concierge-loaner`,
    label: `Concierge + Loaner`,
    description: `Perform the same concierge service while also assigning a loaner vehicle to your customer (includes SMS notifications).`,
  },
  {
    value: `one-way`,
    label: `One-Way`,
    description: `Simply move one of your vehicles from point A to point B.`,
  },
  {
    value: `round-trip`,
    label: `Round-Trip`,
    description: `Swap two of your vehicles between point A and point B.`,
  },
];

// COMPONENT ---------------------------------------------------------------- //

const MovePlannerTypeForm = () => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const { rooftopConfig, type, setType } = React.useContext(MovePlannerContext) as MovePlannerContextType;

  return (
    <div className={cls.type}>
      {types
        ?.filter(t => rooftopConfig?.allowed_move_types?.includes(t?.value))
        ?.map(t => {
          return (
            <div
              key={`type-item-${t?.value}`}
              className={cx(cls.typeItem, type === t?.value ? cls.typeItemActive : null)}
              onClick={() => {
                if (setType) setType(t?.value);
              }}
            >
              <Typography variant='h5' sx={{ marginBottom: `6px`, fontWeight: 600 }}>
                {t?.label}
              </Typography>

              <Typography variant='body2' sx={{ textWrap: `pretty`, marginBottom: `6px` }}>
                {t?.description}
              </Typography>
            </div>
          );
        })}
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  return {
    type: css`
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 12px;
    `,
    typeItem: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      min-width: 212px;
      max-width: 424px;
      padding: 12px;
      border: 1px solid ${theme?.palette?.divider};
      border-radius: 10px;
      text-align: center;
      background-color: ${theme?.palette?.action?.hover};
      color: ${theme?.palette?.text?.primary};
      cursor: pointer;
      transition: 0.1s ease-in-out;
      :hover,
      :focus {
        background-color: ${theme?.palette?.action?.selected};
      }
      :active {
        background-color: ${theme?.palette?.action?.disabledBackground};
      }
    `,
    typeItemActive: css`
      border: 1px solid ${theme?.palette?.primary?.main};
      background-color: ${theme?.palette?.primary?.main};
      color: ${theme?.palette?.primary?.contrastText};
      :hover,
      :focus {
        background-color: ${theme?.palette?.primary?.main};
      }
      :active {
        background-color: ${theme?.palette?.primary?.main};
      }
    `,
  };
};

// EXPORT ---------------------------------------------------------------- //

export default MovePlannerTypeForm;
