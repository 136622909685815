import React from 'react';
import ReactToPrint from 'react-to-print';
import dayjs from 'dayjs';
import { css } from '@emotion/css';

import { Typography, Tooltip, IconButton, Icon, Grid2 as Grid, Button, useTheme, Theme } from '@mui/material';

import { moneyWithCommas, getCleansedPhoneNumber, capFirst } from '@services/formatService';

import bb_logo from '@assets/images/bb_primary.svg';

interface AppraisalPrintProps {
  appraisal: any;
  mileage: any;
  image: any;
  action: any;
  brand: any;
}

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
const AppraisalPrint: React.FC<AppraisalPrintProps> = props => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sxStyles = useSxStyles(theme);

  const componentRef = React.useRef<HTMLDivElement>(null);

  const { appraisal, mileage, image, action, brand } = props;

  const move = appraisal.moves[0] || {};
  const customer = appraisal.customer || {};
  const currentDate = dayjs().format(`MM/DD/YYYY`);

  const ComponentToPrint = React.forwardRef<HTMLDivElement>((_, ref) => (
    <div ref={ref} className={styles.base}>
      <div
        className={styles.header}
        style={{
          background: brand && brand.secondary_color ? brand.secondary_color : `#323232`,
          color: brand && brand.primary_color ? brand.primary_color : `#ffffff`,
        }}
      >
        <Grid container spacing={0} justifyContent='space-between' alignItems='center'>
          <Grid size='grow'>
            <Typography sx={sxStyles.headerText}>APPRAISAL OFFER</Typography>
          </Grid>
          {brand && brand.logo_url ? (
            <Grid>
              <img className={styles.logo} src={brand.logo_url} width='180' alt='market-logo' />
            </Grid>
          ) : null}
        </Grid>
      </div>
      <div className={styles.body}>
        <Grid container spacing={2} alignContent='center'>
          {image && image.original ? (
            <Grid>
              <img src={image.original} width='280' alt='vehicle' />
            </Grid>
          ) : null}
          <Grid size='grow'>
            <div className={styles.vehicleYear}>
              <Typography sx={sxStyles.vehicleYearText}>{move.vehicle_year}</Typography>
            </div>
            <div className={styles.vehicleMain}>
              <Typography sx={sxStyles.vehicleMakeText}>{move.vehicle_make}</Typography>
              <Typography sx={sxStyles.vehicleModelText}>{move.vehicle_model}</Typography>
            </div>

            <div className={styles.mdBreak} />

            <Grid container spacing={2}>
              <Grid size={{ xs: 5 }}>
                <Typography sx={sxStyles.vehicleKeyText}>NAME</Typography>
                <Typography sx={sxStyles.vehicleValText}>{move.consumer_name || `-`}</Typography>

                <div className={styles.smBreak} />

                <Typography sx={sxStyles.vehicleKeyText}>CONTACT</Typography>
                <Typography sx={sxStyles.vehicleValText}>
                  {getCleansedPhoneNumber(move.consumer_phone) || `-`}
                </Typography>
              </Grid>
              <Grid size={{ xs: 7 }}>
                <Typography sx={sxStyles.vehicleKeyText}>FULL VIN</Typography>
                <Typography sx={sxStyles.vehicleValText}>{appraisal.vin || `-`}</Typography>

                <div className={styles.smBreak} />

                <Typography sx={sxStyles.vehicleKeyText}>MILEAGE</Typography>
                <Typography sx={sxStyles.vehicleValText}>{mileage || 0}</Typography>

                {/* <div className={styles.smBreak} />

                <Typography sx={sxStyles.vehicleKeyText}>COLOR</Typography>
                <Typography sx={sxStyles.vehicleValText}>{move.vehicle_color || `-`}</Typography> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div className={styles.mdBreak} />

        <div className={styles.lineBreak} />

        <div className={styles.mdBreak} />

        <Grid container spacing={0}>
          <Grid size={{ xs: 4 }}>
            <div className={styles.gridSplitL}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12 }}>
                  <Typography sx={sxStyles.bodyKeyText}>MARKET</Typography>
                  <Typography sx={sxStyles.bodyValText}>{customer.name || `-`}</Typography>
                </Grid>

                <Grid size={{ xs: 12 }}>
                  <Typography sx={sxStyles.bodyKeyText}>VEHICLE CONDITION</Typography>
                  <Typography sx={sxStyles.bodyValText}>{capFirst(appraisal.condition) || `-`} Condition</Typography>
                </Grid>

                {appraisal.trim_overrides && appraisal.trim_overrides.length > 0 && (
                  <Grid size={{ xs: 12 }}>
                    <Typography sx={sxStyles.bodyKeyText}>ADJUSTED TRIM</Typography>
                    <div className={styles.xsBreak} />
                    {appraisal.trim_overrides.map(
                      (trim: { included_in_price: string; name: string; uoc: string }, i: number) => (
                        <>
                          <Typography key={`print-trim-${i}`} sx={sxStyles.bodyValText}>
                            <strong>{trim.included_in_price ? `-` : `+`}</strong> {trim.name || trim.uoc}
                          </Typography>
                          {i < appraisal.trim_overrides.length - 1 && <div className={styles.xsBreak} />}
                        </>
                      )
                    )}
                  </Grid>
                )}

                <Grid size={{ xs: 12 }}>
                  <Typography sx={sxStyles.bodyKeyText}>VEHICLE NOTES</Typography>
                  <div className={styles.notesBox}>
                    <Typography sx={sxStyles.notesText}>{appraisal.vehicle_notes || ``}</Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid size={{ xs: 8 }}>
            <div className={styles.gridSplitR}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 5 }}>
                  <Typography sx={sxStyles.bodyKeyText}>OFFER DATE</Typography>
                  <Typography sx={sxStyles.bodyValText}>{currentDate}</Typography>
                </Grid>
                <Grid size={{ xs: 7 }}>
                  <Typography sx={sxStyles.bodyKeyText}>APPRAISER CREDENTIALS</Typography>
                  <Typography sx={sxStyles.bodyValText}>{appraisal.appraiser_credentials || `-`}</Typography>
                </Grid>
                <Grid size={{ xs: 5 }}>
                  <Typography sx={sxStyles.bodyKeyText}>APPRAISER NAME</Typography>
                  <Typography sx={sxStyles.bodyValText}>{appraisal.appraiser_name || `-`}</Typography>
                </Grid>
                <Grid size={{ xs: 7 }}>
                  <Typography sx={sxStyles.bodyKeyText}>APPRAISER CONTACT</Typography>
                  <Typography sx={sxStyles.bodyValText}>{appraisal.appraiser_contact || `-`}</Typography>
                </Grid>
              </Grid>

              <div className={styles.mdBreak} />

              <div className={styles.block}>
                <Typography sx={sxStyles.bodyKeyText}>APPRAISER MESSAGE</Typography>
                <div className={styles.notesBox}>
                  <Typography sx={sxStyles.notesText}>{appraisal.appraiser_notes || ``}</Typography>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <div className={styles.xlBreak} />

        <Grid container spacing={2} justifyContent='space-between'>
          <Grid>
            <div className={styles.bb}>
              <Grid container spacing={1} alignItems='center' wrap='nowrap'>
                <Grid>
                  <Typography sx={sxStyles.bbText}>POWERED BY</Typography>
                </Grid>
                <Grid>
                  <a href='https://www.blackbook.com/' target='_blank' rel='noopener noreferrer'>
                    {bb_logo ? <img src={bb_logo} width='80' alt='blackbook logo' /> : null}
                  </a>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid>
            <div className={styles.offer}>
              <Typography sx={sxStyles.offerText}>FINAL OFFER</Typography>
              <div className={styles.offerBox}>
                <Typography sx={sxStyles.offerBoxText}>{moneyWithCommas(appraisal.value_total)}</Typography>
              </div>
              {appraisal.appraiser_expire_time_utc ? (
                <Typography sx={sxStyles.offerSubText}>
                  Offer expires {dayjs(appraisal.appraiser_expire_time_utc).format(`MM/DD/YYYY`)}
                </Typography>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  ));

  return (
    <>
      {action === `icon-button` ? (
        <ReactToPrint
          trigger={() => (
            <Tooltip title='Print appraisal or save as a PDF'>
              <IconButton sx={sxStyles.printIconParent}>
                <Icon sx={sxStyles.icon}>print</Icon>
              </IconButton>
            </Tooltip>
          )}
          content={() => componentRef.current}
          documentTitle={`appraisal_${appraisal.vin}_${currentDate}`}
        />
      ) : null}

      {action === `button` ? (
        <ReactToPrint
          trigger={() => (
            <Tooltip title='Print appraisal or save as a PDF'>
              <Button sx={sxStyles.button} fullWidth color='primary' variant='contained'>
                Print Appraisal
              </Button>
            </Tooltip>
          )}
          content={() => componentRef.current}
          documentTitle={`appraisal_${appraisal.vin}_${currentDate}`}
        />
      ) : null}

      <div style={{ display: `none` }}>
        <ComponentToPrint ref={componentRef} />
      </div>
    </>
  );
};

export default AppraisalPrint;

//////////////////////////////////////// STYLES ////////////////////////////////////////
const useStyles = (theme: Theme) => {
  return {
    logo: css`
      min-width: 180px;
      margin-top: ${theme.spacing(0.75)};
    `,
    base: css`
      position: relative;
      width: 100%;
      height: 100%;
    `,
    header: css`
      position: relative;
      padding-top: ${theme.spacing(2.5)};
      padding-bottom: ${theme.spacing(2.5)};
      padding-left: ${theme.spacing(3)};
      padding-right: ${theme.spacing(3)};
    `,
    body: css`
      position: relative;
      padding: ${theme.spacing(3)};
    `,
    block: css`
      display: block;
    `,
    vehicleImage: css`
      max-width: 100%;
    `,
    vehicleYear: css`
      vertical-align: top;
      display: inline-block;
      border: 3px solid ${theme.palette.text.primary};
      padding: ${theme.spacing(1)};
      margin-right: ${theme.spacing(1)};
      text-align: center;
    `,
    vehicleMain: css`
      vertical-align: top;
      display: inline-block;
    `,
    gridSplitL: css`
      padding-right: ${theme.spacing(3)};
      position: relative;
    `,
    gridSplitR: css`
      padding-left: ${theme.spacing(5)};
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 15px;
        top: 0;
        bottom: 0;
        width: 1px;
        background: #ddd;
      }
    `,
    notesBox: css`
      width: 100%;
      min-height: 80px;
      border: 1px solid ${theme.palette.text.secondary};
      padding: ${theme.spacing(1)};
    `,
    bb: css`
      display: block;
      margin-top: ${theme.spacing(3)};
    `,
    offer: css`
      display: block;
      text-align: right;
      margin-right: ${theme.spacing(3)};
    `,
    offerBox: css`
      padding: ${theme.spacing(2)};
      text-align: center;
      background: ${theme.palette.text.primary};
    `,
    xsBreak: css`
      width: 100%;
      height: ${theme.spacing(1)};
    `,
    smBreak: css`
      width: 100%;
      height: ${theme.spacing(1.5)};
    `,
    mdBreak: css`
      width: 100%;
      height: ${theme.spacing(2)};
    `,
    lgBreak: css`
      width: 100%;
      height: ${theme.spacing(3)};
    `,
    xlBreak: css`
      width: 100%;
      height: ${theme.spacing(4)};
    `,
    lineBreak: css`
      width: 100%;
      height: 1px;
      background: #ddd;
    `,
  };
};

const useSxStyles = (theme: Theme) => ({
  headerText: {
    lineHeight: 1,
    fontSize: 32,
    fontWeight: 700,
  },
  printIconParent: {
    '&:hover $icon': {
      color: theme.palette.info.main,
    },
  },
  icon: {
    transition: '0.2s',
  },
  button: {
    color: '#fff',
    boxShadow: 'none',
    '&:hover, &:active': {
      boxShadow: 'none',
    },
    transition: '0.2s',
  },
  keyText: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  valText: {
    display: 'inline-block',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  vehicleYearText: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  vehicleMakeText: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 300,
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
  },
  vehicleModelText: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
  },
  vehicleKeyText: {
    lineHeight: 1,
    marginBottom: theme.spacing(0.25),
    fontSize: 10,
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  vehicleValText: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 300,
    color: theme.palette.text.primary,
  },
  bodyKeyText: {
    lineHeight: 1,
    marginBottom: theme.spacing(0.25),
    fontSize: 10,
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  bodyValText: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 300,
    color: theme.palette.text.primary,
  },
  notesText: {
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  bbText: {
    lineHeight: 1,
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 400,
  },
  offerText: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1,
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 300,
    color: theme.palette.text.primary,
  },
  offerBoxText: {
    display: 'inline-block',
    lineHeight: 1,
    fontSize: 36,
    fontWeight: 700,
    color: '#fff',
  },
  offerSubText: {
    marginTop: theme.spacing(0.75),
    lineHeight: 1,
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 300,
    color: theme.palette.text.primary,
  },
});
