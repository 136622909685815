import React from 'react';
import { css } from '@emotion/css';
import { TableContainer, Table, TablePagination, Theme, useTheme } from '@mui/material';

import UploadedTableHead from '@features/moveImport/components/UploadedTableHead';
import UploadedTableBody from '@features/moveImport/components/UploadedTableBody';

const defaultOrder = `asc`;
const defaultOrderBy = `order`;

interface UploadedTableProps {
  headers: string[];
  entries: any[];
  errors: any[];
}

//////////////////////// COMPONENT ////////////////////////

const UploadedTable: React.FC<UploadedTableProps> = props => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const { headers, entries, errors } = props;

  const [order, setOrder] = React.useState<'asc' | 'desc'>(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  //TODO: find correct event type for this
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  const tableHeaders = headers.map((header, _i) => {
    return { id: header, align: `left`, label: header };
  });

  return (
    <div className={styles.paper}>
      <TableContainer>
        <Table size='small' aria-label='uploaded-table'>
          <UploadedTableHead
            headers={tableHeaders}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
          <UploadedTableBody
            rows={entries}
            errors={errors}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </Table>
      </TableContainer>

      <div className={styles.pagination}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={entries.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default UploadedTable;

//////////////////////// STYLES ////////////////////////

const useStyles = (theme: Theme) => {
  return {
    paper: css`
      position: relative;
      border: 1px solid ${theme.palette.divider};
      border-radius: 8px;
      background: ${theme.palette.background.paper};
    `,
    pagination: css`
      padding-right: ${theme.spacing(2)};
    `,
  };
};
