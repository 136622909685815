//////////////////////// DEPENDENCIES ////////////////////////

import React, { Dispatch, SetStateAction } from 'react';
import {
  Chip,
  Container,
  Grid2 as Grid,
  Icon,
  InputAdornment,
  MenuItem,
  SxProps,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import Spacer from '@components/Utils/Spacer';
import { useUserDetails } from '@hooks/useUserDetails';
import ReusableMultiselect from '@components/Selectors/ReusableMultiselect';
// import AvatarUpload from './AvatarUpload';
//NOTE: When the avatar upload is being used as part of adding a new user, there is no user id with which to assiociate the photo.
//I'm temporarily disabling it here until we have a better solution. (It's also disabled in old Dealer for the same reason.)
import { Customers, Usertocustomers } from '@gql/schema';
import { css } from '@emotion/css';
import { useUser } from '@store';

const titleGridWidth = 5;

interface MultiselectItem {
  id: string | null | undefined;
  name: string | null | undefined;
}

//////////////////////// COMPONENT ////////////////////////

export default function UserAddEditForm() {
  const theme = useTheme();
  const cls = useStyles();
  const sxStyles = useSxStyles(theme);
  const currentUser = useUser();

  const ctx = useUserDetails();

  const handleInputChange =
    (setHandler: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (setHandler) {
        setHandler(event.target.value);
      }
    };

  const handleCustomerChange =
    (setHandler: React.Dispatch<React.SetStateAction<number>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (setHandler && event.target.value) {
        setHandler(Number(event.target.value));
      }
    };

  const handleSelectedCustomers = (_: string | null, selections?: MultiselectItem[]) => {
    if (selections) {
      const customerSelections = selections.map(s => ctx?.customers?.find(c => c.id === Number(s.id)));
      const validCustomers = customerSelections.filter((c): c is Customers => c !== undefined);
      ctx?.setAllowedCustomers(validCustomers);
      ctx?.setAllowedCustomerIds(validCustomers.map(c => c.id));
    }
  };

  const handleRemoveCustomer = (index: number): void => {
    if (ctx) {
      type AllowedCustomerType = Usertocustomers[] | Customers[];
      const filtered: AllowedCustomerType = (ctx.allowedCustomers as AllowedCustomerType).filter(
        (_, i) => i !== index
      ) as AllowedCustomerType;

      ctx.setAllowedCustomers(filtered);
      ctx.setAllowedCustomerIds(
        filtered.map((c): number => {
          if ('customer_id' in c) return c.customer_id;
          return c.id;
        })
      );
    }
  };

  React.useEffect(() => {
    if (Array.isArray(ctx?.customers) && ctx?.customers?.length > 0) {
      if (ctx?.role === 'dealer-super-admin') {
        // Super admin gets all customers
        ctx?.setAllowedCustomers(ctx?.customers);
        ctx?.setAllowedCustomerIds(ctx?.customers.map(c => c.id));
      } else if (ctx?.customerId) {
        // For other roles, preserve existing selections and ensure default customer is included
        const existingCustomerIds = ctx?.allowedCustomerIds || [];
        const selectedCustomers = new Set([...existingCustomerIds, ctx.customerId]);

        ctx?.setAllowedCustomerIds(Array.from(selectedCustomers));
        if (ctx?.customers) {
          ctx.setAllowedCustomers(ctx.customers.filter(c => selectedCustomers.has(c.id)));
        }
      }
    }
  }, [ctx?.role, ctx?.customers, ctx?.customerId]);

  const formatCustomerForMultiselect = (customer: Customers | Usertocustomers) => {
    if ('customer' in customer) {
      // Handle Usertocustomers type
      return {
        id: String(customer.customer_id),
        name: customer.customer?.name || '',
      };
    } else {
      // Handle Customers type
      return {
        id: String(customer.id),
        name: customer.name,
      };
    }
  };

  return (
    <Container maxWidth='lg'>
      {/* VITALS */}
      <Grid container flexWrap='nowrap'>
        <Grid size={{ xs: titleGridWidth }} sx={sxStyles.sectionL}>
          <Typography sx={sxStyles.sectionTitleTxt}>
            Display Name <span className={cls.sectionRequiredTxt}>*</span>
          </Typography>
        </Grid>

        <Grid>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid size='grow' sx={sxStyles.sectionR}>
          <TextField
            disabled={ctx?.isSaving}
            required
            fullWidth
            multiline
            label='Display Name'
            placeholder='Enter display name...'
            variant='outlined'
            size='small'
            value={ctx?.displayName ?? ''}
            onChange={handleInputChange(ctx?.setDisplayName as Dispatch<SetStateAction<string>>)}
            error={ctx?.validation?.displayName === false}
            helperText={ctx?.validation?.displayName === false ? `A display name is required!` : null}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    person
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid size={{ xs: titleGridWidth }} sx={sxStyles.sectionL}>
          <Typography sx={sxStyles.sectionTitleTxt}>
            Email <span className={cls.sectionRequiredTxt}>*</span>
          </Typography>
        </Grid>

        <Grid>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid size='grow' sx={sxStyles.sectionR}>
          <TextField
            disabled={ctx?.isSaving}
            required
            fullWidth
            multiline
            label='Email'
            placeholder='Enter email address...'
            variant='outlined'
            size='small'
            value={ctx?.email ?? ''}
            onChange={handleInputChange(ctx?.setEmail as Dispatch<SetStateAction<string>>)}
            error={ctx?.validation?.email === false}
            helperText={ctx?.validation?.email === false ? `A valid email address is required!` : null}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    mail
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid size={{ xs: titleGridWidth }} sx={sxStyles.sectionL}>
          <Typography sx={sxStyles.sectionTitleTxt}>Phone (Optional)</Typography>
        </Grid>

        <Grid>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid size='grow' sx={sxStyles.sectionR}>
          <TextField
            disabled={ctx?.isSaving}
            fullWidth
            multiline
            label='Phone'
            placeholder='Enter phone number...'
            variant='outlined'
            size='small'
            value={ctx?.phone ?? ''}
            onChange={handleInputChange(ctx?.setPhone as Dispatch<SetStateAction<string>>)}
            error={ctx?.validation?.phone === false}
            helperText={ctx?.validation?.phone === false ? `Phone number is invalid!` : null}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    phone
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      {/* <Grid container wrap='nowrap'>
        <Grid size={{ xs: titleGridWidth }} sx={sxStyles.sectionL}>
          <Typography sx={sxStyles.sectionTitleTxt}>Avatar (Optional)</Typography>
        </Grid>

        <Grid>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid size='grow' sx={sxStyles.sectionR}>
          <AvatarUpload avatar={ctx.avatarUrl} setAvatar={ctx.setAvatarUrl} userId={ctx.userId} />
        </Grid>
      </Grid> */}

      {/* DEFAULT CUSTOMER */}

      <Grid container wrap='nowrap'>
        <Grid size={{ xs: titleGridWidth }} sx={sxStyles.sectionL}>
          <Typography sx={sxStyles.sectionTitleTxt}>
            Default Market <span className={cls.sectionRequiredTxt}>*</span>
          </Typography>
          <Spacer />
          <Typography sx={sxStyles.sectionSubtitleTxt}>
            The main market with which the user is associated. This field is required for all dealer users. If you are
            creating a user with the dealer super admin role, they will receive permissions for all markets within the
            organization. If you are creating a dealer admin user, you will be prompted to add additional markets below.
          </Typography>
        </Grid>

        <Grid>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid size='grow' sx={sxStyles.sectionR}>
          <TextField
            disabled={ctx?.isSaving}
            select
            fullWidth
            label='Default Market'
            placeholder='Select the default market...'
            variant='outlined'
            size='small'
            value={ctx?.customers?.find(c => c.id === ctx?.customerId)?.id || ''}
            // defaultValue={customers?.find(c => c.id === initialState?.customer?.id)?.id}
            onChange={handleCustomerChange(ctx?.setCustomerId as Dispatch<SetStateAction<number>>)}
            error={ctx?.validation?.customer === false}
            helperText={ctx?.validation?.customer === false ? `Default market is required!` : null}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    roofing
                  </Icon>
                </InputAdornment>
              ),
            }}
          >
            {ctx?.customers &&
              Array.isArray(ctx?.customers) &&
              ctx?.customers?.length &&
              ctx?.customers?.map(c => (
                <MenuItem key={`organization-${c?.id}`} value={c?.id}>
                  {c?.name} (#{c?.id})
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>

      {/* ROLE */}

      <Grid container flexWrap='nowrap'>
        <Grid size={{ xs: titleGridWidth }} sx={sxStyles.sectionL}>
          <Typography sx={sxStyles.sectionTitleTxt}>
            User Role <span className={cls.sectionRequiredTxt}>*</span>
          </Typography>
          <Spacer />

          <Typography sx={sxStyles.sectionSubtitleTxt}>
            Select a default role for the user, which will determine their level of access to the customer or
            organization's markets.
          </Typography>
          <Spacer />
          <Typography sx={sxStyles.sectionSubtitleTxt}>
            <b>Super Admin</b> - This user will have full, automatic management access to all markets within your
            organization and features of this site. They can add new super admins, admins, and users.
          </Typography>
          <Spacer />
          <Typography sx={sxStyles.sectionSubtitleTxt}>
            <b>Admin</b> - This user will have access to the subset of markets within your organization that you will be
            prompted to specify. They cannot create new users, but they can access all other configured features of this
            site.
          </Typography>
          <Spacer />
          <Typography sx={sxStyles.sectionSubtitleTxt}>
            <b>User</b> - This user can create and manage moves for their assigned market.
          </Typography>
        </Grid>

        <Grid>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid size='grow' sx={sxStyles.sectionR}>
          <TextField
            disabled={ctx?.isSaving}
            required
            select
            fullWidth
            label='Role'
            placeholder='Select a role...'
            variant='outlined'
            size='small'
            value={ctx?.role ?? ''}
            onChange={handleInputChange(ctx?.setRole as Dispatch<SetStateAction<string>>)}
            error={ctx?.validation?.defaultRole === false}
            helperText={ctx?.validation?.defaultRole === false ? 'Select a default role!' : null}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    category
                  </Icon>
                </InputAdornment>
              ),
            }}
          >
            {currentUser?.profile?.default_role === 'dealer-super-admin'
              ? [
                  <MenuItem key='dealer-super-admin' value='dealer-super-admin'>
                    Super Admin
                  </MenuItem>,
                  <MenuItem key='dealer-admin' value='dealer-admin'>
                    Admin
                  </MenuItem>,
                  <MenuItem key='dealer' value='dealer'>
                    User
                  </MenuItem>,
                ]
              : currentUser?.profile?.default_role === 'dealer-admin'
                ? [
                    <MenuItem key='dealer-admin' value='dealer-admin'>
                      Admin
                    </MenuItem>,
                    <MenuItem key='dealer' value='dealer'>
                      User
                    </MenuItem>,
                  ]
                : null}
          </TextField>
        </Grid>
      </Grid>

      {/* ALLOWED CUSTOMERS */}

      {ctx?.role && ctx?.role === 'dealer-admin' && (
        <Grid container flexWrap='nowrap'>
          <Grid size={{ xs: titleGridWidth }} sx={sxStyles.sectionL}>
            <Typography sx={sxStyles.sectionTitleTxt}>
              Allowed Markets <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
            <Spacer />
            <Typography sx={sxStyles.sectionSubtitleTxt}>
              Dealer admin users must be given access to at least one market in addition to their default.
            </Typography>
          </Grid>

          <Grid>
            <div className={cls.sectionLine} />
          </Grid>

          <Grid size='grow' sx={sxStyles.sectionR}>
            <ReusableMultiselect
              dataArray={ctx?.customers?.map(c => ({ id: String(c.id), name: c.name })) || []}
              selected={
                Array.isArray(ctx.allowedCustomers) ? ctx.allowedCustomers.map(formatCustomerForMultiselect) : []
              }
              label={'Allowed Markets'}
              handleChange={handleSelectedCustomers}
            />

            <Spacer />
            {Array.isArray(ctx?.allowedCustomers) &&
              ctx?.allowedCustomers?.length > 0 &&
              ctx?.allowedCustomers?.map((customer, index) => (
                <Chip
                  key={index}
                  style={{ margin: 5 }}
                  label={'customer' in customer ? customer.customer?.name || '' : customer.name}
                  onDelete={() => handleRemoveCustomer(index)}
                />
              ))}
            {ctx?.validation?.customers === false && (
              <Typography variant='body2' color='error'>
                Dealer admins must have access to at least one market in addition to their default.
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

//////////////////////// STYLES ////////////////////////

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  sectionL: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionR: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionTitleTxt: {
    fontSize: 16,
    fontWeight: 500,
  },
  sectionRequiredTxt: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.error.main,
  },
  sectionSubtitleTxt: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  sectionLine: {
    width: 1,
    height: '100%',
    background: theme.palette.divider,
  },
});

const useStyles = () => ({
  sectionLine: css`
    width: 1,
    height: '100%',
    background: #00000016,
  `,
  sectionRequiredTxt: css`
    fontSize: 16,
    fontWeight: 500,
    color: #ff2050,
  `,
});
