// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { Moves } from '@gql/schema';

import { getCarouselImagesArray } from '@services/vehicleImagesService';

import { css } from '@emotion/css';
import { Theme, useTheme, Icon, InputAdornment, MenuItem, Paper, TextField } from '@mui/material';

import ImageCarousel from '@components/ImageCarousel';

// TYPES ---------------------------------------------------------------- //

interface MoveDetailsImagesProps {
  move?: Moves;
}

// COMPONENT ---------------------------------------------------------------- //

const MoveDetailsImages = ({ move }: MoveDetailsImagesProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const [imageSelection, setImageSelection] = React.useState(`pickup`);

  if (!move) return null;
  if (!move?.pickup_photos?.length && !move?.delivery_photos?.length) return null;

  const pickupSignerName = move?.pickup_workflow_data?.['signer-name'];
  const pickupImages = getCarouselImagesArray({ move, type: `pickup`, signer: pickupSignerName });
  const deliverySignerName = move?.delivery_workflow_data?.['signer-name'];
  const deliveryImages = getCarouselImagesArray({ move, type: `delivery`, signer: deliverySignerName });

  const getImageSelection = () => {
    if (imageSelection === `pickup`) return pickupImages;
    if (imageSelection === `delivery`) return deliveryImages;
  };

  return (
    <Paper variant='custom' sx={{ padding: `16px` }}>
      <TextField
        select
        fullWidth
        size='small'
        variant='outlined'
        label='Image Set Selection'
        placeholder='Select an image set...'
        value={imageSelection}
        onChange={e => setImageSelection(e.target.value)}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                <Icon color='disabled' fontSize='small'>
                  burst_mode
                </Icon>
              </InputAdornment>
            ),
          },
        }}
        sx={{ marginBottom: `12px` }}
      >
        <MenuItem value='pickup'>Pickup Images - {move?.lane?.pickup?.name}</MenuItem>
        <MenuItem value='delivery'>Delivery Images - {move?.lane?.delivery?.name}</MenuItem>
      </TextField>

      <ImageCarousel size='lg' images={getImageSelection()} />
    </Paper>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({});

// EXPORT ---------------------------------------------------------------- //

export default MoveDetailsImages;
