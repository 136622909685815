// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';

import { Dealer_Viewable_Driver_Info, Moves } from '@gql/schema';

import { getCleansedPhoneNumber } from '@services/formatService';
import { getFormattedVehicleFromMove } from '@services/moveService';
import { getFormattedCombinedStatusFromMove } from '@services/statusService';
import { getWorkflowData } from '@services/utilityService';

import { css } from '@emotion/css';
import { Theme, useTheme, InputAdornment, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { BiDetail, BiSolidContact } from 'react-icons/bi';

import Divider from '@components/Divider';
import InfoItem from '@components/InfoItem';

import MoveDetailsVehicleImage from '@features/moves/components/MoveDetailsVehicleImage';
import MoveDetailsDriverImage from '@features/moves/components/MoveDetailsDriverImage';

const maxContactLength = 128;
const maxNotesLength = 512;

// TYPES ---------------------------------------------------------------- //

interface MoveDetailsInfoProps {
  move?: Moves;
  driver?: Dealer_Viewable_Driver_Info;
  isPrinting?: boolean;
  isEditing?: boolean;
  editContact?: string;
  editNotes?: string;
  setEditContact?: (value: string) => void;
  setEditNotes?: (value: string) => void;
}

// COMPONENT ---------------------------------------------------------------- //

const MoveDetailsInfo = ({
  move,
  driver,
  isPrinting,
  isEditing,
  editContact,
  editNotes,
  setEditContact,
  setEditNotes,
}: MoveDetailsInfoProps) => {
  const theme = useTheme();
  const cls = useStyles(theme, { isPrinting });

  // Pickup workflow data
  const pickupWorkflowData = getWorkflowData(
    `pickup`,
    move?.pickup_workflow_data,
    `move_details`,
    true,
    move?.workflowset
  );

  const pickupWorkflowEven = [];
  const pickupWorkflowOdd = [];

  pickupWorkflowData?.length &&
    pickupWorkflowData
      .filter(wd => !wd.key.includes(`Damages`))
      .forEach((wd, i) => {
        if (i % 2) pickupWorkflowEven.push(wd);
        else pickupWorkflowOdd.push(wd);
      });

  // Delivery workflow data
  const deliveryWorkflowData = getWorkflowData(
    `delivery`,
    move?.delivery_workflow_data,
    `move_details`,
    true,
    move?.workflowset
  );

  const deliveryWorkflowEven = [];
  const deliveryWorkflowOdd = [];

  deliveryWorkflowData?.length &&
    deliveryWorkflowData
      .filter(wd => !wd.key.includes(`Damages`))
      .forEach((wd, i) => {
        if (i % 2) deliveryWorkflowEven.push(wd);
        else deliveryWorkflowOdd.push(wd);
      });

  if (!move) return null;
  return (
    <>
      <div className={cls.info}>
        <MoveDetailsVehicleImage move={move} />

        <div className={cls.infoPaper}>
          <Paper variant={isPrinting ? `customPrint` : `custom`} sx={{ padding: `16px` }}>
            <div className={cls.titleFlex}>
              <div className={cls.titleFlexItem} style={{ textAlign: `left` }}>
                <Tooltip title='HopDrive Move ID' placement='top'>
                  <Typography variant='h5' sx={{ lineHeight: 1, fontWeight: 600 }}>
                    #{move?.id}
                  </Typography>
                </Tooltip>
              </div>

              <div className={cls.titleFlexItem} style={{ textAlign: `center` }}>
                <Tooltip title='Ready By' placement='top'>
                  <Typography variant='h5' sx={{ lineHeight: 1, fontWeight: 600 }}>
                    {dayjs(move?.ready_by).format(`M/D/YYYY h:mm A`)}
                  </Typography>
                </Tooltip>
              </div>

              <div className={cls.titleFlexItem} style={{ textAlign: `right` }}>
                <Tooltip title='Status' placement='top'>
                  <Typography variant='h5' sx={{ lineHeight: 1, fontWeight: 600 }}>
                    {getFormattedCombinedStatusFromMove(move)}
                  </Typography>
                </Tooltip>
              </div>
            </div>

            <div className={cls.sectionFlex}>
              <div className={cls.sectionFlexItem}>
                <InfoItem keyText={`Vehicle`} valueText={getFormattedVehicleFromMove(move) || `N/A`} />
                <InfoItem keyText={`Transmission`} valueText={move?.manual_flag ? `Manual` : `Automatic`} />
                <InfoItem keyText={`Odometer`} valueText={move?.vehicle_odometer || `N/A`} />
                <InfoItem keyText={`Reference ID`} valueText={move?.reference_num || `N/A`} />
                <InfoItem keyText={`VIN`} valueText={move?.vehicle_vin || `N/A`} />
                <InfoItem keyText={`Stock`} valueText={move?.vehicle_stock || `N/A`} />
              </div>

              <div className={cls.sectionFlexItem}>
                <InfoItem keyText={`Driver`} valueText={move?.driver_name || `N/A`} />
                <InfoItem keyText={`Market`} valueText={move?.customer?.name || `N/A`} />
                <InfoItem keyText={`Payer`} valueText={move?.payer?.name || move?.customer?.name || `N/A`} />
                <InfoItem keyText={`Workflow Name`} valueText={move?.workflowset?.name || `-`} />
                <InfoItem keyText={`Distance`} valueText={(move?.lane?.distance_miles?.toFixed(1) || `?`) + ` mi`} />

                {isEditing ? (
                  <TextField
                    fullWidth
                    multiline
                    name='move-details-contact'
                    placeholder='Enter contact info...'
                    value={editContact}
                    onChange={e => {
                      const value = e?.target?.value || ``;
                      if (value?.length > maxContactLength) return;
                      if (setEditContact) setEditContact(value);
                    }}
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position='start'>
                            <BiSolidContact size={20} />
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                ) : (
                  <InfoItem keyText={`Contact Info`} valueText={move?.dealer_contact || `N/A`} />
                )}

                {isEditing ? (
                  <TextField
                    fullWidth
                    multiline
                    name='move-details-notes'
                    placeholder='Enter additional notes/instructions...'
                    value={editNotes}
                    onChange={e => {
                      const value = e?.target?.value || ``;
                      if (value?.length > maxNotesLength) return;
                      if (setEditNotes) setEditNotes(value);
                    }}
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position='start'>
                            <BiDetail size={20} />
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                ) : (
                  <InfoItem keyText={`Notes/Instructions`} valueText={move?.move_details || `N/A`} />
                )}
              </div>
            </div>
          </Paper>

          {move?.consumer_name || move?.consumer_phone ? (
            <Paper variant={isPrinting ? `customPrint` : `custom`} sx={{ flex: 1, padding: `16px` }}>
              <div className={cls.sectionFlex}>
                <div className={cls.sectionFlexItem}>
                  <InfoItem keyText={`Customer Name`} valueText={move?.consumer_name || `N/A`} />
                </div>

                <div className={cls.sectionFlexItem}>
                  <InfoItem
                    keyText={`Customer Phone`}
                    valueText={getCleansedPhoneNumber(move?.consumer_phone) || `N/A`}
                  />
                </div>
              </div>
            </Paper>
          ) : null}

          {move?.cancel_status ? (
            <Paper variant={isPrinting ? `customPrint` : `custom`} sx={{ flex: 1, padding: `16px` }}>
              <div className={cls.sectionFlex}>
                <div className={cls.sectionFlexItem}>
                  <InfoItem
                    keyText={`Cancel Reason`}
                    valueText={move?.cancel_reason || `Cancel reason not provided.`}
                  />
                </div>
              </div>
            </Paper>
          ) : null}

          {pickupWorkflowData?.length ? (
            <div>
              <Divider mt={18} mb={6} tip='View additional data captured by the driver on the pickup'>
                Pickup Workflow Data
              </Divider>

              <Paper variant={isPrinting ? `customPrint` : `custom`} sx={{ flex: 1, padding: `16px` }}>
                <div className={cls.sectionFlex}>
                  <div className={cls.sectionFlexItem}>
                    {pickupWorkflowOdd.map((wd, i) => (
                      <React.Fragment key={`move-details-pickup-workflow-odd-${i}`}>
                        <InfoItem keyText={wd?.key} valueText={wd?.val || `N/A`} />
                      </React.Fragment>
                    ))}
                  </div>

                  <div className={cls.sectionFlexItem}>
                    {pickupWorkflowEven.map((wd, i) => (
                      <React.Fragment key={`move-details-pickup-workflow-even-${i}`}>
                        <InfoItem keyText={wd?.key} valueText={wd?.val || `N/A`} />
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </Paper>
            </div>
          ) : null}

          {deliveryWorkflowData?.length ? (
            <div>
              <Divider mt={18} mb={6} tip='View additional data captured by the driver on the delivery'>
                Delivery Workflow Data
              </Divider>

              <Paper variant={isPrinting ? `customPrint` : `custom`} sx={{ flex: 1, padding: `16px` }}>
                <div className={cls.sectionFlex}>
                  <div className={cls.sectionFlexItem}>
                    {deliveryWorkflowOdd.map((wd, i) => (
                      <React.Fragment key={`move-details-delivery-workflow-odd-${i}`}>
                        <InfoItem keyText={wd?.key} valueText={wd?.val || `N/A`} />
                      </React.Fragment>
                    ))}
                  </div>

                  <div className={cls.sectionFlexItem}>
                    {deliveryWorkflowEven.map((wd, i) => (
                      <React.Fragment key={`move-details-delivery-workflow-even-${i}`}>
                        <InfoItem keyText={wd?.key} valueText={wd?.val || `N/A`} />
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </Paper>
            </div>
          ) : null}
        </div>

        <MoveDetailsDriverImage driver={driver} />
      </div>
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme, props?: any) => ({
  info: css`
    display: flex;
    gap: 18px;
  `,
  infoPaper: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
  `,

  titleFlex: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 16px;
  `,
  titleFlexItem: css`
    flex: 1;
    cursor: pointer;
  `,

  sectionFlex: css`
    display: flex;
    gap: 20px;
    ${theme?.breakpoints?.down('md')} {
      flex-direction: ${props?.isPrinting ? `row` : `column`};
      gap: ${props?.isPrinting ? `20px` : `6px`};
    }
  `,
  sectionFlexItem: css`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;
    word-break: break-word;
  `,

  flex: css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 6px;
  `,
  flexLine: css`
    flex: 1;
    border-top: 1px dashed ${theme?.palette?.action?.focus};
    margin: 9px 0;
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default MoveDetailsInfo;
