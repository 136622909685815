import { GetInvoiceDetailsQuery } from '@gql/schema';
import dayjs from 'dayjs';

export const getDefaultInvoiceId = (): number | null => {
  const localInvoiceId = localStorage.getItem('invoice-id');
  if (localInvoiceId) return parseInt(localInvoiceId);
  return null;
};

export const getDefaultARMoveId = (): number | null => {
  const localARMoveId = localStorage.getItem('invoice-armove-id');
  if (localARMoveId) return parseInt(localARMoveId);
  return null;
};

export const getDefaultFoldId = (): number | null => {
  const localFoldId = localStorage.getItem('invoice-fold-id');
  if (localFoldId) return parseInt(localFoldId);
  return null;
};

export const getDefaultCustomerId = (): number | null => {
  const localCustomerId = localStorage.getItem('invoice-customer-id');
  if (localCustomerId) return parseInt(localCustomerId);
  return null;
};

export const getDefaultStart = (): string => {
  const localStart = localStorage.getItem('invoiceListStart');
  if (localStart && localStart !== 'Invalid Date') return dayjs.utc(dayjs(localStart)).format();
  return dayjs.utc(dayjs().startOf('day').subtract(1, 'month')).format();
};

export const getDefaultEnd = (): string => {
  const localEnd = localStorage.getItem('invoiceListEnd');
  if (localEnd && localEnd !== 'Invalid Date') return dayjs.utc(dayjs(localEnd)).format();
  return dayjs.utc(dayjs().endOf('day')).format();
};

export const getTotalMileageFromMoves = (moves: any[] = []): number => {
  const fallbackMileage = 0;
  if (moves.length > 0) {
    const mileages = moves.map(move => move?.lane?.distance_miles || 0);
    const mileage = mileages.reduce((total, current) => total + current, fallbackMileage);
    return mileage;
  }
  return fallbackMileage;
};

export const getPaidTotalFromARPayments = (payments: any[] = []): number => {
  const fallbackPaidTotal = 0;
  if (payments.length > 0) {
    const paidPayments = payments.filter(p => p.status === 'paid' || p.status === 'successful').map(p => p.amount);
    const paidTotal = paidPayments.reduce((total, current) => total + current, fallbackPaidTotal);
    return paidTotal;
  }
  return fallbackPaidTotal;
};

export const buildAmountObject = (invoice: GetInvoiceDetailsQuery['arinvoices'][number]) => {
  const amount: any = {};
  if (invoice) {
    const armoves = invoice.armoves;
    const arpayments = invoice.arpayments.filter(arp => arp.status === 'paid' || arp.status === 'successful');
    const prepaidArmoves = invoice.armoves.filter(armove => armove.type === 'prepaid');
    const prepaidAmount = prepaidArmoves
      .map(armove => armove.paid_amount)
      .reduce((total, current) => total + current, 0);
    const processingFeeArray = invoice.armoves.filter(armove => armove.type === 'fee') || [];
    const processingFeeSum = processingFeeArray
      .map(pf => pf.paid_amount)
      .reduce((total, current) => total + current, 0);

    const subtotalMoves = armoves.filter(item => item.due_amount > 0 && item.type !== 'prepaid');
    const discountedMoves = subtotalMoves.filter(
      item => !item.disputed && item.due_amount >= item.discount_amount && item.discount_amount > 0
    );
    const disputedMoves = subtotalMoves.filter(item => item.disputed);
    const moveTypeArMoves = subtotalMoves.filter(item => item.type === 'move');
    const spoilageTypeArmoves = subtotalMoves.filter(item => item.type === 'spoilage');
    const paidMoves = subtotalMoves.filter(item => item.paid_amount > 0);
    const productArmoves = subtotalMoves.filter(item => ['product', 'one-time', 'prepaid'].includes(item.type));

    amount.subtotal = subtotalMoves
      .map(item => item.due_amount)
      .reduce((total, current) => total + current, 0)
      .toFixed(2);
    amount.moveDueTotal = moveTypeArMoves
      .map(item => item.due_amount)
      .reduce((total, current) => total + current, 0)
      .toFixed(2);
    amount.spoilageDueTotal = spoilageTypeArmoves
      .map(item => item.due_amount)
      .reduce((total, current) => total + current, 0)
      .toFixed(2);
    amount.discounted = discountedMoves
      .map(item => item.discount_amount)
      .reduce((total, current) => total + current, 0)
      .toFixed(2);
    amount.disputed = disputedMoves
      .map(item => item.due_amount)
      .reduce((total, current) => total + current, 0)
      .toFixed(2);
    amount.paid = paidMoves
      .map(item => item.paid_amount)
      .reduce((total, current) => total + current, 0)
      .toFixed(2);
    amount.paymentAmount = arpayments
      .map(item => item.amount)
      .reduce((total, current) => total + current, 0)
      .toFixed(2);
    amount.totalWithoutPrepaid = armoves
      .map(item => item.due_amount)
      .reduce((total, current) => total + current, 0)
      .toFixed(2);
    amount.productTotal = productArmoves
      .map(item => item.due_amount)
      .reduce((total, current) => total + current, 0)
      .toFixed(2);

    amount.prepaid = Math.max(prepaidAmount, 0);
    amount.total = Math.max(amount.subtotal - amount.discounted - amount.disputed, 0);
    amount.totalSansProcessingFee = Math.max(
      amount.subtotal - amount.discounted - amount.disputed - processingFeeSum,
      0
    );
    amount.due = Math.max(amount.total - (amount.paymentAmount + prepaidAmount), 0);
    amount.balanceRemaining = prepaidAmount - (amount.moveDueTotal + amount.spoilageDueTotal);
    amount.processingFee = Math.max(processingFeeSum, 0);
    amount.payable = amount.due > 0 && invoice.status === 'closed';
  }
  return amount;
};
