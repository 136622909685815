// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { css } from '@emotion/css';
import { TextField, Theme, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

// TYPES ---------------------------------------------------------------- //

type InvoiceSidebarFilterProps = {
  start?: string;
  end?: string;
  setStart?: (start: string) => void;
  setEnd?: (end: string) => void;
};

// COMPONENT ---------------------------------------------------------------- //

const InvoiceSidebarFilter = ({ start, end, setStart, setEnd }: InvoiceSidebarFilterProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const handleStartDateChange = (date: Dayjs | null) => {
    if (dayjs(date).isValid()) {
      const value = date?.add(12, 'hour').startOf('day').format() || ``;
      localStorage.setItem('invoiceListStart', value);
      if (setStart) setStart(value);
    }
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    if (dayjs(date).isValid()) {
      const value = date?.add(12, 'hour').startOf('day').format() || ``;
      localStorage.setItem('invoiceListEnd', value);
      if (setEnd) setEnd(value);
    }
  };

  return (
    <div className={cls.filter}>
      <div className={cls.date}>
        <DatePicker
          className={cls.datePicker}
          orientation='portrait'
          value={start ? dayjs(start) : null}
          onChange={date => handleStartDateChange(date)}
          slots={{
            textField: TextField,
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              name: 'start-date',
              size: 'small',
              variant: 'outlined',
              label: 'Start Date',
              placeholder: 'Select a start date...',
            },
          }}
          maxDate={dayjs(end)}
        />
      </div>

      <div className={cls.date}>
        <DatePicker
          className={cls.datePicker}
          orientation='portrait'
          value={end ? dayjs(end) : null}
          onChange={date => handleEndDateChange(date)}
          slots={{
            textField: TextField,
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              name: 'end-date',
              size: 'small',
              variant: 'outlined',
              label: 'End Date',
              placeholder: 'Select an end date...',
            },
          }}
        />
      </div>
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  filter: css`
    z-index: 100;
    position: sticky;
    top: 0;
    display: flex;
    gap: 6px;
    padding: 18px 12px 12px 12px;
    border-bottom: 1px solid ${theme?.palette?.divider};
    background-color: ${theme?.palette?.background?.paper};
  `,
  date: css`
    flex: 1;
  `,
  datePicker: css`
    max-width: 145px;
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default InvoiceSidebarFilter;
