// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';
import { Map, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';

import { Driverlocations, Moves } from '@gql/schema';

import {
  Marker,
  Point,
  getDefaultBounds,
  getFilteredPoints,
  getSmoothPoints,
} from '@components/DriverTracker/driverTrackerService';

import { css } from '@emotion/css';
import { Theme, useTheme, Typography } from '@mui/material';

import DriverTrackerMarker from '@components/DriverTracker/DriverTrackerMarker';

const log = true;

// TYPES ---------------------------------------------------------------- //

interface DriverTrackerMapProps {
  move?: Moves;
  driverLocations?: Driverlocations[];
  markers?: Marker[];
}

// COMPONENT ---------------------------------------------------------------- //

const DriverTrackerMap = ({ move, driverLocations, markers }: DriverTrackerMapProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const [isRendered, setIsRendered] = React.useState<boolean>(false);

  const map = useMap(`driver-tracker-map`);
  const googleMapsService = useMapsLibrary(`maps`);
  const [googleDirectionsRenderer, setGoogleDirectionsRenderer] = React.useState<
    google.maps.DirectionsRenderer | undefined
  >(undefined);
  const [googleDirectionsService, setGoogleDirectionsService] = React.useState<
    google.maps.DirectionsService | undefined
  >(undefined);

  const [selectedMarker, setSelectedMarker] = React.useState<Marker | undefined>(undefined);
  const [etaTime, setEtaTime] = React.useState<string | undefined>(undefined);
  const [etaMinutes, setEtaMinutes] = React.useState<number | undefined>(undefined);

  // On mount, lets set the Google Directions Renderer and Service
  React.useEffect(() => {
    const newGoogleDirectionsRenderer = new window.google.maps.DirectionsRenderer();
    setGoogleDirectionsRenderer(newGoogleDirectionsRenderer);
    const newGoogleDirectionsService = new window.google.maps.DirectionsService();
    setGoogleDirectionsService(newGoogleDirectionsService);
  }, []);

  // Once we have everything we need, lets render anything useful
  React.useEffect(() => {
    // Check for all dependencies before rendering
    if (isRendered) return;
    if (!map || !googleMapsService || !googleDirectionsRenderer || !googleDirectionsService) return;

    // Set the rendered flag
    setIsRendered(true);

    // Isolate the markers we have
    const pickupMarker = markers?.find(m => m?.id === `pickup`);
    const deliveryMarker = markers?.find(m => m?.id === `delivery`);
    const driverMarker = markers?.find(m => m?.id === `driver`);

    // If we have driver locations, render the driver's path
    if (driverLocations) renderDriverPath();

    // If the driver is working the move, render the current route they are on
    if (move?.status === `pickup started`) renderCurrentRoute(driverMarker, pickupMarker);
    if (move?.status === `delivery started`) renderCurrentRoute(driverMarker, deliveryMarker);

    // eslint-disable-next-line
  }, [isRendered, map, googleMapsService, googleDirectionsRenderer, googleDirectionsService]);

  /** Render the driver's previous path */
  const renderDriverPath = async () => {
    try {
      // Initialize Google API Key
      const apiKey = import.meta.env.VITE_GOOGLE_API_KEY;

      // Format the points so it can be read by the map component
      const points: (Point | undefined)[] = driverLocations?.map(dl => {
        if (dl?.location?.coordinates?.length) {
          const point: Point = {
            lat: dl?.location?.coordinates?.[1],
            lng: dl?.location?.coordinates?.[0],
          };
          return point;
        }
        return undefined;
      });

      // Filter the points by using an algorithm that checks distance and angles
      const filteredPoints = await getFilteredPoints(points);

      // Smooth the points by using the Google Roads API
      const smoothPoints = await getSmoothPoints(filteredPoints, apiKey);

      // Create the polyline from the driver path
      const driverPath = new googleMapsService.Polyline({
        path: smoothPoints?.length ? smoothPoints : filteredPoints,
        geodesic: true,
        strokeColor: theme?.palette?.primary?.main,
        strokeOpacity: move?.status === `delivery successful` ? 1 : 0.5,
        strokeWeight: 8,
      });

      // Draw the path onto the map component
      driverPath.setMap(map);
    } catch (err) {
      console.error(`Failed to render driver path:`, err);
    }
  };

  /** Render the current route the driver is on */
  const renderCurrentRoute = (origin?: Marker, destination?: Marker) => {
    if (!origin) {
      log && console.log(`No driver location found! Skipping route render...`);
      return;
    }

    try {
      // Check for Google Directions API loaded
      if (!googleDirectionsRenderer || !googleDirectionsService) throw new Error(`Google Maps API is not loaded!`);

      // Check for malformed origin and destination
      if (
        !origin ||
        !origin?.position ||
        !origin?.position?.lat ||
        !origin?.position?.lng ||
        !destination ||
        !destination?.position ||
        !destination?.position?.lat ||
        !destination?.position?.lng
      )
        throw new Error(
          `Input for Google call was malformed. Skipping Google call. Driver's route was not rendered...`
        );

      // Build the origin and destination strings to feed into Google
      const originStr = `${origin?.position?.lat},${origin?.position?.lng}`;
      const destinationStr = `${destination?.position?.lat},${destination?.position?.lng}`;

      // Build the directions options
      const directionsOptions: google.maps.DirectionsRendererOptions = {
        polylineOptions: {
          strokeColor: theme?.palette?.info?.main,
          strokeOpacity: 1,
          strokeWeight: 8,
        },
      };

      // Build the request to Google
      const req: google.maps.DirectionsRequest = {
        origin: originStr,
        destination: destinationStr,
        travelMode: google.maps.TravelMode.DRIVING,
      };

      // Set the map for the Google Directions Renderer to use
      googleDirectionsRenderer?.setMap(map);

      // Set the options for the Google Directions Renderer
      googleDirectionsRenderer?.setOptions(directionsOptions);

      // Call the Google Directions API to get the driver route
      googleDirectionsService?.route(req, (res, status) => {
        if (status === 'OK') {
          // Render the route from the response
          // log && console.log(`Google Route Response:`, res);
          googleDirectionsRenderer?.setDirections(res);

          // Set the ETA
          const durationSec = res?.routes?.[0]?.legs?.[0]?.duration?.value || 0;
          const durationMin = Math.round(durationSec / 60);
          const curTimePlusDurationSec = dayjs()
            .add(durationSec || 0, `seconds`)
            .format(`h:mm A`);

          setEtaTime(curTimePlusDurationSec);
          setEtaMinutes(durationMin);
        }
      });
    } catch (err) {
      console.error(`Failed to render current route:`, err);
    }
  };

  // Render map
  return (
    <div className={cls.mapContainer}>
      {/* ROUTE INFO */}
      {etaTime && etaMinutes ? (
        <div className={cls.mapInfo}>
          <Typography className={cls.mapInfoTxt}>
            ETA {etaTime} ({etaMinutes} min)
          </Typography>
        </div>
      ) : null}

      {/* MAP */}
      <Map
        id={`driver-tracker-map`}
        mapId={`a1bb97db90b89ef9`}
        defaultBounds={getDefaultBounds(markers)}
        clickableIcons={false}
        gestureHandling={`cooperative`}
        mapTypeControl={false}
        streetViewControl={false}
      >
        {markers?.map((marker, i) => {
          return (
            <React.Fragment key={`driver-tracker-marker-${i}`}>
              <DriverTrackerMarker
                marker={marker}
                selectedMarker={selectedMarker}
                setSelectedMarker={setSelectedMarker}
              />
            </React.Fragment>
          );
        })}
      </Map>
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  mapContainer: css`
    z-index: 1;
    overflow: hidden;
    position: relative;
    display: block;
    width: 100%;
    height: 720px;
    border: 1px solid ${theme?.palette?.action?.focus};
    border-radius: 10px;
    background-color: ${theme?.palette?.background?.paper};
    box-shadow: 0px 2px 12px 2px ${theme?.palette?.divider};
    cursor: pointer;
    ${theme?.breakpoints?.down('sm')} {
      height: 540px;
    }
    ${theme?.breakpoints?.down('xs')} {
      height: 400px;
    }
  `,
  mapInfo: css`
    z-index: 1;
    position: absolute;
    display: block;
    top: 10px;
    left: 10px;
    padding: 4px 8px;
    border-radius: ${theme?.shape?.borderRadius}px;
    background-color: ${theme?.palette?.utility?.main};
    color: ${theme?.palette?.utility?.contrastText};
    box-shadow: 0 0 4px ${theme?.palette?.divider};
  `,
  mapInfoTxt: css`
    font-size: 16px !important;
    font-weight: 500 !important;
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default DriverTrackerMap;
