// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { getComparator, stableSort } from '@services/utilityService';
import { getDefaultFoldId } from '@features/invoices/services/calcService';

import { TableBody } from '@mui/material';

import InvoiceDetailsTableRow from '@features/invoices/components/InvoiceDetailsTableRow';

// TYPES ---------------------------------------------------------------- //

type InvoiceDetailsTableBodyProps = {
  rows: any[];
  order: string;
  orderBy: string;
  page: number;
  rowsPerPage: number;
  handleDisputeModalOpen: any;
};

// COMPONENT ---------------------------------------------------------------- //

const InvoiceDetailsTableBody = ({
  rows,
  order,
  orderBy,
  page,
  rowsPerPage,
  handleDisputeModalOpen,
}: InvoiceDetailsTableBodyProps) => {
  const [foldId, setFoldId] = React.useState(getDefaultFoldId());

  // Add sorting values for special columns
  const rowsWithSortValues = rows.map(row => ({
    ...row,
    _sortDistance: row.armove?.move?.lane?.distance_miles || 0,
    _sortCost: row.armove?.due_amount - (row.armove?.discount_amount || 0),
  }));

  return (
    <TableBody>
      {stableSort(
        rowsWithSortValues, 
        getComparator(
          order, 
          orderBy === 'DISTANCE' ? '_sortDistance' : 
          orderBy === 'COST' ? '_sortCost' :
          orderBy
        )
      )
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row: any, i: number) => {
          return (
            <React.Fragment key={`invoice-row-${row?.armove?.id}`}>
              <InvoiceDetailsTableRow
                row={row}
                index={i}
                foldId={foldId}
                setFoldId={setFoldId}
                handleDisputeModalOpen={handleDisputeModalOpen}
              />
            </React.Fragment>
          );
        })}
    </TableBody>
  );
};

// EXPORT ---------------------------------------------------------------- //

export default InvoiceDetailsTableBody;
