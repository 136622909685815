import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { Typography} from '@mui/material';
import { Customers, Moves } from '@gql/schema';
import OverviewMoveItem from './OrganizationOverviewTableMoveItem';
import dayjs from 'dayjs';

const log = false;

//Type Definitions
interface Hour {
  value: number;
  label: string;
}

interface OverviewBodyProps {
  customers: Customers[];
}

const OverviewBody: React.FC<OverviewBodyProps> = (props: OverviewBodyProps) => {
  const cls = useStyles();
  const { customers } = props;

  const [hours, setHours] = useState([{ value: 0, label: '12:00am' }]);

  //Generate array of hour objects
  useEffect(() => {
    let hours = generateHours();
    setHours(hours);
    log && console.log('hours', hours);
  }, []);

  useEffect(() => {
    if (hours.length > 1) {
      //TODO: get this to work in ts
      //scrolls 6am row to the top, making 8am the top visible row on load
      // document.getElementById('row6').scrollIntoView();
    }
  }, [hours]);

  //Loop to generate hours, each objcet contains hour as integer and string for rows
  const generateHours = (): Hour[] => {
    let hours: Hour[] = [{ value: 0, label: '12:00am' }];
    for (var i = 1; i < 24; i++) {
      if (i < 12) {
        hours.push({ value: i, label: `${i}:00am` });
      } else if (i === 12) {
        hours.push({ value: i, label: `12:00pm` });
      } else {
        hours.push({ value: i, label: `${i - 12}:00pm` });
      }
    }
    return hours;
  };

  //Convert moves pickup time into an integer representing the hour
  const getMoveHour = (move: Moves) => {
    let moveISO = move.ready_by;
    let moveHour = dayjs(moveISO).format('H');
    let moveHourInt = parseInt(moveHour);
    return moveHourInt;
  };

  //Generate cell with the correct moves (filtered by customer and hour)
  const timeSlotBox = (hour: Hour, moves: Moves[]) => {
    if (moves.length > 0) {
      const hoursMoves = moves.filter(move => getMoveHour(move) === hour.value);
      log && console.log('move col', moves);
      return hoursMoves.map(move => {
        return <OverviewMoveItem key={move.id + 'move-item-cell'} move={move} />;
      });
    } else {
      return null;
    }
  };

  return (
    hours &&
    hours.map(hour => {
      return (
        <div id={'row' + hour.value} key={'row' + hour.value} className={cls.hourRow}>
          <Typography key={'cell' + hour.value} className={cls.timeBlock}>
            {hour.label}
          </Typography>
          {customers.map((customer: Customers) => {
            let customerMoves = customer.moves;
            log && console.log('customerMove', customerMoves);
            return (
              <div key={'column' + customer.name + hour.value} className={cls.column}>
                {timeSlotBox(hour, customerMoves)}
              </div>
            );
          })}
        </div>
      );
    })
  );
};

export default OverviewBody;

const useStyles = () => ({
  hourRow: css`
    flex: 1 1 content;
    display: flex;
    &:nth-of-type(odd) {
      background-color: rgb(229, 232, 232);
    }
    &:nth-of-type(even) {
      background-color: rgb(247, 249, 249);
    }
  `,
  timeBlock: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    word-break: normal;
    padding: 10px;
    text-align: center;
    position: sticky;
    left: 0;
    border-right: 1px solid #bbb;
    background-color: inherit;
    z-index: 2;
  `,
  column: css`
    flex: 1;
    text-align: center;
    border-right: 1px solid #bbb;
    padding: 4px;
    min-width: 164px;
  `,
});
