import React, { useEffect, useState } from 'react';
import { Theme, TablePagination } from '@mui/material';
import { css } from '@emotion/css';

const log = false

interface PaginationProps {
    setLimit?: (limit: number) => void;
    setOffset?: (offset: number) => void;
    totalRecords?: number;
    page: number | null;
    setPage: (page: number) => void;
    rowsPerPage?: number;
    setRowsPerPage?: (rowsPerPage: number) => void;
    checkedItemsLength?: number;
    }

const Pagination = (props: PaginationProps) => {
  const cls = useStyles();

  const { setLimit = () => {}, setOffset = () => {}, totalRecords = 0, page, setPage, rowsPerPage = 50, setRowsPerPage = () => {}, checkedItemsLength = 0 } = props;

  const [currentPage, setCurrentPage] = useState<number>(0)

  useEffect(() => {
    setLimit(rowsPerPage);
    const start = page ? page * rowsPerPage : currentPage * rowsPerPage;
    const end = page ? page * rowsPerPage + rowsPerPage : currentPage * rowsPerPage + rowsPerPage;

    const setPages = () => {
      if (totalRecords === 0) {
        setOffset(0)
      } else if (end < totalRecords && (page !== 0 || currentPage !== 0)) {
        setOffset(start);
      } else if ((page === 0 || currentPage === 0) && end <= totalRecords) {
        setOffset(start);
      } else {
        setOffset(start);
      }
    };

    setPages();

  }, [page, rowsPerPage, totalRecords, setOffset, setLimit, currentPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    log && console.log('event', event)
    setPage(newPage);
    setCurrentPage(newPage)
  };

  const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setCurrentPage(0)
  };

  return (
    <div>
        <TablePagination
          component='div'
          count={totalRecords}
          page={page || currentPage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={cls.pagination}
          labelDisplayedRows={({ from, to, count }) =>
          checkedItemsLength > 0 ? 
            `${from}-${to} of ${count} (${props.checkedItemsLength} selected)`
          :`${from}-${to} of ${count}`}
        />
    </div>
  );
}

export default Pagination

////////// STYLES //////////
const useStyles = (theme?: Theme) => {
    return {
        pagination: css`{
                display: flex,
                justify-content: flex-end,
                ${[theme?.breakpoints.down('xs')]}: {
                  height: 2rem,
                },
                ${[theme?.breakpoints.up('xl')]}: {
                  paddingRight: 15%,
                },
        }`,
    }

}