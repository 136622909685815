// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { Arinvoices } from '@gql/schema';

import { css } from '@emotion/css';
import { Theme, Typography, useTheme } from '@mui/material';
import InvoiceSidebarItem from '@features/invoices/components/InvoiceSidebarItem';

// TYPES ---------------------------------------------------------------- //

type InvoiceSidebarListProps = {
  invoices?: Arinvoices[];
  selectedInvoiceId?: number;
};

// COMPONENT ---------------------------------------------------------------- //

const InvoiceSidebarList = ({ invoices, selectedInvoiceId }: InvoiceSidebarListProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  return (
    <div className={cls.list}>
      {invoices?.length ? (
        invoices?.map(invoice => (
          <React.Fragment key={invoice.id}>
            <InvoiceSidebarItem invoice={invoice} selectedInvoiceId={selectedInvoiceId} />
            <div className={cls.divider} />
          </React.Fragment>
        ))
      ) : (
        <Typography
          variant='body1'
          sx={{
            marginTop: `24px`,
            lineHeight: 1,
            textAlign: `center`,
            fontSize: `18px`,
            fontWeight: `400`,
            color: theme.palette.text.disabled,
          }}
        >
          No invoices found within date range
        </Typography>
      )}
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  list: css`
    position: relative;
  `,
  divider: css`
    width: 100%;
    height: 1px;
    background-color: ${theme?.palette.divider};
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default InvoiceSidebarList;
