import React from 'react';

import { css } from '@emotion/css';
import { CircularProgress, Icon, useTheme } from '@mui/material';

interface LoadingProps {
  className?: string;
  center?: boolean;
  color?: string;
  colorTint?: string;
  icon?: React.ReactNode;
  position?: string;
  size?: string;
  disabled?: boolean;
}

const Loading: React.FC<LoadingProps> = ({
  className = '',
  center = true,
  color = `primary`,
  colorTint = `main`,
  icon = ``,
  position = `relative`,
  size = `md`,
  disabled = false,
}) => {
  const theme = useTheme();

  let myColor, myTint;

  if (colorTint === `light`) myTint = `light`;
  else if (colorTint === `dark`) myTint = `dark`;
  else myTint = `main`;

  if (color === `primary`) {
    myColor = theme.palette.primary[myTint];
  } else if (color === `secondary`) {
    myColor = theme.palette.secondary[myTint];
  } else if (color === `tertiary`) {
    myColor = theme.palette.tertiary[myTint];
  } else if (color === `quaternary`) {
    myColor = theme.palette.quaternary[myTint];
  } else if (color === `info`) {
    myColor = theme.palette.info[myTint];
  } else if (color === `error`) {
    myColor = theme.palette.error[myTint];
  } else if (color === `warning`) {
    myColor = theme.palette.warning[myTint];
  } else if (color === `success`) {
    myColor = theme.palette.success[myTint];
  } else if (color === `ops`) {
    myColor = theme.palette.ops.main;
  } else if (color === `concierge`) {
    myColor = theme.palette.concierge.main;
  } else if (color === `lyft`) {
    myColor = theme.palette.lyft.main;
  } else if (color === `uber`) {
    myColor = theme.palette.uber.main;
  } else if (color === `auto`) {
    myColor = theme.palette.auto.main;
  } else if (color === `default`) {
    myColor = theme.palette.default[myTint];
  } else {
    myColor = color;
  }

  if (disabled) {
    myColor = theme.palette.action.disabledBackground;
  }

  const cls = useStyles(theme, { center, myColor, position });

  const getClassNames = () => {
    const classNames = [cls.root];
    if (className) classNames.push(className);
    return classNames.join(` `);
  };

  return (
    <div className={getClassNames()}>
      <CircularProgress className={cls.loading} />

      {icon ? (
        <div className={cls.iconBox}>
          <Icon className={cls.icon}>{icon}</Icon>
        </div>
      ) : null}
    </div>
  );
};

const useStyles = (theme?: Theme, props?: any) => ({
  root: css`
    position: ${props?.position};
    display: 'block';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 'max-content';
    height: 'max-content';
    margin: ${props?.center ? 'auto' : 0};
  `,
  loading: css`
    display: 'block';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 'auto';
    color: ${props?.myColor};
  `,
  iconBox: css`
    position: 'absolute';
    top: '50%';
    left: '50%';
    transform: 'translate(-50%; -50%)';
    margin-top: 2;
  `,
  icon: css`
    color: ${props?.myColor};
  `,
});

export default Loading;
