import { useEffect, useState } from 'react';
import { useUser, useRooftop } from '@store';
import {
  useGetAllowedCustomersMovesLazyQuery,
  useGetSelectedCustomersMovesLazyQuery,
  useGetMovesAsAdminLazyQuery,
  useGetSelectedCustomersMovesAsAdminLazyQuery,
  Moves,
} from '@gql/schema';
import dayjs from 'dayjs';
import { css } from '@emotion/css';
import DefaultFallback from '@components/Fallbacks/DefaultFallback';
import MovesFilter from '@features/moves/components/MovesFilter';
import * as Sentry from '@sentry/react';
import {
  Container,
  FormControlLabel,
  Grid2 as Grid,
  LinearProgress,
  Paper,
  Switch,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import MovesTable from '@features/moves/components/MovesTable';
import MoveCancelModal from '@features/moves/components/MoveCancelModal';
import { config } from '@config';
import { toast } from 'react-toastify';

const { firebase } = config;

interface Modal {
  open: boolean;
  input: Moves | null;
}

/////////////////////////////////////// FUNCTIONS ///////////////////////////////////////

const getDefaultRange = () => {
  const localRange = localStorage.getItem(`move-index-range`);
  if (localRange) return localRange;
  else return `thisWeek`; // Changed default
};

const getDefaultStart = () => {
  const localRange = localStorage.getItem(`move-index-range`);
  const localStart = localStorage.getItem(`move-index-start`);
  if (localRange === `custom` && localStart) return localStart;

  switch (localRange) {
    case 'today':
      return dayjs().startOf('day').format();
    case 'thisWeek':
      return dayjs().startOf('week').format();
    case 'nextWeek':
      return dayjs().add(1, 'week').startOf('week').format();
    case 'thisMonth':
      return dayjs().startOf('month').format();
    case 'thisYear':
      return dayjs().startOf('year').format();
    default:
      return dayjs().startOf('week').format();
  }
};

const getDefaultEnd = () => {
  const localRange = localStorage.getItem(`move-index-range`);
  const localEnd = localStorage.getItem(`move-index-end`);
  if (localRange === `custom` && localEnd) return localEnd;

  switch (localRange) {
    case 'today':
      return dayjs().endOf('day').format();
    case 'thisWeek':
      return dayjs().endOf('week').format();
    case 'nextWeek':
      return dayjs().add(1, 'week').endOf('week').format();
    case 'thisMonth':
      return dayjs().endOf('month').format();
    case 'thisYear':
      return dayjs().endOf('year').format();
    default:
      return dayjs().endOf('week').format();
  }
};

const getDefaultDisable = () => {
  const localRange = localStorage.getItem('move-index-range');
  return !(localRange && localRange === 'custom');
};

const MovesPage = () => {
  const user = useUser();
  const rooftop = useRooftop();
  const theme = useTheme();
  const sxStyles = useSxStyles(theme);
  const cls = useStyles(theme);

  const [disablePickers, setDisablePickers] = useState(getDefaultDisable());
  const [range, setRange] = useState(getDefaultRange());
  const [start, setStart] = useState(getDefaultStart());
  const [end, setEnd] = useState(getDefaultEnd());
  const [search, setSearch] = useState<string>('');
  const [moves, setMoves] = useState<Moves[]>([]);
  const [allCustomersChecked, setAllCustomersChecked] = useState(false);
  const [customersArray, setCustomersArray] = useState<number[]>([]);
  const [payersArray, setPayersArray] = useState<number[]>([]);
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [selectedUsersArray, setSelectedUsersArray] = useState<string[]>([]);
  const [userOptions, setUserOptions] = useState<string[]>([]);
  const [selectedWorkflowsArray, setSelectedWorkflowsArray] = useState<string[]>([]);
  const [workflowOptions, setWorkflowOptions] = useState<string[]>([]);
  const [customerId, setCustomerId] = useState<number>(rooftop?.id || user?.profile?.customer_id || 0);
  const [modal, setModal] = useState<Modal>({ open: false, input: null });
  const [filteredMoves, setFilteredMoves] = useState<Moves[]>([]);

  const [getAllowedCustomersMoves, allowedCustomerMovesRes] = useGetAllowedCustomersMovesLazyQuery({
    variables: { start, end, customersArray },
  });

  const [getSelectedCustomersMoves, selectedCustomersMovesRes] = useGetSelectedCustomersMovesLazyQuery({
    variables: { start, end, customerId, customersArray },
  });

  const [getMovesAsAdmin, movesAsAdminRes] = useGetMovesAsAdminLazyQuery({
    variables: { start, end },
  });

  const [getSelectedCustomersMovesAsAdmin, selectedCustomersMovesAsAdminRes] =
    useGetSelectedCustomersMovesAsAdminLazyQuery({
      variables: { start, end, customerId },
    });

  const { loading } =
    selectedCustomersMovesRes?.loading ||
    allowedCustomerMovesRes?.loading ||
    movesAsAdminRes?.loading ||
    selectedCustomersMovesAsAdminRes?.loading
      ? { loading: true }
      : { loading: false };

  const handleRefetch = async (manual: boolean = false) => {
    try {
      let res;
      if (isAdmin) {
        if (allCustomersChecked) {
          res = await getMovesAsAdmin();
        } else {
          res = await getSelectedCustomersMovesAsAdmin();
        }
      } else {
        if (allCustomersChecked) {
          res = await getAllowedCustomersMoves();
        } else {
          res = await getSelectedCustomersMoves();
        }
      }
      if (res?.data?.moves && Array.isArray(res.data.moves)) {
        setMoves(res.data.moves as Moves[]);
      } else {
        setMoves([]);
      }
      if (manual) {
        toast.success('Refreshed moves!');
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      setMoves([]);
      if (manual) {
        toast.error('Error refreshing moves!');
      }
    }
  };

  useEffect(() => {
    if (isAdmin === null) {
      return;
    }
    handleRefetch();
  }, [customerId, start, end, customersArray, isAdmin, allCustomersChecked]);

  // Control range picker
  const handleRangeChange = (value: string) => {
    localStorage.setItem(`move-index-range`, value);
    if (value !== `custom`) {
      setDisablePickers(true);
      setRange(value);

      switch (value) {
        case 'today':
          setStart(dayjs().startOf('day').format());
          setEnd(dayjs().endOf('day').format());
          break;
        case 'thisWeek': {
          const weekStart = dayjs().startOf('week').format();
          const weekEnd = dayjs().endOf('week').format();
          setStart(weekStart);
          setEnd(weekEnd);
          break;
        }
        case 'nextWeek':
          setStart(dayjs().add(1, 'week').startOf('week').format());
          setEnd(dayjs().add(1, 'week').endOf('week').format());
          break;
        case 'thisMonth':
          setStart(dayjs().startOf('month').format());
          setEnd(dayjs().endOf('month').format());
          break;
        case 'thisYear':
          setStart(dayjs().startOf('year').format());
          setEnd(dayjs().endOf('year').format());
          break;
      }
    } else {
      setDisablePickers(false);
      setRange(value);
      const start = getDefaultStart();
      const end = getDefaultEnd();
      setStart(start);
      setEnd(end);
    }
  };

  // Control date pickers
  const handleDateChange = (value: string, name: string) => {
    if (name === `start`) {
      const newDate = dayjs.utc(dayjs(value).startOf(`day`)).format();
      localStorage.setItem(`move-index-start`, newDate);
      setStart(newDate);
    } else {
      const newDate = dayjs.utc(dayjs(value).endOf(`day`)).format();
      localStorage.setItem(`move-index-end`, newDate);
      setEnd(newDate);
    }
  };

  const handleModalOpen = (input: Moves) => {
    setModal({ ...modal, open: true, input });
  };
  const handleModalClose = () => {
    setModal({ ...modal, open: false });
  };

  /** Capitalize the first letter of each word */
  const capEach = (str: string | null = null) => {
    if (str && typeof str === 'string') {
      if (!str.includes(' ')) return str.charAt(0).toUpperCase() + str.slice(1);
      const arr = str.split(' ');
      return arr.map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ');
    }
    return str;
  };

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllCustomersChecked(event.target.checked);
  };

  /** Get formatted combined status string from a drive or ride */
  const getFormattedCombinedStatusFromMove = (move: Moves) => {
    let fallbackStatus = 'Unknown';
    if (move) {
      fallbackStatus = move.move_type === 'drive' ? 'Not Assigned' : 'Not Started';
      const cancelStatus = move?.cancel_status?.toLowerCase() || null;
      const status = move?.status?.toLowerCase() || null;
      if (cancelStatus) {
        if (cancelStatus === 'pending') return 'Pending Cancel';
        if (cancelStatus === 'seen') return 'Cancel Was Seen';
        if (cancelStatus === 'canceled') return 'Cancel Before Started';
        if (cancelStatus === 'started') return 'Cancel After Started';
        if (cancelStatus === 'delivered') return 'Cancel After Delivered';
        return capEach(cancelStatus);
      }
      if (status) {
        if (status === 'dispatched') return 'Dispatched';
        if (status === 'pickup started') return 'Pickup Started';
        if (status === 'pickup arrived') return 'Pickup Arrived';
        if (status === 'pickup successful') return 'Pickup Successful';
        if (status === 'delivery started') return 'Delivery Started';
        if (status === 'delivery arrived') return 'Delivery Arrived';
        if (status === 'delivery successful') return 'Delivery Successful';
        if (status === 'awaitingresponse') return 'Awaiting Response';
        if (status === 'accepted') return 'Accepted';
        if (status === 'arrived') return 'Arrived';
        if (status === 'pickedup') return 'Picked Up';
        if (status === 'droppedoff') return 'Dropped Off';
        if (status === 'canceled') return 'Canceled';
        if (status === 'failed') return 'Failed';
        return capEach(status);
      }
    }
    return fallbackStatus;
  };

  const applyFilters = (data: Moves[]) => {
    const filterBySearch = () => {
      if (!search || search.length < 1) {
        return data; // Return all data if no search term
      }
      return data.filter(move => {
        const status = getFormattedCombinedStatusFromMove(move);
        const moveCreator =
          move.createdBy || (move.eventlogs && move.eventlogs.length > 0 ? move.eventlogs[0].user : null);

        const searchLower = search.toLowerCase();
        return (
          (move.id && (move.id + '').toLowerCase().includes(searchLower)) ||
          (move.reference_num && move.reference_num.toLowerCase().includes(searchLower)) ||
          (move.vehicle_stock && move.vehicle_stock.toLowerCase().includes(searchLower)) ||
          (move.lane && move.lane.description && move.lane.description.toLowerCase().includes(searchLower)) ||
          (status && status.toLowerCase().includes(searchLower)) ||
          (moveCreator && moveCreator.toLowerCase().includes(searchLower)) ||
          (move.workflowset?.name && move.workflowset?.name.toLowerCase().includes(searchLower))
        );
      });
    };

    const filterByUsers = (moves: Moves[]) => {
      if (!selectedUsersArray || selectedUsersArray.length === 0) {
        return moves; // Return all moves if no users selected
      }
      return moves.filter(move => {
        const moveCreator =
          move.createdBy || (move.eventlogs && move.eventlogs.length > 0 ? move.eventlogs[0].user : null);
        return moveCreator && selectedUsersArray.includes(moveCreator);
      });
    };

    const filterByWorkflows = (moves: Moves[]) => {
      if (!selectedWorkflowsArray || selectedWorkflowsArray.length === 0) {
        return moves; // Return all moves if no workflows selected
      }
      return moves.filter(move => selectedWorkflowsArray.includes(move.workflowset?.name || ''));
    };

    // Apply filters in sequence
    const searchFiltered = filterBySearch();
    const userFiltered = filterByUsers(searchFiltered);
    return filterByWorkflows(userFiltered);
  };

  useEffect(() => {
    const buildAllowedCustomersArray = async () => {
      const customers = user?.claims[firebase.claims.allowedCustomers];
      const customer = user?.claims[firebase.claims.customerId];
      const payers = user?.claims[firebase.claims.allowedPayers];
      if (
        payers &&
        Array.isArray(payers) &&
        payers.length > 0 &&
        customers &&
        Array.isArray(customers) &&
        customers.length > 0
      ) {
        setCustomersArray([...customers, ...payers, customer]);
        setPayersArray(payers);
      } else if (customers && Array.isArray(customers) && customers.length > 0) {
        setCustomersArray([...customers, customer]);
      } else {
        if (customer) setCustomersArray([customer as number]);
      }
    };

    const checkIfAdmin = async () => {
      const role = user?.claims[firebase.claims.defaultRole] as string;
      setIsAdmin(role?.includes('admin') || false);
    };

    buildAllowedCustomersArray();
    checkIfAdmin();
  }, []);

  useEffect(() => {
    if (rooftop?.id) setCustomerId(rooftop?.id);
  }, [rooftop]);

  useEffect(() => {
    if (moves) {
      const filtered = applyFilters(moves);
      setFilteredMoves(filtered);
    }

    const userSet = new Set<string>();
    userSet.add(user?.profile?.email || '');
    (moves || []).forEach(move => {
      if (move.eventlogs?.length > 0) {
        userSet.add(move.eventlogs[0].user);
      }
    });
    const userNames = Array.from(userSet).sort();
    setUserOptions(userNames);

    const workflowSet = new Set<string>();
    (moves || []).forEach(move => {
      if (move.workflowset?.name) {
        workflowSet.add(move.workflowset?.name);
      }
    });
    const workflowNames = Array.from(workflowSet).sort();
    setWorkflowOptions(workflowNames);
  }, [search, moves, selectedUsersArray, selectedWorkflowsArray]);

  return (
    <>
      <MoveCancelModal open={modal.open} onClose={handleModalClose} moveInput={modal.input} />

      <div className={cls.pageContainer}>
        <Container maxWidth='lg'>
          <div className={cls.header}>
            <Grid container spacing={2} alignItems='center'>
              <Grid size={{ xs: 9 }}>
                <Typography sx={sxStyles.titleText}>Moves Index</Typography>
              </Grid>
              <Grid size={{ xs: 3 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {isAdmin && (
                  <FormControlLabel
                    sx={sxStyles.allCustomerSwitch}
                    control={
                      <Switch
                        checked={allCustomersChecked}
                        onChange={handleSwitch}
                        inputProps={{ 'aria-label': 'all customers checkbox' }}
                      />
                    }
                    label='Show All Markets'
                  />
                )}
              </Grid>
            </Grid>
          </div>

          {loading ? (
            <LinearProgress />
          ) : (
            <>
              <MovesFilter
                refetch={handleRefetch}
                range={range}
                start={start}
                end={end}
                onRangeChange={handleRangeChange}
                onDateChange={handleDateChange}
                disablePickers={disablePickers}
                setSearch={setSearch}
                search={search}
                selectedUsersArray={selectedUsersArray}
                setSelectedUsersArray={setSelectedUsersArray}
                userOptions={userOptions}
                selectedWorkflowsArray={selectedWorkflowsArray}
                setSelectedWorkflowsArray={setSelectedWorkflowsArray}
                workflowOptions={workflowOptions}
                moves={filteredMoves}
              />

              {filteredMoves.length === 0 && !loading ? (
                <DefaultFallback severity='warning' message='No moves found with selected filters.' />
              ) : (
                <Sentry.ErrorBoundary
                  fallback={<DefaultFallback severity='error' message='Error displaying moves table!' />}
                >
                  <Paper variant='custom' style={{ overflow: 'hidden' }}>
                    <MovesTable
                      moves={filteredMoves as Moves[]}
                      isAdmin={!!isAdmin}
                      payersArray={payersArray}
                      handleModalOpen={handleModalOpen}
                    />
                  </Paper>
                </Sentry.ErrorBoundary>
              )}
            </>
          )}
        </Container>
      </div>
    </>
  );
};

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  titleText: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  allCustomerSwitch: {
    fontSize: 24,
    fontWeight: 500,
  },
});

const useStyles = (theme: Theme) => {
  return {
    pageContainer: css`
      padding: ${theme.spacing(3)} 0;
    `,
    header: css`
      margin-bottom: ${theme.spacing(3)};
    `,
  };
};

export default MovesPage;
