// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { useApiIsLoaded } from '@vis.gl/react-google-maps';

import { Marker } from '@components/DriverTracker/driverTrackerService';

import { Driverlocations, Moves, useGetDriverLocationsLazyQuery } from '@gql/schema';

import { useTheme, Paper, Typography } from '@mui/material';

import DriverTrackerMap from '@components/DriverTracker/DriverTrackerMap';
import Loading from '@components/Utils/Loading';

// TYPES ---------------------------------------------------------------- //

interface DriverTrackerProps {
  move?: Moves;
}

// COMPONENT ---------------------------------------------------------------- //

const DriverTracker = ({ move }: DriverTrackerProps) => {
  const theme = useTheme();

  const [getDriverLocations] = useGetDriverLocationsLazyQuery();

  const isApiLoaded = useApiIsLoaded();

  const [initialLoad, setInitialLoad] = React.useState<boolean>(true);
  const [error, setError] = React.useState<boolean>(false);

  const [driverLocations, setDriverLocations] = React.useState<Driverlocations[]>([]);
  const [markers, setMarkers] = React.useState<Marker[]>([]);

  /** Fetch driver locations and set the state for the map */
  const fetchDriverlocationsAndSetState = async () => {
    // First off, make sure this function is only called once so we don't spam Google's API
    if (!initialLoad) return;

    // Continue with the function
    try {
      // Check for move and lane details
      if (!move || !move?.id || !move?.lane || !move?.lane?.pickup || !move?.lane?.delivery)
        throw new Error(`Essential move data is missing!`);

      // Fetch driver locations
      const res = await getDriverLocations({ variables: { moveId: move?.id } });
      const resDriverLocations = res?.data?.driverlocations;

      // Check for valid response
      if (!resDriverLocations) throw new Error(`Query had an invalid response!`);

      // Set the driver locations
      setDriverLocations(resDriverLocations);

      // Create markers for pickup, delivery, and driver
      const pickup = move?.lane?.pickup;
      const delivery = move?.lane?.delivery;
      const pickupMarker: Marker = {
        id: `pickup`,
        name: pickup?.name || undefined,
        address: pickup?.address || undefined,
        position: {
          lat: pickup?.latitude || undefined,
          lng: pickup?.longitude || undefined,
        },
      };
      const deliveryMarker: Marker = {
        id: `delivery`,
        name: delivery?.name || undefined,
        address: delivery?.address || undefined,
        position: {
          lat: delivery?.latitude || undefined,
          lng: delivery?.longitude || undefined,
        },
      };
      const driverMarker: Marker = {
        id: `driver`,
        name: move?.driver_name || undefined,
        address: `Driver's Last Known Location`,
        position: {
          lat: resDriverLocations?.[resDriverLocations?.length - 1]?.location?.coordinates?.[1] || undefined,
          lng: resDriverLocations?.[resDriverLocations?.length - 1]?.location?.coordinates?.[0] || undefined,
        },
      };

      // Build the new markers array
      const newMarkers = [pickupMarker, deliveryMarker];
      if (driverMarker?.position?.lat && driverMarker?.position?.lng) newMarkers.push(driverMarker);

      // Set the markers
      setMarkers(newMarkers);
    } catch (err) {
      console.error(`Failed to initialize map data:`, err);
      setError(true);
      setMarkers([]);
    }

    setInitialLoad(false);
  };

  // Once the Google Maps API is loaded, fetch driver locations and set the state
  React.useEffect(() => {
    if (isApiLoaded) fetchDriverlocationsAndSetState();
    // eslint-disable-next-line
  }, [isApiLoaded]);

  // Check for error state
  if (error)
    return (
      <Paper variant='custom' sx={{ padding: `12px`, textAlign: `center` }}>
        <Typography variant='h6' sx={{ color: theme?.palette?.error?.main }}>
          Map failed to initialize
        </Typography>
      </Paper>
    );

  // Check for loading state
  if (initialLoad)
    return (
      <Paper variant='custom' sx={{ padding: `12px`, textAlign: `center` }}>
        <Typography variant='h6' sx={{ color: theme?.palette?.text?.disabled }}>
          Initializing map...
        </Typography>
        <Loading position='relative' />
      </Paper>
    );

  // Render the map once the data is loaded
  return <DriverTrackerMap move={move} driverLocations={driverLocations} markers={markers} />;
};

// EXPORT ---------------------------------------------------------------- //

export default DriverTracker;
