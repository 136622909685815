import React from 'react';
import { css } from '@emotion/css';
import {
  SxProps,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Theme,
  useTheme,
} from '@mui/material';
import Spacer from '@components/Utils/Spacer';
import WorkflowsetTable from '@features/moveImport/components/WorkflowsetTable';

import useMoveImport from '@features/moveImport/hooks/useMoveImport';

const headers = [
  {
    id: `field_name`,
    label: `Field\xa0Name`,
  },
  {
    id: `required`,
    label: `Required\xa0for Request\xa0Type`,
  },
  {
    id: `accepted_values`,
    label: `Accepted\xa0Values`,
  },
  {
    id: `description`,
    label: `Description`,
  },
];

//////////////////////// COMPONENT ////////////////////////

const ExplanationTable: React.FC = () => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sxStyles = useSxStyles(theme);

  const { explanations, configExplanations } = useMoveImport();

  return (
    <>
      <Typography sx={sxStyles.titleTxt}>Essential Fields</Typography>

      <Spacer size='xs' />

      <div className={styles.paper}>
        <TableContainer>
          <Table size='small' aria-label='explanation-table'>
            <TableHead>
              <TableRow>
                {headers.map(header => (
                  <TableCell key={header.id} align={`left`} padding={`normal`}>
                    {header.label || `Label`}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {explanations.map((row, i) => {
                return (
                  <TableRow key={`explanation-row-${i}`} sx={i % 2 ? sxStyles.rowEven : sxStyles.rowOdd}>
                    <TableCell sx={i < explanations.length - 1 ? undefined : sxStyles.lastCell} align='left'>
                      {row.label || `-`}
                    </TableCell>

                    <TableCell sx={i < explanations.length - 1 ? undefined : sxStyles.lastCell} align='left'>
                      {row.required && row.required.length
                        ? row.required.map((val, j) => (
                            <div key={`explanation-required-value-${j}`}>
                              {val}
                              {j < row.required.length - 1 ? `,` : ``}
                            </div>
                          ))
                        : `-`}
                      {row.requiredNote ? <div className={styles.noteTxt}>*{row.requiredNote}</div> : null}
                    </TableCell>

                    <TableCell sx={i < explanations.length - 1 ? undefined : sxStyles.lastCell} align='left'>
                      {row.acceptedValues && row.acceptedValues.length
                        ? row.acceptedValues.map((val, j) => (
                            <div key={`explanation-accepted-value-${j}`}>
                              "{val}"{j < row.acceptedValues.length - 1 ? `,` : ``}
                            </div>
                          ))
                        : `-`}
                    </TableCell>

                    <TableCell sx={i < explanations.length - 1 ? undefined : sxStyles.lastCell} align='left'>
                      {row.desc || `-`}
                      {row.label.includes('workflowset') && (
                        <>
                          <Spacer size='xs' />
                          <Typography sx={sxStyles.subtitleTxt}>Workflowset Guide:</Typography>
                          <WorkflowsetTable />
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Spacer size='lg' />

      <Typography sx={sxStyles.titleTxt}>Additional Configuration Fields</Typography>
      <Typography sx={sxStyles.subtitleTxt}>*Not included in base CSV template (Must be added manually)</Typography>

      <Spacer size='xs' />

      <div className={styles.paper}>
        <TableContainer>
          <Table size='small' aria-label='configExplanation-table'>
            <TableHead>
              <TableRow>
                {headers.map(header => (
                  <TableCell key={header.id} align={`left`} padding={`normal`}>
                    {header.label || `Label`}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {configExplanations.map((row, i) => {
                return (
                  <TableRow key={`configExplanation-row-${i}`} sx={i % 2 ? sxStyles.rowEven : sxStyles.rowOdd}>
                    <TableCell sx={i < configExplanations.length - 1 ? undefined : sxStyles.lastCell} align='left'>
                      {row.label || `-`}
                    </TableCell>

                    <TableCell sx={i < configExplanations.length - 1 ? undefined : sxStyles.lastCell} align='left'>
                      {row.required && row.required.length
                        ? row.required.map((val, j) => (
                            <div key={`configExplanation-required-value-${j}`}>
                              {val}
                              {j < row.required.length - 1 ? `,` : ``}
                            </div>
                          ))
                        : `-`}
                      {row.requiredNote ? <div className={styles.noteTxt}>*{row.requiredNote}</div> : null}
                    </TableCell>

                    <TableCell sx={i < configExplanations.length - 1 ? undefined : sxStyles.lastCell} align='left'>
                      {row.acceptedValues && row.acceptedValues.length > 0
                        ? row.acceptedValues.map((val, j) => (
                            <div key={`configExplanation-accepted-value-${j}`}>
                              "{val}"{j < row.acceptedValues.length - 1 ? `,` : ``}
                            </div>
                          ))
                        : `-`}
                    </TableCell>

                    <TableCell sx={i < configExplanations.length - 1 ? undefined : sxStyles.lastCell} align='left'>
                      {row.desc || `-`}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default ExplanationTable;

//////////////////////// STYLES ////////////////////////

const useStyles = (theme: Theme) => {
  return {
    paper: css`
      position: relative;
      border: 1px solid ${theme.palette.divider};
      border-radius: 8px;
      background: ${theme.palette.background.paper};
      overflow: hidden;
    `,
    noteTxt: css`
      margintop: ${theme.spacing(1)};
      lineheight: 1.25;
      fontsize: 12;
      fontweight: 400;
      color: ${theme.palette.text.secondary};
    `,
  };
};

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  rowOdd: {
    background: theme.palette.background.paper,
    // '&:hover, &:active': {
    //   background: `${theme.palette.action.hover} !important`,
    // },
    // cursor: 'pointer',
    transition: '0.1s',
  },
  rowEven: {
    background: '#f8f8f8',
    // background: theme.palette.background.light,
    // '&:hover, &:active': {
    //   background: `${theme.palette.action.hover} !important`,
    // },
    // cursor: 'pointer',
    transition: '0.1s',
  },
  lastCell: {
    border: `none`,
  },
  titleTxt: {
    lineHeight: 1,
    fontSize: 21,
    fontWeight: 600,
  },
  subtitleTxt: {
    marginTop: theme.spacing(0.5),
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  noteTxt: {
    marginTop: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
});
