//////////////////////// DEPENDENCIES ////////////////////////

import { Grid2 as Grid, TextField, InputAdornment, Icon, Tooltip, Button, IconButton } from '@mui/material';
import { useUser } from '@store';
import { useNavigate } from 'react-router-dom';

interface UsersFilterProps {
  search?: string;
  onSearchChange?: (search: string) => void;
  onRefresh?: () => void;
}

//////////////////////// COMPONENT ////////////////////////

function UsersFilter({ search, onSearchChange, onRefresh }: UsersFilterProps) {
  const user = useUser();
  const navigate = useNavigate();

  const handleSearchChange = (event: { target: { value: string } }) => {
    if (onSearchChange) onSearchChange(event.target.value);
  };

  const handleRefresh = () => {
    if (onRefresh) {
      onRefresh();
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems='center'>
        <Grid size='grow'>
          <TextField
            fullWidth
            label='Search'
            placeholder='Search table...'
            variant='outlined'
            size='small'
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    search
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {user?.profile?.default_role === 'dealer' ? null : (
          <Tooltip
            title={
              user?.profile?.default_role === 'admin'
                ? 'Please use Admin Portal to add new dealer users'
                : 'Click to add a new user to your organization'
            }
            placement='bottom'
          >
            <Grid>
              <Button
                disabled={user?.profile?.default_role !== 'dealer-super-admin'}
                color='primary'
                size='large'
                onClick={() => {
                  navigate('/users/add');
                }}
              >
                Add User
              </Button>
            </Grid>
          </Tooltip>
        )}
        <Grid>
          <Tooltip title='Manually refresh users'>
            <IconButton onClick={handleRefresh}>
              <Icon>refresh</Icon>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
}

//////////////////////// EXPORT ////////////////////////

export default UsersFilter;
