// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useUser, useRooftop, setRooftop } from '@store';
import { useGetDefaultRooftopLazyQuery } from '@gql/schema';
import { checkForPermittedRole } from '@features/auth/services/authService';

import { css } from '@emotion/css';
import { Theme, useTheme } from '@mui/material';

import Nav from '@features/layout/components/Nav';
import Header from '@features/layout/components/Header';
import Feature from '@features/layout/components/Feature';

// DEFAULTS ---------------------------------------------------------------- //

const getDefaultNavCondensed = (fallback?: boolean) => {
  if (localStorage?.getItem(`navCondensed`)) return JSON.parse(localStorage?.getItem(`navCondensed`));
  return fallback || false;
};

// TYPES ---------------------------------------------------------------- //

interface LayoutProps {
  children: React.ReactNode;
}

// COMPONENT ---------------------------------------------------------------- //

const Layout = ({ children }: LayoutProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const user = useUser();
  const rooftop = useRooftop();
  const isPermitted = checkForPermittedRole(user);

  const [condensed, setCondensed] = React.useState(getDefaultNavCondensed());

  const [getRooftop] = useGetDefaultRooftopLazyQuery();

  const handleNavToggle = () => {
    localStorage?.setItem(`navCondensed`, JSON.stringify(!condensed));
    setCondensed(!condensed);
  };

  const fetchAndSetDefaultRooftop = async (customerId: number) => {
    const res = await getRooftop({ variables: { customerId } });
    setRooftop(res?.data?.customers_by_pk);
  };

  React.useEffect(() => {
    if (user?.profile?.customer_id && !rooftop) fetchAndSetDefaultRooftop(user?.profile?.customer_id);
  }, [user]);

  return (
    <div className={cls.layout}>
      {user && isPermitted ? (
        <>
          <Nav condensed={condensed} handleNavToggle={handleNavToggle} />
          <div className={cls.content}>
            <Header condensed={condensed} handleNavToggle={handleNavToggle} />
            <Feature>{children}</Feature>
          </div>
        </>
      ) : (
        <div className={cls.content}>
          <Feature>{children}</Feature>
        </div>
      )}

      <ToastContainer position='top-center' theme='dark' limit={3} transition={Zoom} />
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  const styles = {
    layout: css`
      position: fixed;
      display: flex;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
    `,
    content: css`
      overflow: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    `,
  };

  return styles;
};

// EXPORT ---------------------------------------------------------------- //

export default Layout;
