// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { useUser, setRooftop, setCustomerConfig, setOrganizationConfig } from '@store';
import { enrichCustomerConfig, enrichOrganizationConfig } from '@utils/configsService';
import { checkForPowerRole, getClaimsFromToken } from '@features/auth/services/authService';
import { Customers, useGetHeaderRooftopsLazyQuery, useGetAllowedHeaderRooftopsLazyQuery } from '@gql/schema';

import { css } from '@emotion/css';
import { Theme, useTheme, Autocomplete, InputAdornment, TextField, Tooltip } from '@mui/material';

import { BiStore } from 'react-icons/bi';

// DEFAULTS ---------------------------------------------------------------- //

const getDefaultRooftopId = (rooftopId?: number) => {
  const localRooftopId = parseInt(localStorage?.getItem(`rooftopId`) || `0`);
  return localRooftopId || rooftopId || 0;
};

// COMPONENT ---------------------------------------------------------------- //

const HeaderRooftopSelect = () => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const user = useUser();
  const hasAdminPrivileges = checkForPowerRole(user);

  const [getHeaderRooftops] = useGetHeaderRooftopsLazyQuery();
  const [getAllowedHeaderRooftops] = useGetAllowedHeaderRooftopsLazyQuery();

  const [rooftops, setRooftops] = React.useState<Customers[]>([]);
  const [rooftopId, setRooftopId] = React.useState<number>(0);

  const [errorText, setErrorText] = React.useState<string>(``);

  React.useEffect(() => {
    const getUserClaims = async () => {
      const claims = await getClaimsFromToken();
      return claims;
    };
    const fetchRooftopsBasedOnRole = async (userRole: string, allowedCustomerIds?: number[]) => {
      if (!userRole) throw new Error(`User role not found`);

      if (userRole !== 'admin' && userRole !== 'dealer-admin' && userRole !== 'dealer-super-admin')
        throw new Error(`User role not allowed`);
      if (userRole === 'dealer-admin' && !allowedCustomerIds?.length) throw new Error(`User has no allowed customers`);

      if (userRole === 'admin') {
        const res = await getHeaderRooftops();
        return res;
      }
      if ((userRole === 'dealer-admin' || userRole === 'dealer-super-admin') && allowedCustomerIds?.length) {
        const res = await getAllowedHeaderRooftops({ variables: { customerIdArr: allowedCustomerIds } });
        return res;
      }
    };
    const fetchRooftopsAndSetState = async () => {
      try {
        const userToken = await user?.getToken();
        if (!userToken) throw new Error(`User token not found`);

        const userClaims = await getUserClaims();
        if (!userClaims) throw new Error(`User claims not found`);

        const userRole = userClaims['x-hasura-default-role'];
        if (!userRole) throw new Error(`User role not found`);

        const allowedCustomerIds = userClaims['x-hasura-allowed-customers'] || [];

        const res = await fetchRooftopsBasedOnRole(userRole, allowedCustomerIds);
        const resRooftops = res?.data?.customers || [];

        const defaultCustomerId =
          typeof userClaims['x-hasura-customer-id'] === 'number'
            ? userClaims['x-hasura-customer-id']
            : userRole === 'admin'
              ? 9
              : 0;
        const defaultRooftopId = getDefaultRooftopId(defaultCustomerId);
        const defaultRooftop = resRooftops?.find(r => r?.id === defaultRooftopId);

        setRooftops(resRooftops as Customers[]);
        setRooftopId(defaultRooftopId);
        setRooftop(defaultRooftop as Customers);

        setErrorText(``);

        if (!defaultRooftop) return;

        const customerConfig = await enrichCustomerConfig(
          userToken,
          defaultRooftop?.config,
          defaultRooftop?.organization?.config
        );
        const organizationConfig = await enrichOrganizationConfig(userToken, defaultRooftop?.organization?.config);

        if (customerConfig) setCustomerConfig(customerConfig);
        if (organizationConfig) setOrganizationConfig(organizationConfig);
      } catch (err) {
        console.error(`Failed to fetch global markets:`, err);
        setErrorText(`Failed to fetch global markets`);
      }
    };
    fetchRooftopsAndSetState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (errorText) console.error(`HeaderRooftopSelect error:`, errorText);
  }, [errorText]);

  const handleRooftopChange = async (rooftop?: Customers) => {
    const userToken = await user?.getToken();
    if (!userToken) throw new Error(`User token not found`);

    const selectedRooftopId = rooftop?.id || 0;
    localStorage?.setItem(`rooftopId`, JSON.stringify(selectedRooftopId));
    setRooftopId(selectedRooftopId);
    setRooftop(rooftop);

    if (!rooftop) return;

    const customerConfig = await enrichCustomerConfig(userToken, rooftop?.config, rooftop?.organization?.config);
    const organizationConfig = await enrichOrganizationConfig(userToken, rooftop?.organization?.config);

    if (customerConfig) setCustomerConfig(customerConfig);
    if (organizationConfig) setOrganizationConfig(organizationConfig);
  };

  if (hasAdminPrivileges)
    return (
      <Tooltip title='Select one of your markets'>
        <Autocomplete
          className={cls.rooftopAutocomplete}
          fullWidth
          disableClearable
          loading={!rooftops?.length}
          loadingText={`Loading markets...`}
          noOptionsText={`No markets found`}
          options={rooftops}
          getOptionKey={r => r?.id || 0}
          getOptionLabel={r => r?.name || ``}
          value={rooftops?.find(r => r?.id === rooftopId) || null}
          onChange={(_event, value) => handleRooftopChange(value)}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              name={`header-rooftop-select`}
              className={cls.rooftopTextField}
              placeholder='Select market...'
              // helperText={errorText}
              slotProps={{
                input: {
                  ...params?.InputProps,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <BiStore className={cls.rooftopIcon} />
                    </InputAdornment>
                  ),
                },
              }}
            />
          )}
        />
      </Tooltip>
    );
  return null;
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  const styles = {
    rooftopAutocomplete: css`
      min-width: 180px;
      max-width: 360px;
      ${theme?.breakpoints?.down('sm')} {
        max-width: 100%;
      }
    `,
    rooftopTextField: css`
      & .MuiOutlinedInput-root {
        & fieldset {
          border-color: transparent;
          background-color: ${theme?.palette?.action?.selected};
          color: ${theme?.palette?.text?.primary};
          transition: all 0.1s ease-in-out;
        }
        &:hover fieldset {
          border-color: transparent;
          background-color: ${theme?.palette?.action?.focus};
        }
        &.Mui-focused fieldset {
          border-color: transparent;
        }
      }
    `,
    rooftopIcon: css`
      margin-left: 8px;
      margin-right: -6px;
      font-size: 18px;
      color: ${theme?.palette?.action?.active};
    `,
  };

  return styles;
};

// EXPORT ---------------------------------------------------------------- //

export default HeaderRooftopSelect;
