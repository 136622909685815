// DEPENDENCIES ---------------------------------------------------------------- //

import React, { useState, useEffect, ReactNode, Dispatch, SetStateAction } from 'react';
import dashboardService from '../services/dashboardService';
import { useRooftop } from '@store';

// TYPES ---------------------------------------------------------------- //

interface DashboardProviderProps {
  children?: ReactNode;
}

interface DashboardContextType {
  customerId: number;
  range: string | null;
  start: string;
  end: string;
  disablePickers: boolean;
  sort: string | null;
  search: string | null;
  conciergeTag: boolean;
  hangtagTag: boolean;
  statusTags: string[];
  makeTags: string[];
  modelTags: string[];
  yearTags: string[];
  colorTags: string[];
  workflowsetTags: string[];
  rooftopTag: boolean;
  customerNameTags: string[];
  userNameTags: string[];
  prelimStart: string;
  prelimEnd: string;
  customerIsThirdPartyPayer: boolean;
  setCustomerId: Dispatch<SetStateAction<number>>;
  setRange: Dispatch<SetStateAction<string | null>>;
  setStart: Dispatch<SetStateAction<string>>;
  setEnd: Dispatch<SetStateAction<string>>;
  setDisablePickers: Dispatch<SetStateAction<boolean>>;
  setSort: Dispatch<SetStateAction<string | null>>;
  setSearch: Dispatch<SetStateAction<string | null>>;
  setConciergeTag: Dispatch<SetStateAction<boolean>>;
  setHangtagTag: Dispatch<SetStateAction<boolean>>;
  setStatusTags: Dispatch<SetStateAction<string[]>>;
  setMakeTags: Dispatch<SetStateAction<string[]>>;
  setModelTags: Dispatch<SetStateAction<string[]>>;
  setYearTags: Dispatch<SetStateAction<string[]>>;
  setColorTags: Dispatch<SetStateAction<string[]>>;
  setWorkflowsetTags: Dispatch<SetStateAction<string[]>>;
  setRooftopTag: Dispatch<SetStateAction<boolean>>;
  setCustomerNameTags: Dispatch<SetStateAction<string[]>>;
  setUserNameTags: Dispatch<SetStateAction<string[]>>;
  setPrelimStart: Dispatch<SetStateAction<string>>;
  setPrelimEnd: Dispatch<SetStateAction<string>>;
  setCustomerIsThirdPartyPayer: Dispatch<SetStateAction<boolean>>;
  handleClearFilters: () => void;
}

// COMPONENT ---------------------------------------------------------------- //

const DashboardContext = React.createContext<DashboardContextType | undefined>(undefined);

const DashboardProvider = ({ children }: DashboardProviderProps) => {
  const rooftop = useRooftop();

  useEffect(() => {
    if (rooftop) {
      setCustomerId(rooftop?.id);
    }
  }, [rooftop]);

  // Data State & Handlers
  const [customerId, setCustomerId] = useState<number>(0);
  const [range, setRange] = useState<string | null>(dashboardService.getRange());
  const [start, setStart] = useState<string>(
    dashboardService.getStart(localStorage?.getItem('dashboard-range') || `3day`)
  );
  const [end, setEnd] = useState<string>(dashboardService.getEnd(localStorage?.getItem('dashboard-range') || '3day'));
  const [disablePickers, setDisablePickers] = useState(dashboardService.getDisable(null));
  const [sort, setSort] = useState<string | null>(`new`);
  const [search, setSearch] = useState<string | null>(``);
  const [conciergeTag, setConciergeTag] = useState(dashboardService.getConcierge());
  const [hangtagTag, setHangtagTag] = useState(dashboardService.getConcierge());
  const [statusTags, setStatusTags] = useState<string[]>([]);
  const [makeTags, setMakeTags] = useState<string[]>([]);
  const [modelTags, setModelTags] = useState<string[]>([]);
  const [yearTags, setYearTags] = useState<string[]>([]);
  const [colorTags, setColorTags] = useState<string[]>([]);
  const [rooftopTag, setRooftopTag] = useState<boolean>(dashboardService.getRooftop());
  const [customerNameTags, setCustomerNameTags] = useState<string[]>([]);
  const [userNameTags, setUserNameTags] = useState<string[]>([]);
  const [workflowsetTags, setWorkflowsetTags] = useState<string[]>([]);
  const [prelimStart, setPrelimStart] = useState<string>('');
  const [prelimEnd, setPrelimEnd] = useState<string>('');
  const [customerIsThirdPartyPayer, setCustomerIsThirdPartyPayer] = useState<boolean>(false);

  const handleClearFilters = () => {
    setDisablePickers(true);
    // setRange(`3day`);
    // setStart(dashboardService.getStart(`3day`));
    // setEnd(dashboardService.getEnd(`3day`));
    setSort(`new`);
    setSearch(``);
    setStatusTags([]);
    setMakeTags([]);
    setModelTags([]);
    setYearTags([]);
    setColorTags([]);
    setCustomerNameTags([]);
    setUserNameTags([]);
    setWorkflowsetTags([]);
    setConciergeTag(false);
    setRooftopTag(false);
    setHangtagTag(false);
    localStorage.removeItem(`dashboard-group-concierge`);
    localStorage.removeItem(`dashboard-group-rooftop`);
    localStorage.removeItem(`dashboard-group-hangtag`);
  };

  const ctx: DashboardContextType = {
    customerId,
    range,
    start,
    end,
    disablePickers,
    sort,
    search,
    conciergeTag,
    hangtagTag,
    statusTags,
    makeTags,
    modelTags,
    yearTags,
    colorTags,
    workflowsetTags,
    rooftopTag,
    customerNameTags,
    userNameTags,
    prelimStart,
    prelimEnd,
    customerIsThirdPartyPayer,
    setCustomerId,
    setRange,
    setStart,
    setEnd,
    setDisablePickers,
    setSort,
    setSearch,
    setConciergeTag,
    setHangtagTag,
    setStatusTags,
    setMakeTags,
    setModelTags,
    setYearTags,
    setColorTags,
    setWorkflowsetTags,
    setRooftopTag,
    setCustomerNameTags,
    setUserNameTags,
    setPrelimStart,
    setPrelimEnd,
    setCustomerIsThirdPartyPayer,
    handleClearFilters,
  };

  return <DashboardContext.Provider value={ctx}>{children}</DashboardContext.Provider>;
};

// EXPORT ---------------------------------------------------------------- //

const useDashboard = (): DashboardContextType => {
  const context = React.useContext(DashboardContext);
  if (context === undefined) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
};

export { useDashboard, DashboardProvider };
