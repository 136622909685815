// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { useDisputeArMoveMutation } from '@gql/schema';
import { capFirst, checkNeg, getMonetaryValue } from '@services/formatService';

import { css } from '@emotion/css';
import { Grid2 as Grid, Icon, InputAdornment, TextField, Theme, Typography, useTheme } from '@mui/material';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '@components/Modal';

import Spacer from '@components/Utils/Spacer';

// TYPES ---------------------------------------------------------------- //

type DisputeModalProps = {
  input?: any;
  open?: boolean;
  onClose?: () => void;
  refetch?: () => void;
};

// COMPONENT ---------------------------------------------------------------- //

const DisputeModal = ({ input, open, onClose, refetch }: DisputeModalProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);
  const clsx = useSxStyles(theme);

  const [reason, setReason] = React.useState(``);

  const [disputeARMove] = useDisputeArMoveMutation();

  React.useEffect(() => {
    if (input?.dispute_reason) setReason(input.dispute_reason);
  }, [input]);

  const handleInputChange = (setHandler: (value: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setHandler && event) setHandler(event.target.value);
  };

  const handleClose = () => {
    setReason(``);
    if (refetch) refetch();
    if (onClose) onClose();
  };

  const addDispute = async () => {
    try {
      const res = await disputeARMove({
        variables: { moveId: input?.move_id, disputed: true, disputeReason: reason },
      });
      if (res?.data?.update_armoves?.affected_rows) {
        handleClose();
        return res;
      }
    } catch (err) {
      console.error(`Error disputing move:`, err);
    }
  };
  const cancelDispute = async () => {
    try {
      const res = await disputeARMove({
        variables: { moveId: input?.move_id, disputed: false, disputeReason: `` },
      });
      if (res?.data?.update_armoves?.affected_rows) {
        handleClose();
        return res;
      }
    } catch (err) {
      console.error(`Error canceling dispute:`, err);
    }
  };

  return (
    <Modal open={open || false}>
      {open && input ? (
        <>
          {!input?.disputed ? (
            <>
              <ModalHeader handleClose={handleClose}>Dispute</ModalHeader>

              <ModalContent
                subtitle={`By disputing a move, an administrator will review your reason for the dispute. If you do not provide a reason, we will not be able to assist you. You should only dispute if a detail of this move is incorrect, or you want to exclude this move from\xa0the\xa0total.`}
              >
                <div className={cls.charge}>
                  <Typography variant='subtitle2' sx={clsx.detailMainText}>
                    Charge Details:
                  </Typography>

                  {input?.details?.map((detail?: any, i?: number) => {
                    return (
                      <React.Fragment key={i}>
                        <Typography variant='body2' sx={clsx.beegText}>
                          {capFirst(detail?.name)}
                        </Typography>

                        <Grid container spacing={1}>
                          <Grid size={{ xs: 9 }}>
                            <Typography sx={{ ...clsx.smolText, ...clsx.typeLeft }}>{detail?.notes}</Typography>
                          </Grid>

                          <Grid size={{ xs: 3 }}>
                            <Typography sx={{ ...clsx.smolText, ...clsx.typeRight }}>
                              {getMonetaryValue(Number(detail?.amount))}
                            </Typography>
                          </Grid>
                        </Grid>

                        <div className={cls.lineBreak} />
                      </React.Fragment>
                    );
                  })}

                  {Number(input?.discount_amount) ? (
                    <>
                      <Typography variant='body2' sx={clsx.beegText}>
                        Discount
                      </Typography>

                      <Grid container spacing={1}>
                        <Grid size={{ xs: 9 }}>
                          <Typography sx={{ ...clsx.smolText, ...clsx.typeLeft }}>{input?.discount_reason}</Typography>
                        </Grid>

                        <Grid size={{ xs: 3 }}>
                          <Typography sx={{ ...clsx.smolText, ...clsx.typeRight }}>
                            {getMonetaryValue(Number(input?.discount_amount))}
                          </Typography>
                        </Grid>
                      </Grid>

                      <div className={cls.lineBreak} />
                    </>
                  ) : null}

                  <Spacer size={1} />

                  <Typography variant='subtitle2' sx={clsx.detailValText}>
                    Total Move Charge:{' '}
                    {getMonetaryValue(checkNeg(Number(input?.due_amount) - Number(input?.discount_amount)))}
                  </Typography>
                </div>

                <Spacer size={3} />

                <TextField
                  required
                  fullWidth
                  multiline
                  label='Reason'
                  placeholder='Enter a brief reason for the dispute...'
                  variant='outlined'
                  value={reason}
                  onChange={handleInputChange(setReason)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                        <Icon color='disabled' fontSize='small'>
                          announcement
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </ModalContent>

              <ModalFooter>
                <ModalAction onClick={() => addDispute()} disabled={reason.length < 1}>
                  Dispute
                </ModalAction>
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalHeader handleClose={handleClose}>Cancel Dispute</ModalHeader>

              <ModalContent
                subtitle={`By canceling a dispute, the move will no longer be flagged for review. No additional action\xa0is\xa0required.`}
              >
                <div className={cls.dispute}>
                  <Typography sx={clsx.disputeHeadText}>Your Disputed Reason: </Typography>

                  <Typography sx={clsx.disputeBodyText}>{input?.dispute_reason}</Typography>
                </div>
              </ModalContent>

              <ModalFooter>
                <ModalAction onClick={() => cancelDispute()}>Confirm</ModalAction>
              </ModalFooter>
            </>
          )}
        </>
      ) : null}
    </Modal>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  charge: css`
    position: relative;
    width: 100%;
    padding: ${theme?.spacing(2)};
    border: 1px solid ${theme?.palette?.divider};
    border-radius: 6px;
    background-color: ${theme?.palette?.action?.hover};
  `,
  dispute: css`
    padding: ${theme?.spacing(2)};
    border: 1px solid ${theme?.palette?.divider};
    border-radius: 6px;
  `,

  lineBreak: css`
    width: 100%;
    border-bottom: 1px solid ${theme?.palette?.divider};
    margin: 8px 0;
  `,
});

const useSxStyles = (theme?: Theme) => ({
  beegText: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  smolText: {
    fontSize: '12px',
    lineHeight: '14px',
  },
  detailMainText: {
    marginBottom: theme?.spacing(0.75),
    fontSize: '20px',
    lineHeight: '24px',
  },
  detailKeyText: {
    textAlign: 'left',
    fontSize: '16px',
    lineHeight: '20px',
  },
  detailValText: {
    textAlign: 'right',
    fontSize: '16px',
    lineHeight: '20px',
  },

  disputeHeadText: {
    color: theme?.palette?.text?.secondary,
    fontSize: '14px',
    fontWeight: 500,
  },
  disputeBodyText: {
    fontSize: '14px',
    fontWeight: 400,
  },

  typeLeft: {
    textAlign: 'left',
  },
  typeRight: {
    textAlign: 'right',
  },
});

// EXPORT ---------------------------------------------------------------- //

export default DisputeModal;
