import React from 'react';
import { css } from '@emotion/css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ExportToCsv } from 'export-to-csv';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/react';

import { Grid2 as Grid, Icon, Paper, SxProps, Tooltip, Theme, Typography, useTheme } from '@mui/material';
import { BiBookmarkHeart, BiCar, BiFile } from 'react-icons/bi';
import { FaClipboardCheck } from 'react-icons/fa';

import {
  Appraisals_Insert_Input,
  Appraisal_Vehicles,
  useUpdateFavoriteAppraisalsMutation,
  useInsertAppraisalsMutation,
} from '@gql/schema';
import { useTools } from '@hooks/useTools';
import AppraisalsTagFilter from '@features/appraisals/components/AppraisalsTagFilter';
import Table from '@components/TableComponents/Table';
import Column from '@components/TableComponents/Table';

const log = false;

const last8Digits = (vin: string) => {
  if (vin && vin.length >= 8) return vin.slice(-8);
};

function cleansePhoneNumber(num: string) {
  var cleaned = ('' + num).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

const defaultOrder = `desc`;
const defaultOrderBy = `APPRAISAL_TIME`;

interface AppraisalRow {
  VEHICLE: string | null;
  VIN: string | null;
  CONSUMER_NAME: string | null;
  CONSUMER_PHONE: string | null;
  APPRAISAL_TIME: string | null;
  STATUS: JSX.Element;
  FAVORITE: JSX.Element;
  vehicle: Appraisal_Vehicles;
}
interface AppraisalsTableProps {
  appraisalVehicles: Appraisal_Vehicles[];
  refetch: () => void;
  allowedMoveTypes: string[];
  setApiLoading: (value: boolean) => void;
  customerId: number;
}

const AppraisalsTable: React.FC<AppraisalsTableProps> = props => {
  const { appraisalVehicles, refetch, allowedMoveTypes, setApiLoading, customerId } = props;
  const theme = useTheme();
  const cls = useStyles(theme);
  const clsx = useSxStyles(theme);
  const { goToMoveDetails, goToRoute } = useTools();

  const [rows, setRows] = React.useState<AppraisalRow[]>([]);

  const [search, setSearch] = React.useState(``);

  // const [apiLoading, setApiLoading] = React.useState(false);

  // const [isConcierge, setIsConcierge] = React.useState(getDefaultIsConcierge());
  const [statusTags, setStatusTags] = React.useState<string[]>([]);
  const [makeTags, setMakeTags] = React.useState<string[]>([]);
  const [modelTags, setModelTags] = React.useState<string[]>([]);
  const [yearTags, setYearTags] = React.useState<string[]>([]);
  const [colorTags, setColorTags] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (appraisalVehicles && appraisalVehicles.length > 0) {
      generateAppraisalRows();
    }
  }, [appraisalVehicles, search, statusTags, makeTags, modelTags, yearTags, colorTags]);

  const [favoriteAppraisalUpdate] = useUpdateFavoriteAppraisalsMutation();
  const [insertAppraisalMutation] = useInsertAppraisalsMutation();

  // Get our 3rd party API vehicle data
  const getBlackBookVehicleData = async (vehicleVin: string) => {
    if (vehicleVin) {
      // Set VIN based on the appraisal VIN passed in
      const vin = vehicleVin;

      // Set customer ID based on what system we are currently using
      let cid = `test`;
      if (import.meta.env.VITE_ENV === `production`) cid = customerId.toString();

      // Call BlackBook with params
      let res = await axios.get(
        `${import.meta.env.VITE_BB_URL}/UsedCarWS/UsedCarWS/UsedVehicle/VIN/${vin}?mileage=0&evm_flag=BOTH&template=19&customerid=${cid}&username=${import.meta.env.VITE_BB_USER}&password=${import.meta.env.VITE_BB_PASS}`
      );

      // If BlackBook gives back valid data
      if (
        res &&
        res.data &&
        res.data.used_vehicles &&
        res.data.used_vehicles.used_vehicle_list &&
        res.data.used_vehicles.used_vehicle_list.length > 0
      ) {
        return res.data.used_vehicles.used_vehicle_list[0];
      }
      // Else return null and give error
      else {
        console.error(`Vehicle not found by BlackBook!`);
        return null;
      }
    }
  };

  // Insert an appraisal into the db
  const insertAppraisal = async (appraisalToInsert: Appraisals_Insert_Input) => {
    try {
      let res = await insertAppraisalMutation({
        variables: { appraisals: [appraisalToInsert] },
      });

      if (
        res?.data?.insert_appraisals &&
        res?.data?.insert_appraisals?.returning &&
        res?.data?.insert_appraisals?.returning?.length > 0
      ) {
        return res.data.insert_appraisals.returning[0];
      } else return null;
    } catch (err) {
      Sentry.captureException(err);
      console.error(`Error inserting appraisal record:`, err);
      return null;
    }
  };

  // Called when the heart is clicked
  const favoriteAppraisal = async (appraisalId: number | null, isFavorite: boolean) => {
    if (appraisalId) {
      try {
        const res = await favoriteAppraisalUpdate({
          variables: { id: appraisalId, favorite: isFavorite ? false : true },
        });
        if (
          res?.data?.update_appraisals &&
          res?.data?.update_appraisals?.returning &&
          res?.data?.update_appraisals?.returning?.length > 0
        ) {
          let favRes = res.data.update_appraisals.returning[0];
          log && console.log(`FAVORITED Appraisal #${favRes.id}`);
          refetch();
        } else {
          console.error(`Failed to favorite appraisal record!`);
          toast.error(`Failed to favorite appraisal record!`, { toastId: `appraisal-error` });
        }
      } catch (err) {
        Sentry.captureException(err);
        console.error(`Error favoriting appraisal record:`, err);
      }
    } else {
      console.error(`Appraisal record does not exist, cannot favorite!`);
      toast.error(`Please view the appraisal record before favoriting it!`, { toastId: `appraisal-error` });
    }
  };

  // If appraisal record doesnt exist (based on VIN for now), insert a new one
  //   Then call 3rd party API and update appraisal record's status and vehicle_data
  // Load appraisal details page (getAppraisalById is queried there)
  // Should only take in ID once we set up appraisals view in db
  const goToAppraisalDetails = async (vehicle: Appraisal_Vehicles) => {
    setApiLoading(true);

    // If vin exists
    if (vehicle && vehicle.vin) {
      // If an appraisal already exists, go to it
      if (vehicle.appraisal && vehicle.appraisal.id) {
        log && console.log(`Appraisal exists! Huzzah!`, vehicle.appraisal);
        goToRoute(`/appraisals/${vehicle.appraisal.id}`);
      }
      // Else make a new appraisal and call 3rd party API
      else {
        // Get 3rd party API vehicle data
        let apiVehicle = await getBlackBookVehicleData(vehicle.vin);
        log && console.log(`API vehicle data:`, apiVehicle);

        const newAppraisal = {
          customer_id: customerId || null,
          status: apiVehicle ? `new` : `no data`,
          vin: vehicle.vin,
          vehicle_data: apiVehicle || null,
        };

        // Set appraisal response to what was inserted
        let insertAppraisalRes = await insertAppraisal(newAppraisal);
        log && console.log(`INSERT Appraisal res:`, insertAppraisalRes);

        // If response is successful at inserting an appraisal, go to the appraisal details
        if (insertAppraisalRes && insertAppraisalRes.id) goToRoute(`/appraisals/${insertAppraisalRes.id}`);
        // Else show an error
        else toast.error(`Something went wrong! Try refreshing the page.`, { toastId: `appraisal-error` });
      }
    }
    // Else show an error
    else toast.error(`VIN is missing or invalid!`, { toastId: `appraisal-error` });

    setApiLoading(false);
  };

  const handleClearFilters = () => {
    setSearch(``);
    setStatusTags([]);
    setMakeTags([]);
    setModelTags([]);
    setYearTags([]);
    setColorTags([]);
  };

  // Filter down only allowed moves
  const applyAllowed = (dataset: Appraisal_Vehicles[], configAllowedMoveTypes: string[]) => {
    if (dataset && dataset.length > 0 && configAllowedMoveTypes && configAllowedMoveTypes.length > 0) {
      let allowedArray: Appraisal_Vehicles[] = [];

      if (configAllowedMoveTypes.includes(`concierge`)) {
        log && console.log(`Concierge moves allowed!`);
        const tmpArray = dataset.filter(result => result?.move?.consumer_pickup);
        allowedArray = [...allowedArray, ...tmpArray];
      }
      if (configAllowedMoveTypes.includes(`operations`)) {
        log && console.log(`Operations moves allowed!`);
        const tmpArray = dataset.filter(result => !result?.move?.consumer_pickup);
        allowedArray = [...allowedArray, ...tmpArray];
      }

      return allowedArray;
    } else return [];
  };

  // Apply sorting for table view
  const applySorting = (dataset: Appraisal_Vehicles[]) => {
    if (dataset && dataset.length > 0)
      return dataset.sort((a, b) => {
        const aTime = a?.move?.ready_by;
        const bTime = b?.move?.ready_by;
        if (!aTime) return 1;
        if (!bTime) return -1;
        if (aTime > bTime) return -1;
        if (aTime < bTime) return 1;
        return 0;
      });
    else return [];
  };

  // Apply multiple filter options on the dataset
  const applyFilters = (dataset: Appraisal_Vehicles[]) => {
    let filterResults = dataset;

    if (statusTags && statusTags.length > 0) {
      filterResults = filterResults.filter(vehicle => {
        const appraisal = vehicle.appraisal || {
          id: null,
          last_save_time_utc: null,
          last_fetch_time_utc: null,
          favorite: false,
          vehicle: { move: { ready_by: null } },
        };
        const appraisalTime =
          appraisal.last_save_time_utc || appraisal.last_fetch_time_utc || vehicle?.move?.ready_by || null;
        let appraisalViewed = false;

        if (appraisal.id && appraisalTime && dayjs(appraisalTime).isAfter(dayjs().subtract(1, 'month'))) {
          appraisalViewed = true;
        }
        if (appraisalViewed && statusTags.includes(`Viewed`)) return true;
        if (!appraisalViewed && statusTags.includes(`Not Viewed`)) return true;
        return false;
      });
    }
    if (makeTags && makeTags.length > 0) {
      filterResults = filterResults.filter((vehicle: Appraisal_Vehicles) => {
        if (vehicle?.move?.vehicle_make && makeTags.includes(vehicle?.move?.vehicle_make)) return true;
        return false;
      });
    }
    if (modelTags && modelTags.length > 0) {
      filterResults = filterResults.filter((vehicle: Appraisal_Vehicles) => {
        if (vehicle?.move?.vehicle_model && modelTags.includes(vehicle?.move?.vehicle_model)) return true;
        return false;
      });
    }
    if (yearTags && yearTags.length > 0) {
      filterResults = filterResults.filter((vehicle: Appraisal_Vehicles) => {
        if (vehicle?.move?.vehicle_year && yearTags.includes(vehicle?.move?.vehicle_year)) return true;
        return false;
      });
    }
    if (colorTags && colorTags.length > 0) {
      filterResults = filterResults.filter((vehicle: Appraisal_Vehicles) => {
        if (vehicle?.move?.vehicle_color && colorTags.includes(vehicle?.move?.vehicle_color as never)) return true;
        return false;
      });
    }

    let searchResults = filterResults;
    if (search && search.length > 0) {
      searchResults = searchResults.filter((vehicle: Appraisal_Vehicles) => {
        if (
          (vehicle?.move?.vehicle_year && (vehicle?.move?.vehicle_year + ``).toLocaleLowerCase().includes(search)) ||
          (vehicle?.move?.vehicle_make && (vehicle?.move?.vehicle_make + ``).toLocaleLowerCase().includes(search)) ||
          (vehicle?.move?.vehicle_model && (vehicle?.move?.vehicle_model + ``).toLocaleLowerCase().includes(search)) ||
          (vehicle?.vin && (vehicle?.vin + ``).toLocaleLowerCase().includes(search))
        )
          return true;
        else return false;
      });
    }

    return searchResults;
  };

  const generateCSV = () => {
    // Create rows and options for CSV
    const createCsvRow = (vehicle: Appraisal_Vehicles) => {
      return {
        VEHICLE:
          `${vehicle?.move?.vehicle_year || ``} ${vehicle?.move?.vehicle_make || ``} ${vehicle?.move?.vehicle_model || ``}` ||
          `-`,
        VIN: vehicle.vin || `-`,
        STATUS: (vehicle.appraisal && vehicle.appraisal.status) || `-`,
        APPRAISAL_TIME: vehicle?.move?.ready_by || `-`,
      };
    };
    const csvRows = appraisalVehicles?.map(move => createCsvRow(move));
    const csvOptions = {
      filename: `Potential_Appraisal_Vehicles`,
      showTitle: true,
      title: `Potential_Appraisal_Vehicles`,
      useKeysAsHeaders: true,
    };

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  };

  const handleAppraisalDetailsClick = (vehicle: Appraisal_Vehicles) => {
    goToAppraisalDetails(vehicle);
  };

  const handleMoveDetailsClick = (vehicle: Appraisal_Vehicles) => {
    if (vehicle?.move?.id) {
      goToMoveDetails(vehicle.move.id.toString());
    }
  };

  const rowActions = [
    {
      name: 'appraisal-details',
      label: 'Go To Appraisal Details',
      icon: <FaClipboardCheck className={cls.menuIcon} />,
      handler: (row: AppraisalRow) => handleAppraisalDetailsClick(row.vehicle),
    },
    {
      name: 'move-details',
      label: 'Go To Move Details',
      icon: <BiCar className={cls.menuIcon} />,
      handler: (row: AppraisalRow) => handleMoveDetailsClick(row.vehicle),
    },
    {},
    {
      name: 'favorite',
      label: 'Favorite/Unfavorite',
      icon: <BiBookmarkHeart className={cls.menuIcon} />,
      color: theme?.palette?.error?.main,
      handler: (row: AppraisalRow) => favoriteAppraisal(row?.vehicle?.appraisal?.id, row?.vehicle?.appraisal?.favorite),
    },
  ];

  const tableActions = [
    {
      name: 'generate-csv',
      label: 'Generate\xa0CSV',
      icon: <BiFile className={cls.menuIcon} />,
      data: { vehicles: appraisalVehicles },
      handler: generateCSV,
    },
  ];

  // Get the vehicles and apply filters
  const generateAppraisalRows = () => {
    let clonedVehicles = [...appraisalVehicles]; //we have to clone the data so that we can sort it as the original data is immutable
    let allowedVehicles = applyAllowed(clonedVehicles, allowedMoveTypes);
    let vehicles = applySorting(allowedVehicles);
    log && console.log(`Vehicles for Appraisal Index:`, vehicles);
    const filteredData = applyFilters(vehicles);
    const rows = filteredData.map((vehicle: Appraisal_Vehicles) => {
      const appraisal = vehicle.appraisal || {
        id: null,
        last_save_time_utc: null,
        last_fetch_time_utc: null,
        favorite: false,
      };
      const appraisalTime =
        appraisal?.last_save_time_utc || appraisal?.last_fetch_time_utc || vehicle?.move?.ready_by || null;
      let appraisalViewed = false;

      if (appraisal?.id && appraisalTime && dayjs(appraisalTime).isAfter(dayjs().subtract(1, 'month')))
        appraisalViewed = true;

      return {
        VEHICLE:
          `${vehicle?.move?.vehicle_year || ``} ${vehicle?.move?.vehicle_make || ``} ${vehicle?.move?.vehicle_model || ``}` ||
          null,
        VIN: vehicle.vin || null,
        CONSUMER_NAME: vehicle?.move?.consumer_name || null,
        CONSUMER_PHONE: vehicle?.move?.consumer_phone || null,
        APPRAISAL_TIME: vehicle?.move?.ready_by || null,
        STATUS: (
          <Tooltip
            placement='top'
            title={
              appraisal.id && appraisalViewed
                ? `Vehicle has been viewed/edited in the past month`
                : `Vehicle has NOT been viewed/edited in the past month`
            }
          >
            <Icon sx={clsx.icon}>{appraisal.id && appraisalViewed ? `visibility` : `visibility_off`}</Icon>
          </Tooltip>
        ),
        FAVORITE: (
          <Tooltip placement='top' title={appraisal?.favorite ? `Unfavorite Vehicle` : `Favorite Vehicle`}>
            <Icon
              sx={appraisal.favorite ? clsx.heartActive : clsx.heart}
              onClick={e => {
                e.stopPropagation();
                favoriteAppraisal(appraisal?.id, appraisal?.favorite);
              }}
            >
              {appraisal.favorite ? `favorite` : `favorite_border`}
            </Icon>
          </Tooltip>
        ),
        vehicle: vehicle,
      };
    });
    setRows(rows);
  };

  if (!appraisalVehicles || !(appraisalVehicles.length > 0 || !rows || !(rows.length > 0))) {
    return (
      <div className={cls.notFound}>
        <Typography sx={clsx.notFoundTxt}>NO VEHICLES FOUND</Typography>
      </div>
    );
  }
  return (
    <>
      <Grid container spacing={2} wrap='nowrap'>
        <Grid>
          <AppraisalsTagFilter
            vehicles={appraisalVehicles}
            search={search}
            setSearch={setSearch}
            handleClearFilters={handleClearFilters}
            tags={{
              status: statusTags,
              make: makeTags,
              model: modelTags,
              year: yearTags,
              color: colorTags,
            }}
            setTags={{
              status: setStatusTags,
              make: setMakeTags,
              model: setModelTags,
              year: setYearTags,
              color: setColorTags,
            }}
            tableActions={tableActions}
          />
        </Grid>

        <Grid size='grow'>
          <Paper variant='custom' style={{ overflow: 'hidden' }}>
            <Table
              data={rows}
              defaultOrderBy={defaultOrderBy}
              defaultOrder={defaultOrder}
              tableAriaLabel='appraisals'
              stickyHeader={true}
              rowActions={rowActions}
              onRowClick={(data: any) => goToAppraisalDetails(data?.vehicle as Appraisal_Vehicles)}
            >
              <Column name='VEHICLE' value={(row: AppraisalRow) => row.VEHICLE || `-`} label='Vehicle' />

              <Column
                name='VIN'
                value={(row: AppraisalRow) => last8Digits(row?.VIN || '') || `-`}
                label='VIN (Last 8)'
              />

              <Column
                name='CONSUMER_NAME'
                value={(row: AppraisalRow) => row.CONSUMER_NAME || `-`}
                label='Customer Name'
              />

              <Column
                name='CONSUMER_PHONE'
                value={(row: AppraisalRow) => cleansePhoneNumber(row?.CONSUMER_PHONE || '') || `-`}
                label='Customer Phone'
              />

              <Column
                name='APPRAISAL_TIME'
                value={(row: AppraisalRow) =>
                  row.APPRAISAL_TIME ? dayjs(row.APPRAISAL_TIME).format('M/D h:mm A') : `-`
                }
                label='Appraisal Time'
              />

              <Column name='STATUS' value={(row: AppraisalRow) => row.STATUS} label='Status' />

              <Column name='FAVORITE' value={(row: AppraisalRow) => row.FAVORITE} label='Favorite' />
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

//////////////////////////////////////// STYLES ////////////////////////////////////////
const useStyles = (theme: Theme) => {
  return {
    notFound: css`
      padding: ${theme.spacing(4)};
      border: 1px solid #ddd;
      border-radius: 8px;
      margin-left: auto;
      margin-right: auto;
      background: #fff;
    `,

    menuIcon: css`
      display: block;
      margin-top: -1px;
      margin-right: 8px;
    `,
  };
};

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  icon: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    cursor: 'pointer',
  },
  heart: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.error.main,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  heartActive: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.light,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
});

export default AppraisalsTable;
