import React from 'react';
import { css } from '@emotion/css';
import dayjs from 'dayjs';

import { Container, LinearProgress, Theme, Typography, useTheme } from '@mui/material';

import { useRooftop, useCustomerConfig } from '@store';

import { Appraisal_Vehicles, useGetAppraisalRowsQuery } from '@gql/schema';
import AppraisalsDateFilter from '@features/appraisals/components/AppraisalsDateFilter';
import AppraisalsNoAccess from '@features/appraisals/components/AppraisalsNoAccess';
import AppraisalsTable from '@features/appraisals/components/AppraisalsTable';

const log = false;

//TODO: Move this to a global location- this is all dashboard filter stuff
const getDefaultDisable = () => {
  const localRange = localStorage.getItem(`appraisal-index-range`);
  if (localRange && localRange === `custom`) return false;
  else return true;
};
const getDefaultRange = () => {
  const localRange = localStorage.getItem(`appraisal-index-range`);
  if (localRange) return localRange;
  else return `this`;
};
const getDefaultStart = () => {
  const localRange = localStorage.getItem(`appraisal-index-range`);
  const localStart = localStorage.getItem(`appraisal-index-start`);
  if (localRange === `custom` && localStart) return localStart;
  else if (localRange === `last`) return dayjs.utc(dayjs().startOf('day').subtract(6, 'months')).format();
  else return dayjs.utc(dayjs().startOf('day').subtract(3, 'months')).format();
};
const getDefaultEnd = () => {
  const localRange = localStorage.getItem(`appraisal-index-range`);
  const localEnd = localStorage.getItem(`appraisal-index-end`);
  if (localRange === `custom` && localEnd) return localEnd;
  else if (localRange === `last`) return dayjs.utc(dayjs().endOf('day').subtract(3, 'months')).format();
  else return dayjs.utc(dayjs().endOf('day')).format();
};

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
function AppraisalsPage() {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sxStyles = useSxStyles(theme);
  const rooftop = useRooftop();
  const customerConfig = useCustomerConfig();

  const [disablePickers, setDisablePickers] = React.useState(getDefaultDisable());
  const [range, setRange] = React.useState(getDefaultRange());
  const [start, setStart] = React.useState(getDefaultStart());
  const [end, setEnd] = React.useState(getDefaultEnd());

  const [accessLoading, setAccessLoading] = React.useState(true);
  const [access, setAccess] = React.useState({ active: false, allowed_move_types: [] });

  const [apiLoading, setApiLoading] = React.useState(false);

  React.useEffect(() => {
    setCustomerAccess();
  }, [customerConfig]);

  const { data, loading, error, refetch } = useGetAppraisalRowsQuery({
    variables: {
      customerId: rooftop?.id || 0,
      start: start,
      end: end,
    },
  });

  // Get the customer config and set the customer's access
  const setCustomerAccess = async () => {
    if (rooftop && customerConfig) {
      try {
        const appraisalsAccess =
          customerConfig && customerConfig.appraisals ? customerConfig.appraisals : { active: false };
        setAccess(appraisalsAccess);
        setAccessLoading(false);
      } catch (err) {
        console.error(`Error getting customer config:`, err);
      }
    }
  };

  // Control range picker
  const handleRangeChange = (value: string) => {
    log && console.log(`handleRangeChange() Value:`, value);
    localStorage.setItem(`appraisal-index-range`, value);
    if (value === `this`) {
      setDisablePickers(true);
      setRange(value);
      setStart(dayjs.utc(dayjs().startOf('day').subtract(3, 'months')).format());
      setEnd(dayjs.utc(dayjs().endOf('day')).format());
    } else if (value === `last`) {
      setDisablePickers(true);
      setRange(value);
      setStart(dayjs.utc(dayjs().startOf('day').subtract(6, 'months')).format());
      setEnd(dayjs.utc(dayjs().endOf('day').subtract(3, 'months')).format());
    } else {
      setDisablePickers(false);
      setRange(value);
      setStart(getDefaultStart());
      setEnd(getDefaultEnd());
    }
  };

  // Control date pickers
  const handleDateChange = (value: string, name: string) => {
    log && console.log(`Date Change:`, { value, name });

    // Set start date
    // If the start date is more than 3 months before the end date, move the end date
    // If the start date is after the end date, move the end date
    if (name === `start`) {
      const newStart = dayjs.utc(dayjs(value).startOf('day')).format();
      if (dayjs(newStart).isBefore(dayjs(end).subtract(3, 'months'))) {
        const newEnd = dayjs.utc(dayjs(newStart).endOf('day').add(3, 'months')).format();
        localStorage.setItem(`appraisal-index-end`, newEnd);
        setEnd(newEnd);
      }
      if (dayjs(newStart).isAfter(dayjs(end))) {
        const newEnd = dayjs.utc(dayjs(newStart).endOf('day')).format();
        localStorage.setItem(`appraisal-index-end`, newEnd);
        setEnd(newEnd);
      }
      localStorage.setItem(`appraisal-index-start`, newStart);
      setStart(newStart);
    }

    // Set end date
    // If the end date is before the start date, move the start date
    // If the end date is more than 3 months after the start date, move the start date
    if (name === `end`) {
      const newEnd = dayjs.utc(dayjs(value).startOf('day')).format();
      if (dayjs(newEnd).isBefore(dayjs(start))) {
        const newStart = dayjs.utc(dayjs(newEnd).startOf('day')).format();
        localStorage.setItem(`appraisal-index-start`, newStart);
        setStart(newStart);
      }
      if (dayjs(newEnd).isAfter(dayjs(start).add(3, 'months'))) {
        const newStart = dayjs.utc(dayjs(newEnd).startOf('day').subtract(3, 'months')).format();
        localStorage.setItem(`appraisal-index-start`, newStart);
        setStart(newStart);
      }
      localStorage.setItem(`appraisal-index-end`, newEnd);
      setEnd(newEnd);
    }
  };

  //TODO: These are all loading states for the table, they should be moved there
  if (accessLoading || apiLoading || loading) return <LinearProgress />;
  if (!access.active) return <AppraisalsNoAccess />;
  return (
    <div className={styles.pageContainer}>
      <Container maxWidth='lg'>
        <Typography sx={sxStyles.head}>Appraisal Index</Typography>

        <AppraisalsDateFilter
          range={range}
          start={start}
          end={end}
          onRangeChange={handleRangeChange}
          onDateChange={handleDateChange}
          disablePickers={disablePickers}
        />

        <AppraisalsTable
          appraisalVehicles={data?.appraisal_vehicles as Appraisal_Vehicles[]}
          // loading={loading}
          // error={error}
          refetch={refetch}
          allowedMoveTypes={access.allowed_move_types}
          setApiLoading={setApiLoading}
          customerId={rooftop?.id || 0}
        />
      </Container>
    </div>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////
const useStyles = (theme: Theme) => {
  return {
    pageContainer: css`
      padding: ${theme.spacing(3)} 0;
      min-height: 100vh;
    `.toString(),
  };
};

const useSxStyles = (theme: Theme) => ({
  head: {
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
});

//////////////////////////////////////// EXPORT ////////////////////////////////////////
export default AppraisalsPage;
