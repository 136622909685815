import { css } from '@emotion/css';
import { Theme } from '@mui/material/styles';

export default (theme?: Theme, props?: object) => ({
  icon: css`
    display: block;
    margin: auto;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: #20c820;
    font-size: 80px;
  `,
});
