// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import axios from 'axios';

import { useUser } from '@store';
import useGooglePlaces from '@hooks/useGooglePlaces';

import {
  Locations,
  useUpsertMovePlannerLocationsMutation,
  useUpdateMovePlannerLocationFavoriteMutation,
} from '@gql/schema';

import { css, cx, keyframes } from '@emotion/css';
import { Theme, useTheme, Autocomplete, InputAdornment, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { BiHeart, BiLoaderCircle, BiLogoGoogle, BiSolidHeart, BiSolidMapPin } from 'react-icons/bi';

import { config } from '@config';

// TYPES ---------------------------------------------------------------- //

interface MovePlannerLocationSelectProps {
  rooftopId?: number;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  name?: string;
  label?: string;
  placeholder?: string;
  helperText?: string;
  excludeLocations?: number[];
  locations?: (Locations | null)[];
  location?: Locations | null;
  onLocationChange?: (location?: Locations | null, isNewLocation?: boolean) => void;
  onLocationFavorite?: (location?: Locations | null, favorite?: boolean) => Promise<void> | void;

  [x: string]: any;
}

interface GoogleSuggestion {
  address?: string;
  favorite?: boolean;
  latitude?: number;
  longitude?: number;
  name?: string;
  place_id?: string;
}

// COMPONENT ---------------------------------------------------------------- //

const MovePlannerLocationSelect = ({
  rooftopId,
  required,
  disabled,
  error,
  name = `location-select`,
  label = ``,
  placeholder = `Select location...`,
  helperText = ``,
  locations = [],
  location,
  onLocationChange,
  onLocationFavorite,
  ...rest
}: MovePlannerLocationSelectProps) => {
  const log = true;

  const theme = useTheme();
  const cls = useStyles(theme);

  const user = useUser();

  const [upsertLocations] = useUpsertMovePlannerLocationsMutation();
  const [updateLocationFavorite] = useUpdateMovePlannerLocationFavoriteMutation();

  const [internalError, setInternalError] = React.useState<boolean>(false);
  const [internalErrorText, setInternalErrorText] = React.useState<string>(``);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingGoogle, setLoadingGoogle] = React.useState<boolean>(false);

  const [userInput, setUserInput] = React.useState<string>(``);
  const [suggestions, setSuggestions] = React.useState<Locations[]>([]);

  const googlePlacesService = useGooglePlaces();

  //const google = new window.google.maps.places.PlacesService(document.createElement('div'));

  /** Handle changing the value */
  const handleLocationChange = async (selectedLocation?: Locations | null, isNewLocation?: boolean) => {
    // If there is no value ID, check if its a Google suggestion or empty location
    if (!selectedLocation?.id) {
      // If there is a place ID or address, we must check for an existing location
      if (selectedLocation?.place_id || selectedLocation?.address) {
        // Check for an existing location with the same place ID or address, and set the ID if it exists
        const existingLocation = locations?.find(
          (l?: Locations | null) =>
            l?.place_id === selectedLocation?.place_id || l?.address === selectedLocation?.address
        );
        if (existingLocation) {
          if (onLocationChange) onLocationChange(existingLocation, isNewLocation);
          return;
        }

        // If there is no existing location, we must create a new one using the Google data provided
        await handleBuildAndUpsertLocationFromSuggestion(selectedLocation);
        return;
      }
    }

    // Set the location normally
    if (onLocationChange) onLocationChange(selectedLocation, isNewLocation);
  };

  /** Handle input change */
  const handleInputChange = async (value: string, reason: string) => {
    setInternalError(false);
    setInternalErrorText(``);
    setUserInput(value);

    if (reason === `input` && value?.length >= 3) {
      await handleGoogleSearch(value);
    } else {
      setSuggestions([]);
    }
  };

  /** Handle changing the favorite status */
  const handleFavoriteChange = async () => {
    setLoading(true);
    if (location?.id && !loading) {
      try {
        const res = await updateLocationFavorite({
          variables: { locationId: location?.id, favoriteFlag: !location?.favorite || false },
        });
        const resSuccess = res?.data?.update_locations?.affected_rows !== 0;
        if (resSuccess && onLocationFavorite) await onLocationFavorite(location, !location?.favorite || false);
      } catch (err) {
        console.error(`Failed to favorite location:`, err);
      }
    }
    setLoading(false);
  };

  /** Handle entering focus on the input */
  const handleFocusEnter = async () => {
    setInternalError(false);
    setInternalErrorText(``);
  };

  /** Handle exiting focus on the input */
  const handleFocusExit = async () => {
    if (!location?.id && userInput?.length >= 3) {
      await handleBuildAndUpsertLocationFromAddress(userInput);
    }
  };

  /** Handle Google suggestions */
  const handleGoogleSearch = async (input: string) => {
    if (loadingGoogle) return;

    setLoadingGoogle(true);

    await googlePlacesService?.textSearch({ query: input }, (res: any) => {
      if (res) {
        // Map Google suggestions to a DB location format
        let newSuggestions = res.map((s: any) => ({
          address: s?.formatted_address,
          favorite: false,
          latitude: s?.geometry.location.lat(),
          longitude: s?.geometry.location.lng(),
          name: s?.name,
          place_id: s?.place_id,
        }));

        // Filter out existing locations
        const existingAddresses = locations?.length > 0 ? locations?.map(l => l?.address) : [];
        newSuggestions = newSuggestions?.filter((s: GoogleSuggestion) => !existingAddresses?.includes(s?.address));

        // Set the suggestions
        setSuggestions(newSuggestions);
      }
    });

    setLoadingGoogle(false);
  };

  /** General handler for upserting a location */
  const handleUpsertLocation = async (upsertableLocation?: Locations | null) => {
    try {
      const upsertRes = await upsertLocations({ variables: { upsertableLocations: [upsertableLocation] } });
      const upsertedLocation = upsertRes?.data?.insert_locations?.returning?.[0];
      if (upsertedLocation) {
        setLoading(false);
        const mutableLocation: Locations | null = JSON.parse(JSON.stringify(upsertedLocation));
        handleLocationChange(mutableLocation, true);

        console.log(`[Click to go to Move Details](${config.hopdrive.admin.url}.hopdrive.io/moves/${upsertedLocation?.id})`,)

        // If there is still no region ID, we should send a notification to admin users
        try {
          if (!upsertedLocation?.region_id) {
            const variables = {
              type: `action`,
              title: `Region null for ${upsertedLocation?.name} (ID: ${upsertedLocation?.id})`,
              body: `New location ${upsertedLocation?.name} (ID: ${upsertedLocation?.id}) was created with a null region ID. Try refetching the region from the location's details page, which you can find by clicking the link in the notes below.`,
              notes: `[Click to go to Move Details](${config.hopdrive.admin.url}moves/${upsertedLocation?.id})`,
              createdat: `now()`,
              createdby: `location-build.dealer`,
            };

            const userToken = await user?.getToken();

            const res = await axios({
              method: `POST`,
              url: `/.netlify/functions/writeAdminNotification`,
              data: {
                 variables: variables,
              },
              headers: {
                authorization: `Bearer ${userToken}`,
              },
            });

            if (res) log && console.log('notificationres', res);
          }
        } catch (err) {
          console.error(`Failed to create notification:`, err);
        }
      } else throw new Error(`No upserted location was returned.`);
    } catch (err) {
      console.error(`Failed to upsert location:`, err);
      setLoading(false);
      setInternalError(true);
      setInternalErrorText(`Failed to build location! If this problem persists, please contact us.`);
    }
  };

  /** Handle upserting a Google suggested location */
  const handleBuildAndUpsertLocationFromSuggestion = async (location: Locations | null) => {
    setLoading(true);

    // Get the user token from the store
    const userToken = await user?.getToken();

    // Build the initial upsertable location object
    let upsertableLocation = {
      active: 1,
      address: location?.address || null,
      customer_id: location?.customer_id || rooftopId,
      latitude: location?.latitude || null,
      longitude: location?.longitude || null,
      name: location?.name || null,
      place_id: location?.place_id || null,
      type: `customer`,
      createdat: `now()`,
      updatedat: `now()`,
    };

    // Fill out the rest of the location from a netlify function
    try {
      const buildRes = await axios({
        method: `POST`,
        url: `/.netlify/functions/buildLocationFromSuggestion`,
        data: {
          location: upsertableLocation,
        },
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });

      if (buildRes?.data?.success) {
        upsertableLocation = buildRes?.data?.upsertableLocation;
      }
    } catch (err) {
      console.error(`Failed to build location:`, err);
      setLoading(false);
      setInternalError(true);
      setInternalErrorText(`Failed to build location! If this problem persists, please contact us.`);
      return;
    }

    // Upsert the location
    await handleUpsertLocation(upsertableLocation);
  };

  /** Handle upserting a location based on a full address */
  const handleBuildAndUpsertLocationFromAddress = async (input?: string) => {
    setLoading(true);

    // Get the user token from the store
    const userToken = await user?.getToken();

    // Build the initial upsertable location object
    let upsertableLocation = {
      active: 1,
      address: input || null,
      customer_id: rooftopId,
      favorite: false,
      type: `customer`,
      createdat: `now()`,
      updatedat: `now()`,
    };

    // Fill out the rest of the location from a netlify function
    try {
      const buildRes = await axios({
        method: `POST`,
        url: `/.netlify/functions/buildLocationFromAddress`,
        data: {
          location: upsertableLocation,
        },
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });

      if (buildRes?.data?.success) {
        upsertableLocation = buildRes?.data?.upsertableLocation;
      }
    } catch (err) {
      console.error(`Failed to build location:`, err);
      setLoading(false);
      setInternalError(true);
      setInternalErrorText(
        `Failed to build location based on the address provided! Please provide a full address or select from the Google suggestions in the list. If this problem persists, please contact us.`
      );
      return;
    }

    // Upsert the location
    await handleUpsertLocation(upsertableLocation);
  };

  /** Location input icon component */
  const InputIcon = () => {
    const tooltipText = !location?.id ? `` : location?.favorite ? `Unfavorite Location` : `Favorite Location`;
    return (
      <Tooltip title={tooltipText} placement='top' followCursor={false}>
        <div>
          {loading || loadingGoogle ? (
            <BiLoaderCircle className={cx(cls.icon, cls.iconLoader)} size={20} />
          ) : !location?.id ? (
            <BiSolidMapPin className={cls.icon} size={20} />
          ) : location?.favorite ? (
            <BiSolidHeart className={cx(cls.icon, cls.iconHeartFull)} size={20} />
          ) : (
            <BiHeart className={cx(cls.icon, cls.iconHeart)} size={20} />
          )}
        </div>
      </Tooltip>
    );
  };

  /** Location menu item icon component */
  const MenuItemIcon = ({ option }) => {
    return (
      <>
        {option?.id ? (
          option?.favorite ? (
            <Tooltip placement='top' title='Favored Location'>
              <div>
                <BiSolidHeart className={cx(cls.icon, cls.optionIcon_favorite)} size={20} />
              </div>
            </Tooltip>
          ) : (
            <Tooltip placement='top' title='Stored Location'>
              <div>
                <BiSolidMapPin className={cx(cls.icon, cls.optionIcon_db)} size={20} />
              </div>
            </Tooltip>
          )
        ) : (
          <Tooltip placement='top' title='Google-Suggested Location'>
            <div>
              <BiLogoGoogle className={cx(cls.icon, cls.optionIcon_google)} size={20} />
            </div>
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <>
      {label ? (
        <label className={cls.locationLabel} htmlFor={name}>
          {label}
          {required ? <span className={cls.locationLabelRequired}> *</span> : null}
        </label>
      ) : null}

      <Autocomplete
        {...rest}
        fullWidth
        freeSolo
        disabled={disabled}
        loading={locations?.length ? false : true}
        loadingText={`Loading locations...`}
        noOptionsText={`No locations found`}
        options={locations.concat(suggestions)}
        filterOptions={createFilterOptions({
          stringify: (option: Locations | null) => `${option?.name || ''} ${option?.nickname || ''} ${option?.address || ''}`,
        })}
        renderOption={(props, option) => (
          <MenuItem {...props} key={`location-builder-${option?.id || option?.place_id}`} value={option?.id}>
            <div className={cls.option}>
              <MenuItemIcon option={option} />

              <div className={cls.optionBlock}>
                <Typography variant='body2' sx={{ display: `block`, fontWeight: 500 }}>
                  {option?.name}
                </Typography>
                <Typography variant='caption' sx={{ display: `block`, color: theme?.palette?.text?.secondary }}>
                  {option?.address}
                </Typography>
              </div>
            </div>
          </MenuItem>
        )}
        getOptionKey={option => option?.id || 0}
        getOptionLabel={option => option?.name || ''}
        isOptionEqualToValue={(option, value) => option?.id === value?.id || option?.place_id === value?.place_id}
        value={location}
        onChange={(event, value) => handleLocationChange(value)}
        onInputChange={(event, value, reason) => handleInputChange(value, reason)}
        onFocus={() => handleFocusEnter()}
        onBlur={() => handleFocusExit()}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            disabled={disabled}
            required={required}
            error={internalError || error}
            name={name}
            placeholder={placeholder}
            helperText={internalErrorText || helperText}
            slotProps={{
              input: {
                ...params?.InputProps,
                onKeyDown: e => {
                  if (e?.key === `Enter`) {
                    e?.stopPropagation();
                    handleFocusExit();
                  }
                },
                startAdornment: (
                  <InputAdornment
                    position='start'
                    onClick={() => handleFavoriteChange()}
                    sx={{ marginLeft: `8px`, marginRight: 0, cursor: `pointer` }}
                  >
                    <InputIcon />
                  </InputAdornment>
                ),
              },
            }}
          />
        )}
        sx={loading ? { userSelect: `none`, pointerEvents: `none` } : undefined}
      />
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  const loader = keyframes`
    0% {
      opacity: 1;
      rotate: 0deg;
    }
    6.25% {
      opacity: 0.5;
    }
    12.5% {
      opacity: 1;
    }
    18.75% {
      opacity: 0.5;
    }
    25% {
      opacity: 1;
    }
    31.25% {
      opacity: 0.5;
    }
    37.5% {
      opacity: 1;
    }
    43.75% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
      rotate: 180deg;
    }
    56.25% {
      opacity: 0.5;
    }
    62.5% {
      opacity: 1;
    }
    68.75% {
      opacity: 0.5;
    }
    75% {
      opacity: 1;
    }
    81.25% {
      opacity: 0.5;
    }
    87.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      rotate: 360deg;
    }
  `;

  const styles = {
    locationLabel: css`
      display: block;
      margin-bottom: 2px;
      margin-left: 6px;
      font-size: 12px;
      font-weight: 600;
    `,
    locationLabelRequired: css`
      color: ${theme?.palette?.error?.main};
    `,
    locationIcon: css`
      margin-left: 8px;
      margin-right: -6px;
      font-size: 18px;
      color: ${theme?.palette?.action?.active};
    `,
    locationIconDisabled: css`
      color: ${theme?.palette?.action?.disabled};
    `,

    option: css`
      display: flex;
      align-items: center;
      gap: 12px;
    `,
    optionIcon_db: css`
      color: ${theme?.palette?.text?.secondary};
    `,
    optionIcon_favorite: css`
      color: ${theme?.palette?.error?.main};
    `,
    optionIcon_google: css`
      color: ${theme?.palette?.text?.disabled};
    `,
    optionBlock: css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    `,

    iconHeart: css`
      :hover {
        color: ${theme?.palette?.error?.main};
      }
    `,
    iconHeartFull: css`
      color: ${theme?.palette?.error?.main};
    `,
    iconLoader: css`
      animation: ${loader} 5s linear infinite;
      user-select: none;
      pointer-events: none;
    `,
    iconDisabled: css`
      color: ${theme?.palette?.action?.disabled};
      user-select: none;
      pointer-events: none;
    `,

    icon: css`
      display: block;
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      transition: 0.1s ease-in-out;
    `,
  };

  return styles;
};

// EXPORT ---------------------------------------------------------------- //

export default MovePlannerLocationSelect;
