import React from 'react';
import { css } from '@emotion/css';
import { useCSVReader } from 'react-papaparse';
import {
  SxProps,
  Grid2 as Grid,
  Button,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  Icon,
  Collapse,
  Paper,
  Theme,
  useTheme,
} from '@mui/material';
import Spacer from '@components/Utils/Spacer';
import ExplanationTable from '@features/moveImport/components/ExplanationTable';

interface CsvReaderProps {
  onUpload: (results: any) => void;
  onRemove: () => void;
  dupeStr: string;
  onDupeStrChange: (value: string) => void;
}

const CsvReader: React.FC<CsvReaderProps> = props => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sxStyles = useSxStyles(theme);

  const { onUpload, onRemove, dupeStr, onDupeStrChange } = props;

  const { CSVReader } = useCSVReader();

  const [showExplanation, setShowExplanation] = React.useState(false);

  const ExplanationToggle = () => {
    return (
      <Button fullWidth sx={sxStyles.btnExplain} variant='text' onClick={() => setShowExplanation(!showExplanation)}>
        {!showExplanation ? (
          <>
            <Icon sx={sxStyles.btnIconL}>keyboard_arrow_down</Icon>
            Show Template Explanation
            <Icon sx={sxStyles.btnIconR}>keyboard_arrow_down</Icon>
          </>
        ) : (
          <>
            <Icon sx={sxStyles.btnIconL}>keyboard_arrow_up</Icon>
            Hide Template Explanation
            <Icon sx={sxStyles.btnIconR}>keyboard_arrow_up</Icon>
          </>
        )}
      </Button>
    );
  };

  return (
    <CSVReader
      onUploadAccepted={(results: any) => {
        if (onUpload) onUpload(results);
      }}
      onDragOver={(event: any) => {
        event.preventDefault();
      }}
      onDragLeave={(event: any) => {
        event.preventDefault();
      }}
      config={{ skipEmptyLines: true }}
    >
      {({ getRootProps, getRemoveFileProps, acceptedFile }: any) => (
        <>
          <Paper variant='custom' sx={{ position: `relative`, width: `100%`, padding: `12px` }}>
            <Typography sx={sxStyles.titleTxt}>Import Moves CSV</Typography>
            <Spacer size='xs' />
            <Grid container spacing={2}>
              <Grid size={{ xs: 6 }}>
                <Typography sx={sxStyles.subtitleTxt}>
                  Use the CSV template provided to build your own moves, then import that CSV here. You may also select
                  a field to prevent creating duplicates of planned or in-progess moves with the same value (Note: This
                  will not prevent duplicates within the CSV itself).
                </Typography>
              </Grid>
            </Grid>

            <Spacer />

            {/* <div className={cls.configBox}>
              <Typography className={cls.titleTxt}>Config</Typography>
              <Spacer /> */}
            <Grid container spacing={2}>
              <Grid size={{ xs: 4 }}>
                <TextField
                  fullWidth
                  select
                  label='Prevent Duplicates with Identical Field'
                  variant='outlined'
                  size='small'
                  value={dupeStr}
                  onChange={e => (onDupeStrChange ? onDupeStrChange(e.target.value) : null)}
                  InputProps={{
                    startAdornment: (
                      <>
                        <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                          <Icon color='disabled' fontSize='small'>
                            difference
                          </Icon>
                        </InputAdornment>
                      </>
                    ),
                  }}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value='reference_num'>reference_num</MenuItem>
                  <MenuItem value='vehicle_stock'>vehicle_stock</MenuItem>
                  <MenuItem value='vehicle_vin'>vehicle_vin</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            {/* </div> */}

            <Spacer />

            {!acceptedFile ? (
              <Button fullWidth sx={sxStyles.btnClickOrDrag} variant='outlined' color='primary' {...getRootProps()}>
                <Icon sx={sxStyles.btnIconL}>add</Icon>
                Click or Drag File to Import
              </Button>
            ) : (
              <>
                <Typography sx={sxStyles.fileTxt}>{acceptedFile.name}</Typography>

                <Spacer size='xxs' />

                <Button
                  fullWidth
                  sx={sxStyles.btnRemove}
                  variant='outlined'
                  onMouseUp={() => {
                    if (onRemove) onRemove();
                  }}
                  {...getRemoveFileProps()}
                >
                  <Icon sx={sxStyles.btnIconL}>close</Icon>
                  Click to Remove Imported File
                </Button>
              </>
            )}

            <Spacer size='xs' />

            <ExplanationToggle />

            <Collapse in={showExplanation}>
              <Spacer size='xs' />
              <ExplanationTable />
              <Spacer size='xs' />
              <ExplanationToggle />
            </Collapse>

            <div className={styles.download}>
              <a href='/downloads/hopdrive_move_import.csv' download>
                <Button disableElevation variant='contained' color='secondary'>
                  <Icon sx={sxStyles.btnIconL}>download</Icon>
                  Download CSV Template
                </Button>
              </a>
            </div>
          </Paper>
        </>
      )}
    </CSVReader>
  );
};

export default CsvReader;

//////////////////////// STYLES ////////////////////////

const useStyles = (theme: Theme) => {
  return {
    paper: css`
      position: relative;
      width: 100%;
      padding: ${theme.spacing(2)};
      border-radius: 8px;
      background: ${theme.palette.background.paper};
      box-shadow: ${theme.shadows[3]};
    `,
    download: css`
      position: absolute;
      top: 12px;
      right: 12px;
    `,
  };
};

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  titleTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 500,
  },
  subtitleTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  fileTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 700,
  },

  configBox: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    // border: theme.border[0],
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    background: theme.palette.background.paper,
  },
  btnClickOrDrag: {
    width: '100%',
    minHeight: 64,
    borderStyle: 'dashed',
    fontSize: 16,
  },
  btnRemove: {
    width: '100%',
    minHeight: 64,
    fontSize: 16,
  },
  btnExplain: {
    color: theme.palette.text.secondary,
  },
  btnIconL: {
    marginRight: 2,
    fontSize: 16,
  },
  btnIconR: {
    marginLeft: 2,
    fontSize: 16,
  },
  explainTable: {
    position: 'relative',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
  },
  explainRow: {},
  explainCell: {
    padding: theme.spacing(2),
  },
  lineBreakV: {
    width: 1,
    height: '100%',
    background: theme.palette.divider,
  },
});
