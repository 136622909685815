// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import axios from 'axios';

import { useUser } from '@store';

import { Lanes, Locations, useUpdateMovePlannerLaneFavoriteMutation } from '@gql/schema';

import { css, cx, keyframes } from '@emotion/css';
import { Theme, useTheme, Tooltip, Typography } from '@mui/material';
import { BiHeart, BiLoaderCircle, BiSolidInfoCircle, BiSolidHeart, BiTransfer } from 'react-icons/bi';

import { cleanseLocations } from '@features/moveCreation/services/movePlannerService';
import {
  MovePlannerContext,
  MovePlannerContextType,
  defaultLane,
  defaultLocation,
} from '@features/moveCreation/providers/MovePlannerProvider';
import MovePlannerLocationSelect from '@features/moveCreation/components/MovePlannerLocationSelect';
import MovePlannerLaneEstimates from './MovePlannerLaneEstimates';
import MovePlannerLaneFavorites from '@features/moveCreation/components/MovePlannerLaneFavorites';

// COMPONENT ---------------------------------------------------------------- //

const MovePlannerLaneForm = ({ appointmentMode }: { appointmentMode: boolean }) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const {
    showDistanceEstimates,
    showPriceEstimates,
    validation,
    rooftopId,
    type,
    consumer,
    lanes,
    lane,
    locations,
    pickup,
    delivery,
    workflowsetId,
    setLanes,
    setLane,
    setLocations,
    setPickup,
    setDelivery,
  } = React.useContext(MovePlannerContext) as MovePlannerContextType;

  const user = useUser();

  const [updateLaneFavorite] = useUpdateMovePlannerLaneFavoriteMutation();

  const [loading, setLoading] = React.useState(false);
  const [loadingFavoriteLane, setLoadingFavoriteLane] = React.useState(false);

  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState(``);

  // When the pickup or delivery location changes, update the lane - Build a new lane if one doesn't exist
  React.useEffect(() => {
    if (pickup?.id && delivery?.id) {
      const newLocations = [...(locations || [])];
      if (!locations?.find(l => l?.id === pickup?.id)) {
        newLocations.push(pickup);
      }
      if (!locations?.find(l => l?.id === delivery?.id)) {
        newLocations.push(delivery);
      }
      if (newLocations.length > locations?.length) {
        setLocations(newLocations);
      }

      const selectedLane = lanes?.find(l => l?.pickup?.id === pickup?.id && l?.delivery?.id === delivery?.id);
      if (selectedLane) {
        setLane({
          ...selectedLane,
          pickup,
          delivery,
        });
      } else if (appointmentMode) {
        handleBuildAppointmentLane();
      } else {
        handleBuildLane();
      }
    } else {
      setLane({
        ...defaultLane,
        pickup: pickup ?? defaultLocation,
        delivery: delivery ?? defaultLocation,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickup, delivery]);

  /** Handle changing the pickup location */
  const handlePickupLocationChange = (location?: Locations | null, isNewLocation?: boolean) => {
    if (location && location?.id !== delivery?.id) {
      if (isNewLocation) {
        const newLocations = locations?.length ? [...locations, location] : [location];
        setLocations(newLocations);
      }
      setPickup(location);
      return;
    } else setPickup(defaultLocation);
  };

  /** Handle changing the delivery location */
  const handleDeliveryLocationChange = (location?: Locations | null, isNewLocation?: boolean) => {
    if (location && location?.id !== pickup?.id) {
      if (isNewLocation) {
        const newLocations = locations?.length ? [...locations, location] : [location];
        setLocations(newLocations);
      }
      setDelivery(location);
      return;
    } else setDelivery(defaultLocation);
  };

  /** Handle swapping the pickup and delivery locations */
  const handleLocationsSwap = () => {
    if (!loading && !loadingFavoriteLane) {
      setPickup(delivery);
      setDelivery(pickup);
    }
  };

  /** Handle updating the favorite bool of the lane */
  const handleLaneFavorite = async () => {
    if (!loading && !loadingFavoriteLane) {
      setLoadingFavoriteLane(true);
      try {
        if (lane?.id) {
          const res = await updateLaneFavorite({ variables: { laneId: lane?.id, favoriteFlag: !lane?.favorite } });
          const resSuccess = res?.data?.update_lanes?.affected_rows !== 0;
          if (resSuccess) {
            const updatedLane = { ...lane, favorite: !lane?.favorite } as Lanes | null;
            setLanes(lanes?.map((l: Lanes | null) => (l?.id === lane?.id ? updatedLane : l)));
            setLane(updatedLane);
          }
        } else if (pickup?.id && delivery?.id) {
          const updatedLane = { ...lane, favorite: !lane?.favorite } as Lanes | null;
          setLanes(
            lanes?.map((l: Lanes | null) =>
              l?.pickup?.id === pickup?.id && l?.delivery?.id === delivery?.id ? updatedLane : l
            )
          );
          setLane(updatedLane);
        }
      } catch (err) {
        console.error(`Failed to favorite lane:`, err);
      }
      setLoadingFavoriteLane(false);
    }
  };

  /** Handle updating the favorite bool of a location */
  const handleLocationFavorite = async (
    location?: Locations | null,
    favorite?: boolean,
    type?: `pickup` | `delivery`
  ) => {
    if (location) {
      const updatedLocation = { ...location, favorite: favorite } as Locations | null;
      setLocations(locations?.map((l?: Locations | null) => (l?.id === location?.id ? updatedLocation : l)));
      if (type === `pickup`) setPickup(updatedLocation);
      if (type === `delivery`) setDelivery(updatedLocation);
    }
  };

  /** Handle building a lane */
  const handleBuildLane = async () => {
    if (pickup?.id && delivery?.id && pickup?.id !== delivery?.id) {
      setLoading(true);

      // Get the user token from the store
      const userToken = await user?.getToken();

      // Find both locations stored in state
      const pickupLocation = locations?.find(l => l?.id === pickup?.id);
      const deliveryLocation = locations?.find(l => l?.id === delivery?.id);

      // Build the initial lane object
      const laneInput = {
        customerId: rooftopId,
        pickup: pickupLocation,
        delivery: deliveryLocation,
      };

      // Fill out the rest of the lane from a netlify function
      try {
        const buildRes = await axios({
          method: `POST`,
          url: `/.netlify/functions/buildLaneAndInverse`,
          data: {
            lane: laneInput,
          },
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        });

        if (buildRes?.data?.success) {
          const builtLanes = buildRes?.data?.upsertableLanes;
          const newLanes = lanes?.length ? [...lanes, ...builtLanes] : [...builtLanes];
          setLanes(newLanes);
          setLane(builtLanes[0]);
        }
      } catch (err) {
        console.error(`Failed to build lane:`, err);
        setLoading(false);
        setError(true);
        setErrorText(`Failed to build lane! If this problem persists, please contact us.`);
        return;
      }
    }
    setLoading(false);
  };

  /** Upserts a bare-bones version of the lane object with only customer_id, pickup_id, delivery_id and description
      This is enough to return a lane id that can be inserted along with the appointment.
      A hasura event will detect that the lane is not finished and will 'enrich' the lane with its missing data after it has been inserted*/
  const handleBuildAppointmentLane = async () => {
    if (pickup?.id && delivery?.id && pickup?.id !== delivery?.id) {
      setLoading(true);

      // Get the user token from the store
      const userToken = await user?.getToken();

      // Find both locations stored in state
      const pickupLocation = locations?.find(l => l?.id === pickup?.id);
      const deliveryLocation = locations?.find(l => l?.id === delivery?.id);

      console.log('type of pickupLocation', typeof pickupLocation?.id);
      console.log('deliveryLocation', deliveryLocation);

      // Build the initial lane object
      const laneInput = {
        customerId: rooftopId,
        pickupId: pickupLocation?.id,
        deliveryId: deliveryLocation?.id,
        pickup: pickupLocation,
        delivery: deliveryLocation,
      };

      // Fill out the rest of the lane from a netlify function
      try {
        const buildRes = await axios({
          method: `POST`,
          url: `/.netlify/functions/handleLaneShellPair`,
          data: {
            variables: laneInput,
          },
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        });

        if (buildRes?.data?.success && buildRes?.data?.laneOne && buildRes?.data?.laneTwo) {
          const builtLanes = [buildRes?.data?.laneOne, buildRes?.data?.laneTwo];
          const newLanes = lanes?.length ? [...lanes, ...builtLanes] : [...builtLanes];
          setLanes(newLanes);
          setLane(builtLanes[0]);
        }
      } catch (err) {
        console.error(`Failed to build lane:`, err);
        setLoading(false);
        setError(true);
        setErrorText(`Failed to build lane! If this problem persists, please contact us.`);
        return;
      }
    }
    setLoading(false);
  };

  /** Gets the pickup location title based on the type and consumer action */
  const getPickupLocationTitle = (capitalize?: boolean) => {
    let title = `pickup`;
    if (type === `concierge`) title = consumer?.action === `pickup` ? `customer` : `service`;
    if (type === `concierge-loaner`) title = `service`;
    if (capitalize) title = title.charAt(0).toUpperCase() + title.slice(1);
    return title;
  };

  /** Gets the delivery location title based on the type and consumer action */
  const getDeliveryLocationTitle = (capitalize?: boolean) => {
    let title = `delivery`;
    if (type === `concierge`) title = consumer?.action === `pickup` ? `service` : `customer`;
    if (type === `concierge-loaner`) title = `customer`;
    if (capitalize) title = title.charAt(0).toUpperCase() + title.slice(1);
    return title;
  };

  return (
    <div className={cls.lane}>
      {type === `concierge` ? (
        <div className={cls.title}>
          <BiSolidInfoCircle className={cls.titleIcon} />

          <Typography variant='body2' sx={{ textWrap: `pretty`, color: theme?.palette?.text?.secondary }}>
            {consumer?.action === `pickup`
              ? `The vehicle will be picked up from the customer and brought to the service location.`
              : `The vehicle will be returned to the customer from the service location.`}
          </Typography>
        </div>
      ) : null}

      {type === `concierge-loaner` ? (
        <div className={cls.title}>
          <BiSolidInfoCircle className={cls.titleIcon} />

          <Typography variant='body2' sx={{ textWrap: `pretty`, color: theme?.palette?.text?.secondary }}>
            {consumer?.action === `pickup`
              ? `The loaner vehicle will be brought to the customer and the customer's vehicle will be picked up and brought to the service location.`
              : `The customer's vehicle will be returned to the customer and the loaner vehicle will be returned to the service location.`}
          </Typography>
        </div>
      ) : null}

      {type === `round-trip` ? (
        <div className={cls.title}>
          <BiSolidInfoCircle className={cls.titleIcon} />

          <Typography variant='body2' sx={{ textWrap: `pretty`, color: theme?.palette?.text?.secondary }}>
            The second move will be an inverse of this lane (Delivery {`->`} Pickup).
          </Typography>
        </div>
      ) : null}

      <div className={cls.flex}>
        <div className={cls.flexItem}>
          <MovePlannerLocationSelect
            rooftopId={rooftopId}
            required
            disabled={loading || loadingFavoriteLane}
            error={!validation?.pickup}
            label={`${getPickupLocationTitle(true)} Location`}
            placeholder={`Select the ${getPickupLocationTitle()} location...`}
            helperText={
              !validation?.pickup
                ? `Please select the ${getPickupLocationTitle()} location!`
                : pickup?.address || `No location selected`
            }
            locations={cleanseLocations(locations, delivery?.id ? [delivery?.id] : [])}
            location={pickup}
            onLocationChange={handlePickupLocationChange}
            onLocationFavorite={(location, favorite) => handleLocationFavorite(location, favorite, `pickup`)}
          />
        </div>

        <div className={cls.controls}>
          <div className={cls.controlsItem}>
            <Tooltip title='Swap Pickup & Delivery' placement='top' followCursor={false}>
              <div
                className={cx(cls.controlsSwap, !pickup?.id && !delivery?.id ? cls.controlsDisabled : null)}
                onClick={() => handleLocationsSwap()}
              >
                <BiTransfer className={cls.iconSwap} size={24} />
              </div>
            </Tooltip>
          </div>

          <div className={cls.controlsItem}>
            <Tooltip title={lane?.favorite ? `Unfavorite Lane` : `Favorite Lane`} placement='top' followCursor={false}>
              <div
                className={cx(
                  cls.controlsFavorite,
                  !lane?.id && !lane?.pickup?.id && !lane?.delivery?.id ? cls.controlsDisabled : null
                )}
                onClick={() => handleLaneFavorite()}
              >
                {loading || loadingFavoriteLane ? (
                  <BiLoaderCircle className={cls.iconLoader} size={24} />
                ) : lane?.favorite ? (
                  <BiSolidHeart className={cls.iconHeartFull} size={24} />
                ) : (
                  <BiHeart className={cls.iconHeart} size={24} />
                )}
              </div>
            </Tooltip>
          </div>
        </div>

        <div className={cls.flexItem}>
          <MovePlannerLocationSelect
            rooftopId={rooftopId}
            required
            disabled={loading || loadingFavoriteLane}
            error={!validation?.delivery}
            label={`${getDeliveryLocationTitle(true)} Location`}
            placeholder={`Select the ${getDeliveryLocationTitle()} location...`}
            helperText={
              !validation?.delivery
                ? `Please select the ${getDeliveryLocationTitle()} location!`
                : delivery?.address || `No location selected`
            }
            locations={cleanseLocations(locations, pickup?.id ? [pickup?.id] : [])}
            location={delivery}
            onLocationChange={handleDeliveryLocationChange}
            onLocationFavorite={(location, favorite) => handleLocationFavorite(location, favorite, `delivery`)}
          />
        </div>
      </div>

      <MovePlannerLaneEstimates
        showDistanceEstimates={showDistanceEstimates}
        showPriceEstimates={showPriceEstimates}
        rooftopId={rooftopId}
        type={type}
        lane={lane}
        workflowsetId={workflowsetId}
      />

      <MovePlannerLaneFavorites lanes={lanes} locations={locations} setPickup={setPickup} setDelivery={setDelivery} />
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  const loader = keyframes`
    0% {
      opacity: 1;
      rotate: 0deg;
    }
    6.25% {
      opacity: 0.5;
    }
    12.5% {
      opacity: 1;
    }
    18.75% {
      opacity: 0.5;
    }
    25% {
      opacity: 1;
    }
    31.25% {
      opacity: 0.5;
    }
    37.5% {
      opacity: 1;
    }
    43.75% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
      rotate: 180deg;
    }
    56.25% {
      opacity: 0.5;
    }
    62.5% {
      opacity: 1;
    }
    68.75% {
      opacity: 0.5;
    }
    75% {
      opacity: 1;
    }
    81.25% {
      opacity: 0.5;
    }
    87.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      rotate: 360deg;
    }
  `;

  return {
    lane: css`
      position: relative;
    `,

    title: css`
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 12px;
      border: 1px solid ${theme?.palette?.divider};
      border-radius: 10px;
      margin-bottom: 12px;
      background-color: ${theme?.palette?.action?.hover};
    `,
    titleIcon: css`
      min-width: 20px;
      min-height: 20px;
      margin-top: -2px;
      font-size: 20px;
      color: ${theme?.palette?.text?.secondary};
    `,

    controls: css`
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      border-radius: 6px;
      ${theme?.breakpoints?.down('md')} {
      }
    `,
    controlsItem: css``,
    controlsSwap: css`
      color: ${theme?.palette?.text?.secondary};
      cursor: pointer;
      transition: 0.1s ease-in-out;
    `,
    controlsFavorite: css`
      color: ${theme?.palette?.text?.secondary};
      cursor: pointer;
      transition: 0.1s ease-in-out;
    `,
    controlsDisabled: css`
      color: ${theme?.palette?.action?.disabled} !important;
      user-select: none;
      pointer-events: none;
    `,

    iconSwap: css`
      display: block;
      :hover {
        color: ${theme?.palette?.text?.primary};
      }
    `,
    iconHeart: css`
      display: block;
      :hover {
        color: ${theme?.palette?.error?.main};
      }
    `,
    iconHeartFull: css`
      display: block;
      color: ${theme?.palette?.error?.main};
    `,
    iconLoader: css`
      display: block;
      animation: ${loader} 5s linear infinite;
    `,

    flex: css`
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      ${theme?.breakpoints?.down('md')} {
        flex-direction: column;
        gap: 2px;
      }
    `,
    flexItem: css`
      flex: 1;
      width: 100%;
    `,
  };
};

// EXPORT ---------------------------------------------------------------- //

export default MovePlannerLaneForm;
