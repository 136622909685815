// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';
import { AdvancedMarker } from '@vis.gl/react-google-maps';

import { Marker } from '@components/DriverTracker/driverTrackerService';

import { css } from '@emotion/css';
import { Paper, Theme, Typography, useTheme } from '@mui/material';
import { BiSolidUserCircle } from 'react-icons/bi';
import { MdFlagCircle, MdPlayCircle } from 'react-icons/md';

// HELPERS ---------------------------------------------------------------- //

const getPinColor = (marker?: Marker) => {
  if (marker?.id === `pickup`) return `info`;
  if (marker?.id === `delivery`) return `success`;
  if (marker?.id === `driver`) return `primary`;
  return `utility`;
};

// TYPES ---------------------------------------------------------------- //

interface DriverTrackerMarkerProps {
  marker?: Marker;
  selectedMarker?: Marker;
  setSelectedMarker?: (marker?: Marker) => void;
}

// COMPONENT ---------------------------------------------------------------- //

const DriverTrackerMarker = ({ marker, selectedMarker, setSelectedMarker }: DriverTrackerMarkerProps) => {
  const pinColor = getPinColor(marker);

  const theme = useTheme();
  const cls = useStyles(theme);

  const PinBackground = ({ className }: { className: string }) => {
    return (
      <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 220'>
        <g>
          <path
            fill={theme?.palette?.[pinColor]?.light}
            d='M80,220c-13.23,0-18.13-12.14-20.48-17.98-.42-1.04-.82-2.02-1.16-2.75l-.1-.21c-3.43-7.72-6.62-14.31-11.04-21.35-4.5-7.22-9.65-13.9-15.11-20.97-5.63-7.31-11.46-14.86-16.53-23.08C5.25,116.95,0,98.89,0,80,0,35.89,35.89,0,80,0s80,35.89,80,80c0,18.97-5.29,37.04-15.72,53.71-5.11,8.34-11.04,16.03-16.77,23.46-5.36,6.95-10.42,13.52-14.83,20.55-4.38,6.98-7.48,13.56-11.08,21.44-.31,.69-.68,1.62-1.07,2.62-2.33,5.92-7.17,18.23-20.53,18.23Z'
          />
        </g>
      </svg>
    );
  };

  const PinForeground = ({ className }: { className: string }) => {
    return (
      <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 140 200'>
        <g>
          <path
            fill={theme?.palette?.[pinColor]?.darker}
            d='M70,0C31.3,0,0,31.3,0,70c0,17.4,5,33.7,14.1,48.4,9.5,15.4,22,28.6,31.6,44,4.7,7.5,8.1,14.5,11.7,22.6,2.6,5.5,4.7,15,12.6,15s10-9.5,12.5-15c3.7-8.1,7-15.1,11.7-22.6,9.6-15.3,22.1-28.5,31.6-44,9.2-14.7,14.2-31,14.2-48.4C140,31.3,108.7,0,70,0Zm0,0'
          />
        </g>
      </svg>
    );
  };

  // Render map
  return (
    <AdvancedMarker
      position={{ lat: marker?.position?.lat || 0, lng: marker?.position?.lng || 0 }}
      onClick={() => {
        if (setSelectedMarker) {
          if (marker?.id === selectedMarker?.id) setSelectedMarker(undefined);
          else setSelectedMarker(marker);
        }
      }}
    >
      <>
        <div className={cls.pin}>
          <PinBackground className={cls.pinBackground} />
          <PinForeground className={cls.pinForeground} />
          {marker?.id === `pickup` ? (
            <MdPlayCircle size={28} style={{ color: theme?.palette?.[pinColor]?.light }} className={cls.pinIcon} />
          ) : null}
          {marker?.id === `delivery` ? (
            <MdFlagCircle size={28} style={{ color: theme?.palette?.[pinColor]?.light }} className={cls.pinIcon} />
          ) : null}
          {marker?.id === `driver` ? (
            <BiSolidUserCircle size={28} style={{ color: theme?.palette?.[pinColor]?.light }} className={cls.pinIcon} />
          ) : null}
        </div>

        {marker?.id === selectedMarker?.id ? (
          <div className={cls.bubble}>
            <Paper variant='custom' sx={{ zIndex: 9000, padding: `12px` }}>
              <Typography
                sx={{
                  marginBottom: `6px`,
                  textTransform: `capitalize`,
                  fontWeight: 600,
                  color: theme?.palette?.[pinColor]?.main,
                }}
              >
                {marker?.id || `Unknown`} Location
              </Typography>
              <Typography variant='body2' sx={{ fontWeight: 500 }}>
                {marker?.name || `Unknown Name`}
              </Typography>
              <Typography sx={{ fontSize: `12px`, color: theme?.palette?.text?.secondary }}>
                {marker?.address || `Unknown Address`}
              </Typography>
              <Typography sx={{ marginTop: `6px`, fontSize: `12px`, color: theme?.palette?.text?.disabled }}>
                {marker?.position?.lat || `Unknown Latitude`}, {marker?.position?.lng || `Unknown Longitude`}
              </Typography>
            </Paper>
          </div>
        ) : null}
      </>
    </AdvancedMarker>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  pin: css`
    position: relative;
  `,
  pinBackground: css`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 48px;
    height: 48px;
  `,
  pinForeground: css`
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
    width: 44px;
    height: 44px;
  `,
  pinIcon: css`
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
  `,

  bubble: css`
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default DriverTrackerMarker;
