import { useEffect, useState } from 'react';
import {
  Container,
  Grid2 as Grid,
  Icon,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { css } from '@emotion/css';
import { type Locations, useGetAllLocationsQuery } from '@gql/schema';
import { useRooftop } from '@store';
import { ExportToCsv } from 'export-to-csv';
import DefaultFallback from '@components/Fallbacks/DefaultFallback';
import LocationsTable from '../components/LocationsTable';
import { toast } from 'react-toastify';
import IconAction from '@components/Utils/IconAction';
import { BiFile } from 'react-icons/bi';

const Locations = () => {
  const theme = useTheme();
  const cls = useStyles(theme);
  const clsx = useSxStyles(theme);
  const rooftop = useRooftop();

  const { data, loading, refetch } = useGetAllLocationsQuery({
    variables: { customerId: rooftop?.id || 0 },
  });

  const [search, setSearch] = useState(``);
  const [locations, setLocations] = useState<Locations[]>([]);

  useEffect(() => {
    if (data?.locations && data?.locations?.length > 0) {
      if (search && search.length > 0) {
        const filteredData: Locations[] = applyFilters(data.locations);
        setLocations(filteredData);
      } else {
        setLocations(data.locations as Locations[]);
      }
    } else {
      setLocations([]);
    }
  }, [data, search]);

  const applyFilters = (data: any[]) => {
    if (!search || search.length < 1) return data;
    return data.filter(loc => {
      const name = getName(loc);
      if (
        (name && name.toLocaleLowerCase().includes(search)) ||
        (loc.address && loc.address.toLocaleLowerCase().includes(search)) ||
        (loc.email && loc.email.toLocaleLowerCase().includes(search)) ||
        (loc.phone && loc.phone.toLocaleLowerCase().includes(search))
      ) {
        return true;
      }
      return false;
    });
  };

  const getTableActions = (locations: any[]) => [
    {
      name: `generate-csv`,
      label: `Generate\xa0CSV`,
      icon: <BiFile className={cls.menuIcon} />,
      data: { locations },
      handler: () => generateCSV(locations),
    },
  ];

  const getName = (loc: any) => {
    if (loc.nickname) return loc.nickname;
    if (loc.name) return loc.name;
    return `Unknown Location`;
  };

  const generateCSV = (locs: any[]) => {
    const createCsvRow = (loc: any) => ({
      NAME: getName(loc),
      ADDRESS: loc.address ? loc.address : `Unknown Address`,
      EMAIL: loc.email ? loc.email : `-`,
      PHONE: loc.phone ? loc.phone : `-`,
    });

    const csvRows = locs.map(loc => createCsvRow(loc));
    const csvOptions = {
      filename: `${locs[0].customer.name.replace(/ /g, '_')}_Locations`,
      showTitle: true,
      title: `${locs[0].customer.name.replace(/ /g, '_')}_Locations`,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  };

  const handleRefresh = async () => {
    try {
      await refetch();
      toast.success('Locations refreshed successfully!');
    } catch (error) {
      console.error('Failed to refresh locations:', error);
      toast.error('Failed to refresh locations!');
    }
  };

  if (loading) return <LinearProgress />;

  if (!locations?.length) return <DefaultFallback fullscreen severity='warning' message='No locations found!' />;

  if (locations?.length) {
    return (
      <div className={cls.pageContainer}>
        <Container maxWidth='lg'>
          <div className={cls.header}>
            <Grid container spacing={2} alignItems='center'>
              <Grid size={{ xs: 7 }}>
                <Typography sx={clsx.titleText}>Locations Index</Typography>
              </Grid>

              <Grid size={{ xs: 5 }}>
                <Grid container spacing={2} alignItems='center'>
                  <Grid size={{ xs: 9 }}>
                    <TextField
                      fullWidth
                      label='Search'
                      placeholder='Search table...'
                      variant='outlined'
                      size='small'
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                            <Icon color='disabled' fontSize='small'>
                              search
                            </Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid size={{ xs: 3 }}>
                    <Grid container spacing={2}>
                      <Grid size={{ xs: 6 }} sx={clsx.icon}>
                        <Tooltip title='Manually refresh'>
                          <IconButton onClick={handleRefresh}>
                            <Icon>refresh</Icon>
                          </IconButton>
                        </Tooltip>
                      </Grid>

                      <Grid size={{ xs: 6 }} sx={clsx.icon}>
                        <IconAction actions={getTableActions(locations)} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>

          <Paper variant='custom' style={{ overflow: 'hidden' }}>
            <LocationsTable locations={locations} />
          </Paper>
        </Container>
      </div>
    );
  }
};

export default Locations;

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  titleText: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  icon: {
    height: `100%`,
    verticalAlign: `top`,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const useStyles = (theme: Theme) => ({
  pageContainer: css`
    padding: ${theme.spacing(3)} 0;
  `,
  header: css`
    margin-bottom: ${theme.spacing(3)};
  `,
  menuIcon: css`
    display: block;
    margin-top: -1px;
    margin-right: 8px;
  `,
});
