// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { Arinvoices } from '@gql/schema';

import { buildAmountObject } from '@features/invoices/services/calcService';
import useCsvGeneration from '@features/invoices/hooks/useCsvGeneration';
import usePdfGeneration from '@features/invoices/hooks/usePdfGeneration';

import { css } from '@emotion/css';
import { Grid2 as Grid, Paper, Theme, Typography, useTheme } from '@mui/material';
import { BiDollarCircle, BiDownload, BiFile } from 'react-icons/bi';

import Spacer from '@components/Utils/Spacer';
import Toolbar from '@components/Utils/Toolbar';

import InvoiceDetailsCalculations from '@features/invoices/components/InvoiceDetailsCalculations';
import InvoiceDetailsInfo from '@features/invoices/components/InvoiceDetailsInfo';
import InvoiceDetailsPayments from '@features/invoices/components/InvoiceDetailsPayments';
import InvoiceDetailsProducts from '@features/invoices/components/InvoiceDetailsProducts';
import InvoiceDetailsTable from '@features/invoices/components/InvoiceDetailsTable';

import DisputeModal from '@features/invoices/components/DisputeModal';
import PayNowModal from '@features/invoices/components/PayNowModal';

// TYPES ---------------------------------------------------------------- //

type InvoiceDetailsContentProps = {
  invoice?: Arinvoices;
  refetch?: () => Promise<void>;
};

// COMPONENT ---------------------------------------------------------------- //

const InvoiceDetailsContent = ({ invoice, refetch }: InvoiceDetailsContentProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const { generateCSV, generateEnhancedCSV } = useCsvGeneration();
  const { generatePDF } = usePdfGeneration();

  const [checkedArmoves, setCheckedArmoves] = React.useState<any[]>([]);

  // Dispute Modal
  const [disputeModal, setDisputeModal] = React.useState<{ open?: boolean; input?: any }>({
    open: false,
    input: null,
  });
  const handleDisputeModalOpen = (input = null) => {
    setDisputeModal({ ...disputeModal, open: true, input: input });
  };
  const handleDisputeModalClose = () => {
    setDisputeModal({ ...disputeModal, open: false, input: null });
  };

  // Pay-Now Modal
  const [payNowModal, setPayNowModal] = React.useState<{ open?: boolean; input?: any }>({
    open: false,
    input: null,
  });
  const handlePayNowModalOpen = (input = null) => {
    setPayNowModal({ ...payNowModal, open: true, input: input });
  };
  const handlePayNowModalClose = () => {
    setPayNowModal({ ...payNowModal, open: false, input: null });
  };

  const amount = buildAmountObject(invoice);

  // Separate moves and products from armoves
  let armoves = invoice?.armoves;
  let products = invoice?.armoves;
  if (armoves?.length) armoves = armoves?.filter((armove?: any) => armove?.type === `move`);
  if (products?.length)
    products = products?.filter(
      (armove?: any) => armove?.type === `product` || armove?.type === `one-time` || armove?.type === `prepaid`
    );

  const actions = [
    {
      label: `Pay Now`,
      icon: <BiDollarCircle className={cls.menuIcon} />,
      color: theme?.palette?.primary?.main,
      handler: () => handlePayNowModalOpen({ invoice, ...amount }),
      disabled: amount?.due <= 0 || invoice?.status !== `closed`,
    },
    {},
    {
      label: `Generate CSV`,
      icon: <BiFile className={cls.menuIcon} />,
      handler: () => generateCSV(invoice),
    },
    {
      label: `Generate Enhanced CSV`,
      icon: <BiFile className={cls.menuIcon} />,
      handler: () => generateEnhancedCSV(invoice),
    },
    {
      label: `Generate PDF`,
      icon: <BiDownload className={cls.menuIcon} />,
      handler: () => generatePDF(invoice),
    },
  ];

  return (
    <>
      <DisputeModal
        input={disputeModal.input}
        open={disputeModal.open}
        onClose={handleDisputeModalClose}
        refetch={refetch}
      />

      <PayNowModal
        input={payNowModal?.input}
        open={payNowModal?.open}
        onClose={handlePayNowModalClose}
        refetch={refetch}
      />

      <div className={cls.content}>
        <Toolbar title='Invoice Details' refetch={refetch} actions={invoice ? actions : []} />

        <Spacer />

        {!invoice ? (
          <Paper variant='custom' className={cls.notFound}>
            <Typography style={{ fontSize: `20px`, fontWeight: 500, color: theme?.palette?.text?.disabled }}>
              No invoice found/selected
            </Typography>
          </Paper>
        ) : (
          <>
            <InvoiceDetailsInfo invoice={invoice} armoves={armoves} />

            <Spacer />

            {products && products?.length ? (
              <>
                <InvoiceDetailsProducts products={products} />

                <Spacer />
              </>
            ) : null}

            {armoves && armoves?.length ? (
              <>
                <InvoiceDetailsTable
                  invoice={invoice}
                  armoves={armoves}
                  amount={amount}
                  checkedArmoves={checkedArmoves}
                  setCheckedArmoves={setCheckedArmoves}
                  handlePayNowModalOpen={handlePayNowModalOpen}
                  handleDisputeModalOpen={handleDisputeModalOpen}
                />

                <Spacer />
              </>
            ) : null}

            <Grid container spacing={2}>
              <Grid size={{ md: 8, sm: 6, xs: 12 }}>
                <InvoiceDetailsPayments invoice={invoice} />
              </Grid>
              <Grid size={{ md: 4, sm: 6, xs: 12 }}>
                <InvoiceDetailsCalculations amount={amount} />
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  content: css`
    position: relative;
  `,

  notFound: css`
    padding: 24px;
    text-align: center;
  `,

  menuIcon: css`
    display: block;
    margin-top: -1px;
    margin-right: 8px;
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default InvoiceDetailsContent;
