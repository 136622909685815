// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { css } from '@emotion/css';
import { Theme, useTheme } from '@mui/material';
import RooftopSelect from '@components/Selectors/RooftopSelect';
import PayerSelect from '@components/Selectors/PayerSelect';

import { MovePlannerContext, MovePlannerContextType } from '@features/moveCreation/providers/MovePlannerProvider';

// COMPONENT ---------------------------------------------------------------- //

const MovePlannerRooftopForm = () => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const {
    hasAdminPrivileges,
    validation,
    rooftops,
    rooftopConfig,
    rooftopId,
    payers,
    payerId,
    setRooftopId,
    setPayerId,
  } = React.useContext(MovePlannerContext) as MovePlannerContextType;

  if (hasAdminPrivileges)
    return (
      <div className={cls.rooftop}>
        <div className={cls.flex}>
          <div className={cls.flexItem}>
            <RooftopSelect
              disableClearable
              required
              disabled={!rooftops?.length || rooftops?.length <= 1}
              error={!validation?.rooftopId}
              label='Responsible Market'
              helperText={!validation?.rooftopId ? `Please select the rooftop responsible for the move!` : ``}
              defaultRooftops={rooftops}
              value={rooftopId}
              onChange={setRooftopId}
              queryCall='never'
            />
          </div>

          {payers?.length ? (
            <div className={cls.flexItem}>
              <PayerSelect
                required={rooftopConfig?.require_third_party_payer}
                disabled={!payers?.length}
                error={!validation?.payerId}
                label='Responsible Payer'
                helperText={!validation?.rooftopId ? `Please select the rooftop that will pay for the move!` : ``}
                defaultPayers={payers}
                value={payerId}
                onChange={setPayerId}
                queryCall='never'
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  return null;
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  return {
    rooftop: css`
      position: relative;
      margin-bottom: 18px;
    `,

    flex: css`
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 12px;
      ${theme?.breakpoints?.down('sm')} {
        flex-direction: column;
      }
    `,
    flexItem: css`
      flex: 1;
      width: 100%;
    `,
  };
};

// EXPORT ---------------------------------------------------------------- //

export default MovePlannerRooftopForm;
