import React from 'react';
import { useTheme, Theme, SxProps, Typography, DialogTitle, IconButton, Icon } from '@mui/material';

interface ModalHeaderProps {
  className?: string;
  titleClassName?: string;
  closeClassName?: string;
  handleClose?: () => void;
  children: React.ReactNode;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({
  handleClose,
  children,
}) => {
  const theme: Theme = useTheme();

  const sxStyles = useSxStyles(theme);

  return (
    <>
      <DialogTitle sx={sxStyles.padding}>
        <Typography sx={sxStyles.title}>{children}</Typography>
      </DialogTitle>
      {handleClose && (
        <IconButton sx={sxStyles.close} onClick={handleClose} aria-label='close'>
          <Icon>close</Icon>
        </IconButton>
      )}
    </>
  );
};

export default ModalHeader;

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  padding: {
    padding: theme.spacing(2),
    margin: 0,
  },
  title: {
    fontSize: 21,
    fontWeight: 500,
  },
  close: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.text.primary,
  },
});
