import React from 'react';
import { useTheme } from '@mui/material/styles';
import useStyles from './Spacer.styles';

interface SpacerProps {
  column?: boolean;
  size?: number | string;
}

const sizeMapping: { [key: string]: number } = {
  xxxs: 0.25,
  xxs: 0.5,
  xs: 1,
  sm: 1.5,
  md: 2,
  lg: 3,
  xl: 4,
  xxl: 6,
  xxxl: 8,
};

const getSize = (size: number | string | undefined): number => {
  if (typeof size === 'string') {
    const parsedSize = parseFloat(size);
    if (!isNaN(parsedSize)) {
      return parsedSize;
    }
    return sizeMapping[size] || 2;
  }
  return size || 2;
};

const Spacer: React.FC<SpacerProps> = ({ column, size }) => {
  const theme = useTheme();
  const styles = useStyles(theme, { size: getSize(size) });

  return <div className={column ? styles.spacerV : styles.spacerH} />;
};

export default Spacer;
