import React from 'react';
import { useMapsLibrary } from '@vis.gl/react-google-maps';

type PlacesServiceType = google.maps.places.PlacesService | null;

const useGooglePlaces = (): PlacesServiceType => {
  const placesLibrary = useMapsLibrary('places');
  const [placesService, setPlacesService] = React.useState<PlacesServiceType>(null);

  React.useEffect(() => {
    if (!placesLibrary) return;
    setPlacesService(new placesLibrary.PlacesService(document.createElement('div')));
  }, [placesLibrary]);

  return placesService;
};

export default useGooglePlaces;
