// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { css, cx } from '@emotion/css';
import { Theme, Typography, useTheme } from '@mui/material';

import { BiSolidHelpCircle } from 'react-icons/bi';

// TYPES ---------------------------------------------------------------- //

interface NavItemProps {
  condensed?: boolean;
  label?: string;
  icon?: React.ReactElement;
  iconSize?: number;
  route?: string;
  currentRoute?: string;
}

// COMPONENT ---------------------------------------------------------------- //

const NavItem = ({ condensed, label, icon, iconSize, route, currentRoute }: NavItemProps) => {
  const theme = useTheme();
  const cls = useStyles(theme, { condensed, iconSize });

  const navigate = useNavigate();

  const handleNavigate = (route?: string) => {
    navigate(route || `/`);
  };

  const Icon = () => {
    const element = React.cloneElement(icon || <BiSolidHelpCircle />, { className: cls.navItemIcon });
    return element;
  };

  return (
    <div
      className={cx(cls.navItem, currentRoute?.includes(route || ``) ? cls.navItemActive : null)}
      onClick={() => handleNavigate(route)}
    >
      <Icon />
      <Typography className={cls.navItemTxt}>{label || `Feature`}</Typography>
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme, props?: { condensed?: boolean; iconSize?: number }) => {
  const styles = {
    navItem: css`
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: ${props?.condensed ? `center` : `flex-start`};
      width: 100%;
      padding: ${props?.condensed ? `6px` : `6px 12px`};
      border-radius: 6px;
      margin-bottom: 4px;
      background-color: transparent;
      color: ${theme?.palette?.text?.contrastPrimary};
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      :hover,
      :focus {
        background-color: ${theme?.palette?.action?.contrastHover};
      }
      :active {
        background-color: ${theme?.palette?.action?.contrastSelected};
      }
    `,
    navItemActive: css`
      background-color: ${theme?.palette?.primary?.light}18 !important;
      color: ${theme?.palette?.primary?.main} !important;
    `,
    navItemIcon: css`
      display: block;
      min-width: ${props?.iconSize || 20}px;
      min-height: ${props?.iconSize || 20}px;
      font-size: ${props?.iconSize || 20}px;
    `,
    navItemTxt: css`
      display: ${props?.condensed ? `none` : `block`};
      margin-left: 12px !important;
      font-size: 14px !important;
      font-weight: 400 !important;
    `,
  };

  return styles;
};

// EXPORT ---------------------------------------------------------------- //

export default NavItem;
