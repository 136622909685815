// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { Action, Tab } from 'src/types';

import { css, cx } from '@emotion/css';
import { Icon, Tab as MuiTab, Tabs as MuiTabs, Theme, Typography, useTheme } from '@mui/material';

import Spacer from '@components/Utils/Spacer';
import IconAction from '@components/Utils/IconAction';
import IconBack from '@components/Utils/IconBack';
import IconClearFilters from '@components/Utils/IconClearFilters';
import IconRefetch from '@components/Utils/IconRefetch';

// TYPES ---------------------------------------------------------------- //

interface ToolbarProps {
  children?: React.ReactNode;
  zIndex?: number;
  position?: 'relative' | 'fixed' | 'sticky';
  fullscreen?: boolean;
  back?: boolean | (() => void);
  title?: string;
  refetch?: () => Promise<void>;
  refetchTooltip?: string;
  onClear?: () => void;
  onClearTooltip?: string;
  actions?: Action[];
  actionsTooltip?: string;
  tabs?: Tab[];
  activeTab?: number;
  onActiveTabChange?: (tab?: number) => void;
}

// COMPONENT ---------------------------------------------------------------- //

const Toolbar = ({
  children,
  zIndex = 10,
  position = 'sticky',
  fullscreen = false,
  back = false,
  title = undefined,
  refetch = undefined,
  refetchTooltip = undefined,
  onClear = undefined,
  onClearTooltip = undefined,
  actions = undefined,
  actionsTooltip = undefined,
  tabs = undefined,
  activeTab = 0,
  onActiveTabChange = undefined,
}: ToolbarProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);
  const clsx = useSxStyles(theme);

  const handleTabChange = (_event?: React.SyntheticEvent, value?: number) => {
    const foundTab = tabs?.find(tab => tab?.id === value);
    if (onActiveTabChange) onActiveTabChange(value);
    if (foundTab?.handler) foundTab.handler();
  };

  return (
    <div
      className={fullscreen ? cls.fullBar : cls.floatingBar}
      style={{
        zIndex: zIndex,
        position: fullscreen ? position : `relative`,
      }}
    >
      <div className={fullscreen ? cls.fullBarContainer : cls.floatingBarContainer}>
        <div className={cls.flex}>
          {back ? (
            <div className={cls.flexItem}>
              <IconBack
                className={cls.backBtn}
                handler={typeof back === `function` ? back : null}
                tooltipPlacement={fullscreen ? `bottom` : `top`}
              />
            </div>
          ) : null}

          {title ? (
            <div className={cls.flexItem}>
              {!back && fullscreen ? <Spacer column size='xs' /> : null}

              {Array.isArray(title) ? (
                title?.map((str, i) => {
                  if (i === title?.length - 1)
                    return (
                      <Typography key={`toolbar-title-${i}`} sx={clsx.title}>
                        {str}
                      </Typography>
                    );
                  return (
                    <Typography key={`toolbar-title-${i}`} sx={clsx.titleAlt}>
                      {str}&nbsp;/&nbsp;
                    </Typography>
                  );
                })
              ) : (
                <Typography sx={clsx.title}>{title}</Typography>
              )}

              {fullscreen ? <Spacer column size='sm' /> : null}
            </div>
          ) : null}

          <div className={cls.flexItemStretch}>{children}</div>

          {refetch || onClear || (actions && actions?.length > 0) ? (
            <div className={cls.flexItem}>
              {refetch ? (
                <IconRefetch
                  refetch={refetch}
                  tooltip={refetchTooltip}
                  tooltipPlacement={fullscreen ? `bottom` : `top`}
                />
              ) : null}

              {onClear ? (
                <IconClearFilters
                  onClear={onClear}
                  tooltip={onClearTooltip}
                  tooltipPlacement={fullscreen ? `bottom` : `top`}
                />
              ) : null}

              {actions && actions?.length ? (
                <IconAction
                  actions={actions}
                  tooltip={actionsTooltip}
                  tooltipPlacement={fullscreen ? `bottom` : `top`}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>

      {tabs?.length ? (
        <MuiTabs
          className={cls.tabs}
          centered
          variant='fullWidth'
          indicatorColor='primary'
          textColor='primary'
          value={activeTab}
          onChange={handleTabChange}
        >
          {tabs?.map((tab, i) => (
            <MuiTab
              key={`toolbar-tab-${tab?.id}`}
              className={cx(cls.tab, activeTab === tab?.id && cls.activeTab)}
              style={{ borderRight: i < tabs?.length - 1 ? `1px solid ${theme?.palette?.divider}` : `none` }}
              disabled={tab?.disabled}
              label={tab?.label}
              icon={<Icon style={{ marginBottom: -2 }}>{tab?.icon}</Icon>}
              iconPosition='start'
            />
          ))}
        </MuiTabs>
      ) : null}
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  fullBar: css`
    top: 0;
  `,
  fullBarContainer: css`
    padding: ${theme?.spacing(2)};
    background: ${theme?.palette?.background?.paper};
  `,
  floatingBar: css`
    top: 0;
  `,
  floatingBarContainer: css``,

  backBtn: css`
    margin-right: ${theme?.spacing(-1)};
  `,

  tabs: css`
    border-top: 1px solid ${theme?.palette?.divider};
    background: ${theme?.palette?.background?.paper};
  `,
  tab: css`
    min-height: 56px;
    max-height: 56px;
    padding: 0;
    padding-top: 2px;
  `,
  activeTab: css`
    background: ${theme?.palette?.primary?.main}10;
  `,

  flex: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${theme?.spacing(2)};
  `,
  flexItem: css``,
  flexItemStretch: css`
    flex: 1;
  `,
});

const useSxStyles = (theme?: Theme) => ({
  title: {
    display: 'inline',
    verticalAlign: 'top',
    lineHeight: 1,
    fontSize: `24px`,
    fontWeight: 600,
  },
  titleAlt: {
    display: 'inline',
    verticalAlign: 'top',
    lineHeight: 1,
    fontSize: `24px`,
    fontWeight: 600,
    color: theme?.palette?.text?.secondary,
  },
});

// EXPORT ---------------------------------------------------------------- //

export default Toolbar;
