import dayjs from 'dayjs';


/** Converts strings to Proper Case (first letter of each word capitalized)
 * @param {String} string - uppercase string
 * @return {String} proper case string
 */
const toProperCase = (string: string) => {
    let upperCaseString = string.toUpperCase();
    let properCaseString = upperCaseString[0] + upperCaseString.substring(1).toLowerCase();
    return properCaseString;
}

/** Formats a date time string to a 12 hour format 
 * @param {String} dateTimeString - date time string
 * @return {String} formatted date time string
 */
const formatDateTime = (dateTimeString: string) => {
    return dayjs(dateTimeString).format('h:mm A');
}


/** Function for formating phone number to (xxx) xxx-xxxx format
 * Copied from https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
 * @param {String} phoneNumberString - string of numbers
 * @return {String} formatted phone number string
 */
const formatPhoneNumber = (phoneNumberString: string | null = '') => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
}

const organizationOverviewUtils = {
    formatDateTime,
    formatPhoneNumber,
    toProperCase
};

export default organizationOverviewUtils;