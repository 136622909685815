import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import DocumentDefinition from '@features/invoices/services/PDFDocumentDefinition';
import { buildAmountObject } from '@features/invoices/services/calcService';
import pdfMake from 'pdfmake/build/pdfmake';
import { GetInvoiceDetailsQuery } from '@gql/schema';

// Defining and Using Custom Fonts
const pdfMakeFonts = {
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf',
  },
};
pdfMake.fonts = pdfMakeFonts;

export const generatePDF = async (invoice?: GetInvoiceDetailsQuery['arinvoices'][number], contacts?: any) => {
  if (invoice) {
    const amount = buildAmountObject(invoice);
    try {
      const docDefinition = new DocumentDefinition(invoice, amount, contacts);
      const dd = docDefinition.create(invoice, amount, contacts);
      const name = `${invoice?.customer?.name?.replace(/ /g, '_')}_Invoice_${dayjs(invoice.start_datetime).add(1, 'day').format('MM-DD-YYYY')}_to_${dayjs(invoice.end_datetime).format('MM-DD-YYYY')}.pdf`;
      pdfMake.createPdf(dd).download(name);
      toast.info('Generated PDF!', { autoClose: 2000, toastId: `generate-pdf` });
    } catch (err) {
      toast.error('Failed to generate PDF!', { toastId: `generate-pdf-error` });
      console.error('Failed to generate PDF:', err);
    }
  }
};
