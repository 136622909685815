import { useState, useEffect } from 'react';
import { authService, buildConvenientGlobalUserObject } from '../services/authService';
import { User as FirebaseUser } from 'firebase/auth';
import { setUser, useUser } from '@store';
import { toast } from 'react-toastify';
import { checkForPermittedRole } from '../services/authService';
export const useAuth = () => {
  const user = useUser();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = authService.onAuthStateChanged(async (firebaseUser: FirebaseUser | null) => {
      const isLoggingIn = !!(!user && firebaseUser);
      const isLoggingOut = !!(user && !firebaseUser);
      const isLoggedOut = !!(!user && !firebaseUser);

      if (isLoggingIn) {
        const globalUser = await buildConvenientGlobalUserObject();
        
        // Check permissions right after getting the global user
        const permittedRole = checkForPermittedRole(globalUser);
        if (!permittedRole) {
          console.log('User does not have permitted role, logging out...', {
            isLoggingIn,
            isLoggingOut,
            isLoggedOut,
            user,
            firebaseUser,
            globalUser,
          });
          toast.error(`The user entered does not have access to this application. If you think this is an error, please contact a Hopdrive admin.`);
          authService.logout();
          return;
        }

        console.log(`(onAuthStateChanged) Auto-logging in ${firebaseUser?.displayName} using active session...`, {
          isLoggingIn,
          isLoggingOut,
          isLoggedOut,
          user,
          firebaseUser,
          globalUser,
        });
        setUser(globalUser);
        setLoading(false);
      }

      if (isLoggingOut) {
        console.log(`(onAuthStateChanged) Auto-logging out ${user?.profile?.display_name}...`, {
          isLoggingIn,
          isLoggingOut,
          isLoggedOut,
          user,
          firebaseUser,
        });
        setUser();
        setLoading(false);
      }

      if (isLoggedOut) {
        console.log('(onAuthStateChanged) is currenly logged out. Nothing to do.', {
          isLoggingIn,
          isLoggingOut,
          isLoggedOut,
          user,
          firebaseUser,
        });
        setUser();
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return { user, loading };
};
