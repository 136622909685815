import React, { useState } from 'react';
import { css } from '@emotion/css';
import * as Sentry from '@sentry/react';
import { Grid2 as Grid, SxProps, useTheme, Theme, Button, Icon } from '@mui/material';

import { Arpaymentmethods, useGetPaymentMethodsBillingQuery } from '@gql/schema';
import { useRooftop } from '@store';

import DefaultErrorFallback from '@components/Fallbacks/DefaultErrorFallback';
import MethodCard from '@features/billing/components/MethodCard';
import MethodAddEditModal from '@features/billing/components/MethodAddEditModal';

////////// COMPONENT //////////
const MethodTable: React.FC = () => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sxStyles = useSxStyles(theme);

  const [modal, setModal] = useState({ open: false });
  const [methods, setMethods] = useState<Arpaymentmethods[]>([]);
  const [primaryExists, setPrimaryExists] = useState(false);
  const [customerId, setCustomerId] = React.useState<number>(0);

  const rooftop = useRooftop();

  React.useEffect(() => {
    if (rooftop) {
      setCustomerId(rooftop?.id);
    }
  }, [rooftop]);

  const { error, data, refetch } = useGetPaymentMethodsBillingQuery({
    variables: { customerId: customerId },
  });

  const handleModalOpen = () => {
    setModal({ ...modal, open: true });
  };
  const handleModalClose = () => {
    setModal({ ...modal, open: false });
    refetch();
  };

  const checkMethodsForPrimary = (methods: Arpaymentmethods[]) => {
    const primaryCard = methods.find(method => method.primary);
    setPrimaryExists(!!primaryCard);
  };

  React.useEffect(() => {
    const methods = data?.arpaymentmethods;
    if (methods && Array.isArray(methods) && methods.length > 0) {
      //Fix ARpaymentmethod type so it does not require customer, or add customer to quuery
      const clonedMethods = methods.map((method: Arpaymentmethods) => {
        return method;
      });
      setMethods(clonedMethods);
      checkMethodsForPrimary(clonedMethods);
    } else {
      setMethods([]);
      setPrimaryExists(false);
    }
  }, [data]);

  if (error) {
    console.error('ERROR DISPLAYING PAYMENT METHODS', error);
    return <DefaultErrorFallback message='ERROR DISPLAYING PAYMENT METHODS' />;
  }

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING PAYMENT METHODS' />}>
      <MethodAddEditModal
        customerId={customerId}
        open={modal.open}
        onClose={handleModalClose}
        primaryExists={primaryExists}
      />

      <div className={styles.root}>
        <Grid container spacing={2}>
          {methods.length > 0
            ? methods.map((method: Arpaymentmethods) => (
                <React.Fragment key={method.id}>
                  <Grid size={{ md: 6, xs: 12 }}>
                    <MethodCard customerId={customerId} method={method} refetch={refetch} />
                  </Grid>
                </React.Fragment>
              ))
            : null}
          <React.Fragment key={0}>
            <Grid size={{ md: 6, xs: 12 }}>
              <Button
                sx={sxStyles.addButton}
                variant='outlined'
                color='primary'
                style={{ fontSize: '16px' }}
                onClick={() => handleModalOpen()}
              >
                <Icon sx={sxStyles.addIcon}>add</Icon>Add Payment Method
              </Button>
            </Grid>
          </React.Fragment>
        </Grid>
      </div>
    </Sentry.ErrorBoundary>
  );
};

export default MethodTable;

////////// STYLES //////////
const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  addButton: {
    width: '100%',
    height: '100%',
    minHeight: '198px',
    padding: '24px',
  },
  addIcon: {
    marginTop: '-2px',
    marginRight: theme.spacing(1),
    fontSize: '16px',
  },
});

const useStyles = (theme: Theme) => {
  return {
    root: css`
      width: 100%;
      margin-top: ${theme.spacing(3)};
    `,
  };
};
