// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';

import { getMonetaryValue } from '@services/formatService';

import { css } from '@emotion/css';
import { Paper, Theme, Typography, useTheme } from '@mui/material';

import Spacer from '@components/Utils/Spacer';

// TYPES ---------------------------------------------------------------- //

type InvoiceDetailsCalculationsProps = {
  amount?: any;
};

// COMPONENT ---------------------------------------------------------------- //

const InvoiceDetailsCalculations = ({ amount }: InvoiceDetailsCalculationsProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);
  const clsx = useSxStyles(theme);

  return (
    <div className={cls.calculations}>
      <Paper variant='custom' sx={{ padding: `16px` }}>
        <Typography variant='h5' sx={{ lineHeight: 1, fontWeight: 600 }}>
          Calculated Totals
        </Typography>

        <Typography
          variant='body2'
          sx={{ marginTop: `4px`, lineHeight: 1, fontSize: `12px`, color: theme?.palette?.text?.secondary }}
        >
          * Includes processing fee
        </Typography>

        <Spacer />

        {amount.discounted || amount.disputed ? (
          <>
            <div className={cls.flex}>
              <Typography sx={clsx.key3Text}>Subtotal</Typography>
              <Typography sx={clsx.val3Text}>{getMonetaryValue(amount?.subtotal)}</Typography>
            </div>

            <div className={cls.flex}>
              <Typography sx={clsx.key3Text}>Processing Fee</Typography>
              <Typography sx={clsx.val3Text}>{getMonetaryValue(amount?.processingFee)}</Typography>
            </div>

            {amount?.discounted > 0 ? (
              <div className={cls.flex}>
                <Typography sx={clsx.key3Text}>Discounted</Typography>
                <Typography sx={clsx.val3Text}>({getMonetaryValue(amount?.discounted)})</Typography>
              </div>
            ) : null}

            {amount?.disputed > 0 ? (
              <div className={cls.flex}>
                <Typography sx={clsx.key3Text}>Disputed</Typography>
                <Typography sx={clsx.val3Text}>({getMonetaryValue(amount?.disputed)})</Typography>
              </div>
            ) : null}
          </>
        ) : null}

        <div className={cls.line} />

        <div className={cls.flex}>
          <Typography sx={clsx.key2Text}>Move Total</Typography>
          <Typography sx={clsx.val2Text}>{getMonetaryValue(amount?.moveDueTotal)}</Typography>
        </div>

        {amount.productTotal > 0 ? (
          <div className={cls.flex}>
            <Typography sx={clsx.key2Text}>Product Total</Typography>
            <Typography sx={clsx.val2Text}>{getMonetaryValue(amount?.productTotal)}</Typography>
          </div>
        ) : null}

        <div className={cls.flex}>
          <Typography sx={clsx.key2Text}>Processing Fee</Typography>
          <Typography sx={clsx.val2Text}>{getMonetaryValue(amount?.processingFee)}</Typography>
        </div>

        <div className={cls.line} />

        <div className={cls.flex}>
          <Typography sx={clsx.key1Text}>Amount Paid</Typography>
          <Typography sx={clsx.val1Text}>{getMonetaryValue(amount?.paymentAmount)}</Typography>
        </div>

        {parseInt(amount?.balanceRemaining) > 0 ? (
          <div className={cls.flex}>
            <Typography sx={clsx.key1Text}>Prepaid Balance</Typography>
            <Typography sx={clsx.val1Text}>{getMonetaryValue(amount?.balanceRemaining)}</Typography>
          </div>
        ) : (
          <div className={cls.flex}>
            <Typography sx={clsx.key1Text}>Amount Due</Typography>
            <Typography sx={clsx.val1Text}>{getMonetaryValue(amount?.due)}</Typography>
          </div>
        )}
      </Paper>
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  calculations: css`
    position: relative;
  `,

  flex: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
  `,

  line: css`
    width: 100%;
    border-bottom: 1px solid ${theme?.palette?.divider};
    margin: 12px 0;
  `,
});

const useSxStyles = (theme?: Theme) => ({
  key1Text: {
    lineHeight: 1.5,
    fontSize: `14px`,
    color: theme?.palette?.text?.primary,
  },
  key2Text: {
    lineHeight: 1.5,
    fontSize: `14px`,
    color: theme?.palette?.text?.secondary,
  },
  key3Text: {
    lineHeight: 1.5,
    fontSize: `14px`,
    color: theme?.palette?.text?.disabled,
  },

  val1Text: {
    lineHeight: 1.5,
    fontSize: `14px`,
    fontWeight: 600,
    color: theme?.palette?.text?.primary,
  },
  val2Text: {
    lineHeight: 1.5,
    fontSize: `14px`,
    fontWeight: 600,
    color: theme?.palette?.text?.secondary,
  },
  val3Text: {
    lineHeight: 1.5,
    fontSize: `14px`,
    fontWeight: 600,
    color: theme?.palette?.text?.disabled,
  },
});

// EXPORT ---------------------------------------------------------------- //

export default InvoiceDetailsCalculations;
