//////////////////////// DEPENDENCIES ////////////////////////

import { Button, Grid2 as Grid, Icon } from '@mui/material';
import { useUserDetails } from '@hooks/useUserDetails';
import { useTools } from '@hooks/useTools';
import Toolbar from '@components/Utils/Toolbar';

interface CreateUserResponse {
  success: boolean;
}

//////////////////////// COMPONENT ////////////////////////

export default function DealerUserAddToolbar() {
  const ctx = useUserDetails();
  const { goToPreviousPage } = useTools();

  const handleCancel = () => {
    goToPreviousPage();
  };

  const handleSubmit = async () => {
    const res = (await ctx?.handleCreateUser()) as CreateUserResponse;
    if (res?.success === true) {
      goToPreviousPage();
    } else ctx.setIsSaving(false);
  };

  const handleSubmitAndAddAnother = async () => {
    const res = await ctx?.handleCreateUser();
    if (res?.success === true) {
      window.location.reload();
    }
  };

  return (
    <Toolbar back title='Add User'>
      <Grid container spacing={1} alignItems='center'>
        <Grid size='grow' />

        <Grid>
          <Button color='utility' onClick={() => handleCancel()}>
            <Icon>highlight_off</Icon>
            Cancel
          </Button>
        </Grid>

        <Grid>
          <Button loading={ctx.isSaving} disabled={ctx.isSaving} onClick={() => handleSubmit()}>
            <Icon>task_alt</Icon>
            Submit
          </Button>
        </Grid>

        <Grid>
          <Button
            loading={ctx.isSaving}
            disabled={ctx.isSaving}
            variant='outlined'
            color='primary'
            onClick={() => handleSubmitAndAddAnother()}
          >
            <Icon>add_task</Icon>
            Submit & Add Another
          </Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
}
