import Spacer from '@components/Utils/Spacer';
import { UserDetailsProvider } from '../providers/UserDetailsProvider';
import { useUser } from '@store';
import DefaultEmptyFallback from '@components/Fallbacks/DefaultEmptyFallback';
import UserAddToolbar from '../components/UserAddToolbar';
import UserAddEditForm from '../components/UserAddEditForm';
import { Container, Grid2 as Grid, useTheme } from '@mui/material';
import { Users } from '@gql/schema';
//////////////////////// COMPONENT ////////////////////////

const UserAdd = () => {
  const currentUser = useUser();
  const theme = useTheme();
  const user = {} as Users;

  if (currentUser?.profile?.default_role === 'dealer' || currentUser?.profile?.default_role === 'admin') {
    return (
      <div style={{ position: 'relative', background: theme.palette.background.paper }}>
        <DefaultEmptyFallback message='RESTRICTED ACCESS' />
      </div>
    );
  } else {
    return (
      <UserDetailsProvider user={user}>
        <div style={{ padding: 16 }}>
          <Container maxWidth='lg'>
            <Grid container spacing={2} alignItems='center'>
              <Grid size={{ xs: 12 }}>
                <UserAddToolbar />
              </Grid>
            </Grid>

            <Spacer size='xl' />

            <UserAddEditForm />

            <Spacer size='xl' />
          </Container>
        </div>
      </UserDetailsProvider>
    );
  }
};

export default UserAdd;
