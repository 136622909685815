// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Moves } from '@gql/schema';

import { css } from '@emotion/css';
import { Theme, useTheme, Paper, Tooltip, Typography } from '@mui/material';

// TYPES ---------------------------------------------------------------- //

interface MoveDetailsLocationProps {
  move?: Moves;
  type?: `pickup` | `delivery`;
  isPrinting?: boolean;
}

// COMPONENT ---------------------------------------------------------------- //

const MoveDetailsLocation = ({ move, type = `pickup`, isPrinting }: MoveDetailsLocationProps) => {
  const navigate = useNavigate();

  const theme = useTheme();
  const cls = useStyles(theme);

  if (!move) return null;
  return (
    <Paper variant={isPrinting ? `customPrint` : `custom`} sx={{ width: `100%`, padding: `16px` }}>
      <div className={cls.titleFlex}>
        <div className={cls.titleFlexItem}>
          <Typography variant='h5' sx={{ lineHeight: 1, textTransform: `capitalize`, fontWeight: 600 }}>
            {type} Location
          </Typography>
        </div>

        <div className={cls.titleFlexItem}>
          <Tooltip title='Region' placement='top'>
            <Typography variant='body2' sx={{ lineHeight: 1 }}>
              {move?.lane?.[type]?.region?.name || `No Region`}
            </Typography>
          </Tooltip>
        </div>
      </div>

      <Tooltip placement='top' title={`Click to see details for this location`}>
        <div
          className={cls.location}
          onClick={() => {
            navigate(`/locations/${move?.lane?.[type]?.id}`);
          }}
        >
          <Typography sx={{ marginBottom: `2px`, fontWeight: 500 }}>{move?.lane?.[type]?.name}</Typography>
          <Typography variant='body2' sx={{ color: theme?.palette?.text?.secondary }}>
            {move?.lane?.[type]?.address}
          </Typography>
        </div>
      </Tooltip>
    </Paper>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  titleFlex: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
    margin-bottom: 12px;
  `,
  titleFlexItem: css`
    cursor: pointer;
  `,

  location: css`
    padding: 6px;
    border-radius: 6px;
    background: ${theme?.palette?.action?.hover};
    cursor: pointer;
    transition: 0.1s ease-in-out;
    &:hover {
      background: ${theme?.palette?.action?.selected};
    }
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default MoveDetailsLocation;
