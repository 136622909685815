import React, { useState, FormEvent } from 'react';

import {
  Theme,
  useTheme,
  TextField,
  Button,
  CssBaseline,
  SxProps,
  Typography,
  InputAdornment,
  Icon,
  Link,
} from '@mui/material';
import PasswordReset from './PasswordReset';
import Spacer from '@components/Utils/Spacer';

interface FirebaseLoginProps {
  onLogin: (email: string, password: string) => Promise<void>;
}

const FirebaseLogin: React.FC<FirebaseLoginProps> = ({ onLogin }) => {
  const theme = useTheme();
  const sxStyles = useSxStyles(theme);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);

  const handleEnter = (event: { keyCode: number }) => {
    if (event && event.keyCode && event.keyCode === 13) {
      handleSubmit();
    }
  };
  const handleSubmit = async (event?: FormEvent) => {
    event?.preventDefault();
    if (!email || !password) {
      setErrorMessage('Please enter an email and password.');
      return;
    }
    try {
      await onLogin(email, password);
    } catch (err) {
      const error = err as { code?: string }; // Type assertion to inform TypeScript about the 'code' property
      if (error?.code?.includes('password') || error?.code?.includes('user-not-found')) {
        setErrorMessage('Wrong email or password.');
      } else if (error?.code?.includes('invalid-email')) {
        setErrorMessage('Please enter a valid email and try again.');
      } else {
        setErrorMessage('An error occurred while attempting to sign in.');
      }
    }
  };

  return (
    <>
      {showResetPassword ? (
        <PasswordReset setOpen={setShowResetPassword} email={email} setEmail={setEmail} />
      ) : (
        <>
          <CssBaseline />
          <div>
            <Typography sx={sxStyles.titleTxt}>Sign in to Hopdrive</Typography>

            <Spacer size='lg' />
            <TextField
              required
              fullWidth
              autoFocus
              id='email'
              name='email'
              autoComplete='email'
              label='Email'
              placeholder='Enter email...'
              variant='outlined'
              size='small'
              value={email}
              onChange={e => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      email
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />

            <Spacer />

            <TextField
              required
              fullWidth
              id='password'
              name='password'
              type='password'
              label='Password'
              placeholder='Enter password...'
              variant='outlined'
              size='small'
              value={password}
              onChange={e => setPassword(e.target.value)}
              onKeyDown={handleEnter}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      lock
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />

            <Spacer size='lg' />

            <Button fullWidth type='submit' color='primary' size='large' onClick={() => handleSubmit()}>
              Sign in
            </Button>

            {errorMessage ? (
              <>
                <Spacer size='xs' />
                <Typography sx={sxStyles.errorTxt}>{errorMessage}</Typography>
                <Spacer size='xs' />
              </>
            ) : null}

            <Spacer size='xs' />

            <div style={{ textAlign: 'center' }}>
              <Link
                sx={sxStyles.resetTxt}
                color='secondary'
                target='_blank noreferrer'
                onClick={() => {
                  setErrorMessage(null);
                  setShowResetPassword(true);
                }}
              >
                Request or reset password
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  titleTxt: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 500,
  },
  errorTxt: {
    textAlign: 'center',
    fontSize: 18,
    color: theme.palette.error.main,
  },
  reset: {
    width: '100%',
    textAlign: 'center',
  },
  resetTxt: {
    fontSize: 14,
    cursor: 'pointer',
  },
});

export default FirebaseLogin;
