// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import dayjs from 'dayjs';

import { Arinvoices } from '@gql/schema';

import { getMonetaryValue } from '@services/formatService';
import { getPaidTotalFromARPayments } from '@features/invoices/services/calcService';

import { css } from '@emotion/css';
import { Grid2 as Grid, Paper, Theme, Typography, useTheme } from '@mui/material';

import Spacer from '@components/Utils/Spacer';

// TYPES ---------------------------------------------------------------- //

type InvoiceDetailsPaymentsProps = {
  invoice?: Arinvoices;
};

// COMPONENT ---------------------------------------------------------------- //

const InvoiceDetailsPayments = ({ invoice }: InvoiceDetailsPaymentsProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);
  const clsx = useSxStyles(theme);

  const paidTotal = getPaidTotalFromARPayments(invoice?.arpayments);

  if (!invoice?.arpayments?.length) return null;
  return (
    <div className={cls.payments}>
      <Paper variant='custom' sx={{ padding: `16px` }}>
        <Typography variant='h5' sx={{ lineHeight: 1, fontWeight: 600 }}>
          Payment History
        </Typography>

        <Spacer />

        <Grid container spacing={1} wrap='nowrap'>
          <Grid size={{ xs: 1 }} sx={clsx.gridItem}>
            <Typography align='left' sx={clsx.payKey}>
              ID
            </Typography>
          </Grid>

          <Grid size={{ xs: 2 }} sx={clsx.gridItem}>
            <Typography align='left' sx={clsx.payKey}>
              Date
            </Typography>
          </Grid>

          <Grid size={{ xs: 4 }} sx={clsx.gridItem}>
            <Typography align='left' sx={clsx.payKey}>
              Transaction ID
            </Typography>
          </Grid>

          <Grid size={{ xs: 2 }} sx={clsx.gridItem}>
            <Typography align='right' sx={clsx.payKey}>
              Status
            </Typography>
          </Grid>

          <Grid size={{ xs: 3 }} sx={clsx.gridItem}>
            <Typography align='right' sx={clsx.payKey}>
              Amount
            </Typography>
          </Grid>
        </Grid>

        <div className={cls.line} />

        {invoice?.arpayments?.map((payment, i) => (
          <React.Fragment key={`payment-row-${payment?.id}`}>
            <Grid container spacing={1} wrap='nowrap'>
              <Grid size={{ xs: 1 }} sx={clsx.gridItem}>
                <Typography align='left' sx={clsx.payVal}>
                  {payment?.id}
                </Typography>
              </Grid>

              <Grid size={{ xs: 2 }} sx={clsx.gridItem}>
                <Typography align='left' sx={clsx.payVal}>
                  {dayjs(payment?.createdat).format(`MM/DD/YYYY`)}
                </Typography>
              </Grid>

              <Grid size={{ xs: 4 }} sx={clsx.gridItem}>
                <Typography align='left' sx={clsx.payVal}>
                  {payment?.gateway_transaction_id || `-`}
                </Typography>
              </Grid>

              <Grid size={{ xs: 2 }} sx={clsx.gridItem}>
                <Typography align='right' sx={{ ...clsx.payVal, textTransform: 'capitalize' }}>
                  {payment?.status}
                </Typography>
              </Grid>

              <Grid size={{ xs: 3 }} sx={clsx.gridItem}>
                <Typography align='right' sx={clsx.payVal}>
                  {getMonetaryValue(payment?.amount)}
                </Typography>
              </Grid>
            </Grid>

            {i < invoice?.arpayments?.length - 1 ? <div className={cls.line} /> : null}
          </React.Fragment>
        ))}

        <Spacer size='lg' />

        <Grid container>
          <Grid size='grow'>
            <Typography align='right' sx={clsx.totalKey}>
              Actual Paid Total (May include processing fee):
            </Typography>
          </Grid>

          <Grid>
            <Typography align='right' sx={clsx.totalVal}>
              {getMonetaryValue(paidTotal)}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  payments: css`
    position: relative;
  `,

  line: css`
    width: 100%;
    border-bottom: 1px solid ${theme?.palette?.divider};
    margin: 12px 0;
  `,
});

const useSxStyles = (theme?: Theme) => ({
  gridItem: {
    minWidth: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  payKey: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: 1,
    textOverflow: 'ellipsis',
    fontSize: `12px`,
    fontWeight: 600,
  },
  payVal: {
    lineHeight: 1,
    fontSize: `14px`,
    fontWeight: 400,
  },

  totalKey: {
    marginTop: `2px`,
    marginRight: theme?.spacing(2),
    lineHeight: 1,
    fontSize: `10px`,
    fontWeight: 500,
  },
  totalVal: {
    lineHeight: 1,
    fontSize: `14px`,
    fontWeight: 600,
  },
});

// EXPORT ---------------------------------------------------------------- //

export default InvoiceDetailsPayments;
