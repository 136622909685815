import { useEffect, useState } from 'react';
import { useUser } from '@store';
import { useGetUsersByOrgLazyQuery } from '@gql/schema';
import { Users } from '@gql/schema';
import { useTheme, Grid2 as Grid, Theme, Container, Typography, SxProps, Paper } from '@mui/material';
import * as Sentry from '@sentry/react';
import Toolbar from '@components/Utils/Toolbar';
import DefaultErrorFallback from '@components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '@components/Fallbacks/DefaultEmptyFallback';
import UsersFilter from '../components/UsersFilter';
import UsersTable from '../components/UsersTable';
import Loading from '@components/Utils/Loading';
import { useLocation } from 'react-router-dom';
import { css } from '@emotion/css';

const UsersPage = () => {
  const user = useUser();
  const theme = useTheme();
  const styles = useStyles(theme);
  const sxStyles = useSxStyles(theme);
  const location = useLocation();

  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<Users[]>([]);

  const [getUsersByOrg] = useGetUsersByOrgLazyQuery();

  const fetchUsersByOrg = async (customerIds: number[]) => {
    if (!user) return;

    setLoading(true);
    try {
      const { data } = await getUsersByOrg({
        variables: { customerIds },
        fetchPolicy: 'network-only',
      });

      let usersList: Users[] = [];
      if (data?.defaultCustomer && data?.defaultCustomer?.length > 0) {
        usersList = data.defaultCustomer as Users[];
      }
      if (data?.userToCustomers && data?.userToCustomers?.length > 0) {
        usersList =
          usersList.length > 0
            ? usersList.concat(data.userToCustomers.map(u => u.user) as Users[])
            : (data.userToCustomers.map(u => u.user) as Users[]);
      }

      usersList = usersList.filter((user, index, self) => index === self.findIndex(u => u.id === user.id));
      setUsers(usersList);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const allowedCustomers = user?.claims?.['x-hasura-allowed-customers'] ?? [];
    fetchUsersByOrg(allowedCustomers as number[]);
  }, [user, location.key]);

  if (loading && user?.profile?.default_role === 'dealer-super-admin') {
    return (
      <div className={styles.pageContainer}>
        <Container maxWidth='lg'>
          <Grid container spacing={2} alignItems='center'>
            <Loading />
          </Grid>
        </Container>
      </div>
    );
  } else if (users?.length > 0 && user?.profile?.default_role === 'dealer-super-admin') {
    return (
      <div className={styles.pageContainer}>
        <Container maxWidth='lg'>
          <div className={styles.header}>
            <Grid container spacing={2} alignItems='center'>
              <Grid size={{ xs: 8 }}>
                <Typography sx={sxStyles.titleText}>Users Index</Typography>
              </Grid>
              <Grid size={{ xs: 4 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <UsersFilter
                  search={search}
                  onSearchChange={setSearch}
                  onRefresh={() => fetchUsersByOrg((user?.claims?.['x-hasura-allowed-customers'] as number[]) ?? [])}
                />
              </Grid>
            </Grid>
          </div>

          <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING USERS TABLE' />}>
            <Paper variant='custom' style={{ overflow: 'hidden' }}>
              <UsersTable users={users} search={search} />
            </Paper>
          </Sentry.ErrorBoundary>
        </Container>
      </div>
    );
  } else if ((!users || !users.length) && user?.profile?.default_role === 'dealer-super-admin') {
    return (
      <div className={styles.pageContainer}>
        <Container maxWidth='lg'>
          <Toolbar fullscreen title='Users'>
            <UsersFilter />
          </Toolbar>
          <DefaultEmptyFallback message='COULD NOT FIND USERS' />
        </Container>
      </div>
    );
  } else {
    return (
      <div className={styles.pageContainer}>
        <Container maxWidth='lg'>
          <DefaultEmptyFallback message='RESTRICTED ACCESS' />
        </Container>
      </div>
    );
  }
};

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  titleText: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  allCustomerSwitch: {
    fontSize: 24,
    fontWeight: 500,
  },
});

const useStyles = (theme: Theme) => {
  return {
    pageContainer: css`
      padding: ${theme.spacing(3)} 0;
    `,
    header: css`
      margin-bottom: ${theme.spacing(3)};
    `,
  };
};

export default UsersPage;
