// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { css } from '@emotion/css';
import { Container, Theme, useTheme } from '@mui/material';

import DefaultErrorFallback from '@components/Fallbacks/DefaultErrorFallback';

import { MovePlannerProvider } from '@features/moveCreation/providers/MovePlannerProvider';
import MovePlannerContent from '@features/moveCreation/components/MovePlannerContent';

// COMPONENT ---------------------------------------------------------------- //

const MovePlannerPage = () => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const location = useLocation();
  const state = location?.state;

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => (
        <DefaultErrorFallback
          message='Unexpected error caught at MovePlannerPage.tsx'
          error={error}
          resetError={resetError}
        />
      )}
    >
      <MovePlannerProvider state={state}>
        <div className={cls.pageContainer}>
          <Container maxWidth='lg'>
            <MovePlannerContent />
          </Container>
        </div>
      </MovePlannerProvider>
    </Sentry.ErrorBoundary>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  return {
    page: css`
      position: relative;
    `,
    pageContainer: css`
      padding: ${theme?.spacing(3)} 0;
      min-height: 100vh;
    `,
  };
};

// EXPORT ---------------------------------------------------------------- //

export default MovePlannerPage;
