//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Chip } from '@mui/material';
import Table from '@components/TableComponents/Table';
import Column from '@components/TableComponents/Table';
import { Users } from '@gql/schema';
import { useNavigate } from 'react-router-dom';
// import { BiSolidUser, } from 'react-icons/bi';
import { MdClear } from 'react-icons/md';

interface UsersTableProps {
  users: Users[];
  search: string | '';
}

//////////////////////// COMPONENT ////////////////////////

const UsersTable = ({ users, search }: UsersTableProps) => {
  const navigate = useNavigate();

  // Set filtered users based on search results
  const [filteredUsers, setFilteredUsers] = React.useState(users);

  const getRoleName = (role: string) => {
    switch (role) {
      case 'dealer':
        return 'User';
      case 'dealer-admin':
        return 'Admin';
      case 'dealer-super-admin':
        return 'Super Admin';
      default:
        return 'Unknown';
    }
  };

  React.useEffect(() => {
    let newFilteredUsers = [...users]; // Create a new array to avoid reference issues

    if (search && search.length > 0) {
      const searchInput = search.toLowerCase().trim();
      newFilteredUsers = newFilteredUsers.filter(u => {
        return (
          String(u.id).toLowerCase().includes(searchInput) ||
          (u.display_name || '').toLowerCase().includes(searchInput) ||
          (u.email || '').toLowerCase().includes(searchInput) ||
          (u.default_role || '').toLowerCase().includes(searchInput) ||
          (u.customer?.name || '').toLowerCase().includes(searchInput)
        );
      });
    }

    setFilteredUsers(newFilteredUsers);
  }, [users, search]);

  return (
    <Table
      data={filteredUsers}
      defaultOrderBy={'name'}
      defaultOrder={'asc'}
      tableAriaLabel={`users`}
      stickyHeader={true}
      onRowClick={(data: any) => navigate(`/users/${(data as Users)?.id}`)}
    >
      <Column
        name={`id`}
        value={(user: Users) => user.id}
        label={`ID`}
        tooltip={`ID of the user`}
        style={{ minWidth: 20 }}
      />
      {/* <Column
          name= {`avatar_img`}
          value= {(user: Users) => user.display_name}
          renderer= {(user: Users) =>
                <Avatar
                alt={user?.display_name}
                src={user?.avatar_url ? user.avatar_url : undefined}
                sx={{
                  width: 48,
                  height: 48,
                  fontSize: 16,
                }}
              >
                {user?.display_name?.charAt(0) || <BiSolidUser size={48} />}
              </Avatar>
          }
        label= {`Avatar`}
        tooltip= {`Image of the user`}
        /> */}
      <Column
        name={`name`}
        value={(user: Users) => user?.display_name}
        label={`Name`}
        tooltip={`Name of the user`}
        style={{ minWidth: 40 }}
      />
      <Column
        name={`email`}
        value={(user: Users) => user?.email}
        label={`Email`}
        tooltip={`Email of the user`}
        style={{ minWidth: 80 }}
      />
      <Column
        name={`market`}
        value={(user: Users) => user?.customer?.name}
        label={`Market`}
        tooltip={`Name of the user's primary market`}
        style={{ minWidth: 20 }}
      />
      <Column
        name={`role`}
        value={(user: Users) => getRoleName(user?.default_role)}
        label={`Role`}
        tooltip={`Default role of the user`}
        style={{ minWidth: 80 }}
      />
      <Column
        name={`status`}
        value={(user: Users) => user?.active}
        renderer={(user: Users) => (
          <Chip
            variant={user.active ? 'filled' : 'outlined'}
            icon={!user.active ? <MdClear /> : undefined}
            size='small'
            color={user.active ? 'primary' : 'default'}
            label={user.active ? 'Active' : 'Inactive'}
          />
        )}
        label={`Status`}
        align={`right`}
        tooltip={`Status of the user`}
        style={{ minWidth: 30 }}
      />
    </Table>
  );
};

export default UsersTable;
