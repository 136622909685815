import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useGetWorkflowsetsQuery } from '@gql/schema';
import { useRooftop } from '@store';
import Loading from '@components/Utils/Loading';
import DefaultErrorFallback from '@components/Fallbacks/DefaultErrorFallback';
import { styled } from '@mui/material/styles';

const StyledContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '8px',
  background: theme.palette.background.paper,
  overflow: 'hidden',
}));

const StyledTable = styled(Table)({
  '& .MuiTableCell-root': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 0, // This is needed for text-overflow to work in table cells
    padding: '6px 16px',
  },
  '& .MuiTableBody-root .MuiTableRow-root:nth-of-type(even)': {
    backgroundColor: '#f8f8f8',
  },
});

const WorkflowsetTable = () => {
  const rooftop = useRooftop();
  const { data, loading, error } = useGetWorkflowsetsQuery({
    variables: {
      rooftopId: rooftop?.id!,
    },
    skip: !rooftop?.id,
  });

  if (loading) return <Loading />;
  if (error) return <DefaultErrorFallback error={error} />;

  return (
    <StyledContainer>
      <StyledTable size="small">
        <TableHead>
          <TableRow>
            <TableCell align='left' width='30%'>
              Id
            </TableCell>
            <TableCell align='left' width='70%'>
              Name
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.workflowsets
            ?.slice()
            .sort((a, b) => a.id - b.id)
            .map(workflowset => (
              <TableRow key={workflowset.id}>
                <TableCell align='left' width='30%'>
                  {workflowset.id}
                </TableCell>
                <TableCell align='left' width='70%'>
                  {workflowset.name}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </StyledTable>
    </StyledContainer>
  );
};

export default WorkflowsetTable;
