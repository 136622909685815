import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@store';
import { css } from '@emotion/css';
import { Theme, useTheme, Button, CircularProgress, Paper, Typography } from '@mui/material';
import Divider from '@components/Divider';
import MovePlannerWorkflowsetForm from '@features/moveCreation/components/MovePlannerWorkflowsetForm';
import MovePlannerRooftopForm from '@features/moveCreation/components/MovePlannerRooftopForm';
import MovePlannerEmailForm from '@features/moveCreation/components/MovePlannerEmailForm';
import MovePlannerConsumerForm from '@features/moveCreation/components/MovePlannerConsumerForm';
import MovePlannerTimeForm from '@features/moveCreation/components/MovePlannerTimeForm';
import MovePlannerLaneForm from '@features/moveCreation/components/MovePlannerLaneForm';
import MovePlannerVehicleForm from '@features/moveCreation/components/MovePlannerVehicleForm';
import MovePlannerAdditionalForm from '@features/moveCreation/components/MovePlannerAdditionalForm';
import { MovePlannerContext } from '@features/moveCreation/providers/MovePlannerProvider';
import { useInsertAppointmentMutation, useUpdateAppointmentMutation, Appointments } from '@gql/schema';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
const log = true;

/////////////////////////// TYPES ///////////////////////////
export interface Vehicle {
  referenceId?: string | null;
  manual?: boolean;
  vin?: string | null;
  stock?: string | null;
  make?: string | null;
  model?: string | null;
  year?: string | null;
  color?: string | null;
}

interface AppointmentAddFormProps {
  appointment?: Appointments;
  editMode?: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface InsertableAppointment {
  status: string;
  customer_id: number;
  pickup_location_id: number;
  delivery_location_id: number;
  appointment_time: string;
  workflow_set_id?: number;
  consumer_at_pickup: boolean;
  consumer_name: string;
  consumer_phone: string;
  driver_notes?: string;
  offered_by: string;
  offered_at: string;
  accepted_terms: boolean;
  vehicle_year?: number;
  vehicle_make: string;
  vehicle_model: string;
  vehicle_color?: string;
  vehicle_vin: string;
  vehicle_manual?: boolean;
  config: object;
  createdat: string;
  createdby: string;
  updatedat: string;
  updatedby: string;
}

/////////////////////////// HELPER FUNCTIONS ///////////////////////////

const getStartTime = () => {
  let start = dayjs().startOf('minute').add(120, 'minute');
  const addStep = 10 - (start.minute() % 10);
  start = start.add(addStep, 'minute');
  return start.utc().format();
};

/////////////////////////// COMPONENT ///////////////////////////

const AppointmentAddForm: React.FC<AppointmentAddFormProps> = ({ appointment, editMode, setEditMode }) => {
  const user = useUser();
  const navigate = useNavigate();
  const theme = useTheme();
  const cls = useStyles(theme);
  const movePlannerContext = React.useContext(MovePlannerContext);
  const [insertAppointment] = useInsertAppointmentMutation();
  const [updateAppointmentMutation] = useUpdateAppointmentMutation();
  const [loading, setLoading] = React.useState(false);

  if (!movePlannerContext) {
    return <div>Loading...</div>;
  }

  const {
    rooftopId,
    rooftopConfig,
    consumer,
    setConsumer,
    vehicle1,
    setVehicle1,
    additional1,
    setAdditional1,
    emails,
    setEmails,
    workflowsetId,
    setWorkflowsetId,
    time,
    setTime,
    setValidation,
    pickup,
    setPickup,
    delivery,
    setDelivery,
    validateForm,
    setLane,
    defaultConsumer,
    defaultVehicle,
    defaultAdditional,
    defaultLane,
    defaultLocation,
    defaultValidation,
  } = movePlannerContext;

  /////// Form handling ///////

  const clearForm = () => {
    setTime(getStartTime());
    setConsumer(defaultConsumer);
    setVehicle1(defaultVehicle);
    setAdditional1(defaultAdditional);
    setLane(defaultLane);
    setPickup(defaultLocation);
    setDelivery(defaultLocation);
    setValidation(defaultValidation);
    setEmails(emails);
    setWorkflowsetId(1);
    setLane(defaultLane);
  };

  ////// Validation ///////

  const isFormValid = () => {
    try {
      const valid = validateForm();
      if (!valid) {
        toast.error(`Please check the form for errors.`);
        return false;
      }
    } catch (error) {
      console.error('Form validation crashed', error);
      return false;
    }

    return true;
  };

  //////// Submission //////

  const buildInsertableAppointment = () => {
    // Check all required fields exist
    if (!rooftopId || !consumer?.name || !consumer?.phone || !vehicle1?.make || !vehicle1?.model || !vehicle1?.vin) {
      return null;
    }

    const config =
      emails && emails?.length > 0
        ? { emailsToNotify: emails, claim_number: vehicle1.referenceId }
        : { claim_number: vehicle1.referenceId };

    try {
      const formattedPickupTime = dayjs(time).utc().format();
      return {
        status: 'ready',
        customer_id: rooftopId,
        pickup_location_id: pickup?.id ?? 0,
        delivery_location_id: delivery?.id ?? 0,
        appointment_time: formattedPickupTime,
        workflow_set_id: workflowsetId ?? 1,
        consumer_at_pickup: consumer.action === 'pickup',
        consumer_name: consumer.name,
        consumer_phone: consumer.phone,
        driver_notes: additional1?.notes ?? '',
        offered_by: user?.profile?.email ?? 'system',
        offered_at: 'now()',
        accepted_terms: false,
        vehicle_year: Number(vehicle1.year) || undefined,
        vehicle_make: vehicle1.make,
        vehicle_model: vehicle1.model,
        vehicle_color: vehicle1.color ?? undefined,
        vehicle_vin: vehicle1.vin,
        vehicle_manual: vehicle1.manual ?? false,
        config,
        createdat: 'now()',
        createdby: user?.profile?.email ?? 'system',
        updatedat: 'now()',
        updatedby: user?.profile?.email ?? 'system',
      } as InsertableAppointment;
    } catch (error) {
      console.error('Crashed trying to create insertable appointment', error);
      return null;
    }
  };

  const addAppointment = async (appointment: InsertableAppointment) => {
    log && console.log(`Attempting to insert appointment...`, appointment);
    try {
      const res = await insertAppointment({ variables: { appointment: appointment } });
      if (res?.data?.insert_appointments_one?.id) {
        log && console.log(`>> INSERT appointment success:`, res.data.insert_appointments_one.id);
        toast.success(
          `Your customer's appointment has been created. They will receive a text message with appointment details and payment information.`
        );
        return res.data.insert_appointments_one.id;
      }
    } catch (err) {
      console.error(`Failed to insert appointment:`, err);
      toast.error(
        `Failed to create appointment. Please ensure that you've entered all the correct data and resubmit. If the problem persists, please contact your representative.`
      );
    }
    return false;
  };

  const updateAppointment = async (appointmentId: number) => {
    log && console.log(`Attempting to update appointment ${appointmentId}...`);
    try {
      // Log the pickupTime before formatting
      log && console.log(`Pickup time before formatting: ${time}`);

      // Apply the same UTC formatting as in buildInsertableAppointment
      const formattedPickupTime = dayjs(time).utc().format();
      log && console.log(`Formatted pickup time: ${formattedPickupTime}`);

      // Preserve existing config and merge with new values
      const updatedConfig = {
        ...appointment?.config, // Keep existing config values
        claim_number: vehicle1?.referenceId,
        emailsToNotify: emails,
      };

      const res = await updateAppointmentMutation({
        variables: {
          id: appointmentId,
          changes: {
            pickup_location_id: pickup?.id ?? 0,
            delivery_location_id: delivery?.id ?? 0,
            appointment_time: formattedPickupTime, // Use formatted pickup time
            workflow_set_id: workflowsetId,
            consumer_at_pickup: consumer?.action === 'pickup' ? true : false,
            consumer_name: consumer!.name,
            consumer_phone: consumer!.phone,
            driver_notes: additional1?.notes,
            vehicle_year: Number(vehicle1!.year),
            vehicle_make: vehicle1!.make,
            vehicle_model: vehicle1!.model,
            vehicle_color: vehicle1!.color,
            vehicle_vin: vehicle1!.vin,
            vehicle_manual: vehicle1!.manual,
            config: updatedConfig,
            updatedat: 'now()',
            updatedby: user?.profile?.email ?? 'system',
          },
        },
      });

      if (res?.data?.update_appointments_by_pk?.id) {
        toast.success(`Appointment updated successfully`);
        return true;
      }
    } catch (err) {
      console.error(`Failed to update appointment:`, err);
      toast.error(`Failed to update appointment`);
    }
    return false;
  };

  const handleFinishAppointment = async () => {
    setValidation(defaultValidation);
    try {
      const valid = isFormValid();
      if (!valid) {
        return;
      }

      setLoading(true);
      if (appointment && appointment.id) {
        await updateAppointment(appointment.id);
        if (setEditMode) {
          setEditMode(false);
        }
      } else {
        if (!rooftopId) {
          toast.error('No market ID found');
          return;
        }
        const insertableAppointment = buildInsertableAppointment();
        if (insertableAppointment) {
          const appointmentId = await addAppointment(insertableAppointment);
          if (appointmentId) {
            navigate(`/appointments/${appointmentId}`);
          }
        }
      }

      clearForm();
    } catch (error) {
      console.error('handleFinishAppointment crashed', error);
    }
    setLoading(false);
  };

  //////////////////////////////////////// RENDER ////////////////////////////////////////
  return (
    <form name='plan-form'>
      {!editMode && (
        <div className={cls.title}>
          <Typography variant='h3' sx={{ fontWeight: 600, marginBottom: `12px` }}>
            Consumer Appointment Planner
          </Typography>

          <Typography variant='body2' sx={{ textWrap: `pretty` }}>
            Welcome to the Consumer Appointment Planner! This form allows you to schedule appointments and arrange
            payment directly with your customer for vehicle pickup and delivery. Once you've submitted the appointment
            information, your customer will receive an invoice by text message. When paid, the appointment will be
            accepted and fulfilled by Hopdrive, and you can track its progress from your dashboard.
          </Typography>
        </div>
      )}

      <Divider
        spacer
        mb={6}
        tip={`Enter some high-level information that we need in order to fulfill your customer's appointment request.`}
      >
        OPTIONS
      </Divider>

      <Paper variant='custom' sx={{ padding: `16px` }}>
        <MovePlannerRooftopForm />
        <MovePlannerWorkflowsetForm />
        <MovePlannerEmailForm appointmentMode={true} />
        <MovePlannerConsumerForm />
        <MovePlannerTimeForm appointmentMode={true} />
      </Paper>

      <Divider
        spacer
        mb={6}
        tip={`Select the vehicle's origin and destination locations. You can either select an existing location or search for a new with Google Maps in the fields below.`}
      >
        LANE
      </Divider>

      <Paper variant='custom' sx={{ padding: `16px` }}>
        <MovePlannerLaneForm appointmentMode={true} />
      </Paper>

      <Divider spacer mb={6} tip={`Fill out the vehicle information and any additional appointment notes below.`}>
        VEHICLE INFORMATION
      </Divider>

      <Paper variant='custom' sx={{ padding: `16px` }}>
        <MovePlannerVehicleForm
          index={1}
          requireMakeAndModel={true}
          rooftopId={rooftopId}
          vehicle={vehicle1}
          setVehicle={setVehicle1}
          appointmentMode={true}
          refNumLabel={rooftopConfig?.appointments?.ref_num_label ? rooftopConfig?.appointments?.ref_num_label : null}
        />
        <MovePlannerAdditionalForm additional={additional1} setAdditional={setAdditional1} appointmentMode={true} />
      </Paper>

      {/* FOOTER */}

      <div className={cls.footer}>
        <Button disabled={loading} name='plan-submit' color='primary' size='large' onClick={handleFinishAppointment}>
          Submit
          {loading ? <CircularProgress color='inherit' size={24} sx={{ marginLeft: `12px` }} /> : null}
        </Button>
      </div>
    </form>
  );
};

const useStyles = (theme?: Theme) => {
  return {
    title: css`
      max-width: 560px;
    `,

    lockedLink: css`
      text-decoration: none;
      font-weight: 600;
      color: ${theme?.palette?.primary?.main};
      transition: 0.1s ease-in-out;
      :hover {
        color: ${theme?.palette?.primary?.light};
      }
    `,

    flex: css`
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: space-between;
      gap: 12px;
      ${theme?.breakpoints?.down('md')} {
        flex-direction: column;
      }
    `,
    flexItem: css`
      flex: 1;
    `,

    spacer: css`
      width: 100%;
      height: 24px;
    `,

    footer: css`
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 36px;
    `,
  };
};

export default AppointmentAddForm;
