// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';

import { css } from '@emotion/css';
import { IconButton, Theme, Tooltip, useTheme } from '@mui/material';

import { BiFilterAlt } from 'react-icons/bi';

// TYPES ---------------------------------------------------------------- //

interface IconClearFiltersProps {
  className?: string;
  onClear?: () => void;
  tooltip?: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  [key: string]: any;
}

// COMPONENT ---------------------------------------------------------------- //

const IconClearFilters = ({
  className = undefined,
  onClear = undefined,
  tooltip = undefined,
  tooltipPlacement = 'top',
  ...rest
}: IconClearFiltersProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const getClassNames = () => {
    const classNames = [cls.iconBtn];
    if (className) classNames.push(className);
    return classNames.join(` `);
  };

  return (
    <Tooltip placement={tooltipPlacement} title={tooltip || `Clear Filters`}>
      <IconButton className={getClassNames()} onClick={onClear} {...rest}>
        <BiFilterAlt size={24} />
      </IconButton>
    </Tooltip>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  iconBtn: css`
    padding: ${theme?.spacing(1)};
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default IconClearFilters;
