import { useState, useEffect } from 'react';
import { useCancelAppointmentMutation } from '@gql/schema';
import { toast } from 'react-toastify';
import { useUser } from '@store';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '@components/Modal';
interface Appointment {
  id: number;
  appointment_time: string;
  consumer_name?: string;
  consumer_phone?: string;
  offered_by?: string;
  offered_at?: string;
  createdat?: string;
  createdby?: string;
  vehicle_make?: string;
  vehicle_year?: number;
  vehicle_model?: string;
  vehicle_vin?: string;
  status: string;
  customer_id: number;
  move_id?: number;
  vehicle_color?: string;
  driver_notes?: string;
  pickup?: {
    address: string;
  };
  delivery?: {
    address: string;
  };
  customer?: {
    name: string;
    config: object;
    organization?: {
      config: object;
    };
  };
  config?: {
    claim_number?: string;
    offer_link?: string;
    emailsToNotify?: string[];
  };
  payment_transaction_id?: string;
  payment_failure_reason?: string;
  move?: {
    status: string;
  };
}

const AppointmentCancelModal = ({
  open,
  onClose,
  appointment,
}: {
  open: boolean;
  onClose: () => void;
  appointment: Appointment;
}) => {
  const user = useUser();

  const [subtitleText, setSubtitleText] = useState('');

  useEffect(() => {
    if (appointment) {
      if (appointment.status === 'paid' && appointment.move_id) {
        setSubtitleText(
          `Are you sure you want to cancel this appointment? Move #${appointment.move_id} will be canceled and the customer's payment will be refunded.`
        );
      } else if (appointment.status === 'paid' && !appointment.move_id) {
        setSubtitleText(
          `Are you sure you want to cancel this appointment? No move will be created, and the customer's payment will be refunded.`
        );
      } else {
        setSubtitleText(`Are you sure you want to cancel this appointment?`);
      }
    }
  }, [appointment]);

  const [cancelAppointmentMutation] = useCancelAppointmentMutation();

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleSubmit = async () => {
    await handleCancelAppointment();

    handleClose();
  };

  const handleCancelAppointment = async () => {
    try {
      const res = await cancelAppointmentMutation({
        variables: {
          id: appointment.id,
          updatedby: user?.profile?.email,
        },
      });
      if (res?.data?.update_appointments ?? null) {
        toast.success('Appointment canceled successfully!');
      } else {
        toast.error('Failed to cancel appointment!');
      }
    } catch (err) {
      console.error('Failed to cancel appointment:', err);
      toast.error('Failed to cancel appointment!');
    }
  };

  return(
    <Modal open={open}>
    <ModalHeader handleClose={handleClose}>Cancel Appointment</ModalHeader>

    <ModalContent subtitle={subtitleText} />

    <ModalFooter>
      <ModalAction onClick={() => handleSubmit()}>Confirm</ModalAction>
      <ModalAction onClick={() => handleClose()} color='secondary'>
        Go Back
      </ModalAction>
    </ModalFooter>
  </Modal>
  )

};

export default AppointmentCancelModal;
