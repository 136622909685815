import React, { useState, MouseEvent } from 'react';
import { Theme, useTheme, SxProps, Typography, Tooltip, Icon, Menu, MenuItem } from '@mui/material';
import { css } from '@emotion/css';

interface Action {
  label?: string;
  handler?: () => void;
  hide?: boolean;
}

interface BoldDivideProps {
  children?: string;
  tip?: string;
  actions?: Action[];
  color?: 'white' | 'dark' | 'default';
  spacer?: boolean;
  mt?: number;
  mb?: number;
}

////////// COMPONENT //////////
export default function BoldDivide(props: BoldDivideProps) {
  const theme = useTheme();
  const sxStyles = useSxStyles(theme);
  const styles = useStyles(theme);

  const { children, tip, actions, color = 'default', spacer, mt, mb } = props;

  const [actionsOpen, setActionsOpen] = useState<null | HTMLElement>(null);

  let label = children ? children : null;
  if (label) label = label.replace(/ /g, '\xa0').replace(/-/g, '-\u2060').toUpperCase();

  let lineColor;
  let labelColor;
  if (color === 'white') {
    lineColor = '#fff';
    labelColor = '#fff';
  } else if (color === 'dark') {
    lineColor = theme.palette.text.primary;
    labelColor = theme.palette.text.primary;
  } else {
    lineColor = theme.palette.text.disabled;
    labelColor = theme.palette.text.secondary;
  }

  const filteredActions = actions ? actions.filter(a => !a.hide) : [];

  const handleActionsOpen = (event: MouseEvent<HTMLElement>) => {
    setActionsOpen(event.currentTarget);
  };

  const handleActionsClose = () => {
    setActionsOpen(null);
  };

  const handleAction = (action: Action) => {
    handleActionsClose();
    if (action.handler) action.handler();
  };

  return (
    <>
      {spacer && <div className={styles.spacer} />}
      <div className={styles.root} style={{ marginTop: mt ? mt : '4px', marginBottom: mb ? mb : '4px' }}>
        {label ? (
          <>
            <div className={styles.hold}>
              <div className={styles.line} />
            </div>
            <Typography sx={sxStyles.txt} style={{ color: labelColor }}>
              {label}
            </Typography>
            <div className={styles.hold}>
              <div className={styles.line} />
              {tip ? (
                <Tooltip placement='top' title={tip}>
                  <Icon sx={sxStyles.tip} style={{ color: labelColor }}>
                    help
                  </Icon>
                </Tooltip>
              ) : null}
              {filteredActions.length > 0 && (
                <>
                  <Tooltip placement='top' title='Actions'>
                    <Icon onClick={handleActionsOpen} sx={sxStyles.actions} style={{ color: labelColor }}>
                      settings
                    </Icon>
                  </Tooltip>
                  <Menu
                    keepMounted
                    id='divide-actions-menu'
                    anchorEl={actionsOpen}
                    open={Boolean(actionsOpen)}
                    onClose={handleActionsClose}
                  >
                    {filteredActions.map((action, i) => (
                      <MenuItem key={`divide-action-${i}`} onClick={() => handleAction(action)}>
                        {action.label || `Action ${i + 1}`}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </div>
          </>
        ) : (
          <div className={styles.flatLine} style={{ background: lineColor }} />
        )}
      </div>
    </>
  );
}

////////// STYLES //////////
const useSxStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  tip: {
    margin: '4px 0 0 10px',
    cursor: 'pointer',
    fontSize: '21px',
    [theme.breakpoints.down('sm')]: {
      margin: '2px 0 0 8px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0 0 0 6px',
    },
  },
  actions: {
    margin: '4px 0 0 10px',
    cursor: 'pointer',
    fontSize: '21px',
    [theme.breakpoints.down('sm')]: {
      margin: '2px 0 0 8px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0 0 0 6px',
    },
  },
  txt: {
    justifySelf: 'center',
    margin: '2px 12px 0 2px',
    fontSize: '22px',
    fontWeight: 1000,
    [theme.breakpoints.down('sm')]: {
      margin: '2px 10px 0 10px',
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '2px 8px 0 8px',
      fontSize: '12px',
    },
  },
});

const useStyles = (theme?: Theme) => ({
  spacer: css`
    width: 100%;
    height: 48px;
    ${theme?.breakpoints.down('sm')} {
      height: 36px;
    }
    ${theme?.breakpoints.down('xs')} {
      height: 24px;
    }
  `,
  root: css`
    display: flex;
    vertical-align: top;
    width: 100%;
  `,
  hold: css`
    display: flex;
    width: 100%;
    height: 1px;
  `,
  line: css`
    display: none;
    width: 100%;
    height: 1px;
    margin-top: 14px;
    background: ${theme?.palette.text.disabled};
    ${theme?.breakpoints.down('sm')} {
      margin-top: 12px;
    }
    ${theme?.breakpoints.down('xs')} {
      margin-top: 10px;
    }
  `,
  flatLine: css`
    width: 100%;
    height: 1px;
    background: ${theme?.palette.text.disabled};
  `,
});
