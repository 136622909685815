import dayjs from 'dayjs';

export const durationBetween = (timeOne?: string | null, timeTwo?: string | null) => {
  if (timeOne && timeTwo) {
    const formattedTimeOne = dayjs(timeOne);
    const formattedTimeTwo = dayjs(timeTwo);
    const durationSec = formattedTimeTwo.diff(formattedTimeOne, `second`);
    return durationSec;
  }
  return 0;
};

export const getDurationInMinutes = (seconds = 0, precision = 0) => {
  const fallbackDuration = `0 min`;

  if (seconds) {
    return `${Number(seconds / 60).toFixed(precision)} min`;
  }

  return fallbackDuration;
};

export const getMinutesBetween = (startTimestamp?: string | null, endTimestamp?: string | null) => {
  if (startTimestamp && endTimestamp) {
    const start = dayjs(startTimestamp);
    const end = dayjs(endTimestamp);
    if (start && end) {
      const dur = end.diff(start);
      const mins = Math.round(Math.abs(dayjs.duration(dur).asMinutes()));
      return mins;
    }
  }
  return 0;
};
