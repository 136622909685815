import { useState, useEffect } from 'react';
import { Tooltip, Typography, useTheme, Theme } from '@mui/material';
import { css, keyframes } from '@emotion/css';
import { Appointments } from '@gql/schema';

interface AppointmentStatusTrackerProps {
    appointment: Appointments;
    size?: 'sm' | 'md' | 'lg';
}

const AppointmentStatusTracker = ({ appointment, size = 'lg' }: AppointmentStatusTrackerProps) => {
    const theme = useTheme();
    const [offeredAt, setOfferedAt] = useState('');
    const [status, setStatus] = useState('');
    const [moveCreated, setMoveCreated] = useState(0);

    useEffect(() => {
        setOfferedAt(appointment?.offered_at || '');
        setStatus(appointment?.status || '');
        setMoveCreated(appointment?.move_id || 0);
    }, [appointment]);

    let step = 0;
    let activeStep = 1;
    let tooltipText = ``;
    
    // Determine the current step and which step should pulse
    if ((status === 'new' || status === 'pending' || status === 'ready') && !offeredAt) {
      step = 1;
      activeStep = 1;
      tooltipText = `Appointment has been created.`;
    } else if (offeredAt && status === 'ready') {
      step = 1;
      activeStep = 1;
      tooltipText = `Offer has been sent to the consumer and is awaiting payment.`;
    } else if (status === 'paying') {
      step = 2;
      activeStep = 2;
      tooltipText = `Payment is being processed.`;
    } else if (status === 'paid' || status === 'failed') {
      step = 2;
      if (status === 'paid') {
        step = 3;
        activeStep = 3;
        tooltipText = `Payment has been collected and Hopdrive is creating the move.`;
      } else {
        step = 2;
        activeStep = 2;
        tooltipText = `Payment failed. The customer may continue to retry.`;
      }
    } else if (moveCreated) {
      step = 3;
      activeStep = 3;
      tooltipText = `Appointment confirmed and move created`;
    } else if (status === 'canceled') {
      step = 3;
      activeStep = 3;
      tooltipText = `Appointment canceled`;
    } else if (status === 'refunded') {
      step = 3;
      activeStep = 3;
      tooltipText = `Appointment canceled and payment refunded`;
    }

    const cls = useStyles(theme, { 
        status,
        moveCreated,
        activeStep,
        size 
    });

    if (status === 'canceled' || status === 'refunded') {
        return (
            <div className={cls.moveStatus}>
                <div className={css`
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 1;
                    padding: 14px;
                    border-radius: 64px;
                    color: ${theme.palette.text.contrastPrimary};
                    background-color: ${theme.palette.grey[500]};
                `}>
                    <Typography sx={{ lineHeight: 1, fontSize: '21px', fontWeight: 500, color: '#fff' }}>
                        {status === 'canceled' ? 'CANCELED' : 'CANCELED & REFUNDED'}
                    </Typography>
                </div>
            </div>
        );
    }

    return (
        <div className={cls.moveStatus}>
            <Tooltip title={activeStep === 1 ? tooltipText : ''} placement='top' arrow>
                <div className={cls.offer}>
                    <Typography sx={{ lineHeight: 1, fontSize: '21px', fontWeight: 500, color: '#fff' }}>
                        OFFER
                    </Typography>
                </div>
            </Tooltip>
            <Tooltip title={activeStep === 2 ? tooltipText : ''} placement='top' arrow>
                <div className={cls.payment}>
                    <Typography sx={{ lineHeight: 1, fontSize: '21px', fontWeight: 500, color: '#fff' }}>
                        PAYMENT
                    </Typography>
                </div>
            </Tooltip>
            <Tooltip title={activeStep === 3 ? tooltipText : ''} placement='top' arrow>
                <div className={cls.confirmation}>
                    <Typography sx={{ lineHeight: 1, fontSize: '21px', fontWeight: 500, color: '#fff' }}>
                        CONFIRMATION
                    </Typography>
                </div>
            </Tooltip>
        </div>
    );
};

const getSizePadding = (size: 'sm' | 'md' | 'lg') => {
    switch (size) {
        case 'sm': return '10px';
        case 'md': return '12px';
        case 'lg': return '14px';
    }
};

const getSizeGap = (size: 'sm' | 'md' | 'lg') => {
    switch (size) {
        case 'sm': return '24px';
        case 'md': return '30px';
        case 'lg': return '36px';
    }
};

const getSizeContentWidth = (size: 'sm' | 'md' | 'lg') => {
    switch (size) {
        case 'sm': return '16px';
        case 'md': return '20px';
        case 'lg': return '24px';
    }
};

const getSizeContentOffset = (size: 'sm' | 'md' | 'lg') => {
    switch (size) {
        case 'sm': return '-8px';
        case 'md': return '-10px';
        case 'lg': return '-12px';
    }
};

const useStyles = (theme: Theme, props: any) => {
    const pulse = keyframes`
        0% {
            background-color: ${theme.palette.primary.main};
        }
        50% {
            background-color: ${theme.palette.primary.lighter};
        }
        100% {
            background-color: ${theme.palette.primary.main};
        }
    `;

    return {
        moveStatus: css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: ${getSizeGap(props.size)};
        `,
        offer: css`
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            padding: ${getSizePadding(props.size)};
            border-radius: 64px 0px 0px 64px;
            color: ${theme.palette.text.contrastPrimary};
            background-color: ${(props.status === 'paying' || props.status === 'paid' || props.status === 'failed' || props.moveCreated || props.status === 'canceled' || props.status === 'refunded' || props.status === 'new' || props.status === 'pending' || props.status === 'ready')
                ? theme.palette.primary.main
                : theme.palette.primary.lighter};
            animation: ${props.activeStep === 1 ? `${pulse} 1s infinite` : `none`};
            ::after {
                content: '';
                position: absolute;
                top: 0px;
                right: ${getSizeContentOffset(props.size)};
                width: ${getSizeContentWidth(props.size)};
                height: 100%;
                transform: skewX(-24deg);
                background-color: ${(props.status === 'paying' || props.status === 'paid' || props.status === 'failed' || props.moveCreated || props.status === 'canceled' || props.status === 'refunded' || props.status === 'new' || props.status === 'pending' || props.status === 'ready')
                    ? theme.palette.primary.main
                    : theme.palette.primary.lighter};
                animation: ${props.activeStep === 1 ? `${pulse} 1s infinite` : `none`};
            }
        `,
        payment: css`
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 2.25;
            padding: ${getSizePadding(props.size)};
            color: ${theme.palette.text.contrastPrimary};
            background-color: ${(props.status === 'paying' || props.status === 'paid' || props.status === 'failed' || props.moveCreated || props.status === 'canceled' || props.status === 'refunded')
                ? theme.palette.primary.main
                : theme.palette.primary.lighter};
            animation: ${props.activeStep === 2 ? `${pulse} 1s infinite` : `none`};
            ::before {
                content: '';
                position: absolute;
                top: 0px;
                left: ${getSizeContentOffset(props.size)};
                width: ${getSizeContentWidth(props.size)};
                height: 100%;
                transform: skewX(-24deg);
                background-color: ${(props.status === 'paying' || props.status === 'paid' || props.status === 'failed' || props.moveCreated || props.status === 'canceled' || props.status === 'refunded')
                    ? theme.palette.primary.main
                    : theme.palette.primary.lighter};
                animation: ${props.activeStep === 2 ? `${pulse} 1s infinite` : `none`};
            }
            ::after {
                content: '';
                position: absolute;
                top: 0px;
                right: ${getSizeContentOffset(props.size)};
                width: ${getSizeContentWidth(props.size)};
                height: 100%;
                transform: skewX(-24deg);
                background-color: ${(props.status === 'paying' || props.status === 'paid' || props.status === 'failed' || props.moveCreated || props.status === 'canceled' || props.status === 'refunded')
                    ? theme.palette.primary.main
                    : theme.palette.primary.lighter};
                animation: ${props.activeStep === 2 ? `${pulse} 1s infinite` : `none`};
            }
        `,
        confirmation: css`
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 2.75;
            padding: ${getSizePadding(props.size)};
            border-radius: 0px 64px 64px 0px;
            color: ${theme.palette.text.contrastPrimary};
            background-color: ${(props.moveCreated || props.status === 'canceled' || props.status === 'refunded')
                ? theme.palette.primary.main
                : theme.palette.primary.lighter};
            animation: ${props.activeStep === 3 ? `${pulse} 1s infinite` : `none`};
            ::before {
                content: '';
                position: absolute;
                top: 0px;
                left: ${getSizeContentOffset(props.size)};
                width: ${getSizeContentWidth(props.size)};
                height: 100%;
                transform: skewX(-24deg);
                background-color: ${(props.moveCreated || props.status === 'canceled' || props.status === 'refunded')
                    ? theme.palette.primary.main
                    : theme.palette.primary.lighter};
                animation: ${props.activeStep === 3 ? `${pulse} 1s infinite` : `none`};
            }
        `
    };
};

export default AppointmentStatusTracker;