// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { Action } from 'src/types';

import { css } from '@emotion/css';
import { Divider, IconButton, Menu, MenuItem, Theme, Tooltip, useTheme } from '@mui/material';

import { BiDotsVerticalRounded } from 'react-icons/bi';

// TYPES ---------------------------------------------------------------- //

interface IconActionProps {
  className?: string;
  actions?: Action[];
  tooltip?: string;
  tooltipPlacement?: 'bottom' | 'top' | 'left' | 'right';
  [key: string]: any;
}

const IconAction = ({
  className = undefined,
  actions = undefined,
  tooltip = undefined,
  tooltipPlacement = 'top',
  ...rest
}: IconActionProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);
  const [actionsRef, setActionsRef] = React.useState<null | HTMLElement>(null);

  const handleActionsOpen = (event: React.MouseEvent<HTMLElement>) => {
    setActionsRef(event.currentTarget);
  };

  const handleActionsClose = () => {
    setActionsRef(null);
  };

  const handleItemHandler = (action: Action) => {
    handleActionsClose();
    if (action?.handler) action?.handler();
  };

  const getClassNames = () => {
    return `${cls.iconBtn} ${className}`;
  };

  return (
    <>
      <Tooltip placement={tooltipPlacement} title={tooltip || 'Actions'}>
        <IconButton className={getClassNames()} onClick={handleActionsOpen} {...rest}>
          <BiDotsVerticalRounded size={24} />
        </IconButton>
      </Tooltip>

      {actions?.length ? (
        <Menu
          keepMounted
          id='actions-menu'
          anchorEl={actionsRef}
          open={Boolean(actionsRef)}
          onClose={handleActionsClose}
        >
          {actions?.map((action, i) =>
            !action?.label ? (
              <Divider key={`action-divider-${i}`} />
            ) : !action?.hide ? (
              <MenuItem
                key={`action-${i}`}
                disabled={action?.disabled}
                onClick={() => handleItemHandler(action)}
                sx={{ color: action?.color || `inherit` }}
              >
                {action?.icon || null}
                {action?.label || `Action ${i + 1}`}
              </MenuItem>
            ) : null
          )}
        </Menu>
      ) : null}
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  iconBtn: css`
    padding: ${theme?.spacing(1)};
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default IconAction;
