// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { Slas, Workflowsets, useGetWorkflowsetsLazyQuery } from '@gql/schema';

import { css } from '@emotion/css';
import { Theme, useTheme, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';

import { BiFile } from 'react-icons/bi';

// TYPES ---------------------------------------------------------------- //

interface WorkflowsetSelectProps {
  rooftopId?: number;
  slaOverride?: Slas;
  required?: boolean;
  error?: boolean;
  name?: string;
  label?: string;
  placeholder?: string;
  helperText?: string;
  defaultWorkflowsets?: Workflowsets[];
  value?: number;
  onChange?: (id: number) => void;
  onFetch?: (workflowsets?: Workflowsets[]) => void;
  queryOverride?: () => Promise<Workflowsets[]>;
  queryCall?: `never` | `onMount` | `onOpen`;

  [x: string]: any;
}

// COMPONENT ---------------------------------------------------------------- //

const WorkflowsetSelect = ({
  rooftopId,
  slaOverride,
  required,
  error,
  name = `workflowset-select`,
  label = ``,
  placeholder = `Select workflow...`,
  helperText = ``,
  defaultWorkflowsets = [],
  value = 0,
  onChange,
  onFetch,
  queryOverride,
  queryCall = `onMount`,
  ...rest
}: WorkflowsetSelectProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const [getWorkflowsets] = useGetWorkflowsetsLazyQuery();

  const [workflowsets, setWorkflowsets] = React.useState<Workflowsets[]>(defaultWorkflowsets || []);
  const [workflowsetId, setWorkflowsetId] = React.useState<number | null>(value || 0);

  const [internalError, setInternalError] = React.useState<boolean>(false);
  const [internalErrorText, setInternalErrorText] = React.useState<string>(``);

  const [loading, setLoading] = React.useState<boolean>(false);

  /** Fetch query and set state */
  const fetchWorkflowsetsAndSetState = async () => {
    setLoading(true);

    try {
      let res;
      if (queryOverride) res = await queryOverride();
      else res = await getWorkflowsets({ variables: { rooftopId: rooftopId || 0 } });

      const resWorkflowsets = res?.data?.workflowsets || [];
      const defaultWorkflowsetId = value || 0;

      setWorkflowsets(resWorkflowsets);
      setWorkflowsetId(defaultWorkflowsetId);

      if (onFetch) onFetch(resWorkflowsets);

      setInternalError(false);
      setInternalErrorText(``);
    } catch (err) {
      console.error(`Failed to fetch workflowsets:`, err);
      setInternalError(true);
      setInternalErrorText(`Failed to fetch workflowsets`);
    }

    setLoading(false);
  };

  /** Handle changing the value */
  const handleValueChange = (value?: number | null) => {
    const selectedWorkflowsetId = value || 0;
    setWorkflowsetId(selectedWorkflowsetId);
    if (onChange) onChange(selectedWorkflowsetId);
  };

  /** Handle opening the dropdown */
  const handleOpen = async () => {
    if (queryCall === `onOpen`) fetchWorkflowsetsAndSetState();
  };

  // Fetch on mount instead of on open
  React.useEffect(() => {
    if (queryCall === `onMount`) fetchWorkflowsetsAndSetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set the data when it comes in as a prop (only when there is a query override)
  React.useEffect(() => {
    setWorkflowsets(defaultWorkflowsets);
  }, [defaultWorkflowsets]);

  // Set the value when it comes in as a prop
  React.useEffect(() => {
    setWorkflowsetId(value);
  }, [value]);

  React.useEffect(() => {
    //If there is no SLA override, we need to fetch the current SLA for the workflowset from the raterulegroup
    console.log(`workflowsets`, workflowsets);
  }, [workflowsets, slaOverride]);

  const WorkflowsetMenuItem = (workflowset: Workflowsets) => {
    const sla = (slaOverride || workflowset?.raterulegroups?.[0]?.sla || {}) as Slas;
    return (
      <MenuItem key={`workflowset-select-${workflowset?.id}`} value={workflowset?.id} sx={{ display: `block` }}>
        <Typography variant='body2' sx={{ display: `block`, marginBottom: `2px` }}>
          {workflowset?.name || `No Workflow Name`}
        </Typography>

        <Typography
          variant='caption'
          sx={{
            display: `block`,
            whiteSpace: `wrap`,
            textWrap: `pretty`,
            marginBottom: `2px`,
            color: theme?.palette?.text?.secondary,
          }}
        >
          {workflowset?.description || `No description`}
        </Typography>

        {SLAinfo(sla, !!slaOverride)}
      </MenuItem>
    );
  };

  const SLAinfo = (sla: Slas, isOverride: boolean) => {
    const slaLabel = sla?.label || `Unknown SLA`;
    const defaultDuration = isOverride ? `1hr` : `48hrs`;
    const slaDuration = sla?.duration_hrs ? `${sla?.duration_hrs}${sla?.duration_hrs === 1 ? `hr` : `hrs`}` : defaultDuration;
    const slaTimestamp = isOverride ? `appointment time` : `ready by time`;
    const slaDescription = `SLA - ${slaLabel} (${slaDuration} from ${slaTimestamp})`;
    return (
      <Typography
        variant='caption'
        sx={{ display: `block`, whiteSpace: `wrap`, color: theme?.palette?.secondary?.main }}
      >
        {slaDescription}
      </Typography>
    );
  };

  return (
    <>
      {label ? (
        <label className={cls.workflowsetLabel} htmlFor={name}>
          {label}
          {required ? <span className={cls.workflowsetLabelRequired}> *</span> : null}
        </label>
      ) : null}

      <TextField
        fullWidth
        select
        required={required}
        error={internalError || error}
        name={name}
        placeholder={placeholder}
        helperText={internalErrorText || helperText}
        defaultValue={``}
        value={workflowsetId || value || 0}
        onChange={e => handleValueChange(parseInt(e?.target?.value))}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position='start'>
                <BiFile size={20} />
              </InputAdornment>
            ),
          },
          select: {
            onOpen: () => handleOpen(),
          },
        }}
        {...rest}
      >
        {workflowsets?.length ? (
          workflowsets?.map(w => WorkflowsetMenuItem(w))
        ) : (
          <MenuItem key={`workflowset-select-0`} value={0 || `0`} sx={{ color: theme?.palette?.text?.secondary }}>
            Select a workflow
          </MenuItem>
        )}
      </TextField>
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  const styles = {
    workflowsetLabel: css`
      display: block;
      margin-bottom: 2px;
      margin-left: 6px;
      font-size: 12px;
      font-weight: 600;
    `,
    workflowsetLabelRequired: css`
      color: ${theme?.palette?.error?.main};
    `,
  };

  return styles;
};

// EXPORT ---------------------------------------------------------------- //

export default WorkflowsetSelect;
