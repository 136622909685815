// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser, useRooftop } from '@store';
import { css } from '@emotion/css';
import { Theme, useTheme, Typography } from '@mui/material';
import {
  BiBorderAll,
  BiImport,
  BiSolidAddToQueue,
  BiSolidBadgeDollar,
  BiSolidCar,
  BiSolidDashboard,
  BiSolidMapPin,
  BiSolidReceipt,
  BiSolidUser,
  BiCalendar,
} from 'react-icons/bi';
import { FaClipboardCheck } from 'react-icons/fa6';

import NavItem from '@features/layout/components/NavItem';

import hopdriveLogo from '@assets/vectors/logo-banner-light.svg';
import hopdriveLogoCondensed from '@assets/vectors/rabbit.svg';

// TYPES ---------------------------------------------------------------- //

interface NavProps {
  condensed: boolean;
  handleNavToggle: () => void;
}

// COMPONENT ---------------------------------------------------------------- //

const Nav = ({ condensed, handleNavToggle }: NavProps) => {
  const theme = useTheme();
  const cls = useStyles(theme, { condensed });

  const location = useLocation();
  const navigate = useNavigate();

  const user = useUser();
  const rooftop = useRooftop();

  const [pathname, setPathname] = React.useState(location?.pathname);
  const [showAppointmentTab, setShowAppointmentTab] = React.useState(false);

  React.useEffect(() => {
    if (location?.pathname) setPathname(location?.pathname);
  }, [location]);

  const handleClickLogo = () => {
    navigate(`/dashboard`);
  };

  React.useEffect(() => {
    if (
      rooftop?.organization?.config?.appointments?.enabled &&
      rooftop?.organization?.config?.appointments?.enabled === true
    ) {
      setShowAppointmentTab(true);
    } else {
      setShowAppointmentTab(false);
    }
  }, [rooftop]);

  return (
    <>
      <nav className={cls.nav}>
        <div className={cls.navBody}>
          <div className={cls.navTitle}>
            <div className={cls.navTitleLogoBox}>
              <img className={cls.navTitleLogoImg1} alt='HopDrive Logo' src={hopdriveLogo} onClick={handleClickLogo} />
              <img
                className={cls.navTitleLogoImg2}
                alt='HopDrive Logo'
                src={hopdriveLogoCondensed}
                onClick={handleClickLogo}
              />
            </div>
          </div>

          <div className={cls.navList}>
            <Typography className={cls.navListTxt}>Operations</Typography>

            <NavItem
              condensed={condensed}
              label={`Dashboard`}
              icon={<BiSolidDashboard />}
              route={`/dashboard`}
              currentRoute={pathname}
            />

            <NavItem
              condensed={condensed}
              label={`Plan Moves`}
              icon={<BiSolidAddToQueue />}
              route={`/plan-moves`}
              currentRoute={pathname}
            />

            <NavItem
              condensed={condensed}
              label={`Import Moves`}
              icon={<BiImport />}
              route={`/import-moves`}
              currentRoute={pathname}
            />
          </div>

          <div className={cls.navDivider} />

          <div className={cls.navList}>
            <Typography className={cls.navListTxt}>Indexes</Typography>

            <NavItem
              condensed={condensed}
              label={`Moves`}
              icon={<BiSolidCar />}
              route={`/moves`}
              currentRoute={pathname}
            />

            <NavItem
              condensed={condensed}
              label={`Locations`}
              icon={<BiSolidMapPin />}
              route={`/locations`}
              currentRoute={pathname}
            />

            {user?.profile?.default_role === 'dealer-super-admin' ? (
              <NavItem
                condensed={condensed}
                label={`Users`}
                icon={<BiSolidUser />}
                route={`/users`}
                currentRoute={pathname}
              />
            ) : null}

            <NavItem
              condensed={condensed}
              label={`Organization View`}
              icon={<BiBorderAll />}
              route={`/organization-view`}
              currentRoute={pathname}
            />
          </div>

          <div className={cls.navDivider} />

          <div className={cls.navList}>
            <Typography className={cls.navListTxt}>Billing</Typography>

            <NavItem
              condensed={condensed}
              label={`Preferences`}
              icon={<BiSolidBadgeDollar />}
              route={`/billing-preferences`}
              currentRoute={pathname}
            />

            <NavItem
              condensed={condensed}
              label={`Invoices`}
              icon={<BiSolidReceipt />}
              route={`/invoices`}
              currentRoute={pathname}
            />
          </div>

          <div className={cls.navDivider} />

          <div className={cls.navList}>
            <Typography className={cls.navListTxt}>Subscriptions</Typography>

            <NavItem
              condensed={condensed}
              label={`Appraisals`}
              icon={<FaClipboardCheck />}
              iconSize={18}
              route={`/appraisals`}
              currentRoute={pathname}
            />

            {showAppointmentTab ? (
              <NavItem
                condensed={condensed}
                label={`Appointments`}
                icon={<BiCalendar />}
                iconSize={18}
                route={`/appointments`}
                currentRoute={pathname}
              />
            ) : null}
          </div>
        </div>

        <div className={cls.navFooter}>
          <div className={cls.navFooterFade} />

          <Typography className={cls.navFooterTxt}>
            {condensed ? `` : `HopDrive Portal `}
            <b className={cls.navFooterVersionTxt}>v{APP_VERSION}</b>
          </Typography>
        </div>
      </nav>

      <div
        className={cls.navOverlay}
        onClick={() => {
          if (handleNavToggle) handleNavToggle();
        }}
      />
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme, props?: { condensed?: boolean }) => {
  const styles = {
    nav: css`
      z-index: ${theme?.zIndex?.drawer};
      position: relative;
      min-width: ${props?.condensed ? `64px` : `240px`};
      max-width: ${props?.condensed ? `64px` : `240px`};
      height: 100%;
      background-color: ${theme?.palette?.nav?.sidebar};
      color: ${theme?.palette?.text?.contrastPrimary};
      transition: all 0.2s ease-in-out;
      ${theme?.breakpoints?.down('lg')} {
        position: fixed;
        display: ${props?.condensed ? `none` : `block`};
        top: 0;
        left: 0;
      }
    `,
    navOverlay: css`
      z-index: ${theme?.zIndex?.drawer - 1};
      position: fixed;
      display: none;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      ${theme?.breakpoints?.down('lg')} {
        display: ${props?.condensed ? `none` : `block`};
      }
    `,

    navBody: css`
      position: relative;
      overflow: auto;
      width: 100%;
      height: 100%;
      padding-bottom: 16px;
    `,

    navTitle: css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;
      padding: ${props?.condensed ? `0` : `0 16px`};
      border-bottom: 1px solid ${theme?.palette?.dividerContrast};
    `,
    navTitleLogoBox: css`
      margin-top: ${props?.condensed ? `2px` : `6px`};
    `,
    navTitleLogoImg1: css`
      display: ${props?.condensed ? `none` : `block`};
      width: 180px;
      max-height: 30px;
      cursor: pointer;
    `,
    navTitleLogoImg2: css`
      display: ${props?.condensed ? `block` : `none`};
      width: 48px;
      max-height: 48px;
      cursor: pointer;
    `,

    navList: css`
      position: relative;
      padding: 16px 6px;
      :last-child {
        margin-bottom: 0;
      }
    `,
    navListTxt: css`
      display: ${props?.condensed ? `none` : `block`};
      margin-bottom: 8px !important;
      margin-left: 10px !important;
      text-transform: uppercase;
      font-size: 10px !important;
      font-weight: 700 !important;
      color: ${theme?.palette?.text?.contrastDisabled};
    `,

    navDivider: css`
      height: 1px;
      background-color: ${theme?.palette?.dividerContrast};
    `,

    navFooter: css`
      position: absolute;
      white-space: nowrap;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 6px;
      background-color: ${theme?.palette?.nav?.sidebar};
      color: ${theme?.palette?.text?.contrastDisabled};
    `,
    navFooterFade: css`
      position: absolute;
      top: -16px;
      left: 0;
      width: 100%;
      height: 16px;
      background: linear-gradient(to bottom, transparent, ${theme?.palette?.nav?.sidebar});
    `,
    navFooterTxt: css`
      line-height: 10px !important;
      text-align: center;
      font-size: 10px !important;
      font-weight: 500 !important;
    `,
    navFooterVersionTxt: css`
      line-height: 10px !important;
      text-align: center;
      font-size: 10px !important;
      font-weight: 900 !important;
    `,
  };

  return styles;
};

// EXPORT ---------------------------------------------------------------- //

export default Nav;
