import { css } from '@emotion/css';
import { Theme } from '@mui/material';

interface Props {
  size?: number;
}

export default (theme?: Theme, props?: object) => ({
  spacerH: css`
    display: 'block';
    width: '100%';
    height: ${theme?.spacing(props?.size || 2)};
  `,
  spacerV: css`
    display: 'inline-block';
    width: ${theme?.spacing(props?.size || 2)};
  `,
});
