import React, { useState } from 'react';

import * as Sentry from '@sentry/react';
import { Arpaymentmethods, useUpdatePrimaryPaymentMethodMutation } from '@gql/schema';
import { css } from '@emotion/css';

import { SxProps, useTheme, Theme, Grid2 as Grid, Typography, Button, Paper } from '@mui/material';
import { FaMoneyCheck, FaQuestionCircle, FaCcVisa, FaCcMastercard, FaCcDiscover, FaCcAmex } from 'react-icons/fa';

import DefaultErrorFallback from '@components/Fallbacks/DefaultErrorFallback';
import MethodAddEditModal from '@features/billing/components/MethodAddEditModal';
import MethodRemoveModal from '@features/billing/components/MethodRemoveModal';
import { toast } from 'react-toastify';

interface MethodCardProps {
  customerId: number;
  method: Arpaymentmethods;
  refetch: () => void;
}

////////// COMPONENT //////////
const MethodCard: React.FC<MethodCardProps> = (props: MethodCardProps) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sxStyles = useSxStyles(theme);

  const { customerId, method, refetch } = props;

  const [editModal, setEditModal] = useState({ open: false, input: method || null });
  const [removeModal, setRemoveModal] = useState({ open: false, input: method || null });

  const [updatePrimaryPaymentMethod] = useUpdatePrimaryPaymentMethodMutation({
    variables: { customerId: method.customer_id, id: method.id },
  });

  // Function to dynamically create a new payment method card
  const createCard = (item: Arpaymentmethods) => {
    let type, cardType, num, exp, route, icon;

    if (item.type === `card`) {
      type = `Credit/Bank Card`;
      num = `${item.account_number}`.padStart(4, `0`);
      exp =
        `${item.expiration}`.padStart(4, `0`).substring(0, 2) +
        `/` +
        `${item.expiration}`.padStart(4, `0`).substring(2, 4);
      route = null;
      if (item.card_type === `visa`) {
        cardType = `Visa`;
        icon = <FaCcVisa className={styles.icon} color='#0050a0' />;
      } else if (item.card_type === `master`) {
        cardType = `Mastercard`;
        icon = <FaCcMastercard className={styles.icon} color='#ff0000' />;
      } else if (item.card_type === `discover`) {
        cardType = `Discover`;
        icon = <FaCcDiscover className={styles.icon} color='#ff7000' />;
      } else if (item.card_type === `amex`) {
        cardType = `American Express`;
        icon = <FaCcAmex className={styles.icon} color='#0080ff' />;
      } else {
        cardType = `Unknown Card`;
        icon = <FaQuestionCircle className={styles.icon} color='#888' />;
      }
    } else if (item.type === `ach`) {
      type = `ACH Check`;
      cardType = null;
      num = `${item.account_number}`.padStart(4, `0`);
      exp = null;
      route = `${item.routing_number}`.padStart(9, `0`);
      icon = <FaMoneyCheck className={styles.icon} color={theme.palette.text.primary} />;
    } else if (item.type === `echeck`) {
      type = `Electronic Check`;
      cardType = null;
      num = `${item.account_number}`.padStart(4, `0`);
      exp = null;
      route = `${item.routing_number}`.padStart(9, `0`);
      icon = <FaMoneyCheck className={styles.icon} color={theme.palette.text.primary} />;
    } else {
      type = `Unknown Type`;
      cardType = `N/A`;
      num = `N/A`;
      exp = `N/A`;
      route = `N/A`;
      icon = <FaQuestionCircle className={styles.icon} color={theme.palette.text.secondary} />;
    }

    const handleEditModalOpen = (input: Arpaymentmethods) => {
      setEditModal({ open: true, input: input });
    };
    const handleEditModalClose = () => {
      setEditModal({ open: false, input: null });
      refetch();
    };

    const removeButtonOnClick = () => {
      //Do not allow user to remove primary card
      if (method.primary) {
        toast.error(
          'Cannot Remove Primary Payment Method: to remove this payment method, you must first set a new primary payment method.'
        );
      } else {
        handleRemoveModalOpen(method);
      }
    };

    const handleRemoveModalOpen = (input: Arpaymentmethods | null = null) => {
      setRemoveModal({ ...removeModal, open: true, input: input });
    };
    const handleRemoveModalClose = () => {
      setRemoveModal({ ...removeModal, open: false });
      refetch();
    };

    const handlePrimary = async () => {
      try {
        await updatePrimaryPaymentMethod();
        refetch();
      } catch (err) {
        Sentry.captureException(err);
        toast.error('Query failed to change primary payment method');
      }
    };

    return (
      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING PAYMENT METHOD' />}>
        <MethodAddEditModal
          customerId={customerId}
          open={editModal.open}
          onClose={handleEditModalClose}
          methodInput={editModal.input}
          primaryExists={false}
        />
        <MethodRemoveModal
          open={removeModal.open}
          onClose={handleRemoveModalClose}
          methodInput={removeModal.input}
          refetch={refetch}
        />

        <Paper variant='custom' sx={{ padding: `18px` }}>
          <Grid container spacing={0}>
            {item.type === `card` ? (
              <>
                <div style={{ display: 'inline-block' }}>{icon}</div>
                <div style={{ display: 'inline-block' }}>
                  <Typography sx={sxStyles.txtOver} variant='h6'>
                    {item.name !== `` || item.name !== null ? item.name : `Payment`}
                  </Typography>
                  <Typography sx={sxStyles.txtUnder} color='textSecondary'>
                    {cardType} ****{num}
                  </Typography>
                  <Typography sx={sxStyles.txtUnder} color='textSecondary'>
                    Expires: {exp}
                  </Typography>
                </div>
              </>
            ) : (
              <>
                <div style={{ display: 'inline-block' }}>{icon}</div>
                <div style={{ display: 'inline-block' }}>
                  <Typography sx={sxStyles.txtOver} variant='h6'>
                    {item.name}
                  </Typography>
                  <Typography sx={sxStyles.txtUnder} color='textSecondary'>
                    {type} ****{num}
                  </Typography>
                  <Typography sx={sxStyles.txtUnder} color='textSecondary'>
                    Routing: {route}
                  </Typography>
                </div>
              </>
            )}
            <div style={{ width: '100%', height: '1px', margin: '24px 0', background: '#ddd' }} />
            <Grid size={{ xs: 12 }}>
              {item.primary ? (
                <Typography
                  color='textSecondary'
                  style={{ float: 'left', verticalAlign: 'top', fontSize: '18px', fontWeight: 500 }}
                >
                  Primary
                </Typography>
              ) : null}
              <Button
                sx={sxStyles.button}
                variant='contained'
                // color='default'
                style={{ background: '#ff2040', color: '#fff' }}
                onClick={() => removeButtonOnClick()}
              >
                Remove
              </Button>
              <Button
                sx={sxStyles.button}
                variant='contained'
                color='primary'
                onClick={() => handleEditModalOpen(method)}
              >
                Edit
              </Button>
              {!item.primary ? (
                <Button sx={sxStyles.button} variant='contained' color='secondary' onClick={() => handlePrimary()}>
                  Make Primary
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      </Sentry.ErrorBoundary>
    );
  };

  return <>{createCard(method)}</>;
};

export default MethodCard;

////////// STYLES //////////
const useStyles = (theme: Theme) => {
  return {
    icon: css`
      font-size: 64px;
      margin-right: ${theme.spacing(3)};
    `,
  };
};

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  button: {
    float: 'right',
    marginLeft: theme.spacing(1),
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  txtOver: {
    fontSize: '18px',
  },
  txtUnder: {
    fontSize: '14px',
    lineHeight: '16px',
  },
});
