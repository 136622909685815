import React from 'react';
import { css } from '@emotion/css';
import dayjs from 'dayjs';

import { Grid2 as Grid, TextField, MenuItem, useTheme, Theme, SxProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

interface AppraisalsDateFilterProps {
  range: string;
  start: string;
  end: string;
  onRangeChange: (value: string) => void;
  onDateChange: (event: any, name: string) => void;
  disablePickers: boolean;
}

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
const AppraisalsDateFilter: React.FC<AppraisalsDateFilterProps> = (props: AppraisalsDateFilterProps) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sxStyles = useSxStyles();

  const { range, start, end, onRangeChange, onDateChange, disablePickers } = props;

  const handleRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onRangeChange(event.target.value);
  };

  const handleDateChange = (name: string) => (value: any) => {
    onDateChange(value, name);
  };

  return (
    <>
      <div className={styles.main}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 6 }}>
            <TextField
              select
              fullWidth
              label='Quarterly Range'
              variant='outlined'
              margin='none'
              value={range}
              onChange={handleRangeChange}
            >
              <MenuItem value={`this`}>This Quarter (Last 3 Months)</MenuItem>
              <MenuItem value={`last`}>Last Quarter (3-6 Months Ago)</MenuItem>
              <MenuItem value={`custom`}>Custom (Max 3-Month Quarter)</MenuItem>
            </TextField>
          </Grid>

          <Grid size={{ xs: 6, md: 3 }}>
            <DatePicker
              disabled={disablePickers}
              orientation='portrait'
              format='MM/DD/YYYY'
              label='Start Date (From)'
              value={dayjs(start)}
              onChange={handleDateChange('start')}
              sx={sxStyles.date}
            />
          </Grid>

          <Grid size={{ xs: 6, md: 3 }}>
            <DatePicker
              disabled={disablePickers}
              orientation='portrait'
              format='MM/DD/YYYY'
              label='End Date (To)'
              value={dayjs(end)}
              onChange={handleDateChange('end')}
              sx={sxStyles.date}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AppraisalsDateFilter;

//////////////////////////////////////// STYLES ////////////////////////////////////////
const useStyles = (theme: Theme) => {
  return {
    main: css`
      margin-bottom: ${theme.spacing(2)};
    `,
  };
};

const useSxStyles = (): Record<string, SxProps<Theme> | undefined> => ({
  date: {
    width: '100%',
  },
});
