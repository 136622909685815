// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Action } from 'src/types';
import { Moves } from '@gql/schema';

import { createSingleMoveInvoice } from '@services/pdfService';

import { css } from '@emotion/css';
import { Theme, useTheme, IconButton, Tooltip, Typography } from '@mui/material';
import {
  BiAddToQueue,
  BiArrowBack,
  BiBlock,
  BiReceipt,
  BiSolidEdit,
  BiSolidSave,
  BiTime,
  BiTransfer,
  BiUndo,
} from 'react-icons/bi';

import IconAction from '@components/Utils/IconAction';

import MoveDetailsToPrint from './MoveDetailsToPrint';
import MoveTimeModal from '@features/moves/components/MoveTimeModal';
import MoveCancelModal from '@features/moves/components/MoveCancelModal';

// TYPES ---------------------------------------------------------------- //

interface MoveDetailsToolbarProps {
  move?: Moves;
  isEditing?: boolean;
  onEdit?: (isEditing: boolean) => void;
  onSave?: () => void;
  onDiscard?: () => void;
}

interface MovePlannerStateProps {
  inverse?: boolean;
  moveId1?: number;
  moveId2?: number;
}

// COMPONENT ---------------------------------------------------------------- //

const MoveDetailsToolbar = ({ move, isEditing, onEdit, onSave, onDiscard }: MoveDetailsToolbarProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);
  const navigate = useNavigate();

  const [timeModal, setTimeModal] = React.useState({ open: false, input: move });
  const [cancelModal, setCancelModal] = React.useState({ open: false, input: move });

  // Time Modal
  const handleTimeModalOpen = () => {
    setTimeModal({ ...timeModal, open: true });
  };
  const handleTimeModalClose = () => {
    setTimeModal({ ...timeModal, open: false });
  };

  // Cancel Modal
  const handleCancelModalOpen = () => {
    setCancelModal({ ...cancelModal, open: true });
  };
  const handleCancelModalClose = () => {
    setCancelModal({ ...cancelModal, open: false });
  };

  // Actions
  const handleCreateDuplicate = () => {
    const parentDrive = move?.parentMove;
    const childDrive = move?.childMoves?.find(cm => cm?.move_type === `drive`);

    const moveId1 = parentDrive?.id || move?.id;
    const moveId2 = childDrive?.id || move?.id;

    const state: MovePlannerStateProps = { moveId1, inverse: false };
    if (moveId2 && moveId2 !== moveId1) state.moveId2 = moveId2;

    navigate(`/plan-moves`, { state });
  };
  const handleCreateInverse = () => {
    const parentDrive = move?.parentMove;
    const childDrive = move?.childMoves?.find(cm => cm?.move_type === `drive`);

    const moveId1 = parentDrive?.id || move?.id;
    const moveId2 = childDrive?.id || move?.id;

    const state: MovePlannerStateProps = { moveId1, inverse: true };
    if (moveId2 && moveId2 !== moveId1) state.moveId2 = moveId2;

    navigate(`/plan-moves`, { state });
  };

  const handleDownloadSingleMoveInvoice = (move?: Moves) => {
    if (!move) return;

    let invoice = null;
    try {
      invoice = move?.accountsReceivable?.invoice;
    } catch (err) {
      console.error(`Failed to parse move invoice:`, err);
    }
    if (invoice) createSingleMoveInvoice(invoice);
    else toast.warn(`Could not generate invoice PDF. Please refer to the Invoices page for this move.`);
  };

  /** Handle saving changes */
  const handleSave = () => {
    if (onSave) onSave();
  };

  /** Handle editing/discarding changes */
  const handleEdit = () => {
    if (isEditing && onDiscard) onDiscard();
    if (onEdit) onEdit(!isEditing);
  };

  /** Disable the invoice button if the move is fully canceled or paid */
  const disableMoveInvoiceButton = (move?: Moves) => {
    if (!move) return false;

    const moveIsFullyCanceled = move?.cancel_status !== null && move?.cancel_status !== `pending`;
    const moveIsPaid =
      move?.accountsReceivable && move?.accountsReceivable?.status && move?.accountsReceivable?.status === `paid`;
    if (move?.status === `delivery successful` || moveIsPaid || moveIsFullyCanceled) return false;
    return true;
  };

  // List of move actions
  const actions: Action[] = [
    {
      label: `Create Duplicate`,
      icon: <BiAddToQueue className={cls.menuIcon} />,
      handler: () => handleCreateDuplicate(),
    },
    {
      label: `Create Inverse`,
      icon: <BiTransfer className={cls.menuIcon} />,
      handler: () => handleCreateInverse(),
    },
    {},
    {
      label: `Change Start Time`,
      icon: <BiTime className={cls.menuIcon} />,
      handler: () => handleTimeModalOpen(),
      disabled: move?.status !== null,
    },
    {},
    {
      label: `Download Invoice PDF`,
      icon: <BiReceipt className={cls.menuIcon} />,
      handler: () => handleDownloadSingleMoveInvoice(move),
      disabled: disableMoveInvoiceButton(move),
    },
    {},
    {
      label: `Cancel Move`,
      icon: <BiBlock className={cls.menuIcon} />,
      color: theme?.palette?.error?.main,
      handler: () => handleCancelModalOpen(),
      disabled:
        move?.cancel_status !== null || move?.status === `delivery arrived` || move?.status === `delivery successful`,
    },
  ];

  return (
    <>
      <MoveTimeModal open={timeModal.open} onClose={handleTimeModalClose} moveInput={timeModal.input} />
      <MoveCancelModal open={cancelModal.open} onClose={handleCancelModalClose} moveInput={cancelModal.input} />

      <div className={cls.flex}>
        <div className={cls.flexItem}>
          <Tooltip placement='top' title={`Return to previous page`}>
            <IconButton onClick={() => navigate(-1)} sx={{ padding: `6px` }}>
              <BiArrowBack size={24} />
            </IconButton>
          </Tooltip>
        </div>

        <div className={cls.flexItem}>
          <Typography variant='h3' sx={{ fontWeight: 600 }}>
            Move Details
          </Typography>
        </div>

        <div className={cls.flexItemStretch} />

        {move ? (
          <>
            <div className={cls.flexItemButtons}>
              <MoveDetailsToPrint move={move} />
            </div>

            <div className={cls.flexItem}>
              {isEditing ? (
                <Tooltip placement='top' title={`Save your changes`}>
                  <IconButton
                    onClick={() => handleSave()}
                    sx={{
                      padding: `6px`,
                      color: theme?.palette?.secondary?.main,
                    }}
                  >
                    <BiSolidSave size={24} />
                  </IconButton>
                </Tooltip>
              ) : null}

              <Tooltip placement='top' title={isEditing ? `Discard your changes` : `Make changes to this move`}>
                <IconButton
                  onClick={() => handleEdit()}
                  sx={{
                    padding: `6px`,
                    color: isEditing ? theme?.palette?.utility?.main : theme?.palette?.action?.active,
                  }}
                >
                  {isEditing ? <BiUndo size={24} /> : <BiSolidEdit size={24} />}
                </IconButton>
              </Tooltip>

              <Tooltip placement='top' title={`Open a list of move actions`}>
                <IconAction actions={actions} />
              </Tooltip>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  flex: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 24px;
  `,
  flexItem: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  flexItemButtons: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    ${theme?.breakpoints?.down('sm')} {
      display: none;
    }
  `,
  flexItemStretch: css`
    flex: 1;
  `,

  menuIcon: css`
    display: block;
    margin-top: -1px;
    margin-right: 8px;
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default MoveDetailsToolbar;
