// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';
import * as Sentry from '@sentry/react';

import DefaultErrorFallback from '@components/Fallbacks/DefaultErrorFallback';

import { DashboardProvider } from '../providers/DashboardProvider';
import DashboardContent from '@features/dashboard/components/DashboardContent';

// COMPONENT ---------------------------------------------------------------- //

const DashboardPage = () => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => (
        <DefaultErrorFallback
          message='Unexpected error caught at DashboardPage.tsx'
          error={error}
          resetError={resetError}
        />
      )}
    >
      <DashboardProvider>
        <DashboardContent />
      </DashboardProvider>
    </Sentry.ErrorBoundary>
  );
};

// EXPORT ---------------------------------------------------------------- //

export default DashboardPage;
