import { useNavigate } from 'react-router-dom';
import Table from '@components/TableComponents/Table';
import Column from '@components/TableComponents/Table';
import { Theme, useTheme, Tooltip, Icon } from '@mui/material';
import { Locations, useUpdateLocationFavoriteMutation } from '@gql/schema';
import { css } from '@emotion/css';
import { toast } from 'react-toastify';
import { BiBookmarkHeart, BiSolidMapPin } from 'react-icons/bi';

const defaultOrder = `asc`;
const defaultOrderBy = `name`;

const log = true;

interface LocationsTableProps {
  locations: Locations[];
}

const LocationsTable: React.FC<LocationsTableProps> = ({ locations }) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const navigate = useNavigate();

  const [updateLocationFavorite] = useUpdateLocationFavoriteMutation();

  const getName = (loc: any) => {
    if (loc.nickname) return loc.nickname;
    if (loc.name) return loc.name;
    return `Unknown Location`;
  };

  const handleLocationFavorite = async (loc: any) => {
    if (loc) {
      try {
        const res = await updateLocationFavorite({
          variables: { id: loc.id, fav: !loc.favorite },
        });
        if (res) log && console.log(`>> UPDATED Favorite Location:`, res?.data?.update_locations);
      } catch (err) {
        log && console.log(`Error updating location:`, err);
        toast.error(`Failed to set location as favorite`);
      }
    } else log && console.log(`Error: Cannot set favorite of an empty location!`);
  };

  const handleNavigateClick = (locationData: Locations) => {
    if (locationData) {
      navigate(`/locations/${locationData?.id}`);
    }
  };

  const handleFavoriteClick = (locationData: Locations) => {
    if (locationData) {
      handleLocationFavorite(locationData);
    }
  };

  const rowActions = [
    {
      name: 'location-details',
      label: 'Go To Location Details',
      icon: <BiSolidMapPin className={cls.menuIcon} />,
      handler: handleNavigateClick,
    },
    {},
    {
      name: 'favorite-location',
      label: 'Favorite/Unfavorite',
      icon: <BiBookmarkHeart className={cls.menuIcon} />,
      color: theme?.palette?.error?.main,
      handler: handleFavoriteClick,
    },
  ];

  return (
    <Table
      data={locations}
      defaultOrderBy={defaultOrderBy}
      defaultOrder={defaultOrder}
      tableAriaLabel='locations'
      stickyHeader={true}
      rowActions={rowActions}
      onRowClick={(data: any) => navigate(`/locations/${(data as Locations)?.id}`)}
    >
      <Column
        name='name'
        value={(location: Locations) => getName(location) || '-'}
        label='Name'
        style={{ minWidth: 120 }}
      />
      <Column
        name='address'
        value={(location: Locations) => location?.address || '-'}
        label='Address'
        style={{ minWidth: 100 }}
      />
      <Column
        name='email'
        value={(location: Locations) => location?.email || '-'}
        label='Email'
        style={{ minWidth: 160 }}
      />
      <Column
        name='phone'
        value={(location: Location) => location?.phone || '-'}
        label='Phone'
        style={{ minWidth: 140 }}
      />
      <Column
        name='favorite'
        value={(location: Locations) => (
          <Tooltip placement='top' title={location.favorite ? 'Unfavorite Location' : 'Favorite Location'}>
            <Icon
              className={location.favorite ? cls.heartActive : cls.heart}
              onClick={e => {
                e.stopPropagation();
                handleLocationFavorite(location);
              }}
            >
              {location.favorite ? 'favorite' : 'favorite_border'}
            </Icon>
          </Tooltip>
        )}
        label='Favorite'
        style={{ minWidth: 160 }}
        align='center'
      />
    </Table>
  );
};

const useStyles = (theme: Theme) => ({
  menuIcon: css`
    display: block;
    margin-top: -1px;
    margin-right: 8px;
  `,

  heart: css`
    margin-top: ${theme?.spacing(0.5)};
    color: ${theme?.palette.text.secondary};

    &:hover {
      color: ${theme?.palette.error.main};
    }

    transition: 0.2s;
    cursor: pointer;
  `,
  heartActive: css`
    margin-top: ${theme?.spacing(0.5)};
    color: ${theme?.palette.error.main};

    &:hover {
      color: ${theme?.palette.error.light};
    }

    transition: 0.2s;
    cursor: pointer;
  `,
});

export default LocationsTable;
