import React from 'react';
import { DialogActions } from '@mui/material';
import { useTheme, Theme, SxProps } from '@mui/material/styles';

interface ModalFooterProps {
  children: React.ReactNode;
}

const ModalFooter: React.FC<ModalFooterProps> = ({ children }) => {
  const theme: Theme = useTheme();
  const sxStyles = useSxStyles(theme);

  return <DialogActions sx={sxStyles.padding}>{children}</DialogActions>;
};

export default ModalFooter;

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  padding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: 0,
    background: `#f0f0f0`,
  },
});
