//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { TableBody } from '@mui/material';
import { getComparator, stableSort } from '@services/sortService';

import UploadedTableRow from '@features/moveImport/components/UploadedTableRow';

interface UploadedTableBodyProps {
    rows: any[];
    errors: any[];
    order: "asc" | "desc";
    orderBy: string;
    page: number;
    rowsPerPage: number;
}

//////////////////////// COMPONENT ////////////////////////

const UploadedTableBody: React.FC<UploadedTableBodyProps> = props => {
  const { rows, errors, order, orderBy, page, rowsPerPage } = props;
  const getErrors = (index: number) => {
    const rowErrors = errors.filter(err => err.index === index);
    return rowErrors;
  };

  return (
    <TableBody>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row: any, i: number) => {
          return (
            <React.Fragment key={`uploaded-row-${i}`}>
              <UploadedTableRow row={row} errors={getErrors(i)} index={i} />
            </React.Fragment>
          );
        })}
    </TableBody>
  );
};
export default UploadedTableBody;