// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';

import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';

// TYPES ---------------------------------------------------------------- //

type InvoiceDetailsTableHeadProps = {
  headers: any[];
  order: string;
  orderBy: string;
  setOrder: any;
  setOrderBy: any;
};

// COMPONENT ---------------------------------------------------------------- //

const InvoiceDetailsTableHead = ({ headers, order, orderBy, setOrder, setOrderBy }: InvoiceDetailsTableHeadProps) => {
  const handleChangeSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableHead>
      <TableRow>
        {headers.map(header => {
          if (!header.hide)
            return (
              <TableCell
                key={header.id}
                align={header.align}
                padding='normal'
                sortDirection={orderBy === header.id ? order : false}
              >
                {header.component || (
                  <TableSortLabel
                    style={{ fontSize: `12px`, fontWeight: 600 }}
                    active={orderBy === header.id}
                    direction={orderBy === header.id ? order : 'asc'}
                    onClick={() => handleChangeSort(header.id)}
                  >
                    {header.label}
                  </TableSortLabel>
                )}
              </TableCell>
            );
          else return null;
        })}
      </TableRow>
    </TableHead>
  );
};

// EXPORT ---------------------------------------------------------------- //

export default InvoiceDetailsTableHead;
