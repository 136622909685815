// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { css } from '@emotion/css';
import { Theme, useTheme } from '@mui/material';

// TYPES ---------------------------------------------------------------- //

interface FeatureProps {
  children: React.ReactNode;
}

// COMPONENT ---------------------------------------------------------------- //

const Feature = ({ children }: FeatureProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  return <main className={cls.feature}>{children}</main>;
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  const styles = {
    feature: css`
      position: relative;
      width: 100%;
      height: 100%;
      background-color: ${theme?.palette?.background?.default};
    `,
  };

  return styles;
};

// EXPORT ---------------------------------------------------------------- //

export default Feature;
