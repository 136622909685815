// DEPENDENCIES ---------------------------------------------------------------- //

import { css, cx, keyframes } from '@emotion/css';
import { Typography, SxProps, Theme, useTheme } from '@mui/material';
import { Hangtags } from '@gql/schema';

import MoveTileHangtagTooltip from '@components/MoveTileHangtagTooltip';

// TYPES ---------------------------------------------------------------- //

interface MoveTileHangtagProps {
  hangtag: Hangtags;
}

// COMPONENT ---------------------------------------------------------------- //

export default function MoveTileHangtag({ hangtag }: MoveTileHangtagProps) {
  const theme = useTheme();
  const cls = useStyles(theme);
  const sxCls = useSxStyles(theme);

  const getHangtagStyle = () => {
    const styles = [cls.hangtag];
    if (hangtag?.status === `assigned` || hangtag?.status === `scanned`) styles.push(cls.hangtagNoWiggle);
    if (hangtag?.status === `completed`) styles.push(cls.hangtagWiggle);
    return cx(styles);
  };

  const getHangtagTextStyle = () => {
    if (hangtag?.rear_code?.length >= 3) return sxCls.hangtagTxt3;
    if (hangtag?.rear_code?.length === 2) return sxCls.hangtagTxt2;
    if (hangtag?.rear_code?.length === 1) return sxCls.hangtagTxt1;
  };

  return (
    <MoveTileHangtagTooltip hangtag={hangtag}>
      <div className={getHangtagStyle()}>
        <div className={cls.hangtagPunch} />
        <Typography sx={getHangtagTextStyle()}>{hangtag?.rear_code}</Typography>
      </div>
    </MoveTileHangtagTooltip>
  );
}

//////////////////////// STYLES ////////////////////////

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  hangtagTxt1: {
    marginTop: '6px',
    fontSize: '16px',
    fontWeight: 900,
    color: theme.palette.text.contrastPrimary,
  },
  hangtagTxt2: {
    marginTop: '6px',
    fontSize: '14px',
    fontWeight: 900,
    color: theme.palette.text.contrastPrimary,
  },
  hangtagTxt3: {
    marginTop: '6px',
    fontSize: '12px',
    fontWeight: 900,
    color: theme.palette.text.contrastPrimary,
  },
});

const useStyles = (theme: Theme) => {
  const wiggle = keyframes`
    0% {
      transform: translateX(-50%);
    }
    35% {
      transform: translateX(-50%) rotate(0deg);
    }
    40% {
      transform: translateX(-50%) rotate(8deg);
    }
    47.5% {
      transform: translateX(-50%) rotate(-6deg);
    }
    52.5% {
      transform: translateX(-50%) rotate(4deg);
    }
    60% {
      transform: translateX(-50%) rotate(-2deg);
    }
    65% {
      transform: translateX(-50%) rotate(0deg);
    }
    100% {
      transform: translateX(-50%);
    }
  `;

  return {
    hangtag: css`
      z-index: 276;
      position: absolute;
      overflow: hidden;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      top: -8px;
      left: 50%;
      transform-origin: 50% 20%;
      width: 28px;
      height: 40px;
      border-radius: 3px;
      background: ${theme?.palette?.primary?.main};
      box-shadow: 0 0 2px #00000080;
      cursor: pointer;
    `,
    hangtagNoWiggle: css`
      animation: none;
    `,
    hangtagWiggle: css`
      animation: ${wiggle} 5s ease-in-out infinite;
    `,

    hangtagPunch: css`
      position: absolute;
      top: 18%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 7px;
      height: 7px;
      border-radius: 7px;
      background: ${theme?.palette?.primary?.darker};
    `,

    ttBox: css`
      display: flex;
      flex-direction: column;
      width: 192px;
    `,
    ttDivider: css`
      width: 100%;
      opacity: 0.5;
    `,
  };
};
