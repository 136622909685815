import { toast } from 'react-toastify';
import { generatePDF as generatePDFService } from '@features/invoices/services/pdfService';
import { GetInvoiceDetailsQuery } from '@gql/schema';

export default function usePdfGeneration() {
  const generatePDF = async (invoice?: GetInvoiceDetailsQuery['arinvoices'][number], contacts?: any) => {
    try {
      await generatePDFService(invoice, contacts);
      toast.info('Generated PDF!', { autoClose: 2000, toastId: `generate-pdf` });
    } catch (err) {
      toast.error('Failed to generate PDF!', { toastId: `generate-pdf-error` });
      console.error('Failed to generate PDF:', err);
    }
  };

  return {
    generatePDF,
  };
}
