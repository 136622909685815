import { DocumentNode } from '@apollo/client';
import { authService } from '@features/auth/services/authService';
import { config } from '@config';

interface GqlParams {
  query: DocumentNode | string;
  variables: Record<string, unknown>;
  clientName?: string;
  endpoint?: string;
  options?: RequestInit;
}

export class GraphqlError extends Error {
  constructor(error: Error) {
    super(error?.message); // Pass the error message to the parent class
    this.name = 'GraphqlError';
  }
}

async function fetchWithTimeout(resource: string, options: RequestInit = {}): Promise<Response> {
  const { timeout = 30000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
}

const execGraphqlWithFetch = async <T>({ query, variables, clientName, endpoint, options }: GqlParams): Promise<T> => {
  if (!endpoint && config.graphql.httpUrl) endpoint = config.graphql.httpUrl;
  if (!clientName && config.app.fullName) clientName = config.app.fullName;
  if (!options) options = { cache: 'no-store' };
  if (!query) throw new Error('Missing query');
  if (!endpoint) throw new Error('Missing endpoint');

  const token = await authService.getAccessToken();
  const queryString = typeof query === 'string' ? query : query?.loc?.source?.body;

  // console.log('GQL query:', {
  //   query,
  //   queryString,
  //   variables,
  //   client: clientName,
  //   endpoint,
  //   options,
  // });

  const res = await fetchWithTimeout(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'hasura-client-name': clientName!,
    } as HeadersInit,
    body: JSON.stringify({
      query: queryString,
      variables,
    }),
    ...options,
  });

  const json = await res.json();

  if (json.error) {
    const gqlError = new GraphqlError(json.error);
    console.error(`GraphQL query or mutation failed:`, gqlError, {
      error: json.error,
      query,
      variables,
      token,
    });
    // Sentry.Native.addBreadcrumb({
    //   category: 'graphql',
    //   message: 'GraphQL query or mutation failed',
    //   data: {
    //     query,
    //     variables,
    //     client,
    //     endpoint,
    //     options,
    //     error: json?.error,
    //   },
    // });
    // Sentry.Native.captureException(gqlError, scope => {
    //   scope.setTransactionName(`GraphQL query or mutation failed`);
    //   scope.setTags({
    //     ...variables,
    //     client,
    //     endpoint,
    //     ...options,
    //   });
    //   return scope;
    // });
  }

  if (json.errors) {
    const gqlError = new GraphqlError(json.errors[0]);
    console.error(`GraphQL query or mutation failed:`, gqlError, {
      errors: json.errors,
      query,
      variables,
      token,
    });
    // Sentry.Native.addBreadcrumb({
    //   category: 'graphql',
    //   message: 'GraphQL query or mutation failed',
    //   data: {
    //     query,
    //     variables,
    //     client,
    //     endpoint,
    //     options,
    //     errors: json?.errors,
    //   },
    // });
    // Sentry.Native.captureException(gqlError, scope => {
    //   scope.setTransactionName(`GraphQL query or mutation failed`);
    //   scope.setTags({
    //     ...variables,
    //     client,
    //     endpoint,
    //     ...options,
    //   });
    //   return scope;
    // });
  }

  if (typeof query === 'string') return json;

  // Try to return just the data object if possible
  const rootResponseKey = (query as DocumentNode).definitions[0]?.selectionSet?.selections[0]?.name?.value;
  const rootResponse = json?.data[rootResponseKey];
  //console.log('Root response key:', { rootResponseKey, rootResponse });
  if (json?.data && json?.data[rootResponseKey]) return rootResponse;

  console.log(`Returning base JSON response:`, json);
  return json;
};

export { execGraphqlWithFetch };
export default execGraphqlWithFetch;
