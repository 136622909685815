// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { Vehiclemodels, useGetVehiclemodelsByVehiclemakeLazyQuery } from '@gql/schema';

import { css, cx } from '@emotion/css';
import { Theme, useTheme, Autocomplete, InputAdornment, TextField } from '@mui/material';

import { BiPurchaseTag } from 'react-icons/bi';

// TYPES ---------------------------------------------------------------- //

interface ModelSelectProps {
  make?: string | null;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  name?: string;
  label?: string;
  placeholder?: string;
  helperText?: string;
  value?: string | null;
  onChange?: (value?: string | null) => void;
  onInputChange?: (input?: string | null) => void;
  onFetch?: (models?: Vehiclemodels[]) => void;
  queryCall?: `never` | `onMount` | `onOpen`;

  [x: string]: any;
}

// COMPONENT ---------------------------------------------------------------- //

const ModelSelect = ({
  make,
  required,
  disabled,
  error,
  name = `model-select`,
  label = ``,
  placeholder = `Enter model...`,
  helperText = ``,
  value = null,
  onChange,
  onInputChange,
  onFetch,
  queryCall = `onOpen`,
  ...rest
}: ModelSelectProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const [getVehiclemodels] = useGetVehiclemodelsByVehiclemakeLazyQuery();

  const [models, setModels] = React.useState<Vehiclemodels[]>([]);
  const [model, setModel] = React.useState<string | null>(value || null);

  const [internalError, setInternalError] = React.useState<boolean>(false);
  const [internalErrorText, setInternalErrorText] = React.useState<string>(``);

  const [loading, setLoading] = React.useState<boolean>(false);

  /** Fetch query and set state */
  const fetchModelsAndSetState = async () => {
    setLoading(true);

    try {
      const res = await getVehiclemodels({ variables: { make: make } });

      const resModels = res?.data?.vehiclemodels || [];
      const defaultModel = value || null;

      setModels(resModels);
      setModel(defaultModel);

      if (onFetch) onFetch(resModels);

      setInternalError(false);
      setInternalErrorText(``);
    } catch (err) {
      console.error(`Failed to fetch vehicle models:`, err);
      setInternalError(true);
      setInternalErrorText(`Failed to fetch vehicle models`);
    }

    setLoading(false);
  };

  /** Handle changing the value */
  const handleValueChange = (value?: string | null) => {
    const selectedValue = value || null;
    setModel(selectedValue);
    if (onChange) onChange(selectedValue);
  };

  /** Handle changing the input */
  const handleInputChange = (input?: string | null) => {
    const editedInput = input || null;
    if (onInputChange) onInputChange(editedInput);
  };

  /** Handle opening the dropdown */
  const handleOpen = async () => {
    if (queryCall === `onOpen`) fetchModelsAndSetState();
  };

  // Fetch on mount instead of on open
  React.useEffect(() => {
    if (queryCall === `onMount`) fetchModelsAndSetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set the value when it comes in as a prop
  React.useEffect(() => {
    setModel(value);
  }, [value]);

  // Reset the models when the make changes
  React.useEffect(() => {
    setModels([]);
  }, [make]);

  return (
    <>
      {label ? (
        <label className={cls.modelLabel} htmlFor={name}>
          {label}
          {required ? <span className={cls.modelLabelRequired}> *</span> : null}
        </label>
      ) : null}

      <Autocomplete
        {...rest}
        fullWidth
        freeSolo
        includeInputInList
        disabled={disabled}
        loading={loading}
        loadingText={`Loading models...`}
        noOptionsText={`No models found`}
        options={models?.map(m => m?.name) || []}
        value={model || null}
        onChange={(_, value) => handleValueChange(value)}
        onInputChange={(_, input) => handleInputChange(input)}
        onOpen={() => handleOpen()}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            required={required}
            error={internalError || error}
            name={name}
            placeholder={placeholder}
            helperText={internalErrorText || helperText}
            slotProps={{
              htmlInput: {
                ...params?.inputProps,
                maxLength: 36,
              },
              input: {
                ...params?.InputProps,
                startAdornment: (
                  <InputAdornment position='start' sx={{ marginLeft: `8px`, marginRight: 0 }}>
                    <BiPurchaseTag className={cx(cls.modelIcon, disabled ? cls.modelIconDisabled : null)} />
                  </InputAdornment>
                ),
              },
            }}
          />
        )}
      />
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  const styles = {
    modelLabel: css`
      display: block;
      margin-bottom: 2px;
      margin-left: 6px;
      font-size: 12px;
      font-weight: 600;
    `,
    modelLabelRequired: css`
      color: ${theme?.palette?.error?.main};
    `,
    modelIcon: css`
      display: block;
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      color: ${theme?.palette?.action?.active};
    `,
    modelIconDisabled: css`
      color: ${theme?.palette?.action?.disabled};
    `,
  };

  return styles;
};

// EXPORT ---------------------------------------------------------------- //

export default ModelSelect;
