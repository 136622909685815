import ReusableMultiselect from '@components/Selectors/ReusableMultiselect';
import { css } from '@emotion/css';
import { Grid2 as Grid, TextField, MenuItem, SxProps, Tooltip, IconButton, Icon, Theme, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getSelectionsArray } from '@services/utilityService';
import { ExportToCsv } from 'export-to-csv';
import { useTools } from '@hooks/useTools';
import { Moves } from '@gql/schema';
import { toast } from 'react-toastify';
import { getFormattedCombinedStatusFromMove } from '@services/statusService';
import { formatTime } from '@services/formatService';
import { capFirst } from '@services/formatService';
import IconAction from '@components/Utils/IconAction';
import { BiFile } from 'react-icons/bi';

interface MovesFilterProps {
  refetch: (manual: boolean) => void;
  range: string;
  start: string;
  end: string;
  onRangeChange: (value: string) => void;
  onDateChange: (value: string, name: string) => void;
  disablePickers: boolean;
  search: string;
  setSearch: (value: string) => void;
  selectedUsersArray: string[];
  setSelectedUsersArray: React.Dispatch<React.SetStateAction<string[]>>;
  userOptions: string[];
  selectedWorkflowsArray: string[];
  setSelectedWorkflowsArray: React.Dispatch<React.SetStateAction<string[]>>;
  workflowOptions: string[];
  moves: Moves[];
}
interface FilterItems {
  id: string | null | undefined;
  name: string | null | undefined;
}

const MovesFilter: React.FC<MovesFilterProps> = ({
  range,
  start,
  end,
  onRangeChange,
  onDateChange,
  disablePickers,
  search,
  setSearch,
  selectedUsersArray,
  setSelectedUsersArray,
  userOptions,
  selectedWorkflowsArray,
  setSelectedWorkflowsArray,
  workflowOptions,
  refetch,
  moves,
}) => {
  const cls = useStyles();
  const theme = useTheme();
  const sxStyles = useSxStyles(theme);
  const { getWorkflowData } = useTools();

  const handleRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onRangeChange(event.target.value);
  };

  const handleDateChange = (name: string) => (value: dayjs.Dayjs | null) => {
    if (value) {
      onDateChange(value.format(), name);
    }
  };

  const handleSelectedUsers = (name: string | null, arr?: FilterItems[]) => {
    if (!arr) return;
    const selectedUsers = getSelectionsArray(selectedUsersArray, arr);
    setSelectedUsersArray(selectedUsers);
  };

  const handleSelectedWorkflows = (name: string | null, arr?: FilterItems[]) => {
    if (!arr) return;
    const selectedWorkflows = getSelectionsArray(selectedWorkflowsArray, arr);
    setSelectedWorkflowsArray(selectedWorkflows);
  };

  const handleResetTable = () => {
    setSearch('');
    setSelectedUsersArray([]);
    setSelectedWorkflowsArray([]);
  };

  const handleRefreshData = () => {
    refetch(true);
  };

  /** Get formatted vehicle string from the move object */
  const getFormattedVehicleFromMove = (move: Moves, includeYear = true, includeColor = true) => {
    const fallbackVehicle = 'Vehicle';
    if (move) {
      const year = move.vehicle_year || null;
      const make = move.vehicle_make || null;
      const model = move.vehicle_model || null;
      const color = move.vehicle_color || null;
      let vehicle = '';
      if (make && model) vehicle = `${make} ${model}`;
      else if (make) vehicle = make;
      else if (model) vehicle = model;
      else vehicle = fallbackVehicle;
      if (includeYear && year) vehicle = `${year} ${vehicle}`;
      if (includeColor && color) vehicle = `${vehicle} (${capFirst(color)})`;
      return vehicle;
    }
    return fallbackVehicle;
  };

  const generateCSV = (moves: Moves[], start: string, end: string) => {
    if (moves && moves.length > 0) {
      try {
        const gatherExtraPickupHeaders = (moves: Moves[]) => {
          let extraPickupHeaders: string[] = [];
          moves.forEach(move => {
            const pickupWorkflowData = getWorkflowData('pickup', move.pickup_workflow_data, 'csv');
            if (pickupWorkflowData && Object.keys(pickupWorkflowData).length > 0) {
              const workflowKeys = Object.keys(pickupWorkflowData);
              extraPickupHeaders = [...extraPickupHeaders, ...workflowKeys];
            }
          });
          return [...new Set(extraPickupHeaders)];
        };
        const extraPickupHeaders = gatherExtraPickupHeaders(moves);

        const gatherExtraDeliveryHeaders = (moves: Moves[]) => {
          let extraDeliveryHeaders: string[] = [];
          moves.forEach(move => {
            const deliveryWorkflowData = getWorkflowData('delivery', move.delivery_workflow_data, 'csv');
            if (deliveryWorkflowData && Object.keys(deliveryWorkflowData).length > 0) {
              const workflowKeys = Object.keys(deliveryWorkflowData);
              extraDeliveryHeaders = [...extraDeliveryHeaders, ...workflowKeys];
            }
          });
          return [...new Set(extraDeliveryHeaders)];
        };
        const extraDeliveryHeaders = gatherExtraDeliveryHeaders(moves);

        const createCsvRow = (move: Moves) => {
          const pickupWorkflowData = getWorkflowData('pickup', move.pickup_workflow_data, 'csv') || {};
          const extraPickupData = {} as Record<string, any>;
          extraPickupHeaders.forEach(key => {
            extraPickupData[key] = pickupWorkflowData[key] !== undefined ? pickupWorkflowData[key] : '';
          });

          const deliveryWorkflowData = getWorkflowData('delivery', move.delivery_workflow_data, 'csv') || {};
          const extraDeliveryData = {} as Record<string, any>;
          extraDeliveryHeaders.forEach(key => {
            extraDeliveryData[key] = deliveryWorkflowData[key] !== undefined ? deliveryWorkflowData[key] : '';
          });

          return {
            move_id: move.id,
            status: getFormattedCombinedStatusFromMove(move) || '',
            customer_name: move?.customer?.name || '',
            ready_by: formatTime(move?.ready_by || '') || '',
            reference_num: move.reference_num || '',
            vehicle_stock: move.vehicle_stock || '',
            vehicle_vin: move.vehicle_vin || '',
            vehicle_make: move.vehicle_make || '',
            vehicle_model: move.vehicle_model || '',
            vehicle_year: move.vehicle_year || '',
            vehicle_color: move.vehicle_color || '',
            vehicle_odometer: move.vehicle_odometer || '',
            formatted_vehicle: getFormattedVehicleFromMove(move) || '',
            lane: move?.lane?.description || '',
            ...extraPickupData,
            ...extraDeliveryData,
          };
        };
        const csvRows = moves.map(move => createCsvRow(move));

        const csvOptions = {
          filename: `moves_${dayjs(start).format('MM-DD-YYYY')}_to_${dayjs(end).format('MM-DD-YYYY')}`,
          useKeysAsHeaders: true,
        };

        const csvExporter = new ExportToCsv(csvOptions);
        csvExporter.generateCsv(csvRows);
        toast.success('Generated CSV!', { autoClose: 2000, toastId: 'csv-generated' });
      } catch (err) {
        toast.error('Failed to generate CSV!');
        console.error('Failed to generate CSV:', err);
      }
    } else {
      toast.error('Failed to generate CSV - No moves found!');
      console.error('Failed to generate CSV - No moves found!');
    }
  };

  const getTableActions = (moves: Moves[]) => [
    {
      name: 'generate-csv',
      label: 'Generate CSV',
      icon: <BiFile className={cls.menuIcon} />,
      data: { moves },
      handler: () => generateCSV(moves, start, end),
    },
  ];

  const actions = getTableActions(moves);

  return (
    <div className={cls.main}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2}>
          <Grid size={{ sm: 6, xs: 12 }}>
            <TextField
              select
              fullWidth
              label='Date Range'
              placeholder='Select a date range...'
              variant='outlined'
              margin='none'
              value={range}
              onChange={handleRangeChange}
            >
              <MenuItem value='today'>Today</MenuItem>
              <MenuItem value='nextWeek'>Next Week</MenuItem>
              <MenuItem value='thisWeek'>This Week</MenuItem>
              <MenuItem value='thisMonth'>This Month</MenuItem>
              <MenuItem value='thisYear'>This Year</MenuItem>
              <MenuItem value='custom'>Custom</MenuItem>
            </TextField>
          </Grid>

          <Grid size={{ sm: 3, xs: 6 }}>
            <DatePicker
              disabled={disablePickers}
              label='Start Date'
              value={dayjs(start)}
              onChange={handleDateChange('start')}
              format='MM/DD/YYYY'
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: 'outlined',
                },
              }}
            />
          </Grid>

          <Grid size={{ sm: 3, xs: 6 }}>
            <DatePicker
              disabled={disablePickers}
              label='End Date'
              value={dayjs(end)}
              onChange={handleDateChange('end')}
              format='MM/DD/YYYY'
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: 'outlined',
                },
              }}
            />
          </Grid>

          <Grid size={{ xs: workflowOptions.length > 1 ? 4 : 6 }}>
            <TextField
              fullWidth
              label='Search'
              placeholder='Search moves...'
              variant='outlined'
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </Grid>

          <Grid size={{ xs: workflowOptions.length > 1 ? 3 : 4 }}>
            <ReusableMultiselect
              dataArray={userOptions.map(item => ({ id: item, name: item }))}
              selected={selectedUsersArray.map(item => ({ id: item, name: item }))}
              label={`Filter by user...`}
              handleChange={handleSelectedUsers}
            />
          </Grid>

          {workflowOptions.length > 1 ? (
            <Grid size={{ xs: 3 }}>
              <ReusableMultiselect
                dataArray={workflowOptions.map(item => ({ id: item, name: item }))}
                selected={selectedWorkflowsArray.map(item => ({ id: item, name: item }))}
                label={`Filter by workflow...`}
                handleChange={handleSelectedWorkflows}
              />
            </Grid>
          ) : null}

          <Grid size={{ xs: 2 }}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 4 }} sx={sxStyles.icon}>
                <Tooltip title='Clear Search Filters'>
                  <IconButton onClick={handleResetTable}>
                    <Icon>filter_alt_off</Icon>
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid size={{ xs: 4 }} sx={sxStyles.icon}>
                <Tooltip title='Manually refresh data'>
                  <IconButton onClick={handleRefreshData}>
                    <Icon>cached</Icon>
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid size={{ xs: 4 }} sx={sxStyles.icon}>
                <IconAction actions={actions} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </div>
  );
};

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  icon: {
    height: `100%`,
    verticalAlign: `top`,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const useStyles = () => ({
  main: css`
    margin-bottom: 16px;
  `,
  menuIcon: css`
    display: block;
    margin-top: -1px;
    margin-right: 8px;
  `,
});

export default MovesFilter;
