import React from 'react';
import { Moves } from '@gql/schema';
import { Box, Grid2 as Grid } from '@mui/material';
import organizationOverviewUtils from '../utils/organizationOverviewUtils';

interface MoveItemTooltipProps {
  move?: Moves;
}

const MoveItemTooltip: React.FC<MoveItemTooltipProps> = (props: MoveItemTooltipProps) => {
  const { move } = props;

  //Copied basic setup from admin move planner's MoveDraggable tooltip
  return (
    <Grid container spacing={2}>
      {move && (
        <>
          <Grid size={{ xs: 6 }}>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>
                <strong>Move</strong>
              </Box>
              <Box>{move?.id}</Box>
              <hr style={{ opacity: 0.5, width: '100%' }} />
            </Grid>

            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Status</Box>
              <Box>{move?.status ? move?.status : 'unplanned'}</Box>
            </Grid>
            {/* Not sure if this should be shown to customers since if canceled the status will be 'canceled' */}
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Cancel Status</Box>
              <Box>{move?.cancel_status || 'N/A'}</Box>
            </Grid>

            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <hr style={{ opacity: 0.5, width: '100%' }} />
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Origin</Box>
              <Box sx={{ wrap: 'nowrap' }}>{move?.lane?.pickup?.name}</Box>
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Dest.</Box>
              <Box sx={{ wrap: 'nowrap' }}>{move?.lane?.delivery?.name}</Box>
            </Grid>

            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <hr style={{ opacity: 0.5, width: '100%' }} />
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>VIN</Box>
              <Box>{move?.vehicle_vin || 'N/A'}</Box>
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Stock</Box>
              <Box>{move?.vehicle_stock || 'N/A'}</Box>
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Year</Box>
              <Box>{move?.vehicle_year || 'N/A'}</Box>
            </Grid>
          </Grid>

          <Grid size={{ xs: 6 }}>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <strong>Times</strong>
              <hr style={{ opacity: 0.5, width: '100%' }} />
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Planned Pickup</Box>
              <Box>{move?.pickup_time ? organizationOverviewUtils.formatDateTime(move?.pickup_time) : 'N/A'}</Box>
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Planned Delivery</Box>
              <Box>{move?.delivery_time ? organizationOverviewUtils.formatDateTime(move?.delivery_time) : 'N/A'}</Box>
            </Grid>

            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <hr style={{ opacity: 0.5, width: '100%' }} />
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Pickup Started</Box>
              <Box>{move?.pickup_started ? organizationOverviewUtils.formatDateTime(move?.pickup_started) : 'N/A'}</Box>
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Pickup Arrived</Box>
              <Box>{move?.pickup_arrived ? organizationOverviewUtils.formatDateTime(move?.pickup_arrived) : 'N/A'}</Box>
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Pickup Successful</Box>
              <Box>
                {move?.pickup_successful ? organizationOverviewUtils.formatDateTime(move?.pickup_successful) : 'N/A'}
              </Box>
            </Grid>

            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <hr style={{ opacity: 0.5, width: '100%' }} />
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Delivery Started</Box>
              <Box>
                {move?.delivery_started ? organizationOverviewUtils.formatDateTime(move?.delivery_started) : 'N/A'}
              </Box>
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Delivery Arrived</Box>
              <Box>
                {move?.delivery_arrived ? organizationOverviewUtils.formatDateTime(move?.delivery_arrived) : 'N/A'}
              </Box>
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Delivery Successful</Box>
              <Box>
                {move?.delivery_successful
                  ? organizationOverviewUtils.formatDateTime(move?.delivery_successful)
                  : 'N/A'}
              </Box>
            </Grid>
          </Grid>
          {move?.consumer_type ? (
            <Grid size={{ xs: 12 }}>
              <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                <strong>Customer Info</strong>
                <hr style={{ opacity: 0.5, width: '100%' }} />
              </Grid>
              <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                <Box>Customer Name</Box>
                <Box>{move?.consumer_name}</Box>
              </Grid>
              <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                <Box>Customer Phone</Box>
                <Box>{organizationOverviewUtils.formatPhoneNumber(move?.consumer_phone)}</Box>
              </Grid>
            </Grid>
          ) : null}
        </>
      )}
    </Grid>
  );
};

export default MoveItemTooltip;
