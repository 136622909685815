// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { condensedCase } from '@services/formatService';

import { css } from '@emotion/css';
import { Theme, useTheme, Button, Typography, Icon } from '@mui/material';

import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// TYPES ---------------------------------------------------------------- //

interface ImageCarouselProps {
  size?: 'sm' | 'md' | 'lg';
  images?: any[];
}

// COMPONENT ---------------------------------------------------------------- //

const ImageCarousel = ({ size = `sm`, images = [] }: ImageCarouselProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const [filteredImages, setFilteredImages] = React.useState([]);
  const [picIndex, setPicIndex] = React.useState(0);
  const [imageFilter, setImageFilter] = React.useState(null);
  const [lightboxOpen, setLightboxOpen] = React.useState(false);
  const [pendingFullscreen, setPendingFullscreen] = React.useState(false);

  const galleryRef = React.useRef(null);

  React.useEffect(() => {
    setFilteredImages(images || []);
    setPicIndex(0);
    setImageFilter(null);
  }, [images]);

  React.useEffect(() => {
    if (pendingFullscreen && filteredImages?.length) {
      setLightboxOpen(true);
      setPendingFullscreen(false);
    }
  }, [pendingFullscreen, filteredImages]);

  const getImageTitle = (index = 0) => {
    const imageTitle = images?.[index]?.label || null;
    return imageTitle;
  };

  const getMainSrc = (index = 0) => {
    const mainSrc = images?.[index]?.original || null;
    return mainSrc;
  };

  const getPrevSrc = (index = 0) => {
    const prevSrc = images?.[(index + images.length - 1) % images.length]?.original || null;
    return prevSrc;
  };

  const getNextSrc = (index = 0) => {
    const nextSrc = images?.[(index + 1) % images.length]?.original || null;
    return nextSrc;
  };

  const handleFilterChange = (str: string | null = null) => {
    let newImages = [...images];

    if (newImages && newImages.length > 0) {
      if (str === `exterior`)
        newImages = newImages?.filter(image => {
          if (
            condensedCase(image?.label)?.includes(`exterior`) ||
            condensedCase(image?.label)?.includes(`driver`) ||
            condensedCase(image?.label)?.includes(`passenger`)
          )
            return true;
          return false;
        });
      if (str === `interior`)
        newImages = newImages?.filter(image => {
          if (
            condensedCase(image?.label)?.includes(`interior`) ||
            condensedCase(image?.label)?.includes(`instrument`) ||
            condensedCase(image?.label)?.includes(`trunk`) ||
            condensedCase(image?.label)?.includes(`vin`)
          )
            return true;
          return false;
        });
      if (str === `wheel`) newImages = newImages?.filter(image => condensedCase(image?.label)?.includes(`wheel`));
      if (str === `damage`) newImages = newImages?.filter(image => condensedCase(image?.label)?.includes(`damage`));

      setFilteredImages(newImages);
      setPicIndex(0);
      setImageFilter(str);
    }
  };

  const handleFullscreen = event => {
    let currentIndex = 0;
    if (galleryRef?.current?.state?.currentIndex !== undefined) {
      currentIndex = galleryRef.current.state.currentIndex;
    }
    setPicIndex(currentIndex);
    setPendingFullscreen(true);
  };

  return (
    <>
      <div className={cls.gallery}>
        <div className={cls.flex}>
          <div className={cls.flexItemStretch}>
            <Button
              fullWidth
              color='secondary'
              variant={!imageFilter ? `contained` : `outlined`}
              onClick={() => handleFilterChange()}
            >
              {size === `sm` ? `All` : `Virtual Walkaround`}
            </Button>
          </div>

          <div className={cls.flexItem}>
            <Button
              fullWidth
              color='secondary'
              variant={imageFilter === `exterior` ? `contained` : `outlined`}
              onClick={() => handleFilterChange(`exterior`)}
            >
              Exterior
            </Button>
          </div>

          <div className={cls.flexItem}>
            <Button
              fullWidth
              color='secondary'
              variant={imageFilter === `interior` ? `contained` : `outlined`}
              onClick={() => handleFilterChange(`interior`)}
            >
              Interior
            </Button>
          </div>

          <div className={cls.flexItem}>
            <Button
              fullWidth
              color='secondary'
              variant={imageFilter === `wheel` ? `contained` : `outlined`}
              onClick={() => handleFilterChange(`wheel`)}
            >
              Wheel
            </Button>
          </div>

          <div className={cls.flexItem}>
            <Button
              fullWidth
              color='secondary'
              variant={imageFilter === `damage` ? `contained` : `outlined`}
              onClick={() => handleFilterChange(`damage`)}
            >
              Damage
            </Button>
          </div>
        </div>

        {filteredImages?.length ? (
          <div className={cls.carousel}>
            <Gallery
              ref={galleryRef}
              items={filteredImages}
              thumbnailPosition='left'
              lazyLoad
              showIndex
              disableKeyDown
              startIndex={picIndex}
              slideDuration={250}
              showFullscreenButton={false}
              onClick={handleFullscreen}
            />
            <Icon className={cls.fullscreenIcon} onClick={handleFullscreen}>
              fullscreen
            </Icon>
          </div>
        ) : (
          <>
            <Typography
              variant='h5'
              sx={{ textAlign: `center`, fontWeight: 600, color: theme?.palette?.text?.disabled }}
            >
              NO IMAGES FOUND
            </Typography>
          </>
        )}
      </div>

      {lightboxOpen && filteredImages?.length ? (
        <Lightbox
          imageTitle={getImageTitle(picIndex)}
          imageCaption={
            filteredImages?.[picIndex]?.label !== filteredImages?.[picIndex]?.description
              ? filteredImages?.[picIndex]?.description
              : null
          }
          mainSrc={getMainSrc(picIndex)}
          prevSrc={getPrevSrc(picIndex)}
          nextSrc={getNextSrc(picIndex)}
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() => setPicIndex((picIndex + filteredImages?.length - 1) % filteredImages?.length)}
          onMoveNextRequest={() => setPicIndex((picIndex + 1) % filteredImages?.length)}
          reactModalStyle={{
            overlay: {
              zIndex: 1000000,
            },
            content: {
              zIndex: 1000000,
            },
          }}
        />
      ) : null}
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  gallery: css`
    position: relative;
  `,
  carousel: css`
    position: relative;
  `,
  fullscreenIcon: css`
    position: absolute;
    bottom: 16px;
    right: 16px;
    color: ${theme?.palette?.text?.contrastPrimary};
    filter: drop-shadow(2px 2px 2px #00000080);
    font-size: 48px;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    :hover,
    :active {
      color: ${theme?.palette?.info?.main};
      transform: scale(1.1);
    }
  `,

  flex: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    margin-bottom: 12px;
  `,
  flexItem: css``,
  flexItemStretch: css`
    flex: 1;
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default ImageCarousel;
