import React from 'react';
import dayjs from 'dayjs';
import { css } from '@emotion/css';

import { Appraisals, Moves, Vehiclephotos } from '@gql/schema';
// import { useVehiclePhotos } from '../../hooks/useVehiclePhotos';

import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { Grid2 as Grid, Typography, TextField, MenuItem, Button, Icon, useTheme, Theme } from '@mui/material';

//This is the format of the image object used in the gallery
type Image = {
  label: string;
  original: string;
  thumbnail: string;
  index: number;
};

interface AppraisalDetailsCarouselProps {
  appraisal: Appraisals;
  vehicleImages: Image[];
  setVehicleImages: React.Dispatch<React.SetStateAction<Image[]>>;
}

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
const AppraisalDetailsCarousel: React.FC<AppraisalDetailsCarouselProps> = props => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sxStyles = useSxStyles(theme);

  const { appraisal, vehicleImages, setVehicleImages } = props;
  // const { sortImages } = useVehiclePhotos();
  let usableMoves: Moves[] = [];
  if (appraisal && appraisal.moves && appraisal.moves.length > 0)
    usableMoves = appraisal.moves.filter(move => move.vehiclephotos && move.vehiclephotos.length > 0);

  const [selectedMove, setSelectedMove] = React.useState(usableMoves.length > 0 ? usableMoves[0].id : null);
  const [galleryPics, setGalleryPics] = React.useState<Image[]>([]);
  const [lightboxPics, setLightboxPics] = React.useState<string[]>([]);
  const [lightboxOpen, setLightboxOpen] = React.useState(false);
  const [picIndex, setPicIndex] = React.useState(0);
  const [imageFilter, setImageFilter] = React.useState<string | null>(null);

  const galleryRef = React.useRef(null);

  React.useEffect(() => {
    handleFilterChange();
  }, [vehicleImages]);

  // Get related move images
  const getVehicleImages = async (move: Moves) => {
    let photos: Vehiclephotos[] = [];

    if (move.vehiclephotos && move.vehiclephotos.length > 0) {
      const deliveryPhotos = move.vehiclephotos.filter(photo => photo.workflow.type === `delivery`);
      const pickupPhotos = move.vehiclephotos.filter(photo => photo.workflow.type === `pickup`);

      if (deliveryPhotos && deliveryPhotos.length > 0) {
        photos = deliveryPhotos;
      } else if (pickupPhotos && pickupPhotos.length > 0) {
        photos = pickupPhotos;
      }
    }

    // Sort the images before setting them (and add an index field)
    // images = sortImages(images);
    const images: Image[] = photos.map((image, i) => ({
      label: image && image.name ? image.name.replace(/-/g, ` `) : ``,
      original: image.url || ``,
      thumbnail: image.url || ``,
      index: i,
    }));

    // Set final image state
    setVehicleImages(images);
  };

  React.useEffect(() => {
    if (usableMoves.length === 0) return;
    getVehicleImages(usableMoves[0]);
  }, []);

  const handleSelectedMoveChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const moveId = event.target.value as number;
    setSelectedMove(moveId);

    const move = usableMoves.find(usableMove => usableMove.id === moveId);
    if (!move) return;
    getVehicleImages(move);
  };

  const handleFilterChange = (str: string | null = null) => {
    let images = [...vehicleImages];

    if (images && images.length > 0) {
      if (str === `exterior`)
        images = images.filter(
          image =>
            image.label.includes(`driver`) || image.label.includes(`passenger`) || image.label.includes(`exterior`)
        );
      if (str === `interior`)
        images = images.filter(image => !image.label.includes(`driver`) && !image.label.includes(`passenger`));
      if (str === `wheel`) images = images.filter(image => image.label.includes(`wheel`));
      if (str === `damage`) images = images.filter(image => image.label.includes(`damage`));

      setPicIndex(0);
      setGalleryPics(images);
      setLightboxPics(images.map(image => image.original));
      setImageFilter(str);
    }
  };

  // const handleFullscreen = () => {
  //   let currentIndex = 0;
  //   if (galleryRef) currentIndex = galleryRef?.current?.state.currentIndex;
  //   if (lightboxPics && lightboxPics.length > 0) {
  //     setPicIndex(currentIndex);
  //     setLightboxOpen(!lightboxOpen);
  //   }
  // };

  return (
    <>
      <div className={styles.paper}>
        <>
          <TextField
            select
            fullWidth
            label='Select Move Images'
            variant='outlined'
            size='small'
            value={selectedMove}
            onChange={handleSelectedMoveChange}
          >
            {usableMoves &&
              usableMoves.length > 0 &&
              usableMoves.map(move => (
                <MenuItem key={`move-select-${move.id}`} value={move.id}>
                  #{move.id} - {dayjs(move.ready_by).format(`MM/DD/YYYY`)} - {move?.lane?.description || `unknown lane`}
                </MenuItem>
              ))}
          </TextField>

          <div className={styles.xsBreak} />

          <Grid container spacing={1} justifyContent='space-between'>
            <Grid size='grow'>
              <Button
                sx={!imageFilter ? sxStyles.buttonActive : sxStyles.button}
                fullWidth
                color='secondary'
                variant='contained'
                onClick={() => handleFilterChange()}
              >
                Virtual&nbsp;Walkaround
              </Button>
            </Grid>
            <Grid>
              <Button
                sx={imageFilter === `exterior` ? sxStyles.buttonActive : sxStyles.button}
                fullWidth
                color='secondary'
                variant='contained'
                onClick={() => handleFilterChange(`exterior`)}
              >
                Exterior
              </Button>
            </Grid>
            <Grid>
              <Button
                sx={imageFilter === `interior` ? sxStyles.buttonActive : sxStyles.button}
                fullWidth
                color='secondary'
                variant='contained'
                onClick={() => handleFilterChange(`interior`)}
              >
                Interior
              </Button>
            </Grid>
            <Grid>
              <Button
                sx={imageFilter === `wheel` ? sxStyles.buttonActive : sxStyles.button}
                fullWidth
                color='secondary'
                variant='contained'
                onClick={() => handleFilterChange(`wheel`)}
              >
                Wheel
              </Button>
            </Grid>
            <Grid>
              <Button
                sx={imageFilter === `damage` ? sxStyles.buttonActive : sxStyles.button}
                fullWidth
                color='secondary'
                variant='contained'
                onClick={() => handleFilterChange(`damage`)}
              >
                Damage
              </Button>
            </Grid>
          </Grid>

          <div className={styles.mdBreak} />

          {galleryPics.length > 0 ? (
            <div className={styles.carousel}>
              <Gallery
                ref={galleryRef}
                items={galleryPics}
                thumbnailPosition='left'
                lazyLoad
                showIndex
                disableKeyDown
                startIndex={picIndex}
                slideDuration={250}
                showFullscreenButton={false}
                // onClick={handleFullscreen}
              />
              <Icon
                sx={sxStyles.fullscreenIcon}
                // onClick={handleFullscreen}
              >
                fullscreen
              </Icon>
            </div>
          ) : (
            <>
              <Typography sx={sxStyles.notFoundTxt}>NO IMAGES FOUND</Typography>
            </>
          )}

          {lightboxOpen && lightboxPics.length > 0 ? (
            <Lightbox
              mainSrc={lightboxPics[picIndex]}
              nextSrc={lightboxPics[(picIndex + 1) % lightboxPics.length]}
              prevSrc={lightboxPics[(picIndex + lightboxPics.length - 1) % lightboxPics.length]}
              onCloseRequest={() => setLightboxOpen(false)}
              onMovePrevRequest={() => setPicIndex((picIndex + lightboxPics.length - 1) % lightboxPics.length)}
              onMoveNextRequest={() => setPicIndex((picIndex + 1) % lightboxPics.length)}
              reactModalStyle={{
                overlay: {
                  zIndex: 1000000,
                },
                content: {
                  zIndex: 1000000,
                },
              }}
            />
          ) : null}
        </>
      </div>
    </>
  );
};

export default AppraisalDetailsCarousel;

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = (theme: Theme) => {
  return {
    paper: css`
      position: relative;
      width: 100%;
      padding: ${theme.spacing(2)};
      border-radius: 8px;
      background: #fff;
      box-shadow: 0 0 12px #00000032;
    `,
    carousel: css`
      position: relative;
    `,
    notFoundTxt: css`
      color: ${theme.palette.text.secondary};
      line-height: 1.25;
      text-align: center;
      font-size: 21px;
      font-weight: 500;
      ${theme.breakpoints.down('sm')} {
        font-size: 18px;
      }
      ${theme.breakpoints.down('xs')} {
        font-size: 16px;
      }
    `,
    xsBreak: css`
      width: 100%;
      height: ${theme.spacing(1)};
    `,
    smBreak: css`
      width: 100%;
      height: ${theme.spacing(1.5)};
    `,
    mdBreak: css`
      width: 100%;
      height: ${theme.spacing(2)};
    `,
    lgBreak: css`
      width: 100%;
      height: ${theme.spacing(3)};
    `,
  };
};
const useSxStyles = (theme: Theme) => ({
  fullscreenIcon: {
    position: 'absolute',
    bottom: 16,
    right: 16,
    color: '#fff',
    filter: 'drop-shadow(2px 2px 2px #00000080)',
    fontSize: 48,
    '&:hover, &:active': {
      color: theme.palette.info.main,
      transform: 'scale(1.1)',
    },
    cursor: 'pointer',
    transition: '0.2s',
  },
  button: {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    background: 'transparent',
    boxShadow: 'none',
    '&:hover, &:active': {
      background: `${theme.palette.secondary.main}32`,
      boxShadow: 'none',
    },
    transition: '0.2s',
  },
  buttonActive: {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: '#fff',
    boxShadow: 'none',
    '&:hover, &:active': {
      boxShadow: 'none',
    },
    transition: '0.2s',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
});
