import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useGetAppointmentSubscription, Appointments } from '@gql/schema';
import { useRooftop } from '@store';
import { MovePlannerProvider } from '@features/moveCreation/providers/MovePlannerProvider';
import DefaultFallback from '@components/Fallbacks/DefaultFallback';
import Loading from '@components/Utils/Loading';
import AppointmentAddForm from '../components/AppointmentAddForm.tsx';
import AppointmentDetailsContent from '../components/AppointmentDetailsContent';
import { useParams, useLocation } from 'react-router-dom';
import { css } from '@emotion/css';
import { Theme, useTheme, Container } from '@mui/material';

const AppointmentDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const isNewAppointment = location.pathname === '/appointments/add';
  const appointmentId = isNewAppointment ? null : id ? parseInt(id) : null;
  const rooftop = useRooftop();
  const theme = useTheme();
  const styles = useStyles(theme);

  const [appointmentsEnabled, setAppointmentsEnabled] = useState(false);

  const { data, loading, error } = useGetAppointmentSubscription({
    variables: { id: appointmentId! },
    skip: appointmentId === null,
  });

  useEffect(() => {
    if (
      rooftop?.organization?.config?.appointments?.enabled &&
      rooftop?.organization?.config?.appointments?.enabled === true
    ) {
      setAppointmentsEnabled(true);
    } else {
      setAppointmentsEnabled(false);
    }
  }, [rooftop]);

  if (loading) {
    return <Loading />;
  }

  if (!appointmentsEnabled) {
    return <DefaultFallback fullscreen severity='error' message='RESTRICTED ACCESS' />;
  }
  // If this is a new appointment, render the form immediately
  if (isNewAppointment) {
    return (
      <div>
        <MovePlannerProvider state={{ appointment: (data?.appointments_by_pk as Appointments) || {} }}>
          <div className={styles.pageContainer}>
            <Container maxWidth='lg'>
              <AppointmentAddForm />
            </Container>
          </div>
        </MovePlannerProvider>
      </div>
    );
  }

  if (error) {
    return (
      <Sentry.ErrorBoundary
        fallback={<DefaultFallback fullscreen severity='error' message='Error fetching appointment details!' />}
      />
    );
  }

  if (data?.appointments_by_pk) {
    return (
      <div className={styles.pageContainer}>
        <Container maxWidth='lg'>
          <AppointmentDetailsContent {...(data.appointments_by_pk as Appointments)} />
        </Container>
      </div>
    );
  }

  return <DefaultFallback fullscreen severity='warning' message='No appointment found!' />;
};

const useStyles = (theme: Theme) => ({
  pageContainer: css`
    padding: ${theme.spacing(3)} 0;
    min-height: 100vh;
  `.toString(),
});

export default AppointmentDetails;
