import { toast } from 'react-toastify';

import {
  generateCSV as generateCSVService,
  generateEnhancedCSV as generateEnhancedCSVService,
} from '@features/invoices/services/csvService';

export default function useCsvGeneration() {
  const generateCSV = (invoice: any) => {
    try {
      generateCSVService(invoice);
      toast.info('Generated CSV!', { autoClose: 2000, toastId: 'generate-csv' });
    } catch (err) {
      toast.error('Failed to generate CSV!', { toastId: 'generate-csv-error' });
      console.error('Failed to generate CSV:', err);
    }
  };

  const generateEnhancedCSV = (invoice: any) => {
    try {
      generateEnhancedCSVService(invoice);
      toast.info('Generated Enhanced CSV!', { autoClose: 2000, toastId: 'generate-csv' });
    } catch (err) {
      toast.error('Failed to generate Enhanced CSV!', { toastId: 'generate-csv-error' });
      console.error('Failed to generate Enhanced CSV:', err);
    }
  };

  return {
    generateCSV,
    generateEnhancedCSV,
  };
}
