import { capFirst } from '@services/formatService';
import dayjs from 'dayjs';
import { validateVin } from '@services/validationService';

export const getFormattedVehicleFromMove = (move: any | null, includeYear = true, includeColor = true) => {
  const fallbackVehicle = `Vehicle`;

  if (move) {
    let type = ``;
    if (move.consumer_type === `customer`) type = `Customer `;
    if (move.consumer_type === `loaner`) type = `Loaner `;

    const year = move?.vehicle_year || move?.driver_vehicle_year || null;
    const make = move?.vehicle_make || move?.driver_vehicle_make || null;
    const model = move?.vehicle_model || move?.driver_vehicle_model || null;
    const color = move?.vehicle_color || move?.driver_vehicle_color || null;

    let vehicle = ``;

    if (make && model) vehicle = `${make} ${model}`;
    else if (make) vehicle = make;
    else if (model) vehicle = model;
    else vehicle = type + fallbackVehicle;

    if (includeYear && year) vehicle = `${year} ${vehicle}`;
    if (includeColor && color) vehicle = `${vehicle} (${capFirst(color)})`;

    return vehicle;
  }
  return fallbackVehicle;
};

export const checkMoveInProgress = (move: any, includeCompleted = false) => {
  if (move) {
    const moveType = move?.move_type || ``;
    const status = move?.status || ``;

    if (moveType === `drive`) {
      if ((status.includes(`pickup`) || status.includes(`delivery`)) && status !== `delivery successful`) return true;
    }

    if (moveType === `ride`) {
      if (status === `accepted` || status === `arrived` || status === `pickedUp`) return true;
    }

    if (includeCompleted) return checkMoveCompleted(move);
  }
  return false;
};

export const checkMoveCompleted = (move: any) => {
  if (move) {
    const moveType = move?.move_type || ``;
    const status = move?.status || ``;
    if (moveType === `drive` && status === `delivery successful`) return true;
    if (moveType === `ride` && status === `droppedOff`) return true;
  }
  return false;
};

export const getLatestTimestampFromMove = (move: any | null) => {
  if (move) {
    if (move?.delivery_successful) return move.delivery_successful;
    if (move?.delivery_arrived) return move.delivery_arrived;
    if (move?.delivery_started) return move.delivery_started;
    if (move?.pickup_successful) return move.pickup_successful;
    if (move?.pickup_arrived) return move.pickup_arrived;
    if (move?.pickup_started) return move.pickup_started;
  }
  return null;
};

export const getDriverInitialsFromMove = (move: any | null) => {
  const fallbackInitials = `N/A`;

  if (move) {
    const fullName = move.driver_name ? move.driver_name : fallbackInitials;

    if (fullName !== fallbackInitials) {
      const names = fullName.split(` `);
      const firstI = names.length > 0 ? names[0][0] : ``;
      const secondI = names.length > 1 ? names[1][0] : ``;
      const thirdI = names.length > 2 ? names[2][0] : ``;

      const initials = `${firstI}${secondI}${thirdI}`.toUpperCase();

      if (initials && initials !== ``) return initials;
    }
  }
  return fallbackInitials;
};

export const getPickupOrDeliveryTimeFromMove = (type = `pickup`, move: any | null, format = `MM/DD/YYYY hh:mm A z`) => {
  if (move) {
    if (type === `pickup`) {
      if (move.pickup_arrived) return dayjs(move.pickup_arrived).format(format);
      if (move.pickup_time) return dayjs(move.pickup_time).format(format);
      if (move.ready_by) return dayjs(move.ready_by).format(format);
    }
    if (type === `delivery`) {
      if (move.delivery_successful) return dayjs(move.delivery_successful).format(format);
      if (move.delivery_time) return dayjs(move.delivery_time).format(format);
      if (move.deliver_by) return dayjs(move.deliver_by).format(format);
    }
  }
  return null;
};

export const getMileageFromMove = (move: any | null) => {
  if (move?.delivery_workflow_data && move.delivery_workflow_data['odometer']) {
    return move.delivery_workflow_data['odometer'];
  } else if (move?.pickup_workflow_data && move.pickup_workflow_data['odometer']) {
    return move.pickup_workflow_data['odometer'];
  } else return 0;
};

export const getVinFromMove = (vehicle: any | null) => {
  if (vehicle?.move?.pickup_workflow_data && vehicle.move.pickup_workflow_data['driver-vin'] && validateVin(vehicle.move.pickup_workflow_data['driver-vin'])) {
    return vehicle.move.pickup_workflow_data['driver-vin'];
  } else if (vehicle?.vin) {
    return parseInt(vehicle.vin);
  } else return null;
};