import React, { useState, FormEvent } from 'react';
import { authService } from '../services/authService';
import { FirebaseError } from 'firebase/app';
import {
  Theme,
  useTheme,
  SxProps,
  Typography,
  Grid2 as Grid,
  Icon,
  Button,
  TextField,
  InputAdornment,
} from '@mui/material';
import Spacer from '@components/Utils/Spacer';

interface PasswordResetProps {
  setOpen: (open: boolean) => void;
  email: string;
  setEmail: (email: string) => void;
}

const PasswordReset: React.FC<PasswordResetProps> = ({ setOpen, email, setEmail }) => {
  const theme = useTheme();
  const sxStyles = useSxStyles(theme);

  const [resetSuccess, setResetSuccess] = useState<boolean | null>(null);

  const handlePasswordReset = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await authService.resetPassword(email);
      setResetSuccess(true);
    } catch (err) {
      if (err instanceof FirebaseError) {
        setResetSuccess(false);
      } else {
        setResetSuccess(false);
      }
    }
  };

  return (
    <div>
      <Spacer size='xl' />

      <Typography sx={sxStyles.titleTxt}>Request or reset password</Typography>
      <Typography sx={sxStyles.subtitleTxt}>Enter your email and receive a link to reset your password.</Typography>

      <Spacer size='lg' />

      {resetSuccess === true ? (
        <>
          <Grid container alignItems='center'>
            <Grid>
              <Icon sx={sxStyles.successIcon}>check_circle</Icon>
            </Grid>

            <Grid size='grow'>
              <Typography sx={sxStyles.successTxt}>
                Your request was sent successfully. Please check your email for the next step.
              </Typography>
            </Grid>
          </Grid>

          <Spacer size='lg' />

          <Button fullWidth size='large' onClick={() => setOpen(false)}>
            Return
          </Button>
        </>
      ) : resetSuccess === false ? (
        <>
          <Grid container alignItems='center'>
            <Grid>
              <Icon sx={sxStyles.errorIcon}>cancel</Icon>
            </Grid>

            <Grid size='grow'>
              <Typography sx={sxStyles.errorTxt}>
                Your request failed to send. Please make sure your email is correct and try again.
              </Typography>
            </Grid>
          </Grid>

          <Spacer size='lg' />

          <Grid container spacing={2}>
            <Grid size='grow'>
              <Button fullWidth size='large' onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </Grid>

            <Grid size='grow'>
              <Button fullWidth color='secondary' size='large' onClick={() => setResetSuccess(null)}>
                Try Again
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <TextField
            required
            fullWidth
            autoFocus
            id='email'
            name='email'
            autoComplete='email'
            label='Email'
            placeholder='Enter email...'
            variant='outlined'
            size='small'
            value={email}
            onChange={e => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    email
                  </Icon>
                </InputAdornment>
              ),
            }}
          />

          <Spacer size='lg' />

          <Grid container spacing={2}>
            <Grid size='grow'>
              <Button fullWidth size='large' onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </Grid>

            <Grid size='grow'>
              <Button
                disabled={!email || resetSuccess === false || resetSuccess === true}
                fullWidth
                type='submit'
                color='primary'
                size='large'
                onClick={e => handlePasswordReset(e)}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default PasswordReset;

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  titleTxt: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 500,
  },
  subtitleTxt: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 400,
  },
  successIcon: {
    marginRight: 8,
    fontSize: 24,
    color: theme.palette.success.main,
  },
  successTxt: {
    verticalAlign: 'top',
    lineHeight: 1.333,
    fontSize: 18,
    color: theme.palette.success.main,
  },
  errorIcon: {
    marginRight: 8,
    fontSize: 24,
    color: theme.palette.error.main,
  },
  errorTxt: {
    verticalAlign: 'top',
    lineHeight: 1.333,
    fontSize: 18,
    color: theme.palette.error.main,
  },
});
