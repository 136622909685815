import React from 'react';
import { css } from '@emotion/css';
import { Container, Typography, SxProps, useTheme, Theme } from '@mui/material';

// import Loading from '../utils/Loading';
import BillingPreferences from '@features/billing/components/BillingPreferences';
import MethodTable from '@features/billing/components/MethodTable';

////////// COMPONENT //////////
const BillingPage: React.FC = () => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sxStyles = useSxStyles(theme);

  return (
    <div className={styles.root}>
      <Container maxWidth='lg'>
        <Typography sx={sxStyles.head}>Billing Preferences</Typography>
        <div style={{ width: '100%', height: '8px' }} />
        <BillingPreferences/>
        <div style={{ width: '100%', height: '32px' }} />
        <Typography sx={sxStyles.head}>Payment Methods</Typography>
        <MethodTable/>
        <div style={{ width: '100%', height: '16px' }} />
      </Container>
    </div>
  );
};

export default BillingPage;

////////// STYLES //////////

const useStyles = (theme: Theme) => {
  return {
    root: css`
      display: flex;
      padding-top: ${theme.spacing(4)};
      padding-bottom: ${theme.spacing(4)};
    `,
  };
};

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  head: {
    marginBottom: theme.spacing(2),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
});
