import { auth } from '@features/auth/services/authService';

export const refreshToken = async () => {
  const user = auth.currentUser;
  if (user) {
    await user.getIdToken(true); // Force refresh
  } else {
    throw new Error('No user is currently authenticated');
  }
};
