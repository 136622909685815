// DEPENDENCIES ---------------------------------------------------------------- //

import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { Moves } from '@gql/schema';

import { getFormattedCombinedStatusFromMove } from '@services/statusService';

import { css } from '@emotion/css';
import { Chip, Theme, useTheme } from '@mui/material';
import { BiBlock, BiCar } from 'react-icons/bi';

import Table from '@components/TableComponents/Table';
import Column from '@components/TableComponents/Table';

const defaultOrder = `desc`;
const defaultOrderBy = `MOVE_ID`;

const formatTime = (time: string) => {
  if (time && typeof time === `string`) {
    let newTime = dayjs(time).format(`M/D/YYYY h:mm A z`);
    newTime = newTime.replace(/\s/g, '\xa0');
    return newTime;
  }
  return null;
};

// TYPES ---------------------------------------------------------------- //

interface MovesTableProps {
  moves: Moves[];
  isAdmin: boolean;
  payersArray: any[];
  handleModalOpen: (move: Moves) => void;
}

// COMPONENT ---------------------------------------------------------------- //

const MovesTable: React.FC<MovesTableProps> = ({ moves, isAdmin, payersArray, handleModalOpen }) => {
  const theme = useTheme();
  const cls = useStyles();

  const navigate = useNavigate();

  const handleNavigateClick = (moveData: Moves) => {
    if (moveData) {
      navigate(`/moves/${moveData?.id}`);
    }
  };

  const handleCancelClick = (moveData: Moves) => {
    if (moveData) {
      handleModalOpen(moveData);
    }
  };

  const rowActions = [
    {
      name: 'move-details',
      label: 'Go To Move Details',
      icon: <BiCar className={cls.menuIcon} />,
      handler: handleNavigateClick,
    },
    {},
    {
      name: 'cancel-move',
      label: 'Cancel Move',
      icon: <BiBlock className={cls.menuIcon} />,
      color: theme?.palette?.error?.main,
      handler: handleCancelClick,
      hide: (data: any): boolean => {
        const moveData = data as Moves;
        const shouldHide = Boolean(moveData.cancel_status) || moveData.status === 'delivery successful';
        return shouldHide;
      },
    },
  ];

  return (
    <Table
      data={moves}
      defaultOrderBy={defaultOrderBy}
      defaultOrder={defaultOrder}
      tableAriaLabel='moves'
      stickyHeader={true}
      rowActions={rowActions}
      onRowClick={(data: any) => navigate(`/moves/${(data as Moves)?.id}`)}
    >
      <Column name='move_id' value={(move: Moves) => move?.id || '-'} label='Move ID' style={{ minWidth: 120 }} />

      <Column
        name='ref_num'
        value={(move: Moves) => move.reference_num || '-'}
        label='Ref #'
        style={{ minWidth: 140 }}
      />

      {/* <Column
        name='vin'
        value={(move: Moves) => move?.vehicle_vin?.toUpperCase() || '-'}
        label='VIN'
        style={{ minWidth: 120 }}
      /> */}

      <Column
        name='stock'
        value={(move: Moves) => move?.vehicle_stock?.toUpperCase() || '-'}
        label='Stock'
        style={{ minWidth: 120 }}
      />

      {isAdmin ? (
        <Column
          name='workflow'
          value={(move: Moves) => move.workflowset?.name || ''}
          label='Workflow'
          style={{ minWidth: 140 }}
        />
      ) : null}

      {isAdmin ? (
        <Column
          name='market'
          value={(move: Moves) => move?.customer?.name || '-'}
          label='Market'
          style={{ minWidth: 160 }}
        />
      ) : null}

      {payersArray && payersArray.length > 0 ? (
        <Column
          name='payer'
          value={(move: Moves) => (move?.payer_id ? move?.payer_id : move?.customer?.name ? move?.customer?.name : '-')}
          label='Payer'
          style={{ minWidth: 120 }}
        />
      ) : null}

      <Column
        name='move_time'
        value={(move: Moves) => move?.ready_by || '-'}
        renderer={(move: Moves) => formatTime(move?.ready_by || '')}
        label='Move Time'
        style={{ minWidth: 160 }}
      />

      <Column
        name='lane'
        value={(move: Moves) => move?.lane?.description || '-'}
        label='Lane'
        style={{ minWidth: 120 }}
      />

      <Column
        name='status'
        value={(move: Moves) => getFormattedCombinedStatusFromMove(move)}
        renderer={(move: Moves) => <Chip variant='outlined' label={getFormattedCombinedStatusFromMove(move)} />}
        label='Status'
        style={{ minWidth: 120 }}
        align='right'
      />
    </Table>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  menuIcon: css`
    display: block;
    margin-top: -1px;
    margin-right: 8px;
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default MovesTable;
