// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { Action } from 'src/types';

import { capFirst, clampNegNum, getMonetaryValue } from '@services/formatService';
import { getFormattedVehicleFromMove } from '@services/moveService';

import { css } from '@emotion/css';
import { Chip, Icon, IconButton, Menu, MenuItem, TextField, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import { BiFile } from 'react-icons/bi';

import Spacer from '@components/Utils/Spacer';
import InfoItem from '@components/InfoItem';

import MoveDetailsTimeline from '@features/moves/components/MoveDetailsTimeline';

// TYPES ---------------------------------------------------------------- //

type InvoiceDetailsTableFoldProps = {
  armove?: any;
  actions?: any[];
};

// COMPONENT ---------------------------------------------------------------- //

const InvoiceDetailsTableFold = ({ armove, actions }: InvoiceDetailsTableFoldProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);
  const clsx = useSxStyles(theme);

  const move = armove?.move || {};
  const pickup = move?.lane?.pickup || {};
  const delivery = move?.lane?.delivery || {};
  const revisions = armove?.revisions || [];

  const [actionsOpen, setActionsOpen] = React.useState<null | HTMLElement>(null);
  const [revisionNum, setRevisionNum] = React.useState<number>(1);

  const handleActionsOpen = (event?: React.MouseEvent<HTMLElement>) => {
    setActionsOpen(event?.currentTarget || null);
  };
  const handleActionsClose = () => {
    setActionsOpen(null);
  };

  const handleAction = (action?: Action) => {
    handleActionsClose();
    if (action?.handler) action?.handler();
  };

  const getRateClass = (move: any) => {
    const rateruleDetail = move?.details?.find((detail: any) => detail?.name === 'Transport');
    const rateruleClass = rateruleDetail?.raterule?.class.replace(/_/g, ' ') ? rateruleDetail.raterule.class.replace(/_/g, ' ') : armove?.move?.class ? armove.move.class.replace(/_/g, ' ') : null;
    return rateruleClass ? capFirst(rateruleClass) : 'N/A';
  };

  const currentRevisionNum = revisions?.length ? revisions?.[0]?.revision + 1 : 1;

  const getSelectedRevision = () => {
    if (currentRevisionNum === revisionNum) return armove || {};
    else {
      for (let i = 0; i < revisions?.length; i++) {
        if (revisions?.[i]?.revision === revisionNum) return revisions?.[i] || {};
      }
    }
    return {};
  };

  const selectedRevision = getSelectedRevision();

  React.useEffect(() => {
    if (armove) setRevisionNum(currentRevisionNum);
  }, [armove, currentRevisionNum]);

  const total = selectedRevision ? selectedRevision?.due_amount - selectedRevision?.discount_amount : 0;
  const paid = selectedRevision ? selectedRevision?.paid_amount : 0;
  const due = total - paid;

  let lineItems = selectedRevision?.details?.length
    ? selectedRevision?.details?.map((ar?: any) => {
        let name = capFirst(ar?.name);
        const notes = capFirst(ar?.notes);
        let amount = ar?.amount;
        const documents = ar?.documents;

        if (ar?.name?.includes('INVALID')) name = ' INVALID Transport Cost';
        else if (ar?.name?.includes('Transport')) name = ' Transport Cost';
        else if (!ar?.name?.includes('Promo') && !ar?.name?.includes('promo'))
          name = `Accessorial: ${capFirst(ar?.name)}`;
        else name = capFirst(ar?.name);

        if (ar?.amount < 0) amount = `(${getMonetaryValue(Math.abs(ar?.amount))})`;
        else amount = `${getMonetaryValue(ar?.amount)}`;

        return { name, notes, amount, documents };
      })
    : [];

  lineItems = lineItems.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return (
    <div className={cls.fold}>
      <div className={cls.foldLeft}>
        <MoveDetailsTimeline move={move} mb={12} showTimes={false} />

        <div className={cls.foldLeftInner}>
          <Typography sx={clsx.titleText} style={{ marginBottom: `12px` }}>
            Move Info
          </Typography>

          <div className={cls.sectionFlex}>
            <div className={cls.sectionFlexItem}>
              <InfoItem size='sm' keyText={`HopDrive ID`} valueText={move?.id ? `#${move?.id}` : `N/A`} />
              <InfoItem size='sm' keyText={`VIN`} valueText={move?.vehicle_vin || `N/A`} />
            </div>

            <div className={cls.sectionFlexItem}>
              <InfoItem size='sm' keyText={`Reference ID`} valueText={move?.reference_num || `N/A`} />
              <InfoItem size='sm' keyText={`Stock`} valueText={move?.vehicle_stock || `N/A`} />
            </div>
          </div>

          <Spacer size={0.5} />

          <InfoItem size='sm' keyText={`Vehicle`} valueText={getFormattedVehicleFromMove(move) || `N/A`} />

          <Spacer />

          <Typography sx={clsx.titleText} style={{ marginBottom: `12px` }}>
            Rate Info
          </Typography>

          <div className={cls.sectionFlex}>
            <div className={cls.sectionFlexItem}>
              <InfoItem size='sm' keyText={`Rate Class`} valueText={getRateClass(armove) || `N/A`} />
            </div>

            <div className={cls.sectionFlexItem}>
              <InfoItem
                size='sm'
                keyText={`Rate Rule`}
                valueText={
                  move?.raterule?.type
                    ? `${move?.raterule?.type === 'per' ? 'Per Mile' : capFirst(move?.raterule?.type)}`
                    : `N/A`
                }
              />
            </div>
          </div>

          <Spacer />

          <Typography sx={clsx.titleText} style={{ marginBottom: `12px` }}>
            Lane Info
          </Typography>

          <div className={cls.sectionFlex}>
            <div className={cls.sectionFlexItem}>
              <InfoItem size='sm' keyText={`Pickup Address`} valueText={pickup?.address || `N/A`} />
              <InfoItem size='sm' keyText={`Delivery Address`} valueText={delivery?.address || `N/A`} />
            </div>
          </div>
        </div>
      </div>

      <div className={cls.foldRight}>
        <div className={cls.flex}>
          <Typography sx={clsx.titleText}>Charge Details</Typography>

          <Tooltip placement='top' title={`Actions`}>
            <IconButton sx={clsx.iconBtn} onClick={handleActionsOpen}>
              <Icon>more_vert</Icon>
            </IconButton>
          </Tooltip>

          <Menu
            keepMounted
            id={`armove-actions-menu`}
            anchorEl={actionsOpen}
            open={Boolean(actionsOpen)}
            onClose={handleActionsClose}
          >
            {actions?.map((action?: Action, i?: number) =>
              !action?.hide ? (
                <MenuItem key={`armove-action-${i}`} onClick={() => handleAction(action)} disabled={action?.disabled}>
                  {action?.icon || null}
                  {action?.label || `Action ${i || 0 + 1}`}
                </MenuItem>
              ) : null
            )}
          </Menu>
        </div>

        {revisions?.length ? (
          <>
            <Spacer />

            <TextField
              select
              fullWidth
              label='Revision'
              value={revisionNum}
              onChange={event => setRevisionNum(Number(event?.target?.value))}
            >
              <MenuItem value={currentRevisionNum}>Current Revision #{currentRevisionNum}</MenuItem>

              {revisions?.map((item?: any) => (
                <MenuItem key={item?.revision} value={item?.revision}>
                  Revision #{item?.revision}
                </MenuItem>
              ))}
            </TextField>
          </>
        ) : null}

        <Spacer />

        {lineItems?.length
          ? lineItems?.map((item?: any, i?: number) => (
              <React.Fragment key={`revision-detail-${i}`}>
                <div className={cls.flex}>
                  <Typography sx={clsx.chargeNameTxt}>{capFirst(item?.name)}</Typography>
                  <Typography sx={clsx.chargeAmountTxt}>{item?.amount}</Typography>
                </div>

                <Typography sx={clsx.chargeNotesTxt}>{capFirst(item?.notes)}</Typography>

                {item?.documents?.length ? (
                  <div className={cls.docs}>
                    {item?.documents?.map((doc?: any) => (
                      <Chip
                        size='small'
                        icon={<BiFile />}
                        label={doc?.name || `Document`}
                        onClick={() => window.open(doc?.url || `/`, '_blank')}
                        sx={{
                          height: `18px`,
                          backgroundColor: theme?.palette?.info?.main,
                          color: theme?.palette?.info?.contrastText,
                          cursor: `pointer`,
                          userSelect: `none`,
                          '&:hover': {
                            backgroundColor: theme?.palette?.info?.dark,
                          },
                          '& .MuiChip-icon': {
                            fontSize: `12px`,
                            color: theme?.palette?.info?.contrastText,
                          },
                          '& .MuiChip-label': {
                            fontSize: `12px`,
                            color: theme?.palette?.info?.contrastText,
                          },
                        }}
                      />
                    ))}
                  </div>
                ) : null}

                <div className={cls.lineBreak} />
              </React.Fragment>
            ))
          : null}

        {selectedRevision?.discount_amount ? (
          <>
            <div className={cls.flex}>
              <Typography sx={clsx.chargeNameTxt}>Discount</Typography>
              <Typography sx={clsx.chargeAmountTxt}>
                (
                {selectedRevision?.discount_amount
                  ? getMonetaryValue(selectedRevision?.discount_amount)
                  : getMonetaryValue(0)}
                )
              </Typography>
            </div>

            <Typography sx={clsx.chargeNotesTxt}>{capFirst(selectedRevision?.discount_reason)}</Typography>

            <div className={cls.lineBreak} />
          </>
        ) : null}

        {lineItems?.length || selectedRevision?.discount_amount ? <Spacer size='xs' /> : null}

        <div className={cls.totals}>
          <div className={cls.totalsLeft}>
            {selectedRevision?.disputed ? (
              <div className={cls.notesBox}>
                <Typography sx={clsx.notesKeyTxt}>Disputed Reason</Typography>
                <Typography sx={clsx.notesValTxt}>{capFirst(selectedRevision?.dispute_reason)}</Typography>
              </div>
            ) : null}
          </div>

          <div className={cls.totalsRight}>
            <div className={cls.flex}>
              <Typography sx={clsx.totalsKeyTxt}>Status:</Typography>
              <Typography sx={clsx.totalsValTxt}>{due <= 0 ? `Paid` : paid <= 0 ? `Unpaid` : `Partial`}</Typography>
            </div>

            <Spacer size={1} />

            <div className={cls.flex}>
              <Typography sx={clsx.totalsKeyTxt}>Total:</Typography>
              <Typography sx={clsx.totalsValTxt}>{getMonetaryValue(clampNegNum(total))}</Typography>
            </div>

            <div className={cls.flex}>
              <Typography sx={clsx.totalsKeyTxt}>Amount Paid:</Typography>
              <Typography sx={clsx.totalsValTxt}>{getMonetaryValue(clampNegNum(paid))}</Typography>
            </div>

            <div className={cls.flex}>
              <Typography sx={clsx.totalsKeyTxt}>Amount Due:</Typography>
              <Typography sx={clsx.totalsValTxt}>{getMonetaryValue(clampNegNum(due))}</Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  fold: css`
    position: relative;
    display: flex;
  `,
  foldLeft: css`
    flex: 2;
    padding: 18px 12px 12px 12px;
    border-right: 1px solid ${theme?.palette?.divider};
    background-color: ${theme?.palette?.background?.default};
  `,
  foldLeftInner: css`
    padding: 6px;
  `,
  foldRight: css`
    flex: 0 1 360px;
    padding: 18px;
    background-color: ${theme?.palette?.background?.alt};
  `,

  sectionFlex: css`
    display: flex;
    gap: 20px;
  `,
  sectionFlexItem: css`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;
    word-break: break-word;
  `,

  flex: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  `,

  lineBreak: css`
    border-bottom: 1px solid ${theme?.palette?.divider};
    margin: 4px 0;
  `,

  docs: css`
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 4px;
  `,

  totals: css`
    display: flex;
    gap: 12px;
  `,
  totalsLeft: css`
    flex: 1 1;
  `,
  totalsRight: css`
    flex: 0 1 160px;
  `,

  notesBox: css`
    padding: 6px;
    border-radius: ${theme?.shape?.borderRadius}px;
    background-color: ${theme?.palette?.action?.selected};
  `,
});

const useSxStyles = (theme?: Theme) => ({
  titleText: {
    lineHeight: 1,
    fontSize: `16px`,
    fontWeight: `600`,
  },

  iconBtn: {
    padding: theme?.spacing(0.5),
  },

  chargeNameTxt: {
    fontSize: `14px`,
    fontWeight: 500,
  },
  chargeNotesTxt: {
    fontSize: `12px`,
    color: theme?.palette?.text?.secondary,
  },
  chargeAmountTxt: {
    fontSize: `14px`,
    fontWeight: 600,
  },

  notesKeyTxt: {
    fontSize: `12px`,
    fontWeight: 500,
  },
  notesValTxt: {
    fontSize: `12px`,
    color: theme?.palette?.text?.secondary,
  },
  totalsKeyTxt: {
    fontSize: `12px`,
  },
  totalsValTxt: {
    fontSize: `14px`,
    fontWeight: 600,
  },
});

// EXPORTS ---------------------------------------------------------------- //

export default InvoiceDetailsTableFold;
