import React from 'react';
import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/material/styles';
import useStyles from './Success.styles';

const Success: React.FC = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return <Icon className={styles.icon}>check_circle</Icon>;
};

export default Success;
