import React from 'react';
import { Dialog } from '@mui/material';
import { useTheme, Theme, SxProps, Box} from '@mui/material';

interface ModalProps {
  className?: string;
  open: boolean;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  handleClose?: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ open, width = 'sm', handleClose, children }) => {
  const theme = useTheme();
  const sxStyles = useSxStyles(theme);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (handleClose) handleClose();
      }}
      maxWidth={width}
      PaperProps={{ sx: { ...sxStyles.root} }}
      disableEscapeKeyDown
      scroll='body'
    >
      <Box sx={sxStyles.main}>{children}</Box>
    </Dialog>
  );
};

export default Modal;

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  root: {
    width: '100%',
    margin: theme.spacing(2),
  },
  main: {
    position: 'relative',
    textAlign: 'left',
  },
});