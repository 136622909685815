// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import ReactToPrint from 'react-to-print';
import dayjs from 'dayjs';

import { Moves } from '@gql/schema';
import { getCarouselImagesArray } from '@services/vehicleImagesService';

import { css } from '@emotion/css';
import { Theme, useTheme, Tooltip, Button, Typography } from '@mui/material';
import { BiDownload } from 'react-icons/bi';
import Divider from '@components/Divider';
import MoveStatusTracker from '@components/MoveStatusTracker';

import MoveDetailsInfo from '@features/moves/components/MoveDetailsInfo';
import MoveDetailsTimeline from '@features/moves/components/MoveDetailsTimeline';
import MoveDetailsLocation from '@features/moves/components/MoveDetailsLocation';

// TYPES ---------------------------------------------------------------- //

interface MoveDetailsToPrint {
  move: Moves;
}

// COMPONENT ---------------------------------------------------------------- //

const MoveDetailsToPrint = ({ move }: MoveDetailsToPrint) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const componentRef = React.useRef();

  const pickupImages = getCarouselImagesArray({ move, type: `pickup` });
  const deliveryImages = getCarouselImagesArray({ move, type: `delivery` });

  const ComponentToPrint = React.forwardRef((_props, ref) => (
    <div ref={ref} className={cls.root}>
      <div className={cls.page}>
        <div className={cls.preventPageBreak}>
          <div style={{ marginBottom: `24px` }}>
            <MoveStatusTracker move={move} size='lg' />
          </div>
        </div>

        <div className={cls.preventPageBreak}>
          <MoveDetailsInfo move={move} isPrinting />
        </div>

        <div className={cls.preventPageBreak}>
          <Divider mb={6} spacer>
            Pickup & Delivery
          </Divider>
          <MoveDetailsTimeline move={move} />
          <div className={cls.flexLocation}>
            <div className={cls.flexLocationItem}>
              <MoveDetailsLocation move={move} type='pickup' isPrinting />
            </div>
            <div className={cls.flexLocationItem}>
              <MoveDetailsLocation move={move} type='delivery' isPrinting />
            </div>
          </div>
        </div>

        {pickupImages?.length ? (
          <div className={cls.preventPageBreak}>
            <Divider mb={6} spacer>
              Pickup Images
            </Divider>
            <div className={cls.flexImages}>
              {pickupImages?.map((image, i) => (
                <div key={`pdf-pickup-image-${i}`} className={cls.flexImagesItem}>
                  <img className={cls.image} src={image?.original} alt='' />
                  <Typography className={cls.label}>{image?.label}</Typography>
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {deliveryImages?.length ? (
          <div className={cls.preventPageBreak}>
            <Divider mb={6} spacer>
              Delivery Images
            </Divider>
            <div className={cls.flexImages}>
              {deliveryImages?.map((image, i) => (
                <div key={`pdf-delivery-image-${i}`} className={cls.flexImagesItem}>
                  <img className={cls.image} src={image?.original} alt='' />
                  <Typography className={cls.label}>{image?.label}</Typography>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  ));

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <Tooltip title='Print or save move details as a PDF' placement='top'>
            <Button color='utility' startIcon={<BiDownload />}>
              Download PDF
            </Button>
          </Tooltip>
        )}
        content={() => componentRef.current}
        documentTitle={`move_details_${move.id}_${dayjs().format()}`}
      />

      <div style={{ display: `none` }}>
        <ComponentToPrint ref={componentRef} />
      </div>
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  root: css`
    position: relative;
    width: 100%;
    height: 100%;
  `,
  page: css`
    position: relative;
    width: 100%;
    height: 100%;
    padding: ${theme?.spacing(2)};
  `,
  preventPageBreak: css`
    @media print {
      clear: both;
      page-break-inside: avoid;
    }
  `,
  image: css`
    max-width: 188px;
    max-height: 141px;
  `,
  label: css`
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    color: ${theme?.palette?.text?.secondary};
  `,

  flexLocation: css`
    display: flex;
    gap: 12px;
  `,
  flexLocationItem: css`
    flex: 1;
  `,

  flexImages: css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;
  `,
  flexImagesItem: css``,
});

// EXPORT ---------------------------------------------------------------- //

export default MoveDetailsToPrint;
