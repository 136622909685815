// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { Lanes, Raterules, useGetMovePlannerRateruleLazyQuery } from '@gql/schema';

import { css } from '@emotion/css';
import { Theme, useTheme, Collapse, LinearProgress, Typography, Alert } from '@mui/material';

// TYPES ---------------------------------------------------------------- //

interface MovePlannerLaneEstimatesProps {
  showDistanceEstimates?: boolean;
  showPriceEstimates?: boolean;
  rooftopId?: number;
  type?: string;
  lane?: Lanes | null;
  workflowsetId?: number;
}

// COMPONENT ---------------------------------------------------------------- //

const MovePlannerLaneEstimates = ({
  showDistanceEstimates,
  showPriceEstimates,
  rooftopId,
  type,
  lane,
  workflowsetId,
}: MovePlannerLaneEstimatesProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const [showEstimates, setShowEstimates] = React.useState(false);
  const [distance, setDistance] = React.useState(0);
  const [duration, setDuration] = React.useState(0);
  const [rateText, setRateText] = React.useState(``);
  const [price, setPrice] = React.useState(0);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [errorText, setErrorText] = React.useState<string>(``);

  const [getRaterule] = useGetMovePlannerRateruleLazyQuery();

  /** Fetch the lane estimates */
  const fetchEstimates = async (existingRaterule?: Raterules) => {
    setLoading(true);

    // Get the distance, duration and move class
    const distance = lane?.distance_miles || 0;
    const duration = (lane?.duration_sec || 0) / 60;
    const moveClass = type === `round-trip` ? `base` : `stranded`;

    // Initialize the raterule
    let raterule: Raterules | null = existingRaterule || null;

    //check if existing raterule is from the workflowset
    const existingRateruleWorkflowsetMatch = existingRaterule?.raterulegroup?.workflowset_id === workflowsetId
    // If theres no raterule, fetch it
    try {
      if (!existingRaterule || !existingRateruleWorkflowsetMatch) {
        const res = await getRaterule({ variables: { rooftopId: rooftopId || 0, distance: distance, class: moveClass, workflowsetId: workflowsetId || 0 } });
        const resRaterule = res.data?.raterules?.[0] || null;
        if (resRaterule) raterule = resRaterule as Raterules;
      }
    } catch (err) {
      console.error(`Error fetching raterule:`, err);
      setError(true);
      setErrorText(`Failed to fetch lane estimates. If this problem persists, please contact us.`);
    }

    const generateRateText = (raterule: Raterules | null) => {
      if(raterule?.type === `flatPlusPer`) return `$${raterule?.flat_rate?.toFixed(2)} Flat + $${raterule?.rate?.toFixed(2)} / mi`;
      else if(raterule?.type === `per`) return `$${raterule?.rate?.toFixed(2)} / mi`;
      else if(raterule?.type === `flat`) return `$${raterule?.flat_rate?.toFixed(2)} Flat`;
      else return `$0.00 Flat`;
    }

    // Calculate the estimates and set state
    setDistance(distance);
    setDuration(duration);
    setRateText(generateRateText(raterule));
    const startDistance = raterule?.distance_start || 0;
    if (raterule?.type === `per`) setPrice((raterule?.rate || 0) * distance);
    else if (raterule?.type === `flat`) setPrice(raterule?.flat_rate || 0);
    else if (raterule?.type === `flatPlusPer`) setPrice((raterule?.flat_rate || 0) + ((raterule?.rate || 0) * (distance - startDistance)));
    else setPrice(0);

    setLoading(false);
  };

  React.useEffect(() => {
    setError(false);
    setErrorText(``);

    if (lane?.pickup && lane?.delivery && (showDistanceEstimates || showPriceEstimates)) {
      setShowEstimates(true);
      fetchEstimates();
    } else setShowEstimates(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, lane, showDistanceEstimates, showPriceEstimates, workflowsetId]);

  return (
    <Collapse in={showEstimates && !loading}>
      <>
        <Typography
          variant='body2'
          sx={{
            marginTop: `18px`,
            marginBottom: `6px`,
            textAlign: `center`,
            fontWeight: 600,
            color: theme?.palette?.text?.disabled,
            userSelect: `none`,
          }}
        >
          LANE ESTIMATES
        </Typography>

        {error && errorText ? (
          <Typography
            variant='body1'
            sx={{ marginBottom: `6px`, textAlign: `center`, color: theme?.palette?.error?.main }}
          >
            {errorText}
          </Typography>
        ) : loading ? (
          <LinearProgress />
        ) : (
          <div className={cls.estimates}>
            {showDistanceEstimates ? (
              <>
                <div className={cls.estimatesItem}>
                  <Typography
                    variant='body2'
                    sx={{ fontSize: `10px`, fontWeight: 600, color: theme?.palette?.text?.disabled }}
                  >
                    DISTANCE
                  </Typography>
                  <Typography variant='body1' sx={{ marginTop: `4px`, fontWeight: 600 }}>
                    {distance?.toFixed(1)} mi
                  </Typography>
                </div>

                <div className={cls.estimatesItem}>
                  <Typography
                    variant='body2'
                    sx={{ fontSize: `10px`, fontWeight: 600, color: theme?.palette?.text?.disabled }}
                  >
                    DURATION
                  </Typography>
                  <Typography variant='body1' sx={{ marginTop: `4px`, fontWeight: 600 }}>
                    {duration?.toFixed(0)} min
                  </Typography>
                </div>
              </>
            ) : null}

            {showDistanceEstimates && showPriceEstimates ? (
              <div className={cls.estimatesItem}>
                <Typography
                  variant='body2'
                  sx={{ fontSize: `10px`, fontWeight: 600, color: theme?.palette?.text?.disabled }}
                >
                  RATE
                </Typography>
                <Typography variant='body1' sx={{ marginTop: `4px`, fontWeight: 600 }}>
                  {rateText}
                </Typography>
              </div>
            ) : null}

            {showPriceEstimates ? (
              <>
                <div className={cls.estimatesItem}>
                  <Typography
                    variant='body2'
                    sx={{ fontSize: `10px`, fontWeight: 600, color: theme?.palette?.text?.disabled }}
                  >
                    PRICE
                  </Typography>
                  <Typography variant='body1' sx={{ marginTop: `4px`, fontWeight: 600 }}>
                    ${price?.toFixed(2)}
                    {type === `round-trip` ? ` x2` : ``}
                  </Typography>
                </div>
                <Alert severity='info' style={{ width: 'fit-content' }}>
                  Price estimates are based on vehicle weight classes 1 & 2. The price will increase for class 3
                  vehicles and above.
                </Alert>
              </>
            ) : null}
          </div>
        )}
      </>
    </Collapse>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  return {
    estimates: css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 12px;
      width: 100%;
      margin-bottom: 6px;
    `,
    estimatesItem: css`
      flex: 1;
      display: flex;
      flex-direction: column;
      min-width: 128px;
      max-width: 256px;
      padding: 12px;
      border: 1px solid ${theme?.palette?.divider};
      border-radius: 10px;
      text-align: center;
      background-color: ${theme?.palette?.action?.hover};
    `,
  };
};

// EXPORT ---------------------------------------------------------------- //

export default MovePlannerLaneEstimates;
