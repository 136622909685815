import React from 'react';
import {
  OutlinedInput,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
  SelectChangeEvent,
} from '@mui/material';

interface MultiselectItem {
  id: string | null | undefined;
  name: string | null | undefined;
}

interface ReusableMultiselectProps {
  label: string;
  dataArray: MultiselectItem[];
  selected: MultiselectItem[];
  handleChange: (name: string | null, arr?: MultiselectItem[]) => void;
}

const ReusableMultiselect = ({ label = '', dataArray, selected, handleChange }: ReusableMultiselectProps) => {
  const [selectedItems, setSelectedItems] = React.useState<MultiselectItem[]>(selected);

  const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    const updatedSelected = dataArray.filter(item => value.includes(item.id ?? ''));
    setSelectedItems(updatedSelected);
    handleChange(label.toLowerCase(), updatedSelected);
  };

  React.useEffect(() => {
    setSelectedItems(selected);
  }, [selected]);

  // React.useEffect(() => {
  //   console.log('selectedItems', selectedItems)
  //   handleChange(label.toLowerCase(), selectedItems);
  // }, [selectedItems]);

  return (
    <div>
      <Select
        fullWidth
        multiple
        value={selectedItems.map(item => item.id ?? '')}
        onChange={handleSelectChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <>{label}</>;  // Show label as placeholder
          }
          return (selected as string[]).map(id => dataArray.find(item => item.id === id)?.name).join(', ');
        }}
        displayEmpty
      >
        {dataArray.map((item: MultiselectItem) => (
          <MenuItem key={item.id ?? ''} value={item.id ?? ''} component='li'>
            <Checkbox checked={selectedItems.findIndex(selectedItem => selectedItem.id === item.id) >= 0} />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default ReusableMultiselect;
