import { toast } from 'react-toastify';
import { capFirst } from '@services/formatService';

interface FilterItems {
  id: string | null | undefined;
  name: string | null | undefined;
}

export const getLastNDigits = (str: string | null, n = 8) => {
  if (str && typeof str === `string` && str.length > n) return str.slice(-n);
  return str;
};

export const getReadableText = (str: string | null, nbsp = false) => {
  if (str) {
    let newStrArray = str.split(/[.\s_-]/g);
    newStrArray = newStrArray.map(val => capFirst(val).trim());
    return newStrArray.join(nbsp ? `\xa0` : ` `);
  }
  return str;
};

export const getSnakeCase = (str: string | null, upperCase = false) => {
  if (str) {
    let newStrArray = str.split(/[,.\s-]/g);
    newStrArray = newStrArray.map(val => val.trim());
    newStrArray = newStrArray.join(`_`);
    return upperCase ? newStrArray.toUpperCase() : newStrArray.toLowerCase();
  }
  return str;
};

export const getNonBreakingWord = (str: string | null) => {
  if (str) {
    let newStr = str.replace(/ /g, '\xa0');
    return newStr;
  }
  return str;
};

/** Generate a list of private step ids from a workflowset */
export const getPrivateStepIds = (workflowset = null) => {
  /** Recursively find and build an array of private workflow steps */
  const findPrivateSteps = (privateSteps, stepsToSearch) => {
    if (!stepsToSearch || !stepsToSearch.length) return privateSteps;
    for (let i = 0; i < stepsToSearch.length; i++) {
      const currentStep = stepsToSearch[i];
      if (currentStep.config && currentStep.config.private) privateSteps.push(currentStep);
      if (currentStep.steps && currentStep.steps.length) {
        privateSteps = findPrivateSteps(privateSteps, currentStep.steps);
      }
    }
    return privateSteps;
  };

  // If we don't have a workflowset, return an empty array
  if (!workflowset) return [];

  // Initialize an array to hold private steps
  let privateSteps = [];

  // Dig through pickup workflow
  if (workflowset.pickupWorkflow && workflowset.pickupWorkflow.steps && workflowset.pickupWorkflow.steps.length) {
    privateSteps = findPrivateSteps(privateSteps, workflowset.pickupWorkflow.steps);
  }

  // Dig through delivery workflow
  if (
    workflowset.deliveryWorkflow &&
    workflowset.deliveryWorkflow.steps &&
    workflowset.deliveryWorkflow.steps.length
  ) {
    privateSteps = findPrivateSteps(privateSteps, workflowset.deliveryWorkflow.steps);
  }

  // Dig through fuel workflow
  if (workflowset.fuelWorkflow && workflowset.fuelWorkflow.steps && workflowset.fuelWorkflow.steps.length) {
    privateSteps = findPrivateSteps(privateSteps, workflowset.fuelWorkflow.steps);
  }

  // Once we have all the private steps, return an array of their ids
  return privateSteps.map(step => step.id);
};

/** Parse move workflow data */
export const getWorkflowData = (type, workflowData, format = `csv`, keyValue = false, workflowset = null) => {
  // Set the type string (pickup, delivery or extra)
  const typeStr = type ? `${type}_` : `extra_`;

  // Check for workflow data and parse it
  if (workflowData && typeof workflowData === `object`) {
    // Initialize editable workflow data
    let editedWorkflowData = { ...workflowData };

    // Remove private steps from workflow data
    if (workflowset) {
      const privateStepIds = getPrivateStepIds(workflowset);
      if (privateStepIds && privateStepIds.length) {
        Object.keys(editedWorkflowData).forEach((id, i) => {
          if (privateStepIds.includes(id)) {
            delete editedWorkflowData[id];
          }
        });
      }
    }

    // Split out the workflow data keys and values
    const workflowDataKeys = editedWorkflowData ? Object.keys(editedWorkflowData) : [];
    const workflowDataVals = workflowDataKeys.map(key => editedWorkflowData[key]) || [];

    // Check for keyValue bool and return the data in the specified format
    if (keyValue) {
      const workflowData = workflowDataKeys.map((key, i) => {
        let formattedKey = key;
        if (format === `csv`) formattedKey = typeStr + getSnakeCase(key);
        if (format === `move_details`) formattedKey = getReadableText(key, true);
        return { index: i, key: formattedKey, val: workflowDataVals[i] };
      });

      return workflowData || [];
    } else {
      let workflowData = {};
      workflowDataKeys.forEach((key, i) => {
        let formattedKey = key;
        if (format === `csv`) formattedKey = typeStr + getSnakeCase(key);
        if (format === `move_details`) formattedKey = getReadableText(key, true);
        workflowData[formattedKey] = workflowDataVals[i];
      });

      return workflowData || {};
    }
  }
  return null;
};

export const getCancelHeaderFromMove = (move: any | null) => {
  const cancelStatus = move?.cancel_status || null;

  if (cancelStatus === `pending` || cancelStatus === `seen`) return `CANCEL REQUESTED`;
  return `CANCELED`;
};

export const getCancelReasonFromMove = (move: any | null) => {
  const cancelStatus = move?.cancel_status || null;
  const cancelReason = move?.cancel_reason || null;

  if (cancelReason) return `${cancelReason}.`;
  if (cancelStatus === `pending` || cancelStatus === `seen`) return `Cancel requested by user.`;
  if (cancelStatus === `delivered`) return `Canceled after vehicle was delivered.`;
  return `Cancel reason not provided.`;
};

export const copyToClipboard = (str: string | null) => {
  try {
    if (str) {
      navigator.clipboard.writeText(str);
      toast.info(`Copied Text - '${str}'`, { autoClose: 2000 });
    } else toast.warning(`No text was found to copy!`);
  } catch (err) {
    toast.error(`Failed to copy text!`);
    console.error(`Failed to copy text:`, err);
  }
};

export const circularJSONStringify = (json: any, spaces = 2, hiddenProperties: string[] = []) => {
  let cache: any[] | null = [];
  let formattedJson = '';
  try {
    formattedJson = JSON.stringify(
      json,
      (key, value) => {
        if (typeof value === 'object' && value !== null) {
          if (cache.includes(value)) return;
          cache.push(value);
        }
        return hiddenProperties.includes(key) ? 'hidden by circularStringify()' : value;
      },
      spaces
    );
  } catch (error) {
    console.error(`Failed doing JSON.stringify() on circular object: ${error.message}`, json, error);
  } finally {
    cache = null;
    return formattedJson;
  }
};

export const getFirstNameFromDisplayName = (displayName: string | null) => {
  if (displayName) {
    const splitArr = displayName.split(` `);
    if (splitArr.length) {
      const firstName = splitArr[0];
      return firstName;
    }
  }
  return null;
};

export const getMiddleNameFromDisplayName = (displayName: string | null) => {
  if (displayName) {
    const splitArr = displayName.split(` `);
    if (splitArr.length > 2) {
      const middleName = splitArr[1];
      return middleName;
    }
  }
  return null;
};

export const getLastNameFromDisplayName = (displayName: string | null) => {
  if (displayName) {
    const splitArr = displayName.split(` `);
    if (splitArr.length > 1) {
      const lastName = splitArr[splitArr.length - 1];
      return lastName;
    }
  }
  return null;
};

export const getUserName = (obj: any | null) => {
  if (obj) {
    if (obj?.name) return obj.name;
    if (obj?.driver_name) return obj.driver_name;
    if (obj?.display_name) return obj.display_name;
    if (obj?.driver?.name) return obj.driver.name;
    if (obj?.driver?.driver_name) return obj.driver.driver_name;
    if (obj?.driver?.display_name) return obj.driver.display_name;
    if (obj?.user?.name) return obj.user.name;
    if (obj?.user_name) return obj.user_name;
    if (obj?.user?.driver_name) return obj.user.driver_name;
    if (obj?.user?.display_name) return obj.user.display_name;
    if (obj?.driver?.user?.name) return obj.driver.user.name;
    if (obj?.driver?.user?.driver_name) return obj.driver.user.driver_name;
    if (obj?.driver?.user?.display_name) return obj.driver.user.display_name;
  }
  return null;
};

export const getInitialsFromName = (obj: any | null) => {
  const fallbackInitials = `N/A`;

  if (obj) {
    const fullName = getUserName(obj) || fallbackInitials;

    if (fullName !== fallbackInitials) {
      const firstName = getFirstNameFromDisplayName(fullName);
      const middleName = getMiddleNameFromDisplayName(fullName);
      const lastName = getLastNameFromDisplayName(fullName);

      const firstI = firstName ? firstName[0] : ``;
      const middleI = middleName ? middleName[0] : ``;
      const lastI = lastName ? lastName[0] : ``;

      const initials = `${firstI}${middleI}${lastI}`.toUpperCase();
      if (initials && initials !== ``) return initials;
    }
  }
  return fallbackInitials;
};

export const getSelectionsArray = (prevSelections: string[], selections: FilterItems[]) => {
  if (!Array.isArray(selections)) return prevSelections;
  
  const newSelections = selections
    .filter(selection => selection.id !== null && selection.id !== undefined)
    .map(selection => selection.id as string)
    .filter(selectionId => !prevSelections.includes(selectionId));

  // Remove selections from prevSelections that are not present in selections
  const updatedSelections = prevSelections.filter(selection => 
    selections.some(s => s.id === selection)
  );

  return [...updatedSelections, ...newSelections];
};

export const getPropValue = (obj: object, key: string) => key.split('.').reduce((o, x) => (o == undefined ? o : o[x]), obj);

export const getComparator = (order: string, orderBy: string) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array: object[], comparator: any) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export const descendingComparator = (a: any, b: any, orderBy: string) => {
  // Handle null/undefined values to appear first
  if (!a[orderBy] && b[orderBy]) return -1;
  if (a[orderBy] && !b[orderBy]) return 1;
  if (!a[orderBy] && !b[orderBy]) return 0;

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};
