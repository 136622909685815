// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';
import dayjs from 'dayjs';

import { getPickupOrDeliveryTimeFromMove } from '@services/moveService';
import { getMinutesBetween } from '@services/timeService';

import { Moves } from '@gql/schema';

import { css } from '@emotion/css';
import { Theme, useTheme, Tooltip, Typography } from '@mui/material';

// TYPES ---------------------------------------------------------------- //

interface MoveDetailsTimelineProps {
  move?: Moves;
  mt?: number;
  mb?: number;
  showTimes?: boolean;
}

// COMPONENT ---------------------------------------------------------------- //

const MoveDetailsTimeline = ({ move, mt, mb, showTimes = true }: MoveDetailsTimelineProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const formatPickupArrived = dayjs(move?.pickup_arrived).format(`h:mm A`);
  const formatPickupSuccessful = dayjs(move?.pickup_successful).format(`h:mm A`);
  const formatDeliveryStarted = dayjs(move?.delivery_started).format(`h:mm A`);
  const formatDeliveryArrived = dayjs(move?.delivery_arrived).format(`h:mm A`);
  const formatDeliverySuccessful = dayjs(move?.delivery_successful).format(`h:mm A`);

  const pickupTime = getMinutesBetween(move?.pickup_arrived, move?.delivery_started);
  const driveTime = getMinutesBetween(move?.delivery_started, move?.delivery_arrived);
  const deliveryTime = getMinutesBetween(move?.delivery_arrived, move?.delivery_successful);

  if (!move?.pickup_arrived || !move?.delivery_started || !move?.delivery_arrived || !move?.delivery_successful) {
    return null;
  }

  return (
    <div className={cls.timeline} style={{ marginTop: mt || undefined, marginBottom: mb || undefined }}>
      {showTimes ? (
        <Tooltip title='Move Start Time'>
          <div className={cls.timeText}>
            <Typography variant='body2' sx={{ fontWeight: 600 }}>
              {getPickupOrDeliveryTimeFromMove(`pickup`, move, `M/D/YY`)}
            </Typography>

            <Typography variant='body2' sx={{ whiteSpace: `nowrap`, fontWeight: 600 }}>
              {getPickupOrDeliveryTimeFromMove(`pickup`, move, `h:mm A`)}
            </Typography>
          </div>
        </Tooltip>
      ) : null}

      <div className={cls.timeFlex}>
        {pickupTime ? (
          <Tooltip
            title={`Pickup - ${
              pickupTime ? `${pickupTime} minutes (${formatPickupArrived} to ${formatPickupSuccessful})` : `0m`
            }`}
          >
            <div className={cls.pickupTime}>
              <Typography
                variant='body2'
                sx={{
                  lineHeight: 1,
                  textAlign: `center`,
                  fontSize: `12px`,
                  fontWeight: 700,
                  color: theme?.palette?.text?.contrastPrimary,
                }}
              >
                Pickup {pickupTime ? `${pickupTime}m` : `0m`}
              </Typography>
            </div>
          </Tooltip>
        ) : null}

        {driveTime ? (
          <Tooltip
            title={`Drive - ${
              driveTime ? `${driveTime} minutes (${formatDeliveryStarted} to ${formatDeliveryArrived})` : `0m`
            }`}
          >
            <div className={cls.driveTime}>
              <Typography
                variant='body2'
                sx={{
                  lineHeight: 1,
                  textAlign: `center`,
                  fontSize: `12px`,
                  fontWeight: 700,
                  color: theme?.palette?.text?.contrastPrimary,
                }}
              >
                Drive {driveTime ? `${driveTime}m` : `0m`}
              </Typography>
            </div>
          </Tooltip>
        ) : null}

        {deliveryTime ? (
          <Tooltip
            title={`Delivery - ${
              deliveryTime ? `${deliveryTime} minutes (${formatDeliveryArrived} to ${formatDeliverySuccessful})` : `0m`
            }`}
          >
            <div className={cls.deliveryTime}>
              <Typography
                variant='body2'
                sx={{
                  lineHeight: 1,
                  textAlign: `center`,
                  fontSize: `12px`,
                  fontWeight: 700,
                  color: theme?.palette?.text?.contrastPrimary,
                }}
              >
                Delivery {deliveryTime ? `${deliveryTime}m` : `0m`}
              </Typography>
            </div>
          </Tooltip>
        ) : null}
      </div>

      {showTimes ? (
        <Tooltip title='Move End Time'>
          <div className={cls.timeText}>
            <Typography variant='body2' sx={{ fontWeight: 600 }}>
              {getPickupOrDeliveryTimeFromMove(`delivery`, move, `M/D/YY`)}
            </Typography>

            <Typography variant='body2' sx={{ whiteSpace: `nowrap`, fontWeight: 600 }}>
              {getPickupOrDeliveryTimeFromMove(`delivery`, move, `h:mm A`)}
            </Typography>
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  timeline: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 18px;
  `,

  timeText: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,

  timeFlex: css`
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 20px;
    cursor: pointer;
  `,
  pickupTime: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 33.333;
    height: 20px;
    padding: 0 4px;
    background: ${theme?.palette?.secondary?.main};
  `,
  driveTime: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 33.333;
    height: 20px;
    padding: 0 4px;
    background: ${theme?.palette?.primary?.main};
  `,
  deliveryTime: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 33.333;
    height: 20px;
    padding: 0 4px;
    background: ${theme?.palette?.tertiary?.main};
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default MoveDetailsTimeline;
