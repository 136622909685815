// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';

import { css } from '@emotion/css';
import { Grid2 as Grid, Tooltip, Theme, useTheme } from '@mui/material';

import { useTools } from '@hooks/useTools';
import { Hangtags } from '@gql/schema';

// TYPES ---------------------------------------------------------------- //

interface MoveTileHangtagTooltipProps {
  children: JSX.Element;
  hangtag: Hangtags;
}

// COMPONENT ---------------------------------------------------------------- //

export default function MoveTileHangtagTooltip({ children, hangtag }: MoveTileHangtagTooltipProps) {
  const theme = useTheme();
  const cls = useStyles(theme);

  const { getFormattedStatusFromHangtag, getReadableText } = useTools();

  return (
    <Tooltip
      sx={{ width: 'fit-content' }}
      title={
        <div className={cls.ttBox}>
          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid sx={{ fontWeight: 900 }}>Hangtag Status:</Grid>
            <Grid sx={{ fontWeight: 900 }}>{(hangtag && getFormattedStatusFromHangtag(hangtag)) || `-`}</Grid>
          </Grid>

          <hr className={cls.ttDivider} />

          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid>Rear Code:</Grid>
            <Grid>{hangtag?.rear_code || `-`}</Grid>
          </Grid>

          <Grid container justifyContent='space-between' alignItems='flex-start'>
            <Grid>Hash Code:</Grid>
            <Grid>{hangtag?.hash_id || `-`}</Grid>
          </Grid>

          {hangtag?.workflow_data && Object.keys(hangtag?.workflow_data).length ? (
            <>
              <hr className={cls.ttDivider} />

              {Object.entries(hangtag?.workflow_data).map(([key, value]) => (
                <Grid key={`hangtag-workflow-${key}`} container justifyContent='space-between' alignItems='flex-start'>
                  <Grid>{getReadableText(key)}:</Grid>
                  <Grid>{value !== null ? value : '-'}</Grid>
                </Grid>
              ))}
            </>
          ) : null}
        </div>
      }
      placement='bottom'
    >
      {children}
    </Tooltip>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme: Theme) => ({
  ttBox: css`
    display: flex;
    flex-direction: column;
    width: 192px;
  `,
  ttDivider: css`
    width: 100%;
    opacity: 0.5;
  `,
});
