// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { Arpaymentmethods } from '@gql/schema';
import { getMonetaryValue } from '@services/formatService';

import { css } from '@emotion/css';
import { Grid2 as Grid, Link, MenuItem, TextField, Theme, Typography, useTheme } from '@mui/material';

import Spacer from '@components/Utils/Spacer';

// TYPES ---------------------------------------------------------------- //

type PayNowContentProps = {
  input?: any;
  paymentMethods?: Arpaymentmethods[];
  paymentMethod?: Arpaymentmethods;
  onPaymentMethodChange: (method?: Arpaymentmethods) => void;
};

// COMPONENT ---------------------------------------------------------------- //

const PayNowContent = ({ input, paymentMethods, paymentMethod, onPaymentMethodChange }: PayNowContentProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);
  const clsx = useSxStyles(theme);

  const processingFee = input?.due ? input?.due * 0.03 : 0;

  const handleMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event?.target?.value);
    const foundMethod = paymentMethods?.find(m => m?.id === value);
    if (onPaymentMethodChange) onPaymentMethodChange(foundMethod || undefined);
  };

  return (
    <>
      {paymentMethods?.length ? (
        <>
          <Spacer size={1} />

          <TextField
            fullWidth
            required
            select
            label='Payment Method'
            helperText={
              paymentMethod?.type === `card` ? `NOTE: A processing fee will be included when using a card.` : ``
            }
            margin='none'
            variant='outlined'
            value={paymentMethod?.id || ``}
            onChange={handleMethodChange}
          >
            <MenuItem key={'Select Payment Method'} value={``}>
              <em>Select Payment Method</em>
            </MenuItem>

            {paymentMethods?.map(m => (
              <MenuItem key={m?.id} value={m?.id}>
                {m.name}
                {m.primary === true ? ` (Primary)` : null}
              </MenuItem>
            ))}
          </TextField>

          <Spacer />

          <Typography sx={{ fontSize: `12px` }}>
            <Link href='/billing-preferences'>Manage Payment Methods</Link>
          </Typography>

          <Spacer size={3} />
        </>
      ) : (
        <>
          <Typography sx={{ fontSize: `12px` }}>
            <Link href='/billing-preferences'>Enter a Payment Method to pay Invoice</Link>
          </Typography>

          <Spacer size={3} />
        </>
      )}

      <Grid container>
        {Number(input?.subtotal) && (Number(input?.discounted) || Number(input?.disputed)) ? (
          <>
            <Grid size={{ xs: 6 }}>
              <Typography sx={clsx.keyText}>Subtotal</Typography>
            </Grid>

            <Grid size={{ xs: 6 }}>
              <Typography sx={clsx.valText}>{getMonetaryValue(Number(input?.subtotal))}</Typography>
            </Grid>

            {Number(input?.discounted) ? (
              <>
                <Grid size={{ xs: 6 }}>
                  <Typography sx={clsx.keyText}>Discounted</Typography>
                </Grid>

                <Grid size={{ xs: 6 }}>
                  <Typography sx={clsx.valText}>({getMonetaryValue(Number(input?.discounted))})</Typography>
                </Grid>
              </>
            ) : null}

            {Number(input?.disputed) ? (
              <>
                <Grid size={{ xs: 6 }}>
                  <Typography sx={clsx.keyText}>Disputed</Typography>
                </Grid>

                <Grid size={{ xs: 6 }}>
                  <Typography sx={clsx.valText}>({getMonetaryValue(Number(input?.disputed))})</Typography>
                </Grid>
              </>
            ) : null}

            <div className={cls.lineBreak} />
          </>
        ) : null}

        {input?.total || input?.total === 0 ? (
          <>
            <Grid size={{ xs: 6 }}>
              <Typography sx={clsx.keyDemiText}>Total</Typography>
            </Grid>

            <Grid size={{ xs: 6 }}>
              <Typography sx={clsx.valDemiText}>{getMonetaryValue(input?.total)}</Typography>
            </Grid>
          </>
        ) : null}

        {paymentMethod?.type === `card` && processingFee ? (
          <>
            <Grid size={{ xs: 6 }}>
              <Typography sx={clsx.keyDemiText}>Processing Fee</Typography>
            </Grid>

            <Grid size={{ xs: 6 }}>
              <Typography sx={clsx.valDemiText}>{getMonetaryValue(processingFee)}</Typography>
            </Grid>
          </>
        ) : null}

        {input?.paid || input?.paid === 0 ? (
          <>
            <Grid size={{ xs: 6 }}>
              <Typography sx={clsx.keyDemiText}>Amount Paid</Typography>
            </Grid>

            <Grid size={{ xs: 6 }}>
              <Typography sx={clsx.valDemiText}>({getMonetaryValue(input?.paid)})</Typography>
            </Grid>
          </>
        ) : null}

        <div className={cls.lineBreak} />

        {input?.due || input?.due === 0 ? (
          <>
            <Grid size={{ xs: 6 }}>
              <Typography sx={clsx.keyGrandText}>Amount Due</Typography>
            </Grid>

            <Grid size={{ xs: 6 }}>
              {paymentMethod?.type === `card` && processingFee ? (
                <Typography sx={clsx.valGrandText}>{getMonetaryValue(input?.due + processingFee)}</Typography>
              ) : (
                <Typography sx={clsx.valGrandText}>{getMonetaryValue(input?.due)}</Typography>
              )}
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme: Theme) => ({
  lineBreak: css`
    width: 100%;
    border-bottom: 1px solid ${theme.palette.divider};
    margin: 8px 0;
  `,
});

const useSxStyles = (theme?: Theme) => ({
  keyText: {
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 400,
    color: theme?.palette?.text?.secondary,
  },
  valText: {
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: 400,
    color: theme?.palette?.text?.secondary,
  },
  keyDemiText: {
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 400,
    color: theme?.palette?.text?.tertiary,
  },
  valDemiText: {
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: 400,
    color: theme?.palette?.text?.tertiary,
  },
  keyBoldText: {
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 400,
  },
  valBoldText: {
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: 400,
  },
  keyGrandText: {
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: 600,
  },
  valGrandText: {
    textAlign: 'right',
    fontSize: '20px',
    fontWeight: 600,
  },
});

// EXPORT ---------------------------------------------------------------- //

export default PayNowContent;
