// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';
import dayjs from 'dayjs';

import { useUser } from '@store';

import { css } from '@emotion/css';
import { Theme, useTheme, Avatar, Container, Paper, Typography } from '@mui/material';
import { BiSolidEnvelope, BiSolidPhone, BiSolidUser } from 'react-icons/bi';

// COMPONENT ---------------------------------------------------------------- //

const ProfilePage = () => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const user = useUser();

  return (
    <Container maxWidth='sm' sx={{ paddingTop: `24px`, paddingBottom: `24px` }}>
      <Paper variant='custom' sx={{ position: `relative`, overflow: `hidden` }}>
        <div className={cls.profileTop}>
          <Typography sx={{ fontSize: 20, fontWeight: 300 }}>Welcome,</Typography>
          <Typography sx={{ fontSize: 40, fontWeight: 500 }}>
            {user?.profile?.display_name || 'Unknown User'}
          </Typography>
        </div>

        <div className={cls.profileBottom}>
          <Typography variant='body2' sx={{ lineHeight: 1 }}>
            Role
          </Typography>
          <Typography variant='h3' sx={{ textTransform: `capitalize`, fontWeight: 600 }}>
            {user?.profile?.default_role || `Unknown Role`}
          </Typography>

          <div className={cls.flex}>
            {user?.profile?.email ? (
              <div className={cls.flexItem}>
                <BiSolidEnvelope className={cls.icon} size={24} />
                <Typography>{user?.profile?.email || `-`}</Typography>
              </div>
            ) : null}

            {user?.profile?.phone ? (
              <div className={cls.flexItem}>
                <BiSolidPhone className={cls.icon} size={24} />
                <Typography>{user?.profile?.phone || `-`}</Typography>
              </div>
            ) : null}
          </div>

          <Typography variant='body2' sx={{ marginTop: `12px`, color: theme?.palette?.text?.secondary }}>
            User since {dayjs(user?.profile?.created_at).format(`MMMM D, YYYY`)}
          </Typography>
        </div>

        <div className={cls.profileImage}>
          <Avatar
            alt={user?.profile?.display_name || `User`}
            src={user?.profile?.avatar_url || ``}
            sx={{
              width: 128,
              height: 128,
              backgroundColor: theme?.palette?.utility?.lighter,
            }}
          >
            <BiSolidUser size={72} />
          </Avatar>

          <div className={cls.profileImageShadow} />
        </div>
      </Paper>
    </Container>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme: Theme) => ({
  profileTop: css`
    position: relative;
    width: 100%;
    height: 192px;
    padding-top: 36px;
    text-align: center;
    background-color: ${theme?.palette?.secondary?.darker};
    color: ${theme?.palette?.text?.contrastPrimary};
  `,
  profileBottom: css`
    position: relative;
    width: 100%;
    height: 256px;
    padding: 18px;
    padding-top: 84px;
    text-align: center;
    background-color: ${theme?.palette?.background?.paper};
    color: ${theme?.palette?.text?.primary};
  `,

  profileImage: css`
    position: absolute;
    bottom: 192px;
    left: 50%;
    transform: translateX(-50%);
  `,
  profileImageShadow: css`
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    box-shadow: 0px 0px 16px 2px ${theme?.palette?.action?.focus} inset;
    cursor: pointer;
  `,

  flex: css`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
    bottom: 24px;
    left: 0;
    width: 100%;
    ${theme?.breakpoints?.down(`sm`)} {
      flex-direction: column;
      gap: 0;
    }
  `,
  flexItem: css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  `,

  icon: css`
    color: ${theme?.palette?.text?.secondary};
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default ProfilePage;
