import React from 'react';
import { Button, Theme, useTheme, Paper } from '@mui/material';
import { buildGoogleStaticMapURL } from '@services/mapService';
import { css } from '@emotion/css';

const { VITE_GOOGLE_API_KEY } = import.meta.env;

interface LocationDetailsMapProps {
  location: {
    latitude: string;
    longitude: string;
    address: string;
  };
}

const LocationDetailsMap: React.FC<LocationDetailsMapProps> = ({ location }) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const staticMapUrl = location =>
    buildGoogleStaticMapURL({
      key: VITE_GOOGLE_API_KEY,
      markers: [
        {
          icon: 'https://s3.ap-south-1.amazonaws.com/social-auto/acknowledgement_images/fkpH1532077213498-faviconsavein32x32.png',
          shadow: true,
          lat: location.latitude,
          lon: location.longitude,
        },
      ],
      center: 'auto',
      zoom: 'auto',
      scale: false,
      size: '574x422',
      maptype: 'roadmap',
      format: 'png',
      visual_refresh: true,
    });

  return (
    <>
      <div className={cls.main}>
        <Paper variant='custom' className={cls.paper}>
          <img className={cls.map} src={staticMapUrl(location)} alt={`location-img`} />

          <div className={cls.actions}>
            <a href={`https://maps.google.com/?q=${location.address}`} target='_blank' rel='noopener noreferrer'>
              <Button color='secondary'>Open In Maps</Button>
            </a>
          </div>
        </Paper>
      </div>
    </>
  );
};

const useStyles = (theme?: Theme) => ({
  main: css``,
  paper: css`
    width: 100%;
    padding: ${theme?.spacing(2)};
  `,
  map: css`
    display: block;
    width: 100%;
    max-width: 574px;
    border: 1px solid ${theme?.palette.divider};
    border-radius: 4px;
    margin-bottom: ${theme?.spacing(2)};
    margin-left: auto;
    margin-right: auto;
  `,
  actions: css`
    display: flex;
    justify-content: flex-end;
    width: 100%;
  `,
  action: css`
    background-color: ${theme?.palette.secondary.main};
    color: #fff;
    &:hover {
      background-color: ${theme?.palette.secondary.main};
    }
  `,
  actionDisabled: css`
    background-color: ${theme?.palette.action.selected};
    &:hover {
      background-color: ${theme?.palette.action.selected};
    }
  `,
});

export default LocationDetailsMap;
