import React, { useEffect } from 'react';
import { Container, Grid2 as Grid, Typography, Theme, useTheme, LinearProgress } from '@mui/material';
import Divider from '@components/Divider';
import { css } from '@emotion/css';
import { useParams } from 'react-router-dom';
import { useRooftop, useUser } from '@store';
import { SubscribeToLocationByIdDocument, useGetLocationByIdQuery } from '@gql/schema';
import LocationDetailsInfo from '../components/LocationDetailInfo';
import LocationDetailsEdit from '../components/LocationDetailsEdit';
import LocationDetailsMap from '../components/LocationDetailsMap';
import Toolbar from '@components/Utils/Toolbar';
import Spacer from '@components/Utils/Spacer';

const LocationDetailPage: React.FC = props => {
  const { id: locationId } = useParams<{ id: string }>();

  const theme = useTheme();
  const cls = useStyles(theme);

  const rooftop = useRooftop();
  const user = useUser();

  const { subscribeToMore, data, loading } = useGetLocationByIdQuery({
    variables: { customerId: rooftop?.id, locationId: parseInt(locationId || '0') },
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: SubscribeToLocationByIdDocument,
      variables: { customerId: rooftop?.id, locationId: parseInt(locationId || '0') },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return {
          ...prev,
          locations: subscriptionData.data.locations,
        };
      },
    });
    return () => unsubscribe();
  }, [subscribeToMore, rooftop, locationId]);

  if (loading) return <LinearProgress />;

  if (!data || !data?.locations || data?.locations?.length == 0)
    return (
      <div className={cls.notFound}>
        <Typography
          sx={{
            color: theme.palette.text.secondary,
            lineHeight: 1.25,
            textAlign: 'center',
            fontSize: '21px',
            fontWeight: 500,
            [theme.breakpoints.down('sm')]: {
              fontSize: '18px',
            },
            [theme.breakpoints.down('xs')]: {
              fontSize: '16px',
            },
          }}
        >
          NO LOCATIONS FOUND
        </Typography>
      </div>
    );

  return (
    <div className={cls.root}>
      <Container maxWidth='lg'>
        <Toolbar back title='Location Details' />

        <Spacer />

        {user && data && data.locations && data.locations.length > 0 && (
          <>
            <LocationDetailsInfo location={data.locations[0]} />

            <Grid container spacing={2}>
              <Grid size={{ md: 6, xs: 12 }}>
                <Divider spacer mb={6} tip='Edit the details of this location.'>
                  Edit Location
                </Divider>

                <LocationDetailsEdit location={data.locations[0]} />
              </Grid>

              <Grid size={{ md: 6, xs: 12 }}>
                <Divider spacer mb={6} tip='View this location on Google maps.'>
                  View Location
                </Divider>

                <LocationDetailsMap location={data.locations[0]} />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </div>
  );
};

export default LocationDetailPage;

const useStyles = (theme?: Theme) => ({
  root: css`
    display: block;
    position: relative;
    padding-top: ${theme?.spacing(4)};
    padding-bottom: ${theme?.spacing(4)};
    @media (max-width: ${theme?.breakpoints.values.sm}px) {
      padding-top: ${theme?.spacing(3)};
      padding-bottom: ${theme?.spacing(3)};
    }
    @media (max-width: ${theme?.breakpoints.values.xs}px) {
      padding-top: ${theme?.spacing(2)};
      padding-bottom: ${theme?.spacing(2)};
    }
  `,

  notFound: css`
    padding: ${theme?.spacing(4)};
    border: 1px solid ${theme?.palette.divider};
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
  `,
});
