import { NavigateFunction } from 'react-router-dom';
import { toast } from 'react-toastify';

export const goToRoute = (route: string | null, navigate: NavigateFunction) => {
  if (route) navigate(route);
  else toast.error(`No route was specified!`);
};

export const goToPreviousPage = (navigate: NavigateFunction) => {
  navigate(-1);
};

export const goToNextPage = (navigate: NavigateFunction) => {
  navigate(1);
};

export const goToMovesIndex = (navigate: NavigateFunction) => {
  navigate(`/moves`);
};

export const goToLanesIndex = (navigate: NavigateFunction) => {
  navigate(`/lanes`);
};

export const goToLocationsIndex = (navigate: NavigateFunction) => {
  navigate(`/locations`);
};

export const goToLocationDetails = (navigate: NavigateFunction, locationId: number) => {
  navigate(`/locations/${locationId}`);
};

export const goToMovePlanner = (navigate: NavigateFunction) => {
  navigate(`/plan-moves`);
};

export const goToMoveDetails = (moveId: string | null, edit = false, navigate: NavigateFunction) => {
  if (moveId && edit) {
    navigate(`/moves/${moveId}`, { state: { editMode: true } });
  } else if (moveId) {
    navigate(`/moves/${moveId}`);
  } else {
    toast.error(`No move ID was specified!`);
  }
};

export const goToInvoice = (
  invoiceId: string | null,
  customerId: string | null,
  foldId: string | null,
  navigate: NavigateFunction
) => {
  if (invoiceId) localStorage.setItem('invoice-id', invoiceId);
  if (customerId) localStorage.setItem('invoice-customer-id', customerId);
  if (foldId) localStorage.setItem('invoice-fold-id', foldId);
  navigate(`/invoices`);
};

export const goToUserDetails = (userId: string | null, navigate: NavigateFunction) => {
  if (userId) navigate(`/users/${userId}`);
  else toast.error(`No user ID was specified!`);
};
