// DEPENDENCIES ---------------------------------------------------------------- //

import axios from 'axios';
import { getDistance } from 'geolib';

const log = false;

// TYPES ---------------------------------------------------------------- //

export interface XY {
  x?: number;
  y?: number;
}

export interface Point {
  lat?: number;
  lng?: number;
}

export interface Marker {
  id: string;
  name?: string;
  address?: string;
  position?: Point;
}

// HELPERS ---------------------------------------------------------------- //

/** Find the angle of 3 points (B is the center point)
 *
 * We use this as a way of detecting angles since the "heading" field (from Google's API) is unreliable
 */
export const getAngleOfThreePoints = (A: XY, B: XY, C: XY) => {
  if (!A?.x || !A?.y || !B?.x || !B?.y || !C?.x || !C?.y) return 0;
  const AB = Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));
  const BC = Math.sqrt(Math.pow(B.x - C.x, 2) + Math.pow(B.y - C.y, 2));
  const AC = Math.sqrt(Math.pow(C.x - A.x, 2) + Math.pow(C.y - A.y, 2));
  return (Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB)) * 180) / Math.PI;
};

/** Reduce the amount of points by an algorithm that checks distance and heading angle */
export const getFilteredPoints = async (points: (Point | undefined)[]) => {
  // Initialize filtered points
  let filteredPoints: (Point | undefined)[] = [...points];

  // Using a for loop instead of a filter so we can modify the array while looping over it
  for (let fpIndex = 0; fpIndex < filteredPoints.length; fpIndex++) {
    // Initialize useful points
    const prevOfPrevPoint = filteredPoints[fpIndex - 2];
    const prevPoint = filteredPoints[fpIndex - 1];
    const curPoint = filteredPoints[fpIndex];
    const nextPoint = filteredPoints[fpIndex + 1];

    // Check if there is a previous and next point (so we always include first and last points)
    if (prevOfPrevPoint && prevPoint && curPoint && nextPoint) {
      // Get the distance between the current point and previous point
      const distanceToPrevPoint = getDistance(
        { latitude: prevPoint.lat, longitude: prevPoint.lng },
        { latitude: curPoint.lat, longitude: curPoint.lng },
        1
      );

      // Set the previous accurate heading angle based on the previous and previous before that points
      const prevAngle = getAngleOfThreePoints(
        { x: prevOfPrevPoint.lat, y: prevOfPrevPoint.lng },
        { x: prevPoint.lat, y: prevPoint.lng },
        { x: curPoint.lat, y: curPoint.lng }
      );

      // Set the current accurate heading angle based on the previous and next points
      const curAngle = getAngleOfThreePoints(
        { x: prevPoint.lat, y: prevPoint.lng },
        { x: curPoint.lat, y: curPoint.lng },
        { x: nextPoint.lat, y: nextPoint.lng }
      );

      // If the distance is not far enough away from the previous point, remove it and roll-back
      // If the previous point's angle is within a specific degree, remove it and roll-back
      const maxDistance = 15.24; // In Meters (1m = 3ft 3.37in)
      const maxAngle = 2; // In Degrees
      if (distanceToPrevPoint < maxDistance || Math.abs(prevAngle - curAngle) < maxAngle) {
        filteredPoints.splice(fpIndex, 1);
        fpIndex--;
      }
    }
  }

  // Remove falsey values from array
  filteredPoints = filteredPoints.filter(Boolean);

  // Return filtered points
  log && console.log(`Filtered Points:`, filteredPoints);
  return filteredPoints;
};

/** Call the Google Roads API to snap & smooth the points
 *
 * Google can only take in 100 points at a time which may lead to multiple API calls
 * We currently have a cap of 20 calls
 */
export const getSmoothPoints = async (filteredPoints: (Point | undefined)[], apiKey: string) => {
  // Initialize smooth points
  let smoothPoints = [];

  // Try and use Google's Roads API to snap the points
  try {
    // Detect the number of API calls we have to do
    const apiCallCount = Math.ceil(filteredPoints.length / 100);

    // Throw an error if theres too many points being requested
    if (apiCallCount > 20) {
      throw new Error(
        `More than 20 API calls to Google were expected. Skipping Google call. Driver's path will not be snapped & smoothed...`
      );
    }

    // Build multiple arrays of points in segments of 100 so Google can work with the data
    const slicedPoints = [];
    for (let pIndex = 0; pIndex / 100 < apiCallCount; pIndex += 100) {
      const singleSlicedPoints = [...filteredPoints].slice(pIndex, pIndex + 100);
      slicedPoints.push(singleSlicedPoints);
    }

    // Do multiple API calls if necessary
    let res = await Promise.allSettled(
      slicedPoints.map(async singleSlicedPoints => {
        // Format points into a string for Google's API to use
        let combinedPoints = ``;
        singleSlicedPoints.forEach((p, i) => {
          combinedPoints += `${p.lat},${p.lng}`;
          if (i < singleSlicedPoints.length - 1) combinedPoints += `|`;
        });

        // Call Google's Roads API to snap the points to a road and smooth it out.
        const snapRes = await axios({
          url: `https://roads.googleapis.com/v1/snapToRoads?path=${combinedPoints}&interpolate=true&key=${apiKey}`,
          method: `GET`,
          headers: {
            'content-type': `application/json`,
          },
        });

        // Check for response
        // log && console.log(`Google Response #${i + 1}:`, snapRes);
        if (snapRes?.data?.snappedPoints?.length) {
          let resPoints = snapRes.data.snappedPoints;
          resPoints = resPoints.map(sp => {
            if (sp?.location.latitude && sp?.location.longitude) {
              return {
                lat: sp.location.latitude,
                lng: sp.location.longitude,
              };
            }
            return null;
          });
          return resPoints;
        }
      })
    );

    smoothPoints = res.map(e => e?.value).flat();
  } catch (err) {
    console.error(`Driver's path was not snapped and smoothed:`, err);
  }

  // Remove falsey values from array
  smoothPoints = smoothPoints.filter(Boolean);

  // Return smooth points
  log && console.log(`Smooth Points:`, smoothPoints);
  return smoothPoints;
};

/** Get the default center coords from points */
export const getDefaultCenter = (points?: (Point | undefined)[]) => {
  if (points?.length) {
    let latSum = 0;
    let lonSum = 0;

    points?.forEach((point: Point | undefined) => {
      latSum += point?.lat || 0;
      lonSum += point?.lng || 0;
    });

    const latAvg = latSum / points?.length;
    const lonAvg = lonSum / points?.length;
    return { lat: latAvg, lng: lonAvg };
  } else {
    return { lat: 37.5407, lng: -77.436 };
  }
};

/** Get the default bounds from markers */
export const getDefaultBounds = (markers?: Marker[]) => {
  const defaultBounds = { north: 50, south: 23, west: -125, east: -66 };

  if (!markers || !markers?.length) return defaultBounds;

  const bounds = {
    north: Math.max(...markers.map(marker => marker?.position?.lat || defaultBounds.north)),
    south: Math.min(...markers.map(marker => marker?.position?.lat || defaultBounds.south)),
    west: Math.min(...markers.map(marker => marker?.position?.lng || defaultBounds.west)),
    east: Math.max(...markers.map(marker => marker?.position?.lng || defaultBounds.east)),
  };

  return bounds;
};
