// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';

import { Moves } from '@gql/schema';

import { getFormattedVehicleFromMove } from '@services/moveService';

import { css } from '@emotion/css';
import { Theme, useTheme, Avatar, Typography, Tooltip } from '@mui/material';
import { FaCar } from 'react-icons/fa';

// TYPES ---------------------------------------------------------------- //

interface MoveDetailsVehicleImageProps {
  move?: Moves;
}

// COMPONENT ---------------------------------------------------------------- //

const MoveDetailsVehicleImage = ({ move }: MoveDetailsVehicleImageProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  return (
    <div className={cls.image}>
      <Typography sx={{ fontWeight: 600, color: theme?.palette?.text?.disabled }}>VEHICLE</Typography>

      <div className={cls.imageBox}>
        <Avatar
          alt={getFormattedVehicleFromMove(move) || `Vehicle`}
          src={
            move?.delivery_photos?.find(photo => photo?.step_id === `driver-front`)?.url ||
            move?.pickup_photos?.find(photo => photo?.step_id === `driver-front`)?.url ||
            ``
          }
          sx={{
            width: 96,
            height: 96,
            backgroundColor: theme?.palette?.utility?.lighter,
          }}
        >
          <FaCar size={56} />
        </Avatar>

        <Tooltip title={getFormattedVehicleFromMove(move) || `Vehicle`} placement='top'>
          <div className={cls.imageBoxShadow} />
        </Tooltip>
      </div>
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  image: css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin-top: 6px;
    ${theme?.breakpoints?.down(`sm`)} {
      display: none;
    }
  `,
  imageBox: css`
    position: relative;
  `,
  imageBoxShadow: css`
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    box-shadow: 0px 0px 12px 2px ${theme?.palette?.action?.focus} inset;
    cursor: pointer;
  `,
});

// EXPORT ---------------------------------------------------------------- //

export default MoveDetailsVehicleImage;
