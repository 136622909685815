// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';

import { Lanes, Locations } from '@gql/schema';

import { css } from '@emotion/css';
import { Theme, useTheme, Collapse, Typography } from '@mui/material';
import { BiRightArrowAlt } from 'react-icons/bi';

// TYPES ---------------------------------------------------------------- //

interface MovePlannerLaneFavoritesProps {
  lanes?: Lanes[];
  locations?: Locations[];
  setPickup?: (location: Locations) => void;
  setDelivery?: (location: Locations) => void;
}

// COMPONENT ---------------------------------------------------------------- //

const MovePlannerLaneFavorites = ({ lanes, locations, setPickup, setDelivery }: MovePlannerLaneFavoritesProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const [showFavorites, setShowFavorites] = React.useState(false);

  const favoriteLanes: Lanes[] = [...lanes]
    ?.filter((l?: Lanes | null) => l?.favorite)
    ?.sort((a?: Lanes | null, b?: Lanes | null) => {
      if (a?.pickup?.name < b?.pickup?.name) return -1;
      if (a?.pickup?.name > b?.pickup?.name) return 1;
      if (a?.pickup?.name === b?.pickup?.name) {
        if (a?.delivery?.name < b?.delivery?.name) return -1;
        if (a?.delivery?.name > b?.delivery?.name) return 1;
      }
      return 0;
    });

  /** Handle selecting a favorite lane from the favorites list */
  const handleLaneSelect = (lane?: Lanes | null) => {
    if (lane) {
      const foundPickup = locations?.find(l => l?.id === lane?.pickup?.id);
      const foundDelivery = locations?.find(l => l?.id === lane?.delivery?.id);
      if (foundPickup && foundDelivery) {
        if (setPickup) setPickup(foundPickup);
        if (setDelivery) setDelivery(foundDelivery);
      }
    }
  };

  return (
    <>
      <Collapse in={showFavorites}>
        <>
          <Typography
            variant='body2'
            onClick={() => setShowFavorites(!showFavorites)}
            sx={{
              marginTop: `12px`,
              marginBottom: `6px`,
              textAlign: `center`,
              fontWeight: 600,
              color: theme?.palette?.text?.disabled,
              cursor: `pointer`,
              transition: `0.1s ease-in-out`,
              userSelect: `none`,
              '&:hover': {
                color: theme?.palette?.secondary?.main,
              },
            }}
          >
            FAVORITE LANES
          </Typography>

          <div className={cls.favoriteList}>
            {favoriteLanes?.map((l: Lanes | null) => {
              return (
                <div
                  key={`favorite-lane-${l?.id || `${l?.pickup?.id}-${l?.delivery?.id}`}`}
                  className={cls.favoriteItem}
                  onClick={() => handleLaneSelect(l)}
                >
                  <div className={cls.favoriteItemLocation}>
                    <Typography>{l?.pickup?.name}</Typography>
                    <Typography variant='caption' sx={{ color: theme?.palette?.text?.secondary }}>
                      {l?.pickup?.address}
                    </Typography>
                  </div>

                  <BiRightArrowAlt size={28} />

                  <div className={cls.favoriteItemLocation}>
                    <Typography>{l?.delivery?.name}</Typography>
                    <Typography variant='caption' sx={{ color: theme?.palette?.text?.secondary }}>
                      {l?.delivery?.address}
                    </Typography>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      </Collapse>

      <div className={cls.favoriteBtn} onClick={() => setShowFavorites(!showFavorites)}>
        <Typography
          variant='caption'
          sx={{ fontWeight: 600, color: theme?.palette?.text?.contrastPrimary, userSelect: `none` }}
        >
          {showFavorites ? `HIDE FAVORITE LANES` : `SHOW FAVORITE LANES`}
        </Typography>
      </div>
    </>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => {
  return {
    favoriteBtn: css`
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0;
      bottom: -28px;
      left: 0;
      right: 0;
      width: fit-content;
      padding: 4px 8px;
      border-radius: 6px;
      margin: 0 auto;
      background-color: ${theme?.palette?.secondary?.main};
      cursor: pointer;
      transition: 0.1s ease-in-out;
      :hover {
        background-color: ${theme?.palette?.secondary?.dark};
      }
    `,

    favoriteList: css`
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 12px;
      border: 1px solid ${theme?.palette?.divider};
      border-radius: 10px;
      margin-bottom: 6px;
      background-color: ${theme?.palette?.action?.hover};
    `,
    favoriteItem: css`
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      padding: 6px;
      border-radius: 6px;
      text-align: center;
      cursor: pointer;
      transition: 0.1s ease-in-out;
      :hover {
        background-color: ${theme?.palette?.action?.hover};
      }
    `,
    favoriteItemLocation: css`
      flex: 1;
      width: 100%;
    `,
  };
};

// EXPORT ---------------------------------------------------------------- //

export default MovePlannerLaneFavorites;
