//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { css } from '@emotion/css';
import { Paper, Switch, SxProps, Theme, Typography, useTheme } from '@mui/material';

import { useGetCustomerAndPrepaidProductsQuery, useUpdateCustomerBillingInfoMutation } from '@gql/schema';
import { useRooftop } from '@store';
import DefaultFallback from '@components/Fallbacks/DefaultFallback';
import PrepaidPackage from '@features/billing/components/PrepaidPackage';
const log = false;

//////////////////////// COMPONENT ////////////////////////
const BillingPreferences: React.FC = () => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sxStyles = useSxStyles();

  const rooftop = useRooftop();

  const [customerId, setCustomerId] = React.useState<number>(0);
  const [customerName, setCustomerName] = React.useState<string>(`Unknown`);
  const [billingFreq, setBillingFreq] = React.useState<string>(`Unknown`);
  const [autoPay, setAutoPay] = React.useState<boolean>(false);
  const [prepaidPackageCost, setPrepaidPackageCost] = React.useState<number>(0);

  React.useEffect(() => {
    if (rooftop) {
      setCustomerId(rooftop?.id);
    }
  }, [rooftop]);

  const { error, data, refetch } = useGetCustomerAndPrepaidProductsQuery({
    variables: { customerId: customerId },
  });

  const [updateCustomerBillingInfo] = useUpdateCustomerBillingInfoMutation();

  const capFirst = (str: string | undefined) => {
    if (typeof str !== 'string') return null;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  React.useEffect(() => {
    if (data && data.customers && data.customers.length > 0) {
      let customer = data.customers[0];
      log &&
        console.log(
          `Got Customer #${customer.id} with Billing Frequency '${customer.billing_frequency}' and Auto-Pay '${customer.auto_pay}'.`
        );
      const billingFreq = capFirst(customer.billing_frequency) || `Unknown`;
      const customerName = customer.name || `Unknown`;
      setCustomerName(customerName);
      setBillingFreq(billingFreq);
      setAutoPay(customer.auto_pay);
    }
    const hasPrepaidPackage = data && data.productfees && data.productfees.length > 0 ? true : false;
    const productFee = hasPrepaidPackage && data ? data.productfees[0] : { cost: 0 };
    const productFeeCost = productFee.cost;
    log && console.log(`Prepaid amount:`, prepaidPackageCost);
    setPrepaidPackageCost(productFeeCost);
  }, [data]);

  const handleCheck = async () => {
    setAutoPay(!autoPay);
    //This should probably be refactored to handle error responses
    await updateCustomerBillingInfo({ variables: { customerId: customerId, autoPay: !autoPay } });
    await refetch();
  };

  if (error) return <DefaultFallback severity='error' message='Error getting billing information!' />;
  if (!data || !data.customers || data.customers.length === 0)
    return <DefaultFallback severity='warning' message='No billing information found.' />;
  return (
    <Paper variant='custom' sx={{ width: `fit-content`, padding: `18px` }}>
      <div className={styles.pref}>
        <Typography sx={sxStyles.txtTitle} color='textSecondary' variant='h6'>
          Market Name
        </Typography>
        <Typography sx={sxStyles.txtItem} variant='h6'>
          {customerName}
        </Typography>
      </div>
      <div className={styles.pref} style={{ width: '64px' }} />
      <div className={styles.pref}>
        <Typography sx={sxStyles.txtTitle} color='textSecondary' variant='h6'>
          Billing&nbsp;Frequency
        </Typography>
        <Typography sx={sxStyles.txtItem} variant='h6'>
          {capFirst(billingFreq)}
        </Typography>
      </div>
      <div className={styles.pref} style={{ width: '64px' }} />
      <div className={styles.pref}>
        <Typography sx={sxStyles.txtTitle} color='textSecondary' variant='h6'>
          Auto&nbsp;Pay
        </Typography>
        <Switch
          disabled={billingFreq === `Daily` || prepaidPackageCost ? true : false}
          checked={autoPay}
          onChange={() => handleCheck()}
          value={autoPay}
          color='primary'
          inputProps={{ 'aria-label': 'checkbox' }}
        />
      </div>
      {prepaidPackageCost ? <PrepaidPackage productFeeCost={prepaidPackageCost} /> : null}
    </Paper>
  );
};

export default BillingPreferences;

//////////////////////// STYLES ////////////////////////

const useStyles = (theme: Theme) => {
  return {
    pref: css`
      display: inline-block;
      vertical-align: top;
    `,
  };
};

const useSxStyles = (): Record<string, SxProps<Theme> | undefined> => ({
  txtTitle: {
    fontSize: '12px',
    fontWeight: 500,
    textAlign: 'center',
  },
  txtItem: {
    fontSize: '18px',
    fontWeight: 500,
    textAlign: 'center',
  },
});
