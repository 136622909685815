import React from 'react';
import { css } from '@emotion/css';

import { Container, Theme, useTheme } from '@mui/material';

import Spacer from '@components/Utils/Spacer';
import useMoveImport from '@features/moveImport/hooks/useMoveImport';

import CsvReader from '@features/moveImport/components/CsvReader';
import UploadedContent from '@features/moveImport/components/UploadedContent';

type Entries = {
  order: number | null;
  move_ids: number[] | null;
  success: boolean | null;
};

const MoveImportPage: React.FC = () => {
  const theme = useTheme();
  const cls = useStyles(theme);

  const [storedCsvData, setStoredCsvData] = React.useState(null);
  const [headers, setHeaders] = React.useState<string[]>([]);
  const [entries, setEntries] = React.useState<Entries[]>([]);
  const [movesToUpsert, setMovesToUpsert] = React.useState<any[]>([]);
  const [dupeStr, setDupeStr] = React.useState(``);

  const { buildMovesFromCsvData } = useMoveImport();

  // Force a reupload of the csv to rebuild the entries with the new dupe string
  React.useEffect(() => {
    if (storedCsvData) handleCsvData(storedCsvData);
    else handleRemoveData();
  }, [dupeStr]);

  // Convert and set movesToUpsert
  const handleCsvData = async (newCsvData: { data: string[][], errors: any[], meta: any[] }) => {
    setStoredCsvData(JSON.parse(JSON.stringify(newCsvData)));
    const buildObj = await buildMovesFromCsvData(newCsvData, dupeStr);
    const { headers, entries, moves } = buildObj;
    setHeaders(headers);
    setEntries(entries);
    setMovesToUpsert(moves);
  };

  // Clear out movesToUpsert
  const handleRemoveData = () => {
    setStoredCsvData(null);
    setHeaders([]);
    setEntries([]);
    setMovesToUpsert([]);
  };

  return (
    <div className={cls.root}>
      <Container maxWidth='lg'>
        <CsvReader
          onUpload={handleCsvData}
          onRemove={handleRemoveData}
          dupeStr={dupeStr}
          onDupeStrChange={setDupeStr}
        />

        <Spacer />

        {entries && movesToUpsert ? (
          <UploadedContent headers={headers} entries={entries} moves={movesToUpsert} />
        ) : null}
      </Container>
    </div>
  );
};
export default MoveImportPage;

//////////////////////// STYLES ////////////////////////
const useStyles = (theme: Theme) => {
  return {
    root: css`
      display: block;
      position: relative;
      padding-top: ${theme.spacing(3)};
      padding-bottom: ${theme.spacing(3)};
      ${theme.breakpoints.down('sm')} {
        padding-top: ${theme.spacing(3)};
        padding-bottom: ${theme.spacing(3)};
      }
      ${theme.breakpoints.down('xs')} {
        padding-top: ${theme.spacing(2)};
        padding-bottom: ${theme.spacing(2)};
      }
    `,
  };
};
