// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import axios from 'axios';

import { Arpaymentmethods, useGetPaymentMethodsLazyQuery } from '@gql/schema';
import { useRooftop, useUser } from '@store';

import { LinearProgress, Typography, useTheme } from '@mui/material';

import Loading from '@components/Utils/Loading';
import Spacer from '@components/Utils/Spacer';
import Success from '@components/Utils/Success';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '@components/Modal';

import PayNowContent from './PayNowContent';

// TYPES ---------------------------------------------------------------- //

type PayNowModalProps = {
  input?: any;
  open?: boolean;
  onClose?: () => void;
  refetch?: () => void;
};

// COMPONENT ---------------------------------------------------------------- //

const PayNowModal = ({ input, open, onClose, refetch }: PayNowModalProps) => {
  const theme = useTheme();

  const user = useUser();
  const rooftop = useRooftop();

  const [formState, setFormState] = React.useState<string>(`pay-now`); // 'pay-now', 'pay-processing' or 'pay-success'

  const [loading, setLoading] = React.useState<boolean>(false);
  const [internalError, setInternalError] = React.useState<boolean>(false);
  const [internalErrorText, setInternalErrorText] = React.useState<string>(``);

  const [paymentMethods, setPaymentMethods] = React.useState<Arpaymentmethods[]>([]);
  const [paymentMethod, setPaymentMethod] = React.useState<Arpaymentmethods | undefined>(undefined);

  const [getPaymentMethods] = useGetPaymentMethodsLazyQuery();

  React.useEffect(() => {
    const fetchPaymentMethods = async () => {
      setLoading(true);

      try {
        const res = await getPaymentMethods({ variables: { customerId: rooftop?.id || 0 } });
        const resPaymentMethods: Arpaymentmethods[] = res?.data?.arpaymentmethods || [];
        const defaultPaymentMethod: Arpaymentmethods | undefined = resPaymentMethods?.[0] || undefined;

        setPaymentMethods(resPaymentMethods);
        setPaymentMethod(defaultPaymentMethod);

        setInternalError(false);
        setInternalErrorText(``);
      } catch (err) {
        console.error(`Failed to fetch payment methods:`, err);
        setInternalError(true);
        setInternalErrorText(`Failed to fetch payment methods.`);
      }

      setLoading(false);
    };

    fetchPaymentMethods();
  }, []);

  const handleClose = () => {
    setFormState(`pay-now`);
    setInternalError(false);
    setInternalErrorText(``);
    if (refetch) refetch();
    if (onClose) onClose();
  };

  const handlePaymentMethodChange = (method: Arpaymentmethods | undefined) => {
    setInternalError(false);
    setInternalErrorText(``);
    setPaymentMethod(method);
  };

  const handleProcessPayment = async () => {
    try {
      const userToken = await user?.getToken();

      if (!userToken) {
        console.error(`Error getting user authentication. If this issue persists, please contact support.`);
        setInternalError(true);
        setInternalErrorText(`Error getting user authentication. If this issue persists, please contact support.`);
        return;
      }

      if (!paymentMethod) {
        console.error(`Please select a valid payment method to continue.`);
        setInternalError(true);
        setInternalErrorText(`Please select a valid payment method to continue.`);
        return;
      }

      setFormState(`pay-processing`);

      const variables = {
        armoves: input?.invoice?.armoves,
        callMethod: `dealer-pay-button`,
        paymentMethod: paymentMethod,
        invoiceId: input?.invoice?.id,
      };

      const res = await axios({
        method: `POST`,
        url: `/.netlify/functions/processPaymentAR`,
        data: variables,
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });

      console.log("PAYMENT RESPONSE", res);

      if (res?.status === 200) {
        setFormState(`pay-success`);
      } else {
        console.error(`Payment failed to process:`, res?.data);
        setInternalError(true);
        setInternalErrorText(`Payment failed to process! If this issue persists, please contact support.`);
        setFormState(`pay-now`);
      }
    } catch (err) {
      // Customers do not need to see this error
      console.error(`An error occurred when attempting to post payment:`, err);
      setFormState(`pay-success`);
    }
  };

  return (
    <Modal open={open || false}>
      {open ? (
        <>
          {formState === `pay-now` && input ? (
            <>
              <ModalHeader handleClose={handleClose}>Pay Now</ModalHeader>

              <ModalContent
                subtitle={`By clicking "Make Payment", your method of payment will be charged for the amount displayed as the "Amount Due". If there are any disputed moves, the invoice will be considered partially paid until an agreement is reached.`}
              >
                {internalError && internalErrorText ? (
                  <>
                    <Typography style={{ marginBottom: `8px`, fontWeight: 500, color: theme?.palette?.error?.main }}>
                      {internalErrorText}
                    </Typography>

                    <Spacer size={1} />
                  </>
                ) : null}

                {loading ? <Loading /> : null}

                <PayNowContent
                  input={input}
                  paymentMethods={paymentMethods}
                  paymentMethod={paymentMethod}
                  onPaymentMethodChange={handlePaymentMethodChange}
                />
              </ModalContent>

              <ModalFooter>
                <ModalAction onClick={() => handleProcessPayment()} disabled={!paymentMethod}>
                  Make Payment
                </ModalAction>
              </ModalFooter>
            </>
          ) : null}

          {formState === `pay-processing` ? (
            <>
              <ModalHeader>Processing Payment...</ModalHeader>

              <ModalContent>
                <LinearProgress />
              </ModalContent>
            </>
          ) : null}

          {formState === `pay-success` ? (
            <>
              <ModalHeader handleClose={handleClose}>Payment Approved!</ModalHeader>

              <ModalContent>
                <Success />
              </ModalContent>

              <ModalFooter>
                <ModalAction onClick={() => handleClose()} color='default'>
                  Done
                </ModalAction>
              </ModalFooter>
            </>
          ) : null}
        </>
      ) : null}
    </Modal>
  );
};

// EXPORT ---------------------------------------------------------------- //

export default PayNowModal;
