// DEPENDENCIES ---------------------------------------------------------------- //

// import React from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { Arinvoices } from '@gql/schema';

import { css } from '@emotion/css';
import { Theme, Tooltip, Typography, useTheme } from '@mui/material';

import { BiSolidAdjust, BiSolidBadgeDollar, BiSolidCheckCircle, BiSolidCommentError } from 'react-icons/bi';

import { getMonetaryValue } from '@services/formatService';
import { buildAmountObject } from '@features/invoices/services/calcService';

// TYPES ---------------------------------------------------------------- //

type InvoiceSidebarItemProps = {
  invoice?: Arinvoices;
  selectedInvoiceId?: number;
};

// COMPONENT ---------------------------------------------------------------- //

const InvoiceSidebarItem = ({ invoice, selectedInvoiceId }: InvoiceSidebarItemProps) => {
  const theme = useTheme();
  const cls = useStyles(theme);
  const clsx = useSxStyles(theme);

  const navigate = useNavigate();

  const amount = buildAmountObject(invoice);

  const handleClick = () => {
    localStorage.setItem(`invoiceId`, invoice?.id?.toString() || `0`);
    localStorage.setItem(`invoiceCustomerId`, invoice?.customer?.id?.toString() || `0`);
    localStorage.setItem(`invoiceFoldId`, `0`);
    navigate(`/invoices/${invoice?.id}`);
  };

  const getItemClass = () => {
    const classNames = [cls.item];
    if (invoice?.status === 'closed') classNames.push(cls.itemStandard);
    else classNames.push(cls.itemDim);
    if (selectedInvoiceId === invoice?.id) {
      if (invoice?.status === 'closed') classNames.push(cls.itemSelectedStandard);
      else classNames.push(cls.itemSelectedDim);
    }
    return classNames.join(' ');
  };

  return (
    <div className={getItemClass()} onClick={handleClick}>
      <div className={cls.title}>
        <Typography sx={clsx.dateText}>
          {dayjs.utc(dayjs(invoice?.start_datetime)).format(`MM/DD/YY`)}

          <span className={cls.toText}>
            {` `}
            {`->`}
            {` `}
          </span>

          {dayjs.utc(dayjs(invoice?.end_datetime)).format(`MM/DD/YY`)}
        </Typography>

        <Tooltip title='Invoice Due Amount' placement='top'>
          <Typography sx={clsx.amountText}>{getMonetaryValue(amount?.due)}</Typography>
        </Tooltip>
      </div>

      <Typography sx={clsx.nameText}>{invoice?.customer.name}</Typography>

      <Typography sx={clsx.statusText}>
        {invoice?.status === 'closed' ? 'Prepared' : 'Not Prepared'} (#{invoice?.id})
      </Typography>

      <div className={cls.iconArea}>
        {invoice?.armoves?.length && amount?.discounted > 0 ? (
          <Tooltip title='Has Discounts' placement='top'>
            <span>
              <BiSolidBadgeDollar size={20} />
            </span>
          </Tooltip>
        ) : null}

        {invoice?.armoves?.length && amount?.disputed > 0 ? (
          <Tooltip title='Has Disputes' placement='top'>
            <span>
              <BiSolidCommentError size={20} />
            </span>
          </Tooltip>
        ) : null}

        {invoice?.armoves?.length &&
        amount?.disputed <= 0 &&
        amount?.paid > 0 &&
        amount?.paid <= amount?.total &&
        amount?.due > 0 ? (
          <Tooltip title='Partially Paid' placement='top'>
            <span>
              <BiSolidAdjust size={20} />
            </span>
          </Tooltip>
        ) : null}

        {invoice?.armoves?.length &&
        ((amount?.disputed <= 0 && amount?.paid >= 0 && amount?.paid >= amount?.total) || amount?.due <= 0) ? (
          <Tooltip title='Fully Paid' placement='top'>
            <span>
              <BiSolidCheckCircle size={20} />
            </span>
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};

// STYLES ---------------------------------------------------------------- //

const useStyles = (theme?: Theme) => ({
  item: css`
    position: relative;
    padding: 12px;
    cursor: pointer;
    transition: 0.1s ease-in-out;
  `,

  itemStandard: css`
    color: ${theme?.palette.text.primary};
    &:hover {
      background-color: ${theme?.palette.action.hover};
    }
  `,
  itemDim: css`
    color: ${theme?.palette.text.disabled};
    &:hover {
      background-color: ${theme?.palette.action.hover};
    }
  `,

  itemSelectedStandard: css`
    background-color: ${theme?.palette.primary.main} !important;
    color: ${theme?.palette.primary.contrastText} !important;
    &:hover {
      background-color: ${theme?.palette.primary.main} !important;
    }
  `,
  itemSelectedDim: css`
    background-color: ${theme?.palette.text.disabled} !important;
    color: ${theme?.palette.text.contrastPrimary} !important;
    &:hover {
      background-color: ${theme?.palette.text.disabled} !important;
    }
  `,

  title: css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  `,
  toText: css`
    font-size: 16px;
    font-weight: 400;
  `,

  iconArea: css`
    position: absolute;
    display: flex;
    align-items: center;
    gap: 2px;
    bottom: 0;
    right: 0;
    padding: ${theme?.spacing(1)};
    line-height: 0;
  `,
});

const useSxStyles = (theme?: Theme) => ({
  dateText: {
    lineHeight: 1,
    fontSize: '16px',
    fontWeight: 500,
  },
  amountText: {
    lineHeight: 1,
    fontSize: '16px',
    fontWeight: 700,
  },
  nameText: {
    marginBottom: '6px',
    lineHeight: 1,
    fontSize: '14px',
  },
  statusText: {
    lineHeight: 1,
    textTransform: 'capitalize',
    fontSize: '12px',
  },
});

// EXPORT ---------------------------------------------------------------- //

export default InvoiceSidebarItem;
