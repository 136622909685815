import React from 'react';
import {
  Grid2 as Grid,
  Typography,
  Checkbox,
  Tooltip,
  IconButton,
  Icon,
  Theme,
  useTheme,
  SxProps,
} from '@mui/material';
import ReusableMultiselect from '@components/Selectors/ReusableMultiselect';
import dashboardService from '../services/dashboardService';
import { Moves } from '@gql/schema';
import { useDashboard } from '../providers/DashboardProvider';
import { useUser } from '@store';
import { css } from '@emotion/css';
import { getSelectionsArray } from '@services/utilityService';

interface DashboardFilterProps {
  moves?: Moves[];
}

interface Filters {
  statuses: (string | null | undefined)[];
  makes: (string | null | undefined)[];
  models: (string | null | undefined)[];
  years: (string | null | undefined)[];
  colors: (string | null | undefined)[];
  customerNames: (string | null | undefined)[];
  userNames: (string | null | undefined)[];
  workflowsets: (string | null | undefined)[];
}

interface FilterItems {
  id: string | null | undefined;
  name: string | null | undefined;
}

interface MultiselectItem {
  id: string | number | null | undefined;
  name: string | number | null | undefined;
}

////////// COMPONENT //////////
function DashboardFilter({ moves }: DashboardFilterProps) {
  const ctx = useDashboard();
  const user = useUser();
  const theme = useTheme();
  const sxStyles = useSxStyles(theme);
  const styles = useStyles(theme);

  const filter: Filters | null = dashboardService.getFilters(user?.profile?.email, moves);

  const showConciergeCheck = (moves?.filter(move => move.consumer_pickup === true).length ?? 0) > 0;
  const showHangtagCheck = (moves?.filter(move => move.hangtags && move.hangtags.length).length ?? 0) > 0;

  React.useEffect(() => {
    //If customer is not third party payer, we need to clear out this grouping option
    if (!ctx.customerIsThirdPartyPayer) {
      localStorage.setItem(`dashboard-group-rooftop`, `false`);
      ctx.setRooftopTag(false);
    }
  }, [ctx.customerIsThirdPartyPayer]);

  const handleTags = (name: string | null, arr?: MultiselectItem[]) => {
    const safeArr: MultiselectItem[] = Array.isArray(arr) ? arr : [];
    const filterArr = safeArr as FilterItems[];

    if (name === 'statuses') {
      const tags = getSelectionsArray(ctx.statusTags, filterArr);
      ctx.setStatusTags(tags);
    } else if (name === 'makes') {
      const tags = getSelectionsArray(ctx.makeTags, filterArr);
      ctx.setMakeTags(tags);
    } else if (name === 'models') {
      const tags = getSelectionsArray(ctx.modelTags, filterArr);
      ctx.setModelTags(tags);
    } else if (name === 'years') {
      const tags = getSelectionsArray(ctx.yearTags, filterArr);
      ctx.setYearTags(tags);
    } else if (name === 'color') {
      const tags = getSelectionsArray(ctx.colorTags, filterArr);
      ctx.setColorTags(tags);
    } else if (name === 'rooftops') {
      const tags = getSelectionsArray(ctx.customerNameTags, filterArr);
      ctx.setCustomerNameTags(tags);
    } else if (name === 'users') {
      const tags = getSelectionsArray(ctx.userNameTags, filterArr);
      ctx.setUserNameTags(tags);
    }  else if (name === 'workflows') {
      const tags = getSelectionsArray(ctx.workflowsetTags, filterArr);
      ctx.setWorkflowsetTags(tags);
    }  else if (name === 'concierge') {
      localStorage.setItem(`dashboard-group-concierge`, JSON.stringify(!ctx.conciergeTag));
      ctx.setConciergeTag(!ctx.conciergeTag);
    } else if (name === 'rooftop') {
      localStorage.setItem(`dashboard-group-rooftop`, JSON.stringify(!ctx.rooftopTag));
      ctx.setRooftopTag(!ctx.rooftopTag);
    } else if (name === 'hangtag') {
      localStorage.setItem('dashboard-group-hangtag', JSON.stringify(!ctx.hangtagTag));
      ctx.setHangtagTag(!ctx.hangtagTag);
    }
  };

  return (
    <>
      <div className={styles.paper}>
        <div className={styles.clear}>
          <Tooltip title='Clear Filters'>
            <IconButton onClick={ctx.handleClearFilters}>
              <Icon>cached</Icon>
            </IconButton>
          </Tooltip>
        </div>

        <Typography sx={sxStyles.head}>Filtering Options</Typography>

        {showConciergeCheck || ctx.customerIsThirdPartyPayer || showHangtagCheck ? (
          <Typography sx={sxStyles.sub}>Groups</Typography>
        ) : null}

        {showConciergeCheck ? (
          <Grid container alignItems='center'>
            <Grid>
              <Checkbox
                sx={sxStyles.optionCheck}
                color='primary'
                checked={ctx.conciergeTag}
                onChange={() => handleTags('concierge')}
              />
            </Grid>
            <Grid size='grow'>
              <Typography sx={sxStyles.optionTxt}>Group By Customer</Typography>
            </Grid>
          </Grid>
        ) : null}

        {ctx.customerIsThirdPartyPayer ? (
          <Grid container alignItems='center'>
            <Grid>
              <Checkbox
                sx={sxStyles.optionCheck}
                color='primary'
                checked={ctx.rooftopTag}
                onChange={() => handleTags('rooftop')}
              />
            </Grid>
            <Grid size='grow'>
              <Typography sx={sxStyles.optionTxt}>Group By Market</Typography>
            </Grid>
          </Grid>
        ) : null}

        {showHangtagCheck ? (
          <Grid container alignItems='center'>
            <Grid>
              <Checkbox
                sx={sxStyles.optionCheck}
                color='primary'
                checked={ctx.hangtagTag}
                onChange={() => handleTags('hangtag')}
              />
            </Grid>
            <Grid size='grow'>
              <Typography sx={sxStyles.optionTxt}>Moves With Hangtags</Typography>
            </Grid>
          </Grid>
        ) : null}

        {ctx.customerIsThirdPartyPayer && filter && filter.customerNames && filter.customerNames.length > 0 ? (
          <>
            <Typography sx={sxStyles.sub}>Markets</Typography>

            <ReusableMultiselect
              dataArray={filter.customerNames.map(item => ({ id: item, name: item }))}
              selected={ctx.customerNameTags?.map(item => ({ id: item, name: item })) ?? []}
              label={'Markets'}
              handleChange={handleTags}
              //   noIdInLabel={true}
            />
          </>
        ) : null}

        {filter && filter.userNames && filter.userNames.length > 0 ? (
          <>
            <Typography sx={sxStyles.sub}>Created By</Typography>
            <ReusableMultiselect
              dataArray={filter.userNames.map(item => ({ id: item, name: item }))}
              selected={ctx.userNameTags?.map(item => ({ id: item, name: item })) ?? []}
              label={'Users'}
              handleChange={handleTags}
            />
          </>
        ) : null}

        {filter && filter.statuses.length > 0 ? (
          <>
            <Typography sx={sxStyles.sub}>Move Status</Typography>
            <ReusableMultiselect
              dataArray={filter.statuses.map(item => ({ id: item, name: item }))}
              selected={ctx.statusTags?.map(item => ({ id: item, name: item })) ?? []}
              label={'Statuses'}
              handleChange={handleTags}
              //   noIdInLabel={true}
            />
          </>
        ) : null}

        {filter && filter.makes.length > 0 ? (
          <>
            <Typography sx={sxStyles.sub}>Vehicle Make</Typography>
            <ReusableMultiselect
              dataArray={filter.makes.map(item => ({ id: item, name: item }))}
              selected={ctx.makeTags?.map(item => ({ id: item, name: item })) ?? []}
              label={'Makes'}
              handleChange={handleTags}
              //   noIdInLabel={true}
            />
          </>
        ) : null}

        {filter && filter.models.length > 0 ? (
          <>
            <Typography sx={sxStyles.sub}>Vehicle Model</Typography>
            <ReusableMultiselect
              dataArray={filter.models.map(item => ({ id: item, name: item }))}
              selected={ctx.modelTags?.map(item => ({ id: item, name: item })) ?? []}
              label={'Models'}
              handleChange={handleTags}
              //   noIdInLabel={true}
            />
          </>
        ) : null}

        {filter && filter.workflowsets.length > 1 ? (
          <>
            <Typography sx={sxStyles.sub}>Workflows</Typography>
            <ReusableMultiselect
              dataArray={filter.workflowsets.map(item => ({ id: item, name: item }))}
              selected={ctx.workflowsetTags?.map(item => ({ id: item, name: item })) ?? []}
              label={'Workflows'}
              handleChange={handleTags}
            />
          </>
        ) : null}

        {!filter ? (
          <div className={styles.notFound}>
            <Typography sx={sxStyles.notFound}>No filters found.</Typography>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default DashboardFilter;

const useSxStyles = (theme: Theme): Record<string, SxProps<Theme> | undefined> => ({
  head: {
    marginBottom: theme.spacing(1),
    lineHeight: 1,
    fontSize: 21,
    fontWeight: 600,
  },
  sub: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 600,
  },
  optionCheck: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  optionTxt: {
    marginTop: `2px`,
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
  },
  notFound: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: 18,
    fontWeight: 500,
  },
});

const useStyles = (theme: Theme) => ({
  paper: css`
    position: relative;
    width: 240px;
    padding: ${theme.spacing(2)};
    border-radius: 8px;
    margin-bottom: ${theme.spacing(2)};
    background-color: ${theme.palette.background.paper};
    box-shadow: 0 0 16px #00000024;
  `,
  clear: css`
    position: absolute;
    top: 12px;
    right: 12px;
  `,
  notFound: css`
    padding: ${theme?.spacing(4)};
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    background: ${theme?.palette?.background?.paper};
    box-shadow: 0 0 16px #00000024;
  `,
});
